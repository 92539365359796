import { useState, useEffect, useCallback } from 'react'
import Select from '@common/Select'
import Input from '@common/Input'
import Button from '@common/Button'
import Message from '@common/Message'
import Table from '@common/Table'
import Pagenation from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import _ from 'lodash'
import EditWin from './EditWin'
import UseLabelName from '../common/UseLabelName'
import { getTaskFlowItems } from '@services/labelManagement'
import { commonApi } from '@utils/request'
import { getSearchParams } from '@utils/submit'
import UseStandardItemSelects from '@customHooks/UseStandardItemSelects'

export default function OfflineTaskManagement() {
  const standardItemSelects = UseStandardItemSelects()

  const [curRecord, setCurRecord] = useState({})
  const [showEditWin, setShowEditWin] = useState(false)
  const [pageConfig, setPageConfig] = useState({})
  const [labelName, setLabelName] = useState('')
  const [searchParams, setSearchParams] = useState({})
  const [statusArr, setStatusArr] = useState([])
  const standardManageItems = UseLabelName(labelName, true)

  useEffect(() => {
    initData()
  }, [pageConfig, searchParams])

  useEffect(() => {
    const taskFlowStatus = _.get(standardItemSelects, 'task_flow_status') || []
    setStatusArr(taskFlowStatus.map(i => ({
      id: String(i.value),
      text: i.name
    })))
  }, [standardItemSelects])

  function initData() {
    const params = _.assign({}, searchParams, {
      'c.label_name': searchParams.label_name,
      label_name: '',
      'a.status': searchParams.status,
      status: '',
      'a.create_user': searchParams.create_user,
      create_user: '',
    })
    commonApi(getTaskFlowItems, {
      ...pageConfig,
      search: getSearchParams(params, ['a.create_user'])
    }).then(data => {
      setCurRecord(data)
    }).catch(err => Message.error(err))
  }

  function updateParams(key, val) {
    let cloneParams = _.cloneDeep(searchParams)
    cloneParams[key] = val
    setSearchParams(cloneParams)
  }

  const getcolumns = useCallback(
    () => {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 20
        }, {
          title: '标签标准ID',
          dataIndex: 'standard_id',
          width: 40
        }, {
          title: '关联标准',
          dataIndex: 'label_name',
        }, {
          title: '标签类型',
          dataIndex: 'label_type_ch',
        }, {
          title: '标签规则ID',
          dataIndex: 'rule_id',
          width: 40
        }, {
          title: '标签规则',
          dataIndex: 'rule_name',
        }, {
          title: '重跑范围开始时间',
          dataIndex: 'start_time',
        }, {
          title: '重跑范围结束时间',
          dataIndex: 'end_time',
        }, {
          title: '任务创建时间',
          dataIndex: 'create_time',
        }, {
          title: '创建人',
          dataIndex: 'create_user',
        }, {
          title: '任务完成时间',
          dataIndex: 'finish_time',
        }, {
          title: '状态',
          dataIndex: 'status_ch',
        }
      ]
    },
    [],
  )

  return (
    <>
      <BreadCrumbsAndTablde>
        <BreadCrumbsAndTablde.LeftCrumbs>
          <Select
            label='标签名称'
            readOnly={false}
            value={searchParams.label_name}
            width={200}
            data={standardManageItems}
            onInputFilter={setLabelName}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('label_name', val)
            }}
          />

          <Select
            label='状态'
            value={searchParams.status}
            width={200}
            data={statusArr}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('status', val)
            }}
          />

          <Input
            label='创建人'
            value={searchParams.create_user}
            width={200}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('create_user', val)
            }}
          />
        </BreadCrumbsAndTablde.LeftCrumbs>
        {/* <BreadCrumbsAndTablde.RightCrumbs>
          <Button
            text='新增任务'
            type='submit'
            iconClass='iconfont icon-add'
            onClick={() => setShowEditWin(true)}
          />
        </BreadCrumbsAndTablde.RightCrumbs> */}
        <BreadCrumbsAndTablde.CenterWrap>
          <Table
            columns={getcolumns()}
            dataSource={_.isArray(curRecord.items) ? curRecord.items : []}
          />

          <Pagenation
            total={ curRecord.total }
            currentPage={ curRecord.current_page }
            pageCount={ curRecord.per_page }
            getClickPage={ page => setPageConfig(r => _.assign({}, r, {
              page
            })) }
            pageCountChange={ limit => setPageConfig(r => _.assign({}, r, {
              limit
            })) }
          />
        </BreadCrumbsAndTablde.CenterWrap>
      </BreadCrumbsAndTablde>

      {
        showEditWin &&
        <EditWin
          showEditWin={showEditWin}
          setShowEditWin={setShowEditWin}
          updatePage={initData}
        />
      }
    </>
  )
}
