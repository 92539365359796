import React, { PureComponent } from 'react'
import Dialog from "@common/Dialog";
import Select from "@common/Select";
import { relateApiTarget } from "@services/keyIndicatorAllocation";
import './index.css'
import SdwSelect from '@common/Select'
import { getTemplateConfList } from '@api/api'
import SdwMessage from '@common/Message'

class RelateWin extends PureComponent {

    state = {
        id: '',
        api_id: '',
        template_id: '',
        allModules:[]
    }

    componentDidMount() {
        this.iniData()
    }

    iniData = () => {
        let {record} = this.props
        this.setState({
            id:record.id,
            api_id:record.api_id,
            template_id:record.template_id,
        })
        this.getTemplateConfList(record.api_id)
    }

    getTemplateConfList = (id) => {
        getTemplateConfList({
            api_id:id
        })
          .then(res => {
              if (res && res.data && res.data.code === 0) {
                  let targetData = res.data.data || []
                  let allModules = []
                  if (targetData.length > 0) {
                      targetData.forEach(item => {
                          item.children.forEach(i => {
                              allModules.push({id:i.id, text:i.template_name, item:i})
                          })
                      })
                  }
                  this.setState({
                      allModules: allModules
                  })
              }
          }, () => SdwMessage.error("接口异常"))
    }

    onSubmit = () => {
        let { api_id,template_id } = this.state
        let {record} = this.props
        const requestData = {
            id: record.id,
            api_id: api_id,
            template_id: template_id
        }
        relateApiTarget(requestData)
          .then(res => {
              if (res && res.data && res.data.code === 0) {
                  SdwMessage.success("关联成功")
                  this.props.setShowRelateWin(false)
              } else {
                  SdwMessage.error(res.data.msg)
              }
          }, () => SdwMessage.error("接口异常"))
    }

    render() {

        let {
            api_id,
            template_id,
            allModules
        } = this.state
        let {
            apiList,
            showRelateWin, setShowRelateWin
        } = this.props

        return (
            <Dialog
                title={'关联接口'}
                width={666}
                visible={showRelateWin}
                changeVisible={setShowRelateWin}
                onCancelClick={() => setShowRelateWin(false)}
                onSureClick={this.onSubmit}
            >
                <div className='relation-win__label'>
                    <span className='relation-label__title'>选择接口</span>
                    <span style={{ marginRight: '8px' }}>
                        <SdwSelect
                          placeholder="搜索API中英文名"
                          isMultipleChoice={false} // 下拉多选
                          value={api_id} // 数组对象
                          readOnly={false} // 配置为false：可以模糊搜索
                          width={260}
                          listWidth={260}
                          data={apiList}
                          onChange={(val, item) => {
                              console.log("val", val)
                              console.log("item", item)
                              this.setState({
                                  api_id:val,
                                  template_id:"",
                              })
                              this.getTemplateConfList(val)
                          }}
                        />
                    </span>
                    <span>
                        <SdwSelect
                          placeholder="模块"
                          isMultipleChoice={false} // 下拉多选
                          value={template_id} // 数组对象
                          readOnly={false} // 配置为false：可以模糊搜索
                          width={220}
                          listWidth={220}
                          data={allModules}
                          onChange={val => this.setState({
                              template_id: val
                          })}
                        />
                    </span>
                </div>
            </Dialog>
        )
    }
}

export default RelateWin
