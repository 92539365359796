import { useState, useEffect, useLayoutEffect } from 'react'
import _ from 'lodash'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import SdwButton from '@common/Button'
import SdwEnable from '@common/Enable'
import SdwMessage from '@common/Message'
import EditWin from './EditWin'
import { getMonitorServerConfigList, getItermSelects, getBusinessStatisticByTopic } from '@services/logManagement'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

export default function LogManagement(props) {

  const [showEditWin, setShowEditWin] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [searchObj, setSearchObj] = useState({
    page: 1,
    limit: 10,
    search: {}
  })
  const [curClickData, setCurClickData] = useState({})
  const [tableData, setTableData] = useState({})
  const [topicArr, setTopicArr] = useState([])
  const [businessArr, setBusinessArr] = useState([])
  const [topicID, setTopicID] = useState('')
  const [businessList, setBusinessList] = useState([])

  useLayoutEffect(() => {
    getItermSelects().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const topic = _.get(res, 'data.data.topic')
        const business = _.get(res, 'data.data.business')
        if (_.isArray(topic)) setTopicArr(topic.map(i => ({id: i.topic_name, text: i.topic_name, topic_id: i.id})))
        if (_.isArray(business)) setBusinessArr(business.map(i => ({id: i.business_name, text: i.business_name})))
      }
    })
  }, [])

  // 根据主题域ID请求对应的业务
  useEffect(() => {
    if (!topicID) return
    getBusinessStatisticByTopic({
      id: topicID
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const business = _.get(res, 'data.data.list')
        if (_.isArray(business)) setBusinessList(business.map(i => ({id: i.business_name, text: i.business_name})))
      }
    })
  }, [topicID])

  useEffect(() => {
    initData()
  }, [searchObj])

  function initData() {
    const { page, limit, search } = searchObj
    getMonitorServerConfigList({
      page,
      limit,
      search: JSON.stringify({
        ...search.topic ? {
          topic: {
            value: search.topic,
            operator: '='
          }
        } : {},
        ...search.business ? {
          business: {
            value: search.business,
            operator: '='
          }
        } : {},
        ...search.service_id ? {
          id: {
            value: search.service_id,
            operator: '='
          }
        } : {},
        ...search.service_name ? {
          service_name: {
            value: search.service_name,
            operator: 'like'
          }
        } : {},
        ...search.fileName ? {
          fileName: {
            value: search.fileName,
            operator: 'like'
          }
        } : {}
      })
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        if (!_.isEmpty(data)) setTableData(data)
      } else {
        const msg = _.get(res, 'data.msg') || '接口异常'
        SdwMessage.err(msg)
      }
    }).catch(err => SdwMessage.err('接口异常' + err))
  }

  function updateSearchObj(key, val) {
    let cloneObj = _.cloneDeep(searchObj)
    cloneObj[key] = val
    setSearchObj(cloneObj)
  }

  function updateSearchObjBySearch(key, val) {
    let cloneObj = _.cloneDeep(searchObj)
    cloneObj.search[key] = val
    if (key != 'page') {
      cloneObj['page'] = 1
    }
    setSearchObj(cloneObj)
  }

  function getColumns() {
    return [
      {
        title: "服务ID",
        dataIndex: "id",
        width: 60
      },
      {
        title: "服务名称",
        dataIndex: "service_name",
        width: 160
      },
      {
        title: "主题域",
        dataIndex: "topic",
        width: 100
      },
      {
        title: "业务",
        dataIndex: "business",
        width: 100
      },
      {
        title: "日志文件",
        dataIndex: "fileName",
        showTip: true,
        width: 280
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        width: 180
      },
      {
        title: "关联任务数",
        width: 80,
        render: data => <span>{_.isArray(data.cfgList) ? data.cfgList.length : 0}</span>
      },
      {
        title: "修改人",
        dataIndex: "update_user",
        width: 100
      },
      {
        title: "修改时间",
        dataIndex: "update_time",
        width: 180
      },
      {
        title: "是否有效",
        width: 100,
        render: data => <SdwEnable enable={data.is_valid} />
      },
      ...hasPriv('setMonitorServerConfig') ? [
        {
          title: "操作",
          width: 80,
          render: data => {
            return (
              <SdwButton
                onClick={() => {
                  setShowEditWin(true)
                  setIsEdit(true)
                  setCurClickData(data)
                }}
                text='编辑'
              />
            )
          }
        }
      ] : []
    ]
  }

  const { topic, business, service_id, service_name, fileName } = searchObj.search || {}

  return (
    <>
      <SdwBreadCrumbs
        history={props.history}
        data={[
          {
            title: '数据采集地',
            path: '/admin/dataCollect/logManagement'
          }, {
            title: '日志监听管理'
          }
        ]}
      />

      <div className="page-mainbody">
        <div className="page-search">
          <div className="input-wrap">
            <div className="ele-search-input">
              <SdwInput
                  width={250}
                  label="服务ID"
                  value={service_id}
                  onChange={val => updateSearchObjBySearch('service_id', val)}
              />
            </div>
            <div className="ele-search-input">
              <SdwInput
                  width={250}
                  label="服务名称"
                  value={service_name}
                  onChange={val => updateSearchObjBySearch('service_name', val)}
              />
            </div>
            <div className="ele-search-input">
              <SdwSelect
                value={topic}
                label="主题域"
                width={160}
                readOnly={false}
                data={topicArr}
                onChange={(val, item) => {
                  updateSearchObjBySearch('topic', val)
                  setTopicID(item.topic_id)
                }}
              />
            </div>
            <div className="ele-search-input">
              <SdwSelect
                value={business}
                width={160}
                label="业务"
                readOnly={false}
                data={topic ? businessList : businessArr}
                onChange={val => updateSearchObjBySearch('business', val)}
              />
            </div>
            <div className="ele-search-input">
              <SdwInput
                width={250}
                label="日志文件"
                value={fileName}
                onChange={val => updateSearchObjBySearch('fileName', val)}
              />
            </div>
            {
              hasPriv('setMonitorServerConfig') &&
              <div className="btn-group">
                <SdwButton
                  type='submit'
                  text='添加'
                  iconClass='iconfont icon-add'
                  width={120}
                  onClick={ () => {
                    setShowEditWin(true)
                    setIsEdit(false)
                    setCurClickData({})
                  } }
                />
              </div>
            }
          </div>
        </div>

        <div className="page-table">
          <SdwTable
            columns={ getColumns() }
            dataSource={ tableData.items || [] }
          />
          {
            _.isArray(tableData.items) && tableData.items.length &&
            <div className="data-management__pagenation-wrap">
              <SdwPagenation
                total={ tableData.total }
                currentPage={ tableData.current_page }
                pageCountList={[10, 15, 20, 40]}
                pageCount={ tableData.per_page }
                getClickPage={ val => updateSearchObj('page', val) }
                pageCountChange={ val => updateSearchObj('limit', val) }
              />
            </div>
          }
        </div>

        {
          showEditWin &&
          <EditWin
            isEdit={isEdit}
            title={isEdit ? '编辑监听服务' : '添加监听服务'}
            visible={showEditWin}
            data={curClickData}
            changeShowEditWin={bool => setShowEditWin(bool)}
            updatePage={initData}
          />
        }
      </div>
    </>
  )
}
