import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

import './index.css'

export default class Popover extends React.Component {

  state = {
    position: {}
  }

  render () {
    return (
      <div
        style={{ display: 'inline-block', lineHeight: 'initial' }}
        onMouseEnter={e => this.setState({
          position: {
            x: e.clientX,
            y: e.clientY
          }
        })}
        onMouseLeave={e => this.setState({
          position: {}
        })}
      >
        {this.props.children}
        {
          !!this.props.tip &&
          !!this.state.position.x &&
          !!this.state.position.y &&
          <ShowTip {...this.props} {...this.state} />
        }
      </div>
    )
  }
}

function ShowTip({ tip = '', position = {} }) {
  
    let clientX = position.x
    let clientY = position.y

    return ReactDOM.createPortal(
      (
        <div
          style={{
            left: `${clientX}px`,
            top: `${clientY - 16}px`
          }}
          className="sdw-popover__wrap"
        >
          <span>{tip}</span>
        </div>
      ),
      document.body
    )
}
