import React from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import SdwCascader from '@common/Cascader'
import SdwMessage from '@common/Message'
import { getTablecfgFieldById, updateTableField } from '@services/metaData'
import { validateFun, getFormDataParams } from '@utils/submit'

import '../../style/editBaseDbWin.css'

const DATA_SET_TYPE_MAP = {
  es: [{
    id: 'ES',
    text: 'ES',
    disabled: true
  }, {
    id: 'keyword',
    text: 'keyword'
  }, {
    id: 'esdate',
    text: 'date'
  }, {
    id: 'double',
    text: 'double'
  }],
  mysql: [{
    id: 'Mysql',
    text: 'Mysql',
    disabled: true
  }, {
    id: 'varchar',
    text: 'varchar'
  }, {
    id: 'int',
    text: 'int'
  }, {
    id: 'tinyint',
    text: 'tinyint'
  }, {
    id: 'datetime',
    text: 'datetime'
  }, {
    id: 'date',
    text: 'date'
  }, {
    id: 'timestamp',
    text: 'timestamp'
  }]
}

class EditBaseDbWin extends React.PureComponent {
  state = {
    curDbData: {
      enable: 1
    },
    justInOnce: true, // 仅仅编辑的时候进入一次赋值操作，之后不再进入
    topicInputValue: {},
    dataSetType: '', // 仓库类型
    sholdCheckValidate: false,
  }

  componentDidMount() {
    let { curClickData, baseDetail } = this.props
    let ID = curClickData.id
    let dataSetType = baseDetail.data_set_type

    this.setState({ dataSetType: dataSetType })

    if (!!ID) {
      this.initData(ID)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !!this.state.curDbData.field_ref_standard &&
      !_.isEmpty(this.props.standardSelctDataDic) &&
      this.state.justInOnce
    ) {
      let { curDbData } = this.state
      let { standardSelctDataDic } = this.props
      let standardObj = standardSelctDataDic[curDbData.field_ref_standard]

      if (!(standardObj && !!standardObj.selectList)) return

      this.setState({
        justInOnce: false, // 仅仅编辑的时候进入一次赋值操作，之后不再进入
        topicInputValue: {
          id: standardObj.selectList,
          texts: [standardObj.content]
        }
      })
    }
  }

  initData = id => {
    getTablecfgFieldById({id}).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data
        this.setState({ curDbData: record })
      }
    })
  }

  updateCurDbData = (key, val) => {
    let record = _.cloneDeep(this.state.curDbData)
    record[key] = val
    this.setState({ curDbData: record })
  }

  handleTopicInputValue = val => {
    let IDlist = val.ids || []
    let ID = IDlist[IDlist.length - 1] || ''
    this.updateCurDbData('field_ref_standard', ID)
    this.setState({topicInputValue: val})
  }

  onSubmit = () => {
    let { curDbData } = this.state

    // 校验
    let validataList = ['field_name', 'field_type', 'field_ref_standard']
    let isValidatePass = validataList.every(i => !!curDbData[i])

    if (!isValidatePass) {
      this.setState({ sholdCheckValidate: true })
      return
    }

    // 下发字段
    let paramsList = ['id', 'data_set_id', 'field_name', 'field_type', 'field_name_ch',
    'is_sensitive', 'enable','comments', 'field_ref_directory', 'field_ref_standard']

    let formData = getFormDataParams(curDbData, paramsList)

    updateTableField(formData).then(res => {
      let oprText = !!curDbData.id ? '编辑' : '创建'
      if (res && res.data && res.data.code === 0) {
        SdwMessage.success(`${oprText}成功`)
        this.props.changeVisible(false)
        this.props.freshTableData() // 重新获取表数据
      } else {
        let msg = res.data.msg || `${oprText}失败`
        SdwMessage.error(msg)
      }
    })
    .catch(msg => {
      SdwMessage.error('请求失败 ' + msg)
    })
  }

  render () {
    let { visible, curTitle, changeVisible, standardSelectsOptions, standardSelctDataDic, curClickData } = this.props
    let { curDbData, sholdCheckValidate, dataSetType, topicInputValue } = this.state

    let standardOptions = []
    if (!_.isEmpty(standardSelctDataDic)) {
      standardOptions = _.values(standardSelctDataDic).map(i => ({
        id: i.id,
        text: i.content
      }))
    }

    return (
      <>
        <SdwDrawer
          title={curTitle}
          visible={visible}
          onIconClick={() => changeVisible(false)}
          onCancelClick={() => changeVisible(false)}
          onSureClick={this.onSubmit}
        >
          <SdwDrawer.Body>
            <div className='edit-base-win__lable-wrap'>
              <span className="edit-base-win__lable-title required">字段名</span>
              <SdwInput
                placeholder='请输入字段名'
                disabled={!!curClickData.id} // 编辑时，字段名禁用
                value={curDbData.field_name}
                sholdCheckValidate={sholdCheckValidate}
                validateFun={val => validateFun(val, '请输入字段名')}
                onChange={val => this.updateCurDbData('field_name', val)}
              />
            </div>
            <div className='edit-base-win__lable-wrap'>
              <span className="edit-base-win__lable-title">字段中文名</span>
              <SdwInput
                placeholder='请输入字段中文名'
                value={curDbData.field_name_ch}
                onChange={val => this.updateCurDbData('field_name_ch', val)}
              />
            </div>
            <div className='edit-base-win__lable-wrap'>
              <span className="edit-base-win__lable-title required">字段类型</span>
              <SdwSelect
                placeholder='请选则字段类型'
                sholdCheckValidate={sholdCheckValidate}
                validateFun={val => validateFun(val, '请选则字段类型')}
                value={curDbData.field_type}
                clearable={false}
                data={DATA_SET_TYPE_MAP[dataSetType]}
                onChange={val => this.updateCurDbData('field_type', val)}
              />
            </div>
            <div className='edit-base-win__lable-wrap'>
              <span className="edit-base-win__lable-title required">关联标准</span>
              {/* <SdwCascader
                value={topicInputValue}
                readOnly={false}
                listWidth={220}
                sholdCheckValidate={sholdCheckValidate}
                validateFun={curRecord => validateFun(curRecord, '请选择关联标准', 'cascader')}
                data={standardSelectsOptions}
                onChange={val => this.handleTopicInputValue(val)}
              /> */}
              <SdwSelect
                placeholder='请选择关联标准'
                sholdCheckValidate={sholdCheckValidate}
                validateFun={val => validateFun(val, '请选择关联标准')}
                value={curDbData.field_ref_standard}
                readOnly={false}
                data={standardOptions}
                onChange={val => this.updateCurDbData('field_ref_standard', val)}
              />
            </div>
            <div className='edit-base-win__lable-wrap'>
              <span className="edit-base-win__lable-title">通用配置目录分类</span>
              <SdwInput
                placeholder='请输入通用配置目录分类'
                value={curDbData.field_ref_directory}
                onChange={val => this.updateCurDbData('field_ref_directory', val)}
              />
            </div>
            <div className='edit-base-win__lable-wrap'>
              <span className="edit-base-win__lable-title">备注</span>
              <SdwInput
                type="textarea"
                placeholder='请输入备注'
                value={curDbData.comments}
                onChange={val => this.updateCurDbData('comments', val)}
              />
            </div>
            <div className='edit-base-win__lable-wrap'>
              <span className="edit-base-win__lable-title">是否敏感</span>
              <SdwSwitch
                value={curDbData.is_sensitive}
                changeValue={ bool => this.updateCurDbData('is_sensitive', bool) }
              />
            </div>
            <div className='edit-base-win__lable-wrap'>
              <span className="edit-base-win__lable-title">是否可用</span>
              <SdwSwitch
                value={curDbData.enable}
                changeValue={ bool => this.updateCurDbData('enable', bool) }
              />
            </div>
          </SdwDrawer.Body>
        </SdwDrawer>
      </>
    )
  }
}

export default EditBaseDbWin