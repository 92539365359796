import { useState, useEffect } from 'react'
import { DateRangePicker } from 'element-react'
import { runDataTask } from '@services/dataAccess'
import { formatTime } from '@utils/formatTime'
import SdwMessage from '@common/Message'

export default function DataBaseWin (props) {

  const [datePicker, setDatePicker] = useState(null)

  useEffect(() => {
    setDatePicker(null)
  }, [props.data.id])

  props.curRef.current = handleSubmit

  function handleSubmit() {
    let isInitType = props.data.stream_type === 'INIT'

    if (isInitType && !datePicker) return

    runDataTask({
      task_id: props.data.id,
      task_type: props.data.stream_type,
      ...(props.data.stream_type === 'INIT' ? {
        start_time: formatTime(+datePicker[0], 0),
        end_time: formatTime(+datePicker[1], 0)
      } : {})
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          SdwMessage.success('提交任务成功')
          props.changeVisible(false)
        } else {
          let msg = res && res.data && res.data.msg || ''
          SdwMessage.error('提交任务失败：' + msg)
        }
      })
      .catch(err => {
        SdwMessage.error('接口异常：' + err)
      })
  }

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <span style={{ display: 'inline-block', width: 78 }}>ID：</span>
        <span>{props.data.id}</span>
      </div>

      <div style={{ marginBottom: 16 }}>
        <span style={{ display: 'inline-block', width: 78 }}>表名：</span>
        <span>{`${props.data.database_name}.${props.data.table_name}（${props.data.data_set_chinese}）`}</span>
      </div>

      <div style={{ marginBottom: 16 }}>
        <span style={{ display: 'inline-block', width: 78 }}>同步方式：</span>
        <span>{props.data.stream_type}</span>
      </div>
      
      {
        props.data.stream_type === 'INIT' &&
        <div style={{ marginBottom: 16 }}>
          <span style={{ display: 'inline-block', width: 78 }}>时间：</span>
          <DateRangePicker
            value={datePicker}
            isReadOnly={true}
            isShowTime={true}
            rangeSeparator=" 至 "
            placeholder="选择日期范围"
            format="yyyy-MM-dd HH:mm:ss"
            align="right"
            disabledDate={time=>time.getTime() > Date.now() + 24*60*60*1000}
            onChange={date => setDatePicker(date)}
          />
        </div>
      }
    </>
  )
}