import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import './index.css'

function BreadCrumbs(props) {
  const HISTORY = useHistory()
  const { data, history, hideStepsFunc } = props

  const handleClick = record => {
    const {
      goBack,
      path
    } = record

    if (goBack && _.isFunction(hideStepsFunc)) { // 此处是专门为 steps（步骤弹窗）而做的，意为关闭当前步骤弹窗，回到初始页面
      hideStepsFunc(false)
    } else {
      path && history && history.push(path) || HISTORY.push(path)
    }
  }

  return (
    <div className="bread-crumbs__page-breadcrumb__wrap">
      <div className="bread-crumbs__breadcrumb">
        {
          Array.isArray(data) && 
          data.map((record, index) => {
            let dataLength = data.length

            return dataLength === ++index ?
            <span key={index} className="bread-crumbs__breadcrumb__item current">{record.title}</span> :
            <span key={index}>
              <span 
                className="bread-crumbs__breadcrumb__item"
                onClick={() => handleClick(record)}
              >{record.title}</span>
              <span className="arrow iconfont icon-01"></span>
            </span>
          })
        }

        <div>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default BreadCrumbs
