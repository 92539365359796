import React, {PureComponent} from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import checkImg from '../img/check_img@2x.png'
import successImg from '../img/success_img@2x.png'
import errorImg from '../img/error_img@2x.png'
import '../../index.css'
import {w3cwebsocket as W3CWebSocket} from 'websocket'
import {requestOptionsWEBSOCKET} from '@config/config'
const baseURL = requestOptionsWEBSOCKET.basename

const CHECK_OPTION = {
    "INPUT_CONFIG_ISSUE": "采集配置下发检测",
    "PARSE_CONFIG_ISSUR": "解析配置下发检测",
    "INPUT_ACCESS": "采集数据检测",
    "PROCESS": "数据处理服务检测",
    "CONSUME": "数据消费服务检测",
}
const STATUS_CH = {
    "100": {
        text: "检测中",
        className: "status-win__check-result",
    },
    "802": {
        text: "正常",
        className: "status-win__success-result",
    },
    "-802": {
        text: "异常",
        className: "status-win__error-result",
    },
    "803": {
        text: "检测结束",
        className: "status-win__success-result",
    },
    "804": {
        text: "检测线程调度失败",
        className: "status-win__error-result",
    }
}

class StatusWin extends PureComponent {

    state = {
        displayList: [],
        displayStatus: 0, //0 检测中 1检测正常 2检测失败
        displayLength: 0
    }

    componentDidMount() {
        let socketList = this.props.socketList
        // const sessionId = socketList.sessionId
        const checkerList = socketList.checkerList
        // const client = new W3CWebSocket(baseURL + '/sdw/filebeat/socket/' + sessionId);

        this.iniData(checkerList)
        // this.socketConnect(client)
    }

    iniData = (checkerList) => {
        let sessionId = this.props.socketList.sessionId
        const client = new W3CWebSocket(baseURL + '/sdw/filebeat/socket/' + sessionId)
        let num = 1
        this.setState({
            displayLength: checkerList.length,
            displayList: checkerList.map(i => {
                if (num == 1) {
                    num++
                    return {
                        checkName: i,
                        status: "100"
                    }
                } else {
                    return {
                        checkName: i,
                        status: ""
                    }
                }
            })
        }, () => {
            this.socketConnect(client)
        })
    }

    socketConnect = (client) => {
        let {displayList, displayLength} = this.state
        console.log("====client", client)
        client.onopen = () => {
            console.log('WebSocket Client Connected');
        };
        client.onerror = (message) => {
            console.log("client=====message", message);
        };
        client.onmessage = (message) => {
            console.log("message======", message);
            if (message.data != "{}" && message.data != "null" && !!message.data) {
                const dataFromServer = JSON.parse(message.data);
                if (dataFromServer.status != "802") {
                    setTimeout(()=>{
                        client.close()
                    }, 3000)
                }
                this.displayList(dataFromServer)
            }
        };
        client.onclose = (message) => {
            console.log('websocket断开: ' + message)
            if (displayLength > 0) {
                let displayExist = [...displayList]
                displayExist[0] = {...displayExist[0], status: "-802"}
                this.setState({
                    displayStatus: 2,
                    displayList: displayExist
                })
            }
        }
    }

    displayList = (dataFromServer) => {
        let {displayList, displayLength} = this.state
        let displayExist = []
        let displayLengthClone = 0
        if (dataFromServer.status == "800") {
            displayExist = displayList.map(item => {
                return {
                    ..._.assign({}, item, {status: "802"})
                }
            })
            this.setState({
                displayLength: displayLengthClone,
                displayList: displayExist,
                displayStatus: 1,
            })
            return
        } else if (dataFromServer.status == "803") {
            return
        } else {
            displayExist = displayList.map(item => {
                if (item.checkName == dataFromServer.checkName && item.status != dataFromServer.status) {
                    return {
                        ..._.assign({}, item, {status: dataFromServer.status})
                    }
                }
                return item
            })
        }

        displayLengthClone = displayLength - 1
        let obj = {
            displayLength: displayLengthClone,
            displayList: displayExist
        }
        if (dataFromServer.status == "802") {
            if (displayLengthClone == 0) {
                obj["displayStatus"] = 1
            }
        } else {
            obj["displayStatus"] = 2
        }
        this.setState({
            ...obj
        })
    }

    // closeWebSocket = (client) => {}

    closeDialog = () => {
        this.props.closeDialog()
    }

    render() {

        let {
            displayList,
            displayStatus
        } = this.state

        let {
            statusVisible
        } = this.props

        return (
            <div>
                <SdwDialog
                    title={'各链路状态检查'}
                    visible={statusVisible}
                    onSureClick={() => this.closeDialog()}
                    onCancelClick={() => this.closeDialog()}
                >
                    <div>
                        {
                            displayStatus == 0 ?
                                <>
                                    <div className='status-win__check-title'>
                                        <img
                                            className='status-win-img_check'
                                            src={checkImg}
                                        />
                                        <span>
                                            正在检测中，请稍等...
                                        </span>
                                    </div>
                                </>
                                : (
                                    displayStatus == 1 ?
                                        <>
                                            <div className='status-win__success-title'>
                                                <img
                                                    className='status-win-img'
                                                    src={successImg}
                                                />
                                                <span>
                                                    链路状态正常
                                                </span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='status-win__error-title'>
                                                <img
                                                    className='status-win-img'
                                                    src={errorImg}
                                                />
                                                <span>
                                                    链路状态异常，请联系开发
                                                </span>
                                            </div>
                                        </>
                                )
                        }
                        <div className='status-win-wrap'>
                            {
                                displayList.map(i => {
                                    return <>
                                        <div className='status-win-label'>
                                            <div className='status-win-text'>{CHECK_OPTION[i.checkName]}</div>
                                            <div className={STATUS_CH[i.status] ? STATUS_CH[i.status].className : "status-win__check-result"}>{STATUS_CH[i.status] ? STATUS_CH[i.status].text : "待检测"}</div>
                                        </div>
                                    </>
                                })
                            }
                        </div>
                    </div>
                </SdwDialog>
            </div>
        )
    }
}

export default StatusWin
