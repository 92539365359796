import React from 'react'
import { connect } from 'react-redux'
import SdwMessage from '@common/Message'
import SdwBreadCrumbs from '@common/BreadCrumbs'

import TagTree from './TagTree'
import TabPage from './TabPage'

import './style/index.css'
import { pageAppTables, pageAppTablesManage } from '@api/api'
import { getTopicBusiness } from '@services/Derived'
import { hasPriv } from '@components/FrontendAuth/AuthMap'
import { getPublicCfg } from '@api/atom'

class ApiDetail extends React.Component {

  state = {
    previewDataMsg: '',
    currentData: {},
    topicData: [],
    runCycleData: [],
    cfgData:{},
  }

  componentDidMount() {
    this.initData()
    this.getPublicCfg()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  initData = () => {
    this.props.updatePageApiTargets([])
    this.props.updateCurClickTreeValue({})
    //console.log("ApiDetail---index=============", this.props.pageApiTargets)
    getTopicBusiness().then(res => {
      let topicData = res && res.data || {}
      if (topicData && topicData.code === 0 && Array.isArray(topicData.data)) {
        this.setState({
          topicData: topicData.data
        })
      }
    }, () => SdwMessage.error("接口异常"))
    let id = this.props.match.params && this.props.match.params.id || ''
    if (!!id) {
      // DO search
      let params = {
        limit: 10,
        page: 1,
        search: {
          id: {
            value: id,
            operator: '='
          }
        },
      }
      if (hasPriv('pageAppTables')) {
        pageAppTables(params).then(res => {
          let jsonData = res && res.data || {}
          if (jsonData && jsonData.code === 0 && jsonData.data) {
            if (!jsonData.data.items || jsonData.data.items.length == 0) {
              SdwMessage.error("异常的ID")
              return
            }
            //this.props.updateCurrentData(jsonData.data.items[0])
            this.setState({
              currentData: jsonData.data.items[0]
            })
          }
        }, () => SdwMessage.error("接口异常"))
        return
      }
      if (hasPriv('pageAppTablesManage')) {
        pageAppTablesManage(params).then(res => {
          let jsonData = res && res.data || {}
          if (jsonData && jsonData.code === 0 && jsonData.data) {
            if (!jsonData.data.items || jsonData.data.items.length == 0) {
              SdwMessage.error("异常的ID")
              return
            }
            //this.props.updateCurrentData(jsonData.data.items[0])
            this.setState({
              currentData: jsonData.data.items[0]
            })
          }
        }, () => SdwMessage.error("接口异常"))
        return
        return
      }
      SdwMessage.error("暂无权限")
    }
  }
  getPublicCfg = () => {
    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }

        this.setState({ cfgData: cfgData })
      }
    }, () => SdwMessage.error("接口异常"))
  }
  render() {
    let {
      currentData,
      topicData,
      cfgData,
    } = this.state

    // console.log("ApiDetail==============currentData", currentData)
    // console.log("ApiDetail==============topicData", topicData)

    let curTopicData = topicData.map(i => {
      return {
        id: i.value,
        text: i.label,
        children:i.children,
      }
    })
    // console.log("================topicData",topicData)

    return (
      <div className="sdw-application-detail__wrap">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/atom'
            }, {
              title: '指标API',
              path: '/admin/factory/api'
            }, {
              title: '指标API详情'
            }
          ]}
        />

        <div
          className="sdw-api-detail__center-wrap"
          style={{
            height: document.body.offsetHeight - 300
          }}
        >
          <div className="left-box">
            {
              currentData && <TagTree
                currentData={currentData}
                cfgData={cfgData}
                initData={this.initData}
                curTopicData={curTopicData}
              />
            }
          </div>

          {/*<div className="center-box">*/}
          {/*  <IndicatorAttributes*/}
          {/*    currentData={currentData}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="right-box">
            {currentData && <TabPage
              currentData={currentData}
              cfgData={cfgData}
              onReWriteData={this.onReWriteData}
              onSubmit={this.onSubmit}
            />}
          </div>
        </div>
      </div>
    )
  }
}

const mapState = state => ({
  pageApiTargets: state.api.pageApiTargets,
  previewData: state.api.previewData,
  curClickTreeValue: state.api.curClickTreeValue,
})

const mapDispatch = dispatch => ({
  updatePageApiTargets: dispatch.api.updatePageApiTargets,
  updatePreviewData: dispatch.api.updatePreviewData,
  updateCurClickTreeValue: dispatch.api.updateCurClickTreeValue,
})

export default connect(mapState, mapDispatch)(ApiDetail)
