// @file 数据查询
import React from 'react'
import BreadCrumbs from '@common/BreadCrumbs'
import Tabs from '@common/Tabs'
import {hasPriv} from '@components/FrontendAuth/AuthMap'
import DataList from './DataList'
import ExportRecord from './ExportRecord'
import _ from "lodash";

const menu = [
    {
        title: '数据列表',
        path: '/datalist',
        component: DataList,
        priv: 'getTablecfgList',
    }, {
        title: '导出记录',
        path: '/exportrecord',
        component: ExportRecord,
        priv: 'exportTaskItems'
    }
]

class DataSearch extends React.PureComponent {

    state = {
        curMenuIndex: 0
    }

    componentDidMount() {
        const search = _.get(this, 'props.location.search')
        // 回到导出记录tab
        if (!!search && search.indexOf('tab=Record')) this.setState({curMenuIndex: 1})
    }

    render() {

        let {curMenuIndex} = this.state
        const hasPrivMenu = menu.filter(i => hasPriv(i.priv))

        return (
            <>
                <div>
                    <BreadCrumbs
                        history={this.props.history}
                        data={[
                            {
                                title: '数据加油站',
                                path: '/admin/dataGasStation/dataSearch'
                            }, {
                                title: '明细查询'
                            }
                        ]}
                    />
                </div>
                <div style={{
                    padding: '0 20px',
                    backgroundColor: '#fff'
                }}>
                    <Tabs
                        menu={hasPrivMenu}
                        curMenuIndex={curMenuIndex}
                        {...this.props}
                    />
                </div>
            </>

        )
    }
}

export default DataSearch
