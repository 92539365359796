// 表基本信息
import React from 'react'
import SdwCard from '@common/Card'
import { LEVEL_TEXT_MAP } from '../../common'

export default props => {

  const columns = [{
    title: '数据库/索引',
    dataIndex: 'database_name'
  }, {
    title: '表名/类型后缀',
    dataIndex: 'pattern'
  }, {
    title: '中文名称',
    dataIndex: 'data_set_chinese'
  }, {
    title: '仓库类型',
    dataIndex: 'data_set_type'
  }, {
    title: '仓库层级',
    render: data => (
      <span>{LEVEL_TEXT_MAP[data.warehouse_level]}</span>
    )
  // }, {
  //   title: 'IP地址',
  //   dataIndex: 'address'
  }, {
    title: '主题/业务',
    render: data => (
      <span>{`${data.topic}/${data.business}`}</span>
    )
  }, {
    title: '负责人',
    dataIndex: 'owner'
  }
  // , {
  //   title: '修改人',
  //   dataIndex: 'modify_user'
  // }, {
  //   title: '修改时间',
  //   dataIndex: 'modify_time'
  // }
]

  return (
    <>
      <SdwCard
        data={props.record}
        columns={columns}
        headerTitle='基本属性'
        lableWidth={96}
      />
    </>
  )
}