import React, { PureComponent } from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import { storeNewField, getBusinessStatisticByTopic } from '@services/dataStandars'
import SdwMessage from '@common/Message'

import 'element-theme-default'
import './style/editDataStandardWin.css'

class DataStandardWin extends PureComponent {

  state = {
    standard_type: '',
    topic: '',
    business_id: '',
    field_name: '',
    field_name_ch: '',
    field_define: '',
    label: '',
    field_format: '',
    field_type: '',
    owner: '',
    field_range: '',
    secret_level_id: 0,
    is_valid: 0,
    is_dim: 0,
    sholdCheckValidate: false,
    businessData: [],
  }

  componentDidMount () {
    this.initData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      prevProps.record.id !== this.props.record.id ||
      !this.props.visible && prevProps.visible !== this.props.visible
    ) {
      this.initData()
    }

    let { topic } = this.state

    if (prevState.topic !== topic && !!topic) {
      getBusinessStatisticByTopic({
        id: topic
      })
        .then(res => {
          if (res && res.data && res.data.code === 0) {
            let topicArr = res.data.data && res.data.data.list.map(i => {
              return {
                id: i.business_id,
                text: i.business_name
              }
            }) || []
            this.setState({
              businessData: topicArr
            })
          }
        })
    }
  }

  initData = () => {
    this.setState({
      standard_type: this.props.record.standard_type,
      topic: this.props.record.topic,
      business_id: this.props.record.business_id,
      field_name: this.props.record.field_name,
      field_name_ch: this.props.record.field_name_ch,
      field_define: this.props.record.field_define,
      label: this.props.record.label,
      field_format: this.props.record.field_format,
      field_type: this.props.record.field_type,
      owner: this.props.record.owner,
      field_range: this.props.record.field_range,
      sholdCheckValidate: false,
      is_valid: this.props.record.enable || Number(!!this.props.record.id),
      secret_level_id: this.props.record.is_sensitive,
      is_dim: this.props.record.is_dim
    })
  }

  validateFun = (curValue, label = '') => {
    if (typeof curValue === 'number' || !!curValue.trim()) {
      return true
    }
    return `${label}不能为空`
  }

  handleSureClick = () => {
    let {
      standard_type,
      topic,
      field_name,
      field_name_ch,
      field_define,
      owner,
      field_range,
      field_format,
      label,
      field_type,
      business_id,
      secret_level_id,
      is_valid,
      is_dim,
    } = this.state

    let isValidatePass =
      typeof standard_type === 'number' &&
      typeof topic === 'number' &&
      !!field_name &&
      !!field_name_ch &&
      !!field_define &&
      !!owner

    if (!isValidatePass) {

      // 手动触发必填项的检查
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    let id = this.props.record.id || 0

    // ajax
    storeNewField({
      field_name,
      field_name_ch,
      field_define,
      field_range,
      field_type,
      field_format,
      topic,
      owner,
      label,
      business_id,
      standard_type,
      id,
      is_sensitive: secret_level_id,
      enable: is_valid,
      is_dim
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let msg = id === 0 ? '新建成功' : '编辑成功'
          SdwMessage.success(msg)

          // 通知父级重新拉数据
          if (typeof this.props.handleRefresh === 'function') {
            this.props.handleRefresh()
          }
        } else {
          SdwMessage.error(res.data.msg);
        }
      })

    // 告诉父级点击了确定按钮
    this.props.onSureClick()
  }

  render () {
    let {
      standard_type,
      topic,
      business_id,
      field_name,
      field_name_ch,
      field_define,
      label,
      field_format,
      field_type,
      owner,
      field_range,
      secret_level_id,
      is_valid,
      is_dim,
      sholdCheckValidate,
      businessData,
    } = this.state

    let typeData = this.props.standardOption.map(i => {
      return {
        id: i.id,
        text: i.standard_type
      }
    })
    let topicData = this.props.topicOption.map(i => {
      return {
        id: i.id,
        text: i.topic_name
      }
    })

    return (
      <SdwDrawer
        title={this.props.title}
        visible={this.props.visible}
        onIconClick={this.props.onIconClick}
        onCancelClick={this.props.onCancelClick}
        onSureClick={this.handleSureClick}
      >
        <SdwDrawer.Body>
          <div className="data-standard-win-wrap">
            <div>
              <span className="label required">标准类型</span>
              <SdwSelect
                placeholder="请选择"
                clearable={false}
                data={typeData}
                value={standard_type}
                disabled={!!this.props.record.id}
                validateFun={value => this.validateFun(value, '类型')}
                sholdCheckValidate={sholdCheckValidate}
                onChange={val => this.setState({standard_type: val})}
              />
            </div>
            <div>
              <span className="label required">字段英文名</span>
              <SdwInput
                value={field_name}
                validateFun={value => this.validateFun(value, '英文名')}
                sholdCheckValidate={sholdCheckValidate}
                onChange={val => this.setState({field_name: val})}
              />
            </div>
            <div>
              <span className="label required">字段中文名</span>
              <SdwInput
                value={field_name_ch}
                validateFun={value => this.validateFun(value, '中文名')}
                sholdCheckValidate={sholdCheckValidate}
                onChange={val => this.setState({field_name_ch: val})}
              />
            </div>
            <div>
              <span className="label required">定义</span>
              <SdwInput
                type="textarea"
                value={field_define}
                validateFun={value => this.validateFun(value, '定义')}
                sholdCheckValidate={sholdCheckValidate}
                onChange={val => this.setState({field_define: val})}
              />
            </div>
            <div>
              <span className="label required">主题</span>
              <SdwSelect
                placeholder="请选择"
                clearable={false}
                data={topicData}
                value={topic}
                validateFun={value => this.validateFun(value, '主题')}
                sholdCheckValidate={sholdCheckValidate}
                onChange={val => this.setState({topic: val})}
              />
            </div>
            <div>
              <span className="label">业务</span>
              <SdwSelect
                placeholder={!topic ? '请先选择主题，再选择业务' : '请选择'}
                clearable={false}
                disabled={!topic} // 先选择主题，在选择业务
                data={businessData}
                value={business_id}
                onChange={val => this.setState({business_id: val})}
              />
            </div>
            <div>
              <span className="label">标签</span>
              <SdwInput
                value={label}
                onChange={val => this.setState({label: val})}
              />
            </div>
            <div>
              <span className="label">格式</span>
              <SdwInput
                value={field_format}
                onChange={val => this.setState({field_format: val})}
              />
            </div>
            <div>
              <span className="label">数据类型</span>
              <SdwInput
                value={field_type}
                onChange={val => this.setState({field_type: val})}
              />
            </div>
            <div>
              <span className="label required">负责人</span>
              <SdwInput
                value={owner}
                validateFun={value => this.validateFun(value, '负责人')}
                sholdCheckValidate={sholdCheckValidate}
                onChange={val => this.setState({owner: val})}
              />
            </div>
            <div>
              <span className="label">值约束</span>
              <SdwInput
                value={field_range}
                onChange={val => this.setState({field_range: val})}
              />
            </div>
            <div>
              <span className="label">敏感级别</span>
              <SdwSelect
                clearable={false}
                data={[
                  {
                    id: 1,
                    text: '低'
                  }, {
                    id: 2,
                    text: '中'
                  }, {
                    id: 3,
                    text: '高'
                  }
                ]}
                value={secret_level_id}
                onChange={val => this.setState({secret_level_id: val})}
              />
            </div>
            <div>
              <span className="label">有效性</span>
              <SdwSelect
                clearable={false}
                data={[
                  {
                    id: 0,
                    text: '无效'
                  }, {
                    id: 1,
                    text: '有效'
                  }
                ]}
                value={is_valid}
                onChange={val => this.setState({is_valid: val})}
              />
            </div>
            <div style={{ marginBottom: '12px' }}>
              <span style={{ lineHeight: '40px' }} className="label">是否作为维度</span>
              <SdwSwitch
                  value={Boolean(is_dim)}
                  changeValue={val => this.setState({is_dim: +val})}
              />
            </div>
            <div style={{ fontSize: '12px', color: '#999' }}>启用后，该标准对应的字段可作为指标加工的维度</div>
          </div>
        </SdwDrawer.Body>
      </SdwDrawer>
    )
  }
}

export default DataStandardWin
