import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwDialog from '@common/Dialog'
import SdwMessage from '@common/Message'
import './style/index.css'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import AddWin from '@pages/Knowledge/MidTable/AddWin'
import JobPublish from '@pages/Knowledge/MidTable/JobPublish'
import PublishDetail from '@pages/Knowledge/MidTable/PublishDetail'
import AddSource from '@pages/Knowledge/MidTable/AddSource'
import StepList from '@pages/Knowledge/MidTable/stepList'
import TableList from '@pages/Knowledge/MidTable/TableList'
import { saveTable, tablePages, getDetailTreeInfo, getDetailInfo, getPublicCfg, jobPublish, jobDisable } from '@api/midTable'
import TableView from '@pages/Knowledge/MidTable/TableView'

class EditMidTable extends React.Component {

  state = {
    editMidTable:{},
    sholdCheckSpecialCodeValidate: false,
    showAddWin:false,
    showPublishDialog:false, //发布弹框
    showDetailDialog:false, //发布详情弹框
    showStopDialog:false, //停止弹框
    showAddSource:false, //添加数据源的visible
    clickDataSource:{}, //当前选择的数据源  为空则说明未选择任何一个数据源
    isEdit:false,
  }

  componentDidMount () {
    this.initData()
    this.getPublicCfgs()
  }

  initData = () => {
    let id = this.props.location.state && this.props.location.state.id || ''
    // console.log("EditMidTable======this.props.location", this.props.location.state)
    if (!!id) {
      this.iniEditMidTable(id)
      //this.iniTableViewTree(id)
      this.iniTableViewList(id)
    }
  }
  getPublicCfgs = () => {
    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }
        this.props.updateCfgData(cfgData)
      }
    })
  }

  //初始化加工视图 不使用
  iniTableViewTree = (id) => {
    getDetailTreeInfo({
      id:id,
    }).then(res => {
      if (res.data && res.data.code === 0) {
        this.props.updateTreeInfo(res.data.data)
      } else {
        let data = {
          list:null,
          tree:null
        }
        this.props.updateTreeInfo(data)
      }
    }, () => SdwMessage.error("接口异常"))
  }
//初始化加工视图
  iniTableViewList = (id) => {
    getDetailInfo({
      id:id,
    }).then(res => {
      if (res.data && res.data.code === 0) {
        this.props.updateDetailInfo(res.data.data)
      }
    }, () => SdwMessage.error("接口异常"))
  }

  changeDialogVisible = (type, flag) => {
    switch (type) {
      case "showAddSource":
        this.setState({
          showAddSource: flag
        })
        break
      case "showAddWin":
        this.setState({
          showAddWin: flag
        })
        break
      case "showPublishDialog":
        this.setState({
          showPublishDialog: flag
        })
        break
      case "showDetailDialog":
        this.setState({
          showDetailDialog: flag
        })
        break
      case "showStopDialog":
        this.setState({
          showStopDialog: flag
        })
        break
    }

  }
  //代码型提交
  onSubmitSpecialCode = () => {
    let { editMidTable} = this.state
    // console.log("EditMit============onSubmitSpecialCode=====editMidTable", editMidTable)
    if (!editMidTable.special_code) {
      this.setState({
        sholdCheckSpecialCodeValidate:true
      })
      return
    }

    let job = {
      job_name:editMidTable.job_name,
      schedule_time:editMidTable.schedule_time,
      exec_cycle:editMidTable.exec_cycle,
      job_type:editMidTable.job_type,
      enabled: 1,
      max_retries: 2,
      special_code: editMidTable.special_code
    }
    saveTable({
      id:editMidTable.id,
      job
    }).then(res => {
      if (res.data && res.data.code === 0) {
        SdwMessage.success("修改成功")
        this.iniEditMidTable(editMidTable.id)
      } else {
        let msg = res.data && res.data.data
        SdwMessage.error(msg);
      }
    }, () => SdwMessage.error("接口异常"))
  }

  //编辑中间表任务之后需要重置当前的editMidTable数据
  iniEditMidTable = (id) => {
    //let editMidTable = this.state.editMidTable
    tablePages({
      search: {
          id: {
            value: id,
            operator: '='
          }
      }
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const midTableList = _.get(res, 'data.data')
        if (midTableList.total == 1) {
          this.setState({
            editMidTable: midTableList.items[0]
          })
        } else {
          SdwMessage.err("参数异常")
        }
      } else {
        const msg = _.get(res, 'data.msg') || '接口异常'
        SdwMessage.err(msg)
      }
    })
  }
  //TableView-----点击节点操作
  tableViewClickNode = (item) => {
    // console.log("tableViewClickNode=========item", item)
    this.setState({
      clickDataSource:item
    })
  }
  //TableView-----点击编辑节点操作
  editTableViewNode = (item) => {
    let currentSource = {}
    // console.log("tableViewClickNode=========item", item)
    if (item.item.table_type == 0) {
      currentSource = {
        id:item.item.id,
        job_id:item.item.job_id,
        table_type:0, //节点类型：-1为根表，0为物理表，1为视图
        data_set_type:item.item.data_set_type,
        table_id: item.item.table_id,       //物理表数据源id
        filter: item.item.filter == null ? "" : item.item.filter,   //物理表过滤条件
        time_field: item.item.time_field ? item.item.time_field.split(";").map(i => {
          return {id:i, text: i}
        }) : [], //物理表取数时间字段
        get_time_cycle: item.item.get_time_cycle,       //物理表取数范围
        groupby_key: item.item.groupby_key,
        enabled: 1,
        table_name:item.item.table_name, //视图名称
        fields_conf:[]
      }
    } else {
      let link = item.item.link
      currentSource = {
        id: item.item.id,  // 视图节点id，新增不传，编辑传
        after_filter:item.item.after_filter,
        job_id:item.item.job_id,
        table_type:1, //节点类型：-1为根表，0为物理表，1为视图
        view_type:item.item.view_type, //0多表关联，1单表聚合，2分桶聚合
        table_name: item.item.table_name, //视图名称
        link_id: link.id,
        concat_type: link.concat_type, //关联类型，有左关联join和合并union
        left_node_id: link.left_node_id,   //主表字段id
        right_node_id: link.right_node_id || "", //副表节点id
        left_field: link.left_field ? link.left_field .split(";").map(i => {
          return {id:i, text: i}
        }) : [],
        right_field: link.right_field ? link.right_field .split(";").map(i => {
          return {id:i, text: i}
        }) : [],
        groupby_key: item.item.groupby_key || "", //聚合字段
        enabled: 1, //默认有效
      }
    }

    // console.log("tableViewClickNode=========item", item)
    // console.log("tableViewClickNode=========currentSource", currentSource)
    this.props.updateCurrentSource(currentSource)
    this.setState({
      isEdit:true
    })
    this.changeDialogVisible("showAddSource", true)
  }
  //停止任务
  stopTask =() => {
    let {editMidTable} = this.state
    jobDisable({
      id:editMidTable.id
    }).then(res => {
      if (res.data && res.data.code === 0) {
        this.changeDialogVisible("showStopDialog", false)
        SdwMessage.success("停止成功")
        this.iniEditMidTable(editMidTable.id)
      } else {
        let msg = res.data && res.data.data
        SdwMessage.error(msg);
      }
    }, () => SdwMessage.error("接口异常"))
  }
  render () {

    let {
      editMidTable,
      comfirmVisible,
      showStopDialog,
      showAddWin,
      showPublishDialog,
      showDetailDialog,
      showAddSource,
      clickDataSource,
      sholdCheckSpecialCodeValidate,
      isEdit,
    } = this.state
    return (
      <div className="sdw-application-detail__wrap">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '数据采集地',
              path: '/admin/dataCollect/logManagement'
            }, {
              title: '中间表管理',
              path: '/admin/dataCollect/midTable'
            }, {
              title: '编辑中间表',
            },
          ]}
        />

        <div className="sdw-application-detail__title-wrap">
          <span>{`${editMidTable.job_name}`}</span>
          <span
            className="el-button--text"
            style={{marginLeft: 20}}
            onClick={()=>this.changeDialogVisible("showAddWin", true)}
          >修改</span>

          {
            editMidTable.publish_state == 0 ? <span className="sdw-editmid__title-wrap__right">
              <span>
                <button
                  className="publish"
                  onClick={()=>this.changeDialogVisible("showPublishDialog", true)}
                >发布</button>
              </span>
            </span> :
              <span
                className="sdw-editmid__title-wrap__right">
              <span>
                <span
                  className="el-button--text"
                  onClick={()=>this.changeDialogVisible("showDetailDialog", true)}
                >发布详情</span>
                <button
                  className="stop"
                  onClick={()=>this.changeDialogVisible("showStopDialog", true)}
                >停止</button>
              </span>
          </span>
          }

        </div>

        <div
          className="sdw-edit_mid_table__center-wrap"
          style={
            editMidTable.job_type == 0 ? {
              height: document.body.offsetHeight - 300,
            } : {
              height: document.body.offsetHeight - 300,
              background: "#FFFFFF"
            }
            }
        >
          <div className="sdw-edit_mid_table__center-wrap-body-title">
            <span className="sdw-edit_mid_table__center-wrap-body-title-word">中间表加工视图</span>
            {
              editMidTable.job_type == 0 &&
              <span onClick={()=>{
                this.props.updateCurrentSource({})
                this.setState({
                  isEdit:false
                })
                this.changeDialogVisible("showAddSource", true)
              }} className="sdw-edit_mid_table__center-wrap-body-title-button">
                <span style={{paddingBottom:"5px"}}>+</span> 添加数据源
              </span>
            }
          </div>
          {/*中间表为配置型*/}
          {
            editMidTable.job_type == 0 && <div>
              <div className="mid_table_view">
                <TableView
                  tableViewClickNode={this.tableViewClickNode}
                />
              </div>
              <div className="mid_table_body">
                <div className="left-box">
                  <StepList />
                </div>
                <div className="right-box">
                  <TableList
                    iniTableViewList={this.iniTableViewList}
                    clickDataSource={clickDataSource}
                    editTableViewNode={this.editTableViewNode}
                  />
                </div>
              </div>
            </div>
          }
          {/*中间表为代码型*/}
          {
            editMidTable.job_type == 1 && <div>
              <div className="center_job_type_1">
                <div className="formInput">
                  <span className="label required">加工函数</span>
                  <SdwInput
                  width={565}
                  type="textarea"
                  value={editMidTable.special_code}
                  size="large"
                  rows={5}
                  autosize={{ minRows: 8, maxRows: 4}}
                  placeholder=""
                  sholdCheckValidate={sholdCheckSpecialCodeValidate}
                  validateFun={val=>{return !!val?true:"加工函数不能为空"}}
                  onChange={val => this.setState({
                    editMidTable: Object.assign({}, editMidTable, {special_code:val})
                  })}
                />
                </div>
                <div className="formInput">
                  <span className="label1"></span>
                  <button onClick={this.onSubmitSpecialCode} style={{backgroundColor:"#265CF0", color:"#FFFFFF"}}>
                    保存
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
        {
          showAddWin &&
          <AddWin
            isEdit={true}
            title={"编辑中间表任务" }
            showAddWin={showAddWin}
            editMidTable={editMidTable}
            changeShowAddWin={bool=>this.changeDialogVisible("showAddWin", bool)}
            updatePage={this.iniEditMidTable}
          />
        }
        {
          showPublishDialog &&
          <JobPublish
            showPublishDialog={showPublishDialog}
            editMidTable={editMidTable}
            changeShowAddWin={bool=>this.changeDialogVisible("showPublishDialog", bool)}
            updatePage={this.iniEditMidTable}
          />
        }
        {
          showDetailDialog &&
          <PublishDetail
            showDetailDialog={showDetailDialog}
            editMidTable={editMidTable}
            changeShowAddWin={bool=>this.changeDialogVisible("showDetailDialog", bool)}
          />

        }

        {
          showAddSource &&
          <AddSource
            isEdit={isEdit}
            showAddSource={showAddSource}
            editMidTable={editMidTable}
            changeShowAddSource={bool=>this.changeDialogVisible("showAddSource", bool)}
            iniTableViewList={this.iniTableViewList}
          />
        }

        <SdwDialog
          visible={showStopDialog}
          title='停止任务'
          width={480}
          height={180}
          changeVisible={(flag)=>{
            this.changeDialogVisible("showStopDialog", flag)
          }}
          onCancelClick={ ()=>{
            this.changeDialogVisible("showStopDialog", false)
          }}
          onSureClick={this.stopTask}
        >
          <span style={{
            fontSize: 14,
            display: 'inline-block'
          }}>停止后任务将变更为未发布状态，确认停止？</span>
        </SdwDialog>
        <SdwDialog
          hideHeader={true}
          visible={comfirmVisible}
          width={480}
          height={180}
          changeVisible={ this.props.changeComfirmVisible }
          onCancelClick={ this.hanlderChangeTreeCancel }
          onSureClick={ this.hanlderChangeTreeSubmit }
        >
          <span style={{
            fontSize: 18,
            marginTop: 36,
            display: 'inline-block'
          }}>是否保存对该指标的修改</span>
        </SdwDialog>
      </div>
    )
  }
}

const mapState = state => ({
  treeInfo: state.midTableView.treeInfo,
  detailInfo: state.midTableView.detailInfo,
  currentSource: state.midTableView.currentSource,
  cfgData: state.midTableView.cfgData,
})

const mapDispatch = dispatch => ({
  updateTreeInfo:dispatch.midTableView.updateTreeInfo,
  updateDetailInfo:dispatch.midTableView.updateDetailInfo,
  updateCurrentSource:dispatch.midTableView.updateCurrentSource,
  updateCfgData:dispatch.midTableView.updateCfgData,
})
export default connect(mapState, mapDispatch)(EditMidTable)
