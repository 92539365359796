import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

class DropDown extends React.PureComponent {

  static defaultProps = {
    menu: [],
    value: '',
    changeValue: () => ({}),
    showTitle: false, // 该值设置为 true 时，所有的选择项都有悬浮提示
    showTitleMaxNumber: 20
  }

  state = {
    showMenuList: false
  }

  render () {

    let { menu, value, changeValue, listMaxWidth, showTitle, showTitleMaxNumber } = this.props
    let { showMenuList } = this.state

    let titleClassName = 'sdw__drop-down__menu-title ellipsis'
    if (showMenuList) titleClassName += ' show_menu_list'

    return (
      <div 
      className="sdw__drop-down__menu-wrap"
        onMouseEnter={() => this.setState({ showMenuList: true })}
        onMouseLeave={() => this.setState({ showMenuList: false })}
      >
        <span 
          className={titleClassName}
          style={{ ...(!!listMaxWidth ? {maxWidth: listMaxWidth} : {}) }}
        >{value || menu[0]}</span>
        {
          showMenuList &&
          <div 
            className="sdw__drop-down__menu-list"
            onMouseEnter={() => this.setState({ showMenuList: true })}
          >
            {
              Array.isArray(menu) &&
              !!menu.length &&
              menu.map(item => (
                <div
                  key={item}
                  title={(showTitle || String(item).length > showTitleMaxNumber) ? item : ''}
                  className={value === item ? 'current ellipsis' : 'ellipsis'}
                  style={{ ...(!!listMaxWidth ? {maxWidth: listMaxWidth} : {}) }}
                  onClick={() => {
                    this.setState({ showMenuList: false })
                    if (typeof changeValue === 'function') {
                      changeValue(item)
                    }
                  }}
                >{item}</div>
              ))
            }
          </div>
        }
      </div>
    )
  }
}

DropDown.propTypes = {
  menu: PropTypes.array,
  value: PropTypes.string,
  changeValue: PropTypes.func,
  listMaxWidth: PropTypes.number,
  showTitle: PropTypes.bool, // 该值设置为 true 时，所有的选择项都有悬浮提示
  showTitleMaxNumber: PropTypes.number, // 转为字符串后长度大于 showTitleMaxNumber 的时候，显示悬浮提示 
}

export default DropDown