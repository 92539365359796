import React, {PureComponent} from 'react'
import _ from 'lodash'
import Drawer from '@common/Drawer'
import Input from '@common/Input'
import Switch from '@common/Switch'
import Select from '@common/Select'
import './EditWin.css'
import { getStaffs } from '@api/alertRules'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import ConfigRule from '@pages/Knowledge/DataManagement/ConfigRule'
import { pageTargetsGroup, saveFocusUser } from '@api/keyIndicatorAllocation'
import SdwMessage from '@common/Message'

class EditWin extends PureComponent {

    state = {
        focusedList: [],
        allMembers:[],
        formData: {
            "rtx_name": "",
            "enabled": 0
        },
        targetsGroupList:[],
        sholdCheckValidate:false,
    }

    componentDidMount() {
        this.getStaffs()
        this.iniData()
        this.pageTargetsGroup()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.record != this.props.record) {
        }
    }

    iniData = () => {
        let {record, isEdit} = this.props
        console.log("iniData=====record", record)
        if (isEdit) {
            let focused_list = []
            let n = 0
            record.user_focused_list.forEach(i => {
                n = n + 1
                focused_list.push({queue: n, name: parseInt(i)})
            })
            this.setState({
                formData:{
                    "rtx_name": record.rtx_name,
                    "enabled": record.enabled
                },
                focusedList:focused_list
            })
        }
    }

    getStaffs = () => {
        getStaffs().then(res => {
            if (res && res.data && res.data.code === 0) {
                let list = _.isArray(res.data.data) ? res.data.data : []
                list.push({name:"default_focus", value:"default_focus"})
                let allMembers = list.map(i => {
                    return {id:i.value, text:i.name, item:i}
                })
                this.setState({
                    allMembers: allMembers
                })
            }
        })
    }

    pageTargetsGroup = () => {
        let params = {
            limit : 1000
        }
        pageTargetsGroup (params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let list = _.isArray(res.data.data.items) ? res.data.data.items : []
                console.log("==targetsGroupList", res.data.data)
                console.log("==targetsGroupList", res.data.data.items)
                console.log("==targetsGroupList", list)
                this.setState({
                    targetsGroupList:list.map(i => {
                        return {id:i.id, text:i.group_name}
                    })
                })
            }
        })
    }


    //删除分组
    removeFocusedList = (index) => {
        let { focusedList } = this.state
        let curArr = _.cloneDeep(focusedList)
        let lastIndex = curArr.length - 1
        curArr.splice(index, 1)

        // 删除的不是最后一项，需要更新删除下面的序号
        if (lastIndex !== index) {
            curArr = curArr.map((item, k) => {
                if (k < index) {
                    return item
                } else {
                    return _.assign({}, item, {
                        queue: item.queue - 1
                    })
                }
            })
        }

        this.setState({ focusedList: curArr })
    }
    //添加分组
    addFocusedList = () => {
        let { focusedList } = this.state
        let curArr = _.cloneDeep(focusedList)
        curArr.push(_.assign({},  {queue: 0, name: ""}, {
            queue: focusedList.length + 1
        }))
        this.setState({ focusedList: curArr })
    }

    changeFocusedList = (index, type, val) => {
        let { focusedList } = this.state
        let curArr = _.cloneDeep(focusedList)

        if (type === 'queue') {
            let beforeVal = Number(index - 1)
            let currentVal = Number(val - 1)

            if (beforeVal < currentVal) {
                for (let j = beforeVal + 1; j <= currentVal; j++) {
                    curArr[j]['queue'] = curArr[j]['queue'] - 1
                }
            } else if (beforeVal > currentVal) {
                for (let j = beforeVal - 1; j >= currentVal; j--) {
                    curArr[j]['queue'] = curArr[j]['queue'] + 1
                }
            }
        }

        curArr[index - 1][type] = val

        this.setState({ focusedList: curArr })
    }

    setFormValue = (type, val) => {
        let {formData} = this.state
        let obj = {}
        switch (type) {
            case "rtx_name":
                obj = {rtx_name:val}
                break
            case "enabled":
                obj = {enabled:val}
                break

        }
        this.setState({
            formData : _.assign({}, formData, {
                ...obj
            })
        })
    }

    onSubmit = () => {
        let {formData, focusedList} = this.state
        let {isEdit} = this.props
        if (!formData.rtx_name || focusedList.length == 0) {
            this.setState({
                sholdCheckValidate:true
            })
            return
        }
        let params = {
            "rtx_name": formData.rtx_name,
            "user_focused_list": focusedList.map(i => {
                return i.name
            }),    // 指标组id数组
            "enabled": formData.enabled
        }
        saveFocusUser (params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let msg = "新增成功"
                if (isEdit) {
                    msg = "编辑成功"
                }
                SdwMessage.success(msg)
                this.props.updatePage()
                this.props.setShowEditWin(false)
            } else {
                SdwMessage.error("接口调用失败")
            }
        })
    }

    render() {

        let {
            focusedList,
            formData,
            allMembers,
            targetsGroupList,
            sholdCheckValidate
        } = this.state

        let {
            isEdit, showEditWin, setShowEditWin, updatePage, record
        } = this.props

        console.log("--------targetsGroupList", targetsGroupList)
        console.log("--------focusedList", focusedList)
        let targetsGroupListClone = targetsGroupList.map(i => {
            for (let j in focusedList) {
                if (focusedList[j].name == i.id) {
                    return {id:i.id, text:i.text, disabled:true}
                }
            }
            return {id:i.id, text:i.text, disabled:false}
        })

        return (
            <Drawer
                title={`${isEdit ? '编辑' : '新增'}关注人`}
                visible={showEditWin}
                onIconClick={() => setShowEditWin(false)}
                onCancelClick={() => setShowEditWin(false)}
                onSureClick={this.onSubmit}
            >
                <Drawer.Body>
                    <div className='form data-editor-win-wrap'>

                        <div className='formInput-selectWin'>
                            <span className='label required'>姓名</span>
                            <div className='content'>
                                <SdwSelect
                                  value={formData.rtx_name}
                                  width='79%'
                                  readOnly={false}
                                  // listMaxHeight={100} // 下拉展示限制最高高度
                                  // listWidth={300} // 下拉展示的宽度，不设置和输入框宽度一样
                                  sholdCheckValidate={sholdCheckValidate}
                                  validateFun={val => {
                                      if (!val) {
                                          return '姓名不能为空！'
                                      }
                                      return true
                                  }}
                                  data={allMembers}
                                  onChange={val => this.setFormValue("rtx_name", val)}
                                />
                            </div>
                        </div>

                        <div className='formInput-selectWin'>
                            <span className='label required'>当前关注</span>
                            <div className='content'>
                                <div className='form-add'>
                                    <span className='edit-content'>
                                        {
                                            focusedList.length == 0 && <span
                                                className='add-config-rule-btn'
                                                onClick={() => this.addFocusedList()}
                                            >添加模块</span>
                                        }
                                        {
                                            !!focusedList.length &&
                                            focusedList.sort((a, b) => a.queue - b.queue).map((item, index) => {
                                                let dataList = focusedList.map((i, index1) => {
                                                    return {
                                                        id: index1 + 1,
                                                        text: index1 + 1
                                                    }
                                                })

                                                return (
                                                  <div key={'focusedList' + index} className='content-one'>
                                                        <span className='editorTwo-input-map left body-left'>
                                                            <Select
                                                              value={item.queue}
                                                              data={dataList}
                                                              onChange={val => this.changeFocusedList(index + 1, 'queue', val)}
                                                              width='18%'
                                                            />
                                                        </span>
                                                      <span>
                                                          <SdwSelect
                                                            value={item.name}
                                                            placeholder={"请选择指标组"}
                                                            readOnly={false}
                                                            validateFun={value =>!value ? "指标组不能为空" : true}
                                                            sholdCheckValidate={sholdCheckValidate}
                                                            onChange={val => this.changeFocusedList(index + 1, 'name', val)}
                                                            data={targetsGroupListClone}
                                                          />
                                                        </span>

                                                      {(index+1) == focusedList.length && (<i onClick={() => this.addFocusedList(index)}
                                                                    className='el-icon-plus'></i>)}
                                                      <i onClick={() => this.removeFocusedList(index)}
                                                         className='el-icon-minus'></i>
                                                  </div>
                                                )
                                            })
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <span className='label'>是否启用</span>
                            <Switch
                                value={formData.enabled}
                                changeValue={val =>  this.setFormValue("enabled", val)}
                            />
                        </div>

                    </div>
                </Drawer.Body>
            </Drawer>
        )
    }
}

export default EditWin
