import { PureComponent } from "react"
import _ from 'lodash'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwTabs from '@common/Tabs'
import TableTempConfig from './TableTempConfig'
import BusinessDataUpload from './BusinessDataUpload'
import BusinessDataUploadRecord from './BusinessDataUploadRecord'
import { hasPriv } from '@components/FrontendAuth/AuthMap'
import NoRight from "@pages/NoRight"

export default class DataGovern extends PureComponent {

  state = {
    menu: [
      {
        title: '表模版列表',
        path: '/table_temp_config',
        component: TableTempConfig,
        priv: 'getAllModels'
      }, {
        title: '经分业务数据上传',
        path: '/business_data_upload',
        component: BusinessDataUpload,
        priv: 'getServiceConfig'
      }, {
        title: '经分业务数据上传记录',
        path: '/business_data_upload_record',
        component: BusinessDataUploadRecord,
        priv: 'getAllUploadRecord'
      }
    ]
  }

  render() {
    const { menu } = this.state
    const curMenu = _.isArray(menu) ? menu.filter(i => hasPriv(i.priv)) : []
    return (
      <>
        <SdwBreadCrumbs 
          history={this.props.history}
          data={[
            {
              title: '数据采集地',
              path: '/admin/dataCollect/dataManagement'
            }, {
              title: '手工上传'
            }
          ]}
        />

        {
          curMenu.length > 0 ?
          <SdwTabs 
            menu={curMenu}
            titleWidth={180}
          /> :
          <NoRight
            height={window.innerHeight - 200}
          />
        }
      </>
    )
  }
}