// 预警规则
import { request, requestYZF } from '../utils/request'

export function getAlarmCfgList (params) {
  return request({ url: '/statistics/fieldAlarm/items', method: 'get', params: params })
}

export function getAllTablecfg (params) {
  return request({ url: '/metadata/tableApi/getAllTablecfg', method: 'get', params: params })
}

export function getTablecfgFields (params) {
  return request({ url: '/metadata/tableApi/getTablecfgFields', method: 'get', params: params })
}

export function getStaffs (params) {
  return request({ url: '/admin/staff/getStaffs', method: 'get', params: params })
}

export function storeNewAppTable (params) {
  return request({ url: '/statistics/dataProcess/storeNewAppTable', method: 'post', data: params })
}

export function updateAlarmConf (params) {
  return request({ url: '/statistics/fieldAlarm/updateAlarmConf', method: 'post', data: params })
}

export function insertAlarmConf (params) {
  return request({ url: '/statistics/fieldAlarm/insertAlarmConf', method: 'post', data: params })
}

export function createTask (params) {
  return requestYZF({ url: '/mini/task_model/api_create_task', method: 'post', data: params })
}

export function getAlarmItemSelects (params) {
  return request({ url: '/statistics/fieldAlarm/alarmItemSelects', method: 'get', params: params })
}

export function alarmBusinessItemSelects (params) {
  return request({ url: '/statistics/fieldAlarm/alarmBusinessItemSelects', method: 'get', params: params })
}

export function channelItemSelects (params) {
  return request({ url: '/statistics/fieldAlarm/channelItemSelects', method: 'get', params: params })
}

export function getProblemTypeSelects (params) {
  return request({ url: '/statistics/fieldAlarm/getProblemTypeSelects', method: 'get', params: params })
}

export function storeProblemType (params) {
  return request({ url: '/statistics/fieldAlarm/storeProblemType', method: 'post', data: params })
}