import React, { Component } from 'react'

import SdwDialog from '@common/Dialog'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwMessage from '@common/Message'
import SdwLoading from "@common/Loadding";

import FieldCascader from "./common/Cascader";
import _ from "lodash";

import { getTablecfgList, getTablecfgFields } from '@services/dimen'
import { storeRelateTarget, getRelateTarget } from '@services/api'
import { formatToIdMap } from './common'

import './style/applicationSettingWin.css'

const fieldSettingObj = {
  dimensionTableMatchingFelds: {
    ids: [],
    texts: []
  }, //维度表匹配字段
  app_field: '',//应用表字段
  addField:[], //[{id:'',text:''},] 添加字段
  filter: '', //条件配置
}

function checkMaxLength(value) {
  if(value.length < 500){
    return true
  }
  return '条件配置输入最大500'
}

function checkNotEmpty(value) {
  if(value.ids.length === 2){
    return true
  }
  return '维度表匹配字段不能为空'
}

export default class ApiSettingWin extends React.Component {
  state={
    fieldSettingList: [_.cloneDeep(fieldSettingObj)],
    tableList: [],
    sholdCheckValidate:false,
    tableFields:[],
    loadding: false,
    oriList: [] // 原始数据
  }
  // 初始化
  componentDidMount() {
    this.onGetTablecfgList()
  }
  // 获取所有的表
  onGetTablecfgList = async () => {
    let that = this
    await getTablecfgList({ limit: 10000, page: 0, search: { enable: { value: '1', operator: "=" } } }).then(res => {
      let tableList = res.data.data.items || []
      tableList = tableList.map(i => {
        return {
          id: i.id,
          text: i.data_set_chinese + "(" + i.database_name + "." + i.table_name + ")"
        }
      })
      that.setState({tableList})
    })

    if (this.props.currentData && this.props.currentData.id) {
      await getRelateTarget({
        "api_id":this.props.currentData.api_id,
        "template_id":this.props.currentData.id
      }).then(res => {
        let code = _.get(res, 'data.code')
        let oriData = _.get(res, 'data.data')
        // console.log('________________res: ', code, oriData);
        if (code === 0 && !_.isEmpty(oriData)) {
          let oriList = oriData.map(ori => {
            let fieldName = that.state.tableList.filter(j => j.id == ori.relate_table_id)[0]
            fieldName = fieldName && fieldName.text || ''
            console.log("-----fieldName", fieldName)
            console.log("-----that.state.tableList", that.state.tableList)
            return {
              key: ori.key,
              dimensionTableMatchingFelds: {
                ids: [ori.relate_table_id, ori.relate_field],
                texts: [fieldName, ori.relate_field]
              },
              app_field: ori.app_field,
              addField: _.isArray(ori.target_names) ? ori.target_names.map(j => {
                return {
                  field_name: j,
                  field_name_ch: j,
                  id: j,
                  text: j
                }
              }) : [],
              filter: ori.filter
            }
          })

          this.setState({
            fieldSettingList: _.cloneDeep(oriList),
            oriList: _.cloneDeep(oriList) // 原始数据
          })
        }
      })
    }

  }
  addConfigRule = () => {
    let fieldSettingList = this.state.fieldSettingList
    let fieldSetting = _.cloneDeep(fieldSettingObj)
    this.setState({ fieldSettingList: [...fieldSettingList,fieldSetting] })
  }
  removeConfigRule = (index) => {
    let fieldSettingList = this.state.fieldSettingList
    if (fieldSettingList.length <= 1) return
    fieldSettingList.splice(index, 1)
    this.setState({ fieldSettingList: [...fieldSettingList] })
  }
  setFieldSettingProps = (val, index, prop) => {
    let fieldSettingList = [...this.state.fieldSettingList]
    fieldSettingList[index][prop] = val
    if(prop === 'dimensionTableMatchingFelds') {
      fieldSettingList[index].addField = []
    }
    this.setState({
      fieldSettingList
    })
  }
  getField = (item, selectItem,index) => {
    this.setFieldSettingProps(selectItem, index, 'dimensionTableMatchingFelds')
    return new Promise((resolve) => {
      let parame = {}
      parame.id = item.id
      this.setState({loadding:true})
      getTablecfgFields(parame).then(res => {
        let tableFields = res.data.data || []
        tableFields = tableFields.map(i => {
          return {
            id: i.field_name,
            text: i.field_name_ch? i.field_name + "(" + i.field_name_ch + ")" : i.field_name
          }
        })
        resolve(tableFields)
        this.setState({
          loadding:false
        })
      })
    })
  }
  checkFieldSetting = (fieldSettingList) => {
    return fieldSettingList.filter(el => el.dimensionTableMatchingFelds.ids.length === 2 && el.app_field !== '' && el.addField.length > 0)
  }

  getParams = item => {
    let { target_name_en, target_name_cn, relate_table_id, relate_field, app_field, filter } = item
    return {
      table_id: this.props.currentData.id,
      target_name_en,
      target_name_cn,
      target_type: 3, // 固定值
      relate_config: JSON.stringify({
        relate_table_id,
        relate_field,
        app_field,
        filter
      })
    }
  }

  handleSubmit = () => {
    let { fieldSettingList, oriList } = this.state
    let {currentData} = this.props
    let cloneList = _.cloneDeep(fieldSettingList)
    cloneList = this.checkFieldSetting(cloneList)
    if (cloneList.length === 0) {
      cloneList.push(_.cloneDeep(fieldSettingObj))
    }
    this.setState({
      fieldSettingList: cloneList
    })

    // 如果新数据和原始数据同，则跳出submit
    let isSame = _.isEqualWith(oriList, fieldSettingList, _.isEqual)
    if (isSame) {
      // 关闭弹窗
      this.props.changeDialogVisible(false)
      return
    }

    // console.log('cloneList: ', cloneList);
    // console.log('oriList: ', oriList);

    // 将 cloneList、oriList 变成以id为key的map，便于对比出增、删、改的类容
    let cloneListMap = []
    cloneList.forEach(i => {
      let cloneListMapItem = {
        target_names: [],
        relate_table_id: "",
        relate_field: "",
        app_field: i.app_field,
        filter: i.filter

      }
      cloneListMapItem["target_names"] = i.addField.map(fieldItem => {
        return fieldItem.field_name
      }) || []
      if (i.dimensionTableMatchingFelds.ids && i.dimensionTableMatchingFelds.ids[0]) {
        cloneListMapItem["relate_table_id"] = i.dimensionTableMatchingFelds.ids[0]
      }
      if (i.dimensionTableMatchingFelds.ids && i.dimensionTableMatchingFelds.ids[1]) {
        cloneListMapItem["relate_field"] = i.dimensionTableMatchingFelds.ids[1]
      }
      cloneListMap.push(cloneListMapItem)
    })
    let params = {
      "api_id":currentData.api_id,
      "template_id":currentData.id,
      "add":cloneListMap
    }
    this.onStoreRelateTarget(params)
    // 关闭弹窗
    this.props.changeDialogVisible(false)
  }


  onStoreRelateTarget = addArr => {
    console.log("-----addArr", addArr)
    //let relateConfig = JSON.parse(addArr["relate_config"])
    storeRelateTarget(addArr)
    .then(res => {
      let code = _.get(res, 'data.code')
      if (code === 0) {
        SdwMessage.success('新增成功')
      } else {
        SdwMessage.error('新增失败')
      }
    }, () => SdwMessage.error("接口异常"))
  }
  render() {
    let { dialogVisible, changeDialogVisible, } = this.props
    let { fieldSettingList, tableList, sholdCheckValidate,loadding } = this.state
    let {setFieldSettingProps,getField} = this
    return (
      dialogVisible &&
      <SdwDialog
        title={'字段设置'}
        visible={dialogVisible}
        changeVisible={changeDialogVisible}
        onCancelClick={() => changeDialogVisible(false)}
        onSureClick={this.handleSubmit}
      >
        <div className='application-setting-field-title'>字段设置</div>
        <div className='application-setting-field'>
          <SdwLoading loadding={loadding}>
            {
              fieldSettingList.map((el,index) => (
                <div key={el.key} className='application-setting-field-row-wrap'>
                  <div className='application-setting-field-row'>
                    <FieldCascader
                      placeholder="选择维度表匹配字段"
                      clearable={true}
                      ajaxFun={(item, selectItem) => getField(item, selectItem,index)}
                      data={tableList}
                      listWidth={390}
                      value={el.dimensionTableMatchingFelds}
                      // sholdCheckValidate={sholdCheckValidate}
                      // validateFun={checkNotEmpty}
                      onChange={(val) => setFieldSettingProps(val, index, 'dimensionTableMatchingFelds')}
                      width={390}
                    />
                    <div className='application-setting-field-margin-sty'>
                      <SdwInput
                        placeholder="API字段，输入表实际值"
                        clearable={true}
                        value={el.app_field}
                        onChange={val => setFieldSettingProps(val, index, 'app_field')}
                        width={200}
                      />
                    </div>
                    <div className='application-setting-field-margin-sty'>
                      <SdwInput
                        placeholder='条件配置'
                        clearable={true}
                        value={el.filter}
                        // sholdCheckValidate={sholdCheckValidate}
                        // validateFun={checkMaxLength}
                        onChange={val => setFieldSettingProps(val, index, 'filter')}
                        width={200}
                      />
                    </div>
                    { index > 0 && <div className="application-setting-field-delete iconfont icon-delete" onClick={this.removeConfigRule.bind(this,index)}></div>}
                  </div>
                  <div className='application-setting-field-bgc'>
                    <FieldSettingRow
                      fetchId={el.dimensionTableMatchingFelds.ids[0]}
                      value={el.addField}
                      onChange={val => setFieldSettingProps(val, index, 'addField')}
                    />
                    </div>
                </div>
              ))
            }
          </SdwLoading>

          <div className="application-setting-field-add" onClick={this.addConfigRule}>
            <div className='application-setting-field-add-icon iconfont icon-add'></div>
            添加配置规则
          </div>
        </div>
      </SdwDialog>
    )
  }
}


class FieldSettingRow extends Component {
  state={
    tableFields: []
  }
  componentDidMount () {
    this.onGetTablecfgFields()
  }
  componentDidUpdate(prevProps, prevState){
    let id = this.props.fetchId
    if(prevProps.fetchId !== id) this.onGetTablecfgFields()
  }
  onGetTablecfgFields = () => {
    let id = this.props.fetchId
    if (!id) return
    let parame = {}
    parame.id = id
    getTablecfgFields(parame).then(res => {
      let tableFields = res.data.data || []
      tableFields = tableFields.map(i => {
        return {
          id: i.field_name,
          field_name: i.field_name,
          field_name_ch: i.field_name_ch,
          text: i.field_name_ch? i.field_name + "(" + i.field_name_ch + ")" : i.field_name
        }
      })
      this.setState({
        tableFields
      })
    })
  }
  render () {
    let {value,onChange} = this.props
    let {tableFields} = this.state
    return (
      <SdwSelect
        placeholder="选择维度表添加字段"
        clearable={false}
        data={tableFields}
        isMultipleChoice={true}
        readOnly={false}
        value={value}
        onChange={val => onChange(val)}
        width={820}
        listWidth={820}
      />
    )
  }
}
