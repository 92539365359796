// 元数据管理
import { request } from '../utils/request'

// 获取 元数据 表数据
export function getMetaDataInfo (params) {
  return request({ url: '/metadata/tableApi/items', method: 'get', params: params })
}

// 获取 元数据 表信息 下拉选项
export function getAllTablecfg (params) {
  return request({ url: '/metadata/tableApi/getAllTablecfg', method: 'get', params: params })
}

// 编辑元数据，数据回显
export function getTablecfgById (params) {
  return request({ url: '/metadata/tableApi/getTablecfgById', method: 'get', params: params })
}

// 获取主题域等
export function getItermSelects (params) {
  return request({ url: '/statistics/dataMarket/getItermSelects', method: 'get', params: params })
}

// 根据主题域id获取 业务business
export function getBusinessStatisticByTopic (params) {
  return request({ url: '/statistics/dataMarket/getBusinessStatisticByTopic', method: 'get', params: params })
}

// 获取所有管理人员姓名
export function getStaffs (params) {
  return request({ url: '/admin/staff/getStaffs/', method: 'get', params: params })
}

// 编辑元数据
export function updateTablecfg (params) {
  return request({ url: '/metadata/tableApi/updateTablecfg', method: 'post', data: params })
}

// 新增元数据
export function storeTablecfg (params) {
  return request({ url: '/metadata/tableApi/storeTablecfg', method: 'post', data: params })
}

// 获取 数据预览 表数据
export function getItems (params) {
  return request({ url: '/metadata/tableApi/getItems', method: 'get', params: params })
}

// 获取 基本信息 表数据
export function getTablecfgDetail (params) {
  return request({ url: '/metadata/tableApi/getTablecfgDetail', method: 'get', params: params })
}

// 权限点校验
export function getTablecfgDetailPermission (params) {
  return request({ url: '/metadata/tableApi/getTablecfgDetailPermission', method: 'get', params: params })
}
// 权限点校验
export function getTablecfgDetailAuth (params) {
  return request({ url: '/metadata/tableApi/getTablecfgDetailAuth', method: 'get', params: params })
}

// 获取 基本信息 编辑数据回显
export function getTablecfgFieldById (params) {
  return request({ url: '/metadata/tableApi/getTablecfgFieldById', method: 'get', params: params })
}

// 获取 基本信息 编辑数据回显 获取所有的字段标准数据选项列表
export function getStandardSelects (params) {
  return request({ url: '/metadata/tableApi/getStandardSelects', method: 'get', params: params })
}

// 获取 基本信息 编辑数据回显 获取所有的字段标准数据选项列表
export function updateTableField (params) {
  return request({ url: '/metadata/tableApi/updateTableField', method: 'post', data: params })
}
//提交权限申请
export const applyTableAuth = (params = {}) => {
  return request({ url: '/metadata/tableApi/applyTableAuth', method: 'post', data: params })
}
