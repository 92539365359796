import { formatTime } from '@utils/formatTime'
import React from 'react'
import _ from 'lodash'
import SdwEmpty from '../../../common/Empty'
import SdwLoading from '@common/Loadding'

import './style/chatArea.css'

export default props => {
  const arrList = _.isArray(props.userInfoData) ? [...props.userInfoData] : []
  _.reverse(arrList)
  return (
    <SdwLoading loadding={!props.isLoad}>
      {
        !!arrList.length ?
        <div className="chat-area">
          {
            arrList.map((info, index) => {
              let detail = info && info.detail || {}
              let answer = detail.answer && decodeURIComponent(detail.answer) || ''
              let query = detail.query && decodeURIComponent(detail.query) || ''
              let msgtime = detail.msgtime && formatTime(detail.msgtime * 1000, 0) || ''

              return (
                <div key={index}>
                  {
                    msgtime &&
                    <div className="history">
                      <p className="desc">{msgtime}</p>
                    </div>
                  }
                  {
                    query &&
                    <div className="conversation">
                      <div className="user-side">
                        <div className="avater">
                          <i className="iconfont icon-v user"></i>
                        </div>
                        <div className="cont">
                          {/* <p className="user-name">太阳当空照<span className="cont-time">4-17 11:03:49</span></p> */}
                          <div className="user-info">
                            <div className="text">
                              {/* {query} */}
                              <span dangerouslySetInnerHTML={{ __html: props.formatSession(query) }}></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    answer &&
                    <div className="conversation">
                      <div className="kf-side">
                        <div className="avater">
                          <i className="iconfont icon-v kf"></i>
                        </div>
                        <div className="cont">
                          {/* <p className="kf-name">客服小助手</p> */}
                          <div className="kf-info">
                            <div className="text">
                              <span dangerouslySetInnerHTML={{ __html: answer }}></span>
                              {/* <span>{answer}</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              )
            })
          }
        </div> :
        <SdwEmpty />
      }
    </SdwLoading>
  )
}