import {useState, useEffect, useCallback} from 'react'
import _ from 'lodash'
import Input from '@common/Input'
import Button from '@common/Button'
import Table from '@common/Table'
import Message from '@common/Message'
import Pagenation from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import {pageTargetsGroup, getPageFocus} from '@services/keyIndicatorAllocation'
import EditWin from './EditWin'
import {getSearchParams} from '@utils/submit'
import {commonApi} from '@utils/request'

export default function IndexConcernManagement() {

    const [showEditWin, setShowEditWin] = useState(false)
    const [curEditItem, setCurEditItem] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [searchParams, setSearchParams] = useState({})
    const [pageConfig, setPageConfig] = useState({pageNum:0, pageSize:10})
    const [focusList, setFocusList] = useState([])
    const [name, setName] = useState("")


    useEffect(() => {
        initData()
    }, [name, pageConfig])

    function initData() {
        commonApi(getPageFocus, {
            ...pageConfig,
            name:name
        }).then(data => {
            setFocusList(data)
        }).catch(err => Message.error(err))
    }

    const getcolumns = useCallback(
        () => {
            return [
                {
                    title: '关注人',
                    dataIndex: 'rtx_name',
                    width: 50
                }, {
                    title: '指标组',
                    showTip: true,
                    width: 150,
                    render: data => {
                        let focused_list = data.user_focused_list || []
                        let group_list = data.group_list || []
                        let str = ""
                        focused_list.forEach(i => {
                            for (let j in group_list) {
                                if (group_list[j].id == i) {
                                    if (str == "") {
                                        str = group_list[j].group_name
                                    } else {
                                        str += "/" + group_list[j].group_name
                                    }
                                }
                            }
                        })
                        return (
                            <span>{str}</span>
                          )
                    }
                }, {
                    title: '关注中',
                    width: 20,
                    render: data => <span>{_.isArray(data.user_focused_list) ? data.user_focused_list.length : 0 }</span>
                }, {
                    title: '修改人',
                    dataIndex: 'modified_user',
                    width: 50
                }, {
                    title: '修改时间',
                    dataIndex: 'modified_time',
                    width: 80
                }, {
                    title: '操作',
                    width: 30,
                    render: data => (
                        <Button
                            text='编辑'
                            onClick={() => onEdit(data)}
                        />
                    )
                }
            ]
        }
    )

    function onCreate() {
        setIsEdit(false)
        setCurEditItem({})
        setShowEditWin(true)
    }

    function onEdit(item) {
        setIsEdit(true)
        setCurEditItem(item)
        setShowEditWin(true)
    }

    function updateParams(key, val) {
        let cloneParams = _.cloneDeep(searchParams)
        cloneParams[key] = val
        setSearchParams(cloneParams)
    }

    return (
        <>
            <BreadCrumbsAndTablde>
                <BreadCrumbsAndTablde.LeftCrumbs>
                    <Input
                        label='关注人'
                        value={name}
                        width={260}
                        onChange={val => {
                            setPageConfig(r => _.assign({}, r, {
                                pageNum: 0
                            }))
                            setName(val)
                        }}
                    />
                </BreadCrumbsAndTablde.LeftCrumbs>

                <BreadCrumbsAndTablde.RightCrumbs>
                    <Button
                        text='新增'
                        type='submit'
                        iconClass='iconfont icon-add'
                        onClick={onCreate}
                    />
                </BreadCrumbsAndTablde.RightCrumbs>

                <BreadCrumbsAndTablde.CenterWrap>
                    <Table
                        columns={getcolumns()}
                        dataSource={_.isArray(focusList.list) ? focusList.list : []}
                    />

                    <Pagenation
                        total={focusList.total}
                        currentPage={pageConfig.pageNum + 1}
                        pageCount={pageConfig.pageSize}
                        getClickPage={pageNum => {
                            pageNum = pageNum - 1
                            //console.log("=====pageNum", pageNum)
                            setPageConfig(r => _.assign({}, r, {
                                pageNum
                            }))
                        }}
                        pageCountChange={pageSize => setPageConfig(r => _.assign({}, r, {
                            pageSize
                        }))}
                    />
                </BreadCrumbsAndTablde.CenterWrap>
            </BreadCrumbsAndTablde>

            {
                showEditWin &&
                <EditWin
                    isEdit={isEdit}
                    showEditWin={showEditWin}
                    setShowEditWin={setShowEditWin}
                    record={curEditItem}
                    updatePage={initData}
                />
            }
        </>
    )
}
