import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

class Switch extends React.PureComponent {

  render () {
    let { value, changeValue }= this.props
    let switchClassName = 'sdw__switch-button-wrap'
    if (!!(+value)) switchClassName += ' is_true'

    return (
      <>
        <span className={switchClassName} onClick={() => {
          if (typeof changeValue === 'function') {
            changeValue(Number(!value))
          }
        }} />
      </>
    )
  }
}

Switch.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  changeValue: PropTypes.func
}

export default Switch