import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwMessage from '@common/Message'
import SdwDateTimePicker from '@common/DateTimePicker'
import './css/AddWin.css'
import { saveTable } from '@services/midTable'
import { connect } from 'react-redux'

class AddWin extends React.Component {

  state = {
    datePicker: '',
    params:{
      job_name:"",
      exec_cycle:"1/day",
      job_type:0,
    },
    sholdCheckValidate:false,
  }


  componentDidMount () {
    this.initData()
  }


  initData = () => {
    let {isEdit, editMidTable} = this.props
    if (isEdit) {
      this.setState({
        params:editMidTable,
        datePicker: editMidTable.schedule_time
      })
    }
  }

  handleSubmit =() => {
    let {datePicker, params} = this.state
    let isEdit = this.props.isEdit
    let {job_name, exec_cycle, job_type} = params
    if (!job_name || !datePicker) {
      this.setState({
        sholdCheckValidate:true
      })
      return
    }
    let job = {
        job_name:job_name,
        schedule_time:datePicker,
        exec_cycle:exec_cycle,
        job_type:job_type,
        enabled: 1,
        max_retries: 2,
    }
    if (job_type == 0) {
      job.special_code = ""
    }
    saveTable({
      ...isEdit?{id:params.id}:{},
      job
    }).then(res => {
      if (res.data && res.data.code === 0) {
        this.props.changeShowAddWin(false)
        if (isEdit) {
          this.props.updatePage(params.id)
        } else {
          this.props.updatePage()
        }

        SdwMessage.success(isEdit?"修改成功":"新增成功")
      } else {
        let msg = res.data && res.data.data
        SdwMessage.error(msg);
      }
    }, () => SdwMessage.error("接口异常"))
  }

  formatDate = date => {
    if (_.isArray(date)) {
      return date.map((item, index) => {
        let time = index === 0 ? '00:00:00' : '23:59:59'
        return `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate()} ${time}`
      })
    } else {
      let year = date.getFullYear()
      let month = (date.getMonth() + 1)
      let day = date.getDate()
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()

      let dateStr =  year+ "-"
      dateStr += (month < 10 ? "0"+month:month) + "-"
      dateStr += (day < 10 ? "0"+day:day) + " "
      dateStr += (hours < 10 ? "0"+hours:hours) + ":"
      dateStr += (minutes < 10 ? "0"+minutes:minutes) + ":"
      dateStr += (seconds < 10 ? "0"+seconds:seconds)
      return dateStr
    }
  }

  render () {
    let {
      isEdit,
      title,
      showAddWin,
      changeShowAddWin,
      cfgData,
    } = this.props
    let midTableRunCyclesOption = cfgData.midTableRunCycles.map(i => {
      return {id:i.value, text:i.name, item:i}
    })

    let {
      params,
      datePicker,
      sholdCheckValidate
    } = this.state

    return (
      <div>
        <SdwDialog
          title={title}
          visible={showAddWin}
          width={530}
          zIndex={1010}
          changeVisible={changeShowAddWin}
          onCancelClick={() => changeShowAddWin(false)}
          onSureClick={this.handleSubmit}
        >
          <div className='add-AddWin-win__label'>
            <span style={{width:"80px"}} className='label__title required'>任务名称</span>
            <SdwInput
              placeholder='请输入任务名称'
              width={350}
              value={params.job_name}
              onChange={val => {
                this.setState({
                  params: Object.assign({}, params, {
                    job_name:val
                  })
                })
              }}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={value => !!value ? true : '任务名称不能为空'}
            />
          </div>
          <div className='add-AddWin-win__label'>
            <span style={{width:"80px"}} className='label__title required'>中间表类型</span>
            <SdwSelect
              width={350}
              disabled={isEdit}
              listWidth={350}
              clearable={false}
              value={params.job_type}
              data={[{id:0, text:"配置型中间表"}, { id:1, text:"代码型中间表"}]}
              onChange={val => {
                this.setState({
                  params: Object.assign({}, params, {
                    job_type:val
                  })
                })
              }}
            />
          </div>
          <div className='add-AddWin-win__label'>
            <span style={{width:"80px"}} className='label__title required'>调度时间</span>
            <SdwDateTimePicker
              placeholder='请选择调度开始时间'
              value={datePicker}
              width={328}
              changeValue={val => {
                this.setState({
                  datePicker: val
                })
              }}
              disabledDateFun={time=>time.getTime() > (Date.now())}
            />
          </div>
          <div className='add-AddWin-win__label'>
            <span style={{width:"80px"}} className='label__title required'>调度周期</span>
            <SdwSelect
              width={350}
              listWidth={350}
              disabled={isEdit}
              clearable={false}
              value={params.exec_cycle}
              data={midTableRunCyclesOption}
              onChange={val => {
                this.setState({
                  params: Object.assign({}, params, {
                    exec_cycle:val
                  })
                })
              }}
            />
          </div>
        </SdwDialog>
      </div>
    )
  }
}
const mapState = state => ({
  cfgData: state.midTableView.cfgData,
})

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(AddWin)
