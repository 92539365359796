import React, { useState } from 'react'
import copyToClipboard from '@utils/copyText'

import USERIMG from './img/icon_12_copy@2x.png'
import './index.css'

export default props => {

  let [isCopy, setIsCopy] = useState(false)
  let [isShowTip, setIsShowTip] = useState(false)

  function handleCopy () {

    !!props.copyText && copyToClipboard(props.copyText)
    setIsCopy(true)
  }

  return (
    <div style={{ position: 'absolute', display: 'inline-block' }}>
      <img 
        className="monitor-eye-detail__user-img" 
        src={USERIMG}
        onClick={() => handleCopy()}
        onMouseLeave={() => {
          setIsCopy(false)
          setIsShowTip(false)
        }}
        onMouseEnter={() => setIsShowTip(true)}
      ></img>
      {
        isShowTip && <div className="suspend">
          <span>{isCopy ? '复制成功' : !!props.copyTip ? props.copyTip : '点击复制'}</span>
        </div>
      }
    </div>
  )
}
