import React from 'react'
import _ from 'lodash'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwTabs from '@common/Tabs'
import DbManagement from './DbManagement'
import LogManagement from './LogManagement'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import './style/index.css'

const menu = [{
  title: '数据库',
  path: '/dbManagement',
  component: DbManagement,
  priv: 'getAllTasks'
}, {
  title: '日志',
  path: '/logmanagement',
  component: LogManagement,
  priv: 'getCollectConfigList'
}]

class DataManagement extends React.PureComponent {

  state = {
    curMenuIndex: 0
  }

  componentDidMount() {
    const search = _.get(this, 'props.location.search')

    // 回到日志tab
    if (!!search && search.indexOf('tab=log')) this.setState({curMenuIndex: 1})
  }

  render () {
    let { curMenuIndex } = this.state

    const hasPrivMenu = menu.filter(i => hasPriv(i.priv))

    return (
      <div className="page-main__wrap data-management">
        <SdwBreadCrumbs 
          history={this.props.history}
          data={[
            {
              title: '数据采集地',
              path: '/admin/dataCollect/logManagement'
            }, {
              title: '数据接入'
            }
          ]}
        />

        <SdwTabs 
          menu={hasPrivMenu}
          curMenuIndex={curMenuIndex}
          {...this.props}
        />
      </div>
    )
  }
}

export default DataManagement