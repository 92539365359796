import React, { PureComponent } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import SdwSelect from '@common/Select'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwButton from '@common/Button'
import { getMetaDataInfo, getAllTablecfg, getItermSelects, getBusinessStatisticByTopic, getStaffs } from '@services/metaData'
import SdwMessage from '@common/Message/index'
import EditMetadataWin from './EditMetadataWin'
import SdwInput from '@common/Input/index'
import { LEVEL_TEXT_MAP } from './common'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import './style/index.css'

const TYPE_DATA = [
  { id: "es", text: "es" },
  { id: "mysql", text: "mysql" },
  { id: "oracle", text: "oracle" }
]

const LEVEL_DATA = _.keys(LEVEL_TEXT_MAP).map(key => {
  return {
    id: key,
    text: LEVEL_TEXT_MAP[key]
  }
})

class Metadata extends PureComponent {

  state = {
    curPage: this.props.pageInfo || 1,
    curLimit: 10,
    tableValue: '',
    idValue: '',
    topicValue: '', // 主题域id
    topicName: '', // 主题域名称
    businessValue: '', // 业务id
    businessName: '', // 业务名称
    typeValue: '',
    levelValue: '',
    enable: 1,
    curTitle: '',
    visible: false,
    tableData: {},
    curData: {},
    tableList: [],
    topicData: [], // 主题域
    businessData: [], // 业务
    businessList: [] // 已选主题域对应的业务
  }

  componentDidMount () {
    this.initData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.curPage !== this.state.curPage ||
      prevState.curLimit !== this.state.curLimit ||
      prevState.tableValue !== this.state.tableValue ||
      prevState.typeValue !== this.state.typeValue ||
      prevState.enable !== this.state.enable ||
      prevState.topicValue !== this.state.topicValue ||
      prevState.businessValue !== this.state.businessValue ||
      prevState.levelValue !== this.state.levelValue
    ) {
      if (prevState.topicValue !== this.state.topicValue) {
        this.onGetBusinessByTopic()
      }
      this.handleSearch()
    }
  }

  initData = () => {
    this.handleSearch()

    // 更新 topicInfo
    getItermSelects().then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        this.setState({
          topicData: record.topic,
          businessData: record.business
        })
        this.props.updateTopicInfo(record)
      }
    })

    // 更新部门人员名单
    getStaffs().then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || []
        this.props.updateStaffUserList(record)
      }
    })

    // 获取表名称
    getAllTablecfg().then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || []
        this.setState({
          tableList: record
        })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    })
  }

  // 根据主题域ID请求对应的业务
  onGetBusinessByTopic = () => {
    let { topicValue } = this.state
    getBusinessStatisticByTopic({id: topicValue}).then(res => {
      if (res && res.data && res.data.code === 0) {
        let topicArr = res.data.data && res.data.data.list.map(i => {
          return {
            id: i.business_id,
            text: i.business_name
          }
        }) || []
        this.setState({
          businessList: topicArr
        })
      }
    })
  }

  handleSearch = () => {
    let { curPage, curLimit, tableValue, idValue, typeValue, levelValue, topicValue, topicName, businessValue, businessName, enable } = this.state

    getMetaDataInfo({
      page: curPage,
      limit: curLimit,
      order: '', // 老平台这里传了一个空串
      search: JSON.stringify({
        ...(!!tableValue ? {
          id: {
            value: tableValue,
            operator: '='
          }
        } : {}),
        ...(!!typeValue ? {
          data_set_type: {
            value: typeValue,
            operator: '='
          }
        } : {}),
        ...(!!idValue ? {
          id: {
            value: idValue,
            operator: '='
          }
        } : {}),
        ...(!!levelValue ? {
          warehouse_level: {
            value: levelValue,
            operator: '='
          }
        } : {}),
        ...(!!topicValue ? {
          topic: {
            value: topicName,
            operator: '='
          }
        } : {}),
        ...(!!businessValue ? {
          business: {
            value: businessName,
            operator: '='
          }
        } : {}),
        ...(enable !== '' ? {
          enable: {
            value: enable,
            operator: '='
          }
        } : {})
      })
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let record = res.data.data || {}
          console.log("tableList-----------------", record)
          this.setState({
            tableData: record
          })
        } else {
          let msg = res.data.msg || '请求失败'
          SdwMessage.error(msg)
        }
      })
      .catch(() => SdwMessage.error('请求失败'))
  }

  getColumns = () => {
    return [
      {
        title: "ID",
        width: 50,
        render: data => (
          <span>{`${data.id}`}</span>
        )
      },{
        title: "表/索引名称",
        width: 180,
        render: data => (
          <span>{`${data.database_name}.${data.table_name}`}</span>
        )
      }, {
        title: "表中文名",
        width: 140,
        dataIndex: "data_set_chinese",
      }, {
        title: "主题域/业务",
        width: 120,
        render: data => (
          <span>{`${data.topic}/${data.business}`}</span>
        )
      }, {
        title: "有效性",
        width: 50,
        render: data => (
          <span>{`${data.enable == 0 ? "无效" : (data.enable == 1? "有效":"全部")}`}</span>
        )
      }, {
        title: "仓库类型",
        width: 50,
        dataIndex: "data_set_type"
      }, {
        title: "数据层级",
        width: 50,
        dataIndex: "warehouse_level",
      // }, {
      //   title: "是否有效",
      //   render: function (data, record) {
      //     return (
      //       <>
      //         {
      //           _.isEqual(typeof data.enable, 'number') && <span className={
      //             data.enable ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
      //           }></span>
      //         }
      //         <span>{_.isEqual(typeof data.enable, 'number') ?
      //           (data.enable ? '有效' : '无效') : '-'}</span>
      //       </>
      //     )
      //   }
      }, {
        title: "负责人",
        dataIndex: "owner"
      },{
        title: "备注",
        width: 180,
        dataIndex: "comments"
      }, {
        title: "操作",
        width: 100,
        fiexd: true,
        render: (data, record) => (
          <>
            <SdwButton
              text="查看"
              onClick={ () => this.jumpToDetails(data) }
            />
            {
              hasPriv('updateTablecfg') &&
              <SdwButton
                text="编辑"
                onClick={ () => this.setState({
                  visible: true,
                  curTitle: `编辑表配置（${data.id}）`,
                  curData: data
                }) }
              />
            }
          </>
        )
      }
    ]
  }

  jumpToDetails = record => {
    this.props.history.push(`/admin/dataMarket/baseInfo/${record.id}`)
  }

  render() {

    let {
      tableData,
      tableValue,
      idValue,
      topicValue, // 主题域id
      businessValue, // 业务id
      typeValue,
      levelValue,
      enable,
      tableList,
      curTitle,
      visible,
      curData,
      topicData, // 主题域
      businessData, // 业务
      businessList // 主题域对应的业务
    } = this.state

    return (
      <>
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '数据集市',
              path: '/admin/dataMarket/dictionary'
            }, {
              title: '元数据管理'
            }
          ]}
        />

        <div className="page-mainbody">
          <div className="page-search">
            <div className="input-wrap">
              <div className="ele-search-input">
                <SdwInput
                    value={idValue}
                    width={100}
                    label="ID"
                    onBlur={this.handleSearch}
                    onEnterKeyDown={this.handleSearch}
                    onChange={val => this.setState({
                      curPage: 1,
                      idValue: val
                    })}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  value={tableValue}
                  label="表名称"
                  readOnly={false}
                  width={260}
                  listWidth={460}
                  data={tableList.map(el => {
                    return {
                      id: el.id,
                      text: (el.database_name ? el.database_name + '.' : '') + el.table_name + '（' + el.data_set_chinese + '）'
                    }
                  })}
                  onChange={val => this.setState({
                    curPage: 1,
                    tableValue: val
                  })}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                    value={topicValue}
                    width={160}
                    label="主题域"
                    data={topicData.map(el => {
                      return {
                        id: el.id,
                        text: el.topic_name
                      }
                    })}
                    onChange={(val, item) => {
                      this.setState({
                        curPage: 1,
                        topicValue: val,
                        topicName: item.text
                      })
                    }}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                    value={businessValue}
                    width={160}
                    label="业务"
                    data={topicValue ? businessList : businessData.map(el => {
                      return {
                        id: el.id,
                        text: el.business_name
                      }
                    })}
                    onChange={(val, item) => {
                      this.setState({
                        curPage: 1,
                        businessValue: val,
                        businessName: item.text
                      })
                    }}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  value={typeValue}
                  width={160}
                  label="仓库类型"
                  data={TYPE_DATA}
                  onChange={val => this.setState({
                    curPage: 1,
                    typeValue: val
                  })}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  value={levelValue}
                  width={160}
                  label="仓库分层"
                  data={LEVEL_DATA}
                  onChange={val => this.setState({
                    curPage: 1,
                    levelValue: val
                  })}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  value={enable}
                  width={160}
                  label="有效性"
                  data={[{
                    id: '',
                    text: '全部'
                  }, {
                    id: 1,
                    text: '有效'
                  }, {
                    id: 0,
                    text: '无效'
                  }]}
                  onChange={val => this.setState({
                    curPage: 1,
                    enable: val
                  })}
                />
              </div>

              <div className="btn-group">
                {
                  hasPriv('storeTablecfg') &&
                  <SdwButton
                    type="submit"
                    text="创建表"
                    iconClass="el-icon-plus"
                    onClick={ () => this.setState({
                      visible: true,
                      curTitle: '创建表配置',
                      curData: {}
                    }) }
                  />
                }
              </div>
            </div>
          </div>
          <div className="page-table">
            <SdwTable
              columns={ this.getColumns() }
              tdWhiteSpace={ true }
              dataSource={ tableData.items || [] }
            />
            {
              _.isArray(tableData.items) && !!tableData.items.length &&
              <SdwPagenation
                total={ tableData.total }
                currentPage={ tableData.current_page }
                pageCountList={[10, 15, 20, 40]}
                pageCount={ tableData.per_page }
                getClickPage={ page => this.setState({ curPage: page }) }
                pageCountChange={ limit => this.setState({ curLimit: limit }) }
              />
            }
          </div>
        </div>

        {
          visible &&
          <EditMetadataWin
            visible={visible}
            curData={curData}
            curTitle={curTitle}
            freshTableData={this.handleSearch}
            changeVisible={ bool => this.setState({ visible: bool }) }
          />
        }
      </>
    )
  }
}

const mapState = state => ({
  pageInfo: state.page.pageInfo
})

const mapDispatch = dispatch => ({
  updateTopicInfo: dispatch.topic.updateTopicInfo,
  updateStaffUserList: dispatch.user.updateStaffUserList,
})

export default withRouter(connect(mapState, mapDispatch)(Metadata))
