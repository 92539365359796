import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import Select from '@common/Select'
import Input from '@common/Input'
import Radiobox from '@common/Radiobox'
import Button from '@common/Button'
import BreadCrumbs from '@common/BreadCrumbs'
import SdwMessage from '@common/Message'
import {searchDetail, saveConfig} from '@services/labelProcess'
import {getPublicCfg, getStandardSelects} from '@services/Derived'
import {getStaffs} from '@api/alertRules'
import {hasPriv} from '@components/FrontendAuth/AuthMap'
import './index.css'

class UserLabelWin extends React.PureComponent {

    state = {
        labelDetail: {},
        breadCrumbsTitle: "新增用户标签", // 面包屑标题
        buttonPrevTitle: "创建标签并返回列表页", // 按钮文案
        buttonNextTitle: "创建标签并进入下一步", // 按钮文案
        paramsData: {
            label_type: "fact",
            label_name: "", // 标签名称（英文）
            tag_name_ch: "", // 标签名称（中文）
            onwer: "", // 标签负责人
            require_rtx: "", // 标签需求方
            intro: "", // 标签介绍
            remark: "", // 标签备注
            topic: "", // 所属主题域id（字典）
            business: "", // 所属业务id（字典）
            first_classify: "", // 一级分类
            second_classify: "", // 二级分类
            sensitivity: "1", // 敏感等级（字典label_sensitivity）
            enable: "0",
            label_value_type: "string", // 标签规则类型（字典label_value_type）
            life_cycle: "", // 有效期（字典label_validate_select）
            value_mapping: "", // 标签值示例
        },
        sholdCheckValidate: false,
        topicData: [],
        businessData: [],
        backPath: "",
        cfgData: "",
        allMembers: [],
        principal: [], // 标签负责人
        demandSide: [], // 标签需求方
    }

    componentDidMount() {
        this.getStandardSelects()
        this.getPublicCfg()
        this.getStaff()

        if (this.props.location.state && this.props.location.state.backPath == "detail") {
            this.setState({
                backPath: "detail"
            })
        }
        let ID = this.props.match.params.id
        if (!!ID && ID != 0) {
            this.onSearchDetail(ID)
        }
    }

    getPublicCfg = () => {
        getPublicCfg().then(res => {
            if (res.data.code === 0 && res.data.data) {
                let cfgData = {}
                for (let i in res.data.data) {
                    cfgData[i] = res.data.data[i]
                }
                this.setState({cfgData: cfgData})
            }
        })
    }

    // 获取主题列表
    getStandardSelects = () => {
        let that = this
        getStandardSelects().then((res) => {
            let userLabelTopicRecord = res.data.data
            that.setState({
                topicData: userLabelTopicRecord.topic,
                businessData: userLabelTopicRecord.business,
            })
        })
    }

    // 获取rtx
    getStaff = () => {
        getStaffs().then(res => {
            if (res && res.data && res.data.code === 0) {
                let list = _.isArray(res.data.data) ? res.data.data : []
                this.setState({
                    allMembers: list
                })
            }
        })
    }

    onSearchDetail = id => {
        let params = {
            id: id
        }
        searchDetail(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let record = res.data.data || {}
                this.setState({
                    breadCrumbsTitle: "编辑用户标签",
                    buttonPrevTitle: "保存&返回列表页",
                    buttonNextTitle: "保存&下一步",
                    labelDetail: record,
                    paramsData: {
                        label_type: record.label_type, // 标签规则类型（字典label_type）
                        label_name: record.label_name, //标签名称（英文）
                        tag_name_ch: record.tag_name_ch, // 标签名称（中文）
                        intro: record.intro, // 标签介绍
                        remark: record.remark, // 标签备注
                        topic: record.topic, // 所属主题域id（字典）
                        business: record.business, // 所属业务id（字典）
                        first_classify: record.first_classify, // 一级分类
                        second_classify: record.second_classify, // 二级分类
                        sensitivity: record.sensitivity, // 敏感等级（字典label_sensitivity）
                        enable: record.enable,
                        label_value_type: record.label_value_type, // 标签规则类型
                        life_cycle: record.life_cycle + "", // 有效期
                        value_mapping: record.value_mapping, // 标签值示例
                    },
                    principal: record.onwer ? record.onwer.split(",") : [],
                    demandSide: record.require_rtx ? record.require_rtx.split(",") : [],
                })
            }
        })
    }

    saveConfig = (val) => {
        let {paramsData, breadCrumbsTitle, backPath, principal, demandSide} = this.state

        if (this.ruleFormData("tag_name_ch", paramsData.tag_name_ch) !== true ||
            this.ruleFormData("label_name", paramsData.label_name) !== true ||
            this.ruleFormData("first_classify", paramsData.first_classify) !== true ||
            this.ruleFormData("second_classify", paramsData.second_classify) !== true ||
            this.ruleFormData("intro", paramsData.intro) !== true ||
            this.ruleFormData("life_cycle", paramsData.life_cycle) !== true ||
            this.ruleFormData("topic", paramsData.topic) !== true ||
            this.ruleFormData("business", paramsData.business) !== true ||
            this.ruleFormData("principal", principal) !== true ||
            this.ruleFormData("demandSide", demandSide) !== true) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }

        let params = {
            data: {...paramsData},
        }
        let ID = this.props.match.params.id
        if (!!ID && ID != "0") {
            params["id"] = ID
        }
        params.data.onwer = principal.join(",")
        params.data.require_rtx = demandSide.join(",")

        saveConfig(params).then(res => {
            if (res.data.code === 0 && res.data.data) {
                this.props.updatePageInfo("")
                this.props.history.push({
                    pathname: val == "prev" ? '/admin/labelProcess/userLabel' : '/admin/labelProcess/setupTwo',
                    state: {
                        id: res.data.data,
                        backPath: backPath,
                        breadCrumbsTitle: breadCrumbsTitle,
                    }
                })
            } else {
                SdwMessage.error(res.data.data || "请求超时")
            }
        })
    }

    ruleFormData = (type, val) => {
        switch (type) {
            case "tag_name_ch":
                if (!val) {
                    return '标签名称不能为空'
                }
                if (val.length > 50) {
                    return '输入最大长度为50'
                }
                break
            case "label_name":
                if (!val) {
                    return '标签英文名不能为空'
                }
                if (val.length > 100) {
                    return '输入最大长度为100'
                }
                break
            case "first_classify":
                if (!val) {
                    return "请选择一级分类"
                }
                break
            case "second_classify":
                if (!val) {
                    return "请填写二级分类"
                }
                break
            case "intro":
                if (val.length > 500) {
                    return '输入最大长度为500'
                }
                break
            case "topic":
                if (!val) {
                    return "请选择主题域"
                }
                break
            case "business":
                if (!val) {
                    return "请选择所属业务"
                }
                break
            case "life_cycle":
                if (!val) {
                    return "有效期不能为空"
                }
                break
            case "principal":
                if (val.length == 0) {
                    return "标签负责人不能为空"
                }
                break
            case "demandSide":
                if (val.length == 0) {
                    return "标签需求方不能为空"
                }
                break
            default:
                break
        }
        return true
    }

    changeParamsValue = (type, val) => {
        let {paramsData} = this.state
        let obj = {}
        if (type == "topic") {
            obj = {
                topic: val,
                business: "",
            }
        } else {
            obj[type] = val
        }
        this.setState({
            paramsData: _.assign({}, paramsData, obj)
        })
    }

    render() {
        let {
            paramsData,
            breadCrumbsTitle,
            buttonPrevTitle,
            buttonNextTitle,
            sholdCheckValidate,
            topicData,
            businessData,
            cfgData,
            allMembers,
            principal,
            demandSide
        } = this.state

        return (
            <>
                <BreadCrumbs
                    history={this.props.history}
                    data={[
                        {
                            title: '标签加工厂',
                            path: '/admin/labelProcess/ticketLabel'
                        }, {
                            title: '用户标签',
                            path: '/admin/labelProcess/userLabel'
                        }, {
                            title: breadCrumbsTitle
                        }
                    ]}
                />

                <div className='page-main__body'>

                    {/* 步骤条 */}
                    <div className='container'>
                        <ol className='step-area'>
                            <li className='success'>
                                <div className='step-inner_1'>
                                    <i className='step-num_1'></i>
                                    <span>标签信息配置</span>
                                </div>
                            </li>
                            <li>
                                <div className='step-inner_1'>
                                    <i className='step-num_1'></i>
                                    <span>标签规则配置</span>
                                </div>
                            </li>
                        </ol>
                    </div>

                    <div className='user-label-form'>
                        <div className='label-title-main'>
                            <span className='label-title-bar'>基本信息</span>
                            <span className='label-title-des'>用于编辑标签基础信息</span>
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>标签名称</span>
                            <Input
                                placeholder='请填写标签名称'
                                width={560}
                                value={paramsData.tag_name_ch}
                                sholdCheckValidate={sholdCheckValidate}
                                onChange={val => this.changeParamsValue("tag_name_ch", val)}
                                validateFun={val => this.ruleFormData("tag_name_ch", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>标签英文名</span>
                            <Input
                                placeholder='请填写标签英文名'
                                width={560}
                                value={paramsData.label_name}
                                sholdCheckValidate={sholdCheckValidate}
                                onChange={val => this.changeParamsValue("label_name", val)}
                                validateFun={val => this.ruleFormData("label_name", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label'>标签介绍</span>
                            <Input
                                placeholder='请填写标签介绍'
                                type='textarea'
                                width={560}
                                value={paramsData.intro}
                                sholdCheckValidate={sholdCheckValidate}
                                onChange={val => this.changeParamsValue("intro", val)}
                                validateFun={val => this.ruleFormData("intro", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>标签负责人</span>
                            <Input
                                placeholder={principal.length == 0 ? '请填写标签负责人rtx' : ''}
                                width={560}
                                isMultipleChoice={true}
                                multipleValue={principal}
                                multipleOption={allMembers}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("principal", val)}
                                onClear={() => this.setState({principal: []})}
                                changeMultipleValue={val => this.setState({principal: val})}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>标签需求方</span>
                            <Input
                                placeholder={demandSide.length == 0 ? '请填写标签需求方rtx' : ''}
                                width={560}
                                isMultipleChoice={true}
                                multipleValue={demandSide}
                                multipleOption={allMembers}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("demandSide", val)}
                                onClear={() => this.setState({demandSide: []})}
                                changeMultipleValue={val => this.setState({demandSide: val})}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label'>标签备注</span>
                            <Input
                                placeholder='请填写标签备注'
                                type='textarea'
                                width={560}
                                value={paramsData.remark}
                                onChange={val => this.changeParamsValue("remark", val)}
                            />
                        </div>

                        <div className='label-title-main'>
                            <span className='label-title-bar'>属性信息</span>
                            <span className='label-title-des'>用于编辑标签属性信息</span>
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>一级分类</span>
                            <Select
                                placeholder='请选择一级分类'
                                width={560}
                                readOnly={false}
                                data={[
                                    {
                                        id: '人口属性',
                                        text: '人口属性'
                                    }, {
                                        id: '风险属性',
                                        text: '风险属性'
                                    }, {
                                        id: '社交属性',
                                        text: '社交属性'
                                    }, {
                                        id: '金融属性',
                                        text: '金融属性'
                                    }, {
                                        id: '游戏属性',
                                        text: '游戏属性'
                                    }, {
                                        id: '服务属性',
                                        text: '服务属性'
                                    }
                                ]}
                                value={paramsData.first_classify}
                                onChange={val => this.changeParamsValue("first_classify", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("first_classify", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>二级分类</span>
                            <Input
                                placeholder='请填写二级分类'
                                width={560}
                                value={paramsData.second_classify}
                                onChange={val => this.changeParamsValue("second_classify", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("second_classify", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>所属主题域</span>
                            <Select
                                placeholder='请选择所属主题域'
                                readOnly={false}
                                data={topicData.map(el => {
                                    return {id: el.id + "", text: el.topic_name}
                                })}
                                onChange={val => {
                                    this.changeParamsValue("topic", val)
                                }}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("topic", val)}
                                width={560}
                                value={paramsData.topic}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>所属业务</span>
                            <Select
                                placeholder={paramsData.topic ? '请选择所属业务' : '请先选择主题域，再选择业务'}
                                disabled={!paramsData.topic}
                                readOnly={false}
                                width={560}
                                data={businessData.filter(i => i.topic_id == paramsData.topic).map(el => {
                                    return {id: el.id + "", text: el.business_name}
                                })}
                                onChange={val => this.changeParamsValue("business", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("business", val)}
                                value={paramsData.business}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>敏感等级</span>
                            <Select
                                placeholder='请选择敏感等级'
                                readOnly={false}
                                width={560}
                                data={[
                                    {
                                        id: '1',
                                        text: 'L1'
                                    }, {
                                        id: '2',
                                        text: 'L2'
                                    }, {
                                        id: '3',
                                        text: 'L3'
                                    }, {
                                        id: '4',
                                        text: 'L4'
                                    }, {
                                        id: '5',
                                        text: 'L5'
                                    }
                                ]}
                                value={paramsData.sensitivity}
                                onChange={val => this.changeParamsValue("sensitivity", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>标签规则类型</span>
                            <Radiobox
                                options={cfgData ? cfgData["label_type"].map((item) => {
                                    return {value: item.value, text: item.name}
                                }) : []}
                                value={paramsData.label_type}
                                changeCheckedFun={val => this.changeParamsValue("label_type", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label'>标签值类型</span>
                            <Radiobox
                                options={cfgData ? cfgData["label_value_type"].map((item) => {
                                    return {value: item.value, text: item.name}
                                }) : []}
                                value={paramsData.label_value_type}
                                changeCheckedFun={val => this.changeParamsValue("label_value_type", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>有效期</span>
                            <Select
                                placeholder='请选择有效期'
                                width={560}
                                readOnly={false}
                                value={paramsData.life_cycle}
                                data={cfgData ? cfgData["label_validate_select"].map(el => {
                                    return {id: el.value, text: el.name}
                                }) : []}
                                onChange={val => this.changeParamsValue("life_cycle", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("life_cycle", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label'>标签值示例</span>
                            <Input
                                placeholder={'映射型：{"white": 1,"light_gray": 2}\n' +
                                    '非映射型：[老师,律师,医生]'}
                                type='textarea'
                                width={560}
                                value={paramsData.value_mapping}
                                onChange={val => this.changeParamsValue("value_mapping", val)}
                            />
                        </div>

                        <div className='btn_box'>
                            <Button
                                disabled={hasPriv("saveUserLabel") ? false : true}
                                type='cancel'
                                text={buttonPrevTitle}
                                onClick={() => this.saveConfig("prev")}
                            />
                            <span style={{marginRight: 8}}></span>
                            <Button
                                disabled={hasPriv("saveUserLabel") ? false : true}
                                type='submit'
                                text={buttonNextTitle}
                                onClick={this.saveConfig}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapState = state => ({
    pageInfo: state.page.pageInfo
})

const mapDispatch = dispatch => ({
    updatePageInfo: dispatch.page.updatePageInfo
})

export default withRouter(connect(mapState, mapDispatch)(UserLabelWin))
