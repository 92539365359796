import { useRef, useState, useEffect } from 'react'
import _ from 'lodash'
import SdwDrawer from "@common/Drawer"
import Form from "@common/Form_D"
import Input from "@common/Input"
import Select from "@common/Select"
import Switch from "@common/Switch"
import Textarea from "@common/Textarea"
import SdwMessage from "@common/Message"
import { setMonitorServerConfig, getItermSelects, getBusinessStatisticByTopic } from '@services/logManagement'

function notEmpty(val) {
  return !!val
}

const rules = {
  service_name: [
    { func: notEmpty, message: '请输入服务中文名称' }
  ],
  topic_id: [
    { func: notEmpty, message: '请选择主题域' }
  ],
  business_id: [
    { func: notEmpty, message: '请选择业务' }
  ],
  ip: [
    { func: notEmpty, message: '请输入该服务数据发送的目标IP，仅填写1个' }
  ],
  port: [
    { func: notEmpty, message: '请输入目标IP端口，仅填写1个' }
  ],
  ip_bak: [
    { func: notEmpty, message: '请输入备用IP，仅填写1个' }
  ],
  port_bak: [
    { func: notEmpty, message: '请输入备用端口，仅填写1个' }
  ],
  iplist: [
    { func: notEmpty, message: '请输入日志服务器IP，多个IP用,（英文逗号）分隔' }
  ],
  fileName: [
    { func: notEmpty, message: '请输入日志文件路径' }
  ],
  precursorFileName: [
    { func: notEmpty, message: '请输入日志文件上一周期路径' }
  ],
  sendMaxLine: [
    { func: notEmpty, message: '请输入日志发送及结算文件数上限' }
  ]
}

const DEFAULT_DATA = {
  ip: '11.177.107.29',
  port: '8000',
  ip_bak: '11.177.107.29',
  port_bak: '8000',
  sendMaxLine: 25,
  is_valid: 0
}

export default function EditWin(props) {
  const { title, data, visible, isEdit, changeShowEditWin, updatePage } = props
  const formRef = useRef(null)

  const [formData, setFormData] = useState({})
  const [topicArr, setTopicArr] = useState([])
  const [businessArr, setBusinessArr] = useState([])

  useEffect(() => {
    let defaultD = DEFAULT_DATA
    if (isEdit) {
      defaultD = _.cloneDeep(data)
    }
    setFormData(defaultD)
  }, [data])

  useEffect(() => {
    getItermSelects().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const topic = _.get(res, 'data.data.topic')
        if (_.isArray(topic)) setTopicArr(topic.map(i => ({ id: i.id, text: i.topic_name })))
      }
    })
  }, [])

  useEffect(() => {
    if (!formData.topic_id) return
    getBusinessStatisticByTopic({
      id: formData.topic_id
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const business = _.get(res, 'data.data.list')
        if (_.isArray(business)) setBusinessArr(business.map(i => ({ id: i.business_id, text: i.business_name })))
      }
    })
  }, [formData.topic_id])

  function setFormDataProps(key, val) {
    let cloneForm = _.cloneDeep(formData)
    cloneForm[key] = val
    setFormData(cloneForm)
  }

  function onSubmit() {
    formRef.current.validate(valid => {
      // console.log('*******valid, isEdit, formData: ', valid, isEdit, formData);
      if (valid) {
        const params = {
          ...formData,
          ...isEdit ? {
            id: data.id
          } : {}
        }
        setMonitorServerConfig({
          data: params
        }).then(res => {
          const code = _.get(res, 'data.code')
          if (code === 0) {
            let msg = isEdit ? '编辑配置成功' : '添加配置成功'
            SdwMessage.success(msg)
            changeShowEditWin(false)
            updatePage()
          } else {
            let tip = isEdit ? '编辑配置失败' : '添加配置失败'
            const msg = _.get(res, 'data.msg') || tip
            SdwMessage.error(msg)
          }
        }).catch(() => SdwMessage.error("接口异常"))
      }
    })
  }

  return (
    <SdwDrawer
      title={title}
      visible={visible}
      onIconClick={() => changeShowEditWin(false)}
      onCancelClick={() => changeShowEditWin(false)}
      onSureClick={onSubmit}
    >
      <SdwDrawer.Body>
        <Form labelWidth={'140px'} labelPosition={'left'} ref={formRef} model={formData} rules={rules}>
          <Form.FormItem label="服务名称" prop="service_name" required>
            <Input
              placeholder="请输入服务中文名称"
              width='100%'
              value={formData.service_name}
              onChange={val => setFormDataProps('service_name', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="主题域" prop="topic_id" required>
            <Select
              placeholder="请选择主题域"
              width='100%'
              readOnly={false}
              value={formData.topic_id}
              data={topicArr}
              onChange={(val, item) => setFormData(r => _.assign({}, r, {
                topic_id: val,
                topic: item.text
              }))}
            />
          </Form.FormItem>
          <Form.FormItem label="业务" prop="business_id" required>
            <Select
              placeholder={!formData.topic_id ? '请先选择主题' : "请选择业务"}
              width='100%'
              disabled={!formData.topic_id}
              value={formData.business_id}
              data={businessArr}
              onChange={(val, item) => setFormData(r => _.assign({}, r, {
                business_id: val,
                business: item.text
              }))}
            />
          </Form.FormItem>
          <Form.FormItem label="目标IP（主）" prop="ip" required>
            <Input
              placeholder="请输入该服务数据发送的目标IP，仅填写1个"
              width='100%'
              value={formData.ip}
              onChange={val => setFormDataProps('ip', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="目标端口（主）" prop="port" required>
            <Input
              placeholder="请输入目标IP端口，仅填写1个"
              width='100%'
              value={formData.port}
              onChange={val => setFormDataProps('port', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="目标IP（备）" prop="ip_bak" required>
            <Input
              placeholder="请输入备用IP，仅填写1个"
              width='100%'
              value={formData.ip_bak}
              onChange={val => setFormDataProps('ip_bak', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="目标端口（备）" prop="port_bak" required>
            <Input
              placeholder="请输入备用端口，仅填写1个"
              width='100%'
              value={formData.port_bak}
              onChange={val => setFormDataProps('port_bak', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="日志服务器IP" prop="iplist" required>
            <Input
              placeholder="请输入日志服务器IP，多个IP用,（英文逗号）分隔"
              width='100%'
              value={formData.iplist}
              onChange={val => setFormDataProps('iplist', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="日志文件路径" prop="fileName" required>
            <Input
              placeholder="请输入日志文件路径，如：/data/SmartFlow/log/FlowH5LogReport2.20210921.log "
              width='100%'
              value={formData.fileName}
              onChange={val => setFormDataProps('fileName', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="路径时间格式" prop="dateFormat">
            <Input
              placeholder="请输入日志文件时间参数格式，如YYYYMMDD"
              width='100%'
              value={formData.dateFormat}
              onChange={val => setFormDataProps('dateFormat', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="上一周期路径" prop="precursorFileName" required>
            <Input
              placeholder="请输入日志文件上一周期路径"
              width='100%'
              value={formData.precursorFileName}
              onChange={val => setFormDataProps('precursorFileName', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="发送接收上限" prop="sendMaxLine" required>
            <Input
              placeholder="请输入日志发送及结算文件数上限"
              width='100%'
              type='number'
              value={formData.sendMaxLine}
              onChange={val => setFormDataProps('sendMaxLine', val)}
            />
          </Form.FormItem>      
          <Form.FormItem label="是否有效" required>
            <Switch
              value={formData.is_valid}
              changeValue={val => setFormDataProps('is_valid', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="备注" prop="remark">
            <Textarea
              placeholder="请输入备注"
              width='100%'
              value={formData.remark}
              onChange={val => setFormDataProps('remark', val)}
            />
          </Form.FormItem> 
        </Form>
      </SdwDrawer.Body>
    </SdwDrawer>
  )
}