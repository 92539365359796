import {request} from '../utils/request'

// 关键指标分页列表
export function pageTargetsGroup(params) {
    return request({url: '/statistics/masterTarget/pageTargetsGroup', method: 'post', data: params})
}

// 新增、修改关键指标配置
export function saveTargetsGroup(params) {
    return request({url: '/statistics/masterTarget/saveTargetsGroup', method: 'post', data: params})
}

// 关联指标api模块
export function relateApiTarget(params) {
    return request({url: '/statistics/masterTarget/relateApiTarget', method: 'post', data: params})
}

// 获取关键指标详情信息
export const getTargetGroupDetail = params => {
    return request({url: '/statistics/masterTarget/getTargetGroupDetail', method: 'get', params: params})
}

// 获取关注人列表
export function getPageFocus(params) {
    return request({url: '/statistics/masterTarget/getPageFocus', method: 'post', data: params})
}

// 保存关注人信息
export function saveFocusUser(params) {
    return request({url: '/statistics/masterTarget/saveFocusUser', method: 'post', data: params})
}
