import React from 'react'
import './style/index.css'
import { connect } from 'react-redux'

class StepList  extends React.Component {
  state = {
    recordList :[],
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.stepList != prevProps.stepList) {
      this.iniStepList()
    }
  }
  iniStepList = () => {
    let stepList = this.props.stepList
    let recordList = []
    let n = 0
    console.log("stepList", stepList)
    for (let i in stepList) {
      if (stepList[i].item["table_name"] == null) {
        continue
      }
      n = n + 1
      let tableName = stepList[i].item["table_type"] == 0 ? "物理表" : "视图"
      recordList.push({
        name:"步骤"+ n +"：添加"+tableName+" "+stepList[i].item["table_name"],
        item:stepList[i]
      })
    }
    console.log("recordList", recordList)
    this.setState({
      recordList:recordList
    })
  }
  render() {

    let {recordList} = this.state
    console.log("recordList====", recordList)
    return (
      <>
        <div className="stepList_title">
          加工步骤总结
        </div>
        <div className="stepList_content">
          {
            recordList.map(i=>{
              return <div key={"stepList_"+i.name} className="stepList_content_li">
                {i.name}
              </div>
            })
          }

        </div>
      </>
    )
  }
}
const mapState = state => ({
  stepList: state.midTableView.stepList,
})

const mapDispatch = dispatch => ({
  updateStepList:dispatch.midTableView.updateStepList
})
export default connect(mapState, mapDispatch)(StepList)
