import { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Button from '@common/Button'
import Table from '@common/Table'
import Message from '@common/Message'
import { testOrderRule } from '@services/labelManagement'
import { commonApi } from '@utils/request'
import './index.css'

function LabelTest(props) {
  const { labelRuleConfigID, example, updatePage } = props

  const [showTest, setShowTest] = useState(false)
  const [isTestSuccess, setIsTestSuccess] = useState(false)
  const [curRecord, setCurRecord] = useState({})
  
  function getcolumns () {
    return [
      {
        title: '工单号',
        dataIndex: 'task_id_field',
      }, {
        title: '标签',
        dataIndex: 'label',
      }
    ]
  }

  function onTest() {
    commonApi(testOrderRule, {
      id: labelRuleConfigID,
      example
    }).then(data => {
      Message.success('测试成功')
      setIsTestSuccess(true)
      setCurRecord([data])
    }).catch(err => Message.error(err || '测试失败'))
    .finally(() => {
      setShowTest(true)
      updatePage()
    })
  }

  return (
    <div style={{ width: 1000 }}>
      <div className='label-rule-config__title-wrap'>标签加工测试</div>
      <div className='label-rule-config__example-wrap'>
        <div className='example-label'>测试案例</div>
        <div style={{ wordBreak: 'break-all' }}>{ example }</div>
      </div>
      <div>
        <Button
          type='cancel'
          text='测试'
          onClick={onTest}
        />
      </div>
      {
        showTest &&
        <>
          <div style={{
            marginTop: 16
          }}>测试结果：
            {
              isTestSuccess ?
              <span style={{ color: '#00876e' }}>成功</span> :
              <span style={{ color: 'red' }}>失败</span>
            }
          </div>
          {
            isTestSuccess &&
            <div style={{
              marginTop: 16
            }}>
              <Table
                columns={getcolumns()}
                dataSource={_.isArray(curRecord) ? curRecord : []}
              />
            </div>
          }
        </>
      }
    </div>
  )
}

const mapState = state => ({
  labelRuleConfigID: state.labelManagement.labelRuleConfigID,
  example: state.labelManagement.example
})

export default connect(mapState)(LabelTest)