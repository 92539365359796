import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select/index'
import { STRATEGY_TYPE_MAP } from './common'

import './style/warn-strategy.css'

class SelectAddInputGroup extends React.Component {

  static defaultProps = {
    isEmpty: false
  }

  validateFun = val => {
    if (this.props.isEmpty || !!val) {
      return true
    }
    return '该输入项不能为空'
  }
  
  validateArrFun = list => {
    if (this.props.isEmpty || (!!list.length && !!list[0])) {
      return true
    }
    return '该输入项不能为空'
  }

  handleChange = (type, index, val) => {
    let { handlerList } = this.props
    let obj = _.cloneDeep(handlerList[index])
    obj[type] = val

    this.props.updateHanderList(index, STRATEGY_TYPE_MAP.update, obj)
  }

  addPolicy = () => {
    this.props.updateHanderList('', STRATEGY_TYPE_MAP.add)
  }

  removePolicy = index => {
    this.props.updateHanderList(index, STRATEGY_TYPE_MAP.del)
  }

  render () {

    let { handlerList, isEmpty, sholdCheckValidate, allMembers, relatedOption } = this.props

    return (
      <>
        {
          !!handlerList.length &&
          handlerList.map((person, index) => (
            <span key={index} className="warn-add-input-group-wrap sdw-warn-strategy__wrap">
              <span style={{ marginRight: 10 }}>
                <SdwSelect
                  value={person.user_type}
                  width={100}
                  clearable={isEmpty}
                  validateFun={this.validateFun}
                  sholdCheckValidate={sholdCheckValidate}
                  data={[
                    {
                      id: 'related',
                      text: '关联字段'
                    }, {
                      id: 'input',
                      text: '手动输入'
                    }
                  ]}
                  onChange={val => this.handleChange('user_type', index, val)} 
                />
              </span>
              {
                !!person.user_type &&
                <>
                  {
                    person.user_type === 'related' ?
                    <>
                      <span style={{ marginRight: 10 }}>
                        <SdwSelect
                          value={person.related_field}
                          width={158}
                          readOnly={false}
                          clearable={isEmpty}
                          validateFun={this.validateFun}
                          sholdCheckValidate={sholdCheckValidate}
                          data={relatedOption}
                          onChange={val => this.handleChange('related_field', index, val)} 
                        />
                      </span>
                      <span>
                        <SdwSelect
                          value={person.decorate}
                          width={100}
                          label="修饰词"
                          clearable={isEmpty}
                          validateFun={this.validateFun}
                          sholdCheckValidate={sholdCheckValidate}
                          data={[
                            {
                              id: 'self',
                              text: '本人'
                            }, {
                              id: 'senior_kf',
                              text: '所属高客'
                            }, {
                              id: 'manager',
                              text: '所属主管'
                            }, {
                              id: 'team_leader',
                              text: '所属组长'
                            }, {
                              id: 'leader',
                              text: '所属总监'
                            }
                          ]}
                          onChange={val => this.handleChange('decorate', index, val)} 
                        />
                      </span>
                    </> :
                    <SdwInput
                      isMultipleChoice={true}
                      multipleValue={person.related_field.split(';')}
                      multipleOption={allMembers}
                      width={'50%'}
                      placeholder="请输入值"
                      validateFun={this.validateArrFun}
                      sholdCheckValidate={sholdCheckValidate}
                      onClear={() => this.handleChange('related_field', index, '')} // 这里的onClear只是清除所有时才触发
                      changeMultipleValue={list => this.handleChange('related_field', index, list.join(';'))}
                    />
                  }
                </>
              }
              <i onClick={this.addPolicy} className="el-icon-plus"></i>
              {
                index > 0 && 
                <i onClick={() => this.removePolicy(index)} className="el-icon-minus"></i>
              }
              {
                index > 0 && <br/>
              }
            </span>
          ))
        }
      </>
    )
  }
}

SelectAddInputGroup.propTypes = {
  isEmpty: PropTypes.bool
}

export default SelectAddInputGroup