import {PureComponent} from 'react'
class AuthText extends PureComponent{
  displayText = () => {
    let code = this.props.code
    let text = ""
    switch (code) {
      case 5001:
        text = <span>您没有该表的访问权限，如需查询和使用该表，可以点击“<a style={{cursor:"pointer"}} onClick={()=>{
          this.props.showAuthApply(true)
        }
        }>申请权限</a>”</span>
        break
      case 5002:
        text = <span>该数据暂未开放，如有疑问可联系管理员(kurtgu)</span>
        break
      case 5003:
        text = <span>已提交权限申请，你可点击“<a style={{cursor:"pointer"}} target="_blank" href="http://sec.cm.com/MyRightApply/">我的权限申请单</a>”查看审批进展</span>
        break
      case 5004:
        text = <span>无效权限点，请联系管理员(kurtgu)处理</span>
        break
      case 5005:
        text = <span>存在其他审批中的数据源，请在审批完成后再提交申请
          <p>你可点击“<a style={{cursor:"pointer"}} target="_blank" href="http://sec.cm.com/MyRightApply/">我的权限申请单</a>”查看审批进展</p></span>
        break
      default:
        text = <span>无效权限点，请联系管理员(kurtgu)处理</span>
    }
    return text
  }
  render() {
    return (
        <div>
          {this.displayText()}
        </div>
    )
  }
}
export default AuthText
