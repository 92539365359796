import React, { createRef, PureComponent } from 'react'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwMessage from '@common/Message'

import { getTargetPage, getAllTablecfg, getTablecfgFieldsAndStandardList, storeOrUpdateDerive } from '@services/Derived'
import { getTargetCfgById } from '@services/application'

import '../style/EditIndicatorsData.css'
import AddIndicators from "./AddIndicators"
import AddFilter from './AddFilter'
import AddArea from './AddArea'
import FloatIndicators from "./FloatIndicators";
import { formatJsonParse } from "@utils/formatJson";
import _ from 'lodash'
import { CHECKED_OPTION, CHECKED_OPTION1, CHECKED_OPTION2, RATE_NAME_MAP, CNT_NAME_MAP, AVG_NAME_MAP } from '../util'

class EditIndicatorsData2 extends PureComponent {

  constructor(props) {
    super(props)
    this.addListRef = createRef()
  }

  state = {
    sourceIndicators: '',
    comments: '',
    rankDimensionData: [], // 排名维度option
    indexAttrTime: [], // 区间型-指标属性-时间周期
    rangeRankData: [], // 排名范围option
    // sourceFieldData: [], // 来源字段和范围字段
    formData: [{
      key: 'float_rate',
      metric_type: 'float_rate',
      enable: 0,
      text: '日环比',
      store_dimension: JSON.stringify([{ id: [] }]),
      cal_expression: JSON.stringify([{ time_cycle: "1d", float_type: ['day_annulus'] }]),
      group_by_field: JSON.stringify({ id: "", key: "", value: "" }),
      disabledCheck: false,
      isShow: true
    }, {
      key: 'float_cnt',
      metric_type: 'float_cnt',
      enable: 0,
      text: '近一天变化量',
      store_dimension: JSON.stringify([{ id: [] }]),
      cal_expression: JSON.stringify([{ time_cycle: "1d", float_type: ['day_annulus'] }]),
      group_by_field: JSON.stringify({ id: "", key: "", value: "" }),
      disabledCheck: false,
      isShow: true
    }, {
      key: 'daily_avg',
      metric_type: 'daily_avg',
      enable: 0,
      text: '日均值',
      store_dimension: JSON.stringify([{ id: [] }]),
      cal_expression: JSON.stringify([{ time_cycle: "mtd", float_type: ['day_avg'] }]),
      group_by_field: JSON.stringify({ id: "", key: "", value: "" }),
      disabledCheck: false,
      isShow: true
    }],
    addData: [],
    filterDataList: [],
    checkedOption: [],
    checkedOption1: [],
    checkedOption2: [],
    showAddList: false, // 展开新增列表
    timeSchedule: [],
    sholdCheckValidate: false,
  }

  componentDidMount() {
    const ID = this.props.record.curRecord.id
    document.addEventListener('click', this.handleGlobalClose)
    this.initData()
    this.onGetTargetCfgById(ID)
    this.onGetTablecfg()
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClose)
  }

  handleGlobalClose = e => {
    if (e.target.innerHTML === '新增') {
      return
    }
    const node = this.addListRef.current
    if (!node.contains(e.target)) {
      this.setState({
        showAddList: false,
      })
    }
  }

  // 增加按钮列表的显示隐藏
  showOrhideAddList = () => {
    this.setState({
      showAddList: !this.state.showAddList,
    })
  }

  // 指标项的内容的显示隐藏
  showOrhideContent = (item, index, type) => {
    const array = type === 'addData' ? _.cloneDeep(this.state.addData) : _.cloneDeep(this.state.formData)
    array[index].isShow = !array[index].isShow
    type === 'addData' ? this.setState({ addData: array }) : this.setState({ formData: array })
  }

  onGetTargetPage = record => {
    let ID = record.id
    if (!ID) return
    const params = {}
    params.search = JSON.stringify({
      id: {
        value: ID,
        operator: "="
      }
    })
  }

  initData = () => {
    const record = this.props.record.curRecord
    const ID = record.id
    // 初始化来源指标和描述
    const sourceIndicators = `${record.target_name_cn}(${record.target_name})`
    const comments = record.comments
    this.setState({
      comments,
      sourceIndicators
    })
    if (!ID) return
    const params = {}
    params.search = JSON.stringify({
      id: {
        value: ID,
        operator: "="
      }
    })

    getTargetPage(params).then(res => {
      let time_chedule = _.get(res, 'data.data.items[0].time_schedule')
      time_chedule = typeof time_chedule === 'string' ? formatJsonParse(time_chedule) : []
      let derivativeTargets = _.get(res, 'data.data.items[0].derivativeTargets')
      derivativeTargets = _.isArray(derivativeTargets) ? derivativeTargets : []
      this.setState({ timeSchedule: time_chedule })
      this.initCheckOption(time_chedule, derivativeTargets)
      const result = _.get(res, 'data.data.items[0].derivativeTargets')
      const formData = this.state.formData
      if (!result) {
        formData.forEach(ele => {
          ele.source_targets = ID + ''
          ele.store_dimension = formatJsonParse(ele.store_dimension)
          ele.cal_expression = formatJsonParse(ele.cal_expression)
          ele.group_by_field = formatJsonParse(ele.group_by_field)
        });
        return
      }

      // 筛选固定的三项和新增的若干项，setState
      let array1 = []

      let numOne = _.filter(result, { 'metric_type': 'float_rate' })
      // 当第一次新增时没启用的 后端不下发回前端 这时使用前端定义的初始化数据
      if (numOne.length === 0) numOne = _.filter(formData, { 'metric_type': 'float_rate' })

      let numTwo = _.filter(result, { 'metric_type': 'float_cnt' })
      if (numTwo.length === 0) numTwo = _.filter(formData, { 'metric_type': 'float_cnt' })

      let numThree = _.filter(result, { 'metric_type': 'daily_avg' })
      if (numThree.length === 0) numThree = _.filter(formData, { 'metric_type': 'daily_avg' })

      array1 = array1.concat(numOne, numTwo, numThree)
      const array2 = result.filter(ele => ele.metric_type !== "float_rate" && ele.metric_type !== "float_cnt" && ele.metric_type !== "daily_avg")
      // 初始化变化率、变化量型和均值型 与排名型和击败率型
      this.initArrayIndicator(array1, ID)
      // 对解析之后的array1进行组装（当前返回值是每一个变化类型对应一条数组对象，然而界面却是将变化型、变化率显示在同一个框下）
      let formatArr1 = this.formatArray1(array1)
      this.initArrayIndicator(array2, ID)
      this.setState({
        formData: formatArr1,
        addData: array2
      })
    })
  }

  initArrayIndicator = (arr, id) => {
    // 加上缺少的项 再把json转化下
    arr.forEach(ele => {
      ele.key = ele.id
      ele.source_targets = id + ''
      ele.time_schedule = formatJsonParse(ele.time_schedule)
      ele.store_dimension = formatJsonParse(ele.store_dimension)
      if (ele.metric_type != "filter") {
        ele.cal_expression = formatJsonParse(ele.cal_expression)
      }
      if (ele.metric_type != "range") {
        ele.group_by_field = formatJsonParse(ele.group_by_field)
      }
      ele.isShow = true
    });
  }

  // 依据接口 time_chedule 字段，提供变化率、变化量的变化类型显示个数
  initCheckOption = (arr, derivativeTargets) => {
    let olderTimeCycleList = derivativeTargets.reduce((prev, item) => {
      let cal_expression = formatJsonParse(item.cal_expression || '', [])
      if (_.isArray(cal_expression)) {
        prev = prev.concat(cal_expression.map(i => i.time_cycle))
      }
      prev = _.uniq(prev)
      return prev
    }, [])
    let option = CHECKED_OPTION.filter(i => arr.some(j => j.time_cycle === i.timeCycle) || olderTimeCycleList.includes(i.timeCycle))
    let option1 = CHECKED_OPTION1.filter(i => arr.some(j => j.time_cycle === i.timeCycle) || olderTimeCycleList.includes(i.timeCycle))
    let option2 = CHECKED_OPTION2.filter(i => arr.some(j => j.time_cycle === i.timeCycle) || olderTimeCycleList.includes(i.timeCycle))
    this.setState({
      checkedOption: option,
      checkedOption1: option1,
      checkedOption2: option2,
    })
  }

  formatArray1 = arr => {
    let list = []
    // 将metric_type分为三类："float_rate"、"float_cnt"、"daily_avg"
    let metricTypeMap = _.groupBy(arr, 'metric_type')
    _.keys(metricTypeMap).forEach(key => {
      let valArr = metricTypeMap[key]
      if (_.isArray(valArr) && valArr.length) {
        // 生成新的项，供界面交互
        let cal_expression_list = valArr.map(item => {
          return {
            id: item.id,
            enable: item.enable, // 是否勾选（该指标是否启用）
            time_cycle: _.get(item, 'cal_expression[0].time_cycle'),
            float_type: _.get(item, 'cal_expression[0].float_type[0]'),
            cal_expression: item.cal_expression
          }
        })
        let obj = _.cloneDeep(valArr[0])
        delete obj.id
        delete obj.cal_expression
        obj.cal_expression_list = cal_expression_list
        list.push(obj)
      }
    })
    return list
  }

  // 排名维度options
  onGetTargetCfgById = id => {
    getTargetCfgById({ id })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let record = res.data.data || {}
          let dimension = Array.isArray(record.dimension) ? record.dimension.filter(i => !!i.en) : []
          let curArr = dimension.map(i => {
            let text = `${i.cn}（${i.en}）`
            return {
              id: i.en,
              text: text
            }
          })
          this.setState({
            rankDimensionData: curArr
          })
        }
      })
  }

  // 来源表
  onGetTablecfg = () => {
    getAllTablecfg().then(res => {
      if (res && res.data && res.data.code === 0) {
        let curArr = Array.isArray(res.data.data) ? res.data.data : []
        curArr = curArr.map(i => {
          let info = !!i.database_name ? `${i.database_name}.${i.table_name}` : i.table_name
          return {
            id: i.id,
            text: `${i.data_set_chinese}（${info}）`
          }
        })
        this.setState({
          rangeRankData: curArr
        })
      }
    })
  }

  //同步过滤型的数据
  syncFilterDataList = (filterDataList) => {
    console.log("=====syncFilterDataList————————filterDataList", filterDataList)
    this.setState({filterDataList: filterDataList})
  }

  addIndicators = name => {
    let label = ""
    switch (name) {
      case "range":
        label = "区间型"
        break
      case "filter":
        label = "过滤型"
        break
      case "rank":
        label = "排名型"
        break
      default:
        label = "击败率型"
    }
    let array = [...this.state.addData]
    // 往formData里面push空的类型
    let obj = {
      key: `${name}${array.length}`,
      metric_type: name,
      metric_field:"",
      label: label,
      cal_expression:[{ "range_name":"", "gte":"", "lt":"" }],
      enable: 0,
      target_type:"",
      store_dimension: [{ id: [] }],
      group_by_field: { id: "", key: "", value: "" },
      time_schedule: [{ run_cycle: '', time_cycle: "" }],
      disabledCheck: false,
      isShow: true,
      target_name:"",
      target_name_cn:"",
    }
    if (name== "range") {
      obj.complex_script = ""
      obj.group_by_field = ""
    }
    array.push(obj)
    this.setState({
      showAddList: false,
      addData: array
    })
  }

  // 变化维度多选
  setChangeDimensions = (index, value) => {
    const array = _.cloneDeep(this.state.formData)
    array[index].store_dimension = _.map(value, i => ({ id: [i.id] }))
    if (array[index].store_dimension.length === 0) {
      array[index].enable = 0
    }
    this.setState({
      formData: array
    })
  }

  setCheckedValue = (index, item, value) => {
    let { formData } = this.state
    let cloneFormData = _.cloneDeep(formData)
    if (!cloneFormData[index].cal_expression_list) cloneFormData[index].cal_expression_list = []
    let cal_expression_list = cloneFormData[index].cal_expression_list
    let float_type = item.key
    let time_cycle = item.timeCycle

    // 通过 float_type、time_cycle 找到对应的位置，打勾或取消勾选
    let cal_index = _.findIndex(cal_expression_list, { float_type, time_cycle })
    if (cal_index === -1) {
      cloneFormData[index].cal_expression_list.push({
        enable: value,
        float_type: item.key,
        time_cycle: item.timeCycle,
        cal_expression: [{ // 接口数据格式
          time_cycle: item.timeCycle,
          float_type: [item.key]
        }]
      })
    } else {
      let cur = cal_expression_list[cal_index]
      if (cur.id) {
        cloneFormData[index].cal_expression_list[cal_index].enable = value
      } else {
        cloneFormData[index].cal_expression_list.splice(cal_index, 1)
      }
    }
    this.setState({
      formData: cloneFormData,
      sholdCheckValidate: false
    })
  }

  // 设置排名类型值
  setComplexScript = (index, value) => {
    const array = [...this.state.addData]
    array[index].complex_script = value
    this.setState({
      addData: array
    })
  }

  // 设置排名维度值
  setRankDimension = (index, value) => {
    const array = [...this.state.addData]
    array[index].store_dimension = !!value ? [{ id: [value] }] : [{ id: [] }]
    this.setState({
      addData: array
    })
  }

  //区间型-设置区间类型group_by_field的值 target_value 或 service_value
  setAddAreaValue = (index, type, value) => {
    const array = [...this.state.addData]
    const arrChangeOne = _.cloneDeep(array[index])
    switch (type) {
      case "target_name" :
        arrChangeOne.target_name = value
        break
      case "target_name_cn" :
        arrChangeOne.target_name_cn = value
        break
      case "group_by_field" :
        arrChangeOne.group_by_field = value
        break
      case "complex_script" :
        arrChangeOne.complex_script = value
        break
      case "metric_field" :
        arrChangeOne.metric_field = value
        break
      case "time_schedule" :
        let time_schedule = JSON.parse(this.props.record.curRecord.time_schedule)
        let time_schedules = []
        for (let i in time_schedule) {
          for (let j in value) {
            if (time_schedule[i].time_cycle == value[j].id) {
              time_schedules.push(time_schedule[i])
            }
          }
        }
        arrChangeOne.time_schedule = time_schedules
        break
    }
    arrChangeOne.enable = 0
    arrChangeOne.metric_type = "range"
    array[index] = arrChangeOne
    this.setState({
      addData: array
    })
  }

  //区间型-设置区间设置
  setAreaData = (val, type, index, k) => {
    const array = [...this.state.addData]
    let cal_expression = array[index].cal_expression
    switch (type) {
      case "range_name":
        cal_expression[k].range_name = val
        break
      case "gte":
        cal_expression[k].gte = val
        break
      case "lt":
        cal_expression[k].lt = val
        break
    }
    array[index].enable = 0
    array[index].cal_expression = cal_expression
    this.setState({ addData: array })
  }

  // 区间型-新增一项区间设置
  addVirtualArea = (index) => {
    const array = [...this.state.addData]
    let cal_expression = array[index].cal_expression
    cal_expression.push({ "range_name":"", "gte":"", "lt":"" });
    array[index].cal_expression = cal_expression
    array[index].enable = 0
    this.setState({ addData: array })
  }

  // 区间型-删除一项区间设置
  removeVirtualArea = (k, index) => {
    const array = [...this.state.addData]
    let cal_expression = array[index].cal_expression
    cal_expression.splice(k, 1);
    array[index].cal_expression = cal_expression
    array[index].enable = 0
    this.setState({ addData: array })
  }

  // 设置排名范围值group_by_field的id
  setRankRange = (index, value) => {
    const array = [...this.state.addData]
    const arrChangeOne = _.cloneDeep(array[index])
    const group_by_field = {
      id: value,
      key:'',
      value:''
    }
    arrChangeOne.group_by_field = group_by_field
    array[index] = arrChangeOne
    this.setState({
      addData: array
    })
  }

  // 设置来源字段或范围字段的值group_by_field的key 或 value
  setSourceOrRangeField = (index, value, propsName) => {
    const array = [...this.state.addData]
    array[index].group_by_field[propsName] = value
    this.setState({
      addData: array
    })
  }

  // 设置时间周期的值 time_schedule
  configDataChange = (val, type, k, index) => {
    const array = [...this.state.addData]
    let time_schedule = [...array[index].time_schedule]
    if (type === "time_schedule_time_cycle") {
      time_schedule[k].time_cycle = val
    } else if (type === "store_dimension_run_cycle") {
      time_schedule[k].run_cycle = val
    }
    array[index].time_schedule = time_schedule
    this.setState({ addData: array })
  }

  // 新增一项time_schedule
  addVirtualProperty2 = (index) => {
    const array = [...this.state.addData]
    let time_schedule = [...array[index].time_schedule]
    time_schedule.push({ run_cycle: '', time_cycle: '' });
    array[index].time_schedule = time_schedule
    this.setState({ addData: array })
  }

  // 删除一项time_schedule
  removeVirtualProperty2 = (k, index) => {
    const array = [...this.state.addData]
    let time_schedule = [...array[index].time_schedule]
    time_schedule.splice(k, 1);
    array[index].time_schedule = time_schedule
    this.setState({ addData: array })
  }

  validateIndicateItemValue = (item, index, type) => {
    // 区分是哪个指标 对应的指标check 不通过把对应指标的data里的disabledCheck置为true
    const arr = type === 'addData' ? [...this.state.addData] : [...this.state.formData]
    let flag = false
    if (item.metric_type === 'float_rate' || item.metric_type === 'float_cnt' || item.metric_type === 'daily_avg') {
      flag = _.get(item, 'store_dimension[0].id.length') > 0 && _.get(item, 'cal_expression_list.length') > 0
    } else if (item.metric_type === 'rank_rate' || item.metric_type === 'rank') {
      flag = item.store_dimension[0].id.length > 0 &&
        !!item.time_schedule[0].run_cycle &&
        !!item.time_schedule[0].time_cycle &&
        !!item.complex_script
    } else if (item.metric_type === 'range' ) {
      flag = item.store_dimension[0].id.length > 0 &&
        !!item.time_schedule.length > 0 &&
        !!item.time_schedule[0].time_cycle &&
        !!item.cal_expression.length > 0 &&
        !!item.group_by_field &&
        !!item.complex_script &&
        !!item.target_name &&
        !!item.target_name_cn
      for (let i in item.cal_expression) {
        let gte = Number(item.cal_expression[i].gte)
        let lt = Number(item.cal_expression[i].lt)
        if (isNaN(lt) || isNaN(gte)) {
          flag = false
        }
      }
      if (flag == true && item.group_by_field != "target_value") {
        flag = !!item.metric_field
      }
    }
    if (!flag) {
      arr[index].disabledCheck = true
    } else {
      arr[index].disabledCheck = false
    }
    type === 'addData' ? this.setState({ addData: arr }) : this.setState({ formData: arr })
    return flag
  }

  enableStateChange = (val, item, index, type) => {
    const array = type === 'addData' ? [...this.state.addData] : [...this.state.formData]
    if (val !== 0) {
      let flag = this.validateIndicateItemValue(item, index, type)
      if (!flag) return
    }
    array[index].disabledCheck = false //为 0 直接fasle 为1上边通过校验置为false
    array[index].enable = val
    type === 'addData' ? this.setState({ addData: array }) : this.setState({ formData: array })
  }

  getTargetName = (obj, type, item, indicateItem) => {

    // 由于同一个指标可以选不同的维度进行排序，因此自动生成命名时需拼接排名维度的前两位字母及排名范围前两位字母
    let str_store = _.get(item, 'store_dimension[0].id[0]') || ''
    let str_group = _.isString(item.group_by_field)?item.group_by_field:_.get(item, 'group_by_field.value') || ''
    let str_name = `${str_store.slice(0, 2)}${str_group.slice(0, 2)}`

    if (type === 'rank_rate') {
      if (item.complex_script === 'asc') {
        obj.target_name = `${indicateItem.target_name}_asc_${str_name}_rate_dbl`
        obj.target_name_cn = `${indicateItem.target_name_cn}_升序击败率_${str_name}`
      } else if (item.complex_script === 'desc') {
        obj.target_name = `${indicateItem.target_name}_desc_${str_name}_rate_dbl`
        obj.target_name_cn = `${indicateItem.target_name_cn}_降序击败率_${str_name}`
      }
    } else {
      if (item.complex_script === 'asc') {
        obj.target_name = `${indicateItem.target_name}_asc_${str_name}_rank_cnt`
        obj.target_name_cn = `${indicateItem.target_name_cn}_升序排名_${str_name}`
      } else if (item.complex_script === 'desc') {
        obj.target_name = `${indicateItem.target_name}_desc_${str_name}_rank_cnt`
        obj.target_name_cn = `${indicateItem.target_name_cn}_降序排名_${str_name}`
      }
    }
    return obj
  }

  getIndicateItem = (type, indicateItem, beAssignObj, item) => {
    if (type === 'rank_rate') {
      beAssignObj.metric_type = 'rank_rate'
    } else {
      beAssignObj.metric_type = 'rank'
    }
    beAssignObj.complex_script = item.complex_script
    beAssignObj.store_dimension = item.store_dimension
    beAssignObj.group_by_field = item.group_by_field
    beAssignObj.time_schedule = item.time_schedule
    beAssignObj.enable = item.enable
    beAssignObj.comments = this.state.comments
    return _.assign(indicateItem, beAssignObj)
  }

  setFloatIndicateItem = (obj, item, indicateItem, type) => {
    let float_type = _.get(item, 'cal_expression[0].float_type[0]')
    let time_cycle = _.get(item, 'cal_expression[0].time_cycle')

    if (type === 'float_rate') {
      // 对应自定义中、英文名
      let en = RATE_NAME_MAP[`${float_type}_${time_cycle}`].en
      let cn = RATE_NAME_MAP[`${float_type}_${time_cycle}`].cn
      obj.target_name = `${indicateItem.target_name}${en}`
      obj.target_name_cn = `${indicateItem.target_name_cn}${cn}`
    } else if (type === 'float_cnt') {
      // 对应自定义中、英文名
      let en = CNT_NAME_MAP[`${float_type}_${time_cycle}`].en
      let cn = CNT_NAME_MAP[`${float_type}_${time_cycle}`].cn
      obj.target_name = `${indicateItem.target_name}${en}`
      obj.target_name_cn = `${indicateItem.target_name_cn}${cn}`
    } else if (type === 'daily_avg') {
      // 对应自定义中、英文名
      let en = AVG_NAME_MAP[`${float_type}_${time_cycle}`].en
      let cn = AVG_NAME_MAP[`${float_type}_${time_cycle}`].cn
      obj.target_name = `${indicateItem.target_name}${en}`
      obj.target_name_cn = `${indicateItem.target_name_cn}${cn}`
    }

    obj.metric_type = type
    obj.store_dimension = item.store_dimension
    //cal_expression参数代表着一个衍生指标
    obj.cal_expression = JSON.stringify(item.cal_expression)

    //重要注释
    //每个衍生指标的time_cycle参数根据cal_expression中的time_cycle  1d来去筛选传过去
    //例如   1d日环比  7d周环比 1m月环比 这里的time_cycle指的是环比  传给接口的time_cycle指的是来源指标的时间周期参数
    let time_schedule = []
    let timeSchedule = this.state.timeSchedule
    console.log("=======timeSchedule", timeSchedule)
    console.log("=======time_cycle", time_cycle)
    for (let i in timeSchedule) {
      if (timeSchedule[i].time_cycle == time_cycle) {
        time_schedule.push(timeSchedule[i])
      }
    }
    console.log("=======obj.time_schedule", time_schedule)
    obj.time_schedule = JSON.stringify(time_schedule)
    console.log("=======obj.time_schedule", obj.time_schedule)
    obj.enable = item.enable
    obj.comments = this.state.comments
    return _.assign(indicateItem, obj)
  }

  setAreaIndicateItem = (obj, item, indicateItem) => {
    obj.target_type = 4
    obj.group_by_field = item.group_by_field  //区间类型
    obj.metric_field = obj.group_by_field == "target_value" ? "" : item.metric_field      //统计方式-区间字段
    obj.metric_type = item.metric_type        //统计方式-度量方式
    obj.complex_script = item.complex_script  //统计方式-度量方式
    obj.cal_expression = JSON.stringify(item.cal_expression) //区间设置
    obj.store_dimension = item.store_dimension //指标属性-维度
    obj.time_schedule = item.time_schedule //指标属性-时间周期
    obj.enable = item.enable
    obj.comments = this.state.comments
    obj.target_name = item.target_name //英文名
    obj.target_name_cn = item.target_name_cn //中文名
    return _.assign(indicateItem, obj)
  }

  setFilterIndicateItem = (obj, item, indicateItem, type) => {
    obj.target_type = 4
    obj.metric_type = "filter"
    obj.cal_expression = item.cal_expression //过滤语法
    obj.store_dimension = item.store_dimension //指标属性-维度
    obj.time_schedule = item.time_schedule //指标属性-时间周期
    obj.enable = item.enable
    obj.target_name = item.target_name //英文名
    obj.target_name_cn = item.target_name_cn //中文名
    return _.assign(indicateItem, obj)
  }

  formatData = arr => {
    let list = []

    _.forEach(arr, item => {
      let cal_expression_list = _.get(item, 'cal_expression_list') || []
      let cloneItem = _.cloneDeep(item)
      delete cloneItem.cal_expression_list
      console.log("=======cal_expression_list", cal_expression_list)
      _.forEach(cal_expression_list, i => {
        console.log("=======cal_expression_list_------------------i", i)
        console.log("=======cal_expression_list_------------------cloneItem", cloneItem)
        list.push({
          ...cloneItem,
          ...(i.id ? {
            id: i.id
          } : {}),
          enable: i.enable,
          cal_expression: i.cal_expression
        })
      })
    })

    return list
  }

  validateFun = record => {
    let isPass = true
    let { timeSchedule } = this.state

    for (let i = 0; i < record.length; i++) {
      const item = record[i];
      let cal_expression_list = _.get(item, 'cal_expression_list') || []
      let isStoreEmpty = !_.get(item, 'store_dimension[0].id[0]') // 变化维度是否为空

      // 非禁用的变化类型为空
      let isTimeCycleEmpty = cal_expression_list.filter(i => timeSchedule.some(j => j.time_cycle === i.time_cycle)).every(k => !k.enable)

      // 变化维度为空 && 非禁用的变化类型也为空，不需要校验
      if (!isTimeCycleEmpty && isStoreEmpty) {
        isPass = false
        break
      }
    }

    return isPass
  }

  // 清洗time_schedule参数只包含cal_expression中为 time_cycle
  clearTimeSchedule = obj => {
    let cloneObj = _.cloneDeep(obj)
    let cal_expression = formatJsonParse(_.get(cloneObj, 'cal_expression'))
    let time_schedule = formatJsonParse(_.get(cloneObj, 'time_schedule'))
    let cur_time_schedule = []

    if (_.isArray(cal_expression) && _.isArray(time_schedule)) {
      cur_time_schedule = time_schedule.filter(ts => cal_expression.some(ce => ce.time_cycle === ts.time_cycle))
    }
    cloneObj.time_schedule = JSON.stringify(cur_time_schedule)

    return cloneObj
  }

  onSureClick = () => {
    let { formData, addData, comments ,filterDataList} = this.state
    // 校验：这里只需要校验formData的，即变化率型和变化量型
    let isPass = this.validateFun(formData)
    if (!isPass) {
      this.setState({ sholdCheckValidate: true })
      return
    }
    let newFormData = this.formatData(formData)
    let params = {}
    let param = { ...this.props.record.curRecord }
    let arrayTemp = []
    //过滤addData 过滤型
    if (filterDataList.length > 0) {
      let addDataNew = []
      for (let i in addData) {
        if (addData[i].metric_type != "filter") {
          addDataNew.push(addData[i])
        }
      }
      addData = addDataNew.concat(filterDataList)
    }
    let data = newFormData.concat(addData)
    _.forEach(data, (item) => {
      let indicateItem = _.cloneDeep(this.props.record.curRecord)
      indicateItem = _.assign(indicateItem, {
        target_type: 4, // 衍生指标的target_type是 4,
      })
      let beAssignObj = {}
      indicateItem.source_targets = indicateItem.id + '' //设置source_targets为进来弹窗的指标的id
      delete indicateItem.derivativeTargets
      delete indicateItem.id
      indicateItem.id = item.id
      if (item.metric_type && item.metric_type.indexOf('rank_rate') !== -1) {
        beAssignObj = this.getIndicateItem('rank_rate', indicateItem, beAssignObj, item)
        beAssignObj = this.getTargetName(beAssignObj, 'rank_rate', item, indicateItem)
      } else if (item.metric_type.indexOf('rank') !== -1) {
        beAssignObj = this.getIndicateItem('rank', indicateItem, beAssignObj, item)
        beAssignObj = this.getTargetName(beAssignObj, 'rank', item, indicateItem)
      } else if (item.metric_type.indexOf('float_rate') !== -1) {
        beAssignObj = this.setFloatIndicateItem(beAssignObj, item, indicateItem, 'float_rate')
      } else if (item.metric_type.indexOf('float_cnt') !== -1) {
        beAssignObj = this.setFloatIndicateItem(beAssignObj, item, indicateItem, 'float_cnt')
      } else if (item.metric_type.indexOf('daily_avg') !== -1) {
        beAssignObj = this.setFloatIndicateItem(beAssignObj, item, indicateItem, 'daily_avg')
      } else if (item.metric_type.indexOf('range') !== -1) {
        beAssignObj = this.setAreaIndicateItem(beAssignObj, item, indicateItem)
        beAssignObj = this.getTargetName(beAssignObj, 'range', item, indicateItem)
      } else if (item.metric_type.indexOf('filter') !== -1) {
        beAssignObj = this.setFilterIndicateItem(beAssignObj, item, indicateItem)
        beAssignObj = this.getTargetName(beAssignObj, 'filter', item, indicateItem)
      } else {
        return
      }
      // 前端不做清洗处理
      // 清洗time_schedule：里面对应的不应该是全部的time_schedule，而是对应的cal_expression中的time_cycle
      // beAssignObj = this.clearTimeSchedule(beAssignObj)
      arrayTemp.push(beAssignObj)
    })
    param.comments = comments
    params.id = param.id
    params.field = param
    params.derivativeTargets = arrayTemp
    // 编辑数据
    console.log("=====params", params)
    storeOrUpdateDerive(params).then((res) => {
      if (res && res.data && res.data.code === 0) {
        let msg = '编辑成功'
        SdwMessage.success(msg)
        // 告诉父级点击了确定按钮
        this.props.onSureClick()
      } else {
        SdwMessage.error(res.data.msg);
      }
    })
  }

  render() {

    const {
      formData,
      addData,
      sourceIndicators,
      comments,
      rankDimensionData,
      rangeRankData,
      checkedOption,
      checkedOption1,
      checkedOption2,
      timeSchedule,
      sholdCheckValidate,
      filterDataList
    } = this.state

    const {
      cfgData,
      record
    } = this.props

    let baseInfo = record.curRecord
    let filterDataListNew = [...filterDataList]
    //filterDataList
    if (filterDataListNew.length == 0) {
      for (let i in addData) {
        if (addData[i].metric_type == "filter") {
          filterDataListNew.push(addData[i])
        }
      }
    }
    //console.log("=====edit, filterDataListNew", filterDataListNew)
    // runCycle
    let runCycle = []
    if (cfgData.hasOwnProperty("runCycle")) {
      runCycle = cfgData.runCycle.map(i => {
        return {
          id: i.id + '',
          text: i.name
        }
      })
    }
    // timeCycle
    let timeCycle = []
    if (cfgData.hasOwnProperty("timeCycle")) {
      timeCycle = cfgData.timeCycle.map(i => {
        return {
          id: i.id ,
          text: i.name
        }
      })
    }
    //console.log("===timeCycle", timeCycle)

    return (
      <div className="editorMain editorMain-Derived">
        <SdwDrawer
          title={this.props.title}
          visible={this.props.visible}
          onIconClick={this.props.onIconClick}
          onCancelClick={this.props.onCancelClick}
          onSureClick={this.onSureClick}
        >
          <SdwDrawer.Body>
            <div className="form data-editor-win-wrap indicators-source">
              <div className="formInput">
                <span className="label">来源指标</span>
                <div className="content">
                  <SdwInput
                    value={sourceIndicators}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="formInput">
                <span className="label">描述</span>
                <div className="content">
                  <SdwInput
                    type='textarea'
                    value={comments}
                    onChange={val => this.setState({ comments: val })}
                    placeholder="统计每个主管下的员工创建工单量的排名、统计员工维度的工单量的日环比、周同比"
                  />
                </div>
              </div>
              {/* 以下是衍生指标详细信息 */}
              <div className="formInput sdw-collapse-borderstyle">
                <div className="sdw-collapse">
                  {/* 循环输出固定的三项 */}
                  {
                    formData.map((ele, index) => {
                      return (
                        <FloatIndicators
                          key={ele.id || ele.key}
                          index={index}
                          item={ele}
                          dimensionTitle={ele.metric_type === 'daily_avg' ? '均值维度' : '变化维度'}
                          typeTitle={ele.metric_type === 'daily_avg' ? '均值类型' : '变化类型'}
                          checkedOption={ele.metric_type === 'float_rate' ? checkedOption : (ele.metric_type === 'float_cnt' ? checkedOption1 : checkedOption2)}
                          rankDimensionData={rankDimensionData}
                          setChangeDimensions={this.setChangeDimensions}
                          setCheckedValue={this.setCheckedValue}
                          enableStateChange={this.enableStateChange}
                          timeSchedule={timeSchedule}
                          sholdCheckValidate={sholdCheckValidate}
                          showOrhideContent={this.showOrhideContent} />
                      )
                    })
                  }
                  {
                    <AddFilter
                      filterDataList={filterDataListNew}
                      // key={item.id || item.key}
                      // index={index}
                      // item={item}
                      rankDimensionData={rankDimensionData}
                      rangeRankData={rangeRankData}
                      baseInfo={baseInfo}
                      syncFilterDataList={this.syncFilterDataList}
                      timeCycle={timeCycle}
                    />
                  }

                  {/* 循环输出 --》map */}
                  {
                    addData.map((item, index) => {
                      switch (item.metric_type) {
                        case "rank":
                          return (
                            <AddIndicators
                              key={item.id || item.key}
                              index={index}
                              item={item}
                              rankDimensionData={rankDimensionData}
                              rangeRankData={rangeRankData}
                              // sourceFieldData={sourceFieldData}
                              runCycle={runCycle}
                              timeCycle={timeCycle}
                              setComplexScript={this.setComplexScript}
                              setRankDimension={this.setRankDimension}
                              setRankRange={this.setRankRange}
                              setSourceOrRangeField={this.setSourceOrRangeField}
                              configDataChange={this.configDataChange}
                              addVirtualProperty2={this.addVirtualProperty2}
                              removeVirtualProperty2={this.removeVirtualProperty2}
                              enableStateChange={this.enableStateChange}
                              showOrhideContent={this.showOrhideContent} />
                          )
                         break
                        case "rank_rate":
                          return (
                            <AddIndicators
                              key={item.id || item.key}
                              index={index}
                              item={item}
                              rankDimensionData={rankDimensionData}
                              rangeRankData={rangeRankData}
                              // sourceFieldData={sourceFieldData}
                              runCycle={runCycle}
                              timeCycle={timeCycle}
                              setComplexScript={this.setComplexScript}
                              setRankDimension={this.setRankDimension}
                              setRankRange={this.setRankRange}
                              setSourceOrRangeField={this.setSourceOrRangeField}
                              configDataChange={this.configDataChange}
                              addVirtualProperty2={this.addVirtualProperty2}
                              removeVirtualProperty2={this.removeVirtualProperty2}
                              enableStateChange={this.enableStateChange}
                              showOrhideContent={this.showOrhideContent} />
                          )
                          break
                        case "filter":
                          break
                        default:
                          return <AddArea
                            key={item.id || item.key}
                            index={index}
                            item={item}
                            baseInfo={baseInfo}
                            rankDimensionData={rankDimensionData}
                            timeCycle={timeCycle}
                            showOrhideContent={this.showOrhideContent}
                            setRankDimension={this.setRankDimension}
                            configDataChange={this.configDataChange}
                            setAreaData={this.setAreaData}
                            addVirtualArea={this.addVirtualArea}
                            removeVirtualArea={this.removeVirtualArea}
                            enableStateChange={this.enableStateChange}
                            setAddAreaValue={this.setAddAreaValue}
                          />
                      }
                    })
                  }
                  <div className="sdw-collapse-listitem">
                    <div className="sdw-collapse-listitem-title sdw-collapse-listitem-title-increatment">
                      <div className='sdw-collapse-listitem-title-text'>
                        <span className='sdw-collapse-listitem-plus' onClick={this.showOrhideAddList}>新增</span>
                      </div>
                    </div>
                    <div ref={this.addListRef} className="sdw-collapse-list-wrap" style={{ 'display': this.state.showAddList ? 'block' : 'none' }}>
                      <div className="sdw-collapse-list__item" onClick={() => this.addIndicators('range')}>区间型</div>
                      {/*<div className="sdw-collapse-list__item" onClick={() => this.addIndicators('filter')}>过滤型</div>*/}
                      <div className="sdw-collapse-list__item" onClick={() => this.addIndicators('rank')}>排名型</div>
                      <div className="sdw-collapse-list__item" onClick={() => this.addIndicators('rank_rate')}>击败率型</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SdwDrawer.Body>
        </SdwDrawer>
      </div>
    )
  }
}

export default EditIndicatorsData2

// 对于复杂的页面，需要拆分组件，此时有以下步骤
// 1、写静态页面（重构）
// 2、把重构页面转换成React页面
// 3、把能循环输出的先处理（数组的map）
// 4、用组件当中的变量替换静态页面中写死的数据
