
import { getStandardSelects } from '@services/metaData'

// 获取所有的字段标准数据选项列表
export const getTopic = async () => {
  let obj = {
    standardSelctDataDic: {},
    topicDataList: []
  }

  await getStandardSelects().then(res => {
    if (res && res.data && res.data.code === 0) {
      let data = res.data.data
        
      // 字段标准解析
      let standardData = data.standard
      let standardDataDic = {}
      let topicHasOtherDic = {}
      for(let i=0; i<standardData.length; i++){
        let businessId = standardData[i].business_id
        let topic = standardData[i].topic
        if(!businessId || businessId.toString() == ""){
          businessId = "0"
        }
        let id = standardData[i].id
        let label = standardData[i].field_name_ch + "[" + standardData[i].field_name + "]"
        let item = {
          "id": id,
          "text": label
        }
        
        if(!standardDataDic.hasOwnProperty(businessId)) {
          standardDataDic[businessId] = []
        }
        standardDataDic[businessId].push(item)
        obj.standardSelctDataDic[id] = {
          "selectList": [parseInt(topic), parseInt(businessId), parseInt(id)],
          "content": label,
          "field_define": standardData[i].field_define,
          "id": id
        }
        if (businessId == "0") {
          if(!topicHasOtherDic.hasOwnProperty(topic)) {
            topicHasOtherDic[topic] = []
          }
          topicHasOtherDic[topic].push(item)
        }
      }
  
      // 主题下的业务解析
      let businessData = data.business
      let businessDataDic = {}
      for(let i=0; i<businessData.length; i++){
        let topicId = businessData[i].topic_id
        let id = businessData[i].id
        let label = businessData[i].business_name
        let item = {
          "id": id,
          "text": label,
          "children": []
        }
        if(standardDataDic.hasOwnProperty(id)){
          item["children"] = standardDataDic[id]
        }
        if(topicId.toString() == ""){
          topicId = "0"
        }
        if(!businessDataDic.hasOwnProperty(topicId)) {
          businessDataDic[topicId] = []
        }
        businessDataDic[topicId].push(item)
      }
  
      // 主题数据的解析
      let topicData = data.topic
      for(let i=0; i<topicData.length; i++){
        let id = topicData[i].id
        let label = topicData[i].topic_name
        let item = {
          "id": id,
          "text": label,
          "children": []
        }
        if(businessDataDic.hasOwnProperty(id)){
          item["children"] = businessDataDic[id]
        }
        obj.topicDataList.push(item)
      }
  
      // 给每个主题加入其他业务
      for(let i=0; i<obj.topicDataList.length; i++){
        let topicId = obj.topicDataList[i].value
        let item = {
          "id": 0,
          "text": "其他",
          "children": []
        }
        if (topicHasOtherDic.hasOwnProperty(topicId)) {
          item.children = topicHasOtherDic[topicId]
        }
        obj.topicDataList[i].children.push(item)
      }
    }
  })

  return obj
}