import React, {PureComponent} from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import SdwMessage from '@common/Message'
import {getStaffs} from '@api/alertRules'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../../index.css'
import {requestOptionsLOG} from '@config/config'
const baseURL = requestOptionsLOG.basename

class CollectionWin extends PureComponent {

    state = {
        params: {
            inputTag: '',
            inputName: '',
            businessTopicId: '',
            businessTopic: '',
            businessId: '',
            business: '',
            principal: '',
            extra: '',
            enable: false,
            rtx: ''
        },
        staffsList: [],
        allMembers: [], // rtx
        sholdCheckValidate: false,
    }

    componentDidMount() {
        this.initData()
        this.getStaffs()
    }

    initData = () => {
        let {isEdit, currentItem} = this.props
        if (isEdit) {
            let params = {
                id: currentItem.id,
                inputTag: currentItem.inputTag,
                inputName: currentItem.inputName,
                businessTopicId: currentItem.businessTopicId,
                businessTopic: currentItem.businessTopic,
                businessId: currentItem.businessId,
                business: currentItem.business,
                principal: "",
                extra: currentItem.extra || "",
                enable: currentItem.enable,
                rtx: this.props.userInfo.name
            }
            this.setState({
                params: params,
                staffsList: (currentItem.principal.split(',') || []).map(i => {
                    return {id: i, text: i}
                })
            })
        } else {
            this.queryMaxConfigId()
        }
    }

    // 获取rtx
    getStaffs = () => {
        getStaffs().then(res => {
            if (res && res.data && res.data.code === 0) {
                let list = _.isArray(res.data.data) ? res.data.data : []
                this.setState({
                    allMembers: list.map(i => {
                        return {id: i.value, text: i.name}
                    })
                })
            }
        })
    }

    // 查询最大配置id
    queryMaxConfigId = () => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/inputTag/queryMaxConfigId',
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
                res.json().then((data) => {
                    let maxConfigId = data.data || ""
                    let inputTag = ""
                    if (maxConfigId.toString().length === 1) {
                        inputTag = 'KF_LOG_' + '100' + (maxConfigId + 1)
                    } else if (maxConfigId.toString().length === 2) {
                        inputTag = 'KF_LOG_' + '10' + (maxConfigId + 1)
                    } else if (maxConfigId.toString().length === 3) {
                        inputTag = 'KF_LOG_' + '1' + (maxConfigId + 1)
                    } else if (maxConfigId.toString().length >= 4) {
                        inputTag = 'KF_LOG_' + (maxConfigId + 1)
                    }
                    this.setParamsData('inputTag', inputTag)
                })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    setParamsData = (type, value) => {
        let {params} = this.state
        let obj = {}
        obj[type] = value
        let paramsClone = Object.assign({}, params, obj)
        this.setState({
            params: paramsClone
        })
    }

    // 更新或写入采集标识信息
    submitClick = () => {
        let {params, staffsList} = this.state
        let rtx = this.props.userInfo.name
        if (!params.inputTag || !params.inputName || !params.businessTopicId || !params.businessId || staffsList.length == 0) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        params.principal = staffsList.map(i => {
            return i.id
        }).join(",")
        fetch(baseURL + '/sdw/filebeat/inputTag/upsertInputTag',
            {
                method: "POST",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                if (data && data.code === 100) {
                    this.props.iniDataList()
                    this.props.changeVisible(false)
                } else {
                    SdwMessage.error(data.message)
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    render() {

        let {
            params,
            staffsList,
            allMembers,
            sholdCheckValidate
        } = this.state

        let {
            isEdit,
            collectionVisible,
            changeVisible
        } = this.props

        let topicData = this.props.topicData.map(i => {
            return {
                id: i.id,
                text: i.topic_name
            }
        })

        let businessData = this.props.businessData.filter(i => i.topic_id == params.businessTopicId).map(el => {
            return {
                id: el.id,
                text: el.business_name
            }
        })

        return (
            <SdwDrawer
                title={isEdit ? "编辑采集标识" : "新增采集标识"}
                visible={collectionVisible}
                onIconClick={() => changeVisible(false)}
                onCancelClick={() => changeVisible(false)}
                onSureClick={this.submitClick}
            >
                <SdwDrawer.Body>
                    <div className='logAccess-win_warp'>
                        <div>
                            <span className='label required'>采集标识</span>
                            <SdwInput
                                placeholder='请输入采集标识'
                                value={params.inputTag}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '采集标识不能为空'}
                                onChange={val => this.setParamsData('inputTag', val)}
                            />
                        </div>
                        <div>
                            <span className='label required'>中文名称</span>
                            <SdwInput
                                placeholder='请输入中文名称'
                                value={params.inputName}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '中文名称不能为空'}
                                onChange={val => this.setParamsData('inputName', val)}
                            />
                        </div>
                        <div>
                            <span className='label required'>主题域</span>
                            <SdwSelect
                                placeholder='请选择主题域'
                                value={params.businessTopicId}
                                data={topicData}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '主题域不能为空'}
                                onChange={(val, item) => {
                                    this.setState({
                                        params: Object.assign({}, params, {
                                            businessTopicId: val,
                                            businessTopic: item.text
                                        })
                                    })
                                }}
                            />
                        </div>
                        <div>
                            <span className='label required'>业务</span>
                            <SdwSelect
                                placeholder={!params.businessTopicId ? '请先选择主题域，再选择业务' : '请选择'}
                                value={params.businessId}
                                data={businessData}
                                disabled={!params.businessTopicId}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '业务不能为空'}
                                onChange={(val, item) => {
                                    this.setState({
                                        params: Object.assign({}, params, {
                                            businessId: val,
                                            business: item.text
                                        })
                                    })
                                }}
                            />
                        </div>
                        <div>
                            <span className='label required'>负责人</span>
                            <SdwSelect
                                readOnly={false}
                                isMultipleChoice={true}
                                placeholder='请输入负责人'
                                data={allMembers}
                                value={staffsList}
                                onChange={val => this.setState({staffsList: val})}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value.length ? true : '负责人不能为空'}
                            />
                        </div>
                        <div>
                            <span className='label'>备注</span>
                            <SdwInput
                                type='textarea'
                                placeholder='请输入'
                                value={params.extra}
                                onChange={val => this.setParamsData('extra', val)}
                            />
                        </div>
                        <div>
                            <span className='label required'>是否有效</span>
                            <SdwSwitch
                                value={params.enable}
                                changeValue={val => this.setParamsData('enable', val)}
                            />
                        </div>
                    </div>
                </SdwDrawer.Body>
            </SdwDrawer>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(CollectionWin))
