import React, { useState, useEffect, useLayoutEffect } from 'react'
import _ from 'lodash'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import SdwTable from '@common/Table'
import SdwPagination from '@common/Pagenation'
import SdwButton from '@common/Button'
import SdwEnable from '@common/Enable'
import SdwMessage from '@common/Message'
import AddWin from './AddWin'
import { tablePages, selectTableList, getPublicCfg} from '@services/midTable'
import { hasPriv } from '@components/FrontendAuth/AuthMap'
import ReRunWin from './ReRunWin'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'

function MidTable(props) {

  const [showAddWin, setShowAddWin] = useState(false)
  const [reRunVisible, setReRunVisible] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [searchObj, setSearchObj] = useState({
    page: props.pageInfo || 1,
    limit: 10,
    search: {}
  })
  const [curClickData, setCurClickData] = useState({})
  const [tableNameArr, setTableNameArr] = useState([])
  const [tableName, seTableName] = useState("")

  const [midTableList, setMidTableList] = useState([])

  useLayoutEffect(() => {
    initData()
    getPublicCfgs()
    // tablePages().then(res => {
    //   const code = _.get(res, 'data.code')
    //   if (code === 0) {
    //     const midTableList = _.get(res, 'data.data')
    //     setMidTableList(midTableList)
    //   }
    // })
  }, [])
  // 获取所有计算方式选项列表
  useLayoutEffect(() => {
    selectTableList().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        if (!_.isEmpty(data)) {
          console.log("selectTableList========", data.map((item) => {
            return {id:item.id,database_name:item.database_name,data_set_chinese:item.data_set_chinese,text:item.database_name+"."+item.table_name+"("+item.data_set_chinese+")"}
          }))
          setTableNameArr(data.map((item) => {
            return {id:item.id,database_name:item.database_name,data_set_chinese:item.data_set_chinese,text:item.database_name+"."+item.table_name+"("+item.data_set_chinese+")"}
          }))
        }
      } else {
        const msg = _.get(res, 'data.msg') || '接口异常'
        SdwMessage.err(msg)
      }
    }).catch(err => SdwMessage.err('接口异常' + err))
  }, [])

  useEffect(() => {
    initData()
  }, [searchObj])

  function initData() {
    const { page, limit, search } = searchObj
    console.log("initData=====search", search)
    let isPublishState = 0
    if (search.hasOwnProperty("publish_state") && (search.publish_state == 0 || search.publish_state == 1) ) {
      isPublishState = 1
    }
    tablePages({
      page,
      limit,
      search: {
          ...isPublishState ? {
          publish_state: {
            value: search.publish_state,
            operator: '='
          }
        } : {},
        ...search.id ? {
          id: {
            value: search.id,
            operator: '='
          }
        } : {},
        ...search.table_name ? {
          database_name: {
            value: search.table_name.database_name,
            operator: 'like'
          }
        } : {},
        ...search.job_name ? {
          job_name: {
            value: search.job_name,
            operator: 'like'
          }
        } : {}
      }
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const midTableList = _.get(res, 'data.data')
        if (!_.isEmpty(midTableList)) setMidTableList(midTableList)
      } else {
        const msg = _.get(res, 'data.msg') || '接口异常'
        SdwMessage.err(msg)
      }
    }).catch(err => SdwMessage.err('接口异常' + err))
  }
  function getPublicCfgs () {
    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }
        props.updateCfgData(cfgData)
      }
    })
  }
  function updateSearchObj(key, val) {
    let cloneObj = _.cloneDeep(searchObj)
    cloneObj[key] = val
    setSearchObj(cloneObj)
  }

  function updateSearchObjBySearch(key, val) {
    let cloneObj = _.cloneDeep(searchObj)
    cloneObj.search[key] = val
    if (key != 'page') {
      cloneObj['page'] = 1
    }
    setSearchObj(cloneObj)
  }

  function getColumns() {
    return [
      {
        title: "ID",
        dataIndex: "id",
        width: 60
      },
      {
        title: "任务名称",
        dataIndex: "job_name",
        width: 160
      },
      {
        title: "表名称",
        width: 200,
        render(data){
          return (
            <span>{`${data.database_name??"-"}`+'.'+`${data.table_name??"-"}`+'('+`${data.data_set_chinese??"-"}`+')'}</span>
          )
        }
      },
      {
        title: "业务/主题域",
        dataIndex: "topic",
        width: 100
      },
      {
        title: "发布状态",
        width: 100,
        render: function(data) {
          return (
            <>
              <span>{_.isEqual(typeof data.publish_state, 'number') ?
                (data.publish_state ? '已发布' : '未发布') : '-'}</span>
            </>
          )
        }
      },
      {
        title: "创建人",
        dataIndex: "create_user",
        width: 100
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        width: 180
      },
      {
        title: "修改时间",
        dataIndex: "modify_time",
        width: 180
      },
      {
        title: "操作",
        width: 100,
        render: data => {
          return (
            <div className="editorMenu-Derived">
              {/* 编辑权限放开，都可以点击编辑进入弹窗查看数据 */}
              <SdwButton
                text="编辑"
                onClick={() => {
                  props.history.push({
                    pathname: '/admin/dataCollect/editMidTable/'+data.id,
                    state: data
                  })
                }}
              />

              <SdwButton
                text="重跑"
                onClick={() => {
                  setCurClickData(data)
                  setReRunVisible(true)
                }}
              />
            </div>
          )
        }
      }
    ]
  }

  const { publish_state, id, job_name } = searchObj.search || {}
  return (
    <>
      <SdwBreadCrumbs
        history={props.history}
        data={[
          {
            title: '数据采集地',
            path: '/admin/dataCollect/logManagement'
          }, {
            title: '中间表管理'
          }
        ]}
      />

      <div className="page-mainbody">
        <div className="page-search">
          <div className="input-wrap">
            <div className="ele-search-input">
              <SdwInput
                  width={250}
                  label="任务ID"
                  value={id}
                  onChange={val => updateSearchObjBySearch('id', val)}
              />
            </div>
            <div className="ele-search-input">
              <SdwInput
                width={250}
                label="任务名称"
                value={job_name}
                onChange={val => updateSearchObjBySearch('job_name', val)}
              />
            </div>
            <div className="ele-search-input">
              <SdwSelect
                value={tableName}
                label="落地表名称"
                width={160}
                readOnly={false}
                data={tableNameArr}
                onChange={(val, item) => {
                  seTableName(val)
                  console.log("+======item", item)
                  updateSearchObjBySearch('table_name', item)
                }}
              />
            </div>
            <div className="ele-search-input">
              <SdwSelect
                value={publish_state}
                width={160}
                label="发布状态"
                data={[
                  {
                    id: 1,
                    text: '已发布'
                  }, {
                    id: 0,
                    text: '未发布'
                  }
                ]}
                onChange={val => updateSearchObjBySearch('publish_state', val)}
              />
            </div>
            {
              hasPriv('setMonitorServerConfig') &&
              <div className="btn-group">
                <SdwButton
                  type='submit'
                  text='添加'
                  iconClass='iconfont icon-add'
                  width={120}
                  onClick={ () => {
                    setShowAddWin(true)
                    setIsEdit(false)
                    setCurClickData({})
                  } }
                />
              </div>
            }
          </div>
        </div>

        <div className="page-table">
          <SdwTable
            columns={ getColumns() }
            dataSource={ midTableList.items || [] }
          />
          {
            _.isArray(midTableList.items) && midTableList.items.length &&
            <div className="data-management__pagenation-wrap">
              <SdwPagination
                total={ midTableList.total }
                currentPage={ midTableList.current_page }
                pageCountList={[10, 15, 20, 40]}
                pageCount={ midTableList.per_page }
                getClickPage={ val => updateSearchObj('page', val) }
                pageCountChange={ val => updateSearchObj('limit', val) }
              />
            </div>
          }
        </div>

        {
          showAddWin &&
          <AddWin
            isEdit={isEdit}
            title={isEdit ?  '编辑中间表任务' : '新增中间表任务' }
            showAddWin={showAddWin}
            data={curClickData}
            changeShowAddWin={bool => setShowAddWin(bool)}
            updatePage={initData}
          />
        }
        {
          reRunVisible && <ReRunWin
            record={curClickData}
            reRunVisible={reRunVisible}
            changeReRunVisible={setReRunVisible}
          />
        }
      </div>
    </>
  )
}

const mapState = state => ({
  pageInfo: state.page.pageInfo,
  cfgData: state.midTableView.cfgData,
})

const mapDispatch = dispatch => ({
  updateCfgData:dispatch.midTableView.updateCfgData,
})

export default withRouter(connect(mapState, mapDispatch)(MidTable))
