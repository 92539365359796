import { useState, useEffect, useMemo, Fragment } from "react";
import PropTypes from 'prop-types'
import { isArray, isEmpty } from 'lodash'
import { openNewWin } from "@utils/request";
import CopyText from '@common/CopyText'
import callImg from '../img/call.svg'
import './index.css'

const aiBuleOperType = ['4', '5', '7', '8','9']
const ivrBuleOperType = ['4', '21', '22']
const imcBuleOperType = ['2']

function isBuleBackground(type = '', item = {}) {
  if (!type || isEmpty(item)) return false

  // 当type为AI，operType为4时，即显示来源时，底色为蓝色
  // 当type为AI，operType为5时，即显示为进入风险看板时，底色为蓝色
  if (type === 'AI' && aiBuleOperType.includes(item['operType'])) {
    return true
  }

  // IVR模块: 当oper_type为22、21、4时，底色显示为蓝色
  if (type === 'IVR' && ivrBuleOperType.includes(item['oper_type'])) {
    return true
  }

  // ASYNC模块: 异步模块都使用蓝色底色
  if (type === 'ASYNC') {
    return true
  }

  // IMC模块: 当event为2时，状态为接入人工，底色为蓝色
  if (type === 'IMC' && imcBuleOperType.includes(item['event'])) {
    return true
  }

  return false
}

function Collapse(props) {
  const {
    list = [],
    config = {
      keys: [], // 显示字段，从左到右
      widths: [], // 显示字段对应的width
      contextKey: '', // context 对应字段
      contextKeys: [], // 每一行展示字段
      contextwidths: [], // 每一行展示字段对应width
    }
  } = props

  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(false)
  }, [list])

  const conKeys = useMemo(() => {
    return isArray(config.keys) ? config.keys : []
  }, [config.keys])

  const conWidths = useMemo(() => {
    return isArray(config.widths) ? config.widths : []
  }, [config.widths])

  const contextKeys = useMemo(() => {
    return isArray(config.contextKeys) ? config.contextKeys : []
  }, [config.contextKeys])

  const contextwidths = useMemo(() => {
    return isArray(config.contextwidths) ? config.contextwidths : []
  }, [config.contextwidths])


  return (
    isArray(list) &&
    list.map((item, firstIndex) => {
      const contextArr = []
      if (!isEmpty(item[config.contextKey])) {
        for (const key in item[config.contextKey]) {
          if (Object.hasOwnProperty.call(item[config.contextKey], key)) {
            const value = item[config.contextKey][key];
            if (!isEmpty(value)) contextArr.push(value)
          }
        }
      }

      const itemType = item.type
      const itemTaskID = item.task_id
      // console.log('---------contextArr: ', itemType, contextArr);

      return (
        <div key={firstIndex} className='comp-collapse-item-wrap'>
          <div
            className='header-item-wrap'
            onClick={() => setIsActive(r => !r)}
          >
            <div className='title-wrap'>
              {
                isArray(conKeys) &&
                conKeys.map((key, keyIndex) => {
                  const archivepathNameHasTaskID = key === 'archivepath_name' && !!item.task_id

                  return (
                    <span
                      key={keyIndex}
                      style={{
                        display: 'inline-block',
                        width: conWidths[keyIndex] || 'auto',
                        ...archivepathNameHasTaskID ? {
                          color: '#265CF0',
                          cursor: 'pointer'
                        } : {}
                      }}
                      onClick={e => {
                        if (archivepathNameHasTaskID) {
                          e.stopPropagation()
                          openNewWin(`https://cd.cm.com/web/kfwp/indexNew?&hash=/taskDetail/${item.task_id}`)
                        }
                      }}
                    >{ item[key] || '' }</span>
                  )
                })
              }
              <span
                className={`iconfont icon-arrow-right ${isActive ? 'is-active' : ''}`}
              />
            </div>
          </div>

          <div className={`body-context-wrap ${isActive ? 'is-active' : ''}`}>
            {
              isArray(contextArr) &&
              contextArr.map((item, index) => (
                <div key={index} className='context-wrap'>
                  {
                    isArray(contextKeys) &&
                    contextKeys.map((key, keyIndex) => (

                        !contextwidths[keyIndex] ?
                        <Fragment key={key + keyIndex}>
                          <div
                            className='div-wrap'
                            style={{
                              position: 'relative',
                              flex: '1 1 auto',
                              backgroundColor: isBuleBackground(itemType, item) ? 'rgba(38,92,240,0.08)' : 'rgba(102,102,102,0.08)'
                            }}
                          >
                            {
                              itemType === 'IVR' && !!item.imc_num && item.imc_num != "0" &&
                              <span>
                                <img src={callImg} style={{
                                  position: 'relative',
                                  top: '2px',
                                  marginRight: 4
                                }} />
                                { `${item.imc_num} - ` }
                              </span>
                            }
                            <span dangerouslySetInnerHTML={{ __html: item[key] }} ></span>
                            {
                              itemType !== 'ASYNC' && !!itemTaskID &&
                              (
                                (itemType === 'IVR' && item.oper_type == '7') ||
                                (itemType === 'ALLINONE')
                              ) &&
                              <CopyText copyText={itemTaskID} />
                            }
                          </div>
                        </Fragment> :
                        <Fragment key={key + keyIndex}>
                          <div
                            className='div-wrap'
                            style={{
                              flex: `0 0 ${contextwidths[keyIndex]}px`,
                              color: '#666',
                              textAlign: 'right'
                            }}
                          >
                            <span>{ item[key] }</span>
                          </div>
                        </Fragment>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      )
    })
  )
}

Collapse.propTypes = {
  list: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
}

export default Collapse
