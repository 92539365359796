import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import './index.css'

const ROW = props => {
  Layout.customObj.gutter = props.gutter

  return (
    <div className="sdw__row-wrap" style={{
      display: 'flex',
      justifyContent: props.justigy || 'flex-start',
      boxSizing: 'border-box',
      marginTop: props.marginTop,
      marginBottom: props.marginBottom
    }}>{props.children}</div>
  )
}

const COL = props => (
  <div className="sdw__col-wrap" style={{
    display: 'inline-block',
    boxSizing: 'border-box',
    width: `${props.span / 24 * 100}%`,
    margin: `0 ${Layout.customObj.gutter / 2}px`
  }}>{props.children}</div>
)

class Layout extends React.PureComponent {

  render () {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

COL.propTypes = {
  span: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 占比宽度：span/24
}

ROW.propTypes = {
  gutter: PropTypes.number, // 单位是：px
  justigy: PropTypes.string
}

Layout.Row = ROW
Layout.Col = COL
Layout.customObj = {}

export default Layout