// @file审计详情界面
import { useState, useEffect, useLayoutEffect } from "react"
import BreadCrumbs from '@common/BreadCrumbs'
import Empty from "@common/Empty"
import Tabs from "@common/Tabs"
import Button from "@common/Button"
import Message from "@common/Message"
import './auditInfoPage.css'
import { getMessage, applyApproval, getProcessApproval, getTopicBusiness } from '@services/auditInfoPage'
import { commonApi } from "@utils/request"
import _ from "lodash"
import { formatJsonParse } from "@utils/formatJson"
import { hasPriv } from "@components/FrontendAuth/AuthMap"
import {
  ALL, PENDING, STATE_MAP, UPDATE_CONTEXT_MAP, TARGET_TYPE_MAP, MSG_TYPE_MAP
} from '@pages/common/index'
import Pagenation from "@common/Pagenation"
import Loadding from "@common/Loadding"
import { getAllAtomTarget } from '@services/Derived'

export default function AuditInfoPage(props) {

  const [messageArr, setMessageArr] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [curStatus, setCurStatus] = useState(ALL);
  const [isLoading, setIsLoading] = useState(false);
  const [approvalTotal, setApprovalTotal] = useState(0);
  const [stomMap, setStomMap] = useState({}); // 原子指标id-text map映射
  const [topicMap, setTopicMap] = useState({});
  const [businessMap, setBusinessMap] = useState({});

  useEffect(() => {
    onInit({})
  }, [])

  useLayoutEffect(() => {
    getAllAtomTarget().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const arr = _.get(res, 'data.data') || []
        const obj = arr.reduce((obj, i) => {
          obj[i.id] = `${i.target_name}(${i.target_name_ch})`
          return obj
        }, {})
        setStomMap(obj)
      } else {
        Message.error('获取原子指标失败 ' + (_.get(res, 'data.msg') || ''))
      }
    })
  }, []);

  useLayoutEffect(() => {
    commonApi(getTopicBusiness, {}).then(data => {
      // console.log('getTopicBusiness: ', data);
      let arr = _.isArray(data) ? _.cloneDeep(data) : []
      let topicArr = []
      let bussinessArr = []
      while (!!arr.length) {
        const item = arr.shift()
        const itemChildren = _.get(item, 'children') || []
        bussinessArr.push(...itemChildren)
        delete item.children
        topicArr.push(item)
      }

      // 将主题、业务映射为id-text
      topicArr = topicArr.reduce((obj, i) => {
        obj[i.value] = i.label
        return obj
      }, {})
      bussinessArr = bussinessArr.reduce((obj, i) => {
        obj[i.value] = i.label
        return obj
      }, {})
      // console.log('topicArr: ', topicArr);
      // console.log('bussinessArr: ', bussinessArr);
      setTopicMap(topicArr)
      setBusinessMap(bussinessArr)
    }).catch(err => Message.error('获取主题域、业务失败 ' + err))
  }, [])

  useLayoutEffect(() => {
    commonApi(getProcessApproval, {
      limit: 10,
      page: 1,
      search: JSON.stringify({})
    }).then(data => {
      let total = _.get(data, 'total')
      setApprovalTotal(total)
    })
  }, [])

  function onInit({
    per_page = 10,
    current_page = 1,
    search = {},
    status = curStatus
  }) {
    // 待处理走 getProcessApproval 接口
    const API = status === ALL ? getMessage : getProcessApproval
    setIsLoading(true)
    commonApi(API, {
      limit: per_page,
      page: current_page,
      search: JSON.stringify(search)
    }).then((data = {}) => {
      let arr = _.get(data, 'items')
      arr = _.isArray(arr) ? arr : []
      setMessageArr(arr)
      setPageInfo({
        current_page: data.current_page || 1,
        per_page: data.per_page || 10,
        total: data.total || 0,
      })

      if (status === PENDING) {
        let total = _.get(data, 'total')
        setApprovalTotal(total)
      }
    }).catch(err => Message.error(err))
    .finally(() => setIsLoading(false))
  }

  function handleChange(item) {
    const curStatus = _.get(item, 'path') || ALL
    setCurStatus(curStatus)
    // console.log('curStatus: ', curStatus);

    setPageInfo({
      current_page: 1,
      per_page: 10,
      total: 0,
    })

    setTimeout(() => {
      onInit({
        status: curStatus
      })
    }, 0);
  }

  function onapplyApproval(params) {
    commonApi(applyApproval, params).then(data => {
      Message.success('审批成功')
      onInit({})
    }).catch(err => Message.error('审批失败 ' + err))
  }

  function updatePageInfo(key, val) {
    let clonePageInfo = _.cloneDeep(pageInfo)
    clonePageInfo[key] = val
    setPageInfo(clonePageInfo)

    onInit(_.assign({}, pageInfo, {
      [key]: val
    }))
  }

  return (
    <div className="page-main__wrap page__audit-info-page">
      <BreadCrumbs
        history={props.history}
        data={[
          {
            title: '指标加工厂',
            path: '/admin/factory/atom'
          }, {
            title: '消息中心'
          }
        ]}
      />

      <Loadding loadding={isLoading}>
        <div style={{
          background: "#fff"
        }}>
        {
          (curStatus === ALL && (!_.isArray(messageArr) || messageArr.length === 0)) ?
          <Empty text='暂无消息'/> :
          <div>
            <div className="header">
              <Tabs
                menu={
                  [
                    {
                      title: '全部消息',
                      path: ALL,
                      component: null
                    },
                    ...hasPriv('applyApproval') ? [{
                      title: `待处理(${approvalTotal})`,
                      path: PENDING,
                      component: null
                    }] : []
                  ]
                }
                onChange={handleChange}
              />
            </div>

            <div className="body">
              {
                messageArr.length > 0 ?
                messageArr.map((item, index) => {
                  const messageContext = formatJsonParse(item.message_context) || {}
                  // console.log('messageContext: ', messageContext);
                  const comment = _.get(messageContext, 'comment') || '-'
                  const updateContext = _.get(messageContext, 'update_context') || {}
                  const isEdit = !!messageContext.id // 新增无id，编辑有id
                  const isReRun = item.msg_type == '3'

                  // 新增隐藏的字段
                  if (!isEdit && !_.isEmpty(updateContext)) {
                    delete updateContext.complex_script // 复合方式
                    delete updateContext.service // 所属业务
                    delete updateContext.topic // 指标主题
                  }

                  return <div key={index} className="inner-box">
                    <div className="box_wrap">
                      <div className="tag">{MSG_TYPE_MAP[item.msg_type] || '-'}</div>
                      <div className="title">{item.message_title || '-'}</div>
                      <div className="label time">{item.send_time || '-'}</div>
                    </div>
                    <div className="box_wrap">
                      <div className="flex_wrap">
                        <div className="label">{'指标ID：'}</div>
                        <div className="label_text">{messageContext.id || '-'}</div>
                      </div>
                      <div className="flex_wrap">
                        <div className="label">{isReRun ? '提交人:' : '修改人：'}</div>
                        <div className="label_text">{item.send_user || '-'}</div>
                      </div>
                    </div>
                    {
                      !isReRun ?
                      <>
                        <div className="box_wrap">
                          <div className="flex_wrap">
                            <div className="label" style={{
                              flex: 'none'
                            }}>{'修改点：'}</div>
                            <div className="label_text">{
                              _.keys(updateContext).map(key => {
                                let context = updateContext[key] || '""'
                                let old_value = updateContext[key]['old_value'] || '""'
                                let new_value = updateContext[key]['new_value'] || '""'

                                if (key === 'topicBusiness') {
                                  context = !_.isEmpty(updateContext[key]) && Array.isArray(updateContext[key]['texts']) ? updateContext[key]['texts'].join('/') : '-'
                                } else if (key === 'source_targets') {
                                  context = stomMap[updateContext[key]]
                                } else if (key === 'target_type') {
                                  context = TARGET_TYPE_MAP[updateContext[key]]
                                  old_value = TARGET_TYPE_MAP[updateContext[key]['old_value']]
                                  new_value = TARGET_TYPE_MAP[updateContext[key]['new_value']]
                                } else if (key === 'topic') {
                                  context = topicMap[updateContext[key]]
                                  old_value = topicMap[updateContext[key]['old_value']]
                                  new_value = topicMap[updateContext[key]['new_value']]
                                } else if (key === 'service') {
                                  context = businessMap[updateContext[key]]
                                  old_value = businessMap[updateContext[key]['old_value']]
                                  new_value = businessMap[updateContext[key]['new_value']]
                                }

                                return (
                                  <div key={key} style={{
                                    marginBottom: 8,
                                    lineHeight: '20px',
                                    display: 'flex'
                                  }}>
                                    <span style={{
                                      fontWeight: 600,
                                      flex: 'none'
                                    }}>{UPDATE_CONTEXT_MAP[key] || key}</span>
                                    {
                                      isEdit ? null : <span>：</span>
                                    }
                                    {
                                      isEdit ?
                                      <div>
                                        <span className="gray" style={{
                                          margin: '0 4px'
                                        }}>{'由'}</span>
                                        <span className="text_ellipsis">{old_value}</span>
                                        <span className="gray" style={{
                                          margin: '0 4px'
                                        }}>{'改为'}</span>
                                        <span className="text_ellipsis">{new_value}</span>
                                      </div> :
                                      <div>
                                        <span>{context}</span>
                                      </div>
                                    }
                                  </div>
                                )
                              })
                            }</div>
                          </div>
                          <div className="flex_wrap">
                            <div className="label">{'修改原因：'}</div>
                            <div className="label_text text_ellipsis">{comment}</div>
                          </div>
                        </div>
                        <div className="box_wrap">
                          <div className="label">{'修改时间:'}</div>
                          <div className="label_text">{messageContext.create_time || '-'}</div>
                        </div>
                      </> :
                      <>
                        <div className="box_wrap">
                          <div className="label">{'重跑时间:'}</div>
                          <div className="label_text">{`${messageContext.start_time} - ${messageContext.end_time}` || '-'}</div>
                        </div>
                        <div className="box_wrap">
                          <div className="label">{'提交时间:'}</div>
                          <div className="label_text">{item.update_time || '-'}</div>
                        </div>
                      </>
                    }
                    <div className="box_wrap">
                      {
                        item.task_status != '0' &&
                        <div className="flex_wrap">
                          <div className="label">{'审批人:'}</div>
                          <div className="label_text">{item.approval_user || '-'}</div>
                        </div>
                      }
                      <div className="flex_wrap">
                        {/* 0: 审批中   1：通过   2：驳回 */}
                        <div className="label">{'审批情况：'}</div>
                        <div className={`label_text pass_${item.task_status}`}>{STATE_MAP[item.task_status] || '-'}</div>
                      </div>
                    </div>
                    {
                      item.task_status != '0' &&
                      <div className="box_wrap">
                        <div className="label">{'审批时间:'}</div>
                        <div className="label_text">{item.update_time || '-'}</div>
                      </div>
                    }
                    {
                      (
                        item.task_status == '0' &&
                        hasPriv('applyApproval')
                      ) &&
                      <>
                        <span>
                          <Button text='驳回' type='cancel' style={{
                            width: 124,
                            textAlign: 'center'
                          }} onClick={() => onapplyApproval({
                            approvalId: messageContext.approvalId,
                            task_status: 2,
                            comment: ''
                          })} />
                        </span>
                        <span style={{ marginLeft: 8 }}>
                          <Button text='通过' type='submit' style={{
                            width: 124,
                            textAlign: 'center'
                          }} onClick={() => onapplyApproval({
                            approvalId: messageContext.approvalId,
                            task_status: 1,
                            comment: ''
                          })} />
                        </span>
                      </>
                    }
                  </div>
                }) :
                <Empty />
              }
            </div>

            {
              messageArr.length > 0 &&
              <div style={{ padding: 16 }}>
                <Pagenation
                  total={ pageInfo.total || 0}
                  currentPage={ pageInfo.current_page || 1}
                  pageCount={ pageInfo.per_page || 10 }
                  pageCountList={[10, 15, 30, 40, 50]}
                  getClickPage={val => updatePageInfo('current_page', val)}
                  pageCountChange={val => updatePageInfo('per_page', val)}
                />
              </div>
            }

          </div>
        }
        </div>
      </Loadding>

    </div>
  )
}
