import React, { PureComponent } from 'react'
import { DateRangePicker } from 'element-react'
import SdwMessage from '@common/Message'
import SdwDialog from '@common/Dialog'
import 'element-theme-default'
import './style/DialogShowRunData.css'
import PropTypes from 'prop-types'
import { runTargetResult, runTargetResultAuth } from '@services/Derived'
import { formatTime } from '@utils/formatTime'
import _ from 'lodash'
import { hasPriv } from "@components/FrontendAuth/AuthMap"

class DialogVisible extends PureComponent {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
    dateRange: [],
    dialogVisible: false,
    hasReRunPriv: false, // 是否有重跑权限
  }

  // 可传递的参数
  static propTypes = {
    closeDialog: PropTypes.object,
  }

  // 属性默认值
  static defaultProps = {
    closeDialog: () => {},
  };

  componentDidMount () {
    this.initData()
    // 初始化预览数据的时间选择器的默认时间
    this.getShowDataDefauteTime()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.dialogVisible !== this.props.dialogVisible) {
      this.initData()
    }
  }

  initData = () => {
    console.log('----hasReRunPriv: ', hasPriv('targetV2RunTargetResult'));
    this.setState({
      dialogVisible: this.props.dialogVisible,
      data: this.props.data || [],
      hasReRunPriv: hasPriv('targetV2RunTargetResult')
    })
  }

  // 获取昨天时间和今天时间
  getShowDataDefauteTime = () => {
    var date = new Date();//当前时间
    var year = date.getFullYear()
    var month = this.zeroFill(date.getMonth() + 1);//月
    var day = this.zeroFill(date.getDate());//日
    let startTime = year + "/" + month + "/" + day + " 00:00:00"

    var s = startTime
    startTime = new Date(s);

    var date2 = new Date();//当前时间
    date2 = new Date(date2.getTime() + 24*60*60*1000);
    year = date2.getFullYear()
    month = this.zeroFill(date2.getMonth() + 1);//月
    day = this.zeroFill(date2.getDate());//日
    let endTime = year + "/" + month + "/" + day + " 00:00:00"

    s = endTime
    endTime = new Date(s);

    this.setState({dateRange: [startTime, endTime]})
  }

  /**
   * 时间补零
   */
  zeroFill = (i) => {
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
  }

  // 获取预览数据
  getShowData = (val, type) => {
    if(type == "date") {
      this.setState({dateRange: val})
    }
  }

  fetchItems() {
    if (this.state.data.length === 0) {
      return
    }
    let params = {}
    let target_id = []
    this.state.data.map(item => {
      target_id.push(item)
    })
    params["tid"] = target_id.join(",")
    if (this.state.dateRange && this.state.dateRange.length > 0) {
      params["start_time"] = formatTime(+new Date(this.state.dateRange[0]), 0)
      params["end_time"] = formatTime(+new Date(this.state.dateRange[1]), 0)
    }

    const API = this.state.hasReRunPriv ? runTargetResult : runTargetResultAuth

    API(params).then(res => {
      this.closeDialog()
      if (res.data.code === 0) {
        SdwMessage.success("重跑任务提交成功")
      } else {
        let msg = _.get(res, 'data.msg') || '重跑任务提交失败'
        SdwMessage.error(msg)
      }
    })
  }

  closeDialog = () => {
    this.setState({dialogVisible: false})
    this.props.closeDialog()
  }

  render () {
    var { dateRange, hasReRunPriv } = this.state;

    return (
      <div className="show-data DialogShowRunData">
        <SdwDialog
            title={"重跑指标"}
            width={520}
            height={208}
            visible={this.state.dialogVisible}
            onSureClick={() => {this.fetchItems()}}
            onCancelClick={() => this.closeDialog()}
            sureText={hasReRunPriv ? "确定" : "提交审批"}
        >
          <div>
            <span style={{marginRight: 8}}>时间</span>
            <DateRangePicker
                value={dateRange}
                placeholder="选择日期范围"
                disabledDate={time=>time.getTime() > (Date.now() + 24*60*60*1000)}
                isShowTime={true}
                format="yyyy-MM-dd HH:mm:ss"
                onChange={date=>{
                  this.getShowData(date, "date")
                }}
            />
          </div>
        </SdwDialog>
      </div>
    )
  }
}

export default DialogVisible
