import produce from 'immer'
import { getDetailTreeInfo } from '@api/midTable'

const state = {
  tableConf: [], // 节点
  physicsTableList: {}, // 物理表列表
  linkConf: [], // 连线的列表
  treeInfo: {
    tree:{},
    list:[],
  }, // 节点的树结构
  detailInfo: {}, // 节点的列表结构
  stepList: {}, // 加工步骤总览
  currentSource:{}, //当前编辑的数据源  为空则为添加数据源
  cfgData:{}, //通用配置
}

const updateTableConf = produce((state, payload) => {
  state.tableConf = payload
})
const updateLinkConf = produce((state, payload) => {
  state.linkConf = payload
})

const updatePhysicsTableList = produce((state, payload) => {
  state.physicsTableList = payload
})

const updateTreeInfo = produce((state, payload) => {
  state.treeInfo = payload
})
const updateDetailInfo = produce((state, payload) => {
  state.detailInfo = payload
})
const updateCurrentSource = produce((state, payload) => {
  state.currentSource = payload
})


const updateStepList = produce((state, payload) => {
  state.stepList = payload
})

const updateCfgData = produce((state, payload) => {
  state.cfgData = payload
})

const reducers = {
  updateTableConf,
  updatePhysicsTableList,
  updateLinkConf,
  updateTreeInfo,
  updateDetailInfo,
  updateStepList,
  updateCurrentSource,
  updateCfgData,
}

export default { state, reducers }
