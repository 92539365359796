import React, { PureComponent } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import { DateRangePicker, Button } from 'element-react'
import SdwTable from '../../../common/Table'
import SdwPagenation from '../../../common/Pagenation'
import SdwSelect from '../../../common/Select'
import SdwBreadCrumbs from '../../../common/BreadCrumbs'
import { GetHotEventMap, GetHotEventChooseList } from '@services/monitoringEye'
import SdwLoading from '@common/Loadding'

import 'element-theme-default'
import './style/index.css'

class MonitoringEye extends PureComponent {

  state = {
    dataPicker: '',
    businessData: [],
    enableOptios: [{
      value: 1,
      label: '有效'
    }, {
      value: 0,
      label: '无效'
    }],
    typeOptions: [{
      value: 1,
      label: '已补登'
    }, {
      value: 0,
      label: '未补登'
    }],
    businessValue: '',
    typeValue: '',
    enableValue: '',
    isPageLoading: true,
    tableData: {},
    curPage: this.props.pageInfo || 0,
    curLimit: 10,
  }

  componentDidMount () {
    this.initDatePicker()
    this.onGetHotEventChooseList()
  }

  componentWillUnmount () {
    sessionStorage.setItem('monitorDataPicker', JSON.stringify(this.state.dataPicker))
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      !_.isEqual(prevState.dataPicker, this.state.dataPicker) ||
      !_.isEqual(prevState.businessValue, this.state.businessValue) ||
      !_.isEqual(prevState.curLimit, this.state.curLimit) ||
      !_.isEqual(prevState.curPage, this.state.curPage) ||
      !_.isEqual(prevState.typeValue, this.state.typeValue) ||
      !_.isEqual(prevState.enableValue, this.state.enableValue)
    ) {
      this.onGetHotEventMap()
    }
  }

  // 初始化时间选择当前一天
  initDatePicker = () => {
    let prevDatePicker = JSON.parse(sessionStorage.getItem('monitorDataPicker'))
    let hasDateAndValidate = _.isArray(prevDatePicker) && prevDatePicker.length >= 2

    // 从详情页跳转回来，需要保留之前的时间
    let showPrevTime = this.props.location.search.indexOf('showPrevDate') !== -1

    if (hasDateAndValidate && showPrevTime) {
      let start = new Date(prevDatePicker[0]);
      let end = new Date(prevDatePicker[1]);
      this.setState({dataPicker: [start, end]})
      return
    }

    let end = new Date();
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);

    this.setState({dataPicker: [start, end]})
  }

  // 获取table表格数据
  onGetHotEventMap = () => {

    let { dataPicker, businessValue, curPage, curLimit, typeValue, enableValue } = this.state

    if (!dataPicker || !dataPicker.length) return

    let date = this.formatDate(dataPicker)
    GetHotEventMap({
      start_time: date[0],
      end_time: date[1],
      ...businessValue ? {
        product: businessValue
      } : {},
      limit: curLimit,
      page: curPage,
      ..._.isEqual(typeof typeValue, 'number') ? {
        record: typeValue
      } : {},
      ..._.isEqual(typeof enableValue, 'number') ? {
        isEffect: enableValue
      } : {}
    }).then(res => {
      if (res && res.data && res.data.code === 0) {
        this.setState({
          isPageLoading: false,
          tableData: res.data.data || {}
        })
      }
    })
  }

  // 获取业务下拉选择项
  onGetHotEventChooseList = () => {
    GetHotEventChooseList().then(res => {
      if (res && res.data && res.data.code === 0) {
        this.setState({
          businessData: res.data.data || []
        })
      }
    })
  }

  getColumns = () => {
    let self = this
    return [
      {
        title: "预警时间",
        dataIndex: "create_time"
      },
      {
        title: "所属业务",
        dataIndex: "product"
      },
      {
        title: "标题",
        dataIndex: "hot_word"
      },
      {
        title: "相关指标",
        width: 380,
        render: function (data, record) {
          let suchHeat = data.such_heat.split(' ')[0]
          return (
            <span>
              {
                `反馈数：${data.today_word}；同比增长：${data.tongbi}；热词占比：${suchHeat}`
              }
            </span>
          )
        }
      },
      {
        title: "所属渠道",
        dataIndex: "type"
      },
      {
        title: "补登状态",
        render: function (data, record) {
          return (
            <span>
              {
                !!data.state ? '已补登' : '-'
              }
            </span>
          )
        }
      },
      {
        title: "是否有效",
        render: function (data, record) {
          return (
            <>
              {
                _.isEqual(typeof data.isEffect, 'number') && <span className={
                  data.isEffect ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                }></span>
              }
              <span>{_.isEqual(typeof data.isEffect, 'number') ?
              (data.isEffect ? '有效' : '无效') : '-'}</span>
            </>
          )
        }
      },
      {
        title: "操作",
        render: function (data, record) {
          return (
            <span>
              <Button
                plain={true}
                type="text"
                size="small"
                onClick={ () => self.jumpToDetail(data) }
              >预警详情</Button>
            </span>
          )
        }
      }
    ]
  }

  jumpToDetail = record => {
    this.props.history.push(`/admin/monitor/monitoringEyeDetail/${record.id}`)
  }

  formatDate = date => {
    if (_.isArray(date)) {
      return date.map((item, index) => {
        let time = index === 0 ? '00:00:00' : '23:59:59'
        return `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate()} ${time}`
      })
    }
  }

  render () {

    let {
      dataPicker,
      businessValue,
      typeValue,
      enableValue,
      enableOptios,
      typeOptions,
      tableData,
      businessData,
      isPageLoading
    } = this.state

    return (
      <div>
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '智慧运营台',
              path: '/admin/monitor/monitorManagement'
            }, {
              title: '热词监控眼'
            }
          ]}
        />

        <div className="page-mainbody">
          <div className="sdw-monitor-eye__wrap" style={{ marginBottom: '16px', lineHeight: '44px' }}>
            <span className="sdw-monitor-eye__date-range-picker-wrap">
              <DateRangePicker
                value={dataPicker}
                isReadOnly={true}
                rangeSeparator=" 至 "
                placeholder="选择日期范围"
                format="yyyy-MM-dd"
                align="right"
                disabledDate={time=>time.getTime() > (Date.now())}
                onChange={date => {
                  this.setState({curPage: 1, dataPicker: date})
                }}
              />
            </span>

            <span style={{ marginLeft: '8px' }}>
              <SdwSelect
                value={businessValue}
                width={160}
                label="业务"
                data={businessData.map(el => {
                  return {
                    id: el.product,
                    text: el.product
                  }
                })}
                onChange={val => this.setState({curPage: 1, businessValue: val})}
              />
            </span>

            <span style={{ marginLeft: '8px' }}>
              <SdwSelect
                value={typeValue}
                width={160}
                label="补登状态"
                data={typeOptions.map(el => {
                  return {
                    id: el.value,
                    text: el.label
                  }
                })}
                onChange={val => this.setState({curPage: 1, typeValue: val})}
              />
            </span>

            <span style={{ marginLeft: '8px' }}>
              <SdwSelect
                value={enableValue}
                width={160}
                label="是否有效"
                data={enableOptios.map(el => {
                  return {
                    id: el.value,
                    text: el.label
                  }
                })}
                onChange={val => this.setState({curPage: 1, enableValue: val})}
              />
            </span>

            <span style={{ position: 'absolute', right: 10, lineHeight: '40px' }}>
              <span style={{ marginRight: '10px' }}>预警：<span style={{ color: '#265cf0' }}>{tableData.total || 0}</span>次</span>
              <span style={{ marginRight: '10px' }}>已补登：<span style={{ color: '#265cf0' }}>{tableData.condition_count || 0}</span>次</span>
              <span style={{ marginRight: '10px' }}>故障：<span style={{ color: '#265cf0' }}>{tableData.fault_count || 0}</span>次</span>
            </span>
          </div>

          <SdwLoading loadding={isPageLoading}>
            <SdwTable
              columns={ this.getColumns() }
              dataSource={ tableData.items || [] }
            />

            <div style={{ marginTop: '16px' }}>
              <SdwPagenation
                total={ tableData.total }
                currentPage={ tableData.current_page }
                pageCountList={[10, 15, 20, 40]}
                pageCount={ tableData.per_page }
                getClickPage={ page => {
                  this.setState({
                    curPage: page
                  })
                } }
                pageCountChange={ limit => {
                  this.setState({
                    curLimit: limit
                  })
                } }
              />
            </div>
          </SdwLoading>

        </div>
      </div>
    )
  }
}

const mapState = state => ({
  pageInfo: state.page.pageInfo
})

export default withRouter(connect(mapState)(MonitoringEye))
