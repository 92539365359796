import { useState, useEffect } from 'react'
import _ from 'lodash'
import BreadCrumbs from '@common/BreadCrumbs'
import Select from '@common/Select'
import Input from '@common/Input'
import Button from '@common/Button'
import Loadding from '@common/Loadding'
import searchImg from '../img/searchImg.svg'
import Base from './Base'
import Service from './Service'
import { accountMap, accountType } from './common'
import UseGetUserTraceItem from '@customHooks/UseGetUserTraceItem'
import DisplayTraceDetail from '@pages/Monitor/UserSearch/DisplayTraceDetail'
import './index.css'
import __canvasWM from '@common/Water'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

function UserSearch(props) {

  const [search, setSearch] = useState(GetRequest())
  const [doSearch, setDoSearch] = useState(GetRequest().account || '')
  const [showSearchImg, setShowSearchImg] = useState(GetRequest().account ? false : true)
  const [isLoadding, setIsLoadding] = useState(false)
  const [displayTraceDetail, setDisplayTraceDetail] = useState(false)
  const userTranceItem = UseGetUserTraceItem(search, doSearch, setIsLoadding)
  //const traceDetailJson = UseGetUserTraceDetail(search, doSearch, setIsLoadding)

  useEffect(()=>{
    let userInfo = props.userInfo
    let content = userInfo ? (userInfo.name ? userInfo.nick : userInfo.FullName) : "未登录-请勿外传"

    __canvasWM({
      content: content,
      container: document.getElementById("userTrace"),
    })
  }, [])

  function GetRequest() {
    var url = window.location.search; //获取url中"?"符后的字串
    if (!url) return  {
      accountType: '2', // 2: 默认手机号
      account: '' // 15991901381  15810802587  15907325909  13690868030
    }
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for(var i = 0; i < strs.length; i ++) {
        theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  }

  const updateSearch = (key = '', val = '') => {
    if (!key) return
    let cloneSearch = _.cloneDeep(search)
    cloneSearch[key] = val
    setSearch(cloneSearch)
  }

  const onSearch = () => {
    props.history.push({pathname: '/admin/dataGasStation/userSearch', search: `?account=${search.account}&accountType=${search.accountType}`})
    setDoSearch(Date.now())
    if (showSearchImg) setShowSearchImg(false)
  }
  return (
    <div id="userTrace">
      <BreadCrumbs
        data={[
          {
            title: '数据加油站',
            path: '/admin/dataGasStation/dataSearch'
          }, {
            title: '用户查询'
          }
        ]}
      />

      <div>
        <Select
          width={140}
          value={search.accountType}
          data={accountMap}
          placeholder='请选择'
          onChange={val => setSearch({
            accountType: val,
            account: ''
          })}
        />
        <Input
          value={search.account}
          width={300}
          onChange={val => updateSearch('account', val)}
          onEnterKeyDown={_.debounce(onSearch, 500)}
        />

        <div style={{
          display: 'inline-block',
          marginLeft: 8
        }}>
          <Button
            text='查询'
            type='submit'
            disabled={!search.accountType || !search.account}
            tip={(!search.accountType || !search.account) ? '请输入查询条件' : ''}
            iconClass='iconfont icon-search'
            onClick={_.debounce(onSearch, 500)}
          />
        </div>
        {
          hasPriv('userTraceDetail')&&(search.accountType && search.account) && <div style={{
            display: 'inline-block',
            float:"right",
            marginLeft: 18
          }}>
            <Button
              text='查看详情'
              type='cancel'
              disabled={!search.accountType || !search.account}
              iconClass='iconfont'
              onClick={()=>{
                // let obj = {
                //   accountType:search.accountType,
                //   account:search.account,
                // }
                // props.history.push({
                //   pathname: '/admin/dataGasStation/userSearchDetail',
                //   state: obj
                // })
                setDisplayTraceDetail(true)
              }}
            />
          </div>
        }

      </div>

      {
        showSearchImg ?
        <img src={searchImg} className='monitor__user-search__search-img' /> :
        <Loadding loadding={isLoadding} tipContent='拼命加载中...'>
          <div className='monitor__user-search__body-wrap' >
            <Base
              userTranceItem={userTranceItem}
            />
          </div>

          <div className='monitor__user-search__body-wrap' >
            <Service
              userTranceItem={userTranceItem}
              search={search}
              isLoadding={isLoadding}
            />
          </div>
        </Loadding>
      }
      {
        displayTraceDetail && <DisplayTraceDetail
          visible={displayTraceDetail}
          //traceDetailJson={traceDetailJson}
          accountType={search.accountType}
          account={search.account}
          onCancelClick={()=>setDisplayTraceDetail(false)}
        />
      }
    </div>
  )
}
const mapState = state => ({
  userInfo: state.user.userInfo,
})

const mapDispatch = dispatch => ({
  updateUserInfo: dispatch.user.updateUserInfo
})

export default withRouter(connect(mapState, mapDispatch)(UserSearch))
