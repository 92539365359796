import React from 'react'
import _ from 'lodash'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import SdwRadio from '@common/Radiobox'
import SdwSwitch from '@common/Switch'
import SdwMessage from '@common/Message'
import SdwTable from '@common/Table'
import BreadCrumbs from '@common/BreadCrumbs'
import StatusWin from '@pages/Knowledge/LogAccess/CollectionServiceManagement/StepWin/StatusWin'
import './index.css'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {requestOptionsLOG} from '@config/config'

const baseURL = requestOptionsLOG.basename

class LogAccessDetail extends React.PureComponent {

    state = {
        tagList: [],
        sholdCheckValidate: false,
        params: {
            id: '',
            cmd: 0, // 解析标识
            parseType: 'SEPARATOR', // 解析类型
            parseValue: '', // 解析值
            startParseType: '', // 开始解析类型
            startParseValue: '', // 开始解析值
            endParseType: '', // 结束解析类型
            endParseValue: '', // 结束解析值
            message: '', // 日志字段示例消息
            rtx: '', // 操作人
            fieldConfigVOS: [], // 字段配置列表
        },
        example: '',
        tableDataList: [],
        isEdit: false,
        enableState: false, // 高级配置开关
        showResult: false, // 解析结果
        isPass: false, // 解析是否通过
        statusVisible: false, // 链路检查弹窗
        socketList: {
            checkerList: [],
            sessionId: 0
        },
        deliveryInnerConfigDTOS: [] // 分发配置信息
    }

    componentDidMount() {
        this.initData()
    }

    initData = () => {
        let query = this.props.location.state || {}
        if (!!query.id) {
            this.queryParseConfig(query.id)
            this.queryInputFileConfigList(query.id)
            this.setState({isEdit: query.isEdit})
        } else {
            this.queryInputFileConfigList()
        }
    }

    // 查询解析配置列表
    queryParseConfig = (id) => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/parseConfig/queryParseConfig/' + id,
            {
                method: "GET",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then((data) => {
                if (data && data.code == 100) {
                    let record = data.data
                    // console.log("=======record", record)
                    let example = record.message
                    let fieldConfigDTOS = record.fieldConfigDTOS
                    // console.log("=======example", example)
                    delete record["message"]
                    let tableDataList = this.getTableList(fieldConfigDTOS, example, record)
                    // console.log("=======tableDataList", tableDataList)
                    delete record.fieldConfigDTOS
                    record.fieldConfigVOS = []
                    record.rtx = rtx
                    let obj = {
                        params: record,
                        example: example,
                        tableDataList: tableDataList,
                        deliveryInnerConfigDTOS: record.deliveryInnerConfigDTOS,
                    }
                    // console.log("=========obj", obj)
                    this.setState({
                        ...obj
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 组装表列表数据
    getTableList = (fieldConfigDTOS, example, params) => {
        let list = []
        if (!example) return
        let parse_type = params.parseType
        let separator = params.parseValue
        switch (parse_type) {
            case "SEPARATOR":
                // if (!separator || String(example).indexOf(separator) === -1) {
                //     list = [{
                //         message: example,
                //         index: 1,
                //         fieldName: '',
                //         fieldType: '',
                //         parseKeys: '',
                //         extra: ''
                //     }]
                // } else {
                    let arr = String(example).split(separator)
                    if (_.isArray(arr)) list = arr.map((i, j) => {
                        if (!fieldConfigDTOS[j]) {
                            fieldConfigDTOS[j] = {fieldName: '', fieldType: 'string', parseKeys: '', extra: ''}
                        }
                        let fieldConfigDTOSItem = fieldConfigDTOS[j]
                        return {
                            message: i,
                            index: j + 1,
                            fieldName: fieldConfigDTOSItem.fieldName,
                            fieldType: fieldConfigDTOSItem.fieldType,
                            parseKeys: fieldConfigDTOSItem.parseKeys,
                            extra: fieldConfigDTOSItem.extra
                        }
                    })
                // }
                break;

            case "KEY_VALUE":
                let curExample = example
                if (String(curExample).indexOf('=') === -1) {
                    list = [{
                        message: '',
                        index: 1,
                        fieldName: curExample,
                        parseKeys: '',
                        fieldType: '',
                        extra: ''
                    }]
                } else {
                    let arr = String(curExample).split('&')
                    if (_.isArray(arr)) {
                        arr.forEach((item, j) => {
                            const curlist = item.split('=')
                            if (!fieldConfigDTOS[j]) {
                                fieldConfigDTOS[j] = {
                                    fieldName: '',
                                    fieldType: 'string',
                                    parseKeys: '',
                                    extra: ''
                                }
                            }
                            let fieldConfigDTOSItem = fieldConfigDTOS[j]
                            list.push({
                                message: curlist[1] || '',
                                index: j + 1,
                                fieldName: curlist[0],
                                fieldType: fieldConfigDTOSItem.fieldType,
                                parseKeys: fieldConfigDTOSItem.parseKeys,
                                extra: fieldConfigDTOSItem.extra
                            })
                        })
                    }
                }
                break;

            default:
                break;
        }
        // console.log("tableDataList=====change", list)
        return list
    }

    updateTableData = (key, val, index) => {
        let {tableDataList} = this.state
        let cloneTableDataList = _.cloneDeep(tableDataList)
        cloneTableDataList[index][key] = val
        this.setState({
            tableDataList: cloneTableDataList
        })
    }

    addVirtualProperty = (index) => {
        let {tableDataList} = this.state
        let item = {message: '', index: index + 2, fieldName: '', fieldType: 'string', parseKeys: '', extra: ''}
        let newTableDataList = []
        for (let i in tableDataList) {
            if (i > index) {
                tableDataList[i].index++
            }
            newTableDataList.push(tableDataList[i])
            if (i == index) {
                newTableDataList.push(item)
            }
        }
        this.setState({
            tableDataList: newTableDataList
        })
    }

    removeVirtualProperty = (index) => {
        let tableDataList = [...this.state.tableDataList]
        tableDataList.splice(index, 1);
        this.setState({tableDataList: tableDataList})
    }

    getColumns = () => {
        return [
            {
                title: '序号',
                dataIndex: 'index',
                width: 50
            }, {
                title: '日志字段示例',
                width: 200,
                render: (data, index) => (
                    <SdwInput
                        placeholder='请输入'
                        value={data.message}
                        onChange={val => this.updateTableData('message', val, index)}
                    />
                )
            },
            {
                title: '字段英文名',
                width: 150,
                render: (data, index) => (
                    <SdwInput
                        placeholder='请输入'
                        // disabled={oriTableData.some(i => getDisabled(i, data))}
                        value={data.fieldName}
                        onChange={val => this.updateTableData('fieldName', val, index)}
                    />
                )
            },
            {
                title: '字段数据类型',
                width: 150,
                render: (data, index) => (
                    <SdwSelect
                        placeholder='请选择'
                        // disabled={oriTableData.some(i => getDisabled(i, data))}
                        value={data.fieldType}
                        onChange={val => this.updateTableData('fieldType', val, index)}
                        data={[
                            {
                                id: 'string',
                                text: 'string'
                            }, {
                                id: 'date',
                                text: 'date'
                            }, {
                                id: 'int',
                                text: 'int'
                            }, {
                                id: 'json',
                                text: 'json'
                            }, {
                                id: 'timeStamp',
                                text: '时间戳'
                            }
                        ]}
                    />
                )
            },
            {
                title: '解析字段',
                width: 200,
                render: (data, index) => (
                    data.fieldType == 'json' ?
                        <SdwInput
                            placeholder='多个请用逗号隔开'
                            width={220}
                            value={data.parseKeys}
                            onChange={val => this.updateTableData('parseKeys', val, index)}
                        /> : '-'
                )
            },
            {
                title: '其他规则',
                width: 240,
                render: (data, index) => {
                    return <>
                        <SdwSelect
                            placeholder='请选择'
                            // disabled={oriTableData.some(i => getDisabled(i, data))}
                            value={data.extra}
                            onChange={val => this.updateTableData('extra', val, index)}
                            data={[
                                {
                                    id: 'utf-8-decode',
                                    text: 'utf-8解码'
                                }, {
                                    id: 'utf-8-encode',
                                    text: 'utf-8编码'
                                }, {
                                    id: 'gbk-decode',
                                    text: 'gbk解码'
                                }, {
                                    id: 'gbk-encode',
                                    text: 'gbk编码'
                                }, {
                                    id: 'encrypt',
                                    text: '加密'
                                }, {
                                    id: 'decrypt',
                                    text: '解密'
                                }
                            ]}
                        />
                        <i onClick={() => this.addVirtualProperty(index)} className="el-icon-plus"
                           style={{marginLeft: 12}}></i>
                        <i onClick={() => this.removeVirtualProperty(index)} className="el-icon-minus"></i>
                    </>
                }
            },
        ]
    }

    // 查询未使用的采集标识
    queryInputFileConfigList = (id = "") => {
        let str = ""
        if (!!id) {
            str = "?parseId=" + id + "&type=parse"
        }
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/inputTag/queryValidInputTag' + str,
            {
                method: "GET",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx}
            }).then(res => {
            res.json().then((data) => {
                let record = data.data
                // console.log('tableData-----------------------', record)
                if (Array.isArray(record)) {
                    this.setState({
                        tagList: record.map(i => {
                            return {id: i.cmd, text: i.cmd + "(" + i.inputTag + ")", item: i}
                        })
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 保存并测试
    submitClick = () => {
        let {params, tableDataList, example} = this.state
        let rtx = this.props.userInfo.name
        let obj = {
            showResult: true,
            isPass: false,
        }
        let paramsClone = _.cloneDeep(params)
        delete paramsClone.deliveryInnerConfigDTOS
        if (!paramsClone.cmd || !paramsClone.parseType) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        } else {
            tableDataList.forEach(i => {
                if (!!i.fieldName || !!i.fieldType || !!i.parseKeys) {
                    // console.log("========fieldConfigVOS", paramsClone.fieldConfigVOS)
                    paramsClone.fieldConfigVOS.push({
                        fieldName: i.fieldName,
                        fieldType: i.fieldType,
                        parseKeys: i.parseKeys,
                        extra: i.extra
                    })
                }
            })
            if ((paramsClone.parseType == "SEPARATOR" && !paramsClone.parseValue)) {
                this.setState({
                    sholdCheckValidate: true
                })
                return
            }
            if (paramsClone.fieldConfigVOS.length == 0) {
                SdwMessage.error("请填写字段配置中的必填内容")
                return
            }
        }
        paramsClone.message = example
        fetch(baseURL + '/sdw/filebeat/parseConfig/upsertParseConfig',
            {
                method: "POST",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(paramsClone)
            }).then(res => {
            res.json().then((data) => {
                if (data.code === 100) {
                    obj.isPass = true
                } else if (data.code == 101) {
                    obj.isPass = true
                    obj['statusVisible'] = true
                    obj['socketList'] = data.data
                } else {
                    obj.isPass = false
                }
                this.setState({
                    ...obj
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 下一步
    jumpToTwo = () => {
        let {params, deliveryInnerConfigDTOS} = this.state
        this.props.history.push({
            pathname: '/admin/dataCollect/setupTwo',
            state: {
                cmd: params.cmd,
                deliveryInnerConfigDTOS: deliveryInnerConfigDTOS,
            }
        })
    }

    // 设置params的值
    setParamsData = (type, value) => {
        let {params} = this.state
        let obj = {}
        obj[type] = value
        let paramsClone = Object.assign({}, params, obj)
        if (paramsClone.parseType == "KEY_VALUE") {
            delete paramsClone.parseValue
        }
        this.setState({
            params: paramsClone
        })
    }

    // 解析示例成表列表
    getFieldConfig = () => {
        const {example, params} = this.state
        let exampleClone = _.cloneDeep(example)

        // 根据高级配置切割example
        let fixed = 'ADVANCED_PARSE_TYPE_STATIC_PARAM' // 固定参数
        let dynamic = 'ADVANCED_PARSE_TYPE_DYNAMIC_PARAM' // 动态参数
        if (params.startParseType == fixed || params.endParseType == fixed) {
            if (params.startParseValue && !params.endParseValue) {
                let index = exampleClone.indexOf(params.startParseValue)
                exampleClone = exampleClone.slice(index)
            } else if (!params.startParseValue && params.endParseValue) {
                let index1 = exampleClone.length - exampleClone.indexOf(params.endParseValue)
                exampleClone = exampleClone.slice(0, -index1)
            } else if (params.startParseValue && params.endParseValue) {
                if (params.startParseType == fixed && params.endParseType == dynamic) {
                    let index = exampleClone.indexOf(params.startParseValue)
                    let index1 = params.endParseValue
                    exampleClone = exampleClone.slice(index, -index1)
                } else if (params.startParseType == dynamic && params.endParseType == fixed) {
                    let index = params.startParseValue
                    let index1 = exampleClone.length - exampleClone.indexOf(params.endParseValue)
                    exampleClone = exampleClone.slice(index, -index1)
                } else {
                    let index = exampleClone.indexOf(params.startParseValue)
                    let index1 = exampleClone.length - exampleClone.indexOf(params.endParseValue)
                    exampleClone = exampleClone.slice(index, -index1)
                }
            }
        } else if (params.startParseType == dynamic || params.endParseType == dynamic) {
            if (params.startParseValue && !params.endParseValue) {
                let index = params.startParseValue
                exampleClone = exampleClone.slice(index)
            } else if (!params.startParseValue && params.endParseValue) {
                let index1 = params.endParseValue
                exampleClone = exampleClone.slice(0, -index1)
            } else if (params.startParseValue && params.endParseValue) {
                let index = params.startParseValue
                let index1 = params.endParseValue
                exampleClone = exampleClone.slice(index, -index1)
            }
        }

        let list = []
        if (!exampleClone) return
        let parse_type = params.parseType
        let separator = params.parseValue
        switch (parse_type) {
            case "SEPARATOR":
                if (!separator || String(exampleClone).indexOf(separator) === -1) {
                    list = [{
                        message: exampleClone,
                        index: 1,
                        fieldName: '',
                        fieldType: 'string',
                        parseKeys: '',
                        extra: ''
                    }]
                } else {
                    let arr = String(exampleClone).split(separator)
                    if (_.isArray(arr)) list = arr.map((i, j) => ({
                        message: i,
                        index: j + 1,
                        fieldName: '',
                        fieldType: 'string',
                        parseKeys: '',
                        extra: ''
                    }))
                }
                break;

            case "KEY_VALUE":
                let curExample = exampleClone
                if (String(curExample).indexOf('=') === -1) {
                    list = [{
                        message: '',
                        index: 1,
                        fieldName: curExample,
                        fieldType: 'string',
                        parseKeys: '',
                        extra: ''
                    }]
                } else {
                    let arr = String(curExample).split('&')
                    if (_.isArray(arr)) {
                        arr.forEach((item, j) => {
                            const curlist = item.split('=')
                            list.push({
                                message: curlist[1] || '',
                                index: j + 1,
                                fieldName: curlist[0],
                                fieldType: 'string',
                                parseKeys: '',
                                extra: ''
                            })
                        })
                    }
                }
                break;

            default:
                break;
        }

        // 只有分隔符的需要给前三项默认值
        if (parse_type === "SEPARATOR") {
            list = list.map((i, index) => {
                let res = _.cloneDeep(i)
                switch (index) {
                    case 0:
                        res.fieldType = 'string'
                        res.fieldName = 'log_head'
                        break;

                    case 1:
                        res.fieldType = 'string'
                        res.fieldName = 'log_module'
                        break;

                    case 2:
                        res.fieldType = 'date'
                        res.fieldName = 'log_time'
                        break;

                    default:
                        break;
                }
                return res
            })
        }
        // console.log("tableDataList=====change", list)
        this.setState({
            tableDataList: list
        })
    }

    // 高级配置开关
    enableStateChange = (val) => {
        if (val) {
            this.setState({enableState: true})
        } else {
            this.setState({enableState: false})
        }
    }


    render() {

        let {
            example,
            tagList,
            params,
            tableDataList,
            sholdCheckValidate,
            showResult,
            isPass,
            statusVisible,
            socketList,
            enableState,
            isEdit
        } = this.state

        if (params.startParseType || params.endParseType) {
            enableState = true
        }

        return (
            <>
                <BreadCrumbs
                    history={this.props.history}
                    data={[
                        {
                            title: '数据采集地',
                            path: '/admin/dataCollect/dataManagement'
                        }, {
                            title: '日志接入',
                            path: '/admin/dataCollect/logAccess'
                        }, {
                            title: '新增日志接入'
                        }
                    ]}
                />

                <div className='log-access-detail-main__body'>

                    {/* 步骤条 */}
                    <div className='log-access-step-container'>
                        <ol className='log-access-step-area'>
                            <li className='success'>
                                <div className='log-access-step-inner_1'>
                                    <i className='log-access-step-num_1'></i>
                                    <span>内容存储配置</span>
                                </div>
                            </li>
                            <li>
                                <div className='log-access-step-inner_1'>
                                    <i className='log-access-step-num_1'></i>
                                    <span>分发配置</span>
                                </div>
                            </li>
                        </ol>
                    </div>

                    <div className='log-access-detail-form'>
                        <div className='log-access-detail-title-main'>
                            <span className='log-access-detail-title-bar'>解析配置</span>
                            <span className='log-access-detail-title-des'>用于配置解析规则</span>
                        </div>
                        <div className='log-access-detail-box-main'>
                            <span className='label required'>CMD</span>
                            <SdwSelect
                                placeholder='请选择CMD'
                                width={600}
                                disabled={isEdit}
                                data={tagList}
                                value={params.cmd}
                                onChange={val => this.setParamsData('cmd', val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '请选择CMD'}
                            />
                        </div>
                        <div className='log-access-detail-box-main'>
                            <span className='label required'>解析标识</span>
                            <SdwRadio
                                options={[{
                                    text: '分隔符解析',
                                    value: "SEPARATOR"
                                }, {
                                    text: '键值对(key_value)解析',
                                    value: "KEY_VALUE"
                                }]}
                                value={params.parseType}
                                changeCheckedFun={val => this.setParamsData('parseType', val)}
                            />
                        </div>
                        {
                            params.parseType == "SEPARATOR" &&
                            <div className='log-access-detail-box-main'>
                                <span className='label required'>分隔符</span>
                                <SdwInput
                                    width={600}
                                    placeholder='请输入分隔符'
                                    value={params.parseValue}
                                    onChange={val => this.setParamsData('parseValue', val)}
                                    sholdCheckValidate={sholdCheckValidate && params.parseType == "SEPARATOR"}
                                    validateFun={value => !!value ? true : '分隔符不能为空'}
                                />
                            </div>
                        }
                        <div className='log-access-detail-box-main'>
                            <span className='label required'>日志内容示例</span>
                            <SdwInput
                                type='textarea'
                                width={600}
                                placeholder='请输入日志内容示例'
                                value={example}
                                onChange={val => this.setState({example: val})}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '请输入日志内容示例'}
                            />
                        </div>

                        <div className='log-access-detail-title-main'>
                            <span className='log-access-detail-title-bar'>高级配置</span>
                            <span style={{marginLeft: 10}}>
                                <SdwSwitch
                                    value={enableState}
                                    changeValue={val => this.enableStateChange(val)}
                                />
                            </span>
                        </div>
                        {enableState &&
                            <>
                                <div className='log-access-detail-box-main'>
                                    <span className='label'>开始解析字符</span>
                                    <SdwSelect
                                        borderTopRightRadius={0}
                                        borderBottomRightRadius={0}
                                        placeholder='请选择参数类型'
                                        width={180}
                                        value={params.startParseType}
                                        data={[{
                                            id: 'ADVANCED_PARSE_TYPE_STATIC_PARAM',
                                            text: '固定参数'
                                        }, {
                                            id: 'ADVANCED_PARSE_TYPE_DYNAMIC_PARAM',
                                            text: '动态参数'
                                        }]}
                                        onChange={val => this.setParamsData('startParseType', val)}
                                    />
                                    <SdwInput
                                        borderTopLeftRadius={0}
                                        borderBottomLeftRadius={0}
                                        placeholder={params.startParseType == 'ADVANCED_PARSE_TYPE_STATIC_PARAM' ?
                                            '从左往右，从字符第一次出现的位置开始解析' : '请输入开始解析的字符索引下标(从左往右)'}
                                        width={420}
                                        value={params.startParseValue}
                                        onChange={val => this.setParamsData('startParseValue', val)}
                                    />
                                </div>
                                <div className='log-access-detail-box-main'>
                                    <span className='label'>结束解析字符</span>
                                    <SdwSelect
                                        borderTopRightRadius={0}
                                        borderBottomRightRadius={0}
                                        placeholder='请选择参数类型'
                                        width={180}
                                        value={params.endParseType}
                                        data={[{
                                            id: 'ADVANCED_PARSE_TYPE_STATIC_PARAM',
                                            text: '固定参数'
                                        }, {
                                            id: 'ADVANCED_PARSE_TYPE_DYNAMIC_PARAM',
                                            text: '动态参数'
                                        }]}
                                        onChange={val => this.setParamsData('endParseType', val)}
                                    />
                                    <SdwInput
                                        borderTopLeftRadius={0}
                                        borderBottomLeftRadius={0}
                                        placeholder={params.endParseType == 'ADVANCED_PARSE_TYPE_STATIC_PARAM' ?
                                            '从左往右，在字符最后一次出现的位置结束解析' : '请输入结束解析的字符索引下表(从右往左)'}
                                        width={420}
                                        value={params.endParseValue}
                                        onChange={val => this.setParamsData('endParseValue', val)}
                                    />
                                </div>
                            </>
                        }

                        <div className='log-access-detail-title-main'>
                            <span className='log-access-detail-title-bar'>字段内容定义</span>
                            <span className='log-access-detail-title-des'>用于字段内容配置</span>
                        </div>
                        <div className='log-access-detail-btn'>
                            <SdwButton
                                type='outline'
                                text='获取字段配置'
                                onClick={this.getFieldConfig}
                            />
                        </div>
                    </div>

                    {!_.isEmpty(tableDataList) &&
                        <div className='log-access-detail-table-box'>
                            <SdwTable
                                setOverflow='visible'
                                dataSource={_.isArray(tableDataList) ? tableDataList : []}
                                columns={this.getColumns()}
                            />
                        </div>
                    }

                    <div className='log-access-detail-form'>
                        <div className='log-access-detail-title-main'>
                            <span className='log-access-detail-title-bar'>日志接入测试</span>
                            <span className='log-access-detail-title-des'>用于测试解析规则</span>
                        </div>
                        <div className='log-access-detail-btn'>
                            <SdwButton
                                type='outline'
                                text='保存并测试'
                                onClick={this.submitClick}
                            />
                            {
                                showResult &&
                                <span className={isPass ? 'pass' : 'nopass'}>{isPass ? '测试通过' : '解析失败'}</span>
                            }
                        </div>

                        <div className='log-access-detail-form-button'>
                            <SdwButton
                                disabled={!showResult}
                                type='cancel'
                                text='完成'
                                onClick={() => {
                                    this.props.history.push('/admin/dataCollect/logAccess')
                                }}
                            />
                            <span style={{marginLeft: 8}}></span>
                            <SdwButton
                                disabled={!isPass}
                                type='submit'
                                text='下一步'
                                onClick={() => this.jumpToTwo()}
                            />
                        </div>
                    </div>
                </div>

                {statusVisible &&
                    <StatusWin
                        statusVisible={statusVisible}
                        socketList={socketList}
                        closeDialog={() => this.setState({statusVisible: false})}
                    />
                }
            </>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(LogAccessDetail))
