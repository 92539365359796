import React from 'react'
import _ from 'lodash'
import { formatJsonParse } from '@utils/formatJson'
import SdwInput from '@common/Input'
import SdwRadiobox from '@common/Radiobox'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import SdwPopover from '@common/Popover'
import SdwMessage from '@common/Message/index'
import ConfigRule from '../../ConfigRule'
import { getTableApiItems, storeCollection, updateCollection, getFieldRuleConf, getInfoById } from '@services/dataAccess'

import '../../style/dataAccess.css'

const DEFAULT_CONFIG_RULE = {
  queue: 1,
  method_name: '',
  method_param: ''
}

const BINLOG = 'BINLOG'
const INIT = 'INIT'
const TIP_STRING = '若源表为分表（按日期或特定规则分开），需配置对应分表规则，保障数据完整入库'

class DataAccess extends React.PureComponent {

  state = {
    taskID: null, // taskID不为null，表示编辑；否则表示新增
    sholdCheckValidate: false,
    sholdSqlCheckValidate: false,
    sholdBreakTableCheckValidate: false,
    resourseTableValue: '',
    targetTableValue: '',
    timeValue: '',
    filedNameValue: '',
    filedTypeValue: '',
    tableRuleValue: 1,
    configRules: '',
    syncType: BINLOG,
    isBreakTable: '1', // 0分  1不分
    sqlConfigValue: '',
    configRuleList: [],
    resourseTableList: [],
    targetTableList: [],
    filedTypeList: [
      {
        id: 'int',
        text: 'int'
      }, {
        id: 'double',
        text: 'double'
      }, {
        id: 'varchar',
        text: 'varchar'
      }, {
        id: 'float',
        text: 'float'
      }
    ],
    fieldList: []
  }

  componentDidMount() {
    this.initData()

    let propState = this.props.location.state || {}
    let ID = propState.id
    
    if (!!ID) {
      this.setState({ taskID: ID })
      this.onGetInfoById(ID)
    }
  }

  initData = () => {
    this.getTableApiItems()
    this.getFieldRuleConf()
  }

  onGetInfoById = id => {
    let pipelineParams = {
      task_id: {
        value: id,
        operator: '='
      }
    }
    getInfoById({
      search: JSON.stringify(pipelineParams),
      tablename: 'data_pipeline'
    }).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        let curArr = Array.isArray(record.items) ? record.items : []

        if (curArr.length) {
          this.setState({ configRuleList: curArr })
        }
      } else {
        SdwMessage.error('获取数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('接口异常：' + error)
      })

    let taskParams = {
      id: {
        value: id,
        operator: '='
      }
    }
    getInfoById({
      search: JSON.stringify(taskParams),
      tablename: 'data_task'
    }).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        let items = record.items || []

        if (items.length) {
          let obj = items[0]
          let sourceID = formatJsonParse(obj.source_id || '')

          this.setState({
            syncType: obj.stream_type,
            resourseTableValue: +obj.source_table,
            targetTableValue: +obj.target_table,
            sqlConfigValue: obj.sql,
            timeValue: obj.suffix_time_field,

            filedNameValue: sourceID.field,
            filedTypeValue: sourceID.type,

            isBreakTable: String(obj.is_split_table),
            tableRuleValue: +obj.split_table_type,
            configRules: !!obj.split_table_rule ? obj.split_table_rule : ''
          })
        }
      } else {
        SdwMessage.error('获取数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('接口异常：' + error)
      })
  }
  
  getTableApiItems = () => {

    // 来源表参数
    let params = {
      limit: 10000,
      search: JSON.stringify({
        data_set_type: {
          value: 'mysql',
          operator: '='
        },
        warehouse_level: {
          value: 'ODS',
          operator: '='
        },
        enable: {
          value: '1',
          operator: '='
        }
      })
    }

    // 来源表
    getTableApiItems(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let curArr = Array.isArray(res.data.data.items) ? res.data.data.items : []
        curArr = curArr.map(i => {
          let curName = !!i.database_name ? `${i.database_name}.${i.table_name}` : `${i.table_name}`
          return {
            id: i.id,
            text: `${curName}（${i.data_set_chinese}）`,
          }
        })

        this.setState({resourseTableList: curArr})
      } else {
        SdwMessage.error('获取来源表数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('获取来源表接口异常：' + error)
      })

    // 目标表参数
    let paramData = {
      limit: 10000,
      search: JSON.stringify({
        data_set_type: {
          value: 'es',
          operator: '='
        },
        warehouse_level: {
          value: 'ODS',
          operator: '='
        },
        enable: {
          value: '1',
          operator: '='
        }
      })
    }

    // 目标表
    getTableApiItems(paramData).then(res => {
      if (res && res.data && res.data.code === 0) {
        let curArr = Array.isArray(res.data.data.items) ? res.data.data.items : []
        curArr = curArr.map(i => {
          let curName = !!i.database_name ? `${i.database_name}.${i.table_name}` : `${i.table_name}`
          return {
            id: i.id,
            text: `${curName}（${i.data_set_chinese}）`,
          }
        })

        this.setState({targetTableList: curArr})
      } else {
        SdwMessage.error('获取目标表数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('获取目标表接口异常：' + error)
      })
  }

  getFieldRuleConf = () => {
    getFieldRuleConf().then(res => {
      if (res && res.data && res.data.code === 0) {
        let fieldRule = res.data.data && res.data.data.field_rule || {}
        let curArr = Object.keys(fieldRule).map(key => {
          return {
            id: key,
            text: fieldRule[key]
          }
        })

        this.setState({fieldList: curArr})
      } else {
        SdwMessage.error('获取数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('接口异常：' + error)
      })
  }

  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    if (label == "主题域/业务" && curValue.ids && curValue.ids.length) {
      return true
    }
    return `${label}不能为空`
  }

  onChangeConfigRule = (index, type, val) => {
    let { configRuleList } = this.state
    let curArr = _.cloneDeep(configRuleList)

    if (type === 'queue') {
      let beforeVal = Number(index - 1)
      let currentVal = Number(val - 1)

      if (beforeVal < currentVal) {
        for (let j = beforeVal + 1; j <= currentVal; j++) {
          curArr[j]['queue'] = curArr[j]['queue'] - 1
        }
      } else if (beforeVal > currentVal) {
        for (let j = beforeVal - 1; j >= currentVal; j--) {
          curArr[j]['queue'] = curArr[j]['queue'] + 1
        }
      }
    }

    curArr[index - 1][type] = val

    this.setState({ configRuleList: curArr })
  }
  
  onDelConfigRule = index => {
    let { configRuleList } = this.state
    let curArr = _.cloneDeep(configRuleList)
    let lastIndex = curArr.length - 1
    curArr.splice(index, 1)

    // 删除的不是最后一项，需要更新删除下面的序号
    if (lastIndex !== index) {
      curArr = curArr.map((item, k) => {
        if (k < index) {
          return item
        } else {
          return _.assign({}, item, {
            queue: item.queue - 1
          })
        }
      })
    }

    this.setState({ configRuleList: curArr })
  }

  addConfigRules = () => {
    let { configRuleList } = this.state
    let curArr = _.cloneDeep(configRuleList)
    curArr.push(_.assign({}, DEFAULT_CONFIG_RULE, {
      queue: configRuleList.length + 1
    }))

    this.setState({ configRuleList: curArr })
  }

  handleSubmit = () => {
    let {
      taskID, // id
      syncType, // 同步方式
      resourseTableValue, // 来源表
      targetTableValue, // 目标表
      sqlConfigValue, // SQL
      filedNameValue, // 唯一键-字段名
      filedTypeValue, // 唯一键-字段类型
      isBreakTable, // 是否分表
      tableRuleValue, // 分表规则

      timeValue, // 时间字段
      configRules, // 配置规则
      configRuleList, // 规则配置
    } = this.state

    let isPassValidate = !!resourseTableValue &&
    !!targetTableValue && (syncType === INIT ? !!sqlConfigValue : true) && !!filedNameValue &&
    !!filedTypeValue && (!(+isBreakTable) ? !!tableRuleValue : true)

    if (!isPassValidate) {
      this.setState({
        sholdCheckValidate: true,
        sholdSqlCheckValidate: syncType === INIT,
        sholdBreakTableCheckValidate: !(+isBreakTable)
      })
      return
    }

    let params = {
      stream_type: syncType,
      source_table: resourseTableValue,
      target_table: targetTableValue,
      ...(syncType === INIT ? {
        sql: sqlConfigValue
      } : {}),
      suffix_time_field: timeValue,
      source_id: JSON.stringify({
        field: filedNameValue,
        type: filedTypeValue
      }),
      is_split_table: String(+isBreakTable),
      ...(!(+isBreakTable) ? {
        split_table_type: tableRuleValue,
        split_table_rule: configRules
      } : {})
    }

    let pipelineList = !!configRuleList.length ? configRuleList.map(i => {
      return {
        queue: i.queue,
        method_name: i.method_name,
        method_param: i.method_param
      }
    }) : []

    if (!!taskID) {

      // 编辑
      updateCollection({
        id: taskID,
        task: JSON.stringify(params),
        pipeline: JSON.stringify(pipelineList)
      }).then(res => {
        if (res && res.data && res.data.code === 0) {
          SdwMessage.success('任务编辑成功')
          this.props.history.push('/admin/dataCollect/dataManagement')
        } else {
          let msg = !!res.data.msg ? `(${res.data.msg})` : ''
          SdwMessage.error(`任务编辑失败${msg}`)
        }
      })
        .catch(error => {
          SdwMessage.error('接口异常：' + error)
        })

    } else {

      // 新增
      storeCollection({
        task: JSON.stringify(params),
        pipeline: JSON.stringify(pipelineList)
      }).then(res => {
        if (res && res.data && res.data.code === 0) {
          SdwMessage.success('任务创建成功')
          this.props.history.push('/admin/dataCollect/dataManagement')
        } else {
          let msg = !!res.data.msg ? `(${res.data.msg})` : ''
          SdwMessage.error(`任务创建失败${msg}`)
        }
      })
        .catch(error => {
          SdwMessage.error('接口异常：' + error)
        })
    }
  }

  changeCheckedRadiobox = val => {
    this.setState({
      isBreakTable: val,
      sholdBreakTableCheckValidate: Number(val) === 1
    })
  }

  changeCheckedSync = val => {
    this.setState({
      syncType: val,
      sholdSqlCheckValidate: val === BINLOG}
    )
  }

  render () {
    let {
      sholdCheckValidate,
      sholdSqlCheckValidate,
      sholdBreakTableCheckValidate,
      resourseTableValue,
      targetTableValue,
      timeValue,
      filedNameValue,
      filedTypeValue,
      tableRuleValue,
      configRules,
      syncType,
      isBreakTable,
      sqlConfigValue,
      configRuleList,
      resourseTableList,
      targetTableList,
      filedTypeList,
      fieldList
    } = this.state

    return (
      <>
        <>
          <div className="data-access__body">
            <span className="number-title-wrap">
              <span className="number-title__two"></span>
              <span className="config-title">接入配置</span>
            </span>
            <div className="main-body">

              <div className="label-title-wrap">
                <i className="label-title-divider" />
                <span className="label-title">接入配置</span>
                <span className="label-title-des">请保障信息准确，避免同步出错</span>
              </div>
              <div className="label-title-wrap">
                <span className="label required">同步方式：</span>
                <SdwRadiobox
                  options={[{
                    text: 'BINLOG(实时增量)',
                    value: BINLOG
                  }, {
                    text: 'INIT(批量同步)',
                    value: INIT
                  }]}
                  value={syncType}
                  changeCheckedFun={val => this.changeCheckedSync(val)}
                />
              </div>
              <div className="label-title-wrap">
                <span className="label required">来源表：</span>
                <SdwSelect
                  value={resourseTableValue}
                  data={resourseTableList}
                  width={440}
                  readOnly={false}
                  placeholder="请选择"
                  validateFun={(value) => this.validateFun(value, '来源表')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({resourseTableValue: val})} />
              </div>
              <div className="label-title-wrap">
                <span className="label required">目标表：</span>
                <SdwSelect
                  value={targetTableValue}
                  data={targetTableList}
                  width={440}
                  readOnly={false}
                  placeholder="请选择"
                  validateFun={(value) => this.validateFun(value, '目标表')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({targetTableValue: val})} />
              </div>
              {
                syncType === INIT &&
                <div className="label-title-wrap">
                  <span className="label required">SQL：</span>
                  <SdwInput
                    width={440}
                    type="textarea"
                    value={sqlConfigValue}
                    validateFun={(value) => this.validateFun(value, 'SQL')}
                    sholdCheckValidate={sholdSqlCheckValidate}
                    onChange={val => this.setState({sqlConfigValue: val})}
                  />
                </div>
              }
              <div className="label-title-wrap">
                <span className="label">时间字段：</span>
                <SdwInput
                  width={440}
                  placeholder="请输入"
                  value={timeValue}
                  onChange={val => this.setState({timeValue: val})}
                />
              </div>
              <div className="label-title-wrap">
                <span className="label required">唯一键：</span>
                <SdwInput
                  width={194}
                  placeholder="请输入字段名"
                  value={filedNameValue}
                  validateFun={(value) => this.validateFun(value, '字段名')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({filedNameValue: val})}
                />
                <span style={{ marginLeft: 8 }}/>
                <SdwSelect
                  value={filedTypeValue}
                  data={filedTypeList}
                  width={200}
                  readOnly={false}
                  placeholder="请选择字段类型"
                  validateFun={(value) => this.validateFun(value, '字段类型')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({filedTypeValue: val})} />
              </div>
              <div className="label-title-wrap">
                <span className="label required">是否分表
                <SdwPopover tip={TIP_STRING}>
                  <i className="icon-tip" />
                </SdwPopover>：
                </span>
                <SdwRadiobox
                  options={[{
                    text: '是',
                    value: '0'
                  }, {
                    text: '否',
                    value: '1'
                  }]}
                  value={isBreakTable}
                  changeCheckedFun={val =>this.changeCheckedRadiobox(val)}
                />
              </div>
              {
                !(+isBreakTable) &&
                <>
                  <div className="label-title-wrap">
                    <span className="label required">分表规则：</span>
                    <SdwSelect
                      value={tableRuleValue}
                      data={[
                        {
                          id: 1,
                          text: '连续型'
                        }
                      ]}
                      width={440}
                      readOnly={false}
                      placeholder="请选择字段类型"
                      validateFun={(value) => this.validateFun(value, '分表规则')}
                      sholdCheckValidate={sholdBreakTableCheckValidate}
                      onChange={val => this.setState({tableRuleValue: val})} />
                  </div>
                  <div className="label-title-wrap">
                    <span className="label">配置规则：</span>
                    <SdwInput
                      width={440}
                      type="textarea"
                      placeholder="{“开始后缀”,“结束后缀”,“分隔符”}"
                      value={configRules}
                      onChange={val => this.setState({configRules: val})}
                    />
                  </div>
                </>
              }

              <div className="label-title-wrap">
                <i className="label-title-divider" />
                <span className="label-title">规则配置</span>
                <span className="label-title-des">（选填）请合理配置清洗函数，保障数据可用性</span>
              </div>
              <div className="label-title-wrap" style={{ display: 'block' }}>
                {
                  !!configRuleList.length &&
                  configRuleList.sort((a, b) => a.queue - b.queue).map((item, index) => {
                    let dataList = configRuleList.map((i, index) => {
                      return {
                        id: index + 1,
                        text: index + 1
                      }
                    })

                    return (
                      <div key={'' + item.queue + item.method_param}>
                        <ConfigRule 
                          data={item}
                          index={index}
                          dataList={dataList}
                          fieldList={fieldList}
                          changeConfigRule={this.onChangeConfigRule}
                          delConfigRule={this.onDelConfigRule}
                        />
                      </div>
                    )
                  })
                }
                <span 
                  className="add-config-rule-btn"
                  onClick={this.addConfigRules}
                >添加配置规则</span>
              </div>

            </div>
          </div>
        </>
        <div className="data-access__footer">
          <SdwButton
            type="submit"
            text="保存配置"
            onClick={this.handleSubmit}
          />
        </div>
      </>
    )
  }
}

export default DataAccess