import { request } from '../utils/request'

// 获取数据字典页面常见主题域和改主题拥有的字段列表
export const getCommonTopics = () => {
  return request({ url: '/statistics/dataMarket/getCommonTopics', method: 'get' })
}

export const getCountedFields = () => {
  return request({ url: '/statistics/dataMarket/getCountedFields', method: 'get' })
}
