import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import './index.css'
import NoRight from '@pages/NoRight'
import _ from 'lodash'

class Tabs extends React.PureComponent {

  state = {
    curIndex: 0
  }

  componentDidMount() {
    const { curMenuIndex } = this.props
    if (typeof curMenuIndex === 'number') this.setState({ curIndex: curMenuIndex })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { curMenuIndex } = this.props
    if (prevProps.curMenuIndex !== curMenuIndex && typeof curMenuIndex === 'number') {
      this.setState({ curIndex: curMenuIndex })
    }
  }

  handleClick (index, item) {
    this.setState({ curIndex: index })
    this.props.updatePageInfo('')
    this.props.updateTabsInfo(index)
    this.props.onChange(item)
  }

  render () {
    let { menu, titleWidth } = this.props
    let { curIndex } = this.state

    let CUR_COMPONENT = null
    if (_.isArray(menu) && menu.length) {
      CUR_COMPONENT = menu[curIndex].component
    }

    return (
      <div style={{position: 'relative'}}>
        {
          Array.isArray(menu) &&
          !!menu.length ?
          <>
            <div className="sdw-tabs-header-wrap">
              {
                menu.map((item, index) => (
                  <span 
                    key={item.title} 
                    style={{
                      width: titleWidth
                    }}
                    className={menu[curIndex].title === item.title ? 'sdw__tabs-page-title current' : 'sdw__tabs-page-title'}
                    onClick={() => this.handleClick(index, item)}
                  >
                    {item.title}
                  </span>
                ))
              }
            </div>
            <span className="sdw__tabs-page-title__liner" style={{position: 'absolute'}}>
              <span className="sdw__tabs-page-title__liner-current" style={{
                left: curIndex * titleWidth,
                width: titleWidth
              }}></span>
            </span>
            <div style={{ marginTop: 2, border: '1px solid transparent' }}>
              {
                CUR_COMPONENT !== null &&
                <CUR_COMPONENT {...this.props} />
              }
            </div>
          </> :
          <NoRight
            height={800}
          />
        }
      </div>
    )
  }
}

Tabs.propTypes = {
  menu: PropTypes.array,
  titleWidth: PropTypes.number, // 数字：需要进行计算
  curMenuIndex: PropTypes.number, // 当前tab的位置
  onChange: PropTypes.func
}

Tabs.defaultProps = {
  menu: [],
  titleWidth: 98,
  curMenuIndex: 0,
  onChange: _.noop
}

const mapState = state => ({
  pageInfo: state.page.pageInfo,
  tabsInfo: state.page.tabsInfo
})

const mapDispatch = dispatch => ({
  updatePageInfo: dispatch.page.updatePageInfo,
  updateTabsInfo: dispatch.page.updateTabsInfo
})

export default withRouter(connect(mapState, mapDispatch)(Tabs))