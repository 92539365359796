import React from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import SdwCheckbox from '@common/Checkbox'
import SdwEmpty from '@common/Empty'

import './style/index.css'

class IndicatorAttributes extends React.PureComponent {

    updateSelectedTarget = (targetTime, targetDimension) => {
        let {curShowSelectedTarget, curClickTreeValue, currentData} = this.props

        let checkedTime = targetTime.filter(i => i.isChecked)
        let checkedDimension = targetDimension.filter(i => i.isChecked)

        if (!checkedTime.length || !checkedDimension.length) {
            this.props.updateCurShowSelectedTarget([])
            return
        }

        let addTarget = []
        checkedTime.forEach(time => {
            checkedDimension.forEach(dimen => {

                let oriTarget = curShowSelectedTarget[curShowSelectedTarget.findIndex(i => i.time === time.id && i.dimension === dimen.id)] || {}

                addTarget.push({
                    'table_id': currentData.id,
                    ...!!oriTarget.db_id ? {
                        'db_id': oriTarget.db_id,
                    } : {},
                    'target_id': curClickTreeValue.id,
                    'name_cn': curClickTreeValue.target_name_cn,
                    'name': curClickTreeValue.target_name,
                    'time': time.id,
                    'dimension': dimen.name_en,
                    'target_type': oriTarget.target_type || 1,  // 1:普通指标 2:top指标
                    'target_top': (oriTarget.top_count || typeof oriTarget.top_count === 'number') ? oriTarget.top_count : null
                })
            })
        })

        this.props.updateCurShowSelectedTarget(addTarget)
    }

    render() {

        let {
            targetTime,
            targetDimension,
        } = this.props

        return (
            <>
                <div className="title">指标属性</div>
                <div className="label-box">
                  <div className="label-box__title">时间</div>
                  <div className="label-box__main">
                    {
                      (_.isArray(targetTime) && !!targetTime.length) ?
                          targetTime.map((item, index) => (
                              <div key={index} className="application-detail__checkbox-wrap">
                                <SdwCheckbox
                                    text={item.name}
                                    isChecked={item.isChecked}
                                    changeCheckedFun={() => {
                                      let curArr = _.cloneDeep(targetTime)
                                      curArr[index].isChecked = !curArr[index].isChecked
                                      this.props.updateTargetTime(curArr)

                                      this.updateSelectedTarget(curArr, targetDimension)
                                    }}
                                />
                              </div>
                          )) :
                          <SdwEmpty
                              height="160px"
                              text="请从左侧选择指标"
                              hideImage={true}
                          />
                    }
                  </div>
                </div>

                <div className="label-box">
                    <div className="label-box__title">维度</div>
                    <div className="label-box__main">
                        {
                            (_.isArray(targetDimension) && !!targetDimension.length) ?
                                targetDimension.map((item, index) => (
                                    <div key={index} className="application-detail__checkbox-wrap">
                                        <SdwCheckbox
                                            text={item.name}
                                            isChecked={item.isChecked}
                                            changeCheckedFun={() => {
                                                let curArr = _.cloneDeep(targetDimension)
                                                curArr[index].isChecked = !curArr[index].isChecked
                                                this.props.updateTargetDimension(curArr)

                                                this.updateSelectedTarget(targetTime, curArr)
                                            }}
                                        />
                                    </div>
                                )) :
                                <SdwEmpty
                                    height="160px"
                                    text="请从左侧选择指标"
                                    hideImage={true}
                                />
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapState = state => ({
    targetTime: state.application.targetTime,
    targetDimension: state.application.targetDimension,
    curShowSelectedTarget: state.application.curShowSelectedTarget,
    curClickTreeValue: state.application.curClickTreeValue,
})

const mapDispatch = dispatch => ({
    updateTargetTime: dispatch.application.updateTargetTime,
    updateTargetDimension: dispatch.application.updateTargetDimension,
    updateCurShowSelectedTarget: dispatch.application.updateCurShowSelectedTarget,
})

export default connect(mapState, mapDispatch)(IndicatorAttributes)
