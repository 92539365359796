import { PureComponent } from "react"
import _ from 'lodash'
import SdwButton from '@common/Button'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import { getAllModels } from '@services/dataGovern'

import './index.css'
import { hasPriv } from "@components/FrontendAuth/AuthMap"
import { fileDownload } from "@utils/request"

export default class TableTempConfig extends PureComponent {

  state = {
    tableData: {},
    searchData: {
      page: 1,
      limit: 10
    }
  }

  componentDidMount() {
    this.initData()
  }

  initData = () => {
    getAllModels({
      ...this.state.searchData
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        if (!_.isEmpty(data)) this.setState({tableData: data})
      }
    })
  }

  getcolumns = () => {
    return [
      {
        title: "配置时间",
        dataIndex: "create_time",
      }, {
        title: "业务",
        dataIndex: "service_name",
      }, {
        title: "模板类型",
        dataIndex: "model_name",
      }, {
        title: "最后修改时间",
        dataIndex: "update_time",
      }, {
        title: "最后修改人",
        dataIndex: "update_user",
      }, 
      ...hasPriv('downloadModel') ? [{
        title: "操作",
        render: data => {
          return (
            <>
              <SdwButton
                text='下载'
                onClick={() => fileDownload(`/collection/dataGovern/downloadModel?id=${data.id}`)}
              />
            </>
          )
        }
      }] : []
    ]
  }

  render() {
    const { tableData, searchData } = this.state

    return (
      <>
        {/* <div className="page-mainbody">
          <div className="page-search">
            <div className="input-wrap">
              <div className="ele-search-input">
                <span className="data-govern__table-temp-config__table-type">表模板类型</span>
              </div>
              
              <div className="btn-group">
                <SdwButton 
                  type="submit" 
                  iconClass='iconfont icon-add'
                  text='新增'
                  width={120}
                  // onClick={ () => this.addOrEditStandard({ title: '数据接入' }) }
                />
              </div>
            </div>
          </div>
        </div> */}
        
        <div style={{ padding: 20, background: '#fff' }}>
          <SdwTable
            columns={this.getcolumns()}
            dataSource={tableData.items || []}
          />
          <SdwPagenation
            total={tableData.total}
            currentPage={tableData.current_page}
            pageCountList={[10, 15, 20, 40]}
            pageCount={tableData.per_page}
            getClickPage={page => {
              this.setState({
                searchData: _.assign({}, searchData, {page})
              }, this.initData)
            }}
            pageCountChange={limit => {
              this.setState({
                searchData: _.assign({}, searchData, {limit})
              }, this.initData)
            }}
          />
        </div>
      </>
    )
  }
}