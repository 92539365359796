function formatJsonParse (str, defaultVal) {
  let jsonData = defaultVal

  if (typeof str === 'string') {
    try {
      jsonData = JSON.parse(str)
    } catch (error) {
      
    }
  }
  return jsonData
}

export {
  formatJsonParse
}
