import React from 'react'
import _ from 'lodash'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import DataReview from './DataReview'
import { Button } from 'element-react'
import { getItermSelects, getBusinessStatisticByTopic } from '@services/dataStandars'
import { getAllTasks, getTableApiItems } from '@services/dataAccess'
import SubmitDataWin from './SubmitDataWin'
import SdwMessage from '@common/Message'

import './style/index.css'

class DataManagement extends React.PureComponent {

  state = {
    curPage: 1,
    curLimit: 10,
    tableData: {},
    searchFieldsArr: [],
    searchValue: '',
    businessValue: '',
    domainValue: '',
    search_create_user: '',
    hideSearchField: true,
    field_name: '',
    businessArr: [],
    topicArr: [],
    topicID: '',  // 已选主题域id
    businessList: [], // 已选主题域对应的业务
    dataReviewVisible: false,
    tableID: '',
    taskID: '',
    curClickData: {},
    submitVisible: false
  }

  componentDidMount() {
    this.initData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // 清空查询
    if (prevState.searchValue !== this.state.searchValue && this.state.searchValue === '') {
      this.setState({
        tableID: ''
      }, this.onSearchFields)
    }
    if (prevState.topicID !== this.state.topicID) {
      this.getBusinessStatisticByTopic()
    }
  }

  initData = () => {
    this.onGetAllTasks()

    getItermSelects().then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        let business = Array.isArray(record.business) ? record.business.map(i => {
          return {
            id: i.business_name,
            text: i.business_name
          }
        }) : []
        let topic = Array.isArray(record.topic) ? record.topic.map(j => {
          return {
            id: j.topic_name,
            text: j.topic_name,
            topic_id: j.id
          }
        }) : []

        this.setState({
          businessArr: business,
          topicArr: topic
        })
      }
    })

    // 目标表参数
    let paramData = {
      limit: 10000,
      search: JSON.stringify({
        data_set_type: {
          value: 'es',
          operator: '='
        },
        warehouse_level: {
          value: 'ODS',
          operator: '='
        },
        enable: {
          value: '1',
          operator: '='
        }
      })
    }

    // 目标表
    getTableApiItems(paramData).then(res => {
      if (res && res.data && res.data.code === 0) {
        let curArr = Array.isArray(res.data.data.items) ? res.data.data.items : []
        curArr = curArr.map(i => {
          let curName = !!i.database_name ? `${i.database_name}.${i.table_name}` : `${i.table_name}`
          return Object.assign({}, i, {
            name: `${curName}（${i.data_set_chinese}）`,
          })
        })

        this.setState({searchFieldsArr: curArr})
      } else {
        SdwMessage.error('获取目标表数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('获取目标表接口异常：' + error)
      })
  }

  // 根据主题域ID请求对应的业务
  getBusinessStatisticByTopic = () => {
    let { topicID } = this.state
    getBusinessStatisticByTopic({id: topicID}).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data && res.data.data.list.map(i => {
          return {
            id: i.business_name,
            text: i.business_name
          }
        }) || []
        this.setState({
          businessList: record
        })
      }
    })
  }

  getColumns = () => {
    let self = this
    return [
      {
        title: "ID",
        dataIndex: "id",
        width: 30
      },
      {
        title: "主题域",
        dataIndex: "topic"
      },
      {
        title: "业务",
        dataIndex: "business"
      },
      {
        title: "表英文名",
        width: 140,
        render: data => {
          let name = !!data.database_name ? `${data.database_name}.${data.table_name}` : data.table_name
          return (
            <span>{name}</span>
          )
        }
      },
      {
        title: "表中文名",
        dataIndex: "data_set_chinese"
      },
      {
        title: "数据源类型",
        dataIndex: "data_set_type"
      },
      {
        title: "同步方式",
        dataIndex: "stream_type"
      },
      {
        title: "创建人",
        dataIndex: "create_user"
      },
      {
        title: "修改时间",
        dataIndex: "modify_time",
        width: 180,
      },
      {
        title: "操作",
        width: 180,
        render: function (data, record) {
          return (
            <span>
              <Button
                type="text"
                size="small"
                onClick={ () => self.addOrEditStandard({
                  title: '编辑',
                  ...data,
                }) }
                style={{"fontSize": "14px"}}
              >编辑</Button>
              <Button
                type="text"
                size="small"
                disabled={data.stream_type !== 'INIT'}
                onClick={ () => self.setState({ taskID: data.id }, () => self.setState({dataReviewVisible: true}))}
                style={{"fontSize": "14px"}}
              >预览数据</Button>
              <Button
                type="text"
                size="small"
                onClick={ () => self.setState({ curClickData: data }, () => self.setState({submitVisible: true}))}
                style={{"fontSize": "14px"}}
              >提交任务</Button>
            </span>
          )
        }
      }
    ]
  }

  addOrEditStandard = obj => {
    this.props.history.push({
      pathname: '/admin/dataCollect/dataAccess',
      state: obj
    })
  }

  onGetAllTasks = (params = {}) => {
    getAllTasks(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        this.setState({ tableData: record })
      } else {
        SdwMessage.error('获取数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('接口异常：' + error)
      })
  }

  onSearchFields = (params = {}) => {
    let { domainValue, businessValue, search_create_user, tableID, curPage, curLimit } = this.state
    let topic = !!params.topic ? params.topic : domainValue
    let business = !!params.business ? params.business : businessValue
    let table_id = !!params.table_id ? params.table_id : tableID
    let curParams = {
      page: !!params.page ? params.page : curPage,
      limit: !!params.limit ? params.limit : curLimit,
      search: JSON.stringify({
        ...(!!topic ? {
          topic: {
            value: topic,
            operator: '='
          }
        } : {}),
        ...(!!business ? {
          business: {
            value: business,
            operator: '='
          }
        } : {}),
        ...(!!table_id ? {
          target_table: {
            value: table_id,
            operator: '='
          }
        } : {}),
        ...(!!search_create_user ? {
          'data_task.create_user': {
            value: search_create_user,
            operator: 'like'
          }
        } : {})
      })
    }
    this.onGetAllTasks(curParams)
  }

  render () {
    let {
      tableData,
      searchFieldsArr,
      searchValue,
      businessValue,
      search_create_user,
      domainValue,
      hideSearchField,
      businessArr,
      topicArr,
      businessList, // 已选主题域对应的业务
      dataReviewVisible,
      submitVisible,
      taskID,
      curClickData,
    } = this.state

    let filterSearchFieldsArr = searchFieldsArr.filter(i => i.name.indexOf(searchValue) !== -1)

    return (
      <>
        <div className="page-mainbody">
          <div className="page-search">
            <div className="input-wrap">
              <div className="ele-search-input">
                <SdwSelect
                  value={domainValue}
                  label="主题域"
                  width={160}
                  data={topicArr}
                  onChange={(val, item) => this.setState({
                    curPage: 1,
                    domainValue: val,
                    topicID: item.topic_id
                  }, () => {
                    this.onSearchFields({
                      topic: val
                    })
                  })}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  value={businessValue}
                  width={160}
                  label="业务"
                  data={domainValue ? businessList : businessArr}
                  onChange={val => this.setState({curPage: 1, businessValue: val}, () => {
                    this.onSearchFields({
                      business: val
                    })
                  })}
                />
              </div>
              <div className="ele-search-input">
                <SdwInput
                  width={250}
                  label="表名"
                  value={searchValue}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onFocus={() => this.setState({
                    curPage: 1,
                    hideSearchField: false
                  })}
                  onBlur={val => {
                    setTimeout(() => {
                      this.setState({
                        hideSearchField: true
                      })
                    }, 500);
                  }}
                  onChange={val => this.setState({searchValue: val})}
                />
                {
                  _.isArray(filterSearchFieldsArr) &&
                  !!filterSearchFieldsArr.length &&
                  !hideSearchField &&
                  <div className="sdw-data-management__search-input-list-wrap">
                    {
                      filterSearchFieldsArr.map((item, index) => (
                        <div
                          key={index}
                          className="ellipsis"
                          title={item.name}
                          onClick={() => {
                            this.onSearchFields({
                              table_id: item.id
                            })
                            this.setState({
                              hideSearchField: true,
                              searchValue: item.name,
                              tableID: item.id
                            })
                          }}>{item.name}</div>
                      ))
                    }
                  </div>
                }
              </div>
              <div className="ele-search-input">
                <SdwInput
                    width={250}
                    label="创建人"
                    value={search_create_user}
                    onBlur={val => {
                      this.onSearchFields({
                        search_create_user: val
                      })
                    }}
                    onChange={val => this.setState({curPage: 1, search_create_user: val})}
                />
              </div>
              <div className="btn-group">
                <Button
                  type="primary"
                  icon='plus'
                  width={120}
                  onClick={ () => this.addOrEditStandard({ title: '数据接入' }) }
                >接入数据</Button>
              </div>
            </div>
          </div>
          <div className="page-table">
            <SdwTable
              columns={ this.getColumns() }
              tdWhiteSpace={ true }
              dataSource={ tableData.items || [] }
            />
            {
              _.isArray(tableData.items) && tableData.items.length &&
              <div className="data-management__pagenation-wrap">
                <SdwPagenation
                  pageStyle={{ marginTop: 0 }}
                  total={ tableData.total }
                  currentPage={ tableData.current_page }
                  pageCountList={[10, 15, 20, 40]}
                  pageCount={ tableData.per_page }
                  getClickPage={ page => {
                    this.setState({
                      curPage: page
                    })
                    this.onSearchFields({page})
                  } }
                  pageCountChange={ limit => {
                    this.setState({
                      curLimit: limit
                    })
                    this.onSearchFields({limit})
                  } }
                />
              </div>
            }
          </div>
        </div>

        <DataReview
          task_id={taskID}
          dataReviewVisible={dataReviewVisible}
          changeDataReviewVisible={bool => this.setState({
            dataReviewVisible: typeof bool === "boolean" ? bool : !dataReviewVisible
          })}
        />

        <SubmitDataWin
          type='DataBaseWin'
          data={curClickData}
          submitVisible={submitVisible}
          changeVisible={bool => this.setState({
            submitVisible: typeof bool === "boolean" ? bool : !submitVisible
          })}
        />
      </>
    )
  }
}

export default DataManagement
