import { request } from '../utils/request'

//我的消息
export const getMessage = (params = {}) => {
  return request({ url: '/dataset/common/getMessage', method: 'post', data: params })
}

//待审批的消息
export const getProcessApproval = (params = {}) => {
  return request({ url: '/dataset/common/getProcessApproval', method: 'post', data: params })
}

//任务审批接口
// {
//   "approvalId": 1,   任务approvalId
//   "task_status": 1,    1同意，2驳回
//   "comment": ""    备注
// }
export const applyApproval = (params = {}) => {
  return request({ url: '/dataset/common/applyApproval', method: 'post', data: params })
}

export const getTopicBusiness = (params = {}) => {
  return request({ url: '/dataset/common/getTopicBusiness', method: 'get', params: params })
}