import { useState, useEffect, useLayoutEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Empty from '@common/Empty'
import Message from '@common/Message'
import FlowChart from '@common/FlowChart'
import img0 from './img/level0.png'
import img1 from './img/level1.png'
import img2 from './img/level2.png'
import img3 from './img/level3.png'
import { formatJsonParse } from '@utils/formatJson'
import { getDataKinship } from '@services/Derived'
import { LEVEL_TEXT_MAP } from '@pages/Market/Metadata/common'
import './index.css'

const typeMap = {
  'dataset': 'dataset', // 元数据表
  'atom': 'atom', // 原子指标
  '1': '1', // 基础型指标
  '2': '2', // 复合型指标
  '3': '3', // 代码型指标
  'app': 'app' // 应用表
}

const topMap = {
  [typeMap.dataset]: 1, // 元数据表
  [typeMap.atom]: 2, // 原子指标
  [typeMap['1']]: 3, // 基础型指标
  [typeMap['2']]: 4, // 复合型指标
  [typeMap['3']]: 3, // 代码型指标  -- 不与复合型指标  同时存在
  [typeMap.app]: 5 // 应用表
}

const IMG_MAP = {
  [typeMap.dataset]: img0,  // 元数据表
  [typeMap.atom]: img1,  // 原子指标
  [typeMap['1']]: img2,  // 基础型指标
  [typeMap['2']]: img2,  // 复合型指标
  [typeMap['3']]: img2,  // 代码型指标
  [typeMap.app]: img0  // 应用表
}

function BloodPage(props) {

  const [maxDeepth, setMaxDeepth] = useState(0) // 找出复合型指标最大深度（用于定位top）
  const [maxWidth, setMaxWidth] = useState(1) // 找出最大宽度（同一层级放最多的元素的数量）
  const [initRecord, setInitRecord] = useState([])
  const [initPathArr, setInitPathArr] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const [divClick, setDivClick] = useState(false) // 是否点击div
  const [position, setPosition] = useState({
    left: 0,
    top: 0
  })
  const [curRecord, setCurRecord] = useState({})
  const [kinshipData, setKinshipData] = useState([])
  const [curID, setCurID] = useState('')

  useEffect(() => {
    const id = _.get(props, 'match.params.id')
    if (!id) return
    setCurID(id)
    getDataKinship({
      target_id: id
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        if (!_.isArray(data)) return
        setKinshipData(data)
      } else {
        const msg = _.get(res, 'data.msg')
        Message.error(msg)
      }
    }).catch(err => Message.error('接口异常 '+ err))
  }, [props.baseInfo])

  useEffect(() => {
    if (!_.isArray(kinshipData)) return
    kinshipData.forEach(i => {
      if (i.sdepth && +i.sdepth > maxDeepth) {
        setMaxDeepth(Number(i.sdepth))
      }

      if (i.tdepth && +i.tdepth > maxDeepth) {
        setMaxDeepth(Number(i.tdepth))
      }
    })
  }, [kinshipData])

  useLayoutEffect(() => {
    if (!_.isArray(kinshipData) || !kinshipData.length) return
    let record = [], pathArr = []
    
    // 去重:避免接口有重复数据
    const curKinshipData = kinshipData.reduce((prev, item) => {
      if (!prev.every(i => i.sid === item.sid && i.tid === item.tid)) {
        prev.push(item)
      }
      return prev
    }, [kinshipData[0]])
    // console.log('----------------去重 curKinshipData: ', curKinshipData);

    let lengthMap = {
      'dataset': [], // 元数据表
      'atom': [], // 原子指标
      '1': [], // 基础型指标
      // '2': [], // 复合型指标  这里需要动态生成
      '3': [], // 代码型指标
      'app': [] // 应用表
    }

    for (let i = 1; i <= maxDeepth; i++) {
      lengthMap[`2-${i}`] = [] // 复合型指标：根据深度分层：深度从1开始（深度为1标识基础型指标）
    }

    curKinshipData.forEach(i => {

      if (i.stype == typeMap['dataset'] && !lengthMap['dataset'].includes(i.sid)) {
        lengthMap['dataset'].push(i.sid)
      }
      
      switch (i.ttype) {
        case typeMap['atom']:
          if (!lengthMap['atom'].includes(i.tid)) {
            lengthMap['atom'].push(i.tid)
          }
          break;
        
        case typeMap['1']:
          if (!lengthMap['1'].includes(i.tid)) {
            lengthMap['1'].push(i.tid)
          }
          break;

        case typeMap['2']:
          if (_.isArray(lengthMap[`2-${i.tdepth}`]) && !lengthMap[`2-${i.tdepth}`].includes(i.tid)) {
            lengthMap[`2-${i.tdepth}`].push(i.tid)
          }
          break;

        case typeMap['3']:
          if (!lengthMap['3'].includes(i.tid)) {
            lengthMap['3'].push(i.tid)
          }
          break;

        case typeMap['app']:
          if (!lengthMap['app'].includes(i.tid)) {
            lengthMap['app'].push(i.tid)
          }
          break;
      
        default:
          break;
      }
    })

    const datasetLength = lengthMap[typeMap['dataset']].length + 1;
    const atomLength = lengthMap[typeMap['atom']].length + 1;
    const oneLength = lengthMap[typeMap['1']].length + lengthMap[typeMap['3']].length + 1; // type=1和type=3的在同一层
    // const twoLength = lengthMap[typeMap['2']].length || 1;
    const appLength = lengthMap[typeMap['app']].length + 1;

    // 找出type为2中数组最多的元素长度
    let twoIndex = 1
    let twoLength = 1
    while (lengthMap.hasOwnProperty(`2-${twoIndex}`) && _.isArray(lengthMap[`2-${twoIndex}`])) {
      const len = lengthMap[`2-${twoIndex}`].length + 1
      if (twoLength < len) twoLength = len
      twoIndex++
    }
    let maxWidth = Math.max(datasetLength, atomLength, oneLength, appLength, twoLength) - 1
    setMaxWidth(maxWidth)

    const maxNum = Math.max(datasetLength, atomLength, oneLength, appLength)

    // console.log('*****************maxDeepth: ', maxDeepth);
    // console.log('*****************lengthMap: ', lengthMap);
    // console.log('----------------maxNum: ', maxNum);
    let leftMap = { // 计算每隔节点的left 
      1: maxNum / datasetLength,
      2: maxNum / atomLength,
      3: maxNum / oneLength
    }

    // 应为复合型指标存在多个，需要动态去确定leftMap
    for (let i = 1; i <= maxDeepth; i++) {
      leftMap[3+i] = maxNum / (lengthMap[`2-${i}`].length + 1)
    }
    // 给应用表设置leftMap
    leftMap[3 + maxDeepth + 1] = maxNum / appLength

    // console.log('-=-==-=--=-=-=-=-=leftMap: ', leftMap);
    const oriLeftMap = _.cloneDeep(leftMap)
    // console.log('-=-==-=--=-=-=-=-=oriLeftMap: ', oriLeftMap);

    curKinshipData.forEach((item, index) => {
      let { sid, source, stitle, target, ttitle, tid } = item

      let hasSameSID = record.some(i => i.id === sid)
      let style = {
        width: 172,
        height: 60
      }

      // 创建div数组
      // sid：sid的存在，一定会渲染对应的节点
      if (!!sid && !hasSameSID) {
        const level = getLevel(item.stype, lengthMap, maxDeepth, item.sid) // 层级决定高度

        record.push({
          id: sid,
          textContent: getDiv(level, sid, item, stitle, source, leftMap, item.stype, item.sdetail),
          style: getStyle(style, level, leftMap)
        })
        
        leftMap[level] = leftMap[level] + oriLeftMap[level]
      }
      let hasSameTID = record.some(i => i.id === tid)
      // tid：tid的存在，一定会渲染对应的节点
      if (!!tid && !hasSameTID) {
        const level = getLevel(item.ttype, lengthMap, maxDeepth, item.tid) // 层级决定高度

        record.push({
          id: tid,
          textContent: getDiv(level, tid, item, ttitle, target, leftMap, item.ttype, item.tdetail),
          style: getStyle(style, level, leftMap)
        })
        leftMap[level] = leftMap[level] + oriLeftMap[level]
      }

      // 创建连线的数组
      if (!pathArr.some(i => i.beginID === sid && i.endID === tid)) {
        pathArr.push({
          beginID: sid,
          beginPosition: 'bottom',
          endID: tid,
          endPosition: 'top'
        })
      }
      
    })

    setInitRecord(record)
    setInitPathArr(pathArr)
  }, [kinshipData, maxDeepth])

  useEffect(() => {
    document.addEventListener('click', onCloseDialog)
    return () => {
      document.removeEventListener('click', onCloseDialog)
    }
  }, [])

  // 通过类型获取level，然后动态计算出高度top
  function getLevel(type, lengthMap, maxDeepth, id) {
    let level = Number(topMap[type])

    if (type == '2') {
      for (const key in lengthMap) {
        if (Object.hasOwnProperty.call(lengthMap, key)) {
          const arr = lengthMap[key];
          if (arr.includes(id) && Number(key.slice(2))) {
            level = Number(key.slice(2)) + 3
          }
        }
      }
    }

    if (type == 'app') {
      // 3：元数据表+原子指标+基础型  maxDeepth：复合型   1：应用表自己
      level = 3 + maxDeepth + 1
    }

    return level
  }

  // 关闭弹窗
  function onCloseDialog() {
    setDivClick(false)
    setShowDialog(false)
  }

  function onDivClick(e, left, top, record, text) {
    setDivClick(true)
    commonFunc(e, left, top, record, text)
  }

  function onMouseEnter(e, left, top, record, text) {
    setDivClick(false)
    commonFunc(e, left, top, record, text)
  }

  function commonFunc(e, left, top, record, text) {
    e.stopPropagation()
    setCurRecord(text == record.source ? _.assign({type: record.type}, record.sdetail) : _.assign({type: record.type}, record.tdetail))
    setPosition({
      left: (left + 184),
      top: top
    })
    setShowDialog(true)
  }

  // 根据level获取top值
  function getTop(level = 1) {
    return 120 * level - 80
  }

  function getLeftAndTop(type, leftMap) {
    if (!type) return
    const level = Number(type) // 层级
    const left = (leftMap[level] - 1) * getWrapWidth(maxWidth) + 330
    const top = getTop(level)

    return {
      left,
      top
    }
  }

  function getStyle(style, level, leftMap) {
    const { left, top } = getLeftAndTop(level, leftMap)

    return {
      ...style,
      left,
      top
    }
  }

  function getDiv(level, id, item, title, text, leftMap, type, detail) {
    const { left, top } = getLeftAndTop(level, leftMap)
    const isCurrentID = _.get(detail, 'id') == curID // 标识当前指标

    return (
      <div 
        className={`detail-blood__flow-chart-wrap type__${isCurrentID ? 'current' : type}`}
        onClick={(e) => onDivClick(e, left, top, _.assign({id, type}, item), text)}
        onMouseEnter={e => onMouseEnter(e, left, top, _.assign({id, type}, item), text)}
        onMouseLeave={() => setShowDialog(false)}
      >
        <div>
          <img className='detail-blood__flow-chart__label-img' src={isCurrentID ? img3 : IMG_MAP[type]} />
          <span className='detail-blood__flow-chart__label' >{title}</span>
        </div>
        <div
          className='detail-blood__flow-chart__obj-wrap-div'
          title={text}
        >{text}</div>
      </div>
    )
  }
  
  const wrapWidth = useMemo(() => {
    return (maxWidth * getWrapWidth(maxWidth) + 600)
  }, [maxWidth])

  const flowHeight = useMemo(() => {
    return ((3 + maxDeepth + 1) * 120)
  }, [maxDeepth])

  // 计算公式
  const cal_expression = useMemo(() => {
    let ce = formatJsonParse(curRecord.cal_expression)
    if (_.isArray(ce)) {
      ce = ce.join('')
    }
    return ce
  }, [curRecord.cal_expression])

  const topicName = useMemo(() => {
    if (!curRecord.topic) return ''
    const record = props.topic.filter(i => i.id == curRecord.topic)[0]
    return _.get(record, 'topic_name') || ''
  }, [curRecord.topic])

  const serviceName = useMemo(() => {
    if (!curRecord.service) return ''
    const record = props.business.filter(i => i.id == curRecord.service)[0]
    return _.get(record, 'business_name') || ''
  }, [curRecord.service])

  return (
    <div 
      className='detail-blood__wrap-box'
      style={{
        // 3：元数据表+原子指标+基础型  maxDeepth：复合型   1：应用表自己
        minHeight: (3 + maxDeepth + 1) * 120 + 110
      }}
    >
      {
        kinshipData.length > 0 ?
        <div
          className='detail-blood__wrap-box__center-box'
          style={{
            width: wrapWidth,
            ...(wrapWidth + 200) < window.innerWidth ? {
              position: 'absolute',
              left: '45%',
              transform: 'translateX(-50%)'
            } : {}
          }}
        >
          <FlowChart
            width='100%'
            height={flowHeight}
            initRecord={initRecord}
            initPathArr={initPathArr}
          />
          {
            (divClick || showDialog) &&
            <div
              className='detail-blood__dialod-wrap' 
              style={{
              top: position.top,
              left: position.left
              }}
              onClick={e => e.stopPropagation()}
            >
              {
                // 表显示信息
                (curRecord.type == 'dataset' || curRecord.type == 'app') ?
                <>
                  <div className='label-wrap'>
                    <span className='label'>表名</span>
                    {
                      !!curRecord.database_name ?
                      <span className='label-break-all'>{ `${curRecord.database_name}.${curRecord.table_name}` || '-' }</span> :
                      <span className='label-break-all'>{ curRecord.table_name || '-' }</span>
                    }
                  </div>
                  <div className='label-wrap'>
                    <span className='label'>中文名</span>
                    <span className='label-break-all'>{ curRecord.data_set_chinese || curRecord.table_name_cn || '-' }</span>
                  </div>
                  {
                    // 标识应用表
                    curRecord.type == 'app' &&
                    <div className='label-wrap'>
                      <span className='label'>更新频率</span>
                      <span className='label-break-all'>{ curRecord.aaa || '-' }</span>
                    </div>
                  }
                  <div className='label-wrap'>
                    <span className='label'>主题</span>
                    <span className='label-break-all'>{ topicName || '-' }</span>
                  </div>
                  <div className='label-wrap'>
                    <span className='label'>仓库分层</span>
                    <span className='label-break-all'>{ LEVEL_TEXT_MAP[curRecord.warehouse_level] || '-' }</span>
                  </div>
                </> :
                <>
                  {/* 基础型，复合型，代码型 */}
                    <div className='label-wrap'>
                      <span className='label'>英文名</span>
                      <span className='label-break-all'>{ curRecord.target_name || '-' }</span>
                    </div>
                    <div className='label-wrap'>
                      <span className='label'>中文名</span>
                      <span className='label-break-all'>{ curRecord.target_name_cn || curRecord.target_name_ch || '-' }</span>
                    </div>
                    <div className='label-wrap'>
                      <span className='label'>指标id</span>
                      <span className='label-break-all'>{ curRecord.id || '-' }</span>
                    </div>
                    {
                      // 原子指标
                      curRecord.type == typeMap.atom ?
                      <>
                        <div className='label-wrap'>
                          <span className='label'>主题域/业务</span>
                          <span className='label-break-all'>{ `${topicName}/${serviceName}` || '-' }</span>
                        </div>
                        <div className='label-wrap'>
                          <span className='label'>时间字段</span>
                          <span className='label-break-all'>{ curRecord.time_field || '-' }</span>
                        </div>
                        <div className='label-wrap'>
                          <span className='label'>度量字段</span>
                          <span className='label-break-all'>{ curRecord.metric_field || '-' }</span>
                        </div>
                        <div className='label-wrap'>
                          <span className='label'>计算逻辑</span>
                          <span className='label-break-all'>{ curRecord.metric_type || '-' }</span>
                        </div>
                      </> : 
                      <>
                        { // 标基础型
                      curRecord.target_type == 1 &&
                      <div className='label-wrap'>
                        <span className='label'>修饰词</span>
                        <span className='label-break-all'>{ curRecord.decoration || '-' }</span>
                      </div>
                    }
                    { // 复合型、代码型
                      curRecord.target_type != 1 &&
                      <>
                        <div className='label-wrap'>
                          <span className='label'>原子指标</span>
                          <span className='label-break-all'>{ curRecord.source_targets_cn || '-' }</span>
                        </div>
                        <div className='label-wrap'>
                          <span className='label'>计算公式</span>
                          <span className='label-break-all'>{ cal_expression || '-' }</span>
                        </div>
                      </>
                    }
                    <div className='label-wrap'>
                      <span className='label'>维度字段</span>
                      {
                        (_.isArray(formatJsonParse(curRecord.store_dimension)) && formatJsonParse(curRecord.store_dimension).length > 0) ?
                        <div style={{
                          display: 'inline-block'
                        }}>
                          {
                            formatJsonParse(curRecord.store_dimension).map(i => (
                              <span 
                                className='label-nowrap' 
                                key={i.id}
                                title={_.isArray(i.id) ? i.id.join('#') : ''}
                              >{_.isArray(i.id) && i.id.join('#') || '-'}</span>
                            ))
                          }
                        </div> : '-'
                      }
                    </div>
                    <div className='label-wrap'>
                      <span className='label'>时间周期</span>
                      {
                        (_.isArray(formatJsonParse(curRecord.time_schedule)) && formatJsonParse(curRecord.time_schedule).length > 0) ?
                        <div style={{
                          display: 'inline-block'
                        }}>
                          <span 
                            className='label-break-all'
                            title={
                              formatJsonParse(curRecord.time_schedule).map(i => {
                                return `${i.time_cycle}/${i.run_cycle}`
                              }).join('|')
                            }
                          >{ formatJsonParse(curRecord.time_schedule).map(i => {
                            return `${i.time_cycle}/${i.run_cycle}`
                          }).join('|') }</span>
                        </div> : '-'
                      }
                    </div>
                    <div className='label-wrap'>
                      <span className='label'>衍生指标</span>
                      {
                        _.isArray(curRecord.derives) && curRecord.derives.length > 0 ?
                        <div style={{
                          display: 'inline-block'
                        }}>
                          {
                            curRecord.derives.map(i => (
                              <span 
                                className='label-nowrap' 
                                key={i.id}
                                title={`${i.target_name}（${i.id}）`}
                              >{`${i.target_name}（${i.id}）`}</span>
                            ))
                          }
                        </div> : '-'
                      }
                    </div>
                    </>
                  }
                </>
              }
              
            </div>
          }
        </div> :
        <Empty
          height={'100%'}
        />
      }
    </div>
  )
}

const mapStates = module => ({
  topic: module.topic.topic,
  business: module.topic.business
})

export default connect(mapStates, null)(BloodPage)

// 获取宽度
function getWrapWidth(w) {
  return w > 3 ? 240 : 300
}