import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './stylesheets/index.css'
import './stylesheets/style-index.css'
import AppRouter from './AppRouter'
import store from './store'
import reportWebVitals from './reportWebVitals'

// 引入图标库，以后只需要直接更新src下的iconfont文件即可
import './iconfont/iconfont.css'

function render(props = {}) {
  const { container } = props
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </React.StrictMode>,
    container ? document.querySelector(container) : document.getElementById('root'),
  )
}

render()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
