// true则通过 false则错误提示
import _ from "lodash";

export const notEmpty = (val) => {
  return !!val.trim()
}

export const genLesslength = (len) => {
  return (val) => val.trim().length > len
}

export const genGreaterlength = (len) => {
  return (val) => val.trim().length < len
}

export const isInteger = (val) => {
  if(val !== '') val = Number(val)
  return _.isInteger(val)
}