import _ from 'lodash'

export const validateFun = (val, tip, type = '') => {

  if (val === null) {
    return tip
  }

  if (type === 'cascader') {
    return (val.ids && !!val.ids.length) || tip
  }

  // 一般的输入、下拉组件（单选）的校验
  if (typeof val === 'string') {
    return !!val || tip
  }

  // 级联组件、下拉组件（多选）的校验
  if (_.isArray(val)) {
    return !!val.length || tip
  }

  return true
}

// 下发字段数组：paramsList；字段获取值得来源：record
export const getFormDataParams = (record = {}, keyList = []) => {
  let formData = new FormData()

  keyList.forEach(key => {
    if (record.hasOwnProperty(key)) {
      formData.append(`field[${key}]`, record[key])
    }
  })

  return formData
}

// 对接口中search进行json
/**
 * 对搜索中的search进行JSON.stringify
 * @param {Object} params search对象
 * @param {Array} likeArr 模糊匹配的字段
 * @returns JSON.stringify(params)
 */
export const getSearchParams = (params = {}, likeArr = []) => {
  let res = {}

  if (_.isObject(params) && !_.isEmpty(params)) {
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        const el = params[key];
        if (el !== '' && el !== undefined) {
          if (likeArr.includes(key)) {
            res[key] = {
              value: el,
              operator: 'like',
              type: 'both'
            }
          } else {
            res[key] = {
              value: el,
              operator: '='
            }
          }
        }
      }
    }
  }

  return JSON.stringify(res)
}