import {PureComponent} from "react"
import "./authApply.css"
import SdwInput from '@common/Input'
import Radiobox from '@common/Radiobox'
import _ from 'lodash'
import Button from '@common/Button'
import authTechnological from "../img/auth_technological.svg"
import { applyTableAuth } from '@services/metaData'
import { commonApi } from '@utils/request'
import Message from '@common/Message'
import { selectTableList } from '@api/midTable'
import SdwMessage from '@common/Message'

class AuthApply extends PureComponent{
  state = {
    curDbData:{
      applyTimeValue:3,
      applyReason:"",
    },
    applyUser:this.props.loginName,
    sholdCheckValidate:false,
  }

  updateCurDbData = (key, val) => {
    let record = _.cloneDeep(this.state.curDbData)
    record[key] = val
    this.setState({ curDbData: record })
  }
  submitApplyAuth = () => {
    let params = {
      id:this.props.id,
      days:this.state.curDbData.applyTimeValue,
      reason:this.state.curDbData.applyReason,
    }
    if (params.reason == "") {
      this.setState({
        sholdCheckValidate:true
      })
      return
    }
    applyTableAuth(params).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        Message.success('提交申请成功')
        setTimeout(()=>{
          console.log("====睡1秒")
        }, 1000)
        console.log("=====1111")
        this.props.showAuthApply(false)
      } else {
        const msg = _.get(res, 'data.msg') || '接口异常'
        Message.error('提交申请失败 ' + msg)
      }
    }).catch(err => Message.error('提交申请失败 ' + err))
  }
  render() {
    let {sholdCheckValidate} = this.state
    console.log("====11111")
    const applyTime = [{ text: '3天',value: 3 },{text: '7天',value: 7}]
    let { applyUser,curDbData } = this.state
    return (
      <div className={"market-metadata-tabledetails-authApply"}>
        <div className={"title"}>
          权限申请
        </div>
        <div className="body">
          <div className="left">
            <div className={"left_body"}>
              <div className='lable-wrap'>
                <span className="lable-title">申请人</span>
                <SdwInput
                  value={applyUser}
                  disabled={true}
                />
              </div>
              <div className='lable-wrap'>
                <span className="lable-title required">申请时长</span>
                <Radiobox
                  options={applyTime}
                  value={curDbData.applyTimeValue}
                  changeCheckedFun={val => this.updateCurDbData("applyTimeValue", val)}
                />
              </div>
              <div className='lable-wrap'>
                <span className="lable-title required">申请原因</span>
                <SdwInput
                  type="textarea"
                  placeholder='请填写数据使用场景、查询数据时间范围'
                  value={curDbData.applyReason}
                  onChange={val => this.updateCurDbData('applyReason', val)}
                  sholdCheckValidate={sholdCheckValidate}
                  validateFun={value => !!value ? true : '申请原因不能为空'}
                />
              </div>
              <div className='lable-wrap'>
                <span className="lable-title"> </span>
                <Button onClick={this.submitApplyAuth} type={"submit"} text={"提交申请"} />
              </div>
            </div>
          </div>
          <div className={"right"}>
            <div className={"right-body"}>
              <img src={authTechnological} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AuthApply
