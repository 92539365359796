import React, { PureComponent } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import { Button } from 'element-react'
import './index.css'
import _ from 'lodash'
import {
  getTopicBusiness, getTargetPage, getTablecfgList, getPublicCfg, getAllAtomTarget,
  getAllModifier, getAllDimMapping, getTarget, getAllTarget
} from '@services/Derived'
import EditData from './components/EditData'
import SdwSelect from '@common/Select'
import SdwMessage from '@common/Message'
import SdwInput from '@common/Input'
import DialogShowData from './components/DialogShowData'
import DialogShowRunData from './components/DialogShowRunData'
import EditIndicatorsData from "./components/EditIndicatorsData/EditIndicatorsData2";
import SdwButton from '@common/Button'
import { formatJsonParse } from '@utils/formatJson'
import SdwLoading from '@common/Loadding'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

// 仅颗粒指标
const ONLY_GRANULAR = 'only_granular'
const TYPE_MAP = {
  "1":"基础型",
  "2":"复合型",
  "3":"代码型",
}

class Derived extends PureComponent {
  state = {
    dataList: [],
    search: {
      target_name: { value: '', operator: '=' },
      target_name_cn: { value: '', operator: 'like', type: 'both' },
      topic: { value: '', operator: '=' },
      source_targets: { value: '', operator: '=' },
      create_user: { value: '', operator: '=' },
      enable: { value: '', operator: '=' },
      id: { value: '', operator: '=' },
      is_granular: { value: '', operator: '=' },
      granular_category: { value: '', operator: '=' }
    },
    cfgData: {},
    topicData: [],
    pagination: {
      total: 0,
      per_page: 10,
      pages: 0,
      current_page: this.props.pageInfo || 0,
    },
    topicDic: {},
    topicServerDic: {},
    curTitle: '',
    curRecord: {},
    visible: false,
    indicatorsDialogVisible: false,
    indicatorsTitle: '',
    indicatorsRecord: {},

    tableList: [],
    atomTarget: [],
    modifier: [],
    dimension: [],
    dialogVisible: false,
    currShowDataRow: {},
    showCheck: false,
    checkTdIdList: [],
    dialogReRunDataVisible: false,
    tableShowData: false,
    allEnableTargetArr: []
  }

  // 初始化
  async componentDidMount() {

    // 路由跳转携带参数进行过滤
    let query = this.props.location.state || {}
    if (query.topicID) {
      const { search } = this.state
      await this.setState({
        search: Object.assign({}, search, {
          topic: Object.assign({}, search.topic, {
            value: String(query.topicID)
          })
        })
      })
    }

    this.getTopicBusiness()
    this.getAllAtomTarget()
    this.getAllDimMapping()
    this.getAllModifier()
    this.getPageData()
    this.getTablecfgList()
    this.getPublicCfg()
    this.getAllEnableTarget()
  }

  getAllEnableTarget = () => {
    getAllTarget({
      search: JSON.stringify({
        // target_type != 4   and  enable = 1 and  is_granular=1
        target_type: {
          value: 4,
          operator: '!='
        },
        enable: {
          value: 1,
          operator: '='
        },
        is_granular: {
          value: 1,
          operator: '='
        }
      })
    }).then(res => {
      let arr = _.get(res, 'data.data')
      arr = _.isArray(arr) ? arr : []
      arr = arr.reduce((prev, item) => {
        prev.push({
          ...item,
          text: `${item.target_name}(${item.target_name_cn})`
        })
        return prev
      }, [])

      // 新增: 仅颗粒指标
      arr.unshift({
        id: ONLY_GRANULAR,
        text: '仅颗粒指标'
      })
      this.setState({
        allEnableTargetArr: arr
      })
    }, () => SdwMessage.error("接口异常"))
  }

  // 获取所有的原子指标选项
  getAllAtomTarget = () => {
    let that = this
    getAllAtomTarget().then(res => {
      let atomTarget = res.data.data || []
      that.setState({ atomTarget: atomTarget })

      // 详情中需要使用
      sessionStorage.setItem('derivedAtomTarget', JSON.stringify(atomTarget))
    })
  }

  // 获取所有的映射
  getAllDimMapping = () => {
    let that = this
    getAllDimMapping().then(res => {
      let dimension = res.data.data || []
      that.setState({ dimension: dimension })

      // 详情中需要使用
      sessionStorage.setItem('derivedDimension', JSON.stringify(dimension))
    })
  }

  // 获取所有的修饰词
  getAllModifier = () => {
    let that = this
    getAllModifier().then(res => {
      let modifier = res.data.data || []
      that.setState({ modifier: modifier })
    })
  }

  // 获取所有的表
  getTablecfgList = () => {
    let that = this
    getTablecfgList({ limit: 10000, page: 0, search: { enable: { value: '1', operator: "=" } } }).then(res => {
      let tableList = res.data.data.items || []
      that.setState({ tableList: tableList })

      // 详情中需要使用
      sessionStorage.setItem('derivedTableList', JSON.stringify(tableList))
    })
  }

  // 获取所有计算方式选项列表
  getPublicCfg = () => {
    let that = this
    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }
        that.setState({ cfgData: cfgData })

        // 详情中需要使用
        sessionStorage.setItem('derivedCfgData', JSON.stringify(cfgData))
      }
    })
  }

  // 获取分页数据
  getPageData = () => {
    //const search = formatJsonParse(sessionStorage.getItem('derivedSearch'))
    const search = ""
    let cloneSearch
    console.log("=====", search)
    if (search == "") {
      console.log("=====this.state.search==", this.state.search)
      cloneSearch = _.cloneDeep(this.state.search)
    } else {
      cloneSearch = _.cloneDeep(search)
      console.log("=====search==", this.state.search)
    }

    let that = this
    var params = {}
    params.page = this.state.pagination.current_page || 0
    params.limit = this.state.pagination.per_page
    params.search = {}

    const granular_category = _.get(cloneSearch, 'granular_category.value')
    if (!!granular_category && granular_category === ONLY_GRANULAR) {
      delete cloneSearch.granular_category
      cloneSearch.is_granular.value = 1
    }

    for (const key in cloneSearch) {
      const dval = cloneSearch[key]
      if (dval.value !== '') {
        if (key == "source_targets") {
          params.search["target_type"] = {"value":1,"operator":"="}
        }
        params.search[key] = dval
      }
    }
    that.setState({tableShowData: false})
    getTargetPage(params).then((res) => {
      that.setState({tableShowData: true})
      let items = res.data.data.items

      if (!items) {
        items = []
      }

      for (let i = 0; i < items.length; i++) {
        let topicId = items[i].topic
        let topicName = topicId
        let enableName = "无效"
        if (that.state.topicDic.hasOwnProperty(topicId)) {
          topicName = that.state.topicDic[topicId].label
        }
        if (items[i].enable == 1) {
          enableName = "有效"
        }
        items[i]["topicName"] = topicName
        items[i]["enableName"] = enableName

        let timeSchedule = items[i].time_schedule
        if (typeof timeSchedule !== Object) {
          try {
            timeSchedule = JSON.parse(timeSchedule)
          } catch (error) {
            timeSchedule = []
          }
        }
        let strArr = []
        for (let i in timeSchedule) {
          strArr.push(timeSchedule[i].time_cycle + "/" + timeSchedule[i].run_cycle)
        }
        items[i]["time_schedule_name"] = strArr.join('/')

      }

      let pagination = that.state.pagination
      pagination.total = res.data.data.total
      pagination.per_page = res.data.data.per_page
      pagination.pages = res.data.data.pages
      pagination.current_page = res.data.data.current_page
      that.setState({
        dataList: [...items],
        pagination: { ...pagination },
      })
    })
  }
  // 获取主题列表
  getTopicBusiness = () => {
    let that = this
    getTopicBusiness().then((res) => {
      that.setState({ topicData: res.data.data })

      // 详情中需要使用
      sessionStorage.setItem('derivedTopicData', JSON.stringify(res.data.data))

      let topicDic = {}
      for (let i = 0; i < res.data.data.length; i++) {
        let topicId = res.data.data[i].value
        if (!topicDic.hasOwnProperty(topicId)) {
          topicDic[topicId] = res.data.data[i]
        }
      }

      that.setState({ topicDic: topicDic })
    })
  }

  // 获取表标题
  getcolumns = () => {
    let self = this
    const columns = [
      {
        title: "checkTd",
        dataIndex: "id",
        width: 20,
        checkTd: 1
      },
      {
        title: "ID",
        width: 50,
        dataIndex: "id",
        checkChildren: true
      },
      {
        title: "中文名称",
        dataIndex: "target_name_cn",
        showTip: true,
        width: 150
      },
      {
        title: "英文名称",
        dataIndex: "target_name",
        showTip: true,
        width: 230
      },
      {
        title: "主题域/业务",
        width: 94,
        render: (data, record) => {
          let { topicDic } = self.state
          let { topic, service } = data
          let topicRecord = topicDic[topic]
          let topicName = '-'
          let children = []
          let serviceName = '-'

          if (!!topicRecord) {
            topicName = topicDic[topic].label
            children = topicDic[topic].children || []
            serviceName = children.filter(i => i.value == service)[0] && children.filter(i => i.value == service)[0].label || ''
          }
          return (
            <span>{`${topicName}/${serviceName}`}</span>
          )
        }
      },
      {
        title: "维度",
        width: 180,
        render: (data, record) => {
          let storeDimension = formatJsonParse(data.store_dimension, [])
          let dimensionName = ''

          // 衍生指标拼接规则
          storeDimension = _.isArray(storeDimension) ? storeDimension.filter(i => !!i.id && _.isArray(i.id)) : []
          if (data.target_type == 4) {
            dimensionName = storeDimension.map(i => i.id.join('/')) || '-'
          } else {
            dimensionName = storeDimension.map(i => i.id.join('#')).join('/') || '-'
          }
          return (
            <span>{dimensionName}</span>
          )
        }
      },
      {
        title: "时间周期",
        dataIndex: "time_schedule_name",
        width: 56
      },
      {
        title: "类型",
        width: 56,
        render: (data, record) => {
          let type = ""
          if (data.target_type == 4) {
            switch (data.metric_type) {
              case "rank":
                type = "衍生指标-排名型"
                break
              case "rank_rate":
                type = "衍生指标-击败率型"
                break
              case "float_cnt":
                type = "衍生指标-变化量型"
                break
              case "float_rate":
                type = "衍生指标-变化率型"
                break
              case "daily_avg":
                type = "衍生指标-均值型"
                break
              case "filter":
                type = "衍生指标-过滤型"
                break
              case "range":
                type = "衍生指标-区间型"
                break
            }
          } else {
            type = TYPE_MAP[data.target_type]
          }

          return (
            <span>{type}</span>
          )
        }
      },
      {
        title: "修饰词",
        dataIndex: "decoration",
        width: 188
      },
      {
        //title: "状态",
        //dataIndex: "enableName",
        title: "状态",
        width: 40,
        render: function (data, record) {
          return (
            <>
              {
                _.isEqual(typeof data.enable, 'number') && <span className={
                  data.enable ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                }></span>
              }
              <span>{_.isEqual(typeof data.enable, 'number') ?
                (data.enable ? '有效' : '无效') : '-'}</span>
            </>
          )
        }
      },
      {
        title: "修改人",
        dataIndex: "modify_user",
        showTip: true,
        width: 76
      },
      {
        title: "更新时间",
        dataIndex: "modify_time",
        width: 143
      },
      {
        title: "操作",
        width: 280,
        fiexd: true,
        render: (data, record) => {
          let isShowButton = false
          if (data.target_type == 4 &&  (data.metric_type=="rank"||data.metric_type=="rank_rate"||data.metric_type=="float_cnt"||data.metric_type=="float_rate"||data.metric_type=="daily_avg"||data.metric_type=="filter"||data.metric_type=="range")) {
            isShowButton = true
          }
          return (
            <div className="editorMenu-Derived">
              {/* 编辑权限放开，都可以点击编辑进入弹窗查看数据 */}
              <SdwButton
                text="编辑"
                disabled={isShowButton}
                onClick={() => { self.onClickGetOneData(data, self) }}
              />
              {
                hasPriv('getDataKinship') &&
                <SdwButton
                  text="详情"
                  disabled={isShowButton}
                  onClick={() => { self.jumpToDetail(data) }}
                />
              }
              <SdwButton
                text="预览数据"
                onClick={() => { self.onClickShowData(data, self) }}
              />
              {
                hasPriv('storeOrUpdateDerive') &&
                <SdwButton
                  text="衍生指标"
                  disabled={isShowButton}
                  onClick={() => { self.showDerivedIndicators({ title: `编辑衍生指标（${data.id}）`, curRecord: data }) }}
                />
              }
            </div>
          )
        },
      }
    ]
    return columns
  }

  jumpToDetail = data => {
    // 详情中需要使用
    //sessionStorage.setItem('derivedSearch', JSON.stringify(this.state.search))
    this.props.history.push(`/admin/factory/derived-detail/${data.id}`)
  }

  // 展示预览数据
  onClickShowData = (data, e) => {
    this.setState({ dialogVisible: true, currShowDataRow: data })
  }

  // 编辑衍生指标
  showDerivedIndicators = (record) => {
    this.setState({
      indicatorsDialogVisible: true,
      indicatorsTitle: record.title,
      indicatorsRecord: record,
    })
  }

  // 获取编辑的行记录
  onClickGetOneData = (data, e) => {
    getTarget({ id: data.id }).then(res => {
      if (res.data.code === 0) {
        let item = res.data.data
        for (let i in item) {
          let val = item[i]

          if (i === "store_dimension") {
            item['store_dimension'] = JSON.parse(val)
            continue
          }

          if (i === "time_schedule") {
            item['time_schedule'] = JSON.parse(val)
            continue
          }

          if (i === "target_type") {
            item["target_type"] = item["target_type"] + ''
            continue
          }

          if (i === "decoration_ids") {
            let decoration_ids = []
            decoration_ids = val ? val.split(",") : []
            item["decoration_ids"] = decoration_ids
            continue
          }

          item[i] = val
        }

        let topicName = ""
        let topicServiceName = ""

        if (this.state.topicDic.hasOwnProperty(item.topic)) {
          topicName = this.state.topicDic[item.topic].label
          if (this.state.topicDic[item.topic].hasOwnProperty("children")) {
            for (let i = 0; i < this.state.topicDic[item.topic].children.length; i++) {
              let sid = this.state.topicDic[item.topic].children[i].value
              let label = this.state.topicDic[item.topic].children[i].label

              if (sid == item.service) {
                topicServiceName = label
              }
            }
          }
        }
        item.topicBusiness = {
          "ids": [item.topic, item.service],
          "texts": [topicName, topicServiceName],
          "clickItem": [item.topic + '' + item.service, topicServiceName],
        }

        this.setState({
          curRecord: item,
          curTitle: '编辑派生指标'
        }, () => this.setState({
          visible: true
        }))
      }
    })
  }

  // 获取当前页码
  getClickPage = (pageNum) => {
    this.state.pagination.current_page = pageNum
    this.getPageData()
  }

  // 获取当前每页总条数
  pageCountChange = (per_page) => {
    this.state.pagination.per_page = per_page
    this.getPageData()
  }

  // 表格数据双向绑定
  SeachHandleChange(key, value) {
    this.state.pagination.current_page = 1

    if (key == "target_name") {
      this.state.search.target_name.value = value;
    }

    if (key == "id") {
      this.state.search.id.value = value;
    }

    if (key == "topic") {
      this.state.search.topic.value = value;
      this.getPageData()
    }

    if (key == "create_user") {
      this.state.search.create_user.value = value;
    }

    this.forceUpdate();
  }

  addOrEdit = (record = {}) => {
    this.setState({
      visible: true,
      curTitle: record.title,
      curRecord: record,
    })
  }

  onSureClick = () => {
    this.setState({
      visible: false,
    })
    this.getPageData()
    this.getAllEnableTarget()
  }

  onSureClickOfIndicators = () => {
    this.setState({
      indicatorsDialogVisible: false,
    })
    this.getPageData()
  }

  closeDialog = () => {
    this.setState({ dialogVisible: false })
  }

  closeRunDataDialog = () => {
    this.setState({
      dialogReRunDataVisible: false,
    })
  }

  // 获取选中的单元格id列表
  checkboxChange = (data) => {
    this.setState({ checkTdIdList: data })
  }

  // 选中的数据重跑
  reRunData = () => {
    this.setState({ dialogReRunDataVisible: true })
  }

  render() {
    var {
      search, pagination, curTitle, visible, curRecord, topicData, tableList, cfgData, atomTarget, dimension, currShowDataRow, dialogVisible, showCheck, checkTdIdList,
      dialogReRunDataVisible, tableShowData, enableValue,indicatorsTitle,indicatorsDialogVisible,indicatorsRecord, allEnableTargetArr
    } = this.state;
    console.log("--------checkTdIdList", checkTdIdList)
    console.log("--------dataList", this.state.dataList)
    return (
      <div className="machFactory Derived">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/atom'
            }, {
              title: '派生指标'
            }
          ]}
        />

        <div className="page-mainbody">
          {/** 搜索区域 */}
          <div className="page-search">
            <div className="input-wrap">

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwInput
                    width={180}
                    label="指标ID"
                    placeholder="使用;号分隔"
                    value={search.id.value}
                    onBlur={this.getPageData}
                    onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                    onChange={val => this.setState({
                      pagination:Object.assign({}, pagination, {
                        current_page:1
                      }),
                      search: Object.assign({}, search, {
                        id: Object.assign({}, search.id, {
                          value: val
                        })
                      })
                    })}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwInput
                  width={180}
                  label="指标中文名"
                  placeholder="请输入关键字"
                  value={search.target_name_cn.value}
                  onBlur={this.getPageData}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      target_name_cn: Object.assign({}, search.target_name_cn, {
                        value: val
                      })
                    })
                  })}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwInput
                  width={180}
                  label="指标英文名"
                  value={search.target_name.value}
                  onBlur={this.getPageData}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      target_name: Object.assign({}, search.target_name, {
                        value: val
                      })
                    })
                  })}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwSelect
                  placeholder="全部"
                  width={180}
                  label="主题域"
                  readOnly={false}
                  value={search.topic.value}
                  data={this.state.topicData.map(el => {
                    return {
                      id: el.value,
                      text: el.label
                    }
                  })}
                  onChange={val => {
                    this.setState({
                      pagination:Object.assign({}, pagination, {
                        current_page:1
                      }),
                      search: Object.assign({}, search, {
                        topic: Object.assign({}, search.topic, {
                          value: val
                        })
                      })
                    }, this.getPageData)
                  }}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwInput
                  width={180}
                  label="创建人"
                  placeholder="请输入RTX名字"
                  value={search.create_user.value}
                  onBlur={this.getPageData}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      create_user: Object.assign({}, search.create_user, {
                        value: val
                      })
                    })
                  })}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwSelect
                  value={search.enable.value}
                  width={160}
                  label="是否有效"
                  data={[
                    {
                      id: 1,
                      text: '有效'
                    }, {
                      id: 0,
                      text: '无效'
                    }
                  ]}
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      enable: Object.assign({}, search.create_user, {
                        value: val
                      })
                    })
                  }, this.getPageData)}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwSelect
                  value={search.granular_category.value}
                  width={200}
                  listWidth={330}
                  label="颗粒指标"
                  data={allEnableTargetArr}
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      granular_category: Object.assign({}, search.granular_category, {
                        value: val
                      })
                    })
                  }, this.getPageData)}
                />
              </span>
              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwSelect
                  placeholder="全部"
                  width={200}
                  listWidth={330}
                  label="原子指标"
                  readOnly={false}
                  value={search.source_targets.value}
                  data={this.state.atomTarget.map(el => {
                    return {
                      id: el.id,
                      text: el.target_name+"("+el.target_name_ch+")"
                    }
                  })}
                  onChange={val => {
                    this.setState({
                      pagination:Object.assign({}, pagination, {
                        current_page:1
                      }),
                      search: Object.assign({}, search, {
                        source_targets: Object.assign({}, search.source_targets, {
                          value: val
                        })
                      })
                    }, this.getPageData)
                  }}
                />
              </span>
              <div className="btn-group">
                <span className="batch sdw-derived__more-button" style={{'position': 'relative', 'top': '3px'}}>
                  <Button onClick={() => this.setState({ showCheck: true })}><i className="el-icon-document"></i> 批量操作</Button>
                </span>

                <Button
                  type="primary"
                  icon='plus'
                  onClick={() => this.addOrEdit({ title: '新增派生指标', curRecord: {} })}
                >新增</Button>

              </div>
            </div>
          </div>


          {/** 分页显示数据区域 */}
          <div className="tableShowData">
            <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
              <SdwTable
                tdWhiteSpace={true}
                childrenString='derivativeTargets'
                columns={this.getcolumns()}
                dataSource={this.state.dataList}
                showCheck={showCheck}
                checkBoxIdList={checkTdIdList}
                checkboxChange={this.checkboxChange}
              />
            </SdwLoading>

            <SdwPagenation
              total={pagination.total}
              currentPage={pagination.current_page}
              pageCount={pagination.per_page}
              getClickPage={this.getClickPage}
              pageCountChange={this.pageCountChange}
            />

            {/** 重跑逻辑 */}
            {showCheck && (
              <div className="reRunData">
                <span className="reRun el-button-span" style={{
                  marginRight: 16
                }}>
                  <SdwButton
                    text="重跑"
                    type="submit"
                    onClick={() => this.reRunData()} disabled={checkTdIdList.length == 0 ? true : false}
                  />
                </span>

                <span className="batch" style={{
                  marginRight: 16
                }}>
                  <SdwButton
                    text="取消"
                    type="cancel"
                    onClick={() => this.setState({ showCheck: false, checkTdIdList: [] })}
                  />
                </span>

                <span className="showDataCheckCount">已选择 {checkTdIdList.length} 项</span>
              </div>
            )}
          </div>
        </div>

        {/** 编辑弹窗 */}
        {visible && <EditData
          title={curTitle}
          visible={visible}
          record={curRecord}
          topicData={topicData}
          tableList={tableList}
          cfgData={cfgData}
          atomTarget={atomTarget}
          dimension={dimension}
          onIconClick={() => this.setState({ visible: false })}
          onCancelClick={() => this.setState({ visible: false })}
          onSureClick={() => {
            this.setState({ visible: false })
            this.onSureClick()
          }}
        />}

        {/** 编辑衍生指标弹窗 */}
        {indicatorsDialogVisible && <EditIndicatorsData
          title={indicatorsTitle}
          visible={indicatorsDialogVisible}
          record={indicatorsRecord}
          cfgData={cfgData}
          onIconClick={() => this.setState({ indicatorsDialogVisible: false })}
          onCancelClick={() => this.setState({ indicatorsDialogVisible: false })}
          onSureClick={() => {
            this.onSureClickOfIndicators()
          }}
        />}

        {/** 预览数据弹窗 */}
        {
          dialogVisible &&
          <DialogShowData
            dialogVisible={dialogVisible}
            record={currShowDataRow}
            closeDialog={this.closeDialog}
          />
        }

        {/** 重跑数据弹窗 */}
        {
          dialogReRunDataVisible &&
          <DialogShowRunData
            dialogVisible={dialogReRunDataVisible}
            data={checkTdIdList}
            closeDialog={this.closeRunDataDialog}
          />
        }
      </div>
    )
  }
}

const mapState = state => ({
  pageInfo: state.page.pageInfo
})

export default withRouter(connect(mapState)(Derived))
