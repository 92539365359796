import PropTypes from 'prop-types'
import { isEmpty, keys, isArray } from 'lodash'
import Collapse from "./Collapse";
import './index.css'

function CollapseWithTime(props) {
  const { 
    search = {},
    info = {},
    config = {
      keys: ['traceTime', 'type', 'archivepath_name', 'desc'], // 显示字段，从左到右
      widths: [180, 200], // 显示字段对应的width
      contextKey: 'info', // context 对应字段
      contextKeys: ['time', 'content'], // 每一行展示字段
      contextwidths: [180], // 每一行展示字段对应width
    }
  } = props
  

  return (
    !isEmpty(info) &&
    keys(info).map((key, index) => (
      <div key={index} className='components-collapse-with-time-wrap'>
        <div className='left-wrap'>{ key }</div>
        <div className='right-wrap'>
          {
            isArray(info[key]) &&
            info[key].map((keyInfo, keyIndex) => (
              <Collapse
                key={keyIndex}
                search={search}
                list={[keyInfo]}
                config={config}
              />
            ))
          }
        </div>
      </div>
    ))
  )
}

CollapseWithTime.propTypes = {
  info: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
}

export default CollapseWithTime