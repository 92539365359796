import React from 'react'
import SdwEmpty from '@common/Empty'

class Blood extends React.PureComponent {

  render () {

    return (
      <>
        <SdwEmpty text='规划中...'/>
      </>
    )
  }
}

export default Blood