import { request } from '../utils/request'

export function getAllTopics (params) {
  return request({ url: '/statistics/dataMarket/getAllTopics', method: 'get', params: params })
}

export function getBusinessStatisticByTopic (params) {
  return request({ url: '/statistics/dataMarket/getBusinessStatisticByTopic', method: 'get', params: params })
}

export function getTopicInfoById (params) {
  return request({ url: '/statistics/dataMarket/getTopicInfoById', method: 'get', params: params })
}

export function searchFields (params) {
  return request({ url: '/statistics/dataMarket/searchFields', method: 'get', params: params })
}

export function storeNewBusiness (params) {
  return request({ url: '/statistics/dataMarket/storeNewBusiness', method: 'post', data: params })
}
