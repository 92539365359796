import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Mapping } from '../util'
import SdwSelect from '@common/Select'
import SdwCheckbox from '@common/Checkbox'
import '../style/EditIndicatorsData.css'

class FloatIndicators extends PureComponent {

  validateFun = val => {
    // 先判断非禁用项，如果有一个勾选后，需要校验变化维度；否则不需要校验变化维度
    let { item, timeSchedule } = this.props
    let cal_expression_list = item.cal_expression_list || []
    let notValidate = cal_expression_list.filter(i => timeSchedule.some(j => j.time_cycle === i.time_cycle)).every(k => !k.enable)
    let isPass = notValidate || !!val.length

    return isPass || '变化维度不能为空'
  }

  render() {
    const {
      item,
      index,
      checkedOption,
      rankDimensionData,
      timeSchedule,
      sholdCheckValidate,
      setCheckedValue,
      setChangeDimensions,
      showOrhideContent,
      dimensionTitle,
      typeTitle
    } = this.props

    console.log("=====------------------------------------------------metric_type", item.metric_type)
    console.log("=====checkedOption", checkedOption)
    console.log("=====timeSchedule", timeSchedule)

    let {store_dimension, cal_expression_list} = item
    let  changeDimension = typeof store_dimension === 'string' ? [] : _.map(store_dimension, item => {
      return item.id[0] || ''
    }) || []
    cal_expression_list = _.isArray(cal_expression_list) ? cal_expression_list : []
    let ids = cal_expression_list.map(i => i.enable && i.id).filter(j => !!j).join()
    let hasEnabled = cal_expression_list.some(i => i.enable)

    return (
      <div className="sdw-collapse-listitem">
        <div className="sdw-collapse-listitem-title" onClick={() => showOrhideContent(item, index, 'formData')}>
          <div className='sdw-collapse-listitem-title-text' style={{'color': hasEnabled ? '#265CF0' : '' }}>衍生指标-{Mapping[item.metric_type]}{item.complex_script === 'asc' ? '(升序)' : item.complex_script === 'desc' ? '(降序)' : ''}{!!ids ? `（${ids}）`: ''}</div>
          <div><i className={`sdw-collapse__icon sdw-collapse__drop-down ${item.isShow ? 'sdw-collapse__drop-up' : ''}`}></i></div>
        </div>
        <div className="sdw-collapse-listitem-content" style={{ 'display': item.isShow ? 'block' : 'none' }}>
          <div className="form data-editor-win-wrap">
            <div className="formInput">
              <span className="label">{dimensionTitle}</span>
              <div className="content rank-dimension-warp">
                <SdwSelect
                  clearable={false}
                  isMultipleChoice={true}
                  placeholder={'请选择'}
                  value={rankDimensionData.filter(i => changeDimension.includes(i.id))}
                  data={rankDimensionData}
                  onChange={val => setChangeDimensions(index, val)}
                  sholdCheckValidate={sholdCheckValidate}
                  validateFun={this.validateFun}
                />
              </div>
            </div>
            <div className="formInput">
              <span className="label">{typeTitle}</span>
              <div className="content checkbox-warp-style" style={{ marginLeft: 99 }}>
                {
                  checkedOption.map((checkItem, checkItemIndex) => {
                    let isChecked = cal_expression_list.some(i => i.float_type === checkItem.key && i.time_cycle === checkItem.timeCycle && i.enable)
                    return (
                      <SdwCheckbox
                        disabled={!timeSchedule.some(i => i.time_cycle === checkItem.timeCycle)}
                        key={checkItem.key + checkItem.timeCycle}
                        text={checkItem.text}
                        isChecked={ isChecked }
                        changeCheckedFun={() => setCheckedValue(index, checkItem, +!isChecked)}
                      />
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FloatIndicators
