import { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import SdwTextarea from '@common/Textarea'
import SdwButton from '@common/Button'
import SdwMessage from '@common/Message/index'
import { checkLogCollectCfg } from '@services/dataAccess'

function DataAccessTest(props) {

  const [formRecord, setFormRecord] = useState({})
  const [showResult, setShowResult] = useState(false)
  const [isPass, setIsPass] = useState(false)

  function updateFormRecord(key, val) {
    let cloneFormData = _.cloneDeep(formRecord)
    cloneFormData[key] = val
    setFormRecord(cloneFormData)
  }

  function handlePrev() {
    props.updateCurrentPage(props.pageMap.main)
  }

  function handleTest() {
    const { formData, tableData } = props
    if (_.isEmpty(formData) || _.isEmpty(tableData)) {
      SdwMessage.info('缺少参数！')
      return
    }
    checkLogCollectCfg({
      id: formData.id,
      msg: formRecord.logText
    }).then(res => {
      const code = _.get(res, 'data.code')
      const msg = _.get(res, 'data.msg')
      setShowResult(true)
      if (code === 0) {
        SdwMessage.success(msg || '测试配置成功！')
        setIsPass(true)
      } else {
        setIsPass(false)
        SdwMessage.error(msg || '测试配置失败！')
      }
    }).catch(err => SdwMessage.error('接口异常' + err))
  }

  // 跳转日志页面
  function jumpBtn() {
    props.history.push('/admin/dataCollect/dataManagement?tab=log')
  }

  return (
    <>
      <div style={{ height: 600 }}>
        <div className="data-access__body">
          <span className="number-title-wrap">
            <span className="number-title__three"></span>
            <span className="config-title">接入测试</span>
          </span>
          <div className="main-body">

            <div className="label-title-wrap">
              <i className="label-title-divider" />
              <span className="label-title">日志接入测试</span>
              {/* <span className="label-title-des">请保障信息准确，避免同步出错</span> */}
            </div>

            <div className="label-title-wrap">
              <span className="label required">测试内容示例：</span>
              <SdwTextarea
                width={440}
                placeholder="请输入测试内容"
                value={formRecord.logText}
                onChange={val => updateFormRecord('logText', val)}
              />
            </div>

            <div className="label-title-wrap" style={{ display: 'block' }}>
              <div>
                <SdwButton
                  text='测试'
                  type='cancel'
                  disabled={!formRecord.logText}
                  onClick={handleTest}
                />
                <div>
                  {
                    showResult &&
                    <>
                      <span style={{ textAlign: 'left', marginRight: 8 }}>测试结果：</span>
                      <span style={{color: isPass ? 'green' : 'red'}}>{ isPass ? '测试通过' : '解析失败' }</span>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="data-access__footer">
        <SdwButton
          type="cancel"
          text="上一步"
          onClick={handlePrev}
        />
        <SdwButton
          type="submit"
          text="完成"
          onClick={jumpBtn}
        />
      </div>
    </>
  )
}

const mapState = state => ({
  pageMap: state.dataAccess.pageMap,
  formData: state.dataAccess.formData,
  tableData: state.dataAccess.tableData,
})

const mapDispatch = dispatch => ({
  updateFormData: dispatch.dataAccess.updateFormData,
  updateCurrentPage: dispatch.dataAccess.updateCurrentPage,
})

export default connect(mapState, mapDispatch)(DataAccessTest)
