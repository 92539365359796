import _ from 'lodash'
import Dictionary from '@pages/Market/Dictionary'
import DataStandard from '@pages/Market/DataStandard'
import DomainManagement from '@pages/Market/DomainManagement'
import SubjectDomainDetail from '@pages/Market/SubjectDomainDetail'
import DataStandardDetail from '@pages/Market/DataStandardDetail'
import Metadata from '@pages/Market/Metadata/index'
import BaseInfo from '@pages/Market/Metadata/TableDetails/index'
import switchRoutes from './switchRoutes'

export const routerPath = '/admin/dataMarket'

export const bar = {
  rootUrl: routerPath,
  activeClassName: 'current',
  className: 'sdw-nav-item',
  name: '数据集市',
  children: [
    {
      icon: '',
      title: '数据字典',
      path: `${routerPath}/dictionary`,
    },
    {
      icon: '',
      title: '数据标准',
      path: `${routerPath}/standard`,
    },
    {
      icon: '',
      title: '主题域管理',
      path: `${routerPath}/management`,
    },
    // {
    //   icon: '',
    //   title: '元数据管理(旧)',
    //   path: `/html/metadata/tableList`,
    //   gotoPathState: 1,
    // },
    {
      icon: '',
      title: '元数据管理',
      path: `${routerPath}/metadatas`,
    }
  ],
  order: 0
}

const pages = {
  Dictionary: {
    icon: 'icon-278',
    title: '数据字典',
    path: `${routerPath}/dictionary`,
    component: Dictionary,
  },
  DataStandard: {
    icon: 'icon-278',
    title: '数据标准',
    path: `${routerPath}/standard`,
    component: DataStandard,
  },
  DomainManagement: {
    icon: 'icon-278',
    title: '主题域管理',
    path: `${routerPath}/management`,
    component: DomainManagement,
  },
  SubjectDomainDetail: {
    icon: 'icon-278',
    title: '主题域管理详情',
    path: `${routerPath}/domaindetail`,
    component: SubjectDomainDetail,
  },
  DataStandardDetail: {
    icon: 'icon-278',
    title: '标准详情',
    path: `${routerPath}/standardinfo`,
    component: DataStandardDetail,
  },
  Metadata: {
    icon: 'icon-278',
    title: '元数据管理',
    path: `${routerPath}/metadatas`,
    component: Metadata,
  },
  BaseInfo: {
    icon: 'icon-278',
    title: '元数据管理',
    path: `${routerPath}/baseInfo/:id`,
    component: BaseInfo,
  }
}

export const menus = [
  // {
  //   icon: 'icon-64',
  //   title: '知识菜单',
  //   path: `${routerPath}/monitor`,
  //   children: [pages.knowledgeLearning, pages.learningGroup],
  // },
  // {
  //   icon: 'icon-132',
  //   title: '考试菜单',
  //   path: `${routerPath}/dialogue`,
  //   children: [pages.orderShare, pages.testList],
  // },
]

export const Router = switchRoutes({ routes: _.values(pages) })
