import React, { PureComponent } from 'react'
import logoImg from '../img/logo.svg'
import { NavLink, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { hasPriv } from '@components/FrontendAuth/AuthMap'
import AuditInfo from './AuditInfo'

import '../style/navBar.css'

class UILayoutNavbar extends PureComponent {

  state = {
    showMenuList: false
  }

  componentDidMount() {
  }

  gotoPath = (path) => {
      window.open(path, "_new"); 
  }

  render() {

    let { showMenuList } = this.state

    const bars = this.props.bars
    const match = this.props.location.pathname

    // console.info(match, match.path)
    // console.log(bars)
    return (
      <div className="page-header">
        <div className="page-header__wrap">
          <div className="m-head">
            <div className="m-head__logo" onClick={() => { window.location.href = '/admin/dataMarket/dictionary' }}><img className="pc-image" src={logoImg} /></div>
            <ul className="m-head__menu">
              {
                bars.sort((a, b) => a.order - b.order).map((bar, index) => {

                  // bar.children.some(child => hasPriv(child.path))：意思是：它下面的所有子节点，至少有一个拥有查看的权限，否则禁用掉
                  let childHasPriv = bar.children.some(child => hasPriv(child.path))
                  return (
                    <li 
                      className={
                        "menu__item" + 
                        (this.props.location.pathname.startsWith(bar.rootUrl) ? ' current' : '') + 
                        (bar.children && bar.children.length > 0 && childHasPriv ? '' : ' disabled')
                      } 
                      key={index}
                      onMouseEnter={() => {
                        if (!childHasPriv) return
                        this.setState({ showMenuList: true })
                      }}
                      onMouseLeave={() => this.setState({ showMenuList: false })}
                    >
                      {
                        bar.children && bar.children.length > 0 && childHasPriv ?
                        <Link onClick={() => this.setState({ showMenuList: false })} to={bar.rootUrl} className="menu__txt" style={{ display: 'inline-block' }}>{bar.name}<i className={(bar.children.length > 0 ? 'show-down-icon' : '')}></i></Link> : 
                        <span className="header-nav__disabled-button" style={{ display: true }}>{bar.name}</span>
                      }
                      {
                        bar.children && bar.children.length > 0 &&
                        showMenuList &&
                        <div className="m-select" key={index}>
                          <ul className="m-select-menu">
                            {
                              bar.children.map((child, i) => {
                                let gotoPathState = child.gotoPathState ? child.gotoPathState : 0

                                // 权限校验：导航显示与否
                                let curPathHasPriv = hasPriv(child.path)
                                if (!curPathHasPriv) return null

                                if(gotoPathState === 0) {
                                  return (
                                    <Link onClick={() => {
                                      this.props.updatePageInfo("")
                                      this.props.updateTabsInfo("")
                                      this.setState({showMenuList: false})
                                    }} to={child.path} className={"m-select-menu__item" + (this.props.location.pathname === child.path ? ' current' : '')} key={i} style={{ display: 'inline-block' }}>
                                      {child.title}
                                    </Link>
                                  )
                                }
                                if(gotoPathState === 1) {
                                  return (
                                    <Link to='' onClick={() => {this.gotoPath(child.path)}} className={"m-select-menu__item" + (this.props.location.pathname === child.path ? ' current' : '')} key={i} style={{ display: 'inline-block' }}>
                                      {child.title}
                                    </Link>
                                  )
                                }                           
                              })
                            }
                          </ul>
                        </div>
                      }
                    </li>
                  )
                })
              }
            </ul>
            <div className="m-head__userInfo">
              <AuditInfo {...this.props}/>
              <div className="user-logo">
                <img style={{ width: '100%', borderRadius: '50%' }} src={this.props.userInfo.avatar || ''} />
              </div>
              <p className="user-name">{this.props.userInfo.FullName || ''}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = state => ({
  userInfo: state.user.userInfo,
  pageInfo: state.page.pageInfo,
  tabsInfo: state.page.tabsInfo
})

const mapDispatch = dispatch => ({
  updateUserInfo: dispatch.user.updateUserInfo,
  updatePageInfo: dispatch.page.updatePageInfo,
  updateTabsInfo: dispatch.page.updateTabsInfo
})

export default withRouter(connect(mapState, mapDispatch)(UILayoutNavbar))
