// @file 数据查询接口
import { request } from '../utils/request'

export const getTablecfgList = params => {
  return request({ url: '/metadata/tableApi/getTablecfgList', method: 'get', params: params })
}

// 获取 元数据 表信息 下拉选项
export function getAllTablecfg (params) {
  return request({ url: '/metadata/tableApi/getAllTablecfg', method: 'get', params: params })
}

export function getMysqlTables (params) {
  return request({ url: '/metadata/tableApi/getMysqlTables', method: 'get', params: params })
}

export function getEsIndex (params) {
  return request({ url: '/metadata/tableApi/getEsIndex', method: 'get', params: params })
}

export function getTables (params) {
  return request({ url: '/metadata/tableApi/getTableName', method: 'get', params: params })
}

export function getItems (params) {
  return request({ url: '/metadata/tableApi/getItems', method: 'get', params: params })
}

export function getItemsNew (params, headers = {}) {
  return request({ url: '/metadata/tableApi/getItemsNew', method: 'get', params: params , headers:headers})
}

export const getTablecfgFieldsNew = (params = {}) => {
  return request({ url: 'metadata/tableApi/getTablecfgFieldsNew', method: 'get', params: params })
}

export const getTablecfgFieldsAndStandardList = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgFieldsAndStandardList', method: 'get', params: params })
}

export const exportItemsData = (params = {}, headers = {}) => {
  return request({ url: '/metadata/tableApi/exportItemsData', method: 'get', params: params , headers:headers})
}

export const exportTaskItems = (params = {}) => {
  return request({ url: '/metadata/tableApi/exportTaskItems', method: 'get', params: params })
}
