import produce from 'immer'

const state = {
  userInfo: {}, // initial state
  staffUserList: [], // 部门所有的人员名单
}

const updateUserInfo = produce((state, payload) => {
  state.userInfo = payload
})

const updateStaffUserList = produce((state, payload) => {
  state.staffUserList = payload
})

const reducers = {
  updateUserInfo,
  updateStaffUserList
}

export default { state, reducers }
