import React, {Fragment} from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import {Checkbox, Button} from 'element-react'
import {connect} from 'react-redux'
import SdwMulRadiobox from '@common/MulRadiobox'
import {getNodeRelateFields, fieldItems, saveFields} from '@api/midTable'
import SdwMessage from '@common/Message'
import './css/index.css'

class AddField extends React.Component {

    constructor(props) {
        super(props)
        this.popoverRef = React.createRef()
    }

    state = {
        //节点处
        fieldParams: {
            id: "",  // 字段id，新增不传，编辑传
            config: { // 字段加工配置，由字段配置接口获取，当mappint_type=single时，固定传copy加工方式
                script: "copy",
                value: {}
            },
            field: "",  // 结果字段
            src_reader_id: "",
            field_type: 0,   // 字段类型
            filter: "",   // 过滤条件
            mapping_type: "single",   // single：表原生字段，combine：加工字段
            enable: 1
        },
        fieldList: [], //字段名称列表 //针对原生字段
        sholdCheckValidate: false,
        fieldItems: [], //加工方式列表
        fieldConfig: [{
            "field": "field",
            "is_column": 1,
            "must": 1,
            "name": "字段",
            "type": "select"
        }], // 选择加工方式后 此变量存里面的conf配置用以在页面上渲染是select还是input组件
        fieldTypeMapOption: [],
        dataInfoList: [{field_value: '', field_type: 0, field: ''}], // 数据信息列表
        showPopover: false, // 是否显示批量增加浮窗
        isUp: false, // 浮窗是否向上展示
        checkList: [], // 已选择的字段列表
        checkAll: false, // 是否全选
        isIndeterminate: true, // 全选图标
    }

    componentDidMount() {
        this.iniData()
        this.iniFieldTypeMap()
        this.iniDataSourceFields()
        this.getFieldItems()
        window.addEventListener('mousedown', this.handleClosePopover)
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClosePopover)
    }

    // 点击空白处关闭浮窗
    handleClosePopover = (event) => {
        if (this.popoverRef.current && !this.popoverRef.current.contains(event.target)) {
            this.setState({showPopover: false})
        }
    }

    iniFieldTypeMap = () => {
        let cfgData = this.props.cfgData
        if (JSON.stringify(cfgData) != "{}") {
            let field_type_map = cfgData.field_type.map(i => {
                return {id: parseInt(i.value), text: i.code, item: i}
            })
            this.setState({
                fieldTypeMapOption: field_type_map
            })
        }
    }

    //获取计算字段的配置
    getFieldItems = () => {
        let {isEdit, fieldParams} = this.props
        fieldItems().then(res => {
            if (res && res.data && res.data.code === 0) {
                let fieldItemsList = res.data.data || {}
                let fieldItems = []
                for (let k in fieldItemsList) {
                    fieldItems.push({id: k, text: fieldItemsList[k].name, item: fieldItemsList[k]})
                }
                this.setState({
                    fieldItems: fieldItems
                })
                if (isEdit) { //如果是编辑则填充
                    let fieldParamsClone = _.cloneDeep(fieldParams)
                    let item = fieldItems.filter(i => i.id == fieldParamsClone.config.script)
                    this.setState({
                        fieldConfig: item[0].item.config
                    })
                }

            } else {
                let msg = res.data.msg || '请求失败'
                SdwMessage.error(msg)
            }
        }).catch(error => SdwMessage.error('请求失败 ' + error))
    }

    //初始化form数据
    iniData = () => {
        let {isEdit, fieldParams, clickItem} = this.props
        if (isEdit) {
            let fieldParamsClone = _.cloneDeep(fieldParams)
            this.setState({
                fieldParams: fieldParamsClone
            })
        } else {
            if (clickItem.item.table_type == 1 && (clickItem.item.view_type == 1)) { //单表聚合或者分桶
                let fieldParamsState = this.state.fieldParams
                let fieldParamsClone = _.cloneDeep(fieldParamsState)
                this.setState({
                    fieldParams: _.assign({}, fieldParamsClone, {
                        mapping_type: "combine"
                    })
                })
            }
        }
    }

    //初始字段列表
    iniDataSourceFields = () => {
        let {clickItem, stepList} = this.props
        let item = clickItem.item
        if (!item) {
            return
        }
        let params = {
            id: item.job_id,
            node_id: item.id,
        }
        if (item.hasOwnProperty("link") && item.link) {
            params.link_id = item.link.id
        }
        getNodeRelateFields(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let record = res.data.data || []
                let fieldList = []
                record.forEach(i => {
                    let text = i.field_name
                    if (stepList.hasOwnProperty(i.reader_id)) {
                        text = "【" + stepList[i.reader_id].text + "】" + text
                    }
                    fieldList.push({id: i.field_name, text: text, reader_id: i.reader_id})
                })
                this.setState({
                    fieldList: fieldList
                })
            } else {
                let msg = res.data.msg || '请求失败'
                SdwMessage.error(msg)
            }
        }).catch(error => SdwMessage.error('请求失败 ' + error))
    }

    //切换节点
    changeClickTableView = (item) => {
        this.setState({
            clickItem: item
        }, this.iniDataSourceFields)
    }

    setFormValue = (type, val) => {
        let {fieldParams, fieldItems} = this.state
        let obj = {}
        switch (type) {
            case "mapping_type":
                obj = {
                    mapping_type: val,
                    field: "",
                }
                if (val == "single") {
                    let item = fieldItems.filter(i => i.id == "copy")
                    this.selectConfig("copy", item[0].item)
                }
                break
            case "src_reader_id":
                obj = {
                    src_reader_id: (val.reader_id ? val.reader_id : ""),
                    field: val.id
                }
                break
            case "field_type":
                obj = {field_type: val}
                break
            case "filter":
                obj = {filter: val}
                break
            case "field":
                obj = {field: val}
                break
            default:
                break
        }
        this.setState({
            fieldParams: Object.assign({}, fieldParams, {
                ...obj
            })
        })
    }

    selectConfig = (type, item) => {
        let {fieldParams} = this.state
        fieldParams.config.script = type
        fieldParams.config.value = {}
        let fieldParamsClone = _.cloneDeep(fieldParams)
        this.setState({
            fieldParams: fieldParamsClone,
            fieldConfig: item.config
        })
    }

    setValue = (type, val) => {
        let {fieldParams} = this.state
        fieldParams.config.value = _.assign({}, fieldParams.config.value, {
            [type]: val
        })
        let fieldParamsClone = _.cloneDeep(fieldParams)
        this.setState({
            fieldParams: fieldParamsClone
        })
    }

    // 增加数据信息列表
    addVirtualGroup = () => {
        this.setState(prevState => ({
            dataInfoList: [...prevState.dataInfoList, {field_value: '', field_type: 0, field: ''}]
        }))
    }

    // 删除数据信息列表
    removeVirtualGroup = (index) => {
        this.setState(prevState => {
            let dataInfoList = [...prevState.dataInfoList]
            dataInfoList.splice(index, 1)
            return {dataInfoList}
        })
    }

    // 编辑数据信息列表
    updateVirtualGroup = (val, type, index, item) => {
        this.setState(prevState => {
            let dataInfoList = [...prevState.dataInfoList]
            if (type === 'field_value') {
                dataInfoList[index].field_value = val
                dataInfoList[index].field = val
                dataInfoList[index].src_reader_id = item.reader_id
            } else {
                dataInfoList[index][type] = val
            }
            return {dataInfoList}
        })
    }

    // 批量增加字段 模块
    renderPopover = () => {
        let {checkList, showPopover, isUp, fieldList, dataInfoList} = this.state
        let {clickFieldsList} = this.props
        let list = []
        if (dataInfoList.some(obj => !_.isEmpty(obj.field))) {
            list = dataInfoList.filter(i => !_.isEmpty(i.field)).map(i => i.field)
        }
        return (
            <div style={{position: 'relative'}}>
                <Button
                    type='text'
                    onClick={(e) => {
                        this.setState({
                            showPopover: true,
                            isUp: e.clientY + 100 > 800,
                            checkList: list
                        })
                    }}
                >+添加更多字段</Button>
                {showPopover && (
                    <div
                        ref={this.popoverRef}
                        style={{maxHeight: 220}}
                        className={`popover-list-wrap ${isUp ? 'popover-list-wrap-up' : ''}`}
                    >
                        <div className='content'>
                            <Checkbox.Group
                                style={{display: 'flex', flexDirection: 'column'}}
                                value={checkList}
                                onChange={val => {
                                    this.handleCheckChange(val)
                                }}
                            >
                                {
                                    fieldList.map((item, k) => {
                                        return (
                                            <Checkbox
                                                key={k}
                                                label={item.id}
                                                disabled={clickFieldsList.some(i => i.field === item.id) || (checkList.length >= 500 && !checkList.includes(item.id))}
                                            >{item.text}</Checkbox>
                                        )
                                    })
                                }
                            </Checkbox.Group>
                        </div>
                        <div className='buttons'>
                            <Checkbox
                                checked={this.state.checkAll}
                                indeterminate={this.state.isIndeterminate}
                                onChange={val => this.handleCheckAllChange(val)}
                            >全选</Checkbox>
                            <div className='btn'>
                                <Button type='text' onClick={this.addFieldsToDataInfoList}>确定</Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    handleCheckChange = (value) => {
        let {fieldList} = this.state
        const checkedCount = value.length
        const fieldListLength = fieldList.length
        this.setState({
            checkList: value,
            checkAll: checkedCount === fieldListLength,
            isIndeterminate: checkedCount > 0 && checkedCount < fieldListLength,
        })
    }

    // 全选
    handleCheckAllChange = (checked) => {
        let {fieldList} = this.state
        let {clickFieldsList} = this.props
        // 过滤掉在clickFieldsList中的元素
        // const enabledItems = fieldList.filter(item => !clickFieldsList.some(i => i.field === item.id))
        // const checkList = checked ? _.isArray(enabledItems) && enabledItems.map(i => i.id) : []
        const enabledItems = this.getEnabledItems(fieldList, clickFieldsList)
        let checkList = checked ? enabledItems.slice(0, 500).map(i => i.id) : []
        if (checkList.length === 500) {
            SdwMessage.info('一次最多只能选择500个选项')
        }
        this.setState({
            isIndeterminate: false,
            checkAll: checked,
            checkList: checkList,
        })
    }

    getEnabledItems = (fieldList, clickFieldsList) => {
        return fieldList.filter(item => !clickFieldsList.some(i => i.field === item.id))
    }

    // 批量添加字段
    addFieldsToDataInfoList = () => {
        let {checkList, dataInfoList, fieldList} = this.state
        // 判断字段是否存在，不允许重复添加
        const existingFields = dataInfoList.map(item => item.field)
        const list = checkList
            .filter(field => !existingFields.includes(field))
            .map(field => {
                const matchedField = fieldList.find(item => item.id === field)
                const reader_id = matchedField ? matchedField.reader_id : null
                return {
                    field_value: field,
                    field: field,
                    field_type: 0,
                    src_reader_id: reader_id
                }
            })
        // 如果field不存在，则直接新添加一条数据
        const newDataInfoList = dataInfoList.map(item => {
            if (!item.field) {
                const newItem = list.shift()
                return newItem ? newItem : item
            }
            return item
        })
        // 删除取消勾选的字段
        const finalDataInfoList = newDataInfoList.filter(item => checkList.includes(item.field))
        // 如果finalDataInfoList为空，添加一条空的字段
        if (finalDataInfoList.length === 0) {
            finalDataInfoList.push({field_value: '', field_type: 0, field: ''})
        }
        this.setState({
            dataInfoList: [
                ...finalDataInfoList,
                ...list
            ],
            showPopover: false
        })
    }

    validateListData = (data) => {
        if (!_.isEmpty(data)) {
            for (let i = 0; i < data.length; i++) {
                const item = data[i]
                if (!item.field_value || !item.field) {
                    return false
                }
            }
            return true // 通过校验
        }
    }

    saveFields = () => {
        let {fieldParams, dataInfoList} = this.state
        let {isEdit, clickItem} = this.props
        let params = {}
        if (!isEdit && fieldParams.mapping_type == "single") {
            if (!this.validateListData(dataInfoList)) {
                this.setState({
                    sholdCheckValidate: true
                })
                return
            }
            params = {
                "id": clickItem.item.job_id,
                "node_id": clickItem.item.id,
                "fields_conf": dataInfoList.map(item => {
                    return {
                        config: {
                            script: "copy",
                            value: {
                                field: item.field_value
                            }
                        },
                        field: item.field,
                        src_reader_id: item.src_reader_id,
                        field_type: item.field_type,
                        mapping_type: "single",
                        enable: 1
                    }
                })
            }
        } else {
            params = {
                "id": clickItem.item.job_id,
                "node_id": clickItem.item.id,
                "fields_conf": [
                    {
                        "config": fieldParams.config,
                        "field": fieldParams.field,  // 结果字段
                        "src_reader_id": fieldParams.mapping_type == "single" ? fieldParams.src_reader_id : clickItem.id,   // 当mapping_type=single时, 取fieldParams.src_reader_id，否为当前节点id
                        "field_type": fieldParams.field_type,   // 字段类型
                        "filter": fieldParams.filter,   // 过滤条件
                        "mapping_type": fieldParams.mapping_type,   // single：表原生字段，combine：加工字段
                        "enable": 1
                    },
                ]
            }
        }
        let sdwMes = "添加成功"
        if (isEdit) {
            sdwMes = "编辑成功"
            params.fields_conf[0].id = fieldParams.id
        }
        saveFields(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                this.props.iniDataSourceFields()
                SdwMessage.success(sdwMes)
                this.props.changeShowAddField(false)
            } else {
                let msg = res.data.data || '请求失败'
                SdwMessage.error(msg)
            }
        }).catch(error => SdwMessage.error('请求失败 ' + error))

    }

    render() {
        let {
            isEdit,
            changeShowAddField,
            recordList,
            clickFieldsList,
            clickItem,
            cfgData,
        } = this.props

        let {
            fieldParams,
            dataInfoList,
            fieldList,
            sholdCheckValidate,
            fieldItems,
            fieldConfig,
            fieldTypeMapOption,
        } = this.state

        let clickFieldsListOptions = clickFieldsList.map(i => {
            return {id: i.field, text: i.field, item: i}
        }) || []
        let fieldItemsCopy = _.cloneDeep(fieldItems)
        let fieldItemsClone = []
        let mappingType = [
            {
                text: '原生字段',
                value: 'single',
            },
            {
                text: '计算字段',
                value: 'combine'
            },
        ]
        if (clickItem.item.table_type == 1 && (clickItem.item.view_type == 1)) { //单表聚合
            mappingType = [
                {
                    text: '计算字段',
                    value: 'combine'
                },
            ]
        }
        let pids = cfgData.midTableFunctionMapping[clickItem.item.view_type]
        fieldItemsClone = fieldItemsCopy.filter(i => {
            let ret = pids.some(pid => pid == i.item.pid)
            if (ret == true) {
                return i
            }
        })

        // 禁用已添加的字段
        let disabledArr = [
            ...dataInfoList.filter(i => !_.isEmpty(i.field_value)).map(i => i.field_value),
            ...clickFieldsList.filter(i => !_.isEmpty(i.field)).map(i => i.field)
        ]

        return (
            <div>
                <SdwDialog
                    title={isEdit ? "编辑字段" : "新增字段"}
                    width={800}
                    height={800}
                    zIndex={1010}
                    visible={true}
                    changeVisible={() => changeShowAddField(false)}
                    onCancelClick={() => changeShowAddField(false)}
                    onSureClick={this.saveFields}
                >
                    <div className='add-AddField-win__label'>
                        <span className='label__title required'>字段类型</span>
                        <SdwMulRadiobox
                            options={mappingType}
                            value={fieldParams.mapping_type}
                            changeCheckedFun={val => this.setFormValue("mapping_type", val)}
                        />
                    </div>
                    <div className='add-AddField-win__label'>
                        <span className='label__title required'>数据源</span>
                        <SdwSelect
                            width={570}
                            disabled={true}
                            listWidth={570}
                            clearable={false}
                            readOnly={false}
                            value={clickItem.id}
                            data={recordList}
                        />
                    </div>

                    {!isEdit && fieldParams.mapping_type == "single" &&
                        <div className='add-AddField-win__label'>
                            <span className='label__title required'>数据信息</span>
                            <div>
                                {_.isArray(dataInfoList) && dataInfoList.map((item, k) => {
                                    return (
                                        <div key={k} style={{marginBottom: 12, position: 'relative'}}>
                                            <SdwSelect
                                                placeholder={'请选择字段'}
                                                width={260}
                                                listWidth={260}
                                                dialogHeight={800}
                                                borderTopRightRadius={0}
                                                borderBottomRightRadius={0}
                                                readOnly={false}
                                                value={item.field_value}
                                                data={fieldList}
                                                onChange={(val, item) => {
                                                    this.updateVirtualGroup(val, 'field_value', k, item)
                                                }}
                                                disabledObj={{
                                                    arr: disabledArr,
                                                    key: 'id',
                                                    tip: '已存在相同名称的字段，请勿重复添加'
                                                }}
                                                sholdCheckValidate={sholdCheckValidate}
                                                validateFun={value => !!value ? true : '字段不能为空'}
                                            />
                                            <SdwSelect
                                                placeholder={'请选择数据类型'}
                                                width={130}
                                                listWidth={130}
                                                borderTopLeftRadius={0}
                                                borderTopRightRadius={0}
                                                borderBottomLeftRadius={0}
                                                borderBottomRightRadius={0}
                                                value={item.field_type}
                                                data={fieldTypeMapOption}
                                                onChange={(val, item) => {
                                                    this.updateVirtualGroup(val, 'field_type', k, item)
                                                }}
                                            />
                                            <SdwInput
                                                placeholder={'请输入显示名称'}
                                                width={180}
                                                borderTopLeftRadius={0}
                                                borderBottomLeftRadius={0}
                                                value={item.field}
                                                onChange={(val, item) => {
                                                    this.updateVirtualGroup(val, 'field', k, item)
                                                }}
                                                sholdCheckValidate={sholdCheckValidate}
                                                validateFun={value => !!value ? true : '显示名称不能为空'}
                                            />
                                            <i onClick={() => this.addVirtualGroup(k)} className='el-icon-plus'
                                               style={{marginLeft: 8}}></i>
                                            {k > 0 && <i onClick={() => this.removeVirtualGroup(k)}
                                                         className='el-icon-minus'></i>}
                                        </div>
                                    )
                                })}
                                {this.renderPopover()}
                            </div>
                        </div>
                    }

                    {!(!isEdit && fieldParams.mapping_type == "single") &&
                        <div className='add-AddField-win__label'>
                            <span className='label__title required'>数据类型</span>
                            <SdwSelect
                                width={570}
                                listWidth={570}
                                clearable={false}
                                readOnly={false}
                                value={fieldParams.field_type}
                                data={fieldTypeMapOption}
                                onChange={val => this.setFormValue("field_type", val)}
                            />
                        </div>
                    }

                    {
                        isEdit && fieldParams.mapping_type == "single" &&
                        <Fragment key={"add_filed_single"}>
                            {
                                fieldConfig.map(i => {
                                    let placeholder = i.type == 'input' ? `请输入${i.name}` : `请选择${i.name}`
                                    let label = i.name
                                    let is_column = i.is_column
                                    let field = i.field
                                    let fieldValue = fieldParams.config.value[field]
                                    let is_multi = i.is_multi
                                    let sholdCheck = i.must == 1 ? sholdCheckValidate : false
                                    let data = []
                                    if (is_column != 1) {
                                        try {
                                            data = JSON.parse(i.value).map(i => {
                                                if (i.default == 1 && !fieldValue) {
                                                    fieldValue = i.value
                                                }
                                                return {id: i.value, text: i.name, item: i}
                                            })
                                        } catch (e) {
                                        }
                                    } else {
                                        data = fieldList
                                    }
                                    return (
                                        <div key={`${field}_select`} className='add-AddField-win__label'>
                                            <span className='label__title required'>{label}</span>
                                            <SdwSelect
                                                width={570}
                                                listWidth={570}
                                                clearable={false}
                                                isMultipleChoice={is_multi ? true : false}
                                                readOnly={false}
                                                value={fieldValue}
                                                data={data}
                                                sholdCheckValidate={sholdCheck}
                                                placeholder={placeholder}
                                                validateFun={val => !val ? "此处不能为空" : true}
                                                onChange={(val, item) => {
                                                    this.setValue(field, val)
                                                    this.setFormValue("src_reader_id", item)
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }
                            <div className='add-AddField-win__label'>
                                <span className='label__title required'>显示名称</span>
                                <SdwInput
                                    width={570}
                                    placeholder='请输入显示名称'
                                    value={fieldParams.field}
                                    sholdCheckValidate={sholdCheckValidate}
                                    validateFun={val => !val ? "显示名称不能为空" : true}
                                    onChange={val => this.setFormValue("field", val)}
                                />
                            </div>
                        </Fragment>
                    }
                    {
                        fieldParams.mapping_type == "combine" &&
                        <Fragment key={"add_filed_combine"}>
                            <div className='add-AddField-win__label'>
                                <span className='label__title required'>字段名称</span>
                                <SdwInput
                                    width={570}
                                    placeholder='请填写加工完成后的字段名称'
                                    value={fieldParams.field}
                                    sholdCheckValidate={sholdCheckValidate}
                                    validateFun={val => !val ? "显示名称不能为空" : true}
                                    onChange={val => this.setFormValue("field", val)}
                                />
                            </div>
                            <div className='add-AddField-win__label'>
                                <span className='label__title'>过滤条件</span>
                                <SdwInput
                                    width={570}
                                    placeholder='请输入过滤条件'
                                    value={fieldParams.filter}
                                    onChange={val => this.setFormValue("filter", val)}
                                />
                            </div>
                            <div className='add-AddField-win__label'>
                                <span className='label__title required'>加工方式</span>
                                <SdwSelect
                                    width={570}
                                    listWidth={570}
                                    clearable={false}
                                    readOnly={false}
                                    value={fieldParams.config.script}
                                    data={fieldItemsClone}
                                    sholdCheckValidate={sholdCheckValidate}
                                    placeholder="请选择字段加工方式"
                                    validateFun={val => !val ? "加工方式不能为空" : true}
                                    onChange={(val, item) => {
                                        this.selectConfig(val, item.item)
                                    }}
                                />
                            </div>
                            {
                                fieldConfig.map(i => {
                                    let placeholder = i.type == 'input' ? `请输入${i.name}` : `请选择${i.name}`
                                    let label = i.name
                                    let zujianType = i.type
                                    let is_column = i.is_column
                                    let field = i.field
                                    let is_multi = i.is_multi
                                    let fieldValue = fieldParams.config.value[field] || (is_multi == 1 ? [] : "")
                                    if (is_multi == 1 && typeof (fieldValue) == "string") {
                                        fieldValue = fieldValue.split(",").map(i => {
                                            return {id: i, text: i}
                                        })
                                    }
                                    let sholdCheck = i.must == 1 ? sholdCheckValidate : false
                                    if (zujianType == "select") {
                                        let data = []
                                        if (is_column == 1) {
                                            if (clickItem.item.table_type == 1 && (clickItem.item.view_type == 1 || clickItem.item.view_type == 2)) { //单表或者分桶
                                                data = fieldList
                                            } else {
                                                data = clickFieldsListOptions
                                            }
                                        } else {
                                            try {
                                                data = JSON.parse(i.value).map(i => {
                                                    return {id: i.value, text: i.name, item: i}
                                                })
                                            } catch (e) {

                                            }
                                        }
                                        return (
                                            <div key={`${field}_${zujianType}`} className='add-AddField-win__label'>
                                                <span className='label__title required'>{label}</span>
                                                <SdwSelect
                                                    width={570}
                                                    listWidth={570}
                                                    clearable={false}
                                                    isMultipleChoice={is_multi ? true : false}
                                                    readOnly={false}
                                                    value={fieldValue}
                                                    data={data}
                                                    sholdCheckValidate={sholdCheck}
                                                    placeholder={placeholder}
                                                    validateFun={val => !val ? "此处不能为空" : true}
                                                    onChange={(val, item) => {
                                                        let value = val
                                                        if (is_multi == 1) {
                                                            value = val.map(i => {
                                                                return i.id
                                                            }).join(",")
                                                        }
                                                        this.setValue(field, value)
                                                    }}
                                                />
                                            </div>
                                        )
                                    } else if (zujianType == "input") {
                                        return (
                                            <div key={`${field}_${zujianType}`} className='add-AddField-win__label'>
                                                <span className='label__title required'>{label}</span>
                                                <SdwInput
                                                    width={570}
                                                    placeholder={placeholder}
                                                    value={fieldValue}
                                                    sholdCheckValidate={sholdCheck}
                                                    validateFun={val => !val ? "此处不能为空" : true}
                                                    onChange={val => this.setValue(field, val)}
                                                />
                                            </div>
                                        )
                                    } else if (zujianType == "number") {
                                        return (
                                            <div key={`${field}_${zujianType}`} className='add-AddField-win__label'>
                                                <span className='label__title required'>{label}</span>
                                                <SdwInput
                                                    width={570}
                                                    placeholder={placeholder}
                                                    value={fieldValue}
                                                    sholdCheckValidate={sholdCheck}
                                                    validateFun={val => !val ? "此处不能为空" : true}
                                                    onChange={val => {
                                                        this.setValue(field, val)
                                                    }}
                                                />
                                            </div>
                                        )
                                    } else if (zujianType == "textarea") {
                                        return (
                                            <div key={`${field}_${zujianType}`} className='add-AddField-win__label'>
                                                <span className='label__title required'>{label}</span>
                                                <SdwInput
                                                    type='textarea'
                                                    width={570}
                                                    placeholder={placeholder}
                                                    value={fieldValue}
                                                    sholdCheckValidate={sholdCheck}
                                                    validateFun={val => !val ? "此处不能为空" : true}
                                                    onChange={val => this.setValue(field, val)}
                                                />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </Fragment>
                    }
                </SdwDialog>
            </div>
        )
    }
}

const mapState = state => ({
    tableConf: state.midTableView.tableConf,
    linkConf: state.midTableView.linkConf,
    physicsTableList: state.midTableView.physicsTableList,
    cfgData: state.midTableView.cfgData,
})

const mapDispatch = dispatch => ({
    updateTableConf: dispatch.midTableView.updateTableConf,
    updateLinkConf: dispatch.midTableView.updateLinkConf,
    updatePhysicsTableList: dispatch.midTableView.updatePhysicsTableList,
})

export default connect(mapState, mapDispatch)(AddField)
