import React, { PureComponent } from 'react'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import { Button } from 'element-react'
import './index.css'
import { getTopicBusiness, getPage, getTablecfgList, getPublicCfg, srcTableItems } from '@services/atom'
import EditData from './components/EditData'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import _ from 'lodash'
import SdwLoading from '@common/Loadding'
import { hasPriv } from '@components/FrontendAuth/AuthMap'
import { commonApi } from '@utils/request'
import SdwMessage from '@common/Message/index'

class Atom extends PureComponent {
  state = {
    dataList: [],
    search: {
      target_name: { value: '', operator: '=' },
      target_name_ch: { value: '', operator: 'like', type: 'both' },
      target_type: { value: '', operator: '=' },
      topic: { value: '', operator: '=' },
      create_user: { value: '', operator: '=' },
      enable: { value: '', operator: '=' },
      related_table_id: { value: '', operator: '=' },
    },
    topicData: [],
    pagination: {
      total: 0,
      per_page: 10,
      pages: 0,
      current_page: 0,
    },
    topicDic: {},
    topicServerDic: {},
    curTitle: '',
    curRecord: {},
    visible: false,
    tableList: [],
    cfgData: {},
    tableShowData: false,
    srcTableIArr: []
  }

  // 初始化
  async componentDidMount() {

    // 路由跳转携带参数进行过滤
    let query = this.props.location.state || {}
    if (query.topicID) {
      const { search } = this.state
      await this.setState({
        search: Object.assign({}, search, {
          topic: Object.assign({}, search.topic, {
            value: String(query.topicID)
          })
        })
      })
    }

    this.getTopicBusiness()
    this.getPageData()
    this.getTablecfgList()
    this.getPublicCfg()
    this.srcTableItems()
  }

  // 获取所有的表
  getTablecfgList = () => {
    let that = this
    getTablecfgList({ limit: 10000, page: 0, search: { enable: { value: '1', operator: "=" } } }).then(res => {
      let tableList = res.data.data.items || []
      that.setState({tableList: tableList})
    })
  }

  // 获取所有计算方式选项列表
  getPublicCfg = () => {
    let that = this
    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }
        that.setState({cfgData: cfgData})
      }
    })
  }

  // 获取分页数据
  getPageData = () => {
    let that = this
    var params = {}
    params.page = this.state.pagination.current_page || 0
    params.limit = this.state.pagination.per_page
    params.search = {}
    for (const key in this.state.search) {
      const dval = this.state.search[key]
      if (dval.value !== "") {
        params.search[key] = dval
      }
    }

    getPage(params).then((res) => {
      let items = res.data.data.items

      if(!items) {
        items = []
      }

      for (let i=0; i<items.length; i++) {
        let topicId = items[i].topic
        let topicName = topicId
        let enableName = "无效"
        if(that.state.topicDic.hasOwnProperty(topicId)) {
          topicName = that.state.topicDic[topicId].label
        }
        if(items[i].enable == 1) {
          enableName = "有效"
        }
        items[i]["topicName"] = topicName
        items[i]["enableName"] = enableName
      }

      let pagination = that.state.pagination
      pagination.total = res.data.data.total
      pagination.per_page = res.data.data.per_page
      pagination.pages = res.data.data.pages
      pagination.current_page = res.data.data.current_page
      that.setState({
        dataList: [...items],
        pagination: {...pagination},
      })
    })
  }

  // 获取该原子指标已配置的来源表
  srcTableItems = () => {
    commonApi(srcTableItems, {}).then(data => {
      if (!_.isArray(data) || !data.length) return
      const srcTableIArr = data.filter(i => !!i.related_table_id).map(i => ({
        id: i.related_table_id,
        text: `${i.data_set_chinese}(${i.database_name}.${i.table_name})`
      }))
      this.setState({
        srcTableIArr
      })
    }).catch(err => SdwMessage.error(err))
  }

  // 获取主题列表
  getTopicBusiness = () => {
    let that = this
    that.setState({tableShowData: false})
    getTopicBusiness().then((res) => {
      that.setState({tableShowData: true})
      that.setState({topicData: res.data.data})
      let topicDic = {}
      for(let i=0; i<res.data.data.length; i++) {
        let topicId = res.data.data[i].value
        if(!topicDic.hasOwnProperty(topicId)) {
          topicDic[topicId] = res.data.data[i]
        }
      }
      that.setState({topicDic: topicDic})
    })
  }

  // 获取表标题
  getcolumns = () => {
    let self = this
    let {cfgData} = this.state
    const columns = [
      {
          title: "中文名称",
          width: 80,
          dataIndex: "target_name_ch",
      },
      {
          title: "英文名称",
        width: 80,
          dataIndex: "target_name",
      },
      {
          title: "主题域/业务",
        width: 80,
          // dataIndex: "topicName",
          render: data => {
            const { topicDic } = this.state
            if (_.isEmpty(topicDic)) return '-'
            const topicID = data.topic
            const serviceID = data.service
            const topicText = topicDic[topicID] && topicDic[topicID].label || ''
            const childrenList = topicDic[topicID] && _.get(topicDic[topicID], 'children') || []
            const record = childrenList.find(i => i.value == serviceID) || {}
            const text = _.isEmpty(record) ? '-' : record.label
            return <span>{ `${topicText}/${text}` }</span>
          }
      },
      {
          title: "来源表",
          // dataIndex: "related_table_id",
        width: 80,
          render: data => {
            const { tableList } = this.state
            if (_.isEmpty(tableList) || !_.isArray(tableList) || !tableList.length) return '-'
            const record = tableList.find(i => i.id == data.related_table_id) || {}
            const text = _.isEmpty(record) ? '-' : record.data_set_chinese + "(" + record.database_name + "." + record.table_name + ")"
            return <span>{ text }</span>
          }
      },
      {
        title: "度量字段",
        width: 40,
        dataIndex: "metric_field",
      },
      {
        title: "时间字段",
        width: 40,
        dataIndex: "time_field",
      },
      {
        title: "计算逻辑",
        width: 60,
        render: data => {
          if (!cfgData.calculationMethod) {
            return (
              <>
              </>
            )}
          for (let i in cfgData.calculationMethod) {
            if (cfgData.calculationMethod[i].id == data.metric_type) {
              return (
                <>
                  {cfgData.calculationMethod[i].name}
                </>
              )}
          }
        }
      },
      {
        title: "过滤条件",
        width: 50,
        dataIndex: "calculation",
      },
      {
          title: "修改人",
          width: 30,
          dataIndex: "modify_user",
      },
      {
        //title: "状态",
        //dataIndex: "enableName",
        width:20,
        title: "状态",
        render: function (data, record) {
          return (
            <>
              {
                _.isEqual(typeof data.enable, 'number') && <span className={
                  data.enable ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                }></span>
              }
              <span>{_.isEqual(typeof data.enable, 'number') ?
                (data.enable ? '有效' : '无效') : '-'}</span>
            </>
          )
        }
      },
      {
          title: "更新时间",
        width: 90,
          dataIndex: "modify_time",
      },
      ...hasPriv('atomCreateOrUpdate') ?
      [{
          title: "操作",
          width: 20,
          render: (data, record) => {
              return (
                  <div style={{'color': '#517df3', 'cursor': 'pointer'}} onClick={ () => { self.onClickGetOneData(data, self) } }>编辑</div>
              )
          },
      }] : []
    ]
    return columns
  }

  // 获取编辑的行记录
  onClickGetOneData = (data, e) => {
    let topicName = ""
    let topicServiceName = ""
    if(this.state.topicDic.hasOwnProperty(data.topic)) {
      topicName = this.state.topicDic[data.topic].label
      if(this.state.topicDic[data.topic].hasOwnProperty("children")){
        for(let i=0; i<this.state.topicDic[data.topic].children.length; i++) {
          let sid = this.state.topicDic[data.topic].children[i].value
          let label = this.state.topicDic[data.topic].children[i].label
          if(sid == data.service) {
            topicServiceName = label
          }
        }
      }
    }
    data.topicBusiness = {
      "ids": [data.topic, data.service],
      "texts": [topicName, topicServiceName],
      "clickItem": [data.topic + '' + data.service, topicServiceName],
    }
    data.enable = data.enable.toString()
    this.setState({
      curRecord: data,
      visible: true,
      curTitle: '编辑原子指标'
    })
  }

  // 获取当前页码
  getClickPage = (pageNum) => {
    this.state.pagination.current_page = pageNum
    this.getPageData()
  }

  // 获取当前每页总条数
  pageCountChange = (per_page) => {
    this.state.pagination.per_page = per_page
    this.getPageData()
  }

  addOrEdit = (record = {}) => {
    this.setState({
      visible: true,
      curTitle: record.title,
      curRecord: record,
    })
  }

  onSureClick = () => {
    this.setState({
      visible: false,
    })
    this.getPageData()
    this.srcTableItems()
  }

  render () {
    var { search, pagination, curTitle, visible, curRecord, topicData, tableList, cfgData, tableShowData } = this.state;
    return (
      <div className="machFactory">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/atom'
            }, {
              title: '原子指标'
            }
          ]}
        />

        <div className="page-mainbody">
          {/** 搜索区域 */}
          <div className="page-search">
            <div className="input-wrap">
              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwInput
                  width={150}
                  label="指标中文名"
                  placeholder="请输入关键字"
                  value={search.target_name_ch.value}
                  onBlur={this.getPageData}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      target_name_ch: Object.assign({}, search.target_name_ch, {
                        value: val
                      })
                    })
                  })}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwInput
                  width={150}
                  label="指标英文名"
                  value={search.target_name.value}
                  onBlur={this.getPageData}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      target_name: Object.assign({}, search.target_name, {
                        value: val
                      })
                    })
                  })}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwSelect
                  placeholder="全部"
                  width={180}
                  label="主题域"
                  readOnly={false}
                  value={search.topic.value}
                  data={this.state.topicData.map(el => {
                    return {
                      id: el.value,
                      text: el.label
                    }
                  })}
                  onChange={val => {
                    this.setState({
                      pagination:Object.assign({}, pagination, {
                        current_page:1
                      }),
                      search: Object.assign({}, search, {
                        topic: Object.assign({}, search.topic, {
                          value: val
                        })
                      })
                    }, this.getPageData)
                  }}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwInput
                  width={150}
                  label="创建人"
                  placeholder="请输入RTX名字"
                  value={search.create_user.value}
                  onBlur={this.getPageData}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      create_user: Object.assign({}, search.create_user, {
                        value: val
                      })
                    })
                  })}
                />
              </span>

              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwSelect
                  placeholder="全部"
                  width={150}
                  listWidth={330}
                  label="来源表"
                  readOnly={false}
                  value={search.related_table_id.value}
                  data={this.state.srcTableIArr}
                  onChange={val => {
                    this.setState({
                      pagination:Object.assign({}, pagination, {
                        current_page:1
                      }),
                      search: Object.assign({}, search, {
                        related_table_id: Object.assign({}, search.related_table_id, {
                          value: val
                        })
                      })
                    }, this.getPageData)
                  }}
                />
              </span>
              <span style={{ marginRight: '8px', marginBottom: '8px' }}>
                <SdwSelect
                  value={search.enable.value}
                  width={150}
                  label="有效性"
                  data={[{
                    id: '',
                    text: '全部'
                  }, {
                    id: 1,
                    text: '有效'
                  }, {
                    id: 0,
                    text: '无效'
                  }]}
                  onChange={val => {
                    this.setState({
                      pagination:Object.assign({}, pagination, {
                        current_page:1
                      }),
                      search: Object.assign({}, search, {
                        enable: Object.assign({}, search.enable, {
                          value: val
                        })
                      })
                    }, this.getPageData)
                  }}
                />
              </span>
              <div className="btn-group">
                {
                  hasPriv('atomCreateOrUpdate') &&
                  <Button
                    type="primary"
                    icon='plus'
                    onClick={ () => this.addOrEdit({ title: '新增原子指标', curRecord:{} }) }
                  >新增</Button>
                }
              </div>
            </div>

          </div>

          {/** 分页显示数据区域 */}
          <div className="tableShowData">
            <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
              <SdwTable
                tdWhiteSpace={true}
                columns={this.getcolumns()}
                dataSource={this.state.dataList}
              />
            </SdwLoading>
            <SdwPagenation
              total={pagination.total}
              currentPage={pagination.current_page}
              pageCount={pagination.per_page}
              getClickPage={this.getClickPage}
              pageCountChange={this.pageCountChange}
            />
          </div>
        </div>

        {/** 编辑弹窗 */}
        <EditData
          title={curTitle}
          visible={visible}
          record={curRecord}
          topicData={topicData}
          tableList={tableList}
          cfgData={cfgData}
          onIconClick={() => this.setState({ visible: false })}
          onCancelClick={() => this.setState({ visible: false })}
          onSureClick={() => {
            this.setState({ visible: false })
            this.onSureClick()
          }}
        />
      </div>
    )
  }
}

export default Atom
