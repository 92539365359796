import { request } from '../utils/request'

// 来源表（mysql）、目标表（es）
export const getTableApiItems = params => {
  return request({ url: '/metadata/tableApi/items', method: 'get', params: params })
}

export const getAllTablecfg = params => {
  return request({ url: '/metadata/tableApi/getAllTablecfg', method: 'get', params: params })
}

export const getFieldRuleConf = params => {
  return request({ url: '/collection/dbimport/getFieldRuleConf', method: 'get', params: params })
}

export const getAllTasks = params => {
  return request({ url: '/collection/dbimport/getAllTasks', method: 'get', params: params })
}

export const getInfoById = params => {
  return request({ url: '/collection/dbimport/getInfoById', method: 'get', params: params })
}

export const getTargetPreview = params => {
  return request({ url: '/collection/dbimport/getDataPreview', method: 'get', params: params })
}

export const getCollectConfigList = params => {
  return request({ url: '/collection/dbimport/getCollectConfigList', method: 'get', params: params })
}

export const getLogCollectConfigDetail = params => {
  return request({ url: '/collection/dbimport/getLogCollectConfigDetail', method: 'get', params: params })
}

export const setLogCollectConfig = params => {
  return request({ url: '/collection/dbimport/setLogCollectConfig', method: 'post', data: params })
}

export const checkLogCollectCfg = params => {
  return request({ url: '/collection/dbimport/checkLogCollectCfg', method: 'post', data: params })
}

export const getMonitorServerConfigList = params => {
  return request({ url: '/collection/dbimport/getMonitorServerConfigList', method: 'get', params: params })
}

export const setMonitorJoinLogConfig = params => {
  return request({ url: '/collection/dbimport/setMonitorJoinLogConfig', method: 'post', data: params })
}

// 任务配置
export const storeCollection = params => {
  return request({ url: '/collection/dbimport/store', method: 'post', data: params })
}

// 更新配置
export const updateCollection = params => {
  return request({ url: '/collection/dbimport/update', method: 'post', data: params })
}

// 任务提交
export const runDataTask = params => {
  return request({ url: '/collection/dbimport/runDataTask', method: 'post', data: params })
}
