// 派生指标详情页
import { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwLayout from '@common/Layout'
import SdwTabs from '@common/Tabs'
import Message from '@common/Message'
import NoRight from '@pages/NoRight'
import BloodPage from './Blood'
import LogPage from './Log'
import EditData from '../components/EditData'
import EditIndicatorsData from '../components/EditIndicatorsData/EditIndicatorsData2'
import editImg from '../../../../img/icon_16_bj@2x.png'
import { getTargetPage, getTopicBusiness, getTarget } from '@services/Derived'
import { formatJsonParse } from '@utils/formatJson'
import { TYPE_MAP_TEXT } from '@config/derived'
import { hasPriv } from '@components/FrontendAuth/AuthMap'
import './index.css'
import { getTitle } from '@utils/common'
import ReRunWin from '../components/ReRunWin'

const Menu = [
  {
    title: '指标血缘',
    path: '/BloodPage',
    component: BloodPage
  }, {
    title: '运行日志',
    path: '/LogPage',
    component: LogPage
  }
]

const topicData = formatJsonParse(sessionStorage.getItem('derivedTopicData'))
const tableList = formatJsonParse(sessionStorage.getItem('derivedTableList'))
const cfgData = formatJsonParse(sessionStorage.getItem('derivedCfgData'))
const atomTarget = formatJsonParse(sessionStorage.getItem('derivedAtomTarget'))
const dimension = formatJsonParse(sessionStorage.getItem('derivedDimension'))

function Detail(props) {

  const [curID, setCurID] = useState('')
  const [baseInfo, setBaseInfo] = useState({})
  const [topicMap, setTopicMap] = useState({})
  const [businessMap, setBusinessMap] = useState({})
  const [visible, setVisible] = useState(false) // 编辑弹窗
  const [curRecord, setCurRecord] = useState({})
  const [indicatorsVisible, setIndicatorsVisible] = useState(false) // 派生指标弹窗
  const [reRunVisible, setReRunVisible] = useState(false) // 重跑设置弹窗

  // 获取主题列表
  useEffect(() => {
    getTopicBusiness().then(res => {
      const code = _.get(res, 'data.code')
      if (code !== 0) return
      const arr = _.get(res, 'data.data')
      if (!_.isArray(arr)) return

      let topicObj = {}
      let businessObj = {}
      arr.forEach(i => {
        topicObj[i.value] = i.label

        if (_.isArray(i.children) && i.children.length) {
          i.children.forEach(j => {
            businessObj[j.value] = j.label
          })
        }
      })

      setTopicMap(topicObj)
      setBusinessMap(businessObj)
    })
  }, [])

 // 通过id请求详情数据
  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    getCurItem(curID)
  }, [curID])

  // 获取编辑的行记录
  function getCurItem(id) {
    if (!id || _.isEmpty(topicData)) return

    let topicDic = {}
    for (let i = 0; i < topicData.length; i++) {
      let topicId = topicData[i].value
      if (!topicDic.hasOwnProperty(topicId)) {
        topicDic[topicId] = topicData[i]
      }
    }

    getTarget({ id }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        let item = _.get(res, 'data.data')
        for (let i in item) {
          let val = item[i]

          if (i === "store_dimension") {
            item['store_dimension'] = formatJsonParse(val)
            continue
          }

          if (i === "time_schedule") {
            item['time_schedule'] = formatJsonParse(val)
            continue
          }

          if (i === "target_type") {
            item["target_type"] = item["target_type"] + ''
            continue
          }

          if (i === "decoration_ids") {
            let decoration_ids = []
            decoration_ids = val ? val.split(",") : []
            item["decoration_ids"] = decoration_ids
            continue
          }

          item[i] = val
        }

        let topicName = ""
        let topicServiceName = ""

        const record = topicDic[item.topic] || {}
        if (!_.isEmpty(record)) {
          topicName = record.label
          if (_.isArray(record.children)) {
            for (let i = 0; i < record.children.length; i++) {
              let sid = record.children[i].value
              let label = record.children[i].label

              if (sid == item.service) {
                topicServiceName = label
              }
            }
          }
        }
        item.topicBusiness = {
          "ids": [item.topic, item.service],
          "texts": [topicName, topicServiceName],
          "clickItem": [item.topic + '' + item.service, topicServiceName],
        }

        setCurRecord(item)
      }
    })
  }

  function initData() {
    const ID = _.get(props, 'match.params.id') || ''
    if (!!ID) {
      setCurID(ID)
      getCurItem(ID)
    }
    getTargetPage({
      search: JSON.stringify({
        id: {
          value: ID,
          operator: '='
        }
      })
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const record = _.get(res, 'data.data.items[0]')
        if (!_.isEmpty(record)) {
          setBaseInfo(record)
        }
      } else {
        const msg = _.get(res, 'data.msg') || '获取详情数据失败！'
        Message.error(msg)
      }
    }).catch(err => Message.error('获取详情数据接口异常' + err))
  }

  // 计算公式
  let cal_expression = useMemo(() => {
    let ce = formatJsonParse(baseInfo.cal_expression)
    if (_.isArray(ce)) {
      ce = ce.join('')
    }
    return ce
  }, [baseInfo.cal_expression])

  // 维度
  let store_dimension = useMemo(() => {
    let sd = formatJsonParse(baseInfo.store_dimension)
    if (_.isArray(sd)) {
      sd = sd.map(i => {
        const arr = _.isArray(i.id) ? i.id : []
        return arr.join('#')
      }).join('/')
    }
    return sd
  }, [baseInfo.store_dimension])

  // 时间周期
  let time_schedule = useMemo(() => {
    let ts = formatJsonParse(baseInfo.time_schedule)
    if (_.isArray(ts)) {
      ts = ts.map(i => {
        return `${i.time_cycle}/${i.run_cycle}`
      }).join('|')
    }
    return ts
  }, [baseInfo.time_schedule])

  let derivativeTargets = useMemo(() => {
    let der = baseInfo.derivativeTargets
    if (_.isArray(der)) {
      der = der.filter(j => j.enable).map(i => i.id).join(',')
    }
    return der
  }, [baseInfo.derivativeTargets])

  function onSureClick() {
    setVisible(false)
    initData()
  }

  function onSureClickOfIndicators() {
    setIndicatorsVisible(false)
    initData()
  }

  return (
    <div className="derived-detail__wrap">
      <SdwBreadCrumbs
        history={props.history}
        data={[
          {
            title: '指标加工厂',
            path: '/admin/factory/atom'
          }, {
            title: '派生指标',
            path: '/admin/factory/derived'
          }, {
            title: '指标详情'
          }
        ]}
      />

      {
        hasPriv('getDataKinship') ?
        <>
          <div className="base-info">
            <div className="base-info__title">
              <span>指标信息</span>
              {
                hasPriv('derivedCreateOrUpdate') &&
                <span
                  className="iconfont icon-edit"
                  onClick={() => setVisible(true)}
                />
              }
              {
                hasPriv('storeOrUpdateDepth') &&
                <span
                  className="iconfont icon-setup"
                  onClick={() => setReRunVisible(true)}
                />
              }
            </div>
            <div className="base-info__content">
              <SdwLayout.Row gutter={20}>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">英文名</span><span title={ getTitle(baseInfo.target_name) }>{ baseInfo.target_name || '-' }</span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">指标类型</span><span title={ getTitle(baseInfo.target_type) }>{ TYPE_MAP_TEXT[baseInfo.target_type] || '-' }</span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  {/* TODO */}
                  <span className="title">原子指标</span><span title={ getTitle(baseInfo.source_targets) }>{ baseInfo.source_targets || '-' }</span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">描述</span><span title={ getTitle(baseInfo.comments) }>{ baseInfo.comments || '-' }</span>
                </div></SdwLayout.Col>
              </SdwLayout.Row>
              <SdwLayout.Row gutter={20}>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">中文名</span><span title={ getTitle(baseInfo.target_name_cn) }>{ baseInfo.target_name_cn || '-' }</span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">维度</span><span title={ getTitle(store_dimension) }>{ store_dimension || '-' }</span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">主题域/业务</span>
                  <span
                    title={ getTitle(`${topicMap[baseInfo.topic]}/${businessMap[baseInfo.service]}`) }
                  >{`${topicMap[baseInfo.topic]}/${businessMap[baseInfo.service]}` || '-'}</span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">计算公式</span><span title={ getTitle(cal_expression) }>{ cal_expression || '-' }</span>
                </div></SdwLayout.Col>
              </SdwLayout.Row>
              <SdwLayout.Row gutter={20}>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">时间周期</span><span title={ getTitle(time_schedule) }>{ time_schedule || '-' }</span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">单位</span><span title={ getTitle(baseInfo.target_unit) }>{ baseInfo.target_unit || '-' }</span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"><div className="grid-content bg-purple">
                  <span className="title">衍生指标</span>
                  <span title={ getTitle(derivativeTargets) }>
                    { derivativeTargets || '-' }
                    {
                      hasPriv('storeOrUpdateDerive') &&
                      <span
                        className="iconfont icon-edit"
                        onClick={() => setIndicatorsVisible(true)}
                      />
                    }
                  </span>
                </div></SdwLayout.Col>
                <SdwLayout.Col span="6"></SdwLayout.Col>
              </SdwLayout.Row>
            </div>
          </div>

          <div className="base-info" style={{ marginTop: 16 }}>
            <SdwTabs
              {...props}
              menu={Menu}
              baseInfo={baseInfo}
            />
          </div>

          {/* 编辑弹窗 */}
          {
            visible &&
            <EditData
              title={`编辑派生指标（${curID}）`}
              visible={visible}
              record={curRecord}
              topicData={topicData}
              tableList={tableList}
              cfgData={cfgData}
              atomTarget={atomTarget}
              dimension={dimension}
              onIconClick={() => setVisible(false)}
              onCancelClick={() => setVisible(false)}
              onSureClick={onSureClick}
            />
          }

          {/* 编辑弹窗 */}
          {
            reRunVisible &&
            <ReRunWin
              title={`编辑派生指标（${curID}）`}
              visible={reRunVisible}
              record={curRecord}
              cfgData={cfgData}
              setReRunVisible={setReRunVisible}
              onSureClick={onSureClick}
            />
          }

          {/* 编辑派生指标弹窗 */}
          {
            indicatorsVisible &&
            <EditIndicatorsData
              title={`编辑衍生指标（${curID}）`}
              visible={indicatorsVisible}
              record={{
                curRecord: baseInfo
              }}
              cfgData={cfgData}
              onIconClick={() => setIndicatorsVisible(false)}
              onCancelClick={() => setIndicatorsVisible(false)}
              onSureClick={onSureClickOfIndicators}
            />
          }
        </> :
        <NoRight
          height={window.innerHeight - 200}
        />
      }
    </div>
  )
}

export default Detail
