import { useState, useEffect, Fragment, useMemo } from 'react'
import _ from 'lodash'
import MulRadiobox from '@common/MulRadiobox'
import Table from '@common/Table'
import { commonApi } from '@utils/request'
import Message from '@common/Message/index'
import Loadding from '@common/Loadding'
import Pagenation from '@common/Pagenation'
import { historyOrder, relationAccount, getUserTraceItem } from '@services/userSearch'
import './index.css'

const getcolumns = () => {
  return [
    {
      title: "工单号",
      width: 80,
      render: data => (
        <a
          href={`https://cd.cm.com/web/kfwp/indexNew?&hash=/taskDetail/${data.task_id}`}
          target='_blank'
          rel='noopener'
        >{ data.task_id }</a>
      )
    }, {
      title: "渠道",
      dataIndex: "service_channel",
      width: 50
    }, {
      title: "标题",
      dataIndex: "title",
      width: 80
    }, {
      title: "建单时间",
      dataIndex: "create_time",
      width: 80
    }, {
      title: "归档路径",
      dataIndex: "archivepath_name",
      width: 120
    }, {
      title: "工单状态",
      dataIndex: "is_handled",
      width: 30
    }, {
      title: "满意度",
      dataIndex: "satis_level",
      width: 30
    }
  ]
}

export default function WorkOrder(props) {

  const {
    userTranceItem = {},
    search = {}
  } = props

  const [oriCurRelativeVal, setOriCurRelativeVal] = useState('')
  const [relativeVal, setRelativeVal] = useState('current')
  const [isAll, setIsAll] = useState(false)
  const [relativeOptions, setRelativeOptions] = useState([
    {
      text: '当前帐号',
      value: 'current'
    }
  ])
  const [dataList, setDataList] = useState([])
  const [isLoadding, setIsLoadding] = useState(false)
  const [relationAccountMap, setRelationAccountMap] = useState({})
  const [descobj, setDescobj] = useState({})
  const [curBaseInfo, setCurBaseInfo] = useState({})
  const [searchParams, setSearchParams] = useState({
    page: 1,
    limit: 50
  })

  const basicInfo = _.get(userTranceItem, 'basicInfo') || {}

  useEffect(() => {
    if (_.isEmpty(basicInfo)) return
    setCurBaseInfo(basicInfo)
  }, [basicInfo])

  useEffect(() => {
    if (_.isEmpty(basicInfo)) return
    commonApi(relationAccount, {
      qq: basicInfo.QQ,
      phone: basicInfo.PHONE,
      openid: basicInfo.OPENID,
      is_detail: 0
    }).then(data => {
      setRelationAccountMap(data)
      const keys = _.keys(data).map(key => key) || []
      let arr = []
      if (_.isArray(keys) && keys.length) {
        arr = keys.reduce((prev, key) => {
          prev.push({
            text: `${key}(${_.get(data[key], 'account_type') || '-'})`,
            value: key
          })
          return prev
        }, [])
      }

      // 给当前帐号一个标识
      const oriCurID = basicInfo.OPENID || basicInfo.PHONE || basicInfo.QQ
      setRelativeVal(oriCurID)
      setOriCurRelativeVal(oriCurID)
      setRelativeOptions([{
        text: '当前帐号',
        value: oriCurID
      }, ...arr])
    }).catch(err => Message.error(err))
  }, [basicInfo])

  useEffect(() => {
    if (!relativeVal || !oriCurRelativeVal) return

    let params = {}
    if (oriCurRelativeVal === relativeVal) {
      params = search
    } else {
      if (_.isEmpty(relationAccountMap)) return
      const curRecord = relationAccountMap[relativeVal] || {}
      params = {
        account: curRecord.account,
        accountType: curRecord.type_id
      }
    }

    setIsLoadding(true)
    commonApi(getUserTraceItem, params).then(data => {

      // 当前点击帐号的基本信息
      const basicInfo = _.get(data, 'basicInfo') || {}
      setCurBaseInfo(basicInfo)
      getTableInfo(basicInfo)
    }).catch(err => Message.error(err))
    .finally(() => setIsLoadding(false))
  }, [relativeVal])

  useEffect(() => {
    if (_.isEmpty(basicInfo)) return
    getTableInfo(basicInfo, isAll)
  }, [basicInfo])

  function getTableInfo(basicInfo = {}, isAll = false) {
    setIsLoadding(true)
    commonApi(historyOrder, {
      is_all: Number(isAll),
      phone: basicInfo.PHONE,
      openid: basicInfo.OPENID
    }).then(data => {
      const tasks = _.get(data, 'tasks') || []
      const orderStatus = _.get(data, 'orderStatus') || {}
      setDescobj(orderStatus)
      setDataList(tasks)
    }).catch(err => {
      Message.error(err)
      setDescobj({})
      setDataList([])
    }).finally(() => setIsLoadding(false))
  }

  const curDataList = useMemo(() => {
    if (!_.isArray(dataList) || !dataList.length) return []

    // 没有展开，不做分页
    if (!isAll) return dataList

    const {
      page = 1,
      limit = 50
    } = searchParams

    const startNum = (page - 1) * limit
    const endNum = page * limit

    return dataList.slice(startNum, endNum)

  }, [dataList, searchParams, isAll])

  return (
    <Loadding top={200} loadding={isLoadding} tipContent='拼命加载中...'>
      <div className='monitor__user-search__outer-wrap'>
        <div className='monitor__user-search__service__link-account-wrap'>
          <div className='label'>关联帐号</div>
          <MulRadiobox
            colorStyle={"light"}
            options={relativeOptions}
            value={relativeVal}
            changeCheckedFun={val => {
              setRelativeVal(val)
              setIsAll(false)
            }}
          />
        </div>
        <div className='monitor__user-search__service__desc-wrap'>
          <div className='title'>{ isAll ? '历史工单' : '近30天工单' }</div>
          <div className='desc'>
            {
              !_.isEmpty(descobj) &&
              _.keys(descobj).map((key, index) => (
                <Fragment key={index}>
                  <span>{ key } </span>
                  <span style={{
                    color: '#265CF0'
                  }}>{ descobj[key] }</span>
                  <span> 个；</span>
                </Fragment>
              ))
            }
          </div>
        </div>


        <Table
          columns={getcolumns()}
          dataSource={curDataList}
          tbodyMinHeight={ !!curDataList.length ? 20 : 230 }
        />

        {
          !isAll &&
          <div
            className='monitor__user-search__service__work-order__more-click'
          >
            <span
              className='btn'
              onClick={() => {
                getTableInfo(curBaseInfo, true)
                _.debounce(setIsAll, 500)(r => !r)
              }}
            >已展示30天内的工单，点击查看更多</span>
            <span className='iconfont icon-arrow-down' />
          </div>
        }

        {
          isAll &&
          <Pagenation
            total={dataList.length || 0}
            currentPage={searchParams.page || 1}
            pageCount={searchParams.limit || 50}
            getClickPage={val => setSearchParams(r => _.assign({}, r, {
              page: val
            }))}
            pageCountChange={val => setSearchParams(r => _.assign({}, r, {
              limit: val
            }))}
          />
        }
      </div>
    </Loadding>
  )
}
