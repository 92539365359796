import { useState, useEffect } from 'react'
import Table from '@common/Table'
import Message from '@common/Message'
import Loadding from '@common/Loadding'
import { relationAccount } from '@services/userSearch'
import './index.css'
import { commonApi } from '@utils/request'
import _ from 'lodash'

function getcolumns() {
  return [
    {
      title: "帐号",
      dataIndex: "account",
    }, {
      title: "帐号类型",
      dataIndex: "account_type",
    }, {
      title: "关系来源",
      dataIndex: "relation",
    }, {
      title: "关系类型",
      dataIndex: "relation_type",
    }, {
      title: "入库时间",
      dataIndex: "date",
    }
  ]
}

export default function LinkAccount(props) {

  const {
    userTranceItem = {}
  } = props

  const [dataSource, setDataSource] = useState([])
  const [isLoadding, setIsLoadding] = useState(false)

  const basicInfo = _.get(userTranceItem, 'basicInfo') || {}
  
  useEffect(() => {
    if (_.isEmpty(basicInfo)) return
    setIsLoadding(true)
    commonApi(relationAccount, {
      qq: basicInfo.QQ,
      phone: basicInfo.PHONE,
      openid: basicInfo.OPENID,
      is_detail: 1
    }).then(data => {
      const arr = _.values(data) || []
      setDataSource(arr)
    }).catch(err => Message.error(err))
    .finally(() => setIsLoadding(false))
  }, [basicInfo])

  return (
    <Loadding top={200} loadding={isLoadding} tipContent='拼命加载中...'>
      <div className='monitor__user-search__outer-wrap'>
        <Table
          columns={getcolumns()}
          dataSource={dataSource}
        />
      </div>
    </Loadding>
  )
}