import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import './style/configRule.css'

export default props => {

  return (
    <div className="sdw-config-rule-wrap">
      <SdwSelect
        value={props.data.queue}
        width={104}
        clearable={false}
        data={props.dataList}
        onChange={val => {
          if (typeof props.changeConfigRule !== 'function') return
          props.changeConfigRule(props.index + 1, 'queue', val)
        }}
      />
      <span style={{ marginLeft: 8 }} />
      <SdwSelect
        value={props.data.method_name}
        width={328}
        data={props.fieldList}
        onChange={val => {
          if (typeof props.changeConfigRule !== 'function') return
          props.changeConfigRule(props.index + 1, 'method_name', val)
        }}
      />
      <span style={{ marginLeft: 8 }} />
      <SdwInput
        value={props.data.method_param}
        width={360}
        placeholder=''
        onChange={val => {
          if (typeof props.changeConfigRule !== 'function') return
          props.changeConfigRule(props.index + 1, 'method_param', val)
        }}
      />
      <span style={{ marginLeft: 8 }} />
      <i 
        className="sdw-config-rule-del-icon"
        onClick={() => {
          if (typeof props.delConfigRule !== 'function') return
          props.delConfigRule(props.index)
        }}
      />
    </div>
  )
}