import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from '@common/Select'
import _ from 'lodash'
import { getStaffs } from '@services/metaData'
import './index.css'

function SelectUser(props) {
  const { value, onChange, placeholder, width, oriAllMembers, maxOptionsLength } = props

  const [allMembers, setAllMembers] = useState([])
  const [multipleValue, setMultipleValue] = useState([])

  useEffect(() => {
    // 如果外部传入了下拉选项，则不需再次请求接口数据
    if (_.isArray(oriAllMembers) && oriAllMembers.length) {
      setAllMembers(oriAllMembers)
      return
    }
    
    getStaffs().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        let list = _.isArray(data) ? data : []
        const Options = list.map(i => ({
          id: i.value,
          text: i.name
        }))
        setAllMembers(Options)
      }
    })
  }, [oriAllMembers])

  useEffect(() => {
    if (_.isArray(value)) {
      setMultipleValue(value)
    }
  }, [value])

  return (
    <div className='components__select-users__wrap-div'>
      <Select
        placeholder={placeholder}
        width={width}
        isMultipleChoice
        readOnly={false}
        maxOptionsLength={maxOptionsLength}
        value={multipleValue}
        hideDefaultList
        onChange={val => {
          setMultipleValue(val)
          onChange(val)
        }}
        data={allMembers}
      />
    </div>
  )
}

SelectUser.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func, // 最大展示候选项数量（接口数据过多，用于优化使用）,避免界面卡顿
  maxOptionsLength: PropTypes.number
}

SelectUser.defaultProps = {
  width: '100%',
  placeholder: '',
  value: [],
  onChange: _.noop,
  maxOptionsLength: 200
}

export default SelectUser