import React, {PureComponent} from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import SdwMessage from '@common/Message'
import {getStaffs} from '@api/alertRules'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../../index.css'
import {requestOptionsLOG} from '@config/config'

const baseURL = requestOptionsLOG.basename

class MachineWin extends PureComponent {

    state = {
        params: {
            ip: '', // 安装机器
            principal: [], // 负责人
            cluster: '', // 机器所属集群
            extra: '', // 备注
            enable: false, // 是否有效
            rtx: '', // 操作人
            filebeatAdvancedConfigVOS: []
        },
        staffsList: [],
        advancedConfigList: [{id: 0}], // filebeat高级配置
        sholdCheckValidate: false,
        allMembers: [], // rtx
        optionsList: []
    }

    componentDidMount() {
        this.initData()
        this.getStaffs()
        this.queryAdvancedConfig()
    }

    initData = () => {
        let {isEdit, currentItem} = this.props
        if (isEdit) {
            let params = {
                id: currentItem.id,
                ip: currentItem.ip || '',
                principal: '',
                cluster: currentItem.cluster || '',
                extra: currentItem.extra || '',
                enable: currentItem.enable,
                rtx: this.props.userInfo.name,
            }
            this.setState({
                params: params,
                staffsList: (currentItem.principal.split(',') || []).map(i => {
                    return {id: i, text: i}
                })
            })
        }
    }

    // 查询高级配置信息
    queryAdvancedConfig = () => {
        let str = '?configLocation=' + 'GLOBAL_CONFIGURATION'
        fetch(baseURL + '/sdw/filebeat/filebeat/queryAdvancedConfig' + str,
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then(res => {
            res.json().then((data) => {
                let list = data.data || []
                let {isEdit, currentItem} = this.props
                this.setState({
                    optionsList: list
                })
                // 编辑状态下初始化
                if (isEdit) {
                    this.setState({
                        advancedConfigList: !_.isEmpty(currentItem.filebeatAdvancedConfigQueryDTOS) && currentItem.filebeatAdvancedConfigQueryDTOS.map(item => {
                            let filebeatConfigItemValueDTOS = []
                            // let configType = ''
                            list.forEach(i => {
                                if (i.id == item.configId) {
                                    filebeatConfigItemValueDTOS = i.filebeatConfigItemValueDTOS
                                    // configType = i.configType
                                }
                            })
                            return {
                                ...item,
                                // configType,
                                filebeatConfigItemValueDTOS: filebeatConfigItemValueDTOS.map(i => {
                                    return {
                                        id: i.id,
                                        text: i.configItemName,
                                        configItemId: i.configItemId,
                                        configItemValue: i.configItemValue
                                    }
                                }) || []
                            }
                        }) || [{id: 0}]
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 获取rtx
    getStaffs = () => {
        getStaffs().then(res => {
            if (res && res.data && res.data.code === 0) {
                let list = _.isArray(res.data.data) ? res.data.data : []
                this.setState({
                    allMembers: list.map(i => {
                        return {id: i.value, text: i.name}
                    })
                })
            }
        })
    }

    addVirtualGroup = () => {
        let item = {}
        let advancedConfigList = [...this.state.advancedConfigList]
        advancedConfigList.push(item);
        this.setState({advancedConfigList: advancedConfigList})
    }

    removeVirtualGroup = (index) => {
        let advancedConfigList = [...this.state.advancedConfigList]
        advancedConfigList.splice(index, 1);
        this.setState({advancedConfigList: advancedConfigList})
    }

    updateVirtualGroup = (val, type, index, item) => {

        let {optionsList} = this.state

        if (type == 'configId') {
            let advancedConfigList = [...this.state.advancedConfigList]
            optionsList.forEach(i => {
                if (i.configType == 'CONFIG_TYPE_SELECT') {
                    if (i.id == val) {
                        advancedConfigList[index].filebeatConfigItemValueDTOS = i.filebeatConfigItemValueDTOS.map(j => {
                            return {
                                ...j,
                                text: j.configItemName
                            }
                        })
                    }
                }
            })
            advancedConfigList[index].id = val
            advancedConfigList[index].configId = val
            advancedConfigList[index].configName = item.text
            // advancedConfigList[index].configValueId = 0
            // advancedConfigList[index].configValue = ''
            // advancedConfigList[index].configType = item.configType
            this.setState({advancedConfigList: advancedConfigList})
        }

        if (type == 'configValueId') {
            let advancedConfigList = [...this.state.advancedConfigList]
            advancedConfigList[index].configValueId = val
            // advancedConfigList[index].configValue = item.configItemValue
            this.setState({advancedConfigList: advancedConfigList})
        }
    }

    setParamsData = (type, value) => {
        let {params} = this.state
        let obj = {}
        obj[type] = value
        let paramsClone = Object.assign({}, params, obj)
        this.setState({
            params: paramsClone
        })
    }

    // 更新或写入IP配置信息
    submitClick = () => {
        let {params, staffsList, advancedConfigList} = this.state
        let rtx = this.props.userInfo.name
        if (!params.ip) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        params.principal = staffsList.map(i => {
            return i.id
        }).join(',')
        params.enable = params.enable == 1 ? true : false
        advancedConfigList.map(i => {
            if (!_.isEmpty(i.filebeatConfigItemValueDTOS)) {
                i.filebeatConfigItemValueVOS = i.filebeatConfigItemValueDTOS.filter(j => {
                    return j.id === i.configValueId
                })
                delete i.filebeatConfigItemValueDTOS
            }
            return i
        })
        params.filebeatAdvancedConfigVOS = advancedConfigList
        fetch(baseURL + '/sdw/filebeat/filebeat/upsertFilebeatConfig',
            {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                if (data && data.code === 100) {
                    this.props.iniList()
                    this.props.changeVisible(false)
                } else {
                    SdwMessage.error(data.message)
                    // this.props.changeVisible(false)
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    render() {
        let {
            isEdit,
            machineVisible,
            changeVisible,
        } = this.props

        let {
            params,
            staffsList,
            sholdCheckValidate,
            allMembers,
            advancedConfigList,
            optionsList
        } = this.state

        return (
            <SdwDrawer
                title={isEdit ? "编辑机器配置" : "新增机器配置"}
                visible={machineVisible}
                onIconClick={() => changeVisible(false)}
                onCancelClick={() => changeVisible(false)}
                onSureClick={this.submitClick}
            >
                <SdwDrawer.Body>
                    <div className='logAccess-win_warp'>
                        <div>
                            <span className='label required'>日志服务器IP</span>
                            <SdwInput
                                placeholder='请输入日志服务器IP'
                                value={params.ip}
                                onChange={val => this.setParamsData("ip", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '日志服务器IP不能为空'}
                            />
                        </div>
                        <div>
                            <span className='label required'>目标服务器IP</span>
                            <SdwInput
                                placeholder='请选择目标服务器IP'
                                disabled={true}
                                value={'数据平台所有服务器'}
                            />
                        </div>
                        <div>
                            <span className='label'>高级配置</span>
                            <div className='content'>
                                {
                                    advancedConfigList instanceof Array && advancedConfigList.map((item, k) => {
                                        return (
                                            <div key={k} style={{marginBottom: 10}}>
                                                <SdwSelect
                                                    placeholder='请选择'
                                                    width={'40%'}
                                                    data={optionsList.map(i => {
                                                        return {
                                                            id: i.id,
                                                            text: i.configName,
                                                            // configType: i.configType
                                                        }
                                                    })}
                                                    value={item.configId}
                                                    onChange={(val, item) => this.updateVirtualGroup(val, 'configId', k, item)}
                                                />
                                                <span style={{marginRight: 8}}></span>
                                                <SdwSelect
                                                    placeholder='请先选择'
                                                    width={'40%'}
                                                    data={item.filebeatConfigItemValueDTOS}
                                                    value={item.configValueId}
                                                    onChange={val => this.updateVirtualGroup(val, 'configValueId', k, item)}
                                                />
                                                <i onClick={() => this.addVirtualGroup(k)} className='el-icon-plus'
                                                   style={{marginLeft: 8}}></i>
                                                {k > 0 && (<i onClick={() => this.removeVirtualGroup(k)}
                                                              className='el-icon-minus'></i>)}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span className='label'>服务器负责人</span>
                            <SdwSelect
                                readOnly={false}
                                isMultipleChoice={true}
                                placeholder='请输入日志服务器负责人'
                                data={allMembers}
                                value={staffsList}
                                onChange={val => this.setState({staffsList: val})}
                            />
                        </div>
                        <div>
                            <span className='label'>所属集群</span>
                            <SdwInput
                                placeholder='请输入日志服务器所属集群'
                                value={params.cluster}
                                onChange={val => this.setParamsData("cluster", val)}
                            />
                        </div>
                        <div>
                            <span className='label'>备注</span>
                            <SdwInput
                                type='textarea'
                                placeholder='请输入备注'
                                value={params.extra}
                                onChange={val => this.setParamsData("extra", val)}
                            />
                        </div>
                        <div>
                            <span className='label required'>是否启用</span>
                            <SdwSwitch
                                value={params.enable}
                                changeValue={val => this.setParamsData('enable', val)}
                            />
                        </div>
                    </div>
                </SdwDrawer.Body>
            </SdwDrawer>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(MachineWin))
