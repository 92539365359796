import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import './index.css'

class SdwSelect extends Component {
  selectInputRef = React.createRef()
  selectInputWrapRef = React.createRef()

  static defaultProps = {
    width: null,
    height: 40,
    listMaxHeight: 300,
    placeholder: '全部',
    clearable: true,
    value: '',
    disabled: false,
    readOnly: true,
    data: [],
    sholdCheckValidate: false,
    label: '',
    isMultipleChoice: false,
    hideDefaultList: false,
    disabledObj: {
      arr: [], // 禁用数组
      key: '', // 与data备选项中的哪个字段比较
      tip: '', // 禁用提示语
    },

    onChange: _.noop,
    onInputFilter: _.noop,
    onFocus: _.noop,
  }

  state = {
    isValidatePass: true,
    showSelectList: false,
    showClearIcon: false,
    validateText: '',
    isFilter: false,
    curInputVal: '', // 模糊匹配输入值
    isShowToUp: false, // 是否向上展示
  }

  componentDidMount() {
    document.addEventListener('click', this.handleGlobalClose)
    let record = _.isArray(this.props.data) && (this.props.data.filter(i => i.id === this.props.value)[0] || {})
    let text = record.text || ''
    this.setState({
      curInputVal: text
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClose)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // 异步传入的data，有一定的延迟，初始化回显
    if ((prevProps.data.length !== this.props.data.length && this.props.data.length > 0) || (this.props.data.length > 0 && prevProps.value !== this.props.value)) {
      let record = _.isArray(this.props.data) && (this.props.data.filter(i => i.id === this.props.value)[0] || {})
      let text = record.text || ''
      // 异步传入的data，有一定的延迟，初始化回显
      if (!!text && text !== this.state.curInputVal) {
        this.setState({
          curInputVal: text
        })
      }
    }

    if (prevProps.sholdCheckValidate !== this.props.sholdCheckValidate) {
      this.validate(this.props.value)
    }

    // 一次性批量输入英文 + 英文逗号或者英文分号，进行批量选项的选中，英文必须全匹配才能选中
    if (!prevState.curInputVal && !!this.state.curInputVal && (String(this.state.curInputVal).indexOf(',') !== -1 || String(this.state.curInputVal).indexOf(';') !== -1)) {
      const valArr = String(this.state.curInputVal).split(/[,;]/).filter(i => !!i)
      // console.log('valArr: ', valArr)
      const allOptions = this.props.data
      // console.log('allOptions: ', allOptions)
      if (allOptions.length && valArr.length) {
        const selectedArr = allOptions.filter(i => valArr.some(j => j === i.id))
        // console.log('selectedArr: ', selectedArr)
        if (selectedArr.length) {
          this.props.onChange(selectedArr)
          const notExistArr = valArr.filter(val => !selectedArr.some(selectItem => selectItem.id === val))
          if (!_.isEmpty(notExistArr)) {
              // console.log('notExistArr: ', notExistArr)
              // console.log('不存在的选项：', notExistArr.join(', '))
              this.setState({
                  isValidatePass: false,
                  validateText: `${notExistArr.join(', ')} 不存在`
              })
          }
        }
      }
    }
  }

  handleGlobalClose = e => {
    const node = ReactDOM.findDOMNode(this.selectInputWrapRef.current)

    if (!node.contains(e.target)) {
      let record = _.isArray(this.props.data) && (this.props.data.filter(i => i.id === this.props.value)[0] || {})
      let text = record.text || ''
      this.setState({
        showSelectList: false,
        curInputVal: text
      })

      let filterVal = ''
      if (this.props.isMultipleChoice) {
        filterVal = []
      }
      this.props.onInputFilter(filterVal)
    }
  }

  handleClearInput = e => {
    e.preventDefault()
    this.setState({
      curInputVal: ''
    })
    let record = ''

    if (this.props.isMultipleChoice) {
      record = []
    }

    this.props.onChange(record, {})
    this.props.onInputFilter(record)
    this.validate('')
    this.selectInputRef.current.focus()
  }

  handleClick = (e) => {
    let curIsShowToUp = this.state.isShowToUp

    // 一旦向上展示，就固定向上展示
    if (!curIsShowToUp && e.clientY+270 > window.innerHeight) {
      curIsShowToUp = true
    }
    if (this.props.dialogHeight) {
      if (!curIsShowToUp && e.clientY+50 > this.props.dialogHeight) {
        curIsShowToUp = true
      }
    }

    if (this.props.disabled) return
    let { showSelectList } = this.state
    this.setState(({
      showSelectList: this.props.isMultipleChoice || !showSelectList,
      isFilter: false,
      isShowToUp: curIsShowToUp
    }))
  }

  onDelMultipleValue = item => {
    let { isMultipleChoice, value } = this.props

    if (Array.isArray(value)) {
      let curValue = _.cloneDeep(value)
      let index = curValue.findIndex(i => i.id === item.id)
      if (index !== -1) {
        curValue.splice(index, 1)
      }
      this.props.onChange(curValue)

      this.validate(curValue)
    }
    this.setState({
      showClearIcon: false,
      showSelectList: isMultipleChoice
    })
  }

  handleItemClick = (e, item) => {
    let { isMultipleChoice, value } = this.props

    if (isMultipleChoice && Array.isArray(value)) {
      let curValue = _.cloneDeep(value)
      let index = curValue.findIndex(i => i.id === item.id)
      if (index !== -1) {
        curValue.splice(index, 1)
      } else {
        curValue.push(item)
      }
      this.props.onChange(curValue)

    } else {
      this.props.onChange(item.id, item)
    }

    this.setState({
      showClearIcon: false,
      showSelectList: isMultipleChoice,
      curInputVal: isMultipleChoice ? '' : item.text
    }, () => {
      this.validate(item.id)
    })
  }

  validate = curID => {

    // 如果没有传入validateFun进行校验，直接跳过
    if (typeof this.props.validateFun !== 'function') {
      return
    }

    let res = this.props.validateFun(curID)

    // validateFun只有返回true，才会校验通过
    if (res === true) {
      this.setState({
        isValidatePass: res,
        validateText: ''
      })
    } else {
      this.setState({
        isValidatePass: false,
        validateText: res
      })
    }
  }

  handleOnInput = e => {
    let curInputVal = e.target.value
    this.props.onInputFilter(curInputVal)
    if (typeof this.props.getCurrentVal === 'function') {
      this.props.getCurrentVal(curInputVal)
    }
    this.setState({
      isFilter: true,
      showSelectList: true,
      curInputVal
    })
  }

  render() {
    let {
      placeholder,
      disabled,
      width,
      listWidth,
      listMaxHeight,
      height,
      clearable,
      readOnly,
      data,
      label,
      isMultipleChoice,
      value,
      maxOptionsLength, // 最大展示候选项数量（接口数据过多，用于优化使用）
      hideDefaultList,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius
    } = this.props

    let {
      isValidatePass,
      validateText,
      showSelectList,
      showClearIcon,
      curInputVal,
      isFilter,
      isShowToUp
    } = this.state

    let filterData = data
    if (isFilter) {
      filterData = _.isArray(data) ? data.filter(i => !_.isEmpty(i.text) && i.text.indexOf(curInputVal) !== -1) : []
    }

    if (!readOnly && _.isArray(filterData) && filterData.length && typeof maxOptionsLength === 'number' && maxOptionsLength) {
      filterData = filterData.slice(0, maxOptionsLength)
    }

    // let wrapDivClassName = isValidatePass ? 'sdw-select-input__wrap' : 'sdw-select-input__wrap sdw-error-input'
    let wrapDivClassName = `${isValidatePass ? 'sdw-select-input__wrap' : 'sdw-select-input__wrap sdw-error-input'} ${isMultipleChoice && disabled ? ' is-multiple-disabled' : ''}`

    let iconClassName = 'sdw-select__icon'
    if (clearable && showClearIcon) {

      if (isMultipleChoice && _.isArray(value) && value.length) {
        iconClassName += ' sdw-select-clearable'
      } else if (!isMultipleChoice && curInputVal) {
        iconClassName += ' sdw-select-clearable'
      }
    } else if (showSelectList) {
      iconClassName += ' sdw-select__drop-up sdw-select__drop-down'
    } else {
      iconClassName += ' sdw-select__drop-down'
    }

    let selectInputWrapClass = isValidatePass ? 'operation-label-title' : 'operation-label-title sdw-error-input'
    let inputClassName = 'sdw-select__wrap'
    if (showSelectList) {
      inputClassName += ' sdw-select__show-selected'
      selectInputWrapClass += ' sdw-select__show-selected'
      wrapDivClassName += ' sdw-select__show-selected'
    }

    return (
      <span
          className={isMultipleChoice ? 'sdw-select-input-span__wrap is-multiple-choice' : 'sdw-select-input-span__wrap'}
          style={{cursor: isMultipleChoice && disabled ? 'not-allowed' : ''}}
      >
        {
          !!label && <span className={selectInputWrapClass}>{label}</span>
        }
        <div
          ref={this.selectInputWrapRef}
          className={wrapDivClassName}
          style={{
            width: width === null ? '80%' : width,
            borderTopLeftRadius: borderTopLeftRadius,
            borderTopRightRadius: borderTopRightRadius,
            borderBottomLeftRadius: borderBottomLeftRadius,
            borderBottomRightRadius:borderBottomRightRadius
        }}
          onClick={this.handleClick}
          onMouseEnter={() => this.setState({ showClearIcon: true })}
          onMouseLeave={() => this.setState({ showClearIcon: false })}
        >
          {
            isMultipleChoice &&
            Array.isArray(value) &&
            value.map(item => (
              // <span className="sdw-select-input__multiple-value__span-wrap ellipsis" key={item.id}>
              <span
                  className={`sdw-select-input__multiple-value__span-wrap ellipsis ${isMultipleChoice && disabled ? 'is-multiple-color' : ''}`}
                  key={item.id}
              >
                <span style={{
                  ...isMultipleChoice ? {
                    width: '100%',
                    display: 'inline-block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  } : {}
                }}>{item.text}</span>
                <i
                  className='sdw-input-tags__clear-icon'
                  onClick={() => this.onDelMultipleValue(item)}
                />
              </span>
            ))
          }
          <input
            type='text'
            ref={this.selectInputRef}
            readOnly={readOnly}
            value={curInputVal || ''}
            disabled={disabled}
            placeholder={(isMultipleChoice && value.length) ? '' : placeholder}
            style={{
              width: isMultipleChoice ? '50%' : '100%',
              height,
              borderTopLeftRadius: borderTopLeftRadius,
              borderTopRightRadius: borderTopRightRadius,
              borderBottomLeftRadius: borderBottomLeftRadius,
              borderBottomRightRadius:borderBottomRightRadius
            }}
            onInput={this.handleOnInput}
            onFocus={e => this.props.onFocus(e.target.value)}
            className={inputClassName}
          />

          {
            !disabled && <i className={iconClassName} onClick={(showClearIcon && clearable) ? this.handleClearInput : null}></i>
          }
          {
            !isValidatePass && <div className='sdw-error-input__tip'>{validateText}</div>
          }
          {
            ((isMultipleChoice && hideDefaultList) ? !!this.state.curInputVal : true) &&
            <div
              className={`sdw-select-list-wrap ${showSelectList ? 'sdw-select-list-wrap-up' : ''} ${isShowToUp ? 'is-show-to-up' : ''}`}
              onMouseEnter={() => this.setState({ showClearIcon: false })}
              style={{
                width: listWidth ? listWidth : '100%',
                maxHeight: listMaxHeight
              }} >
              {
                showSelectList &&
                !!filterData.length ?
                filterData.map((item, index) => {
                  let isSelected = !isMultipleChoice ? this.state.curInputVal === item.text :
                  (_.isArray(this.props.value) ? this.props.value.some(i => i.id === item.id) : false)

                  let { arr, key, tip } = this.props.disabledObj
                  let isDisabled = arr.some(i => i === item[key])
                  return (
                    item.dividLine  ?
                    <div key={index} className="sdw-select-list__divid-line" /> :
                    ((item.disabled || isDisabled) ?
                    <div title={tip || item.text} key={item.id} className="sdw-select-list__disabled-item">{item.text}</div> :
                    <div
                      key={item.id}
                      style={{
                        fontSize:"14px",
                        color: item.isSelected == 1 ?"#CCCCCC" :(isSelected ? '#265cf0' : ''),
                        fontWeight: item.isSelected == 1 ? '400':(isSelected ? '700' : ''),
                      }}
                      className='sdw-select-list__item'
                      title={item.text}
                      onClick={e => this.handleItemClick(e, item)}>{item.text}
                    </div>)
                  )})
                :
                <div className="sdw-select-list__empty-item">暂无匹配数据</div>
              }
            </div>
          }
        </div>
      </span>
    )
  }
}

SdwSelect.propTypes = {
  data: PropTypes.array,
  maxOptionsLength: PropTypes.number, // 最大展示候选项数量（接口数据过多，用于优化使用）
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listMaxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  clearable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  disabled: PropTypes.bool,
  disabledObj: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onInputFilter: PropTypes.func, // 会返回输入的值给父组件使用
  onFocus: PropTypes.func, // 会返回输入的值给父组件使用
  validateFun: PropTypes.func,
  sholdCheckValidate: PropTypes.bool, // true: 手动触发必填项的检查
  isMultipleChoice: PropTypes.bool, // true: 支持'多选'
  hideDefaultList: PropTypes.bool, // true: 当curInputVal为空时，不展示下拉选项
}

export default SdwSelect
