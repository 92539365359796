import React, { PureComponent } from 'react'
import SdwTable from '../../../common/Table'
import SdwPagenation from '../../../common/Pagenation'
import { Button } from 'element-react'
import './index.css'
import { getPage, getTablecfgList, getModifierList } from '@services/modifier'
import EditData from './components/EditData'
import DialogShowData from './components/DialogShowData'
import SdwBreadCrumbs from '../../../common/BreadCrumbs'
import SdwSelect from '../../../common/Select'
import SdwInput from '../../../common/Input'
import _ from 'lodash'
import SdwLoading from '@common/Loadding'

class Atom extends PureComponent {
  state = {
    dataList: [],
    search: {
      filter_value: { value: "", operator: "like", type: "both" },
      source_id: { value: "", operator: "=" },
      modifier_name: { value: "", operator: "like" },
      modify_user: { value: "", operator: "like" }
    },
    pagination: {
      total: 0,
      per_page: 10,
      pages: 0,
      current_page: 0,
    },
    topicDic: {},
    topicServerDic: {},
    curTitle: '',
    curRecord: {},
    visible: false,
    tableList: [],
    dialogVisible: false,
    curShowDataRecord: {},
    tableShowData: false
  }

  // 初始化
  componentDidMount() {
    this.getPageData()
    this.getTablecfgList()
  }

  // 获取所有的表
  getTablecfgList = () => {
    let that = this
    getTablecfgList({ limit: 10000, page: 0, search: { enable: { value: '1', operator: "=" } } }).then(res => {
      let tableList = res.data.data.items || []
      that.setState({tableList: tableList})
    })
  }

  // 获取分页数据
  getPageData = () => {
    let that = this
    var params = {}
    params.page = this.state.pagination.current_page || 0
    params.limit = this.state.pagination.per_page
    params.search = {}

    for (const key in this.state.search) {
      const dval = this.state.search[key]
      if (dval.value) {
        params.search[key] = dval
      }
    }

    that.setState({tableShowData: false})
    getPage(params).then((res) => {
      that.setState({tableShowData: true})

      let items = res.data.data.items

      if(!items) {
        items = []
      }

      for (let i=0; i<items.length; i++) {
        let topicId = items[i].topic
        let topicName = topicId
        let enableName = "无效"
        if(that.state.topicDic.hasOwnProperty(topicId)) {
          topicName = that.state.topicDic[topicId].label
        }
        if(items[i].enable == 1) {
          enableName = "有效"
        }
        items[i]["topicName"] = topicName
        items[i]["enableName"] = enableName
      }

      let pagination = that.state.pagination
      pagination.total = res.data.data.total
      pagination.per_page = res.data.data.per_page
      pagination.pages = res.data.data.pages
      pagination.current_page = res.data.data.current_page
      that.setState({
        dataList: [...items],
        pagination: {...pagination},
      })
    })
  }

  // 获取表标题
  getcolumns = () => {
    let self = this
    const columns = [
      {
          title: "ID",
          dataIndex: "id",
          width: 44
      },
      {
          title: "修饰词名称",
          dataIndex: "modifier_name",
          width: 228
      },
      {
          title: "来源表",
          dataIndex: "source_name",
          width: 228
      },
      {
          title: "过滤条件",
          dataIndex: "filter_value",
          width: 228
      },
      {
          title: "修改人",
          dataIndex: "modify_user",
          width: 76
      },
      {
        //title: "状态",
        //dataIndex: "enableName",
        title: "状态",
        width: 40,
        render: function (data, record) {
          return (
            <>
              {
                _.isEqual(typeof data.enable, 'number') && <span className={
                  data.enable ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                }></span>
              }
              <span>{_.isEqual(typeof data.enable, 'number') ?
                (data.enable ? '有效' : '无效') : '-'}</span>
            </>
          )
        }
      },
      {
          title: "更新时间",
          dataIndex: "modify_time",
          width: 146
      },
      {
          title: "操作",
          width: 100,
          render: (data, record) => {
              return (
                  <div className="editorMenu">
                    <span style={{'color': '#517df3', 'cursor': 'pointer', marginRight: '10px'}} onClick={ () => { self.onClickGetOneData(data, self) } }>编辑</span>
                    <span style={{'color': '#517df3', 'cursor': 'pointer'}} onClick={ () => { self.onClickShowData(data, self) } }>预览数据</span>
                  </div>
              )
          },
      }
    ]
    return columns
  }

  // 展示预览数据
  onClickShowData = (data, e) => {
    this.setState({ dialogVisible: true, curShowDataRecord: data })
  }

  // 获取编辑的行记录
  onClickGetOneData = (data, e) => {
    const search = {
      id: { value: data.id, operator: "=" }
    };
    getModifierList({ search: search }).then(res => {
      if (res.data.code == 0) {
        let item = res.data.data.items[0] || [];
        item.enable = item.enable.toString()
        this.setState({
          curRecord: item,
          visible: true,
          curTitle: '编辑修饰词'
        })
      }
    });
  }

  // 获取当前页码
  getClickPage = (pageNum) => {
    this.state.pagination.current_page = pageNum
    this.getPageData()
  }

  // 获取当前每页总条数
  pageCountChange = (per_page) => {
    this.state.pagination.per_page = per_page
    this.getPageData()
  }

  // 表格数据双向绑定
  SeachHandleChange(key, value) {
    this.state.pagination.current_page = 1

    if(key == "modifier_name") {
      this.state.search.modifier_name.value = value;
    }

    if(key == "source_id") {
      this.state.search.source_id.value = value;
      this.getPageData()
    }

    if(key == "modify_user") {
      this.state.search.modify_user.value = value;
    }

    this.forceUpdate();
  }

  addOrEdit = (record = {}) => {
    this.setState({
      visible: true,
      curTitle: record.title,
      curRecord: record,
    })
  }

  onSureClick = () => {
    this.setState({
      visible: false,
    })
    this.getPageData()
  }

  closeDialog = () => {
    this.setState({dialogVisible: false})
  }

  render () {
    var { search, pagination, curTitle, visible, curRecord, tableList, dialogVisible, curShowDataRecord, tableShowData } = this.state;
    return (
      <div className="machFactory Modifier">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/atom'
            }, {
              title: '修饰词管理'
            }
          ]}
        />

        <div className="page-mainbody">
          {/** 搜索区域 */}
          <div className="page-search">
            <div className="input-wrap">

              <span style={{ marginRight: '8px' }}>
                <SdwInput
                  width={180}
                  label="修饰词名称"
                  value={search.modifier_name.value}
                  onBlur={this.getPageData}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      modifier_name: Object.assign({}, search.modifier_name, {
                        value: val
                      })
                    })
                  })}
                />
              </span>

              <span style={{ marginRight: '8px' }}>
                <SdwSelect
                  placeholder="全部"
                  width={180}
                  listWidth={520}
                  label="来源表"
                  readOnly={false}
                  value={search.source_id.value}
                  data={tableList.map(el => {
                    return {
                      id: el.id,
                      text: el.data_set_chinese + "（" + el.database_name + "." + el.table_name + "）"
                    }
                  })}
                  onChange={val => {
                    this.setState({
                      pagination:Object.assign({}, pagination, {
                        current_page:1
                      }),
                      search: Object.assign({}, search, {
                        source_id: Object.assign({}, search.source_id, {
                          value: val
                        })
                      })
                    }, this.getPageData)
                  }}
                />
              </span>

              <span style={{ marginRight: '8px' }}>
                <SdwInput
                  width={180}
                  label="创建人"
                  placeholder="请输入RTX名字"
                  value={search.modify_user.value}
                  onBlur={this.getPageData}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onChange={val => this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      modify_user: Object.assign({}, search.modify_user, {
                        value: val
                      })
                    })
                  })}
                />
              </span>

              <div className="btn-group">
                <Button
                  type="primary"
                  icon='plus'
                  onClick={ () => this.addOrEdit({ title: '新增修饰词', curRecord:{} }) }
                >新增</Button>
              </div>
            </div>

          </div>

          {/** 分页显示数据区域 */}
          <div className="tableShowData">
            <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
              <SdwTable
                tdWhiteSpace={true}
                columns={this.getcolumns()}
                dataSource={this.state.dataList}
              />
            </SdwLoading>

            <SdwPagenation
              total={pagination.total}
              currentPage={pagination.current_page}
              pageCount={pagination.per_page}
              getClickPage={this.getClickPage}
              pageCountChange={this.pageCountChange}
            />
          </div>
        </div>

        {/** 编辑弹窗 */}
        <EditData
          title={curTitle}
          visible={visible}
          record={curRecord}
          tableList={tableList}
          onIconClick={() => this.setState({ visible: false })}
          onCancelClick={() => this.setState({ visible: false })}
          onSureClick={() => {
            this.setState({ visible: false })
            this.onSureClick()
          }}
        />

        {/** 预览数据弹窗 */}
        <DialogShowData
          dialogVisible={dialogVisible}
          record={curShowDataRecord}
          closeDialog={this.closeDialog}
        />
      </div>
    )
  }
}

export default Atom
