// @file 指标配置，前端分页
import React, {useState, useMemo} from 'react'
import _ from 'lodash'
import Table from '@common/Table'
import Select from '@common/Select'
import Input from '@common/Input'
import Pagenation from '@common/Pagenation'
import {Button, Dropdown} from 'element-react'
import {hasPriv} from '@components/FrontendAuth/AuthMap'
import ApiSettingWin from '@pages/MachFactory/Api/ApiSettingWin'
import './style/selectedAttributes.css'

export default function SelectedAttributes(props) {

    const {
        curClickTreeValue,
        columns,
        dataSource,
        handleEdit,
        reRunVisible, // 重跑弹窗
        regularRerunVisible, // 定时重跑弹窗
    } = props

    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(15)
    const [searchValue, setSearchValue] = useState({field: "a.id", value: ""})
    const [settingDialogVisible, setSettingDialogVisible] = useState(false)

    const curDataSource = useMemo(() => {
        if (!_.isArray(dataSource) || !dataSource.length) return []
        const start = (currentPage - 1) * pageCount
        const end = currentPage * pageCount
        return dataSource.slice(start, end)
    }, [currentPage, pageCount, dataSource])

    const Total = _.isArray(dataSource) ? dataSource.length : 0

    function changeTitle(command) {
        if (command == 'associatedField') {
            if (Object.keys(curClickTreeValue).length > 0) {
                setSettingDialogVisible(true)
            }
        } else if (command == 'regularRerun') {
            regularRerunVisible(curClickTreeValue)
        }
    }

    return (
        <div style={{padding: '10px'}} className='selected-attributes-body'>
            <div style={{lineHeight: '44px', display: "flex", marginBottom: "20px"}}>
                <div style={{"flex": "1"}}>
                    {/*{`${curClickTreeValue.template_name ? curClickTreeValue.template_name : ""}（id:${curClickTreeValue.id ? curClickTreeValue.id : "0"}）`}*/}
                    <Select
                        width={120}
                        disabled={Object.keys(curClickTreeValue).length == 0 ? true : false}
                        borderTopRightRadius={0}
                        borderBottomRightRadius={0}
                        value={searchValue.field}
                        data={[{
                            id: 'a.id',
                            text: '字段ID'
                        }, {
                            id: 'a.target_id',
                            text: '指标ID'
                        }, {
                            id: 'b.target_name',
                            text: '指标英文名'
                        }, {
                            id: 'b.target_name_cn',
                            text: '指标中文名'
                        }, {
                            id: 'a.short_name',
                            text: '指标简称'
                        }]}
                        onChange={i => {
                            setSearchValue(_.assign({}, searchValue, {field: i, value: ""}))
                            props.setSearch(i, "")
                        }}
                    />
                    <Input
                        width={260}
                        value={searchValue.value}
                        placeholder={searchValue.field == 'a.id' || searchValue.field == 'a.target_id' ? '使用,号分隔' : '请输入'}
                        disabled={Object.keys(curClickTreeValue).length == 0 ? true : false}
                        borderTopLeftRadius={0}
                        borderBottomLeftRadius={0}
                        onChange={val => {
                            setSearchValue(_.assign({}, searchValue, {value: val}))
                            props.setSearch(searchValue.field, val)
                        }}
                    />
                </div>

                <div>
                    <Dropdown
                        trigger='click'
                        menu={(
                            <Dropdown.Menu>
                                <Dropdown.Item command='associatedField'>关联字段</Dropdown.Item>
                                <Dropdown.Item command='regularRerun'>指标定时重跑</Dropdown.Item>
                            </Dropdown.Menu>
                        )}
                        onCommand={command => changeTitle(command)}
                    >
                        <Button
                            type='text'
                            disabled={Object.keys(curClickTreeValue).length == 0 ? true : false}
                        >更多设置<i className="el-icon-arrow-down el-icon--right"></i></Button>
                    </Dropdown>
                    {/*<Button
                        disabled={Object.keys(curClickTreeValue).length == 0 ? true : false}
                        icon='el-icon-edit'
                        onClick={() => {
                            if (Object.keys(curClickTreeValue).length > 0) {
                                setSettingDialogVisible(true)
                            }
                        }}
                    >字段设置</Button>*/}
                    {
                        hasPriv('tableApiRunTargetResult') &&
                        <button
                            className='rerun'
                            disabled={Object.keys(curClickTreeValue).length == 0 ? true : false}
                            onClick={() => reRunVisible(curClickTreeValue)}
                        >重跑</button>
                    }

                    <button
                        className='edit'
                        disabled={Object.keys(curClickTreeValue).length == 0 ? true : false}
                        onClick={() => handleEdit(curClickTreeValue)}
                    >编辑指标</button>

                </div>
            </div>

            <Table
                tdWhiteSpace={true}
                columns={columns()}
                dataSource={curDataSource}
            />

            <Pagenation
                total={Total}
                currentPage={currentPage}
                pageCount={pageCount}
                // pageCountList={ [15, 30, 40, 50] }
                getClickPage={val => setCurrentPage(val)}
                pageCountChange={val => setPageCount(val)}
            />

            {settingDialogVisible && <ApiSettingWin
                dialogVisible={settingDialogVisible}
                currentData={curClickTreeValue}
                changeDialogVisible={setSettingDialogVisible}
            />}
        </div>
    )
}
