// 业务配置
import { request } from '../utils/request'

//业务配置 -> 指标运营配置 indicatorOperationConfiguration

export function getIndicatorConfItems (params) {
  return request({ url: '/operation/indicatorOperationConf/sdwGetIndicatorConfItems', method: 'get', params: params })
}

export function insertIndicatorConf (params) {
  return request({ url: '/operation/indicatorOperationConf/insertIndicatorConf', method: 'post', data: params })
}

export function updateIndicatorConf (params) {
  return request({ url: '/operation/indicatorOperationConf/updateIndicatorConf', method: 'post', data: params })
}

export function getIndicators (params) {
  return request({ url: '/operation/indicatorOperationConf/getAllIndicators', method: 'get', params: params })
}

export function getTargets (params) {
  return request({ url: '/operation/indicatorOperationConf/getTargets', method: 'get', params: params })
}

export function deleteTarget (params) {
  return request({ url: '/operation/indicatorOperationConf/delIndicatorConf', method: 'post', data: params })
}

export function getArchiveCodeConfItems (params) {
  return request({ url: '/operation/indicatorOperationConf/getArchiveCodeConfItems', method: 'get', params: params })
}

export function getArchiveCodeProductIdsSelect (params) {
  return request({ url: '/operation/indicatorOperationConf/getArchiveCodeProductIdsSelect', method: 'get', params: params })
}

export function getArchiveCodeArchivesSelect (params) {
  return request({ url: '/operation/indicatorOperationConf/getArchiveCodeArchivesSelect', method: 'get', params: params })
}

export function getArchiveCodeL4Query (params) {
  return request({ url: '/operation/indicatorOperationConf/getArchiveCodeL4Query', method: 'get', params: params })
}

export function getArchiveCodeSelect (params) {
  return request({ url: '/operation/indicatorOperationConf/getArchiveCodeSelect', method: 'get', params: params })
}

export function getArchiveCodeL4idSelect (params) {
  return request({ url: '/operation/indicatorOperationConf/getArchiveCodeL4idSelect', method: 'get', params: params })
}

export function insertArchiveCodeConf (params) {
  return request({ url: '/operation/indicatorOperationConf/insertArchiveCodeConf', method: 'post', data: params })
}

export function updateArchiveCodeConf (params) {
  return request({ url: '/operation/indicatorOperationConf/updateArchiveCodeConf', method: 'post', data: params })
}

export function getItemsSelect (params) {
  return request({ url: '/operation/formBusiness/getItemsSelect', method: 'get', params: params })
}
export function queryFormsConfPage (params) {
  return request({ url: '/operation/formBusiness/queryFormsConfPage', method: 'post', data: params })
}
export function getFormById (params) {
  return request({ url: '/operation/formBusiness/getFormById', method: 'get', params: params })
}
export function saveFormConf (params) {
  return request({ url: '/operation/formBusiness/saveFormConf', method: 'post', data: params })
}
