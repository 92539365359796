import { request } from '../utils/request'
import { ajaxPost } from "./AjaxApi";
import { requestOptions } from '../config/config'

const baseURL = requestOptions.basename

// 获取主题列表
export const getTopicBusiness = (params = {}) => {
  return request({ url: '/dataset/common/getTopicBusiness', method: 'get', data: params })
}

// 获取所有的表
export const getTablecfgList = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgList', method: 'get', params: params })
}

// 获取该表的所有的字段
export const getTablecfgFields = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgFields', method: 'get', params: params })
}

// 获取该表的所有的字段
export const getTableFields = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgDetail', method: 'get', params: params })
}

// 获取分页数据
export const getPage = (params = {}) => {
  return request({ url: '/dataset/process/dimensionList', method: 'get', params: params })
}

// 获取所有的计算方式
export const dimenGetOptions = (params = {}) => {
  return request({ url: '/dataset/process/dimensionAllList', method: 'get', data: params })
}

// 获取所有的数据标准
export const getStandardSelects = (params = {}) => {
  return request({ url: '/metadata/tableApi/getStandardSelects', method: 'get', data: params })
}

/**
 * 新增或更新维度
 * @param {object} params
 * @param {func} callback
 */
export const storeOrUpdateAtomTarget = (params = {}) => {
  return request({ url: '/dataset/process/addOrUpdateDimen', method: 'get', params: params })
}


// 获取指定维度的数据
export const getDimenById = (params = {}) => {
  return request({ url: '/dataset/process/dimensionOne', method: 'get', params: params })
}

// 获取映射搜索的选项数据
export const dimMappingAllList = (params = {}) => {
  return request({ url: '/dataset/process/dimMappingAllList', method: 'get', params: params })
}

// 获取映射分页数据
export const getPageMap = (params = {}) => {
  return request({ url: '/dataset/process/dimMappingList', method: 'get', params: params })
}

// 获取所有sql来源表选项列表
export const getPropertySourceSqlTables = (params = {}) => {
  return request({ url: '/dataset/process/getPropertySourceSqlTables', method: 'get', params: params })
}

// 新增或更新映射 
export const addOrUpdateDimenMap = (params = {}) => {
  return request({ url: '/dataset/process/addOrUpdateDimenMap', method: 'get', params: params })
}

// 获取指定映射数据 
export const getDimenMapById = (params = {}) => {
  return request({ url: '/dataset/process/dimMappingOne', method: 'get', params: params })
}