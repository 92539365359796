import produce from 'immer'

const state = {
  labelRuleConfig: {}, // 标签规则配置项
  labelRuleConfigID: '', // 标签规则配置id
  example: '', // 标签规则配置--关联日志--example
}

const updateLabelRuleConfig = produce((state, payload) => {
  state.labelRuleConfig = payload
})

const updateLabelRuleConfigID = produce((state, payload) => {
  state.labelRuleConfigID = payload
})

const updateExample = produce((state, payload) => {
  state.example = payload
})

const reducers = {
  updateLabelRuleConfig,
  updateLabelRuleConfigID,
  updateExample
}

export default { state, reducers }
