import _ from "lodash"

const TOP_MAP = {
  normal: 1,
  top: 2
}

const TOP_MAP_TEXT = {
  [TOP_MAP.normal]: '普通指标',
  [TOP_MAP.top]: 'TOP型指标'
}
const EDIT_TEXT = {
  0: '未编辑',
  1: '已编辑'
}

export {
  EDIT_TEXT,
  TOP_MAP,
  TOP_MAP_TEXT
}

export function formatToIdMap(list) {
  let objMap = {}
  if (!_.isArray(list) || !list.length) return objMap

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    let { dimensionTableMatchingFelds, addField } = item
    if (_.isEmpty(dimensionTableMatchingFelds) || !_.isArray(dimensionTableMatchingFelds.ids) || dimensionTableMatchingFelds.ids.length !== 2) break
    let relateConfig = {
      relate_table_id: dimensionTableMatchingFelds.ids[0],
      relate_field: dimensionTableMatchingFelds.ids[1],
      app_field: item.app_field,
      filter: item.filter
    }

    if (_.isArray(addField) && addField.length) {
      addField.forEach(f => {
        let { id, field_name, field_name_ch } = f
        objMap[id] = {
          id,
          target_name_en: field_name,
          target_name_cn: field_name_ch,
          ...relateConfig
        }
      })
    }
  }


  return objMap
}
