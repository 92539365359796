import { useRef, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import Drawer from '@common/Drawer'
import Input from '@common/Input'
import Select from '@common/Select'
import Switch from '@common/Switch'
import Form from '@common/Form_D'
import Message from '@common/Message'
import SelectUsers from '@components/SelectUsers'
import DateTimePicker from '@common/DateTimePicker'
import { setMonitorSetting, getMonitorCfg, getMonitorSubscribe } from '@services/monitorSetting'

function notEmpty(val) {
  return !!val
}

function maxLength(val) {
  return String(val).length <= 20
}

const rules = {
  monitor_type: [
    { func: notEmpty, message: '请选择类型' }
  ],
  template_id: [
    { func: notEmpty, message: '请输入模版ID' }
  ],
  title: [
    { func: maxLength, message: '允许输入最大长度20' },
    { func: notEmpty, message: '请输入标题' }
  ],
  schedule_time: [
    { func: notEmpty, message: '请选择推送开始时间' }
  ],
  repeat_cycle: [
    { func: notEmpty, message: '请选择重复频率' }
  ],
  period_format: [
    { func: notEmpty, message: '请输入报告日期格式' }
  ]
}

export default function EditWin(props) {
  const formRef = useRef(null)
  const { data, showWin, setShowWin, updatePage, allMembers } = props
  const CurID = data.id

  const [formData, setFormData] = useState({})
  const [repeatCycleMap, setRepeatCycleMap] = useState([])
  const [cycleArr, setCycleArr] = useState([])  

  useEffect(() => {
    getMonitorCfg().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const repeatCycleMap = _.get(res, 'data.data.repeatCycleMap')
        if (_.isArray(repeatCycleMap)) setRepeatCycleMap(repeatCycleMap)
      }
    })
  }, [])

  useEffect(() => {
    let templateID = _.get(data, 'template_id')
    let record = !!templateID ? _.cloneDeep(data) : {enable: 0}
    setFormData(record)

    // 数据回显，需要查询的接口
    if (!!templateID) {
      getMonitorSubscribe({
        templateID
      }).then(res => {
        const code = _.get(res, 'data.code')
        if (code === 0) {

          const memberKey = {} // 存放下拉数组的（重复频率3个选人的key）
          const repeatCycleMap = _.get(res, 'data.data.data.source_list.repeatCycleMap') || _.get(res, 'data.data.repeatCycleMap')
          const repeat_cycle = _.get(res, 'data.data.job.repeat_cycle')
          if (!_.isEmpty(repeatCycleMap) && _.isArray(repeatCycleMap) && !!repeat_cycle) {
            const arr = repeatCycleMap.filter(i => i.value === repeat_cycle)
            const children = _.get(arr, '[0].children')
            if (_.isArray(children) && children.length) {
              setCycleArr(children)
              children.forEach(i => {
                if (!memberKey.hasOwnProperty(i.send_cycle)) memberKey[i.send_cycle] = []
              })
            }
          }

          const job = _.get(res, 'data.data.job')
          if (!_.isEmpty(job)) {
            let cloneJob = _.cloneDeep(job)
            cloneJob = _.assign({}, cloneJob, memberKey)

            const info_list = _.get(res, 'data.data.data.info_list')
            if (_.isArray(info_list)) {
              info_list.forEach(i => {
                const key = i.send_cycle
                if (cloneJob.hasOwnProperty(key)) {
                  cloneJob[key].push({
                    id: i.rtx_name,
                    text: i.rtx_name
                  })
                }
              })
            }

            setFormData(cloneJob)
          }
        } else {
          const msg = _.get(res, 'data.msg') || '数据详情获取失败！'
          Message.error(msg)
        }
      }).catch(err => Message.error('数据详情接口异常' + err))
    }
  }, [data])

  function setFormDataProps(key, val) {
    let cloneFormData = _.cloneDeep(formData)
    cloneFormData[key] = val
    setFormData(cloneFormData)
  }

  function delOldParams(old, val) {
    let cloneFormData = _.cloneDeep(formData)
    cloneFormData['repeat_cycle'] = val
    _.isArray(old) && old.forEach(i => {
      const key = i.send_cycle
      if (cloneFormData.hasOwnProperty(key)) delete cloneFormData[key]
    })
    setFormData(cloneFormData)
  }
  
  function handleOnChange(val, item) {
    setCycleArr(r => {
      delOldParams(r, val)
      return item && item.children || []
    })
  }

  function handleSubmit()  {
    formRef.current.validate(valid => {
      // console.log('*******CurID: ', CurID, formData);
      if (valid) {
        setMonitorSetting({
          data: _.assign({}, formData, {
            ...CurID ? {
              id: CurID
            } : {}
          })
        }).then(res => {
          const code = _.get(res, 'data.code')
          if (code === 0) {
            let msg = CurID ? '编辑配置成功' : '添加配置成功'
            Message.success(msg)
            setShowWin(false)
            updatePage()
          } else {
            let msg = CurID ? '编辑配置失败' : '添加配置失败'
            Message.error(msg)
          }
        }).catch(() => Message.error("接口异常"))
      }
    })
  }

  // 这里已经选择过的人需要过滤掉，不允许重复选择
  const curAllMembers = useMemo(() => {
    let arr = allMembers
    let selectedMember = [] // 存储已选用户id（英文名）

    if (_.isArray(cycleArr) && cycleArr.length) {
      cycleArr.forEach(item => {
        const key = item.send_cycle
        if (_.isArray(formData[key])) {
          selectedMember = selectedMember.concat(formData[key].map(i => i.id))
        }
      })
    }
    arr = arr.filter(i => !selectedMember.includes(i.id))
    return arr
  }, [allMembers, formData])

  return (
    <Drawer
      title={CurID ? '编辑监控' : '新增监控'}
      visible={showWin}
      onIconClick={() => setShowWin(false)}
      onCancelClick={() => setShowWin(false)}
      onSureClick={handleSubmit}
    >
      <Drawer.Body>
        <Form 
          labelWidth={'110px'} 
          labelPosition={'left'} 
          ref={formRef} 
          model={formData} 
          rules={rules}
        >
          <Form.FormItem label="类型" prop="monitor_type" required>
            <Select
              placeholder="请选择类型"
              disabled={CurID}
              width='100%'
              value={formData.monitor_type}
              onChange={val => setFormDataProps('monitor_type', val)}
              data={[{
                id: 1,
                text: '基础服务监控'
              }, {
                id: 2,
                text: '服务风险监控'
              }]}
            />
          </Form.FormItem>
          <Form.FormItem label="模版ID" prop="template_id" required>
            <Input
              placeholder="请输入模版ID"
              width='100%'
              disabled={CurID} // 编辑禁用
              value={formData.template_id}
              onChange={val => setFormDataProps('template_id', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="标题" prop="title" required>
            <Input
              placeholder="请输入标题"
              disabled={CurID}
              width='100%'
              value={formData.title}
              onChange={val => setFormDataProps('title', val)}
            />
          </Form.FormItem>
          <div className='drawer__wrap-div' style={{ marginBottom: 0 }}>
            <div style={{ display: 'inline-flex', width: '40%' }}>
              <Form.FormItem label="推送开始时间" prop="schedule_time" required>
                <DateTimePicker
                  value={formData.schedule_time}
                  changeValue={val => setFormDataProps('schedule_time', val)}
                />
              </Form.FormItem>
            </div>
            <div style={{ display: 'inline-flex', width: '40%', paddingLeft: 26 }}>
              <Form.FormItem label="重复频率" prop="repeat_cycle" required>
                <Select
                  placeholder="请选择重复频率"
                  disabled={CurID}
                  width='100%'
                  value={formData.repeat_cycle}
                  onChange={handleOnChange}
                  data={_.isArray(repeatCycleMap) ? repeatCycleMap.map(i => ({
                    id: i.value,
                    text: i.label,
                    children: i.children
                  })) : []}
                />
              </Form.FormItem>
            </div>
          </div>
          <Form.FormItem label="报告日期格式" prop="period_format" required>
            <Input
              placeholder="请输入报告日期格式"
              width='100%'
              value={formData.period_format}
              onChange={val => setFormDataProps('period_format', val)}
            />
          </Form.FormItem>
          {
            _.isArray(cycleArr) &&
            cycleArr.length > 0 &&
            cycleArr.map(item => (
              <Form.FormItem key={item.send_cycle} label={item.name} prop={item.send_cycle}>
                <SelectUsers
                  placeholder="请输入"
                  width='100%'
                  oriAllMembers={curAllMembers}
                  value={formData[item.send_cycle]}
                  onChange={val => setFormDataProps(item.send_cycle, val)}
                />
              </Form.FormItem>
            ))
          }
          <Form.FormItem label="是否可用">
            <Switch
              value={formData.enable}
              changeValue={val => setFormDataProps('enable', val)}
            />
          </Form.FormItem>
        </Form>
      </Drawer.Body>
    </Drawer>
  )
}