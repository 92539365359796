import { useState, useEffect } from 'react'
import SdwDialog from '@common/Dialog'
import SdwTable from '@common/Table'
import SdwButton from '@common/Button'
import { DateRangePicker } from 'element-react'
import { getTargetPreview } from '@services/dataAccess'
import { formatTime } from '@utils/formatTime'
import SdwMessage from '@common/Message'
import SdwLoading from '@common/Loadding/index'

import './style/dataReview.css'

export default function SubmitDataWin (props) {

  const [datePicker, setDatePicker] = useState()
  const [dataSource, setDataSource] = useState([])
  const [columns, setColumns] = useState([])
  const [isLoadding, setIsLoadding] = useState(true)

  useEffect(() => {
    if (!props.task_id) return
    
    // reset
    setColumns([])
    setDataSource([])
    setIsLoadding(true)

    let today = new Date()
    let endTime = formatTime(+new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} 00:00:00`) + 24*60*60*1000, 0)
    let startTime = formatTime(+new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} 00:00:00`) - 6*24*60*60*1000, 0)

    setDatePicker([new Date(startTime), new Date(endTime)])

    doGetTargetPreview({
      startTime: startTime,
      endTime: endTime
    })
    
  }, [props.task_id])

  function doGetTargetPreview(params) {
    getTargetPreview({
      task_id: props.task_id,
      start_time: params.startTime,
      end_time: params.endTime
    }).then(res => {
      setIsLoadding(false)
      if (res && res.data && res.data.code === 0) {
        let curObj = res.data.data || {}
        let curArr = Array.isArray(curObj.data) ? curObj.data : []

        if (curArr.length) {
          let columnArr = Object.keys(curArr[0]).map(key => {
            return {
              title: key,
              dataIndex: key,
              showTip: true
            }
          })
          setColumns(columnArr)
          setDataSource(curArr)
        }
      } else {
        let msg = res && res.data && res.data.msg || '请求数据失败'
        SdwMessage.error(msg)
      }
    })
      .catch(error => {
        SdwMessage.error('接口请求有误：' + error)
      })
  }

  function getcolumns () {
    return columns
  }

  return (
    <>
      <SdwDialog
        width={1200}
        title='预览数据'
        hideSureButton={true}
        hideCancelButton={true}
        visible={props.dataReviewVisible}
        changeVisible={() => {
          if (typeof props.changeDataReviewVisible !== 'function') return
          props.changeDataReviewVisible()
        }}
        onCancelClick={() => props.changeDataReviewVisible(false)}
        onSureClick={() => props.changeDataReviewVisible(false)}
      >
        <SdwLoading loadding={isLoadding}>
          <div style={{ textAlign: 'left' }}>
            <DateRangePicker
              value={datePicker}
              isReadOnly={true}
              rangeSeparator=" 至 "
              placeholder="选择日期范围"
              format="yyyy-MM-dd"
              align="right"
              disabledDate={time=>time.getTime() > Date.now() + 24*60*60*1000}
              onChange={date => setDatePicker(date)}
            />
            <span style={{ marginLeft: 8 }} />
            <SdwButton
              text="查询"
              type="cancel"
              iconClass="sdw-data-review-search-btn"
              onClick={() => {
                doGetTargetPreview({
                  startTime: formatTime(+new Date(datePicker[0]), 0),
                  endTime: formatTime(+new Date(datePicker[1]), 0)
                })
              }}
            />

            <div style={{ marginTop: 16 }}>
              <SdwTable
                columns={getcolumns()}
                tdWhiteSpace={ true }
                isUserSelect="text"
                dataSource={dataSource}
              />
            </div>
          </div>
        </SdwLoading>
      </SdwDialog>
    </>
  )
}