import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import Table from '@common/Table'
import Pagenation from '@common/Pagenation'
import {searchLogs} from '@api/labelProcess'

import '../index.css'

function DetailPage(prop) {

    let labelDetail = prop.labelDetail
    console.log("labelDetail===========", labelDetail)

    const [logDetail, setLogDetail] = useState({})
    const [searchParams, setSearchParams] = useState({
        limit: 10,
        page: 1
    })

    useEffect(() => {
        let params = {
            "id": labelDetail.id,   // 标签id
            ...searchParams,
        }
        searchLogs(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let logDetail = res.data.data || {}
                console.log("logDetail------------------------", logDetail)
                setLogDetail(logDetail)
            }
        })
    }, [searchParams])

    function getColumns() {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 18
            },
            {
                title: '标签名称',
                dataIndex: 'label_name',
                width: 100
            },
            {
                title: '入库量',
                dataIndex: 'write_count',
                width: 30
            },
            {
                title: '入库时间',
                dataIndex: 'create_time',
                width: 80
            },
            {
                title: '入库结果',
                width: 30,
                render: data => {
                    let arr = {
                        "0": "未开始",
                        "1": "进行中",
                        "2": "已完成",
                        "3": "失败",
                        "4": "已取消",
                    }
                    let msg = arr[data.status]
                    return <span>{msg}</span>
                }
            },
            {
                title: '入库日志',
                dataIndex: 'log_detail',
                width: 300
            }
        ]
    }

    function updateSearchParams(type, val) {
        setSearchParams(r => _.assign({}, r, {
            [type]: val
        }))
    }

    return (
        <div style={{padding: 16}}>
            <div className='page-detail'>
                <span className='detail-text'>入库总量：{labelDetail.result_user_total}</span>
                <span className={
                    Boolean(+labelDetail.enable) ? 'enable__is-effect' : 'enable__no-effect'
                }></span>
                <span style={{fontSize: "12px", color: "#999999", marginLeft: "16px"}}>
                    {labelDetail.enable ? '开始启用' : '暂未启用'} {labelDetail.import_first_time ? '首次入库时间' : ''} {labelDetail.import_first_time}
                </span>
                <span style={{fontSize: "12px", color: "#999999", marginLeft: "5px", marginRight: "5px"}}>
                    {labelDetail.import_last_time ? '|' : ''}
                </span>
                <span style={{fontSize: "12px", color: "#999999"}}>
                    {labelDetail.import_last_time ? '最近更新' : ''} {labelDetail.import_last_time}
                </span>
            </div>
            <Table
                columns={getColumns()}
                dataSource={_.isArray(logDetail.items) ? logDetail.items : []}
            />
            <Pagenation
                total={logDetail.total || 0}
                currentPage={logDetail.current_page || 1}
                getClickPage={val => updateSearchParams('page', val)}
                pageCountChange={val => updateSearchParams('limit', val)}
            />
        </div>
    )
}

export default DetailPage
