import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { routerOptions } from './config/config'
import RootRouter from './routers'

const AppRouter = props => (
  <BrowserRouter {...routerOptions}>
    <RootRouter {...props} />
  </BrowserRouter>
)

export default AppRouter
