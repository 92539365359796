import { useState, useEffect, useCallback, useMemo } from 'react'
import Select from '@common/Select'
import Input from '@common/Input'
import Button from '@common/Button'
import Table from '@common/Table'
import Message from '@common/Message'
import Pagenation from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import _ from 'lodash'
import EditWin from './EditWin'
import { getStandardManageItems } from '@services/labelManagement'
import UseGetTopic from '@customHooks/UseGetTopic'
import { getSearchParams } from '@utils/submit'
import { commonApi } from '@utils/request'
import UseLabelName from '../common/UseLabelName'
import UseStandardItemSelects from '@customHooks/UseStandardItemSelects'

export default function LabelStandardManagement() {
  const getTopicRecord = UseGetTopic()
  const standardItemSelects = UseStandardItemSelects()

  const [curRecord, setCurRecord] = useState({})
  const [showEditWin, setShowEditWin] = useState(false)
  const [curEditItem, setCurEditItem] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [searchParams, setSearchParams] = useState({})
  const [pageConfig, setPageConfig] = useState({})
  const [labelName, setLabelName] = useState('')
  const standardManageItems = UseLabelName(labelName, true)
  const [labelTypeArr, setLabelTypeArr] = useState([])

  const topicArr = useMemo(() => {
    return _.isArray(_.get(getTopicRecord, 'topic')) ? _.get(getTopicRecord, 'topic') : []
  }, [getTopicRecord])

  const businessArr = useMemo(() => {
    return _.isArray(_.get(getTopicRecord, 'business')) ? _.get(getTopicRecord, 'business') : []
  }, [getTopicRecord])

  const curBusinessList = useMemo(() => {
    return _.isArray(_.get(getTopicRecord, 'business')) ? _.get(getTopicRecord, 'business').filter(j => j.topic_id == searchParams.topic).map(i => ({
      ...i,
      id: i.id,
      text: i.business_name
    })) : []
  }, [getTopicRecord, searchParams.topic])

  useEffect(() => {
    const labelType = _.get(standardItemSelects, 'label_type') || []
    setLabelTypeArr(labelType.map(i => ({
      id: String(i.value),
      text: i.name
    })))
  }, [standardItemSelects])

  useEffect(() => {
    initData()
  }, [searchParams, pageConfig])

  function initData() {
    const params = _.assign({}, searchParams, {
      'a.create_user': searchParams.create_user,
      create_user: '',
      'a.topic': searchParams.topic,
      topic: ''
    })
    commonApi(getStandardManageItems, {
      ...pageConfig,
      search: getSearchParams(params, ['a.create_user', 'label_name'])
    }).then(data => {
      setCurRecord(data)
    }).catch(err => Message.error(err))
  }

  const getcolumns = useCallback(
    () => {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 20
        }, {
          title: '标签名称',
          dataIndex: 'label_name',
        }, {
          title: '标签ID',
          dataIndex: 'task_label_id',
        }, {
          title: '数据标准',
          dataIndex: 'field_name_ch',
        }, {
          title: '标签类型',
          render: data => {
            if (_.isEmpty(labelTypeArr) || !_.isArray(labelTypeArr)) return
            const record = labelTypeArr.find(i => i.id == data.label_type) || {}
            return (
              <span>{ record['text'] || '-' }</span>
            )
          }
        }, {
          title: '所属主题域',
          render: data => {
            if (_.isEmpty(topicArr) || !_.isArray(topicArr)) return
            const topicRecord = topicArr.find(i => i.id == data.topic) || {}
            return (
              <span>{ topicRecord['topic_name'] || '-' }</span>
            )
          }
        }, {
          title: '所属业务',
          render: data => {
            if (_.isEmpty(businessArr) || !_.isArray(businessArr)) return
            const businessRecord = businessArr.find(i => i.id == data.service) || {}
            return (
              <span>{ businessRecord['business_name'] || '-' }</span>
            )
          }
        }, {
          title: '加工规则数',
          dataIndex: 'rule_num',
        }, {
          title: '创建人',
          dataIndex: 'create_user',
        }, {
          title: '修改时间',
          dataIndex: 'update_time',
        }, {
          title: '操作',
          render: data => (
            <Button
              text='编辑'
              onClick={() => onEdit(data)}
            />
          )
        }
      ]
    },
    [topicArr, businessArr, labelTypeArr],
  )

  function onCreate() {
    setIsEdit(false)
    setCurEditItem({})
    setShowEditWin(true)
  }

  function onEdit(item) {
    setIsEdit(true)
    setCurEditItem(item)
    setShowEditWin(true)
  }

  function updateParams(key, val) {
    let cloneParams = _.cloneDeep(searchParams)
    cloneParams[key] = val
    setSearchParams(cloneParams)
  }

  const topicList = useMemo(() => {
    return topicArr.map(i => ({
      id: i.id,
      text: i.topic_name
    }))
  }, [topicArr])

  const businessList = useMemo(() => {
    return businessArr.map(i => ({
      id: i.id,
      text: i.business_name
    }))
  }, [businessArr])

  return (
    <div className='label-standard-management-warp'>
      <BreadCrumbsAndTablde>
        <BreadCrumbsAndTablde.LeftCrumbs>
          <Select
            label='标签名称'
            readOnly={false}
            value={searchParams.label_name}
            width={200}
            data={standardManageItems}
            onInputFilter={setLabelName}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('label_name', val)
            }}
          />

          <Select
              label='标签类型'
              value={searchParams.label_type}
              width={200}
              data={labelTypeArr}
              onChange={val => {
                setPageConfig(r => _.assign({}, r, {
                  page: 1
                }))
                updateParams('label_type', val)
              }}
          />

          <Select
            label='所属主题域'
            value={searchParams.topic}
            width={200}
            data={topicList}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('topic', val)
            }}
          />

          <Select
            label='所属业务'
            value={searchParams.service}
            width={200}
            data={searchParams.topic ? curBusinessList : businessList}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('service', val)
            }}
          />

          <Input
            label='创建人'
            value={searchParams.create_user}
            width={200}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('create_user', val)
            }}
          />
        </BreadCrumbsAndTablde.LeftCrumbs>
        <BreadCrumbsAndTablde.RightCrumbs>
          <Button
            text='新增标签'
            type='submit'
            iconClass='iconfont icon-add'
            onClick={onCreate}
          />
        </BreadCrumbsAndTablde.RightCrumbs>
        <BreadCrumbsAndTablde.CenterWrap>
          <Table
            columns={getcolumns()}
            dataSource={_.isArray(curRecord.items) ? curRecord.items : []}
          />

          <Pagenation
            total={ curRecord.total }
            currentPage={ curRecord.current_page }
            pageCount={ curRecord.per_page }
            getClickPage={ page => setPageConfig(r => _.assign({}, r, {
              page
            })) }
            pageCountChange={ limit => setPageConfig(r => _.assign({}, r, {
              limit
            })) }
          />
        </BreadCrumbsAndTablde.CenterWrap>
      </BreadCrumbsAndTablde>

      {
        showEditWin &&
        <EditWin
          isEdit={isEdit}
          labelTypeArr={labelTypeArr}
          showEditWin={showEditWin}
          setShowEditWin={setShowEditWin}
          record={curEditItem}
          updatePage={initData}
          getTopicRecord={getTopicRecord}
        />
      }

    </div>
  )
}
