import React, { PureComponent } from 'react'
import SdwTable from '../../../common/Table'
import SdwPagenation from '../../../common/Pagenation'
import { Button } from 'element-react'
import './index.css'
import { getTopicBusiness, getPage, getTablecfgList, dimenGetOptions, getStandardSelects, getDimenById } from '@services/dimen'
import EditData from './components/EditData'
import SdwSelect from '../../../common/Select'
import SdwLoading from '@common/Loadding'

class Atom extends PureComponent {
  state = {
    dataList: [],
    search: {
      dim_type: { value: '', operator: '=' },
      dim_name_cn: { value: '', operator: '=' }
    },
    cfgData: {
      dimensionType: [],
      dimensionName: [],
      dimensionTypeMap: {}
    },
    topicData: [],
    pagination: {
      total: 0,
      per_page: 10,
      pages: 0,
      current_page: 0,
    },
    topicDic: {},
    topicServerDic: {},
    curTitle: '',
    curRecord: {},
    visible: false,
    tableList: [],
    standardSelectsOptions: [],
    standardSelctDataDic: {},
    PropertySourceSqlTablesDic: {},
    tableShowData: false
  }

  // 初始化
  componentDidMount() {
    this.getTablecfgList()
    this.getOptions()
    this.getStandardSelects()
  }

  // 获取所有的表
  getTablecfgList = () => {
    let that = this
    getTablecfgList({ limit: 10000, page: 0, search: { enable: { value: '1', operator: "=" } } }).then(res => {
      let tableList = res.data.data.items || []
      that.setState({tableList: tableList})

      let PropertySourceSqlTablesDic = {}
      for(let i=0; i<tableList.length; i++) {
        let id = tableList[i].id;
        let databaseName = tableList[i].database_name;
        let tableName = tableList[i].table_name;
        let key = databaseName + "_" + tableName
        PropertySourceSqlTablesDic[key] = id;
      }
      that.setState({PropertySourceSqlTablesDic: PropertySourceSqlTablesDic})
    })
  }

  /**
   * 获取所有的字段标准数据选项列表
   */
  getStandardSelects() {
    const data = {}
    if(this.state.standardSelectsOptions.length > 0){
      return
    }

    getStandardSelects().then(response => {
      if (response.data.code == 0) {
        let data = response.data.data

        // 字段标准解析
        let standardData = data.standard || []
        let standardDataDic = {}
        let topicHasOtherDic = {}
        let standardSelctDataDic = {}
        for(let i=0; i<standardData.length; i++){
          let businessId = standardData[i].business_id
          let topic = standardData[i].topic
          if(!businessId || businessId.toString() == ""){
            businessId = "0"
          }
          let id = standardData[i].id
          let label = standardData[i].field_name_ch + "[" + standardData[i].field_name + "]"
          let item = {
            "value": id,
            "label": label
          }
          standardSelctDataDic[id] = {
            "selectList": [parseInt(topic), parseInt(businessId), parseInt(id)],
            "name": label
          }
          if(!standardDataDic.hasOwnProperty(businessId)) {
            standardDataDic[businessId] = []
          }
          standardDataDic[businessId].push(item)
          if (businessId == "0") {
            if(!topicHasOtherDic.hasOwnProperty(topic)) {
              topicHasOtherDic[topic] = []
            }
            topicHasOtherDic[topic].push(item)
          }
        }

        this.setState({standardSelctDataDic: standardSelctDataDic})

        // 主题下的业务解析
        let businessData = data.business || []
        let businessDataDic = {}
        for(let i=0; i<businessData.length; i++){
          let topicId = businessData[i].topic_id
          let id = businessData[i].id
          let label = businessData[i].business_name
          let item = {
            "value": id,
            "label": label,
            "children": []
          }
          if(standardDataDic.hasOwnProperty(id)){
            item["children"] = standardDataDic[id]
          }
          if(topicId.toString() == ""){
            topicId = "0"
          }
          if(!businessDataDic.hasOwnProperty(topicId)) {
            businessDataDic[topicId] = []
          }
          businessDataDic[topicId].push(item)
        }

        // 主题数据的解析
        let topicData = data.topic || []
        let topicDataList = []
        for(let i=0; i<topicData.length; i++){
          let id = topicData[i].id
          let label = topicData[i].topic_name
          let item = {
            "value": id,
            "label": label,
            "children": []
          }
          if(businessDataDic.hasOwnProperty(id)){
            item["children"] = businessDataDic[id]
          }
          topicDataList.push(item)
        }

        // 给每个主题加入其他业务
        for(let i=0; i<topicDataList.length; i++){
          let topicId = topicDataList[i].value
          let item = {
            "value": 0,
            "label": "其他",
            "children": []
          }
          if (topicHasOtherDic.hasOwnProperty(topicId)) {
            item.children = topicHasOtherDic[topicId]
          }
          topicDataList[i].children.push(item)
        }

        this.setState({standardSelectsOptions: topicDataList})

        this.getPageData()
      }
    })
  }

  // 获取搜索选项列表
  getOptions() {
    if (this.state.cfgData.dimensionType.length > 0) {
      return
    }
    let that = this
    dimenGetOptions().then(res => {
      if (res.data.code === 0) {
        let data = res.data.data || this.cfgData
        let cfgData = {}
        cfgData.dimensionType = data.type || []
        cfgData.dimensionName = data.other || []
        cfgData.dimensionDesc = data.other || []
        let dimensionTypeMap = {}
        for(let i=0; i<data.type.length; i++){
          let typekey = data.type[i].id;
          dimensionTypeMap[typekey.toString()] = data.type[i].name;
        }
        cfgData.dimensionTypeMap = dimensionTypeMap;

        that.setState({cfgData: cfgData})
      }
    })
  }

  // 获取分页数据
  getPageData = () => {
    let that = this
    var params = {}
    params.page = this.state.pagination.current_page || 0
    params.limit = this.state.pagination.per_page
    params.search = {}

    for (const key in this.state.search) {
      const dval = this.state.search[key]
      if (dval.value) {
        params.search[key] = dval
      }
    }

    that.setState({tableShowData: false})
    getPage(params).then((res) => {
      that.setState({tableShowData: true})

      let items = res.data.data.items

      if(!items) {
        items = []
      }

      for (let i=0; i<items.length; i++) {
        let dimType = items[i].dim_type
        let dimTypeName = ""
        if(this.state.cfgData.dimensionTypeMap.hasOwnProperty(dimType)) {
          dimTypeName = this.state.cfgData.dimensionTypeMap[dimType]
        }
        items[i]["dimTypeName"] = dimTypeName

        let dataStandardName = items[i]["data_standard"];
        if(this.state.standardSelctDataDic.hasOwnProperty(items[i]["data_standard"])){
          dataStandardName = this.state.standardSelctDataDic[items[i]["data_standard"]]["name"]
        }
        items[i]["dataStandardName"] = dataStandardName
      }

      let pagination = that.state.pagination
      pagination.total = res.data.data.total
      pagination.per_page = res.data.data.per_page
      pagination.pages = res.data.data.pages
      pagination.current_page = res.data.data.current_page
      that.setState({
        dataList: [...items],
        pagination: {...pagination},
      })
    })
  }

  // 获取主题列表
  getTopicBusiness = () => {
    let that = this
    getTopicBusiness().then((res) => {
      that.setState({topicData: res.data.data})
      let topicDic = {}
      for(let i=0; i<res.data.data.length; i++) {
        let topicId = res.data.data[i].value
        if(!topicDic.hasOwnProperty(topicId)) {
          topicDic[topicId] = res.data.data[i]
        }
      }
      that.setState({topicDic: topicDic})
    })
  }

  // 获取表标题
  getcolumns = () => {
    let selt = this
    const columns = [
      {
          title: "中文名称",
          dataIndex: "dim_name_cn",
      },
      {
          title: "英文名称",
          dataIndex: "dim_name_en",
      },
      {
          title: "类型",
          dataIndex: "dimTypeName",
      },
      {
          title: "关联数据标准",
          dataIndex: "dataStandardName",
      },
      {
          title: "映射关系",
          dataIndex: "dim_name_en",
      },
      {
          title: "修改人",
          dataIndex: "modify_user",
      },
      {
          title: "修改时间",
          dataIndex: "modify_time",
      },
      {
          title: "操作",
          width: "50",
          render: (data, record) => {
              return (
                  <div style={{'color': '#517df3', 'cursor': 'pointer'}} onClick={ () => { selt.onClickGetOneData(data, selt) } }>编辑</div>
              )
          },
      }
    ]
    return columns
  }

  // 获取编辑的行记录
  onClickGetOneData = (data, e) => {
    console.log("onClickGetOneData 获取编辑的数据：", data, e)
    let that = this
    getDimenById({ id: data.id }).then(res => {
      console.log("onClickGetOneData 获取编辑的数据 返回：", res)

      if (res.data.code === 0) {
        let editorData = res.data.data.form[0]
        let propertyTableId = ""
        let tableKey = editorData.source_db + "_"  + editorData.source_table
        let data_standard = []
        let virtualPropertyList = res.data.data.propertyInfo
        if (this.state.standardSelctDataDic.hasOwnProperty(editorData.data_standard)){
          data_standard = this.state.standardSelctDataDic[editorData.data_standard].selectList
        }
        if(this.state.PropertySourceSqlTablesDic.hasOwnProperty(tableKey)){
          propertyTableId = this.state.PropertySourceSqlTablesDic[tableKey]
        }

        let propertyInfo = {
          "id": "",
          "field": "",
          "name": "",
          "selectVal": "",
          "fieldId": "",
        }

        if(editorData.dim_type == 1 && virtualPropertyList && virtualPropertyList.length > 0) {
          propertyInfo.id = virtualPropertyList[0].id;
          propertyInfo.field = virtualPropertyList[0].attr_id || "";
          propertyInfo.name = virtualPropertyList[0].attr_name || "";
          propertyInfo.fieldId = virtualPropertyList[0].attr_id_field_id || "";
          if(propertyInfo.field != "") {
            propertyInfo.selectVal = propertyInfo.field + "@@@" + propertyInfo.fieldId;
          }
        }

        let virtualPropertyListNew = [
          {
            "id": "",
            "name": "",
            "desc": "",
          }
        ]

        if(editorData.dim_type == 2 && virtualPropertyList && virtualPropertyList.length > 0) {
          virtualPropertyListNew = [];
          for(let i=0; i<virtualPropertyList.length; i++) {
            let item = {
              "id": virtualPropertyList[i].id,
              "name": virtualPropertyList[i].attr_id || "",
              "desc": virtualPropertyList[i].attr_name || "",
            }
            virtualPropertyListNew.push(item);
          }
        }

        let form = {
          id: editorData.id,
          upState: 1,
          dim_type: editorData.dim_type.toString(),
          dim_name_cn: editorData.dim_name_cn,
          dim_name_en: editorData.dim_name_en,
          data_standard: data_standard,
          propertyTableId: propertyTableId,
          propertyInfo: propertyInfo,
          virtualPropertyList: virtualPropertyListNew,
          filter:editorData.filter
        }

        that.setState({
          curRecord: form,
          visible: true,
          curTitle: '编辑维度'
        })
      }
    })
  }

  // 获取当前页码
  getClickPage = (pageNum) => {
    this.state.pagination.current_page = pageNum
    this.getPageData()
  }

  // 获取当前每页总条数
  pageCountChange = (per_page) => {
    this.state.pagination.per_page = per_page
    this.getPageData()
  }

  addOrEdit = (record = {}) => {
    this.setState({
      visible: true,
      curTitle: record.title,
      curRecord: record,
    })
  }

  onSureClick = () => {
    this.setState({
      visible: false,
    })
    this.getPageData()
  }

  render () {
    var { search, pagination, curTitle, visible, curRecord, topicData, tableList, standardSelectsOptions, tableShowData } = this.state;
    return (
      <div>
        {/** 搜索区域 */}
        <div className="page-search">
          <div className="input-wrap">

            <span style={{ marginRight: '8px' }}>
              <SdwSelect
                  placeholder="全部"
                  width={180}
                  label="中文名"
                  readOnly={false}
                  value={search.dim_name_cn.value}
                  data={this.state.cfgData.dimensionName.map(el => {
                    return {
                      id: el.dim_name_cn,
                      text: el.dim_name_cn
                    }
                  })}
                  onChange={val => {
                    this.setState({
                      pagination:Object.assign({}, pagination, {
                        current_page:1
                      }),
                      search: Object.assign({}, search, {
                        dim_name_cn: Object.assign({}, search.dim_name_cn, {
                          value: val
                        })
                      })
                    }, this.getPageData)
                  }}
              />
            </span>

            <span style={{ marginRight: '8px' }}>
              <SdwSelect
                placeholder="全部"
                width={180}
                label="维度类型"
                readOnly={false}
                value={search.dim_type.value}
                data={this.state.cfgData.dimensionType.map(el => {
                  return {
                    id: el.id,
                    text: el.name
                  }
                })}
                onChange={val => {
                  this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      dim_type: Object.assign({}, search.dim_type, {
                        value: val
                      })
                    })
                  }, this.getPageData)
                }}
              />
            </span>

            <div className="btn-group" style={{ top: 0 }}>
              <Button
                type="primary"
                icon='plus'
                onClick={ () => this.addOrEdit({ title: '新增维度', curRecord:{} }) }
              >新增</Button>
            </div>
          </div>

        </div>

        {/** 分页显示数据区域 */}
        <div className="tableShowData">
          <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
            <SdwTable
              tdWhiteSpace={true}
              columns={this.getcolumns()}
              dataSource={this.state.dataList}
            />
          </SdwLoading>

          <SdwPagenation
            total={pagination.total}
            currentPage={pagination.current_page}
            pageCount={pagination.per_page}
            getClickPage={this.getClickPage}
            pageCountChange={this.pageCountChange}
          />
        </div>

        {/** 编辑弹窗 */}
        <EditData
          title={curTitle}
          visible={visible}
          record={curRecord}
          topicData={topicData}
          tableList={tableList}
          standardSelectsOptions={standardSelectsOptions}
          onIconClick={() => this.setState({ visible: false })}
          onCancelClick={() => this.setState({ visible: false })}
          onSureClick={() => {
            this.setState({ visible: false })
            this.onSureClick()
          }}
        />
      </div>
    )
  }
}

export default Atom
