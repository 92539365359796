import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwInput from '@common/Input'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import { Button } from 'element-react'
import { pageAppTables, getModifyTargets, publishApi , pageAppTablesManage} from '@services/api'
import SdwMessage from '@common/Message'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import AddApiWin from './AddApiWin'
//import ReRunWin from './ReRunWin'
import { getTopicBusiness } from '@services/Derived'
import SdwLoading from '@common/Loadding'
import SdwDialog from '@common/Dialog'
import ConfirmWin from './ConfirmWin/index.js'
import { getPublicCfg } from '@api/atom'

class Api extends React.Component {

  state = {
    tableName: '',
    create_user: '',
    curPage: this.props.pageInfo || 1,
    curLimit: 10,
    dialogVisible: false,
    reRunVisible: false,
    topicData: [],
    runCycleData: [],
    allAppInfos: {},
    curRecord: {},
    tableShowData: false,
    publishType: '',
    topicDic: {},
    editDataVisible: false,
    getModifyTargetsList: [],
    editDataItem: {},
  }

  componentDidMount() {
    this.initData()
  }

  initData = async () => {
    this.onSearchApiInfo()
    this.getTopicBusiness()
    this.getPublicCfg()
  }
  getPublicCfg = () => {
    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }

        this.setState({ runCycleData: cfgData.runCycle })
      }
    }, () => SdwMessage.error("接口异常"))
  }
  onSearchApiInfo = () => {
    let { curLimit, curPage } = this.state
    this.setState({ tableShowData: false })
    if (hasPriv('pageAppTables')) {
      pageAppTables({
        ...!!curLimit ? {
          limit: curLimit,
        } : {},
        ...!!curPage ? {
          page: curPage
        } : {}
      }).then(res => {
        this.setState({ tableShowData: true })
        let jsonData = res && res.data || {}
        if (jsonData && jsonData.code === 0 && jsonData.data) {
          this.setState({
            allAppInfos: jsonData.data
          })
        }
      }, () => SdwMessage.error("接口异常"))
      return
    }
    if (hasPriv('pageAppTablesManage')) {
      pageAppTablesManage({
        ...!!curLimit ? {
          limit: curLimit,
        } : {},
        ...!!curPage ? {
          page: curPage
        } : {}
      }).then(res => {
        this.setState({ tableShowData: true })
        let jsonData = res && res.data || {}
        if (jsonData && jsonData.code === 0 && jsonData.data) {
          this.setState({
            allAppInfos: jsonData.data
          })
        }
      }, () => SdwMessage.error("接口异常"))
      return
    }
  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    if (
      !_.isEqual(prevStates.curPage, this.state.curPage) ||
      !_.isEqual(prevStates.curLimit, this.state.curLimit)
    ) {
      this.initTableData()
    }
  }
  // 获取主题列表
  getTopicBusiness = () => {
    let that = this
    getTopicBusiness().then((res) => {
      that.setState({ topicData: res.data.data })

      let topicDic = {}
      for (let i = 0; i < res.data.data.length; i++) {
        let topicId = res.data.data[i].value
        if (!topicDic.hasOwnProperty(topicId)) {
          topicDic[topicId] = res.data.data[i]
        }
      }
      that.setState({ topicDic: topicDic })
    })
  }
  getcolumns = () => {
    let self = this
    let isDisplayOperate = false
    if (hasPriv('saveTemplateConf') || hasPriv('publishApi')) {
      isDisplayOperate = true
    }
    const columns = [
      {
        title: "API名称",
        width: 260,
        // dataIndex: "table_name",
        render: (data, record) => {
          return (
            <span>{`${data.table_name}（${data.table_name_cn}）`}</span>
          )
        }
      },
      {
        title: "主题域/业务",
        width: 160,
        // dataIndex: "topic",
        render: (data, record) => {
          let { topicDic } = self.state
          let { topic, service } = data
          let topicRecord = topicDic[topic]
          let topicName = '-'
          let children = []
          let serviceName = '-'
          if (!!topicRecord) {
            topicName = topicDic[topic].label
            children = topicDic[topic].children || []
            serviceName = children.filter(i => i.value == service)[0] && children.filter(i => i.value == service)[0].label || ''
          }
          return (
            <span>{`${topicName}/${serviceName}`}</span>
          )
        }
      },
      {
        title: "项目",
        width: 80,
        dataIndex: "project",
      },
      {
        title: "负责人",
        width: 96,
        render: function (data, record) {
          let resp_user = ""
          // if (data.resp_user != "") {
          //   resp_user = JSON(data.resp_user).join(";")
          // }
          return <span>
            {data.resp_user}
          </span>
        }
      },
      {
        title: "创建时间",
        width: 158,
        dataIndex: "create_time",
      },
      {
        title: "调用次数",
        width: 158,
        dataIndex: "request_cnt",
      },
      {
        title: "发布状态",
        width: 158,
        render: function (data, record) {
          return (
            <>
              {
                _.isEqual(typeof data.publish_state, 'number') && <span className={
                  data.publish_state ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                }></span>
              }
              <span>{_.isEqual(typeof data.publish_state, 'number') ?
                (data.publish_state ? '已发布' : '未发布') : '-'}</span>
            </>
          )
        }
      },
      ...isDisplayOperate ?
        [{
          title: "操作",
          width: 80,
          render: (data, record) => {
            return (
              <>
                {
                  hasPriv('saveTemplateConf') &&
                  <Button
                    type="text"
                    size="small"
                    onClick={() => self.handleEdit(data)}
                    style={{ "fontSize": "14px" }}
                  >编辑</Button>
                }
                {
                  hasPriv('publishApi') &&
                  <Button
                    disabled={data.publish_state ? true : false}
                    type="text"
                    size="small"
                    onClick={() => self.publishApiTable(data)}
                    style={{ "fontSize": "14px" }}
                  >发布</Button>
                }
              </>
            )
          },
        }] : []
    ]
    return columns
  }

  initTableData = () => {
    let {
      tableName,
      create_user,
      curPage,
      curLimit,
      publishType
    } = this.state

    // DO search
    let params = {
      limit: curLimit,
      page: curPage,
      ...(!!tableName || !!create_user) ? {
        search: JSON.stringify({
          ...!!tableName ? {
            table_name: {
              value: `%${tableName}%`,
              operator: 'like'
            }
          } : {},
          ...!!create_user ? {
            create_user: {
              value: `%${create_user}%`,
              operator: 'like'
            }
          } : {}
        }),
        orSearch: JSON.stringify({
          ...!!tableName ? {
            table_name_cn: {
              value: `%${tableName}%`,
              operator: 'like'
            }
          } : {},
          ...!!create_user ? {
            create_user: {
              value: `%${create_user}%`,
              operator: 'like'
            }
          } : {}
        })
      } : {}
    }

    this.setState({ tableShowData: false })
    pageAppTables(params).then(res => {
      this.setState({ tableShowData: true })

      let jsonData = res && res.data || {}
      if (jsonData && jsonData.code === 0 && jsonData.data) {
        this.setState({
          allAppInfos: jsonData.data
        })
      }
    }, () => SdwMessage.error("接口异常"))
  }

  changeDialogVisible = flag => {
    this.setState({
      dialogVisible: flag
    })
  }

  changeReRunVisible = flag => {
    this.setState({
      reRunVisible: flag
    })
  }

  handleEdit = data => {
    this.props.history.push(`/admin/factory/apiDetail/${data.id}`)
  }

  handleReRun = data => {
    this.setState({
      curRecord: data
    })
    this.changeReRunVisible(true)
  }

  publishApiTable = (data) => {
    getModifyTargets({
      id: data.id
    }).then(res => {
      let jsonData = res && res.data || {}
      if (jsonData && jsonData.code === 0 && jsonData.data) {
        this.setState({
          getModifyTargetsList: jsonData.data,
          editDataVisible: true,
          editDataItem: data
        })
      }
    }, () => SdwMessage.error("接口异常"))

  }
  submitApiTable = () => {
    let editDataItem = this.state.editDataItem
    publishApi({
      id: editDataItem.id
    }).then(res => {
      let jsonData = res && res.data || {}
      if (jsonData && jsonData.code === 0) {
        this.setState({ editDataVisible: false })
        this.onSearchApiInfo()
      } else {
        SdwMessage.error("接口异常")
      }
    }, () => SdwMessage.error("接口异常"))

  }
  render() {
    let {
      tableName,
      create_user,
      dialogVisible,
      reRunVisible,
      topicData,
      runCycleData,
      allAppInfos,
      curRecord,
      tableShowData,
      editDataVisible,
      getModifyTargetsList,
    } = this.state

    let curTopicData = topicData.map(i => {
      return {
        id: i.label,
        text: i.value
      }
    })
    console.log("TagTree====curTopicData", curTopicData)

    let curRunCycleData = runCycleData.map(i => {
      return {
        id: i.id,
        text: i.name
      }
    })

    return (
      <div className="Application">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/api'
            }, {
              title: '指标API'
            }
          ]}
        />

        <div className="page-mainbody">
          <div className="page-search">
            <div className="input-wrap" style={{ lineHeight: '44px' }}>
              <div className="ele-search-input">
                <SdwInput
                  width={180}
                  label="API名称"
                  placeholder="支持中文、英文"
                  value={tableName}
                  onEnterKeyDown={this.initTableData}
                  onBlur={this.initTableData}
                  onChange={val => this.setState({
                    curPage: 1,
                    tableName: val
                  })}
                />
              </div>
              <div className="ele-search-input">
                <SdwInput
                  width={180}
                  label="创建人"
                  placeholder="请输入RTX名称"
                  value={create_user}
                  onEnterKeyDown={this.initTableData}
                  onBlur={this.initTableData}
                  onChange={val => this.setState({
                    curPage: 1,
                    create_user: val
                  })}
                />
              </div>

              <div className="btn-group">
                {
                  hasPriv('saveAppConf') &&
                  <Button
                    type="primary"
                    icon='plus'
                    onClick={() => this.changeDialogVisible(true)}
                  >新增</Button>
                }
              </div>
            </div>
          </div>

          <div className="page-table">
            <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
              <SdwTable
                tdWhiteSpace={true}
                columns={this.getcolumns()}
                dataSource={allAppInfos && allAppInfos.items || []}
              />
            </SdwLoading>

            <SdwPagenation
              total={allAppInfos.total}
              currentPage={allAppInfos.current_page}
              pageCountList={[10, 15, 20, 40]}
              pageCount={allAppInfos.per_page}
              getClickPage={page => {
                this.setState({
                  curPage: page
                })
              }}
              pageCountChange={limit => {
                this.setState({
                  curLimit: limit
                })
              }}
            />
          </div>
        </div>

        {
          dialogVisible&&<AddApiWin
            dialogVisible={dialogVisible}
            runCycleData={curRunCycleData}
            onSearchApiInfo={this.onSearchApiInfo}
            changeDialogVisible={this.changeDialogVisible}
          />
        }

        {/** 编辑弹窗 */}
        {
          editDataVisible &&
          <SdwDialog
            title='提示'
            hideHeader={true}
            visible={editDataVisible}
            changeVisible={bool => this.setState({ editDataVisible: bool })}
            onCancelClick={() => this.setState({ editDataVisible: false })}
            onSureClick={() => { this.submitApiTable() }}
          >
            <ConfirmWin
              title='以下为本次改动的字段，请确认是否改动！'
              confirmData={getModifyTargetsList}
            />
          </SdwDialog>
        }
      </div>
    )
  }
}

const mapState = state => ({
  pageInfo: state.page.pageInfo
})

export default withRouter(connect(mapState)(Api))
