import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import AddApplicationWin from '../AddApplicationWin'
import ApplicationSettingWin from '../ApplicationSettingWin'
import RegularRerunWin from '../RegularRerunWin'
import { getManageTopics } from '@services/management'
import { getPublicCfg } from '@services/atom'
import { getTargetCfgById, searchAppInfoManage } from '@services/application'
import { searchAppInfo, storeAppTarget, updateAppTarget, deleteAppTarget, searchTarget, updatePublishState, getTargetResults } from '@services/application'
import { TOP_MAP } from '../common'
import SdwDialog from '@common/Dialog'
import SdwMessage from '@common/Message'
import {Dropdown} from 'element-react'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import TagTree from './TagTree'
import IndicatorAttributes from './IndicatorAttributes'
import TabPage from './TabPage'

import './style/index.css'

class ApplicationDetail extends React.Component {

  state = {
    previewDataMsg: '',
    currentData: '',
    dialogVisible: false,
    setTopVisible: false,
    topicData: [],
    runCycleData: [],
    previewTableHeader: [], // 预览数据表头
    settingDialogVisible: false, // 关联字段弹窗
    regularRerunVisible: false, // 定时重跑弹窗
  }

  componentDidMount () {
    this.initData()
  }

  onSearchAppInfo = params => {
    if (hasPriv('searchAppInfo')) {
      searchAppInfo({
        search: JSON.stringify({
          id: {
            value: params.id,
            operator: '='
          }
        })
      })
        .then(res => {
          if (res && res.data && res.data.code === 0) {
            let record = res.data.data || {}
            if (!_.isArray(record.items) || !record.items.length) return

            let curDate = record.items[0] || {}
            if (curDate.id !== this.state.currentData.id) return

            this.setState({
              currentData: curDate
            })
          }
        }, () => SdwMessage.error("接口异常"))
      return
    }
    if (hasPriv('searchAppInfoManage')) {
      searchAppInfoManage({
        search: JSON.stringify({
          id: {
            value: params.id,
            operator: '='
          }
        })
      })
        .then(res => {
          if (res && res.data && res.data.code === 0) {
            let record = res.data.data || {}
            if (!_.isArray(record.items) || !record.items.length) return

            let curDate = record.items[0] || {}
            if (curDate.id !== this.state.currentData.id) return

            this.setState({
              currentData: curDate
            })
          }
        }, () => SdwMessage.error("接口异常"))
      return
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    // 还原时间 + 维度的勾选
    if (
      this.state.reWriteData &&
      prevState.reWriteData !== this.state.reWriteData
    ) {
      let { oriAllSelectedTarget } = this.props
      if (!!oriAllSelectedTarget.length) {
        this.onReWriteData(oriAllSelectedTarget)
      }

      this.setState({
        reWriteData: false
      })
    }
  }

  onReWriteData = oriAllSelectedTarget => {
    if (_.isArray(oriAllSelectedTarget)) {
      let checkedTime = []
      let checkedDimen = []
      oriAllSelectedTarget.forEach(target => {
        checkedTime.push(target.time)
        checkedDimen.push(target.dimension)
      })

      checkedTime = Array.from(new Set(checkedTime))
      checkedDimen = Array.from(new Set(checkedDimen))

      let { targetTime, targetDimension } = this.props
      let curTargetTime = _.cloneDeep(targetTime)
      let curTargetDimension = _.cloneDeep(targetDimension)

      curTargetTime.forEach(time => {

        if (!oriAllSelectedTarget.length) {
          time.isChecked = false
        } else {
          time.isChecked = checkedTime.includes(time.id)
        }
      })
      curTargetDimension.forEach(dimen => {

        if (!oriAllSelectedTarget.length) {
          dimen.isChecked = false
        } else {
          dimen.isChecked = checkedDimen.includes(dimen.name_en)
        }
      })

      this.props.updateTargetTime(curTargetTime)
      this.props.updateTargetDimension(curTargetDimension)
    }
  }

  initData = () => {
    // this.initDatePicker()

    let id = this.props.location.state && this.props.location.state.id || ''
    if (!!id) {
      let record = this.props.location.state
      this.setState({
        currentData: record
      }, this.onSearchTarget)
      this.onSearchAppInfo(record)
    }

    getManageTopics().then(res => {
      let jsonData = res && res.data || {}
      if (jsonData && jsonData.code === 0 && Array.isArray(jsonData.data)) {
        this.setState({
          topicData: jsonData.data
        })
      }
    }, () => SdwMessage.error("接口异常"))

    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }

        this.setState({ runCycleData: cfgData.runCycle })
      }
    }, () => SdwMessage.error("接口异常"))

  }

  changeDialogVisible = flag => {
    this.setState({
      dialogVisible: flag
    })
  }

  // 关联字段弹窗
  changeSettingDialogVisible = flag => {
    this.setState({
      settingDialogVisible: flag
    })
  }

  // 定时重跑弹窗
  changeDelayReRunVisible = flag => {
    this.setState({
      regularRerunVisible: flag
    })
  }

  initClickTreeNode = val => {
    this.props.updateCurClickTreeValue(val)

    // 点击叶子节点，查询对应的信息
    if (!val.children) {
      this.onGetTarget(val)
    } else {
      this.props.updateTargetTime([])
      this.props.updateTargetDimension([])
    }
  }

  hanlderChangeTreeSubmit = async () => {
    await this.onSubmit()

    this.hanlderChangeTreeCancel()
  }

  hanlderChangeTreeCancel = () => {
    this.props.changeComfirmVisible(false)
    this.initClickTreeNode(this.props.currentClickTreeNode)
  }

  // isSingelTarget: 是否是单个指标查询
  onSearchTarget = (targetID = '', isSingelTarget = false) => {
    let params = {
      table_id: {
        value: this.state.currentData.id,
        operator: '='
      },
      ...!!targetID ? {
        target_id: {
          value: targetID,
          operator: '='
        }
      } : {}
    }
    return searchTarget({
      search: JSON.stringify(params)
    })
      .then(res => {
        let oriData = []
        if (res && res.data && res.data.code === 0) {
          let jsonData = _.isArray(res.data.data) ? res.data.data : []
          oriData = jsonData.map(i => {
            return Object.assign({}, i, {
              "db_id": i.id,
              "target_id": i.target_id,
              "name_cn": i.target_name_cn,
              "name": i.target_name_en,
              "time": i.time_cycle,
              "dimension": i.target_dim,
              "target_type": i.target_type,
              "target_top": i.top_count
            })
          })
        }

        if (isSingelTarget) {
          this.props.updateCurShowSelectedTarget(oriData)
          this.props.updateOriAllSelectedTarget(oriData)

          // 触发时间 + 维度的勾选
          this.setState({
            reWriteData: true
          })
        } else {
          this.props.updateAllSelectedIndicator(oriData)
        }
      }, () => SdwMessage.error("接口异常"))
  }

  onPublish = () => {
    let { currentData } = this.state
    let id = currentData.id

    if (!id) return

    // TODO
    updatePublishState({
      id: id
    })
      .then(res => {
        if (res.data && res.data.code === 0) {
          this.initData()
          let msg = '发布成功'
          SdwMessage.success(msg)
        } else {
          let msg = res.data && res.data.msg
          SdwMessage.error(msg)
        }
      }, () => SdwMessage.error("接口异常"))
  }

  onGetTarget = record => {

    //  TODO  请求当前指标下的原始数据
    this.onSearchTarget(record.id, true).then(() => {
      this.onGetTargetCfgById(record)
    }, () => SdwMessage.error("接口异常"))
  }

  onGetTargetCfgById = record => {
    getTargetCfgById({
      id: record.id
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let jsonData = res.data.data || {}
          let time = jsonData.time_cycle || []
          let dimension = jsonData.dimension || []

          this.props.updateCurClickTreeValue(Object.assign({}, this.props.curClickTreeValue, jsonData))
          this.props.updateTargetTime(time.map(i => {
            return {
              ...i,
              isChecked: false
            }
          }))

          this.props.updateTargetDimension(dimension.map(i => {
            return {
              id: i.id,
              isChecked: false,
              name_en: i.en,
              name: `${i.cn || '-'}（${i.en || '-'}）`
            }
          }))

          this.setState({
            reWriteData: true, // 还原时间 + 维度的勾选
          })
        }
      }, () => SdwMessage.error("接口异常"))
  }

  onSubmit = () => {
    let { curShowSelectedTarget, oriAllSelectedTarget } = this.props

    let curTarget = _.cloneDeep(curShowSelectedTarget)
    let oriTarget = _.cloneDeep(oriAllSelectedTarget)
    let addNewTarget = []  // 新增的项
    let delOriTarget = []  // 删除的项
    let updateOriTarget = []  // 更新的项:当前仅更新指标类型

    // 计算出新增的，走新增接口
    curTarget.forEach(curItem => {
      if (!oriTarget.length || oriTarget.every(i => !(i.time === curItem.time && i.dimension === curItem.dimension))) {
        addNewTarget.push(curItem)
      }
    })

    oriTarget.forEach(oriItem => {

      // 计算出删除的，走删除接口
      if (!curTarget.some(i => i.time === oriItem.time && i.dimension === oriItem.dimension) && oriItem.id) {
        delOriTarget.push(oriItem)
      } else {

        // 计算出更新的项
        let index = curTarget.findIndex(i => {
          return (i.id === oriItem.id && i.time === oriItem.time && i.dimension === oriItem.dimension) &&
          (i.target_top !== oriItem.target_top || i.target_type !== oriItem.target_type)

        })
        if (index !== -1 && oriItem.id) {
          updateOriTarget.push(Object.assign({}, curTarget[index], {
            id: oriItem.id
          }))
        }
      }
    })

    if (addNewTarget.length) {
      let addParams = this.formatParams(addNewTarget)
      storeAppTarget(JSON.stringify({
        add: addParams
      }))
        .then(res => {
          if (res && res.data && res.data.code === 0) {
            SdwMessage.success('保存成功')
            this.reInitData()
          } else {
            SdwMessage.error('保存失败')
          }
        }, () => SdwMessage.error("接口异常"))
    }

    if (updateOriTarget.length) {
      let updateParams = this.formatParams(updateOriTarget)
      updateAppTarget(JSON.stringify({
        update: updateParams
      }))
        .then(res => {
          if (res && res.data && res.data.code === 0) {
            SdwMessage.success('更新成功')
            this.reInitData()
          } else {
            SdwMessage.error('更新失败')
          }
        }, () => SdwMessage.error("接口异常"))
    }

    if (delOriTarget.length) {
      let deleteParams = this.formatParams(delOriTarget)
      deleteAppTarget(JSON.stringify({
        delete: deleteParams
      }))
        .then(res => {
          if (res && res.data && res.data.code === 0) {
            SdwMessage.success('删除成功')
            this.reInitData()
          } else {
            SdwMessage.error('删除失败')
          }
        }, () => SdwMessage.error("接口异常"))
    }
  }

  reInitData = () => {
    let { curClickTreeValue } = this.props
    this.onSearchTarget(curClickTreeValue.id, true) // 不仅要更新单个指标的数据
    this.onSearchTarget() // 还要更新总表的数据---已选指标表二
    this.initData() // 更新当前数据
  }

  formatParams = list => {
    return list.map(i => {
      return {
        ...i.id ? {
          id: i.id
        } : {},
        table_id: i.table_id,
        target_id: i.target_id,
        target_name_en: i.name,
        target_name_cn: i.name_cn,
        time_cycle: i.time,
        target_dim: i.dimension,
        target_type: i.target_type,
        ...i.target_type === TOP_MAP.top ? {
          top_count: i.target_top
        } : {}
      }
    })
  }

   changeTitle = (command) => {
    if (command == 'associatedField') {
      this.changeSettingDialogVisible(true)
    }
    else if (command == 'regularRerun') {
      this.changeDelayReRunVisible(true)
    }
  }

  render () {

    let {
      currentData,
      dialogVisible,
      settingDialogVisible, // 关联字段弹窗
      regularRerunVisible, // 定时重跑弹窗
      topicData,
      runCycleData,
    } = this.state

    let {
      comfirmVisible,
    } = this.props

    let curTopicData = topicData.map(i => {
      return {
        id: i.topic_id,
        text: i.topic_name
      }
    })

    let curRunCycleData = runCycleData.map(i => {
      return {
        id: i.id,
        text: i.name
      }
    })

    return (
      <div className="sdw-application-detail__wrap">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/atom'
            }, {
              title: '应用表管理',
              path: '/admin/factory/application'
            }, {
              title: '新增应用表'
            }
          ]}
        />

        <div className="sdw-application-detail__title-wrap">
          <span>{`${currentData.table_name}（${currentData.table_name_cn}）`}</span>
          {
            hasPriv('updateNewAppTable') &&
            <span
              className="el-button--text"
              onClick={() => this.changeDialogVisible(true)}
            >修改</span>
          }
          {/*<span
            className="el-button--text"
            style={{marginLeft: 10}}
            onClick={() => this.changeSettingDialogVisible(true)}
          >设置</span>*/}
          {
            hasPriv('updatePublishState') &&
            <span className="sdw-application-detail__title-wrap__right">
              <span>
                <button
                  className="publish"
                  disabled={
                    // 已发布需要禁用按钮且文案为已发布
                    !!currentData.publish_state
                    // TODO 这里只有有权限的人才能点击发布按钮
                  }
                  onClick={this.onPublish}
                >{!!currentData.publish_state ? '已发布' : '发布'}</button>
              </span>
            </span>
          }

          <span style={{marginLeft: 10}}></span>
          <Dropdown
              trigger='click'
              menu={(
                  <Dropdown.Menu>
                    <Dropdown.Item command='associatedField'>关联字段</Dropdown.Item>
                    <Dropdown.Item command='regularRerun'>应用表定时重跑</Dropdown.Item>
                  </Dropdown.Menu>
              )}
              onCommand={command => this.changeTitle(command)}
          >
            <span className="el-dropdown-link">
              更多设置<i className="el-icon-arrow-down el-icon--right"></i>
            </span>
          </Dropdown>
        </div>

        <div
          className="sdw-application-detail__center-wrap"
          style={{
            height: document.body.offsetHeight - 300
          }}
        >
          <div className="left-box">
            <TagTree
              onGetTarget={this.onGetTarget}
            />
          </div>
          <div className="center-box">
            <IndicatorAttributes
              currentData={currentData}
            />
          </div>
          <div className="right-box">
            <TabPage
              currentData={currentData}
              onReWriteData={this.onReWriteData}
              onSubmit={this.onSubmit}
            />
          </div>
        </div>

        {
          dialogVisible &&
            <AddApplicationWin
                dialogVisible={dialogVisible}
                currentData={currentData}
                curTopicData={curTopicData}
                runCycleData={curRunCycleData}
                initData={this.initData}
                changeDialogVisible={this.changeDialogVisible}
                isEdit={true}
            />
        }

        {
          settingDialogVisible &&
            <ApplicationSettingWin
                dialogVisible={settingDialogVisible}
                currentData={currentData}
                changeDialogVisible={this.changeSettingDialogVisible}
            />
        }

        {
          regularRerunVisible &&
            <RegularRerunWin
                currentData={currentData}
                dialogVisible={regularRerunVisible}
                changeDelayReRunVisible={this.changeDelayReRunVisible}
            />
        }

        <SdwDialog
          hideHeader={true}
          visible={comfirmVisible}
          width={480}
          height={180}
          changeVisible={ this.props.changeComfirmVisible }
          onCancelClick={ this.hanlderChangeTreeCancel }
          onSureClick={ this.hanlderChangeTreeSubmit }
        >
          <span style={{
            fontSize: 18,
            marginTop: 36,
            display: 'inline-block'
          }}>是否保存对该指标的修改</span>
        </SdwDialog>
      </div>
    )
  }
}

const mapState = state => ({
  allSelectedIndicator: state.application.allSelectedIndicator,
  curShowSelectedTarget: state.application.curShowSelectedTarget,
  oriAllSelectedTarget: state.application.oriAllSelectedTarget,
  comfirmVisible: state.application.comfirmVisible,
  currentClickTreeNode: state.application.currentClickTreeNode,
  curClickTreeValue: state.application.curClickTreeValue,
  targetTime: state.application.targetTime,
  targetDimension: state.application.targetDimension,
})

const mapDispatch = dispatch => ({
  updateAllSelectedIndicator: dispatch.application.updateAllSelectedIndicator,
  updateCurShowSelectedTarget: dispatch.application.updateCurShowSelectedTarget,
  updateOriAllSelectedTarget: dispatch.application.updateOriAllSelectedTarget,
  changeComfirmVisible: dispatch.application.changeComfirmVisible,
  updateCurClickTreeValue: dispatch.application.updateCurClickTreeValue,
  updateTargetTime: dispatch.application.updateTargetTime,
  updateTargetDimension: dispatch.application.updateTargetDimension,
})

export default connect(mapState, mapDispatch)(ApplicationDetail)
