import React, { Component } from 'react'

import SdwSelect from '@common/Select'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import SdwDrawer from '@common/Drawer'
import { Button } from 'element-react'
import EditBusinessIndicaWin from "./EditBusinessIndicaWin";

import { getIndicatorConfItems,getIndicators,getTargets } from "@services/operationConfiguration";

import _ from 'lodash'
import '../style/BusinessIndicatorsOperation.css'
import { TIME_CYCLE_OPTIONS } from "../utils/const";
import { hasPriv } from '@components/FrontendAuth/AuthMap'


export default class BusinessIndicatorsOperation extends Component {
  editBusinessIndicaRef = React.createRef()
  state = {
    dimension_id: '',  //指标维度
    time_cycle: '',      //统计周期

    tableData: {},
    showEditBusinessIndicaWin: false,
    isEdit: false,
    currentConf: '',
    indicatorsDimentionList: [],
    targetList:[],

    curPage: 1,
    curLimit: 10,
  }
  componentDidMount () {
    this.getBusinessIndicatorsCfgListBySearch()
    this.getIndicatorsDimensionOptions()
    this.onGetTargets()
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      !_.isEqual(prevState.curPage, this.state.curPage) ||
      !_.isEqual(prevState.curLimit, this.state.curLimit)
    ) {
      this.getBusinessIndicatorsCfgListBySearch()
    }
  }

  onGetTargets = () => {
    getTargets().then(res => {
      if (res && res.data && res.data.code === 0) {
        let targetList = res.data.data || []
        targetList = targetList.map(i => ({
          id: i.id,
          text: i.target_name_cn + '(' + i.target_name + ')',
          target_name: i.target_name
        }))
        this.setState({
          targetList
        })
      }
    })
  }

  getIndicatorsDimensionOptions = () => {
    getIndicators().then(res => {
      if (res && res.data && res.data.code === 0) {
        let indicatorsDimentionList = res.data.data || []
        indicatorsDimentionList = indicatorsDimentionList.map(i => ({
          id: i.id,
          text: i.field_name_ch + '(' + i.field_name + ')',
          field_name: i.field_name
        }))
        this.setState({
          indicatorsDimentionList
        })
      }
    })
  }

  getBusinessIndicatorsCfgListBySearch = () => {
    let { curPage, curLimit, dimension_id, time_cycle } = this.state
    let searchParams = JSON.stringify({
      ...dimension_id ? {
        dimension_id: {
          value: dimension_id,
          operator: '='
        }
      } : {},
      ...time_cycle ? {
        time_cycle: {
          value: time_cycle,
          operator: '='
        }
      } : {}
    })

    getIndicatorConfItems({
      page: curPage,
      limit: curLimit,
      search: searchParams
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          this.setState({
            tableData: res.data.data || {}
          })
        }
      })
  }

  getColumns = () => {
    let self = this
    return [
      {
        title: "ID",
        width: 80,
        dataIndex: "id"
      },
      {
        title: "指标维度名称",
        dataIndex: "dimension_name"
      },
      {
        title: "统计周期",
        dataIndex: "time_cycle"
      },
      {
        title: "维度值",
        dataIndex: "dimension_value"
      },
      {
        title: "已配置指标数",
        render: function (data) {
          return (
            <span>{data.target_value_configuration? data.target_value_configuration.length : '-'}</span>
          )
        }
      },
      {
        title: "修改人",
        dataIndex: "modify_user"
      },
      {
        title: "更新时间",
        dataIndex: "modify_time"
      },
      ...hasPriv('updateIndicatorConf') ?
      [{
        title: "操作",
        width: 80,
        render: function (data) {
          return (
            <span>
              <Button
                plain={true}
                type="text"
                size="small"
                onClick={ () => self.handleEdit(data) }
              >编辑</Button>
            </span>
          )
        }
      }] : []
    ]
  }
  handleEdit = (data) => {
    this.setState({
      currentConf: data,
      showEditBusinessIndicaWin: true,
      isEdit: true
    })
  }
  render(){
    let { dimension_id, time_cycle, tableData, showEditBusinessIndicaWin, isEdit, currentConf, indicatorsDimentionList} = this.state
    return (
      <div>
        <div className="page-mainbody">
          <div className="sdw-business-indicators__wrap">
            <span className="sdw-business-indicators-search__wrap">
              <SdwSelect
                placeholder="请选择"
                value={dimension_id}
                width={250}
                label="指标维度"
                readOnly={false}
                data={indicatorsDimentionList}
                onChange={val => this.setState({curPage: 1, dimension_id: val},this.getBusinessIndicatorsCfgListBySearch)}
              />
            </span>
            <span className="sdw-business-indicators-search__wrap">
              <SdwSelect
                placeholder="请选择"
                value={time_cycle}
                width={250}
                label="统计周期"
                readOnly={false}
                data={TIME_CYCLE_OPTIONS.map(item => ({id: item.id, text: `${item.id}(${item.text})`}))}
                onChange={val => this.setState({curPage: 1, time_cycle: val}, this.getBusinessIndicatorsCfgListBySearch)}
              />
            </span>

            <span className="sdw-business-indicators-search__wrap btn-group">
              {
                hasPriv('insertIndicatorConf') &&
                <button
                  className="sure-button el-icon-plus"
                  icon="plus"
                  onClick={() => this.setState({
                    showEditBusinessIndicaWin: true,
                    isEdit: false
                  })}
                >添加配置</button>
              }
            </span>
          </div>

          <SdwTable
            columns={ this.getColumns() }
            dataSource={ _.isArray(tableData.items) ? tableData.items : [] }
          />

          <div style={{ marginTop: '16px' }}>
            <SdwPagenation
              total={ tableData.total }
              currentPage={ tableData.current_page }
              pageCountList={[10, 15, 20, 40]}
              pageCount={ tableData.per_page }
              getClickPage={ page => { this.setState({ curPage: page }) }}
              pageCountChange={ limit => { this.setState({ curLimit: limit }) }}
            />
          </div>
        </div>


        <SdwDrawer
          title={ isEdit ? '编辑配置' : '添加配置' }
          visible={showEditBusinessIndicaWin}
          onIconClick={() => this.setState({ showEditBusinessIndicaWin: false })}
          onCancelClick={() => this.setState({ showEditBusinessIndicaWin: false })}
          onSureClick={() => this.editBusinessIndicaRef.current.handleSubmit()}
        >
          <SdwDrawer.Body>
            <EditBusinessIndicaWin
              ref={this.editBusinessIndicaRef}
              targetList={this.state.targetList}
              indicatorsDimentionList={indicatorsDimentionList}
              currentConf={currentConf}
              changeVisibleFun={flag => this.setState({ showEditBusinessIndicaWin: flag })}
              isEdit={isEdit}
              getBusinessIndicatorsCfgListBySearch={this.getBusinessIndicatorsCfgListBySearch}
            />
          </SdwDrawer.Body>
        </SdwDrawer>
      </div>
    )
  }
}
