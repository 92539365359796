import { PureComponent } from "react"
import _ from 'lodash'
import SdwSelect from '@common/Select'
import SdwUpLoad from '@common/UpLoad'
import SdwButton from '@common/Button'
import SdwMessage from "@common/Message/index"
import { getServiceConfig, getAllModels, uploadFile } from '@services/dataGovern'

import './index.css'

export default class BusinessDataUpload extends PureComponent {

  state = {
    file: '',
    businessOption: [],
    modelOption: [],
    formData: {}
  }

  componentDidMount() {
    this.initData()
  }

  initData = () => {
    getServiceConfig().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const list = _.get(res, 'data.data')
        if (_.isArray(list)) {
          let arr = list.map(i => ({ id: i, text: i }))
          this.setState({businessOption: arr})
        }
      }
    })
  }

  updateFormData = (key, val) => {
    let cloneObj = _.cloneDeep(this.state.formData)
    cloneObj[key] = val
    if (key === 'service_name') {
      cloneObj['model_id'] = ''
      this.onGetModel(val)
    }
    this.setState({ formData: cloneObj })
  }

  onGetModel = service_name => {
    let params = {
      page: 1,
      limit: 1000,
      search: JSON.stringify({
        service_name: {
          value: service_name,
          operator: '='
        }
      })
    }
    getAllModels(params).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const items = _.get(res, 'data.data.items') || []
        let modelOption = items.map(i => ({
          id: i.id,
          text: i.model_name
        }))
        if (_.isArray(modelOption)) this.setState({ modelOption })
      }
    })
  }

  onSubmit = () => {
    const { formData, file } = this.state
    const fileName = _.get(file, '[0].name')
    const fileSize = _.get(file, '[0].size')
    // console.log('********file: ', file);

    if (!/\.(xlsx|xls)$/.test(fileName)) return SdwMessage.warning('仅支持导入上传xlsx/xls文件格式！')
    if (fileSize > 4*1024*1024) return SdwMessage.warning('仅支4M以内文件！')
    
    let params = new FormData()
    params.append('upload-file', file[0])
    params.append('file_name', fileName)
    params.append('id', formData.model_id)
    params.append('service_name', formData.service_name)

    uploadFile(params).then(res => {
      const code = _.get(res, 'data.code')
      if (code ===0) {
        this.setState({
          formData: {},
          file: ''
        })
        SdwMessage.success('导入文件成功！')
      } else {
        let msg = _.get(res, 'data.msg') || '导入文件失败！'
        SdwMessage.error(msg)
      }
    }).catch(err => SdwMessage.error('接口异常' + err))
  }

  render() {
    const { file, businessOption, modelOption, formData } = this.state

    return (
      <div className="data-govern__business-data-upload">
        <div className="center-box">
          <div>
            <span className='required' style={{marginRight: 16}}>所属业务</span>
            <SdwSelect
              placeholder='请选择所属业务'
              data={businessOption}
              value={formData.service_name}
              onChange={val => this.updateFormData('service_name', val)}
            />
          </div>
          <div>
            <span className='required' style={{marginRight: 16}}>模板类型</span>
            <SdwSelect
              placeholder={!formData.service_name ? '请先选择所属业务' : '请选择模板导入类型'}
              disabled={!formData.service_name}
              data={modelOption}
              value={formData.model_id}
              onChange={val => this.updateFormData('model_id', val)}
            />
          </div>
          <div>
            <span className='required' style={{marginRight: 16, verticalAlign: 'super'}}>导入文件</span>
            <SdwUpLoad 
              value={file} 
              width={474}
              height={32}
              accees=".xlsx,.xls" // 选择文件类型
              placeholder='请选择导入文件（限制4M以内）'
              tip='仅支持导入上传xlsx/xls文件格式，若需上传多个文件需多次上传'
              changeValue={file => this.setState({ file })}
            />
          </div>
          <div style={{marginTop: 100}}>
            <SdwButton
              text='上传'
              type='submit'
              disabled={!formData.model_id || !file}
              onClick={this.onSubmit}
            />
          </div>
        </div>
      </div>
    )
  }
}