import _ from "lodash"
import { Redirect, Route, Switch } from 'react-router-dom'
import { authMap } from '@components/FrontendAuth/AuthMap'
import NoRight from '@pages/NoRight'

// 路由守卫：判断当前路由是否能访问
function FrontendAuth(props) {
  let pathname = _.get(props, 'location.pathname')
  let permissions = sessionStorage.getItem('permissions')
  let permissionsIsEmpty = !permissions

  // 没有任何权限，直接空状态界面
  if (permissionsIsEmpty) return <Redirect to="/noright" from="*" />

  try {
    permissions = JSON.parse(permissions)
  } catch (error) {
    console.log(error);
  }

  // 路由存在，就需要校验权限
  let noRight = pathname && _.isArray(authMap[pathname]) && authMap[pathname].length && !authMap[pathname].some(key => permissions[key])
  if (noRight) {
    return <NoRight height={1000} />
  }

  if (_.isArray(props.Routers) && props.Routers.length) {
    return (
      <Switch>
        {props.Routers.map(router => <Route key={router.routerPath} path={router.routerPath} component={router.Router} />)}
        <Redirect to='/admin/dataMarket' from="*" />
      </Switch>
    )
  }

  return <Redirect to="/noright" from="*" />
}

export default FrontendAuth