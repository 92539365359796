import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwSelect from '@common/Select'
import SdwMessage from '@common/Message'
import './css/index.css'
import { getDetailInfo, getNodeFields, jobPublish } from '@services/midTable'
import {getTableApiItems } from '@services/dataAccess'
import { connect } from 'react-redux'

class JobPublish extends React.Component {

  state = {
    params:{
      "id": "",   //
      "table_id": "",   // 落地表id
      "table_primary_key": "",   // 多选，选节点的字段
      "result_id": "",    // 节点id
      "partition_field_id": "", // 分区字段
    },
    sholdCheckValidate:false,
    tableList: [],
    nodeList:[],
    clickItem:{},
    fieldsListOption:[],
  }

  componentDidMount () {
    this.initData()
    this.getDetailInfo()
    this.initTableList()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {params} = this.state
    if (prevState.params.id !== params.id) {
      if (!params.result_id) {
        this.setState({
          params:_.assign({}, params, {
            table_primary_key: "",
          }),
        })
      }
    }
  }

    initData = () => {
    let {stepList, editMidTable} = this.props
    let nodeList = []
    for (let i in stepList) {
      nodeList.push(stepList[i])
    }
    this.setState({
      clickItem:stepList[editMidTable.result_id]?stepList[editMidTable.result_id]:{},
      nodeList:nodeList
    }, this.iniDataSourceFields)
  }

  getDetailInfo = () => {
    let {editMidTable} = this.props
    getDetailInfo({
      id:editMidTable.id,
    }).then(res => {
      if (res.data && res.data.code === 0) {
        let detailInfo = res.data.data
        this.setState({
          params:{
            id: editMidTable.id,   //
            table_id: detailInfo.table_id || "",   // 落地表id
            table_primary_key: detailInfo.table_primary_key || "",   // 多选，选节点的字段
            result_id: detailInfo.result_id || "",    // 节点id
            partition_field_id: detailInfo.partition_field_id || "",   // 分区字段
          },
        })
      }
    }, () => SdwMessage.error("接口异常"))
  }

  initTableList = () => {
    // 目标表参数
    let paramData = {
      limit: 10000,
      search: JSON.stringify({"warehouse_level":{"value":"DWD","operator":"="},"enable":{"value":1,"operator":"="}})
    }

    // 目标表
    getTableApiItems(paramData).then(res => {
      if (res && res.data && res.data.code === 0) {
        let curArr = Array.isArray(res.data.data.items) ? res.data.data.items : []
        curArr = curArr.map(i => {
          let curName = !!i.database_name ? `${i.database_name}.${i.table_name}` : `${i.table_name}`
          return Object.assign({}, i, {
            text: `${curName}（${i.data_set_chinese}）`,
          })
        })

        this.setState({tableList: curArr})
      } else {
        SdwMessage.error('获取落地表数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('获取落地表接口异常：' + error)
      })
  }

  handleSubmit =() => {
    let {params} = this.state
    // console.log("params=======", params)
    if (!(params.result_id && params.table_id && params.table_primary_key)) {
      this.setState({
        sholdCheckValidate:true
      })
      return
    }
    jobPublish({
      ...params
    }).then(res => {
      if (res.data && res.data.code === 0) {
        this.props.updatePage(params.id)
        this.props.changeShowAddWin(false)
        SdwMessage.success("发布成功")
      } else {
        let msg = res.data && res.data.data
        SdwMessage.error(msg);
      }
    }, () => SdwMessage.error("接口异常"))
  }

  //初始字段列表
  iniDataSourceFields = () => {
    let {clickItem} = this.state
    let item = clickItem.item
    if (!item) {
      return
    }
    let params = {
      id:item.job_id,
      node_id:item.id,
    }
    if (item.hasOwnProperty("link") && item.link) {
      params.link_id = item.link.id
    }
    getNodeFields(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || []
        this.setState({
          fieldsListOption:record
        })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }
  //切换节点
  changeClickTableView = (item) => {
    // console.log("====item", item)
    let {params} = this.state
    this.setState({
      params:_.assign({}, params, {
        result_id:item.id,
        table_primary_key:"",
      }),
      clickItem:item
    }, this.iniDataSourceFields)
  }

  setFormValue = (type, val) => {
    let { params } = this.state
    let obj = {}
    switch (type) {
      case "table_id":
        obj = {
          table_id: val
        }
        break
      case "table_primary_key":
        obj = { table_primary_key: val }
        break
      case "result_id":
        obj = { result_id: val }
        break
      case "partition_field_id":
        obj = { partition_field_id: val }
        break
    }
    this.setState({
      params: Object.assign({}, params, {
        ...obj
      })
    })
  }
  render () {
    let {
      showPublishDialog,
      changeShowAddWin,
    } = this.props

    let {
      params,
      tableList,
      nodeList,
      sholdCheckValidate,
      fieldsListOption,
    } = this.state

    return (
      <div>
          <SdwDialog
              title={"发布中间表"}
              visible={showPublishDialog}
              width={600}
              height={500}
              changeVisible={changeShowAddWin}
              onCancelClick={() => changeShowAddWin(false)}
              onSureClick={this.handleSubmit}
          >
              <div className='add-AddWin-win__label'>
                  <span className='label__title required'>落地表</span>
                  <SdwSelect
                      width={450}
                      placeholder={"请选择落地的数据表"}
                      listWidth={450}
                      clearable={false}
                      readOnly={false}
                      value={params.table_id}
                      data={tableList}
                      onChange={val => {
                          this.setFormValue("table_id", val)
                      }}
                  />
              </div>
              <div className='add-AddWin-win__label'>
                  <span className='label__title required'>节点</span>
                  <SdwSelect
                      width={450}
                      placeholder={"请选择节点"}
                      listWidth={450}
                      clearable={false}
                      readOnly={false}
                      value={params.result_id}
                      data={nodeList}
                      onChange={(val, data) => {
                          this.changeClickTableView(data)
                      }}
                  />
              </div>
              <div className='add-AddWin-win__label'>
                  <span className='label__title required'>主键</span>
                  <SdwSelect
                      isMultipleChoice={true}
                      placeholder={"请选择主键，支持多个字段组合"}
                      width={450}
                      listWidth={450}
                      listMaxHeight={150}
                      clearable={false}
                      readOnly={false}
                      value={params.table_primary_key ? params.table_primary_key.split(',').map(i => {
                          return {id: i, text: i}
                      }) : []}
                      data={_.isArray(fieldsListOption) ? fieldsListOption.map(i=>{
                          return {id:i.field, text:i.field, item:i}
                      }) : []}
                      onChange={(val) => {
                          this.setFormValue("table_primary_key", val.map(i => {
                              return i.id
                          }).join(','))
                      }}
                      sholdCheckValidate={sholdCheckValidate}
                      validateFun={val => {
                          return val.length > 0 ? true : "主键不能为空"
                      }}
                  />
              </div>
              <div className='add-AddWin-win__label'>
                  <span className='label__title'>分区字段</span>
                  <SdwSelect
                      placeholder={"默认为【加工实例时间】"}
                      width={450}
                      listWidth={450}
                      listMaxHeight={150}
                      value={params.partition_field_id}
                      data={_.isArray(fieldsListOption) ? fieldsListOption.map(i=>{
                          return {id:i.id, text:i.field, item:i}
                      }) : []}
                      onChange={val => {
                        this.setFormValue("partition_field_id", val)
                      }}
                  />
              </div>
          </SdwDialog>
      </div>
    )
  }
}

const mapState = state => ({
    stepList: state.midTableView.stepList,
    cfgData: state.midTableView.cfgData,
})

const mapDispatch = dispatch => ({
    updateStepList: dispatch.midTableView.updateStepList
})
export default connect(mapState, mapDispatch)(JobPublish)
