// 重跑设置弹窗
import { useState, useEffect, useMemo, useLayoutEffect } from "react"
import _ from 'lodash'
import Dialog from "@common/Dialog"
import Select from "@common/Select"
import Input from "@common/Input"
import Popover from "@common/Popover"
import Switch from "@common/Switch"
import Message from "@common/Message"
import { storeOrUpdateDepth } from '@services/application'
import './style/ReRunWin.css'
import { commonApi } from "@utils/request"
import { formatJsonParse } from "@utils/formatJson"

export default function ReRunWin(props) {
  const {
    record,
    cfgData,
    visible,
    onSureClick,
    setReRunVisible,
  } = props

  const [empty_drop, setEmpty_drop] = useState(0)
  // delay_expression：[{"time_cycle": "1d", "delay_num":"1/days"}, {"time_cycle": "30d", "delay_num":"1/days"}]
  const [delay_expression, setDelay_expression] = useState([{}])
  const [timeCycle, setTimeCycle] = useState([])

  // 找出是否有2个完全相同的设置：0标识没有完全相同设置，大于0标识有，返回对应的index，用于显示errtip
  const isSameIndex = useMemo(() => {
    let flag = []
    for (let i = 0; i < delay_expression.length; i++) {
      const prev = delay_expression[i];
      for (let j = i+1; j < delay_expression.length; j++) {
        const next = delay_expression[j];
        if (prev.time_cycle === next.time_cycle && prev.delay_num === next.delay_num) {
          flag.push(j)
          break
        }
      }
    }
    return flag
  }, [delay_expression])

  // 数据回显
  useLayoutEffect(() => {
    // console.log('---record: ', record);
    const {
      empty_drop = 0,
      delay_expression = '""'
    } = record || {}

    let delayExpression = formatJsonParse(delay_expression)
    delayExpression = (_.isArray(delayExpression) && !!delayExpression.length) ? delayExpression.map(i => ({
      time_cycle: i.time_cycle,
      delay_num: i.delay_num.slice(0, -5) // 保存的是加了 /days 的，回显只需要前面的数字
    })) : [{}]
    console.log('数据回显: delayExpression: ', delayExpression);
    setDelay_expression(delayExpression)

    setEmpty_drop(empty_drop)
  }, [record])

  useEffect(() => {
    const arr = _.get(record, 'time_schedule') || []
    let timeCycle = (!!_.get(cfgData, 'timeCycle') && _.isArray(cfgData.timeCycle)) ? cfgData.timeCycle : []
    if (!_.isArray(arr) || !arr.length || !timeCycle.length) {
      setTimeCycle([])
      return
    }

    const selectedTimeCycleArr = arr.map(i => i.time_cycle)

    timeCycle = timeCycle.filter(j => selectedTimeCycleArr.includes(j.id)).map(i => ({
      id: i.id,
      text: i.name
    }))

    // console.log('time_schedule: ', arr);
    setTimeCycle(timeCycle)
  }, [record.time_schedule, cfgData])

  const handleChange = (index, key, val) => {
    let cloneDelayExpression = _.cloneDeep(delay_expression)
    cloneDelayExpression[index] = _.assign({}, cloneDelayExpression[index], {
      [key]: val
    })
    setDelay_expression(cloneDelayExpression)
  }

  const handleAdd = () => {
    let cloneDelayExpression = _.cloneDeep(delay_expression)
    cloneDelayExpression.push({})
    setDelay_expression(cloneDelayExpression)
  }

  const handleDel = index => {
    let cloneDelayExpression = _.cloneDeep(delay_expression)
    cloneDelayExpression.splice(index, 1)
    setDelay_expression(cloneDelayExpression)
  }

  const handleSureClick = () => {
    if (!record.id) {
      Message.error('没有指标id，请刷新界面重试')
      return
    }
    // console.log('isSameIndex: ', isSameIndex);
    // 校验不通过
    if (isSameIndex.length) return

    let cloneDelayExpression = _.cloneDeep(delay_expression)
    let cloneDelayExpressionClone = cloneDelayExpression.filter(i => !!i.delay_num && !!i.time_cycle)
    let cloneDelayExpressionCloneFilter = cloneDelayExpression.filter(i => !!i.delay_num || !!i.time_cycle)
    if (cloneDelayExpressionClone.length != cloneDelayExpressionCloneFilter.length) {
      Message.error('任务设置不可为空')
      return
    }
    setDelay_expression(cloneDelayExpressionClone) // 删除设置不全的配置

    // 组装下发参数格式
    cloneDelayExpressionClone = cloneDelayExpressionClone.map(i => ({
      time_cycle: i.time_cycle,
      delay_num: i.delay_num + '/days'
    }))
    // console.log('aaa: ', empty_drop, cloneDelayExpression);
    commonApi(storeOrUpdateDepth, {
      id: record.id,
      field: {
        empty_drop,
        delay_expression: JSON.stringify(cloneDelayExpressionClone)
      }
    }).then(data => {
      setReRunVisible(false)
      onSureClick()
      Message.success('重跑设置成功')
    }).catch(() => Message.error('重跑设置失败'))
  }

  return (
    <>
      <Dialog
        title='重跑设置'
        width={666}
        cusWrapClass='derived__rerun-win-wrap'
        visible={visible}
        changeVisible={setReRunVisible}
        onCancelClick={() => setReRunVisible(false)}
        onSureClick={handleSureClick}
      >
        <div style={{
          display: 'flex',
        }}>
          <div style={{
            lineHeight: '40px',
            flex: '0 0 80px'
          }}>
            <span>定时重跑</span>
            <Popover tip='按照选定时间周期的每一轮跑数后的结束时间end_time，在其间隔天数所在日期进行重跑'>
              <i className="icon-tip" />
            </Popover>
          </div>

          <div>
            {
              _.isArray(delay_expression) &&
              delay_expression.map((item, index) => {
                const selectedArr = delay_expression.map(i => i.time_cycle)
                const curTimeCycle = timeCycle.filter(i => !selectedArr.includes(i.id))

                return (
                  <div key={index} style={{
                    display: 'inline-block',
                    marginBottom: 16
                  }}>
                    <span style={{
                      marginLeft: 8
                    }}>
                      <Select
                        width={200}
                        listWidth={200}
                        data={timeCycle}
                        placeholder='请选择时间周期'
                        value={delay_expression[index] && delay_expression[index]['time_cycle'] || ''}
                        onChange={val => handleChange(index, 'time_cycle', val)}
                      />
                    </span>
                    <span style={{
                      marginLeft: 8
                    }}>
                      <Input
                        width={80}
                        errTip={(delay_expression[index] && delay_expression[index]['delay_num'] && isSameIndex.includes(index)) ? '任务设置不可重复' : ''}
                        placeholder='间隔天数'
                        value={delay_expression[index] && delay_expression[index]['delay_num'] || ''}
                        onChange={val => handleChange(index, 'delay_num', val)}
                      />
                    </span>
                    <span>
                      <i onClick={handleAdd} className="el-icon-plus"></i>
                      {
                        index > 0 &&
                        <i onClick={() => handleDel(index)} className="el-icon-minus"></i>
                      }
                    </span>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div>
          <span style={{
            marginRight: 8
          }}>重跑指标为0正常入库</span>
          <Switch
            value={empty_drop}
            changeValue={setEmpty_drop}
          />
        </div>
      </Dialog>
    </>
  )
}
