import React, { Component } from "react";
import PropTypes from 'prop-types'
import "./index.css";
import { hGClose } from "@utils/handleGlobalClose";
import _ from "lodash";

class Cascader extends Component {
  cascaderInputWrapRef = React.createRef()
  selectInputRef = React.createRef()
  static defaultProps = {
    width: '100%',
    listWidth: 200,
    listMaxHeight: 300,
    height: 40,
    placeholder: '全部',
    value: {
      ids: [],
      texts: []
    },
    data: [],
  }
  state = {
    showCascaderList: false,
    showCascaderSecondList: false,
    secondList: [],
    fuzzyData:[],
    inputValue: '',
    showClearIcon: false,
    isValidatePass: true,
    validateText: ''
  }
  componentDidMount() {
    document.addEventListener('click', this.handleGlobalClose)
    let { value } = this.props
    if (value.texts.length > 1) {
      this.setState({
        inputValue: value.texts.join('/')
      })
    }
  }
  componentDidUpdate(prevProps,prevState, snapshot){
    let {data,value,sholdCheckValidate} = this.props
    if(prevProps.data.length !== data.length){
      this.setState({
        fuzzyData: data
      })
    }
    if(prevProps.value.texts.length !== value.texts.length || prevProps.value !== value ){
      if (value.texts.length > 1) {
        this.setState({
          inputValue: value.texts.join('/')
        })
      }
    }

    if (prevProps.sholdCheckValidate !== sholdCheckValidate) {
      this.validate(value)
    }

  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClose)
  }
  handleGlobalClose = e => {
    hGClose(e, this.cascaderInputWrapRef.current, this, 'showCascaderList')
  }
  cascaderToggle = () => {
    this.setState({
      showCascaderList: !this.state.showCascaderList
    })
  }
  toGetSecondList = (e, item) => {
    let selectItem = this.selectItem = {
      ids: [item.id],
      texts: [item.text]
    }

    this.props.ajaxFun(item, selectItem).then(res => {
      if (Array.isArray(res)) {
        this.setState({
          secondList: res,
          showCascaderSecondList: true
        })
      }
    })
  }
  setValue = (e, item) => {
    let selectItem = this.selectItem
    let newSelectItem = {
      ids: [selectItem.ids[0], item.id],
      texts: [selectItem.texts[0], item.text]
    }
    // 把选中的数据传递回上层
    this.props.onChange(newSelectItem)
    this.validate(newSelectItem)
    this.setState({
      showCascaderList: false
    })
  }
  fuzzyFilter = (value) => {
    let {data} = this.props
    let filterArr = data.filter(i => i.text.includes(value))
    this.setState({
      showCascaderList:true,
      fuzzyData: filterArr,
      inputValue: value
    })
  }
  handleClearInput = e => {
    e.preventDefault()
    this.setState({
      inputValue: ''
    })
    let temObj ={ids: [],texts: []}
    this.props.onChange(temObj)
    this.validate(temObj)
    this.selectInputRef.current.focus()
  }
  validate = curRecord => {

    // 如果没有传入valiateFun进行校验，直接跳过
    if (typeof this.props.validateFun !== 'function') {
      return
    }

    let res = this.props.validateFun(curRecord)

    // validateFun只有返回true，才会校验通过
    if (res === true) {
      this.setState({
        isValidatePass: res,
        validateText: ''
      })
    } else {
      this.setState({
        isValidatePass: false,
        validateText: res
      })
    }
  }
  render() {
    let { data,placeholder, isError, width, listWidth, listMaxHeight, value,clearable } = this.props
    let { showCascaderList, showCascaderSecondList, secondList,inputValue ,fuzzyData,showClearIcon,isValidatePass,validateText,} = this.state

    if(inputValue === '' || !fuzzyData.length) {
      fuzzyData = _.cloneDeep(data)
    }
    let isErrorTip = isError && isError.error
    let errorMsg = isError && isError.msg

    if (!isError) {
      isErrorTip = !isValidatePass
      errorMsg = validateText
    }

    let { cascaderToggle, toGetSecondList, setValue, handleClearInput } = this

    return (
      <div
        style={{ display: 'inline-block', position: 'relative', width: width ? width : '100%' }}
        ref={this.cascaderInputWrapRef}
        onMouseEnter={() => this.setState({ showClearIcon: true })}
        onMouseLeave={() => this.setState({ showClearIcon: false })}
      >
        <input
          className={`sdw-req-cascader__wrap ${showCascaderList ? 'sdw-req-select__show-selected' : ''} ${isErrorTip ? 'sdw-req-error-input' : ''}`}
          value={inputValue}
          placeholder={placeholder}
          ref={this.selectInputRef}
          onClick={cascaderToggle}
          onChange={(e)=> this.fuzzyFilter(e.target.value)}
          style={{ width: '100%', height: '40px' }} />
        <i onClick={(showClearIcon && clearable) ? handleClearInput : cascaderToggle}
          className={`sdw-req-cascader__icon sdw-req-cascader__drop-down
          ${clearable && showClearIcon && inputValue ? 'sdw-select-clearable':''}
          ${showCascaderList ? 'sdw-req-cascader__drop-up' : ''}`}></i>

        { isErrorTip && <div className="sdw-req-error-input__tip">{errorMsg}</div>}

        {
          showCascaderList &&
          <div className="sdw-req-cascader-list-wrap">

            <div className="sdw-req-cascader-list__area-wrap" style={{ width: listWidth, maxHeight: listMaxHeight }}>
              {
                 Array.isArray(fuzzyData) && fuzzyData.length > 0 ?
                  fuzzyData.map(item => {
                    let isSelected = value.ids && value.ids[0] === item.id
                    return (
                      <div key={item.id} className={"sdw-req-cascader-list__area " + (isSelected ? ' is_selected' : '')} onClick={(e) => toGetSecondList(e, item)}>
                        {item.text}
                        <i className="sdw-req-cascader__list-item-right-icon"></i>
                      </div>
                    )
                  })
                  :
                  <div className="sdw-req-cascader-list__empty-item">
                      暂无匹配数据
                  </div>
              }

            </div>

            {
              showCascaderSecondList &&
              <div className="sdw-req-cascader-list__area-wrap" style={{ width: listWidth, maxHeight: listMaxHeight }}>
                {
                  Array.isArray(secondList) && secondList.length > 0 ?
                    secondList.map(item => {
                      let isSelected = value.ids && value.ids[1] === item.id
                      return (
                        <div key={item.id} className={"sdw-req-cascader-list__area " + (isSelected ? ' is_selected' : '')} onClick={(e) => setValue(e, item)}>
                          {item.text}
                        </div>
                      )
                    })
                    :
                    <div className="sdw-req-cascader-list__empty-item">
                      暂无匹配数据
                    </div>
                }
              </div>
            }

          </div>
        }
      </div>
    )
  }
}

Cascader.propTypes = {
  data: PropTypes.array,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listWidth: PropTypes.number,
  listMaxHeight: PropTypes.number,
  value: PropTypes.object,
  height: PropTypes.number,
  placeholder: PropTypes.string,
  ajaxFun: PropTypes.func
}

export default Cascader
