import { request } from '../utils/request'

//中间表分页列表
export const tablePages = params => {
  return request({ url: '/metadata/midTable/tablePages', method: 'post', data: params })
}

//获取中间表详情
export const getDetailInfo = params => {
  return request({ url: '/metadata/midTable/getDetailInfo', method: 'get', params: params })
}
//获取字段配置
export const fieldItems = params => {
  return request({ url: '/metadata/midTable/fieldItems', method: 'get', params: params })
}
//获取落地表列表
export const selectTableList = params => {
  return request({ url: '/metadata/midTable/selectTableList', method: 'get', params: params })
}
//保存中间表
export const saveTable = params => {
  return request({ url: '/metadata/midTable/saveTable', method: 'post', data: params })
}
//保存物理表
export const saveNode = params => {
  return request({ url: '/metadata/midTable/saveNode', method: 'post', data: params })
}
//保存节点
export const saveLink = params => {
  return request({ url: '/metadata/midTable/saveLink', method: 'post', data: params })
}
//获取节点的聚合字段
export const getNodeFields = params => {
  return request({ url: '/metadata/midTable/getNodeFields', method: 'get', params: params })
}

//获取节点的聚合字段
export const getNodeRelateFields = params => {
  return request({ url: '/metadata/midTable/getNodeRelateFields', method: 'get', params: params })
}

//详情树  结构接口
export const getDetailTreeInfo = params => {
  return request({ url: '/metadata/midTable/getDetailTreeInfo', method: 'get', params: params })
}
// 获取所有的计算方式
export const getPublicCfg = (params = {}) => {
  return request({ url: '/dataset/common/getPublicCfg', method: 'get', data: params })
}
//保存字段
export const saveFields = params => {
  return request({ url: '/metadata/midTable/saveFields', method: 'post', data: params })
}
//删除字段
export const deleteFields = params => {
  return request({ url: '/metadata/midTable/deleteFields', method: 'post', data: params })
}
//删除节点
export const deleteNode = params => {
  return request({ url: '/metadata/midTable/deleteNode', method: 'post', data: params })
}
//预览数据
export const tablePreview = params => {
  return request({ url: '/metadata/midTable/tablePreview', method: 'post', data: params })
}
//中间表发布
export const jobPublish = params => {
  return request({ url: '/metadata/midTable/jobPublish', method: 'post', data: params })
}
//中间表停止
export const jobDisable = params => {
  return request({ url: '/metadata/midTable/jobDisable', method: 'post', data: params })
}
//中间表重跑
export const taskReRun = params => {
  return request({ url: '/metadata/midTable/taskReRun', method: 'post', data: params })
}
