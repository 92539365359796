import React from 'react'
import PropTypes from 'prop-types'

import './mulIndex.css'

class Radiobox extends React.Component {

  static defaultProps = {
    changeCheckedFun: () => {},
    options: []
  }

  render() {
    let { options, value, colorStyle } = this.props
    let className = "sdw-mul-radiobox__wrap"
    if (colorStyle == "light") {
      className += " light"
    } else {
      className += " deep"
    }
    return (
      <div className={className}>
        {
          Array.isArray(options) &&
          !!options.length &&
          options.map((item, index) => (
            <span
              key={index}
              className={ value === item.value ? 'current': ''}
              onClick={() => this.props.changeCheckedFun(item.value)}
            >
              <input
                value={item.text}
                type="radio"
                checked={value == item.value}
                onChange={() => {}}
                name={`onlyOne_${options[0].value}_${options[0].text}`}
              />
              <span className={ value === item.value ? 'radiobox-text ': 'radiobox-text'}>{item.text}</span>
            </span>
          ))
        }
      </div>
    )
  }
}

Radiobox.propTypes = {
  options: PropTypes.array,
  colorStyle: PropTypes.string,
  changeCheckedFun: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
}

export default Radiobox
