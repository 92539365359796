import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwMessage from '@common/Message'
import {Checkbox} from 'element-react'
import {getDelayTargets, saveAppDelay, getAppDetail} from '@services/application'
import './style/reRunWin.css'

export default class RegularRerunWin extends React.Component {

    state = {
        delayList: [], // 可选指标
        checkList: [], // 已选指标
    }

    componentDidMount() {
        this.getDelayTargets()
        this.getAppDetail()
    }

    // 获取重跑指标
    getDelayTargets = () => {
        let {currentData} = this.props
        // console.log('currentData=====', currentData)
        let params = {
            'id': currentData.id
        }
        getDelayTargets(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let record = res.data.data || []
                // console.log('getDelayTargets=====', record)
                this.setState({
                    delayList: record
                })
            }
        })
    }

    // 多选框回显
    getAppDetail = () => {
        let {currentData} = this.props
        let params = {
            'id': currentData.id
        }
        getAppDetail(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let array
                let items = res.data.data.delay_ids || ''
                if (items) {
                    array = (items.split(',')).map(Number)
                } else {
                    array = []
                }
                this.setState({
                    checkList: array
                })
            }
        })
    }

    // 提交重跑
    handleSubmit = () => {
        let {currentData} = this.props
        let {delayList, checkList} = this.state
        if (delayList.length == 0) {
            SdwMessage.error('暂无相关指标')
            return
        }
        let params = {
            'id': currentData.id,
            'target_ids': checkList
        }
        saveAppDelay(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                SdwMessage.success('定时重跑提交成功')
                this.props.changeDelayReRunVisible(false)
            }
        }).catch(() => SdwMessage.error('定时重跑提交失败'))
    }

    render() {
        let {dialogVisible, changeDelayReRunVisible} = this.props
        let {delayList, checkList} = this.state

        // console.log('checkList======', checkList)

        return (
            <SdwDialog
                title='应用表定时重跑设置'
                visible={dialogVisible}
                onCancelClick={() => changeDelayReRunVisible(false)}
                onSureClick={this.handleSubmit}
                width={640}
            >
                <div>
                    {
                        _.isArray(delayList) && delayList.length > 0 ?
                            <span style={{fontSize: '14px', fontWeight: '500', marginRight: '16px'}}>
                                已设置重跑指标
                                <span style={{color: '#999', fontWeight: '400'}}>
                                    （勾选后，将按照指标设置的定时重跑规则进行重跑）
                                </span>
                            </span> :
                            <span style={{fontSize: '14px', fontWeight: '500', marginRight: '16px'}}>
                                暂无相关指标
                            </span>
                    }
                    <div>
                        <div className='app-rerun__checkbox-wrap'>
                            <Checkbox.Group value={checkList}>
                                {
                                    delayList.map((item) => (
                                        <Checkbox
                                            label={item.target_id}
                                        >{`${item.target_name}（${item.target_name_cn}）`}</Checkbox>
                                    ))
                                }
                            </Checkbox.Group>
                        </div>
                    </div>
                </div>
            </SdwDialog>
        )
    }
}
