// 指标类型

export const TYPE_MAP = {
  one: 1,
  two: 2,
  three: 3,
  four: 4
}

export const TYPE_MAP_TEXT = {
  [TYPE_MAP.one]: '基础指标',
  [TYPE_MAP.two]: '复合指标',
  [TYPE_MAP.three]: '代码型指标',
  [TYPE_MAP.four]: '衍生指标'
}
