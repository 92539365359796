// @file 已选指标，前端分页
import {useState, useMemo} from 'react'
import _ from 'lodash'
import Table from '@common/Table'
import Pagenation from '@common/Pagenation'
import Select from "@common/Select"
import Input from "@common/Input"
import '../ApiDetail/style/selectedAttributes.css'

export default function SelectedAttributesTwo(props) {

    const {
        columns,
        dataSource,
        isDisplayPage
    } = props

    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(10)
    const [searchValue, setSearchValue] = useState({field: "a.id", value: ""})

    const curDataSource = useMemo(() => {
        if (!_.isArray(dataSource) || !dataSource.length) return []
        const start = (currentPage - 1) * pageCount
        const end = currentPage * pageCount
        return dataSource.slice(start, end)
    }, [currentPage, pageCount, dataSource])

    const Total = _.isArray(dataSource) ? dataSource.length : 0

    return (
        <div style={{padding: '10px'}} className='selected-attributes-body'>
            <div style={{lineHeight: '44px', display: "flex", marginBottom: "20px"}}>
                <div style={{"flex": "1"}}>
                    <Select
                        width={120}
                        borderTopRightRadius={0}
                        borderBottomRightRadius={0}
                        value={searchValue.field}
                        data={[{
                            id: 'a.id',
                            text: '字段ID '
                        }, {
                            id: 'a.target_id',
                            text: '指标ID'
                        }, {
                            id: 'b.target_name',
                            text: '指标英文名'
                        }, {
                            id: 'b.target_name_cn',
                            text: '指标中文名'
                        }, {
                            id: 'a.short_name',
                            text: '指标简称'
                        }]}
                        onChange={i => {
                            setSearchValue(_.assign({}, searchValue, {field: i, value: ""}))
                            props.setSearch(i, "")
                        }}
                    />
                    <Input
                        width={260}
                        placeholder={searchValue.field == 'a.id' || searchValue.field == 'a.target_id' ? '使用,号分隔' : '请输入'}
                        borderTopLeftRadius={0}
                        borderBottomLeftRadius={0}
                        value={searchValue.value}
                        onChange={val => {
                            setSearchValue(_.assign({}, searchValue, {value: val}))
                            props.setSearch(searchValue.field, val)
                        }}
                    />
                </div>
            </div>

            <Table
                tdWhiteSpace={true}
                columns={columns()}
                dataSource={curDataSource}
            />

            {isDisplayPage && <Pagenation
                total={Total}
                currentPage={currentPage}
                pageCount={pageCount}
                // pageCountList={ [15, 30, 40, 50] }
                getClickPage={val => setCurrentPage(val)}
                pageCountChange={val => setPageCount(val)}
            />}
        </div>
    )
}
