import React from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import SdwButton from '@common/Button'
import {Tabs} from 'element-react'
import {pageApiTargets, previewTargets} from '@services/api'
import SdwMessage from '@common/Message'
import {TOP_MAP, TOP_MAP_TEXT} from '../common'
import {withRouter} from 'react-router-dom'
import SelectedAttributes from './SelectedAttributes'
import SdwInput from '@common/Input'
import './style/tabPage.css'
import ReRunWin from '../ReRunWin'
import RegularRerunWin from '../RegularRerunWin'
import SdwLoading from '@common/Loadding'
import SdwSelect from '@common/Select'
import ReactJson from 'react-json-view'

const CUR_TARGET_INDEX = '1'

class TabPage extends React.PureComponent {

    state = {
        previewData: [], // 预览数据
        previewTableHeader: [], // 预览数据表头
        curActiveName: CUR_TARGET_INDEX,
        setTopVisible: false,
        curClickTargetData: {}, // 当前设置点击的对象，设置指标 or 删除的项
        curClickTreeValue: {},
        search: {},
        searchData: {
            page: 1,
            limit: 1000,
        },
        previewColumns: [],
        cmdStr: "",
        reRunVisible: false,
        disTestTableDataVisible: false,
        disTestTableText: "",
        isLoading: true,
        shouldVerify: false,
        jsonData: {},
    }

    componentDidMount() {
        //this.getPublicCfg()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 预览数据，时间搜索
        if (prevProps.curClickTreeValue !== this.props.curClickTreeValue) {
            this.onSearchPreviewData()
        }
    }

    onSearchPreviewData = () => {
        let {curClickTreeValue} = this.props
        let {search, searchData} = this.state
        let searchClone = Object.assign({}, search, {
            // template_id: curClickTreeValue.id
        })
        this.setState({
            search: searchClone
        })
        let params = {
            "template_id": curClickTreeValue.id,
            "search": {},
            "orSearch": {},
            ...searchData
        }
        for (const key in searchClone) {
            if (key !== "form_data" && key !== "env" && key !== "") {
                const dval = searchClone[key]
                if (dval.value !== "") {
                    params.search[key] = dval
                }
            }
        }
        pageApiTargets(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let targetData = res.data.data || []
                this.props.updatePageApiTargets(targetData.items)
            }
        }, () => SdwMessage.error("接口异常"))
    }

    setSearch = (type, value) => {
        let {search, searchData} = this.state
        let searchObj
        if (type === "a.id" || type === "a.target_id") {
            if (value) {
                searchObj = {operator: "in", value: value.split(",")}
            } else {
                searchObj = {operator: "in", value: ""}
            }
        } else {
            searchObj = {operator: "like", value: value}
        }
        this.setState({
            search: {
                [type]: _.assign({}, search[type], searchObj)
            },
            searchData: _.assign({}, searchData, {
                page: 1
            })
        }, () => {
            this.onSearchPreviewData()
        })
    }

    //调用测试 搜索
    //targetData  data 数据列表  header表格头
    initTestTableData = () => {
        // let { currentData, curClickTreeValue} = this.props
        let search = this.state.search
        let {form_data, env} = search
        console.log("------------search", search)
        // if (!template_id) {
        //   SdwMessage.error("请先选择指标API")
        //   return
        // }
        if (!form_data || !env) {
            this.setState({
                shouldVerify: true
            })
            return
        }
        this.setState({
            disTestTableDataVisible: true,
            isLoading: true,
            disTestTableText: "数据调用中"
        })
        let params = {
            ...search
        }
        previewTargets(params)
            .then(res => {
                if (res && res.data && res.data.code === 0) {
                    let jsonData = res.data.data.data || {}
                    this.setState({
                        jsonData: jsonData,
                        isLoading: false,
                    })
                } else {
                    SdwMessage.error("接口调用异常,请核对搜索条件")
                    this.setState({
                        isLoading: false,
                    })
                }
            }, () => {
                SdwMessage.error("接口异常")
                this.setState({
                    isLoading: false,
                })
            })
    }

    getColumns = () => {
        return [
            {
                title: "字段ID",
                dataIndex: "id",
                width: 55
            },
            {
                title: "指标ID",
                dataIndex: "target_id",
                width: 55
            },
            {
                title: "中文名",
                dataIndex: "target_name_cn",
                width: 98
            },
            {
                title: "英文名",
                showTip: true,
                dataIndex: "target_name",
                width: 130
            },
            {
                title: "时间",
                dataIndex: "time_cycle",
                width: 28
            },
            {
                title: "维度",
                dataIndex: "dimension_value",
                width: 112
            },
            {
                title: "过滤",
                dataIndex: "filter_str",
                width: 112
            },
            {
                title: "简称",
                dataIndex: "short_name",
                width: 60
            },
            {
                title: "排序",
                dataIndex: "sort_value",
                width: 50
            },
            {
                title: "全称",
                width: 120,
                dataIndex: "full_name",
            },
            {
                title: "单位",
                width: 120,
                dataIndex: "table_unit",
            },
            {
                title: "分组",
                width: 120,
                dataIndex: "group_value",
            },
            {
                title: "关联预警",
                width: 120,
                dataIndex: "alarm_ids",
            },
            {
                title: "备注",
                width: 120,
                dataIndex: "remark",
            },
            {
                title: "聚合方式",
                width: 100,
                render: data => {
                    let arr = {
                        "sum": "求和",
                        "avg": "平均",
                        "calculate": "比率"
                    }
                    let msg = arr[data.agg_name]
                    return <span>{msg}</span>
                }
            },
            {
                title: "计算公式",
                width: 150,
                dataIndex: "agg_calculate",
            }
        ]
    }

    handleEdit = (val) => {
        console.log("ApiDetail==========handleEdit_props", this.props)
        if (Object.keys(val).length == 0) {
            SdwMessage.error("请先选择账号专区API")
            return
        }
        console.log("========handleEdit", val)
        this.props.history.push({
            pathname: '/admin/factory/indexDetail',
            state: val
        })
    }

    // 重跑
    reRunVisible = (val) => {
        console.log("ApiDetail==========reRunVisible_props", this.props)
        if (Object.keys(val).length == 0) {
            SdwMessage.error("请先选择账号专区API")
            return
        }
        this.changeReRunVisible(true)
    }

    // 重跑弹窗
    changeReRunVisible = flag => {
        this.setState({
            reRunVisible: flag
        })
    }

    // 定时重跑
    regularRerunVisible = () => {
        this.changeRegularReRunVisible(true)
    }

    // 定时重跑弹窗
    changeRegularReRunVisible = flag => {
        this.setState({
            regularRerunVisible: flag
        })
    }

    handleSetButtom = data => {
        this.setState({
            curClickTargetData: data
        }, () => {
            this.changeSetTopVisible(true)
        })
    }

    handleDeleteButtom = data => {
        let {curShowSelectedTarget} = this.props
        let arr = _.cloneDeep(curShowSelectedTarget)

        let index = arr.findIndex(i => i.time === data.time && i.dimension === data.dimension)
        arr.splice(index, 1)

        this.props.updateCurShowSelectedTarget(arr)

        // 还原勾选
        this.props.onReWriteData(arr)
    }

    updateTargetValue = (curTarget, params) => {
        let {curShowSelectedTarget} = this.props
        let arr = _.cloneDeep(curShowSelectedTarget)

        arr.forEach(item => {
            if (curTarget.id === item.id && item.time === curTarget.time && item.dimension === curTarget.dimension) {
                item.target_type = params.selectInputValue
                item.target_top = params.selectInputValue === TOP_MAP.top ? params.inputValue : null
            }
        })

        this.props.updateCurShowSelectedTarget(arr)
    }

    changeSetTopVisible = flag => {
        this.setState({
            setTopVisible: flag
        })
    }

    onCancel = () => {
        let {oriAllSelectedTarget} = this.props

        this.props.updateCurShowSelectedTarget(oriAllSelectedTarget)
        this.props.onReWriteData(oriAllSelectedTarget)
    }

    render() {
        let {
            search,
            curActiveName,
            // previewColumns,
            // cmdStr,
            reRunVisible, // 重跑弹窗
            regularRerunVisible, // 定时重跑弹窗
            disTestTableDataVisible,
            // disTestTableText,
            isLoading,
            shouldVerify,
            jsonData,
        } = this.state

        console.log("TabPage======search", search)

        let {
            curClickTreeValue,
            currentData,
            pageApiTargets,
            // previewData,
            cfgData,
        } = this.props

        let targetApiTest = []

        if (cfgData.hasOwnProperty("targetApiTest")) {
            targetApiTest = cfgData.targetApiTest.map(i => {
                return {
                    id: i.value,
                    text: i.name
                }
            })
        }

        // console.log("--------cfgData", cfgData)

        return (
            <>
                <Tabs activeName={curActiveName}
                      onTabClick={(tab) => this.setState({
                          curActiveName: tab.props.name,
                          search: {form_data: "", env: "test"}
                      })}
                >
                    <Tabs.Pane label={`指标配置(${pageApiTargets ? pageApiTargets.length : 0})`} name="1">
                        <SelectedAttributes
                            handleEdit={this.handleEdit}
                            reRunVisible={this.reRunVisible} // 重跑
                            regularRerunVisible={this.regularRerunVisible} // 定时重跑
                            columns={this.getColumns}
                            curClickTreeValue={curClickTreeValue}
                            dataSource={pageApiTargets}
                            setSearch={this.setSearch}
                        />
                    </Tabs.Pane>
                    <Tabs.Pane label="调用测试" name="2">
                        <div style={{padding: '10px'}}>
                            <div style={{display: "flex"}} className="sdw-application-detail__opration-wrap">
                                <SdwInput
                                    type='textarea'
                                    rows={5}
                                    width={"100%"}
                                    placeholder={"tid:29\n" +
                                        "data_time:20220809\n" +
                                        "time_cycle:b2d\n" +
                                        "fields:1697,1687,1699\n" +
                                        "service_type:service_channel#second_dir_code"}
                                    value={search.form_data}
                                    sholdCheckValidate={shouldVerify}
                                    validateFun={value => !!value ? true : '此处必填'}
                                    onChange={val => this.setState({
                                        search: Object.assign({}, search, {
                                            form_data: val
                                        })
                                    })}
                                />
                            </div>
                            <div className="test-button-string">
                                <div style={{display: "flex"}}>
                                    <SdwSelect
                                        value={search.env}
                                        width={160}
                                        label=""
                                        data={targetApiTest}
                                        onChange={val => this.setState({
                                            search: Object.assign({}, search, {
                                                env: val
                                            })
                                        })}
                                    />
                                </div>
                                <div style={{display: "flex", marginLeft: "10px"}}>
                                    <SdwButton
                                        onClick={this.initTestTableData}
                                        type="submit"
                                        style={{height: "23px"}}
                                        text={"调用测试"}
                                    />
                                </div>
                            </div>
                            <div className="test-display-table">
                                {
                                    disTestTableDataVisible &&
                                    <SdwLoading className="page-main__wrap" loadding={isLoading}>
                                        <div className="modal" style={{height: "650px"}}>
                                            <ReactJson
                                                name={null}
                                                //theme="isotope"
                                                collapsed={6}
                                                displayObjectSize={false}
                                                //collapseStringsAfterLength={10}
                                                displayDataTypes={false}
                                                src={jsonData}
                                                //enableClipboard={false}
                                                shouldCollapse={false}
                                            />
                                        </div>
                                    </SdwLoading>
                                }
                            </div>
                        </div>
                    </Tabs.Pane>
                </Tabs>

                {/* 重跑弹窗 */}
                <ReRunWin
                    record={currentData}
                    curClickTreeValue={curClickTreeValue}
                    reRunVisible={reRunVisible}
                    changeReRunVisible={this.changeReRunVisible}
                />

                {/* 定时重跑弹窗 */}
                <RegularRerunWin
                    curClickTreeValue={curClickTreeValue}
                    regularRerunVisible={regularRerunVisible}
                    changeRegularReRunVisible={this.changeRegularReRunVisible}
                />
            </>
        )
    }
}

const mapState = state => ({
    pageApiTargets: state.api.pageApiTargets,
    previewData: state.api.previewData,
    curClickTreeValue: state.api.curClickTreeValue,
})

const mapDispatch = dispatch => ({
    updatePageApiTargets: dispatch.api.updatePageApiTargets,
    updatePreviewData: dispatch.api.updatePreviewData,
    updateCurClickTreeValue: dispatch.api.updateCurClickTreeValue,
})

export default withRouter(connect(mapState, mapDispatch)(TabPage))
