import React, { PureComponent } from 'react'
import { Dialog, DateRangePicker } from 'element-react'
import SdwMessage from '../../../../common/Message'
import 'element-theme-default'
import './style/DialogShowData.css'
import PropTypes from 'prop-types'
import SdwTable from '../../../../common/Table'
import SdwSelect from '../../../../common/Select'
import { getTargetResults } from '@services/Derived'
import { formatTime } from '@utils/formatTime'
import { Button } from 'element-react'
import SdwLoading from '@common/Loadding'

class DialogVisible extends PureComponent {
  constructor(props) {
    super(props);
  }

  state = {
    id: "",
    timefield: "",
    dateRange: [],
    tableFields: [],
    dialogVisible: false,
    pagination: {
      total: 0,
      cost_time:0,
      per_page: 20,
      from: 1,
      to: 0,
      current_page: 1,
      items: []
    },
    record: {},
    theader: [],
    tableShowData: false
  }

  // 可传递的参数
  static propTypes = {

  }

  // 属性默认值
  static defaultProps = {
    closeDialog: () => {},
  };

  componentDidMount () {
    this.initData()
    // 初始化预览数据的时间选择器的默认时间
    this.getShowDataDefauteTime()
  }

  componentDidUpdate (prevProps, prevState) {
    if(this.props.dialogVisible == false || this.props.record.id == 0 || this.props.record.id == "") {
      return
    }

    if (prevProps.dialogVisible !== this.props.dialogVisible) {
      this.initData()
    }
    if (prevProps.dialogVisible !== this.props.dialogVisible) {
      this.setState({
        dialogVisible: this.props.dialogVisible,
      })
    }
  }



  initData = () => {
    this.setState({
      dialogVisible: this.props.dialogVisible,
      id: this.props.record.id || 0,
      pagination:  {
        cost_time:0,
        total: 0,
        per_page: 20,
        from: 1,
        to: 0,
        current_page: 1,
        items: []
      },
      record: {...this.props.record}
    }, () => {this.fetchItems()})
  }

  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    return `${label}不能为空`
  }

  // 获取昨天时间和今天时间
  getShowDataDefauteTime = () => {
    var date = new Date(Date.now() - 24*60*60*1000);//当前时间
    var year = date.getFullYear()
    var month = this.zeroFill(date.getMonth() + 1);//月
    var day = this.zeroFill(date.getDate());//日
    let startTime = year + "/" + month + "/" + day + " 00:00:00"

    var s = startTime
    startTime = new Date(s);

    var date2 = new Date();//当前时间
    date2 = new Date(date2.getTime());
    year = date2.getFullYear()
    month = this.zeroFill(date2.getMonth() + 1);//月
    day = this.zeroFill(date2.getDate());//日
    let endTime = year + "/" + month + "/" + day + " 00:00:00"

    s = endTime
    endTime = new Date(s);

    this.setState({dateRange: [startTime, endTime]})
  }

  /**
   * 时间补零
   */
  zeroFill = (i) => {
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
  }

  // 获取预览数据
  getShowData = (val, type) => {
    if(type == "field") {
      this.setState({timefield: val}, () => {
        // 获取预览数据
        this.fetchItems()
      })
    }
    if(type == "date") {
      this.setState({dateRange: val}, () => {
        // 获取预览数据
        this.fetchItems()
      })
    }
  }

  fetchItems() {
    let that = this
    const params = { target_id: this.state.id }
    if (this.state.dateRange && this.state.dateRange.length > 0) {
      params.start_time = formatTime(+new Date(this.state.dateRange[0]), 0)
      params.end_time = formatTime(+new Date(this.state.dateRange[1]), 0)
    }

    if(params.target_id == 0 || params.target_id == "") {
      return
    }
    that.setState({tableShowData: false})
    getTargetResults(params).then(res => {
      that.setState({tableShowData: true})
      let items = []
      let theader = []
      let total = 0
      if (res.data.code === 0) {
        const tmp = res.data.data.data || []
        total = res.data.data.total || 0
        let cost_time = res.data.data.cost_time || 0
        tmp.forEach((v, k) => {
          if (k < 100) {
            if (Object.keys(v).length > Object.keys(theader).length) {
              for (let i in v) {
                theader.push(i)
              }
            }
            items.push(v)
          }
        });
        if (res.data.data.header) {
          theader = res.data.data.header
        }

        let pagination = {...this.state.pagination}
        pagination.items = items
        pagination.total = total
        pagination.cost_time = cost_time
        that.setState({
          theader: theader,
          pagination: pagination
        })
      } else {
        SdwMessage.error("查询异常：" + (res.data.msg || "请求超时"))
      }
    })
  }

  gettest = (fieldData) => {
    let dateFieldList = [];
    let timefield = "";
    if (fieldData.length > 0) {
      for (let i in fieldData) {
        const v = fieldData[i];
        if (v.field_type.includes("date")) {
          dateFieldList.push(v);
        }
      }
      if (dateFieldList.length >0){
        timefield = dateFieldList[0].field_name;
      }

    }
    this.setState({
      tableFields: dateFieldList,
      timefield: timefield
    })
  }

  closeDialog = () => {
    this.setState({dialogVisible: false})
    this.props.closeDialog()
  }

  // 获取表标题
  getcolumns = () => {
    let columnData = this.state.theader

    let columns = []

    for(let i in columnData) {
      let item = {
        title: columnData[i],
        width: "150",
        dataIndex: columnData[i],
      }
      columns.push(item)
    }

    return columns
  }

  render () {
    var { dateRange, timefield, pagination, tableShowData } = this.state;

    // 字段解析
    let tableFields = this.state.tableFields.map(i => {
      return {
        id: i.field_name,
        text: i.field_name_ch + '(' + i.field_name + ')'
      }
    })

    return (
      <div className="show-data DialogShowData DialogShowData-Derived">
        <Dialog
            title="预览数据 "
            size="tiny"
            visible={ this.state.dialogVisible }
            onCancel={ () => this.closeDialog() }
            lockScroll={ false }
          >
            <Dialog.Body>
              <div className="seach">
                <div className="source">
                  <div className="block">
                    <DateRangePicker
                      value={dateRange}
                      placeholder="选择日期范围"
                      isShowTime={true}
                      format="yyyy-MM-dd HH:mm:ss"
                      onChange={date=>{
                        this.getShowData(date, "date")
                      }}
                      />
                  </div>
                </div>

                {/*<div className="dataCount">共{pagination.total}条</div>*/}
                <div className="dataCount">共耗时{pagination.cost_time}秒，共{pagination.total}条，展示{pagination.items.length}条</div>
              </div>

              <div className="show-data-list">
                <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
                  <SdwTable
                      columns={this.getcolumns()}
                      dataSource={pagination.items}
                  />
                </SdwLoading>
              </div>
            </Dialog.Body>
          </Dialog>
      </div>
    )
  }
}

export default DialogVisible
