import { useRef, useState, useEffect } from 'react'
import _ from 'lodash'
import Dialog from "@common/Dialog"
import DatePicker from '@common/DatePicker'
import Radiobox from '@common/Radiobox'
import Message from '@common/Message'
import { submitTask } from '@services/labelManagement'
import { commonApi } from '@utils/request'
import '../style/index.css'

const TASK_TYPE = {
  online: 1,
  offline: 2
}

export default function EditWin(props) {
  const { record, showEditWin, setShowEditWin, updatePage } = props

  const [dateTimeRangeValue, setDateTimeRangeValue] = useState('')
  const [taskType, setTaskType] = useState(1)
  
  function onSubmit() {
    commonApi(submitTask, {
      id: record.id,
      field: {
        type: taskType,
        ...taskType === TASK_TYPE.offline ? {
          start_time: `${dateTimeRangeValue} 00:00:00`,
          end_time: `${dateTimeRangeValue} 23:59:59`
        } : {}
      }
    }).then(data => {
      Message.success('提交任务成功')
      updatePage()
      setShowEditWin(false)
    }).catch(err => Message.error(err || '提交任务失败'))
  }

  return (
    <Dialog
      title='提交标签加工任务'
      width={666}
      visible={showEditWin}
      changeVisible={setShowEditWin}
      onCancelClick={() => setShowEditWin(false)}
      onSureClick={onSubmit}
      sureBtnDisabled={taskType === TASK_TYPE.offline ? !dateTimeRangeValue : false}
      cusWrapClass='label-management__ticket-label-rules__editwin'
    >
      <div className='add-application-win__label'>
        <span className='label__title'>任务类型</span>
        <Radiobox
          options={[{
            text: '实时接入',
            value: TASK_TYPE.online
          }, {
            text: '离线重跑',
            value: TASK_TYPE.offline
          }]}
          value={taskType}
          changeCheckedFun={setTaskType}
        />
      </div>
      {
        taskType === TASK_TYPE.online ?
        <div className='add-application-win__label'>
          <span style={{
            marginLeft: 8
          }}>确认后将开始执行标签采集</span>
        </div> :
        <div className='add-application-win__label'>
          <span className='label__title'>时间范围</span>
          <DatePicker
            value={dateTimeRangeValue}
            changeValue={setDateTimeRangeValue}
          />
        </div>
      }
    </Dialog>
  )
}
