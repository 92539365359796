import React from 'react'
import './index.css'

export default class Checkbox extends React.Component {

  render() {

    let { text, changeCheckedFun, isChecked, value, disabled, disabledArr } = this.props

    return (
      <>
      {
        typeof text === 'string' &&
        <div
          className="sdw-checkbox__wrap"
          onClick={() => {
            if (disabled) return
            changeCheckedFun()
          }}
        >
          <input
            type="checkbox"
            checked={isChecked}
            disabled={disabled}
            onChange={() => {}} // 没有此方法,控制台会报错
          />
          <span 
            className="checkbox__text"
            title={String(text).length > 14 ? text : ''}
          >
            { text }
          </span>
        </div>
      }

      {
        Array.isArray(text) &&
        !!text.length &&
        !!value &&
        text.map(i => (
          <span key={i} style={{ marginRight: 8 }}>
            <input
              value={i}
              type="checkbox"
              checked={value.indexOf(i) !== -1}
              name={`onlyOne${text[0]}`}
              onChange={() => {}}
              disabled={disabledArr.indexOf(i) !== -1}
              onClick={e => {
                let val = e.target.value
                let checked = e.target.checked
                let arr = [...value]

                if (typeof this.props.changeValue !== 'function') return
                if (checked) {
                  this.props.changeValue([...arr, val])
                } else {
                  let index = arr.findIndex(i => i == val)
                  if (index === -1) return
                  arr.splice(index, 1)
                  this.props.changeValue(arr)
                }
              }}
            />{i}
          </span>
        ))
      }
      </>
    )
  }
}