import React from 'react'
import './style/index.css'
import SdwSelect from '@common/Select'
import { connect } from 'react-redux'
import SdwTable from '@common/Table'
import _ from 'lodash'
import SdwButton from '@common/Button'
import { getNodeFields, getPublicCfg,  deleteFields, deleteNode, tablePreview} from '@api/midTable'
import SdwMessage from '@common/Message'
import AddField from '@pages/Knowledge/MidTable/AddFiled'
import Dialog from '@common/Dialog'
import Loadding from '@common/Loadding'

class TableList  extends React.Component {
  state = {
    recordList :[], //节点下拉选项
    clickItem:{}, //选中某个节点
    clickFieldsList:[], //字段table列表
    stepList:[], //所有的节点
    fieldParams:{},
    showAddField:false,
    //dialogDelVisible:false,
    isEdit:false,
    dialogDelNodeVisible:false,
    previewDataColumns:[], //预览数据的行
    previewDataList:[], //预览数据
    field_type_map:{},
    isLoading:false,
  }
  componentDidMount() {
    this.iniFieldTypeMap()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.stepList != prevProps.stepList) {
      this.iniStepList()
      this.iniDataSourceFields()
    }
    if (this.props.clickDataSource != prevProps.clickDataSource) {
      this.iniClickData()
    }
    if (this.props.cfgData != prevProps.cfgData) {
      this.iniFieldTypeMap()
    }
  }

  iniFieldTypeMap = () => {
    let cfgData = this.props.cfgData
    if (JSON.stringify(cfgData) != "{}") {
      let field_type_map = {}
      cfgData.field_type.forEach(i => {
        field_type_map[i.value] = {id:parseInt(i.value), text:i.code, item:i}
      })
      this.setState({
        field_type_map:field_type_map
      })
    }
  }

  iniStepList = () => {
    let stepList = this.props.stepList
    let clickItem = this.state.clickItem
    let recordList = []
    for (let i in stepList) {
      if (stepList[i].item["table_name"] == null) {
        continue
      }
      let text = ""
      if (stepList[i].item["table_type"] == 0) {
        text = "【物理表】 " + stepList[i].text
      } else {
        text = "【视图】 " + stepList[i].text
      }
      if (JSON.stringify(clickItem) != "{}" && clickItem.id == stepList[i].id) {
        this.setState({
          clickItem:stepList[i]
        })
      }
      recordList.push({
        id:stepList[i].id,
        text:text,
        item:stepList[i]
      })
    }
    console.log("recordList", recordList)
    this.setState({
      recordList:recordList,
      stepList:stepList,
    })
  }
  //初始化当前的点击的字段信息
  iniClickData = () => {
    let clickDataSource = this.props.clickDataSource
    this.setState({
      clickItem:clickDataSource
    }, this.iniDataSourceFields)

  }
  //初始字段列表
  iniDataSourceFields = () => {
    let {clickItem} = this.state
    let item = clickItem.item
    if (!item) {
      return
    }
    console.log("----------------clickItem", clickItem)
    console.log("----------------item", item)
    let params = {
      id:item.job_id,
      node_id:item.id,
    }
    if (item.hasOwnProperty("link") && item.link) {
      params.link_id = item.link.id
    }
    console.log("end-----------------params", params)
    getNodeFields(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || []
        this.setState({
          clickFieldsList:record,
          previewDataList:[],
        })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }
  //切换节点
  changeClickTableView = (item) => {
    this.setState({
      clickItem:item
    }, this.iniDataSourceFields)
  }
  //编辑字段
  editField = (item) => {
    let fieldParams = {
      id: item.id,  // 字段id，新增不传，编辑传
      config: item.config,
      field: item.field,  // 结果字段
      src_reader_id:item.src_reader_id,
      field_type: item.field_type,   // 字段类型
      filter: item.filter,   // 过滤条件
      mapping_type: item.mapping_type,   // single：表原生字段，combine：加工字段
      enabled: item.enabled
    }
    this.setState({
      isEdit:true,
      fieldParams:fieldParams,
      showAddField:true
    })
  }
  //删除字段 不需要弹框
  // delField = (item) => {
  //   let fieldParams = {
  //     id: item.id,  // 字段id，新增不传，编辑传
  //     config: item.config,
  //     field: item.field,  // 结果字段
  //     src_reader_id:item.src_reader_id,
  //     field_type: item.field_type,   // 字段类型
  //     filter: item.filter,   // 过滤条件
  //     mapping_type: item.mapping_type,   // single：表原生字段，combine：加工字段
  //     enabled: item.enabled
  //   }
  //   this.setState({
  //     fieldParams:fieldParams,
  //     dialogDelVisible:true
  //   })
  // }
  //删除字段确认点击
  deleteFieldsSureClick = (item) => {
    let {clickItem} = this.state
    let fieldParams = {
      id: item.id,  // 字段id，新增不传，编辑传
      config: item.config,
      field: item.field,  // 结果字段
      src_reader_id:item.src_reader_id,
      field_type: item.field_type,   // 字段类型
      filter: item.filter,   // 过滤条件
      mapping_type: item.mapping_type,   // single：表原生字段，combine：加工字段
      enabled: item.enabled
    }
    let params = {
      "id": clickItem.item.job_id,
      "node_id": clickItem.item.id,
      "field_ids": [fieldParams.id]
    }
    deleteFields(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        SdwMessage.success("删除字段成功")
        this.iniDataSourceFields()
        // this.setState({
        //   dialogDelVisible:false
        // })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }
  //删除按钮点击节点
  delNode = () => {
    let {clickItem} = this.state
    if (JSON.stringify(clickItem) == "{}") {
      return
    }
    this.setState({
      dialogDelNodeVisible:true
    })
  }
  //删除节点确认点击
  deleteNodeSureClick = () => {
    let {clickItem} = this.state
    if (JSON.stringify(clickItem) == "{}") {
      return
    }
    let item = clickItem.item
    let params = {
      "id": item.job_id,
      "node_id": item.id
    }
    if (item.hasOwnProperty("link") && item.link) {
      params.link_id = item.link.id
    }
    console.log("clickItem")
    deleteNode(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        SdwMessage.success("删除节点成功")
        this.props.iniTableViewList(item.job_id)
        this.setState({
          clickItem: {},
          clickFieldsList:[],
          previewDataList:[],
          dialogDelNodeVisible:false
        })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }
  //添加字段按钮点击
  addFieldClick = () => {
    let {clickItem} = this.state
    if (JSON.stringify(clickItem) == "{}") {
      return
    }
    this.setState({
      isEdit:false,
      showAddField:true
    })
  }
  //编辑节点按钮点击事件触发
  editTableViewNode = () => {
    let {clickItem} = this.state
    console.log("editTableViewNode=========clickItem", clickItem)
    if (JSON.stringify(clickItem) == "{}") {
      return
    }
    this.props.editTableViewNode(clickItem)
  }
  changeFieldType = (data, val) => {
    console.log("data", data)
    console.log("data", val)
  }
  getColumns = () => {
    let {stepList, clickItem,field_type_map} = this.state
    return [
      {
        title: "字段ID",
        width: 55,
        showTip: true,
        render(data){
          let mappingType = {
            single:"原生",
            combine:"计算",
          }
          return (
            <>
              <span className="columns_filed_id">{mappingType[data.mapping_type]}</span>
              {data.field}
            </>
          )
        }
      },
      // {
      //   title: "数据类型",
      //   width: 50,
      //   render(data){
      //     return (
      //       <>
      //         {field_type_map[data.field_type] ? field_type_map[data.field_type].text : "-"}
      //       </>
      //     )
      //   }
      // },
      {
        title: "来源表",
        width: 90,
        showTip: true,
        render(data) {
          return (
            <>
              {stepList[data.src_reader_id].text}
            </>
          );
        }
      },
      {
        title: "操作",
        width: 70,
        render: data => {
          if (clickItem.item && clickItem.item.table_type == 1 && clickItem.item.view_type == 1 && data.mapping_type == "single" ) { //单表聚合并且是原生字段则不能操作
            return (
              <>
              </>
            )
          }
          return (
            <div className="editorMenu-Derived">
              {/* 编辑权限放开，都可以点击编辑进入弹窗查看数据 */}
              <SdwButton
                onClick={()=>this.editField(data)}
                text="编辑"
              />
              <SdwButton
                onClick={()=>this.deleteFieldsSureClick(data)}
                text="删除"
              />
            </div>
          )
        }
      }
    ]
  }
  //预览数据刷新按钮点击
  previewDataFlush = () => {
    let {clickItem, isLoading} = this.state
    if (!clickItem || JSON.stringify(clickItem) == "{}" || isLoading) {
      return
    }
    this.setState({
      isLoading:true
    })
    let params = {
      "id": clickItem.item.job_id,
      "node_id": clickItem.item.id
    }
    tablePreview(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let list = res.data.data.list || []
        this.setState({
          previewDataList:list
        })
      } else {
        let msg = res.data.data || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error)).finally(()=>{
      this.setState({
        isLoading:false
      })
    })
  }
  getPreviewDataColumns = () =>{
    let {clickFieldsList} = this.state
    if (!clickFieldsList || clickFieldsList.length == 0 ) {
      return []
    }
    return clickFieldsList.map(i => ({
      title: <>
        {i.field}
      </>,
      dataIndex: i.field
    }))
  }



  render() {

    let {
      recordList,
      clickFieldsList,
      clickItem,
      fieldParams,
      showAddField,
      isEdit,
      //dialogDelVisible,
      dialogDelNodeVisible,
      previewDataList,
      isLoading,
      stepList,
    } = this.state
    console.log("recordList====", recordList)
    console.log("clickItem====", clickItem)
    console.log("clickFieldsList====", clickFieldsList)
    console.log("showAddField====", showAddField)
    console.log("stepList====", stepList)
    return (
      <>
        <div className="table-list-checkout">
          <SdwSelect
            placeholder="请选择"
            value={clickItem.id}
            width={470}
            readOnly={false}
            data={recordList}
            onChange={(val, item) => this.changeClickTableView(item.item)}
          />
          <span style={{marginLeft:"8px"}} className="el-button--text"
            onClick={this.editTableViewNode}
          >
            编辑
          </span>
          <span style={{marginLeft:"8px"}} className="el-button--text"
            onClick={this.delNode}
          >
            移除
          </span>
        </div>
        <div className="table-list-table_content">
          <div className="table-list-table_content_left">
            <div className="table-list-table_content_left-title">
              <span className="table-list-table_content_left-words">字段信息</span>
              <span onClick={this.addFieldClick} className="table-list-table_content_left-title-button">
                <span style={{paddingBottom:"5px"}}>+</span> 添加字段
              </span>
            </div>
            <SdwTable
              columns={ this.getColumns() }
              dataSource={ clickFieldsList }
            />
          </div>
          <div className="table-list-table_content_right">
            <div className="table-list-table_content_right-title">
              <span className="table-list-table_content_right-words">预览数据（注：随机从各数据源抽取1000条数据加工，并展示100条）</span>
              <span onClick={this.previewDataFlush} className={isLoading?"table-list-table_content_right-title-button-loading":"table-list-table_content_right-title-button"}>
                刷新
              </span>
            </div>
            <Loadding loadding={isLoading}>
              <SdwTable
                tableHeight={510}
                columns={this.getPreviewDataColumns() }
                dataSource={ previewDataList }
              />
            </Loadding>
          </div>
        </div>
        {/**删除字段弹窗 */}
        {/*{*/}
        {/*  dialogDelVisible &&*/}
        {/*  <Dialog*/}
        {/*    title='移除字段 '*/}
        {/*    width={400}*/}
        {/*    visible={dialogDelVisible}*/}
        {/*    changeVisible={this.deleteFieldsSureClick}*/}
        {/*    onCancelClick={() => this.setState({*/}
        {/*      dialogDelVisible:false*/}
        {/*    })}*/}
        {/*    onSureClick={this.deleteFieldsSureClick}*/}
        {/*  >*/}
        {/*    <div>确认移除字段<span >{fieldParams.field}</span>？</div>*/}
        {/*  </Dialog>*/}
        {/*}*/}
        {/**移除节点弹窗 */}
        {
          dialogDelNodeVisible &&
          <Dialog
            title='移除数据源'
            width={400}
            visible={dialogDelNodeVisible}
            changeVisible={this.deleteNodeSureClick}
            onCancelClick={() => this.setState({
              dialogDelNodeVisible:false
            })}
            onSureClick={this.deleteNodeSureClick}
          >
            <div>移除后将影响后续的加工步骤，确认移除？</div>
          </Dialog>
        }
        {
          showAddField&&<AddField
            changeShowAddField={bool=>{
              this.setState({
                showAddField:bool
              })
            }}
            stepList={stepList}
            recordList={recordList}
            clickItem={clickItem}
            clickFieldsList={clickFieldsList}
            fieldParams={fieldParams}
            isEdit={isEdit}
            iniDataSourceFields={this.iniDataSourceFields}
          />
        }
      </>
    )
  }
}
const mapState = state => ({
  stepList: state.midTableView.stepList,
  cfgData: state.midTableView.cfgData,
})

const mapDispatch = dispatch => ({
  updateStepList:dispatch.midTableView.updateStepList
})
export default connect(mapState, mapDispatch)(TableList)
