import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwMessage from '@common/Message'
import { storeNewAppTable, updateNewAppTable } from '@services/application'
import { getStaffs } from '@api/alertRules'

import './style/addApplicationWin.css'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

export default class AddApplicationWin extends React.Component {

  state = {
    appInputValue: '',
    zhNameInputValue: '',
    domainValue: '',
    sholdCheckValidate: false,
    timeValue: 'day',
    isAppInputValuePass: false,
    isZhNameValidateFunPass: false,
    isRefreshValuePass: false,
    isRespUserValuePass: false,
    isDomainValuePass: false,
    timeSelectList: [
      {
        id: 'year',
        text: 'year'
      }, {
        id: 'month',
        text: 'month'
      }, {
        id: 'day',
        text: 'day'
      }, {
        id: 'hour',
        text: 'hour'
      }
    ],
    refreshValue: '',
    respUser: [],
    allMembers:[]
  }

  componentDidMount() {
    this.iniData()
    this.getStaff()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.dialogVisible && prevProps.dialogVisible !== this.props.dialogVisible) {
      this.setState({
        sholdCheckValidate: false
      })
    }

    if (!_.isEqual(prevProps.currentData, this.props.currentData)) {
      let { currentData } = this.props
      let indexFlag = (currentData.table_name || '').lastIndexOf('_')
      this.setState({
        appInputValue: currentData.table_name.substr(0, indexFlag) || '',
        timeValue: indexFlag !== -1 ? currentData.table_name.substr(indexFlag + 1) : 'day',
        zhNameInputValue: currentData.table_name_cn,
        // zhNameInputValue: currentData.table_name_cn,
        domainValue: Number(currentData.topic),
        refreshValue: currentData.run_cycle,
        respUser: currentData.resp_user.split(","),
      })
    }
  }

  iniData = () => {
    let { currentData } = this.props
    console.log("----------currentData", currentData)
    if (!!currentData) {
      let indexFlag = (currentData.table_name || '').lastIndexOf('_')
      this.setState({
        appInputValue: currentData.table_name.substr(0, indexFlag) || '',
        timeValue: indexFlag !== -1 ? currentData.table_name.substr(indexFlag + 1) : 'day',
        zhNameInputValue: currentData.table_name_cn,
        // zhNameInputValue: currentData.table_name_cn,
        domainValue: Number(currentData.topic),
        refreshValue: currentData.run_cycle,
        respUser: currentData.resp_user.split(","),
      })
    }

  }

  getStaff = () => {
    getStaffs().then(res => {
      if (res && res.data && res.data.code === 0) {
        let list = _.isArray(res.data.data) ? res.data.data : []
        //list.push({name:"default_focus", value:"default_focus"})
        this.setState({
          allMembers: list
        })
      }
    })
  }

  appNameValidateFun = val => {
    this.setState({
      isAppInputValuePass: false
    })

    if (!/^[0-9a-zA-Z_]{1,}$/.test(val)) {
      return '仅支持英文、数字、下划线_'
    }

    if (val.length > 50) {
      return '输入最大长度50'
    }
    if (!!val) {
      this.setState({
        isAppInputValuePass: true
      })
      return true
    }
    return '该输入项不能为空'
  }

  zhNameValidateFun = val => {
    this.setState({
      isZhNameValidateFunPass: false
    })

    if (val.length > 50) {
      return '输入最大长度50'
    }
    if (!!val) {
      this.setState({
        isZhNameValidateFunPass: true
      })
      return true
    }
    return '该输入项不能为空'
  }

  domainSelectValidateFun = val => {
    this.setState({
      isDomainValuePass: false
    })

    if (!!val) {
      this.setState({
        isDomainValuePass: true
      })
      return true
    }
    return '该输入项不能为空'
  }

  selectValidateFun = val => {
    this.setState({
      isRefreshValuePass: false
    })

    if (!!val) {
      this.setState({
        isRefreshValuePass: true
      })
      return true
    }
    return '该输入项不能为空'
  }

  respUserValidateFun = val => {
    this.setState({
      isRespUserValuePass: false
    })

    if (val.length > 0) {
      this.setState({
        isRespUserValuePass: true
      })
      return true
    }
    return '运营负责人不能为空'
  }

  resetData = () => {
    this.setState({
      appInputValue: '',
      timeValue: 'day',
      zhNameInputValue: '',
      domainValue: '',
      refreshValue: ''
    })
  }

  handleSubmit = () => {
    let {
      isAppInputValuePass,
      isZhNameValidateFunPass,
      isDomainValuePass,
      isRefreshValuePass,
      isRespUserValuePass,
      appInputValue,
      timeValue,
      zhNameInputValue,
      domainValue,
      refreshValue,
      respUser
    } = this.state

    let { isEdit } = this.props

    let isValidatePass = false

    isValidatePass = isAppInputValuePass && isZhNameValidateFunPass && isDomainValuePass

    console.log(")---------------isRespUserValuePass", isRespUserValuePass)
    console.log(")---------------isValidatePass", isValidatePass)

    if ((!isEdit && !isValidatePass) || !isRespUserValuePass) {
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    // ajax updateNewAppTable
    if (isEdit && hasPriv('updateNewAppTable')) {
      updateNewAppTable({
        id: this.props.currentData.id,
        table_name: `${appInputValue}_${timeValue}`,
        table_name_cn: zhNameInputValue,
        topic: domainValue,
        run_cycle: refreshValue,
        resp_user: respUser.join(","),
      })
        .then(res => {
          if (res.data && res.data.code === 0) {
            this.resetData()
            this.props.changeDialogVisible(false)

            // 新增/编辑成功，从新拉去新数据
            if (isEdit) {
              this.props.initData()
            } else {
              this.props.searchAppInfo()
            }

            let msg = isEdit ? '编辑成功' : '新增成功'
            SdwMessage.success(msg)
          } else {
            let msg = res.data && res.data.msg
            SdwMessage.error(msg);
          }
        }, () => SdwMessage.error("接口异常"))
    } else if (hasPriv('storeNewAppTable')) {
      storeNewAppTable({
        table_name: `${appInputValue}_${timeValue}`,
        table_name_cn: zhNameInputValue,
        topic: domainValue,
        run_cycle: refreshValue,
        resp_user: respUser.join(","),
      })
        .then(res => {
          if (res.data && res.data.code === 0) {
            this.resetData()
            this.props.changeDialogVisible(false)

            // 新增/编辑成功，从新拉去新数据
            if (isEdit) {
              this.props.initData()
            } else {
              this.props.searchAppInfo()
            }

            let msg = isEdit ? '编辑成功' : '新增成功'
            SdwMessage.success(msg)
          } else {
            let msg = res.data && res.data.msg
            SdwMessage.error(msg);
          }
        }, () => SdwMessage.error("接口异常"))
    } {

    }

  }

  render() {
    let {
      dialogVisible,
      changeDialogVisible,
      isEdit,
      curTopicData,
      runCycleData,
    } = this.props

    let {
      timeValue,
      timeSelectList,
      refreshValue,
      sholdCheckValidate,
      appInputValue,
      zhNameInputValue,
      domainValue,
      respUser,
      allMembers,
    } = this.state

    console.log("-------respUser", respUser)
    console.log("-------allMembers", allMembers)

    return (
      <div>
        <SdwDialog
          title={isEdit ? '修改应用表' : '新增应用表'}
          visible={dialogVisible}
          width={610}
          changeVisible={changeDialogVisible}
          onCancelClick={() => changeDialogVisible(false)}
          onSureClick={this.handleSubmit}
        >
          <div className='add-application-win__label'>
            <span className='label__title required'>应用表名</span>
            <SdwInput
              placeholder='app_business'
              width={320}
              disabled={isEdit}
              value={appInputValue}
              onChange={val => this.setState({ appInputValue: val })}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={this.appNameValidateFun}
            />
            <span style={{ marginLeft: 2 }}>
              <SdwSelect
                width={100}
                listWidth={100}
                disabled={isEdit}
                clearable={false}
                value={timeValue}
                data={timeSelectList}
                onChange={val => this.setState({ timeValue: val })}
              />
            </span>
          </div>
          <div className='add-application-win__label'>
            <span className='label__title required'>中文名</span>
            <SdwInput
              placeholder='IVR业务天表'
              width={422}
              value={zhNameInputValue}
              onChange={val => this.setState({ zhNameInputValue: val })}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={this.zhNameValidateFun}
            />
          </div>
          <div className='add-application-win__label'>
            <span className='label__title required'>主题域</span>
            <SdwSelect
              placeholder="请选择"
              width={422}
              listWidth={418}
              sholdCheckValidate={sholdCheckValidate}
              clearable={false}
              validateFun={this.domainSelectValidateFun}
              value={domainValue}
              data={curTopicData}
              onChange={val => this.setState({ domainValue: val })}
            />
          </div>
          <div className='add-application-win__label'>
            <span className='label__title required'>运营负责人</span>
            <SdwInput
              isMultipleChoice={true}
              multipleValue={respUser}
              multipleOption={allMembers}
              width={422}
              placeholder="请输入rtx"
              validateFun={this.respUserValidateFun}
              sholdCheckValidate={sholdCheckValidate}
              onClear={() => this.setState({ respUser:  [] })} // 这里的onClear只是清除所有时才触发
              changeMultipleValue={val => this.setState({ respUser: val })}
            />
          </div>
          <div className='add-application-win__label'>
            <span className='label__title required'>更新频率</span>
            <SdwSelect
              placeholder="请选择"
              width={422}
              disabled={isEdit}
              listWidth={418}
              sholdCheckValidate={sholdCheckValidate}
              clearable={false}
              validateFun={this.selectValidateFun}
              value={refreshValue}
              data={runCycleData}
              onChange={val => this.setState({ refreshValue: val })}
            />
          </div>
        </SdwDialog>
      </div>
    )
  }
}
