// 传入时间戳，返回 2020-01-01 00:01:00
export function formatTime(time = +new Date(), numHour = 1) {
  var date = new Date(time + (numHour + 8) * 3600 * 1000); // 增加(numHour + 8)小时 ---> 东八区：小时加 8
  return date.toJSON().substr(0, 19).replace('T', ' ');
}

// 选择的时间为[2021-10-28, 2021-10-29]--------> [2021-10-28 00:00:00, 2021-10-29 23:59:59]
export function getRangeDateTime(rangeTime = '') {
  if (!rangeTime || !Array.isArray(rangeTime) || rangeTime.length !== 2) return

  return rangeTime.map((t, index) => {
    if (index === 0) return `${t} 00:00:00`
    return `${t} 23:59:59`
  })
}