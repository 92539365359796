import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import Select from '@common/Select'
import Input from '@common/Input'
import Button from '@common/Button'
import Table from '@common/Table'
import Enable from '@common/Enable'
import Pagenation from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import { pageTargetsGroup } from '@services/keyIndicatorAllocation'
import Message from '@common/Message/index'
import { commonApi } from '@utils/request'
import RelateWin from './RelateWin'
import StepWin from './StepWin'
import { getSearchParams } from '@utils/submit'
import { pageAppTables } from '@api/api'
import SdwMessage from '@common/Message/index'

export default function IndexGroupConfiguration() {

    const [curRecord, setCurRecord] = useState({})
    const [showRelateWin, setShowRelateWin] = useState(false)
    const [curEditItem, setCurEditItem] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [showSteps, setShowSteps] = useState(false)
    const [pageConfig, setPageConfig] = useState({})
    const [searchParams, setSearchParams] = useState({})
    const [apiList, setApiList] = useState([])

    useEffect(() => {
        initData()
    }, [pageConfig, searchParams])

    useEffect(() => {
        getApi()
    }, [])

    function initData() {
        commonApi(pageTargetsGroup, {
            ...pageConfig,
            search: getSearchParams(searchParams, ['group_name'])
        }).then(data => {
            setCurRecord(data)
        }).catch(err => Message.error(err))
    }

    function getApi() {
        let params = {
            limit: 1000,
            page: 1,
            // search: {
            //     table_name: {
            //         value: `%${apiName}%`,
            //         operator: 'like'
            //     }
            // },
            // orSearch: {
            //     table_name_cn: {
            //         value: `%${apiName}%`,
            //         operator: 'like'
            //     }
            // },
        }
        pageAppTables(params).then(res => {
            let jsonData = res && res.data || {}
            if (jsonData && jsonData.code === 0 && jsonData.data) {
                setApiList(jsonData.data.items.map(i => {
                    return {
                        id:i.id,
                        text:i.table_name_cn+"("+i.table_name+")",
                        item:i
                    }
                }))
            }
        }, () => SdwMessage.error("接口异常"))
    }

    const getcolumns = useCallback(
        () => {
            return [
                {
                    title: "ID",
                    dataIndex: 'id',
                    width: 30
                }, {
                    title: '指标组',
                    dataIndex: 'group_name',
                    width: 90
                }, {
                    title: '二级分类',
                    width: 120,
                    render: data => {
                        let str = ""
                        if (data.two_group != "") {
                            let two_group = JSON.parse(data.two_group)
                            for (let i in two_group) {
                                if (str == "") {
                                    str = two_group[i].name
                                } else {
                                    str += "/" + two_group[i].name
                                }
                            }
                        }
                        return (
                            <span>{str}</span>
                        )
                    }
                }, {
                    title: '三级分类',
                    width: 120,
                    render: data => {
                        let str = ""
                        if (data.three_group != "") {
                            let three_group = JSON.parse(data.three_group)
                            for (let i in three_group) {
                                if (str == "") {
                                    str = three_group[i].name
                                } else {
                                    str += "/" + three_group[i].name
                                }
                            }
                        }
                        return (
                            <span>{str}</span>
                        )
                    }
                }, {
                    title: '备注',
                    dataIndex: 'remark',
                    width: 60
                }, {
                    title: '状态',
                    render: data => <Enable enable={data.enabled} />,
                    width: 50,
                }, {
                    title: '可见范围',
                    width: 50,
                    render: data => <span>{data.visible_count || 0 }人</span>
                }, {
                    title: '关注人',
                    width: 50,
                    render: data => <span>{data.indicator_focus_count || 0 }人</span>
                }, {
                    title: '订阅人',
                    width: 50,
                    render: data => <span>{data.indicator_subscribe_count || 0 }人</span>
                }, {
                    title: '修改人',
                    dataIndex: 'update_user',
                    width: 60
                }, {
                    title: '修改时间',
                    dataIndex: 'update_time',
                    width: 120
                }, {
                    title: '操作',
                    width: 90,
                    render: data => (
                        <>
                            <Button
                                text='编辑'
                                onClick={() => onEdit(data)}
                            />
                            <Button
                                text='关联接口'
                                onClick={() => onRelate(data)}
                            />
                        </>
                    )
                }
            ]
        },
    )

    function onCreate() {
        setIsEdit(false)
        setCurEditItem({})
        setShowSteps(true)
    }

    function onEdit(item) {
        setIsEdit(true)
        setCurEditItem(item)
        setShowSteps(true)
    }

    function onRelate(item) {
        setIsEdit(true)
        setCurEditItem(item)
        setShowRelateWin(true)
    }

    function updateParams(key, val) {
        let cloneParams = _.cloneDeep(searchParams)
        cloneParams[key] = val
        setSearchParams(cloneParams)
    }
    return (
        <>
            <BreadCrumbsAndTablde>
                <BreadCrumbsAndTablde.LeftCrumbs>
                    <Input
                        label='指标组'
                        value={searchParams.group_name}
                        onChange={val => {
                            setPageConfig(r => _.assign({}, r, {
                                page: 1
                            }))
                            updateParams('group_name', val)
                        }}
                        width={260}
                    />
                    <Select
                        label='是否有效'
                        value={searchParams.enabled}
                        data={[{
                            id: '',
                            text: '全部'
                        }, {
                            id: 1,
                            text: '有效'
                        }, {
                            id: 0,
                            text: '无效'
                        }
                        ]}
                        onChange={val => {
                            setPageConfig(r => _.assign({}, r, {
                                page: 1
                            }))
                            updateParams('enabled', val)
                        }}
                        width={200}
                    />
                </BreadCrumbsAndTablde.LeftCrumbs>

                <BreadCrumbsAndTablde.RightCrumbs>
                    <Button
                        text='新增'
                        type='submit'
                        iconClass='iconfont icon-add'
                        onClick={onCreate}
                    />
                </BreadCrumbsAndTablde.RightCrumbs>

                <BreadCrumbsAndTablde.CenterWrap>
                    <Table
                        columns={getcolumns()}
                        dataSource={_.isArray(curRecord.items) ? curRecord.items : []}
                    />

                    <Pagenation
                        total={curRecord.total}
                        currentPage={curRecord.current_page}
                        pageCount={curRecord.per_page}
                        getClickPage={page => setPageConfig(r => _.assign({}, r, {
                            page
                        }))}
                        pageCountChange={limit => setPageConfig(r => _.assign({}, r, {
                            limit
                        }))}
                    />
                </BreadCrumbsAndTablde.CenterWrap>
            </BreadCrumbsAndTablde>

            {/* 关联接口弹窗 */}
            {
                showRelateWin&&<RelateWin
                  apiList={apiList}
                  showRelateWin={showRelateWin}
                  record={curEditItem}
                  setShowRelateWin={setShowRelateWin}
                />
            }

            {/* 新增 */}
            {
                showSteps&&<StepWin
                  showSteps={showSteps}
                  hideStepsFunc={setShowSteps}
                  isEdit={isEdit}
                  record={curEditItem}
                  updatePage={initData}
                />
            }
        </>
    )
}
