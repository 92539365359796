/**
 * @file 导航左右结构 + table 组合成的通用界面组件：这里主要是样式的抽取
 */
import './index.css'

function LeftCrumbs(props) {
  return <>{ props.children }</>
}

function RightCrumbs(props) {
  return <>{ props.children }</>
}

function CenterWrap(props) {
  return <>{ props.children }</>
}

function BreadCrumbsAndTablde(props) {
  let LeftCrumbsProps = null
  let RightCrumbsProps = null
  let CenterWrapProps = null

  // 只有一个的时候，是对象，不是数组，同意转为数组处理
  const propsChildren = Array.isArray(props.children) ? props.children : [props.children]
  
  propsChildren.forEach(comp => {
    switch (comp.type.prototype.name) {
      case 'LeftCrumbs':
        LeftCrumbsProps = comp
        break;

      case 'RightCrumbs':
        RightCrumbsProps = comp
        break;

      case 'CenterWrap':
        CenterWrapProps = comp
        break;
    
      default:
        break;
    }
  })

  return (
    <>
      <div className='sdw__bread-crumbs__wrap'>
        <div className='sdw__bread-crumbs__left-wrap'>
          { LeftCrumbsProps }
        </div>
        <div className='sdw__bread-crumbs__right-wrap'>
          { RightCrumbsProps }
        </div>
      </div>
      <div className='sdw__table__wrap'>
        { CenterWrapProps }
      </div>
    </>
  )
}

BreadCrumbsAndTablde.LeftCrumbs = LeftCrumbs
BreadCrumbsAndTablde.LeftCrumbs.prototype.name = 'LeftCrumbs'
BreadCrumbsAndTablde.RightCrumbs = RightCrumbs
BreadCrumbsAndTablde.RightCrumbs.prototype.name = 'RightCrumbs'
BreadCrumbsAndTablde.CenterWrap = CenterWrap
BreadCrumbsAndTablde.CenterWrap.prototype.name = 'CenterWrap'

export default BreadCrumbsAndTablde