// 应用管理
import { request } from '../utils/request'

export function getAllAppInfos (params) {
  return request({ url: '/statistics/dataProcess/getAllAppInfos', method: 'get', params: params })
}

export function searchAppInfo (params) {
  return request({ url: '/statistics/dataProcess/searchAppInfo', method: 'get', params: params })
}

export function searchAppInfoManage (params) {
  return request({ url: '/statistics/dataProcess/searchAppInfoManage', method: 'get', params: params })
}

export function getAllTopicTarget (params) {
  return request({ url: '/statistics/dataProcess/getAllTopicTarget', method: 'get', params: params })
}

export function getTargetCfgById (params) {
  return request({ url: '/statistics/dataProcess/getTargetCfgById', method: 'get', params: params })
}

// 这是搜索指标的
export function searchTarget (params) {
  return request({ url: '/statistics/dataProcess/searchTarget', method: 'get', params: params })
}

// 这是接口去重过的指标的
export function searchDistinctTarget (params) {
  return request({ url: '/statistics/dataProcess/searchDistinctTarget', method: 'get', params: params })
}

export function storeNewAppTable (params) {
  return request({ url: '/statistics/dataProcess/storeNewAppTable', method: 'post', data: params })
}

export function updateNewAppTable (params) {
  return request({ url: '/statistics/dataProcess/updateNewAppTable', method: 'post', data: params })
}

export function storeAppTarget (params) {
  return request({ url: '/statistics/dataProcess/storeAppTarget', method: 'post', data: params })
}

export function updateAppTarget (params) {
  return request({ url: '/statistics/dataProcess/updateAppTarget', method: 'post', data: params })
}

export function deleteAppTarget (params) {
  return request({ url: '/statistics/dataProcess/deleteAppTarget', method: 'post', data: params })
}

export function runTargetResult (params) {
  return request({ url: '/statistics/dataProcess/runTargetResult', method: 'post', data: params })
}

export function updatePublishState (params) {
  return request({ url: '/statistics/dataProcess/updatePublishState', method: 'get', params: params })
}

export function getTargetResults (params) {
  return request({ url: 'statistics/dataProcess/getTargetResults', method: 'get', params: params })
}

export function getMetricTypeConf (params) {
  return request({ url: '/dataset/targetV2/getMetricTypeConf', method: 'get', params: params })
}

export function getAppDimField (params) {
  return request({ url: '/statistics/dataProcess/getAppDimField', method: 'get', params: params })
}

export function storeOrUpdateDepth (params) {
  return request({ url: '/dataset/targetV2/storeOrUpdateDepth', method: 'post', data: params })
}

// 获取模块下的所有可以延迟重跑的指标
export function getDelayTargets (params) {
  return request({ url: '/statistics/dataProcess/getDelayTargets', method: 'get', params: params })
}

// 保存延迟重跑指标
export function saveAppDelay (params) {
  return request({ url: '/statistics/dataProcess/saveAppDelay', method: 'post', data: params })
}

// 查询模块详情
export function getAppDetail (params) {
  return request({ url: '/statistics/dataProcess/getAppDetail', method: 'get', params: params })
}
