import { request } from '../utils/request'

// 获取树结构（有权限）
export function getPathTree (params) {
    return request({ url: '/visual/node/getPathTree', method: 'post', data: params })
}

// 获取树结构（无权限）
export function getPathTreeAuth (params) {
    return request({ url: '/visual/node/getPathTreeAuth', method: 'post', data: params })
}

// 新增节点
export function insertPath (params) {
    return request({ url: '/visual/node/insertPath', method: 'post', data: params })
}

// 编辑节点
export function updatePath (params) {
    return request({ url: '/visual/node/updatePath', method: 'post', data: params })
}

// 获取id详情
export const getPathInfo = params => {
    return request({url: '/visual/node/getPathInfo', method: 'get', params: params})
}

// 记录页面路由日志
export function record (params) {
    return request({ url: '/statistics/page/record', method: 'post', data: params })
}

// 设置用户主页
export function setHomePath (params) {
    return request({ url: '/visual/node/setHomePath', method: 'post', data: params })
}

// 获取用户主页
export function getHomePath (params) {
    return request({ url: '/visual/node/getHomePath', method: 'get', params: params })
}

// 排序节点
export function setNodesSort (params) {
    return request({ url: '/visual/node/setNodesSort', method: 'post', data: params })
}

// 获取公司人员姓名
export function staffItems (params) {
    return request({ url: '/admin/staff/staffItems', method: 'get', params: params })
}
