import {useEffect, useState, useMemo} from 'react'
import _ from 'lodash'
import Layout from '@common/Layout'
import {getPublicCfg} from '@services/Derived'
import UseGetTopic from '@customHooks/UseGetTopic'
import '../index.css'

function BasicPage(prop) {

    const getTopicRecord = UseGetTopic()
    const [cfgData, setCfgData,] = useState({})
    // console.log('cfgData======', cfgData)

    useEffect(() => {
        getPublicCfg().then(res => {
            if (res.data.code === 0 && res.data.data) {
                let cfgData = {}
                for (let i in res.data.data) {
                    cfgData[i] = res.data.data[i]
                }
                setCfgData(cfgData)
            }
        })
    }, [])

    let labelDetail = prop.labelDetail
    // console.log('labelDetail======', labelDetail)

    // 主题域
    const topicArr = useMemo(() => {
        return _.isArray(_.get(getTopicRecord, 'topic')) ? _.get(getTopicRecord, 'topic') : []
    }, [getTopicRecord])
    const topicValue = topicArr.find(i => i.id == labelDetail.topic) || {}

    // 业务
    const businessArr = useMemo(() => {
        return _.isArray(_.get(getTopicRecord, 'business')) ? _.get(getTopicRecord, 'business') : []
    }, [getTopicRecord])
    const businessValue = businessArr.find(i => i.id == labelDetail.business) || {}

    // 敏感等级
    let sensitivityList = {'1': 'L1', '2': 'L2', '3': 'L3', '4': 'L4', '5': 'L5'}
    let sensitivityValue = sensitivityList[labelDetail.sensitivity]

    // 标签值类型
    if (cfgData.hasOwnProperty('label_value_type')) {
        cfgData['label_value_type'].forEach(element => {
            if (element.value == labelDetail.label_value_type) {
                labelDetail.label_value_type_ch = element.name
            }
        })
    }

    // 有效期
    if (cfgData.hasOwnProperty('label_validate_select')) {
        cfgData['label_validate_select'].forEach(element => {
            if (element.value == labelDetail.life_cycle) {
                labelDetail.life_cycle_ch = element.name
            }
        })
    }

    return (
        <div className='page-info'>
            <div className='page-info__content'>
                <Layout.Row gutter={20}>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>标签负责人</span>
                            <span className='info-text'>{labelDetail.onwer || '-'}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>标签需求方</span>
                            <span className='info-text'>{labelDetail.require_rtx || '-'}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter={20}>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>一级分类</span>
                            <span className='info-text'>{labelDetail.first_classify || '-'}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>二级分类</span>
                            <span className='info-text'>{labelDetail.second_classify || '-'}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter={20}>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>所属主题域</span>
                            <span className='info-text'>{topicValue['topic_name'] || '-'}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>所属业务</span>
                            <span className='info-text'>{businessValue['business_name'] || '-'}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter={20}>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>敏感等级</span>
                            <span className='info-text'>{sensitivityValue || '-'}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>标签值类型</span>
                            <span className='info-text'>{labelDetail.label_value_type_ch || '-'}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter={20}>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>标签备注</span>
                            <span className='info-text'>{labelDetail.remark || '-'}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>有效期</span>
                            <span className='info-text'>{labelDetail.life_cycle_ch || '-'}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter={20}>
                    <Layout.Col span='12'>
                        <div>
                            <span className='info-title'>标签值示例</span>
                            <span className='info-text'>{labelDetail.value_mapping || '-'}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
            </div>
        </div>
    )
}

export default BasicPage