import React from 'react'
import SdwDialog from '@common/Dialog'
import SdwCheckbox from '@common/Checkbox'
import SdwMessage from '@common/Message'
import { DateRangePicker } from 'element-react'
import { runTargetResult } from '@services/api'
import { formatTime } from '@utils/formatTime'
import './style/reRunWin.css'

export default class ReRunWin extends React.Component {

  state = {
    dataPicker: '',
    isChecked: false,
    isChecked2: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.record.id !== this.props.record.id) {
      this.initData()
    }
  }

  initData = () => {
    this.setState({
      dataPicker: '',
      isChecked: false,
      isChecked2: false,
    })
  }

  handleSubmit = () => {
    let { dataPicker, isChecked, isChecked2 } = this.state
    let {record, curClickTreeValue} = this.props
    // 未选择时间，不下发重跑
    if (!dataPicker) return
    console.log("reRunWin=======dataPicker", dataPicker)
    console.log("reRunWin=======isChecked", isChecked)
    console.log("reRunWin=======isChecked2", isChecked2)
    console.log("reRunWin=======isChecked2", isChecked2)
    console.log("reRunWin=======record", record)
    console.log("reRunWin=======curClickTreeValue", curClickTreeValue)
    let params = {}
    params["api_id"] = record.id
    params["template_id"] = curClickTreeValue.id
    if (dataPicker && dataPicker.length > 0) {
      params["start_time"] = formatTime(+new Date(dataPicker[0]), 0)
      params["end_time"] = formatTime(+new Date(dataPicker[1]), 0)
    }
    params["is_online"] = +isChecked
    params["is_test"] = +isChecked2
    runTargetResult(params).then(res => {

      let record = res && res.data || {}
      let appTable = record && record.data && record.data.app_table

      if (record.code === 0) {
        SdwMessage.success(`${!!appTable ? '指标：' + appTable : ''} 重跑任务提交成功`)
        this.props.changeReRunVisible(false)
        return
      }

      const errMsg = record.msg || `${!!appTable ? '指标：' + appTable : ''} 重跑任务提交失败`
      SdwMessage.error(errMsg)
    }, () => SdwMessage.error("接口异常"))
  }

  render () {
    let { reRunVisible, changeReRunVisible } = this.props

    let { dataPicker, isChecked, isChecked2 } = this.state

    return (
      <SdwDialog
        title='重跑数据'
        visible={reRunVisible}
        changeVisible={changeReRunVisible}
        onCancelClick={() => changeReRunVisible(false)}
        onSureClick={this.handleSubmit}
        width={524}
      >
        <div className="sdw-re-run-win__date-range-picker-wrap">
          <span style={{ marginRight: '42px', wordBreak: 'keep-all' }}>时间</span>
          <DateRangePicker
            value={dataPicker}
            isShowTime={true}
            isReadOnly={true}
            width={400}
            rangeSeparator=" 至 "
            placeholder="选择日期范围"
            format="yyyy-MM-dd HH:mm:ss"
            align="right"
            disabledDate={time=>time.getTime() > (Date.now() + 24*60*60*1000)}
            onChange={date => {
              this.setState({ dataPicker: date })
            }}
          />
        </div>
        <div className="sdw-re-run-win__date-range-picker-wrap" style={{marginTop: 16, display: 'flex'}}>
          <span style={{ marginRight: '16px', transform: 'translateY(10px)', wordBreak: 'keep-all' }}>数据同步</span>
          <div className="sdw-re-run-win__checkBox">
            <SdwCheckbox
              text="测试环境"
              isChecked={isChecked2}
              changeCheckedFun={() => {
                this.setState({
                  isChecked2: !isChecked2
                })
              }}
            />
            <div style={{marginLeft:"50px"}}>
              <SdwCheckbox
                text="正式环境"
                isChecked={isChecked}
                changeCheckedFun={() => {
                  this.setState({
                    isChecked: !isChecked
                  })
                }}
              />
            </div>
          </div>
        </div>
      </SdwDialog>
    )
  }
}
