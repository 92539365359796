import { request } from '../utils/request'

// 获取数据字典页面常见主题域和改主题拥有的字段列表
export const getItermSelects = () => {
  return request({ url: '/statistics/dataMarket/getItermSelects', method: 'get' })
}

export const searchFields = params => {
  return request({ url: '/statistics/dataMarket/searchFields', method: 'get', params: params })
}

export const searchFieldsForAll = params => {
  return request({ url: '/statistics/dataMarket/searchFieldsForAll', method: 'get', params: params })
}

export const getFieldById = params => {
  return request({ url: '/statistics/dataMarket/getFieldById', method: 'get', params: params })
}

export const getFieldUpdateHistory = params => {
  return request({ url: '/statistics/dataMarket/getFieldUpdateHistory', method: 'get', params: params })
}

export const getBusinessStatisticByTopic = params => {
  return request({ url: '/statistics/dataMarket/getBusinessStatisticByTopic', method: 'get', params: params })
}

export const getFieldRelativeTableInfo = params => {
  return request({ url: '/statistics/dataMarket/getFieldRelativeTableInfo', method: 'get', params: params })
}

export const storeNewField = params => {
  return request({ url: '/statistics/dataMarket/storeNewField', method: 'post', data: params })
}
