export const Mapping = {
  float_rate: '变化率型',
  float_cnt: '变化量型',
  daily_avg: '均值型',
  rank: '排名型',
  "": '区域性',
  filter: '过滤型',
  rank_rate: '击败率型'
}

// 变化率--变化类型
export const CHECKED_OPTION = [{
  key: 'day_annulus',
  timeCycle: '1d',
  text: '日环比（1d）'
}, {
  key: '7day_avg',
  timeCycle: '1d',
  text: '前7天均值日环比（1d）'
}, {
  key: '14day_avg',
  timeCycle: '1d',
  text: '前14天均值日环比（1d）'
}, {
  key: 'day_annulus',
  timeCycle: 'b2d',
  text: '日环比（b2d）'
}, {
  key: 'day_annulus',
  timeCycle: 'b3d',
  text: '日环比（b3d）'
}, {
  key: 'day_annulus',
  timeCycle: '7d',
  text: '累计日环比（7d）'
}, {
  key: 'week_annulus',
  timeCycle: 'wtd',
  text: '周环比（wtd）'
}, {
  key: 'week_same',
  timeCycle: '7d',
  text: '周同比（7d）'
}, {
  key: 'day_annulus',
  timeCycle: '30d',
  text: '累计日环比（30d）'
}, {
  key: 'week_same',
  timeCycle: '30d',
  text: '周同比（30d）'
}, {
  key: 'day_annulus',
  timeCycle: '90d',
  text: '累计日环比（90d）'
}, {
  key: 'day_annulus',
  timeCycle: '180d',
  text: '累计日环比（180d）'
}, {
  key: 'month_annulus',
  timeCycle: 'mtd',
  text: '月环比（mtd）'
}, {
  key: 'month_annulus',
  timeCycle: 'asm',
  text: '月环比（asm）'
}, {
  key: 'month_day#avg#annulus',
  timeCycle: 'mtd',
  text: '日均值环比（mtd）'
}, {
  key: 'week_day#avg#annulus',
  timeCycle: 'wtd',
  text: '日均值环比（wtd）'
}]

// 变化量--变化类型
export const CHECKED_OPTION1 = [{
  key: 'day_annulus',
  timeCycle: '1d',
  text: '近1天变化量（1d）'
}, {
  key: '7day_avg',
  timeCycle: '1d',
  text: '前7天均值日变化量（1d）'
}, {
  key: '14day_avg',
  timeCycle: '1d',
  text: '前14天均值日变化量（1d）'
}, {
  key: 'day_annulus',
  timeCycle: 'b2d',
  text: '近一天变化量（b2d）'
}, {
  key: 'day_annulus',
  timeCycle: 'b3d',
  text: '近一天变化量（b3d）'
}, {
  key: 'day_annulus',
  timeCycle: '7d',
  text: '近1天变化量（7d）'
}, {
  key: 'day_annulus',
  timeCycle: '30d',
  text: '近1天变化量（30d）'
}, {
  key: 'week_annulus',
  timeCycle: 'wtd',
  text: '近1周变化量（wtd）'
}, {
  key: 'week_annulus',
  timeCycle: '30d',
  text: '周同比变化量（30d）'
}, {
  key: 'day_annulus',
  timeCycle: '90d',
  text: '近1天变化量（90d）'
}, {
  key: 'day_annulus',
  timeCycle: '180d',
  text: '近1天变化量（180d）'
}, {
  key: 'month_annulus',
  timeCycle: 'mtd',
  text: '近1个月变化量（mtd）'
}, {
  key: 'month_annulus',
  timeCycle: 'asm',
  text: '近1个绩效月变化量（asm）'
}, {
  key: 'month_day#avg#annulus',
  timeCycle: 'mtd',
  text: '日均值变化量（mtd）'
}, {
  key: 'week_day#avg#annulus',
  timeCycle: 'wtd',
  text: '日均值变化量（wtd）'
}]

// 均值型--变化类型
export const CHECKED_OPTION2 = [{
  key: 'day_avg',
  timeCycle: 'mtd',
  text: '日均值（mtd）'
}, {
  key: 'day_avg',
  timeCycle: 'wtd',
  text: '日均值（wtd）'
}]

// 变化率--变化类型--对应中英文命名的后缀：键值是：[`${float_type}_${time_cycle}`]
export const RATE_NAME_MAP = {
  'day_annulus_1d': {
    cn: '_1d日环比',
    en: '_1drh_dbl'
  },
  '7day_avg_1d': {
    cn: '_1d7天均值日环比',
    en: '_1drh_7avg_dbl'
  },
  '14day_avg_1d': {
    cn: '_1d14天均值日环比',
    en: '_1drh_14avg_dbl'
  },
  'day_annulus_b2d': {
    cn: '_b2d日环比',
    en: '_b2drh_dbl'
  },
  'day_annulus_b3d': {
    cn: '_b3d日环比',
    en: '_b3drh_dbl'
  },
  'day_annulus_7d': {
    cn: '_7d累计日环比',
    en: '_7drh_dbl'
  },
  'week_same_7d': {
    cn: '_7d周同比',
    en: '_7dzt_dbl'
  },
  'week_annulus_wtd': {
    cn: '_wtd周环比',
    en: '_wtdrt_dbl'
  },
  'day_annulus_30d': {
    cn: '_30d累计日环比',
    en: '_30drh_dbl'
  },
  'week_same_30d': {
    cn: '_30d周同比',
    en: '_30dzt_dbl'
  },
  'day_annulus_90d': {
    cn: '_90d累计日环比',
    en: '_90drh_dbl'
  },
  'day_annulus_180d': {
    cn: '_180d累计日环比',
    en: '_180drh_dbl'
  },
  'month_annulus_mtd': {
    cn: '_mtd月环比',
    en: '_zmh_dbl'
  },
  'month_day#avg#annulus_mtd': {
    cn: '_mtd日均值环比',
    en: '_mtd_davgh_dbl'
  },
  'month_annulus_asm': {
    cn: '_asm月环比',
    en: '_amh_dbl'
  },
  'week_day#avg#annulus_wtd': {
    cn: '_wtd日均值环比',
    en: '_wtd_davgh_dbl'
  }
}

// 变化量--变化类型--对应中英文命名的后缀：键值是：[`${float_type}_${time_cycle}`]
export const CNT_NAME_MAP = {
  'day_annulus_1d': {
    cn: '_1d变化量',
    en: '_1d_dbl'
  },
  '7day_avg_1d': {
    cn: '_1d7天均值日变化量',
    en: '_1d_7avg_dbl'
  },
  '14day_avg_1d': {
    cn: '_1d14天均值日变化量',
    en: '_1d_14avg_dbl'
  },
  'day_annulus_b2d': {
    cn: '_b2d变化量',
    en: '_b2d_dbl'
  },
  'day_annulus_b3d': {
    cn: '_b3d变化量',
    en: '_b3d_dbl'
  },
  'day_annulus_7d': {
    cn: '_7d变化量',
    en: '_7d_dbl'
  },
  'day_annulus_30d': {
    cn: '_30d变化量',
    en: '_30d_dbl'
  },
  'week_annulus_30d': {
    cn: '_30d周同比变化量',
    en: '_30dztl_dbl'
  },
  'day_annulus_90d': {
    cn: '_90d变化量',
    en: '_90d_dbl'
  },
  'day_annulus_180d': {
    cn: '_180d变化量',
    en: '_180d_dbl'
  },
  'week_annulus_wtd': {
    cn: '_wtd近1周变化量',
    en: '_wtd_dbl'
  },
  'week_day#avg#annulus_wtd': {
    cn: '_wtd日均值变化量',
    en: '_wtd_davgc_dbl'
  },
  'month_annulus_mtd': {
    cn: '_1m变化量',
    en: '_1m_dbl'
  },
  'month_day#avg#annulus_mtd': {
    cn: '_mtd日均值变化量',
    en: '_mtd_davgc_dbl'
  },
  'month_annulus_asm': {
    cn: '_asm变化量',
    en: '_asm_dbl'
  }
}

// 均值型--变化类型--对应中英文命名的后缀：键值是：[`${float_type}_${time_cycle}`]
export const AVG_NAME_MAP = {
  'day_avg_mtd': {
    cn: '_mtd日均值',
    en: '_mtd_davg_dbl'
  },
  'day_avg_wtd': {
    cn: '_wtd日均值',
    en: '_wtd_davg_dbl'
  }
}

// Json

// Assign

// setState
