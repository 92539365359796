import produce from 'immer'

const state = {
  pageApiTargets: [], // 点击账号专区API  所有的已选择指标---用于表二显示使用
  curClickTreeValue: {}, //点击的专区API
  currentData:{}, //点击指标API列表编辑的item
  previewData: {
    data:[],
    header:[],
  }, //调用测试table
}

const updatePageApiTargets = produce((state, payload) => {
  state.pageApiTargets = payload
})
const updateCurClickTreeValue = produce((state, payload) => {
  state.curClickTreeValue = payload
})
const updateCurrentData = produce((state, payload) => {
  state.currentData = payload
})

const updatePreviewData = produce((state, payload) => {
  state.previewData = payload
})

const reducers = {
  updatePageApiTargets,
  updateCurClickTreeValue,
  updatePreviewData,
  updateCurrentData,
}

export default { state, reducers }
