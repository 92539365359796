import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import { getTargetCfgById } from '@services/application'
import { searchTarget } from '@services/application'
import { TOP_MAP } from '../common'
import SdwDialog from '@common/Dialog'
import SdwMessage from '@common/Message'

import TagTree from './TagTree'
import IndicatorAttributes from './IndicatorAttributes'
import TabPage from './TabPage'

import './style/index.css'

class indexDetail extends React.Component {

  state = {
    previewDataMsg: '',
    dialogVisible: false,
    setTopVisible: false,
    topicData: [],
    runCycleData: [],
    previewTableHeader: [], // 预览数据表头
    settingDialogVisible: false,
    currentData:{},
    enterIndexDetailEdit:true,
  }

  componentDidMount () {
    this.initData()
  }

  initData = async () => {
    // this.initDatePicker()

    let id = this.props.location.state && this.props.location.state.id || ''
    console.log("==========initData----indexDetail", this.props.location.state)
    if (!!id) {
      let record = this.props.location.state
      this.props.updateApiTemplateClickTreeValue(record)
      this.props.updateCurrentData(record)
      this.setState({
        enterIndexDetailEdit:false,
        currentData:record
      })
      this.onSearchTarget()
    }
  }
  changeDialogVisible = flag => {
    this.setState({
      dialogVisible: flag
    })
  }

  changeSettingDialogVisible = flag => this.setState({ settingDialogVisible: flag })

  initClickTreeNode = val => {
    this.props.updateCurClickTreeValue(val)

    // 点击叶子节点，查询对应的信息
    if (!val.children) {
      this.onGetTarget(val)
    } else {
      this.props.updateTargetTime([])
      this.props.updateTargetDimension([])
    }
  }

  hanlderChangeTreeSubmit = async () => {
    await this.onSubmit()

    this.hanlderChangeTreeCancel()
  }

  hanlderChangeTreeCancel = () => {
    this.props.changeComfirmVisible(false)
    this.initClickTreeNode(this.props.currentClickTreeNode)
  }

  onGetTarget = record => {

    //  TODO  请求当前指标下的原始数据
    this.onSearchTarget(record.id, true).then(() => {
      this.onGetTargetCfgById(record)
    }, () => SdwMessage.error("接口异常"))
  }
// isSingelTarget: 是否是单个指标查询
  onSearchTarget = (targetID = '', isSingelTarget = false) => {
    let params = {
      table_id: {
        value: this.props.currentData.id,
        operator: '='
      },
      ...!!targetID ? {
        target_id: {
          value: targetID,
          operator: '='
        }
      } : {}
    }
    return searchTarget({
      search: JSON.stringify(params)
    })
      .then(res => {
        let oriData = []
        if (res && res.data && res.data.code === 0) {
          let jsonData = _.isArray(res.data.data) ? res.data.data : []
          oriData = jsonData.map(i => {
            return Object.assign({}, i, {
              "db_id": i.id,
              "target_id": i.target_id,
              "name_cn": i.target_name_cn,
              "name": i.target_name_en,
              "time": i.time_cycle,
              "dimension": i.target_dim,
              "target_type": i.target_type,
              "target_top": i.top_count
            })
          })
        }

        if (isSingelTarget) {
          this.props.updateCurShowSelectedTarget(oriData)
          this.props.updateOriAllSelectedTarget(oriData)

          // 触发时间 + 维度的勾选
          this.setState({
            reWriteData: true
          })
        } else {
          //this.props.updateAllSelectedIndicator(oriData)
        }
      }, () => SdwMessage.error("接口异常"))
  }
  onGetTargetCfgById = record => {
    getTargetCfgById({
      id: record.id
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let jsonData = res.data.data || {}
          let time = jsonData.time_cycle || []
          let dimension = jsonData.dimension || []

          this.props.updateCurClickTreeValue(Object.assign({}, this.props.curClickTreeValue, jsonData))
          this.props.updateFilterStr("")
          this.props.updateTargetTime(time.map(i => {
            return {
              ...i,
              isChecked: false
            }
          }))

          this.props.updateTargetDimension(dimension.map(i => {
            return {
              id: i.id,
              isChecked: false,
              name_en: i.en,
              name: `${i.cn || '-'}（${i.en || '-'}）`
            }
          }))

          this.setState({
            reWriteData: true, // 还原时间 + 维度的勾选
          })
        }
      }, () => SdwMessage.error("接口异常"))
  }

  reInitData = () => {
    let { curClickTreeValue } = this.props
    this.onSearchTarget(curClickTreeValue.id, true) // 不仅要更新单个指标的数据
    this.onSearchTarget() // 还要更新总表的数据---已选指标表二
    this.initData() // 更新当前数据
  }

  formatParams = list => {
    return list.map(i => {
      return {
        ...i.id ? {
          id: i.id
        } : {},
        table_id: i.table_id,
        target_id: i.target_id,
        target_name_en: i.name,
        target_name_cn: i.name_cn,
        time_cycle: i.time,
        target_dim: i.dimension,
        target_type: i.target_type,
        ...i.target_type === TOP_MAP.top ? {
          top_count: i.target_top
        } : {}
      }
    })
  }

  render () {

    let {
      enterIndexDetailEdit,
      currentData,
    } = this.state

    let {
      comfirmVisible,
    } = this.props

    return (
      <div className="sdw-application-detail__wrap">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/atom'
            }, {
              title: '指标API',
              path: '/admin/factory/api'
            }, {
              title: '指标API详情',
              path: '/admin/factory/apiDetail/'+currentData.api_id
            }, {
              title: '指标编辑'
            }
          ]}
        />

        <div
          className="sdw-api-detail__center-wrap"
          style={{
            height: document.body.offsetHeight - 300
          }}
        >
          <div className="left-box">
            <TagTree
              onGetTarget={this.onGetTarget}
            />
          </div>
          <div className="center-box">
            <IndicatorAttributes
              currentData={currentData}
              enterIndexDetailEdit={enterIndexDetailEdit}
            />
          </div>
          <div className="right-box">
            <TabPage
              currentData={currentData}
              enterIndexDetailEdit={enterIndexDetailEdit}
              onSubmit={this.onSubmit}
            />
          </div>
        </div>
        <SdwDialog
          hideHeader={true}
          visible={comfirmVisible}
          width={480}
          height={180}
          changeVisible={ this.props.changeComfirmVisible }
          onCancelClick={ this.hanlderChangeTreeCancel }
          onSureClick={ this.hanlderChangeTreeSubmit }
        >
          <span style={{
            fontSize: 18,
            marginTop: 36,
            display: 'inline-block'
          }}>是否保存对该指标的修改</span>
        </SdwDialog>
      </div>
    )
  }
}

const mapState = state => ({
  curShowSelectedTarget: state.apiIndex.curShowSelectedTarget,
  oriAllSelectedTarget: state.apiIndex.oriAllSelectedTarget,
  comfirmVisible: state.apiIndex.comfirmVisible,
  currentClickTreeNode: state.apiIndex.currentClickTreeNode,
  curClickTreeValue: state.apiIndex.curClickTreeValue,
  targetTime: state.apiIndex.targetTime,
  targetDimension: state.apiIndex.targetDimension,
  apiTemplateClickTreeValue: state.apiIndex.apiTemplateClickTreeValue,
  filterStr: state.apiIndex.filterStr,
  currentData: state.apiIndex.currentData,
  indexDetailIsEdit: state.apiIndex.indexDetailIsEdit,
})

const mapDispatch = dispatch => ({
  updateCurShowSelectedTarget: dispatch.apiIndex.updateCurShowSelectedTarget,
  updateOriAllSelectedTarget: dispatch.apiIndex.updateOriAllSelectedTarget,
  changeComfirmVisible: dispatch.apiIndex.changeComfirmVisible,
  updateCurClickTreeValue: dispatch.apiIndex.updateCurClickTreeValue,
  updateTargetTime: dispatch.apiIndex.updateTargetTime,
  updateTargetDimension: dispatch.apiIndex.updateTargetDimension,
  updateApiTemplateClickTreeValue: dispatch.apiIndex.updateApiTemplateClickTreeValue,
  updateFilterStr: dispatch.apiIndex.updateFilterStr,
  updateCurrentData: dispatch.apiIndex.updateCurrentData,
  updateIndexDetailIsEdit: dispatch.apiIndex.updateIndexDetailIsEdit,
})

export default connect(mapState, mapDispatch)(indexDetail)
