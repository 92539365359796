import React from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Tabs} from 'element-react'
import {pageApiTargets, saveApiTargets, deleteApiTargets} from '@services/api'
import SdwMessage from '@common/Message'
import SetTopWin from '../SetTopWin'
import SelectedAttributes from './SelectedAttributes'
import SelectedAttributesTwo from './SelectedAttributesTwo'
import SdwDialog from '@common/Dialog'
import {hasPriv} from '@components/FrontendAuth/AuthMap'
import EditCurrentWin from '@pages/MachFactory/Api/EditCurrentWin'

const CUR_TARGET_INDEX = '1'

class TabPage extends React.PureComponent {

    state = {
        curActiveName: CUR_TARGET_INDEX,
        setTopVisible: false,
        delVisible: false,
        curClickTargetData: {}, // 当前编辑扩展字段点击的对象，设置指标 or 删除的项
        curClickTargetData_index: 0, // 当前编辑扩展字段点击的对象，设置指标 or 删除的数组index
        hasClickTargetData: {}, // 已选指标
        editCurrentWinVisible: false, // 已选指标编辑窗口
        curShowSelectedTarget: [], //当前指标
        allSelectedIndicator: [],
        hasSelectedIndicator: {},
        search: {},
        searchData: {
            page: 1,
            limit: 1000,
        },
    }

    componentDidMount() {
        let {enterIndexDetailEdit} = this.props
        if (enterIndexDetailEdit) {
            this.setState({
                curShowSelectedTarget: []
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 预览数据，时间搜索
        if (
            //prevProps.curClickTargetData != this.props.curClickTargetData ||
            prevProps.curShowSelectedTarget != this.props.curShowSelectedTarget
        ) {
            this.addCurShowSelectedTarget()
        }
        if (prevProps.currentData != this.props.currentData) {
            this.iniAllSelectedIndicator()
        }
    }

    iniAllSelectedIndicator = () => {
        let {currentData} = this.props
        let {search, searchData} = this.state
        let searchClone = _.cloneDeep(search)
        this.setState({
            search: searchClone
        })
        let params = {
            "template_id": currentData.id,
            "search": {},
            "orSearch": {},
            ...searchData
        }
        for (const key in searchClone) {
            if (key !== "") {
                const dval = searchClone[key]
                if (dval.value !== "") {
                    params.search[key] = dval
                }
            }
        }
        pageApiTargets(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let targetData = res.data.data.items || []
                //this.props.updateAllSelectedIndicator(targetData)
                this.setState({
                    allSelectedIndicator: targetData
                })
            }
        }, () => SdwMessage.error("接口异常"))
    }

    setSearch = (type, value) => {
        let {search, searchData} = this.state
        let searchObj
        if (type === "a.id" || type === "a.target_id") {
            if (value) {
                searchObj = {operator: "in", value: value.split(",")}
            } else {
                searchObj = {operator: "in", value: ""}
            }
        } else {
            searchObj = {operator: "like", value: value}
        }
        this.setState({
            search: {
                [type]: _.assign({}, search[type], searchObj)
            },
            searchData: _.assign({}, searchData, {
                page: 1
            })
        }, () => {
            this.iniAllSelectedIndicator()
        })
    }

    //新增指标到当前指标里去
    addCurShowSelectedTarget = () => {
        let {curShowSelectedTarget} = this.state
        let curShowSelectedTargetClone = _.cloneDeep(curShowSelectedTarget)
        let curShowSelectedTargetProps = this.props.curShowSelectedTarget
        //如果新增的指标和当前指标中的template_id、target_id、time_cycle、dimension_value、filter_str
        if (curShowSelectedTargetProps.length > 0) {
            for (let index in curShowSelectedTargetProps) {
                if (curShowSelectedTarget.length == 0) {
                    curShowSelectedTargetClone = curShowSelectedTargetProps
                } else {
                    let findIndex = curShowSelectedTarget.findIndex(i => i.template_id === curShowSelectedTargetProps[index].template_id && i.target_id === curShowSelectedTargetProps[index].target_id && i.time_cycle === curShowSelectedTargetProps[index].time_cycle && i.dimension_value === curShowSelectedTargetProps[index].dimension_value && i.filter_str === curShowSelectedTargetProps[index].filter_str)
                    if (findIndex == -1) {
                        curShowSelectedTargetClone.push(curShowSelectedTargetProps[index])
                    }
                }
            }
            this.setState({
                curShowSelectedTarget: curShowSelectedTargetClone
            })
        }
    }

    //当前指标-确定按钮
    onSubmit = () => {
        let {curShowSelectedTarget} = this.state
        console.log('curShowSelectedTarget=====', curShowSelectedTarget)
        let {currentData} = this.props
        if (curShowSelectedTarget.length == 0) {
            return
        }
        let params = {
            api_id: currentData.api_id,
            template_id: currentData.id,
            targets: []
        }
        for (let i in curShowSelectedTarget) {
            if (curShowSelectedTarget[i].hasOwnProperty("targets")) {
                delete curShowSelectedTarget[i].targets.group_value_name
                params.targets.push({
                    ...curShowSelectedTarget[i].targets
                })
            } else {
                params.targets.push({
                    "target_id": curShowSelectedTarget[i].target_id, // 指标id
                    "dimension_value": curShowSelectedTarget[i].dimension_value, // 维度
                    "time_cycle": curShowSelectedTarget[i].time_cycle, // 时间周期
                    "filter_str": curShowSelectedTarget[i].filter_str, // 过滤条件
                    "short_name": "", // 简称
                    "full_name": "", // 全称
                    "table_unit": "", // 单位
                    "sort_value": "", // 排序
                    "group_value": "", // 分组
                    "alarm_ids": "", // 预警值
                    "remark": "",
                    "agg_name": "", // 聚合算法
                    "agg_calculate": "",
                })
            }
        }
        saveApiTargets({
            ...params
        }).then(res => {
            if (res && res.data && res.data.code === 0) {
                this.iniAllSelectedIndicator()
                this.setState({
                    curShowSelectedTarget: []
                })
            } else {
                SdwMessage.error("参数异常")
            }
        }, () => SdwMessage.error("接口异常"))
    }

    //当前指标-编辑button
    handleSetButton = (data, index) => {
        this.setState({
            curClickTargetData: data,
            curClickTargetData_index: index
        }, () => {
            this.changeSetTopVisible(true)
        })
    }

    //当前指标-删除button
    handleDeleteButton = index => {
        let {curShowSelectedTarget} = this.state
        let arr = _.cloneDeep(curShowSelectedTarget)
        arr.splice(index, 1)
        this.setState({
            curShowSelectedTarget: arr
        })
    }

    //当前指标-编辑button - 扩展字段修改字段
    updateTargetValue = (curClickTargetData_index, params) => {
        let {curShowSelectedTarget} = this.state
        let arr = _.cloneDeep(curShowSelectedTarget)
        arr[curClickTargetData_index].targets = params
        arr[curClickTargetData_index].is_edit_target = 1
        this.setState({
            curShowSelectedTarget: arr
        })
    }

    //已选指标-编辑button
    editHasTargetButton = (data) => {
        this.setState({
            hasClickTargetData: data,
            editCurrentWinVisible: true,
        })
    }

    //已选指标-删除button
    delHasTargetButton = () => {
        let hasSelectedIndicator = this.state.hasSelectedIndicator
        let params = {
            api_id: hasSelectedIndicator.api_id,
            template_id: hasSelectedIndicator.template_id,
            targets: [{
                "id": hasSelectedIndicator.id,
                "is_delete": 1,
                "target_id": hasSelectedIndicator.target_id, // 指标id
                "dimension_value": hasSelectedIndicator.dimension_value,  // 维度
                "time_cycle": hasSelectedIndicator.time_cycle, // 时间周期
                "filter_str": hasSelectedIndicator.filter_str, // 过滤条件
                "short_name": hasSelectedIndicator.short_name, // 简称
                "full_name": hasSelectedIndicator.full_name, // 全称
                "sort_value": hasSelectedIndicator.sort_value, // 排序
                "group_value": hasSelectedIndicator.group_value, // 分组
                "alarm_ids": hasSelectedIndicator.alarm_ids // 预警值
            }]
        }
        deleteApiTargets({
            ...params
        })
            .then(res => {
                if (res && res.data && res.data.code === 0) {
                    this.iniAllSelectedIndicator()
                    this.changeDelVisible(false)
                } else {
                    SdwMessage.error("参数异常")
                }
            }, () => SdwMessage.error("接口异常"))
    }

    changeDelVisible = flag => {
        this.setState({
            delVisible: flag
        })
    }

    changeSetTopVisible = flag => {
        this.setState({
            setTopVisible: flag
        })
    }

    getColumns = () => {
        return [
            {
                title: "指标ID",
                dataIndex: "target_id",
                width: 50
            },
            {
                title: "中文名",
                dataIndex: "name_cn",
                width: 100
            },
            {
                title: "英文名",
                showTip: true,
                dataIndex: "name",
                width: 100
            },
            {
                title: "时间",
                dataIndex: "time_cycle",
                width: 50
            },
            {
                title: "维度",
                dataIndex: "dimension_value",
                width: 100
            },
            {
                title: "过滤",
                width: 120,
                dataIndex: "filter_str",
            },
            {
                title: "是否编辑",
                width: 100,
                render: (data, record) => {
                    return (
                        <>
                            {
                                <span className={
                                    data.is_edit_target ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                                }></span>
                            }
                            <span>{data.is_edit_target == 0 ? "未编辑" : "已编辑"}</span>
                        </>
                    )
                }
            },
            {
                title: "操作",
                width: 80,
                fiexd: true,
                render: (data, index) => {
                    return (
                        <>
                            <span
                                style={{'color': '#517df3', 'cursor': 'pointer'}}
                                onClick={() => this.handleSetButton(data, index)}
                            >编辑</span>
                            <span
                                style={{'color': '#517df3', 'cursor': 'pointer', marginLeft: 8}}
                                onClick={() => this.handleDeleteButton(index)}
                            >删除</span>
                        </>
                    )
                },
            }
        ]
    }

    getColumnsTwo = () => {
        return [
            {
                title: "字段ID",
                dataIndex: "id",
                width: 50
            },
            {
                title: "指标ID",
                dataIndex: "target_id",
                width: 50
            },
            {
                title: "中文名",
                dataIndex: "target_name_cn",
                width: 100
            },
            {
                title: "英文名",
                showTip: true,
                dataIndex: "target_name",
                width: 100
            },
            {
                title: "时间",
                dataIndex: "time_cycle",
                width: 30
            },
            {
                title: "维度",
                dataIndex: "dimension_value",
                width: 100
            },
            {
                title: "过滤",
                width: 120,
                dataIndex: "filter_str",
            },
            {
                title: "简称",
                width: 120,
                dataIndex: "short_name",
            },
            {
                title: "全称",
                width: 120,
                dataIndex: "full_name",
            },
            {
                title: "单位",
                width: 120,
                dataIndex: "table_unit",
            },
            {
                title: "排序",
                width: 120,
                dataIndex: "sort_value",
            },
            {
                title: "分组",
                width: 120,
                dataIndex: "group_value",
            },
            {
                title: "关联预警",
                width: 120,
                dataIndex: "alarm_ids",
            },
            {
                title: "备注",
                width: 120,
                dataIndex: "remark",
            },
            {
                title: "聚合方式",
                width: 100,
                render: data => {
                    let arr = {
                        "sum": "求和",
                        "avg": "平均",
                        "calculate": "比率"
                    }
                    let msg = arr[data.agg_name]
                    return <span>{msg}</span>
                }
            },
            {
                title: "计算公式",
                width: 150,
                dataIndex: "agg_calculate",
            },
            ...hasPriv('deleteApiTargets') ? [{
                title: "操作",
                width: 80,
                fiexd: true,
                render: (data, index) => {
                    return (
                        <>
                            <span
                                style={{'color': '#517df3', 'cursor': 'pointer'}}
                                onClick={() => this.editHasTargetButton(data)}
                            >编辑</span>
                            {
                                <span
                                    style={{'color': '#517df3', 'cursor': 'pointer', marginLeft: 8}}
                                    onClick={() => {
                                        this.setState({
                                            hasSelectedIndicator: data
                                        })
                                        this.changeDelVisible(true)
                                    }}
                                >删除</span>
                            }
                        </>
                    )
                },
            }] : [],
        ]
    }

    render() {

        let {
            curActiveName,
            setTopVisible,
            delVisible,
            curClickTargetData,
            curClickTargetData_index,
            curShowSelectedTarget,
            allSelectedIndicator,
            editCurrentWinVisible,
            hasClickTargetData,
        } = this.state

        let {
            oriAllSelectedTarget
        } = this.props

        let isButtomDisabled = curShowSelectedTarget.length !== oriAllSelectedTarget.length ||
            _.differenceWith(curShowSelectedTarget, oriAllSelectedTarget, _.isEqual).length

        return (
            <>
                <div className="current_target">
                    <Tabs activeName="1" onTabClick={(tab) => this.setState({curActiveName: tab.props.name})}>
                        <Tabs.Pane label={`当前指标（${curShowSelectedTarget.length}）`} name={CUR_TARGET_INDEX}>
                            <SelectedAttributes
                                isDisplayPage={false}
                                columns={this.getColumns}
                                dataSource={curShowSelectedTarget}
                            />
                        </Tabs.Pane>
                        <Tabs.Pane label={`已选指标（${allSelectedIndicator.length}）`} name="2">
                            <SelectedAttributesTwo
                                isDisplayPage={true}
                                columns={this.getColumnsTwo}
                                dataSource={allSelectedIndicator}
                                setSearch={this.setSearch}
                            />
                        </Tabs.Pane>
                    </Tabs>
                </div>


                {
                    curActiveName === CUR_TARGET_INDEX &&
                    <div className="sdw-application-detail__opration-buttom-wrap">
                        <div className="sdw-application-detail__opration">
                            <button
                                disabled={!isButtomDisabled}
                                onClick={this.onCancel}
                            >取消
                            </button>
                            <button
                                className="sure-button"
                                disabled={!isButtomDisabled}
                                onClick={this.onSubmit}
                            >确定
                            </button>
                        </div>
                    </div>
                }

                <SetTopWin
                    setTopVisible={setTopVisible}
                    curClickTargetData={curClickTargetData}
                    curClickTargetData_index={curClickTargetData_index}
                    updateTargetValue={this.updateTargetValue}
                    changeSetTopVisible={this.changeSetTopVisible}
                />

                {
                    editCurrentWinVisible &&
                    <EditCurrentWin
                        currentData={this.props.currentData}
                        setTopVisible={editCurrentWinVisible}
                        curClickTargetData={hasClickTargetData}
                        iniAllSelectedIndicator={this.iniAllSelectedIndicator}
                        changeSetTopVisible={flag => this.setState({editCurrentWinVisible: flag})}
                    />
                }

                <SdwDialog
                    hideHeader={true}
                    visible={delVisible}
                    width={480}
                    height={180}
                    onCancelClick={() => this.changeDelVisible(false)}
                    onSureClick={() => this.delHasTargetButton()}
                >
                    <span style={{
                        fontSize: 18,
                        marginTop: 36,
                        display: 'inline-block'
                    }}>是否删除该指标？</span>
                </SdwDialog>
            </>
        )
    }
}

const mapState = state => ({
    //allSelectedIndicator: state.apiIndex.allSelectedIndicator,
    oriAllSelectedTarget: state.apiIndex.oriAllSelectedTarget,
    curShowSelectedTarget: state.apiIndex.curShowSelectedTarget,
    //currentData: state.apiIndex.currentData,
})

const mapDispatch = dispatch => ({
    //updateAllSelectedIndicator: dispatch.apiIndex.updateAllSelectedIndicator,
    updateCurShowSelectedTarget: dispatch.apiIndex.updateCurShowSelectedTarget,
    updateApiTemplateClickTreeValue: dispatch.apiIndex.updateApiTemplateClickTreeValue,
    //updateCurrentData: dispatch.apiIndex.updateCurrentData,
})

export default connect(mapState, mapDispatch)(TabPage)
