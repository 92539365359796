import React, {PureComponent} from 'react'
import SdwInput from '@common/Input'
import SdwSwitch from '@common/Switch'
import SdwDialog from '@common/Dialog'
import SdwMessage from '@common/Message'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {requestOptionsLOG} from '@config/config'
const baseURL = requestOptionsLOG.basename

class SupplementWin extends PureComponent {

    state = {
        params: {
            id: '',
            exceptionMessage: '',
            relevanceTimeCycle: false,
            rtx: ''
        },
        sholdCheckValidate: false
    }

    // 异常补录
    submitClick = () => {
        let rtx = this.props.userInfo.name
        let {params} = this.state
        let {currentItem} = this.props
        params.id = currentItem.id
        params.rtx = rtx
        if (!params.exceptionMessage) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        fetch(baseURL + '/sdw/filebeat/reconciliation/exceptionAdditionalRecord',
            {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then(data => {
                if (data && data.code === 100) {
                    SdwMessage.success(data.message)
                    this.closeDialog()
                    this.props.queryReconciliations()
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    setParamsData = (type, value) => {
        let {params} = this.state
        let obj = {}
        obj[type] = value
        let paramsClone = Object.assign({}, params, obj)
        this.setState({
            params: paramsClone
        })
    }

    closeDialog = () => {
        this.props.closeDialog()
    }

    render() {

        let {
            params,
            sholdCheckValidate
        } = this.state

        let {
            supplementVisible
        } = this.props

        return (
            <div>
                <SdwDialog
                    title={'补录信息'}
                    width={650}
                    visible={supplementVisible}
                    onCancelClick={() => this.closeDialog()}
                    onSureClick={this.submitClick}
                >
                    <div>
                        <div className='logAccess-win_warp'>
                            <div>
                                <span className='label required'>修复信息</span>
                                <SdwInput
                                    placeholder='请补录修复信息'
                                    type='textarea'
                                    value={params.exceptionMessage}
                                    onChange={val => this.setParamsData('exceptionMessage', val)}
                                    sholdCheckValidate={sholdCheckValidate}
                                    validateFun={value => !!value ? true : '修复信息不能为空'}
                                />
                            </div>
                            <div>
                                <span className='label'>关联本期异常</span>
                                <SdwSwitch
                                    value={params.relevanceTimeCycle}
                                    changeValue={val => this.setParamsData('relevanceTimeCycle', val)}
                                />
                            </div>
                        </div>
                    </div>
                </SdwDialog>
            </div>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(SupplementWin))