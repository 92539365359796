import React from 'react'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import '../css/AddSource.css'
import { getTablecfgDetail } from '@services/modifier'
import SdwMessage from '@common/Message'

export default class TableSource extends React.Component {




  changeDataSource = (item) => {
    this.props.setTableNodeValue("table_id", item)
    getTablecfgDetail({id:item.id}).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        let timeFieldList = []
        record.fields = record.fields || []
        record.fields.forEach(i => {
          if (i.field_type == "date" || i.field_type == "timestamp"|| i.field_type == "datetime" || i.field_type=="esdate" || i.field_type=="date(7)") {
            timeFieldList.push({id:i.field_name, text:i.field_name})
          }
        })
        this.props.setTableNodeValue("time_field", [])
        this.props.setTableNodeValue("timeFieldList", timeFieldList)
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }

  render () {
    let {
      nodeParams,
      sholdCheckValidate,
      tableList,
      timeFieldList,
      timeCycleList,
      setTableNodeValue,
      changeDataSetType,
    } = this.props
    console.log("====tableList", tableList)
    console.log("====timeFieldList", timeFieldList)
    return (
      <>
        <div className='add-AddSource-win__label' >
          <span className='label__title required'>数仓类型</span>
          <SdwSelect
            width={450}
            listWidth={450}
            clearable={false}
            value={nodeParams.data_set_type}
            data={[{id:"es", text:"ES"}, { id:"mysql", text:"Mysql"}]}
            onChange={val => {
              changeDataSetType(val)
            }}
            sholdCheckValidate={sholdCheckValidate}
            validateFun={val=> { return !!val?true:"数仓类型不能为空"}}
          />
        </div>
        <div className='add-AddSource-win__label'>
          <span className='label__title required'>数据源</span>
          <SdwSelect
            placeholder={"请选择数据源"}
            width={450}
            listWidth={450}
            clearable={false}
            readOnly={false}
            value={nodeParams.table_id}
            data={tableList.map(el => {
              return {
                id: el.id,
                text: "【"+el.warehouse_level + "】"+el.data_set_chinese + '（' + (el.database_name ? el.database_name + '.' : '') + el.table_name + '）',
                data:el,
              }
            })}
            onChange={(val , item) => {
              this.changeDataSource(item.data)
            }}
            sholdCheckValidate={sholdCheckValidate}
            validateFun={val=> { return !!val?true:"数据源不能为空"}}
          />
        </div>
        {
          <div className='add-AddSource-win__label'>
            <span className='label__title required'>取数时间字段</span>
            <SdwSelect
              isMultipleChoice={true}
              placeholder={"请选择取数时间字段"}
              width={450}
              listWidth={450}
              listMaxHeight={150}
              clearable={false}
              readOnly={false}
              value={nodeParams.time_field}
              data={timeFieldList}
              onChange={(val, item) => {
                setTableNodeValue("time_field", val)
              }}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val=> {return val.length > 0?true:"取数时间字段不能为空"}}
            />
          </div>
        }
        {
          <div className='add-AddSource-win__label'>
            <span className='label__title required'>取数范围</span>
            <SdwSelect
              placeholder={"请选择取数范围"}
              width={450}
              listWidth={450}
              listMaxHeight={100}
              clearable={false}
              value={nodeParams.get_time_cycle}
              data={timeCycleList}
              onChange={val => {
                setTableNodeValue("get_time_cycle", val)
              }}
              //sholdCheckValidate={sholdCheckValidate}
              //validateFun={val=> { return !val?true:"取数范围不能为空"}}
            />
          </div>
        }
        <div className='add-AddSource-win__label'>
          <span className='label__title'>过滤条件</span>
          <SdwInput
            placeholder='请输入过滤条件'
            width={450}
            value={nodeParams.filter}
            onChange={val => {
              setTableNodeValue("filter", val)
            }}
          />
        </div>
      </>
    )
  }
}
