import { request } from '../utils/request'

export function getStandardSelects (params) {
  return request({ url: '/metadata/tableApi/getStandardSelects', method: 'get', params: params })
}

export function standardItemSelects (params) {
  return request({ url: '/tagmanage/standardManage/standardItemSelects', method: 'get', params: params })
}

export function getUserTraceItem (params) {
  return request({ url: `/usertrace/userTrace/item`, method: 'get', params: params })
}

export function getUserTraceDetail (params) {
  return request({ url: `/usertrace/userTrace/detail`, method: 'get', params: params })
}
