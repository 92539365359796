import { request } from '../utils/request'
import { ajaxPost } from "./AjaxApi";
import { requestOptions } from '../config/config'

const baseURL = requestOptions.basename

// 获取主题列表
export const getTopicBusiness = (params = {}) => {
  return request({ url: '/dataset/common/getTopicBusiness', method: 'get', data: params })
}

// 获取所有的表
export const getTablecfgList = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgList', method: 'get', params: params })
}

// 获取所有的来源表（并不是库里面所有的，只是原子指标表中已配置了的来源表）,仅在原子指标来源表下拉选项中使用
export const srcTableItems = (params = {}) => {
  return request({ url: '/dataset/atomTargetV2/srcTableItems', method: 'get', params: params })
}

// 获取该表的所有的字段
export const getTablecfgFields = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgFields', method: 'get', params: params })
}

// 获取分页数据
export const getPage = (params = {}) => {
  return request({ url: '/dataset/atomTargetV2/items', method: 'get', params: params })
}

// 获取所有的计算方式
export const getPublicCfg = (params = {}) => {
  return request({ url: '/dataset/common/getPublicCfg', method: 'get', data: params })
}

/**
 * 添加，修改原子指标
 * @param {object} params
 * @param {func} callback
 */
export function storeOrUpdateAtomTarget (params, callback) {
  return ajaxPost(baseURL + "/dataset/atomTargetV2/storeOrUpdate", params, callback);
}

// 修改派生指标弹窗确认
export function getChildTarget (params) {
  return request({ url: '/dataset/atomTargetV2/getChildTarget', method: 'get', params: params })
}