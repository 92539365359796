import _ from "lodash";

export const notEmpty = (val) => {
  return _.isString(val) && !!val.trim()
}

export const isLength = (val, number) => {
  return val.trim().length < number
}
/**
 * rulers 校验的规则
 * params 需要校验的参数
 * return false 不通过 true 通过
 */
export const isCheck = (rulers, params) => {
  if (!_.isObject(rulers) && !params) return
  const arr = _.keys(rulers) || []
  let flag = true
  for (let i = 0; i < arr.length; i++) {
    let key = arr[i]
    let rule = rulers[key]
    rule.forEach(element => {
      let val = params[key]
      let res = element(val)
      if(!res) flag = false
    });
  }
  // 如果校验结果为 true 则通过 false则不通过
  return flag
}

// function check( rule, beValiData ) {
//   // 有自定义validator 走自定义检验的方法 没有validator 有required且为true则走公共的定义方法
//   // if (rule.validator) return rule.validator(beValiData)
//   switch (rule.type) {
//     case String:
//       return notEmpty(beValiData)
//     case Number:
//       return _.isNumber(beValiData)
//     case Date:
//       return _.isDate(beValiData)
//     default:
//   }
// }