import { PureComponent } from "react"
import _ from 'lodash'
import SdwButton from '@common/Button'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import Dialog from '@common/Dialog'
import { getAllUploadRecord, deleteUploadRecord } from '@services/dataGovern'
import SdwMessage from "@common/Message/index"
import { hasPriv } from "@components/FrontendAuth/AuthMap"
import { fileDownload } from "@utils/request"

export default class BusinessDataUploadRecord extends PureComponent {

  state = {
    tableData: {},
    searchData: {
      page: 1,
      limit: 10
    }, // 搜索参数: limit\page
    visible: false,
    curID: ''
  }

  componentDidMount() {
    this.initData()
  }

  initData = () => {
    const { searchData } = this.state
    getAllUploadRecord({
      ...searchData
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        if (!_.isEmpty(data)) this.setState({
          tableData: data
        })
      } else {
        const msg = _.get(res, 'data.msg') || '获取数据失败！'
        SdwMessage.error(msg)
      }
    }).catch(err => SdwMessage.error('接口异常' + err))
  }

  getcolumns = () => {
    return [
      {
        title: "上传时间",
        dataIndex: "create_time",
      }, {
        title: "业务",
        dataIndex: "service_name",
      }, {
        title: "文件名",
        dataIndex: "package_name",
      }, {
        title: "模板类型",
        dataIndex: "model_name",
      }, {
        title: "创建人",
        dataIndex: "create_user",
      }, {
        title: "最后操作人",
        dataIndex: "update_user",
      },
      ...(hasPriv('downloadUploadRecord') || hasPriv('deleteUploadRecord')) ? [{
        title: "操作",
        render: data => {
          return (
            <>
            {
              hasPriv('downloadUploadRecord') &&
              <SdwButton
                text='下载'
                onClick={() => fileDownload(`/collection/dataGovern/downloadUploadRecord?id=${data.id}`)}
              />
            }
            {
              hasPriv('deleteUploadRecord') &&
              <SdwButton
                text={data.deleted ? '已删除' : '删除'}
                disabled={data.deleted}
                onClick={() => this.onDelete(data)}
              />
            }
            </>
          )
        }
      }] : []
    ]
  }

  changeDialogVisible = bool => {
    this.setState({
      visible: bool
    })
  }

  onDelete = data => {
    this.setState({
      curID: data.id
    })

    // 二次确定弹窗
    this.changeDialogVisible(true)
  }

  onSureClick = () => {
    const { curID } = this.state

    if (curID) {
      deleteUploadRecord({
        id: curID
      }).then(res => {
        const code = _.get(res, 'data.code')
        if (code === 0) {
          this.initData()
          SdwMessage.success('删除成功！')
          this.changeDialogVisible(false)
        } else {
          const msg = _.get(res, 'data.msg') || '删除失败！'
          SdwMessage.error(msg)
        }
      }).catch(err => SdwMessage.error('接口异常' + err))
    }
  }

  render() {
    const { tableData, searchData, visible } = this.state

    return (
      <>        
        <div style={{ padding: 20, background: '#fff' }}>
          <SdwTable
            columns={this.getcolumns()}
            dataSource={tableData.items || []}
          />
          <SdwPagenation
            total={tableData.total}
            currentPage={tableData.current_page}
            pageCountList={[10, 15, 20, 40]}
            pageCount={tableData.per_page}
            getClickPage={page => {
              this.setState({
                searchData: _.assign({}, searchData, {page})
              }, this.initData)
            }}
            pageCountChange={limit => {
              this.setState({
                searchData: _.assign({}, searchData, {limit})
              }, this.initData)
            }}
          />
        </div>

        {
          visible &&
          <Dialog
            title='提示'
            width={400}
            visible={visible}
            changeVisible={this.changeDialogVisible}
            onCancelClick={() => this.changeDialogVisible(false)}
            onSureClick={this.onSureClick}
          >
            <div>相关数据将标记为删除，是否确认？</div>
          </Dialog>
        }

      </>
    )
  }
}