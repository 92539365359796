import React, {Component} from 'react'
import _ from 'lodash'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwTreeNew from '@common/TreeNew'
import SdwMessage from '@common/Message'
import SdwSwitch from '@common/Switch'
import CatalogueWin from './win/CatalogueWin'
import ReportWin from './win/ReportWin'
import SortWin from './win/SortWin'
import {hasPriv} from '@components/FrontendAuth/AuthMap'
import {requestOptions} from '@config/config'
import {getPathTree, getPathTreeAuth, insertPath, updatePath, getPathInfo, record, getHomePath, setHomePath, staffItems} from '@services/dataVisualization'
import sortImg from './img/sort_img@2x.png'
import openImg from './img/open_tree@2x.png'
import closeImg from './img/close_tree@2x.png'
import './index.css'

const baseURL = requestOptions.basename

class DataVisualization extends Component {

    state = {
        curClickTreeValue: {}, // 存储二级节点点击的节点对象
        curClickTreeRecord: {}, // 存储所有节点点击的节点对象
        treeData: [],
        treeOptions: {
            children: 'children',
            label: 'path_name',
        },
        isEdit: false,
        dialogVisible: false, // 目录弹窗
        reportDialogVisible: false, // 报表弹窗
        sortDialogVisible: false, // 排序弹窗
        pathTreeData: {
            "sholdCheckValidate": false,
            "id": 0,
            "data": {
                "pid": 0,
                "path_name": "",
                "permissions": "",
                "uri": "",
                "remark": "",
                "enabled": 0,
                "is_white_node": 0, // 是否白名单 1是 0否
            }
        },
        staffUserList: [],
        layoutSpan: 0, // 左边选择框 0打开状态 1关闭状态
        homePath: {}, // 默认主页
        homePathId: 0, // 默认主页id
        flag: 0, // 1设置用户主页 0取消设置用户主页
    }

    componentDidMount() {
        this.iniData()
        this.getPathTree()
        this.getHomePath()
        this.staffItems()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log("componentDidUpdate-----props", this.props)
        if (prevProps.match.params.id != this.props.match.params.id) {
            // console.log("componentDidUpdate-----props", this.props)
            this.iniData()
        }
    }

    iniData = () => {
        // console.log("-------------------props", this.props)
        let id = this.props.match.params.id
        if (!!id && id != "0") {
            getPathInfo({id: id}).then((res) => {
                if (res && res.data && res.data.code === 0) {
                    let pathInfo = res.data.data || []
                    // console.log("pathInfo----111-------", pathInfo)
                    if (pathInfo.pid != 0) {
                        this.setState({
                            curClickTreeValue: pathInfo
                        })
                    }
                }
            }).catch(error => {
                SdwMessage.error('接口异常' + error)
            })
        }
    }

    getPathTree = () => {
        let api = hasPriv('getPathTree') ? getPathTree : getPathTreeAuth
        api().then((res) => {
            if (res && res.data && res.data.code === 0) {
                let items = res.data.data || []
                // console.log("treeData----------------------", items)
                items.map(i => {
                    if (hasPriv('insertPath')) {
                        i.isAddIconShow = true
                    }
                    if (hasPriv('updatePath')) {
                        i.isEditIconShow = true
                    }
                    if (i.children != null) {
                        i.children.map(j => {
                            if (hasPriv('insertPath')) {
                                j.isAddIconShow = true
                            }
                            if (hasPriv('updatePath')) {
                                j.isEditIconShow = true
                            }
                            return j
                        })
                    }
                    return i
                })
                // console.log("items==========================", items)
                this.setState({
                    treeData: items
                })
            }
        }).catch(error => {
            SdwMessage.error('接口异常' + error)
        })
    }

    // 获取用户主页
    getHomePath = () => {
        getHomePath().then((res) => {
            if (res && res.data && res.data.code === 0) {
                let record = res.data.data || {}
                // console.log('homePath==========', record)
                this.setState({
                    homePath: record,
                    homePathId: record.id
                })
            }
        })
    }

    // 设置用户主页
    setHomePath = (value) => {
        let id = this.props.match.params.id
        let params = {
            id: id,
            flag: value
        }
        setHomePath(params).then((res) => {
            if (res && res.data && res.data.code === 0) {
                this.getHomePath()
                if (params.flag == 1) {
                    SdwMessage.success('已成功自定义默认报表')
                } else {
                    SdwMessage.success('已取消自定义默认报表')
                }
            } else {
                SdwMessage.error('自定义默认报表失败')
            }
        })
    }

    // 获取公司人员名单
    staffItems = (value = '') => {
        let params = {
            page: 1,
            limit: 20,
            search: {
                FullName: {
                    value: value,
                    operator: 'like'
                }
            }
        }
        staffItems(params).then((res) => {
            if (res && res.data && res.data.code === 0) {
                let record = res.data.data.items || []
                this.setState({
                    staffUserList: record
                })
            }
        })
    }

    operateFun = (type, item) => {
        console.log("---type=====item", type, item)
        let staffUserList = this.state.staffUserList
        console.log("---staffUserList", staffUserList)
        let obj = {}
        let permissions = []
        if (!!item.permissions && typeof (item.permissions) == 'string') {
            permissions = item.permissions.split(',').map(i => {
                let obj = {id: i, text: i}
                // staffUserList.forEach(item => {
                //     // {value:'rtx' , name:'名字'}
                //     if (item.LoginName == i) {
                //         obj['text'] = item.FullName
                //     }
                // })
                return obj
            })
            // console.log("permissions-=-=-=-=-=-=-=-=-=-=", permissions)
        }
        let pathTreeData = {
            "sholdCheckValidate": false,
            "id": 0,
            "data": {
                "pid": item.pid,
                "path_name": item.path_name,
                "permissions": permissions,
                "uri": item.uri,
                "remark": item.remark,
                "enabled": item.enabled,
                "is_white_node": item.is_white_node,
            }
        }
        let isEdit = false
        switch (type) {
            case "add":
                pathTreeData.data = {
                    "pid": 0,
                    "path_name": "",
                    "permissions": [],
                    "uri": "",
                    "remark": "",
                    "enabled": 0,
                    "is_white_node": 0,
                }
                if (item.tree_level == 0) {
                    obj = {dialogVisible: true}
                } else {
                    pathTreeData.data.pid = item.pid
                    obj = {reportDialogVisible: true}
                }
                break
            case "edit":
                pathTreeData.id = item.id
                if (item.tree_level == 0) {
                    obj = {dialogVisible: true}
                } else {
                    obj = {reportDialogVisible: true}
                }
                isEdit = true
                break
            default:
                break
        }
        this.setState({
            ...obj,
            isEdit: isEdit,
            pathTreeData: pathTreeData,
        })
    }

    savePath = () => {
        let {pathTreeData, isEdit} = this.state
        if (!pathTreeData.data.path_name) {
            let pathTreeDataClone = _.cloneDeep(pathTreeData)
            pathTreeDataClone.sholdCheckValidate = true
            this.setState({
                pathTreeData: pathTreeDataClone
            })
            return
        }
        // console.log("isEdit---------------------", isEdit)
        // console.log("pathTreeData---------------------", pathTreeData)
        let api = isEdit ? updatePath : insertPath
        // console.log("isEdit---------------------", isEdit)
        // console.log("pathTreeData---------------------", pathTreeData)
        let permissions = ""
        if (pathTreeData.data.permissions.length > 0) {
            permissions = pathTreeData.data.permissions.map(i => {
                return i.id
            }).join(",")
            // console.log("permissions-=-=-=-=-=-=-=-=-=-=", permissions)
        }
        api({
            ...!!pathTreeData.id ? {
                id: pathTreeData.id
            } : {},
            "data": {
                "pid": pathTreeData.data.pid,
                "path_name": pathTreeData.data.path_name,
                "uri": pathTreeData.data.uri,
                "permissions": permissions,
                "remark": pathTreeData.data.remark,
                "enabled": pathTreeData.data.enabled,
                "is_white_node": pathTreeData.data.is_white_node,
            }
        }).then(res => {
            if (res && res.data && res.data.code === 0) {
                let msg = isEdit ? '编辑成功' : '新增成功'
                SdwMessage.success(msg)
                this.getPathTree()
                this.addOrEdit(false)
                this.reportAddOrEdit(false)
            } else {
                let msg = isEdit ? '编辑失败' : '新增失败'
                SdwMessage.error(msg + "：" + res.data.msg)
            }
        }).catch(error => {
            SdwMessage.error('接口异常' + error)
        })
    }

    setPathTreeData = (type, value) => {
        let {pathTreeData, flag} = this.state
        let pathTreeDataClone = _.cloneDeep(pathTreeData)
        let flagClone = _.cloneDeep(flag)
        // console.log("-----=type", type)
        // console.log("-----=value", value)
        switch (type) {
            case 'pid':
                pathTreeDataClone.data.pid = value
                break
            case 'path_name':
                pathTreeDataClone.data.path_name = value
                break
            case 'uri':
                pathTreeDataClone.data.uri = value
                break
            case 'permissions':
                pathTreeDataClone.data.permissions = value
                break
            case 'remark':
                pathTreeDataClone.data.remark = value
                break
            case 'enabled':
                pathTreeDataClone.data.enabled = value
                break
            case 'is_white_node':
                pathTreeDataClone.data.is_white_node = value
                break
            case 'flag':
                flagClone = value
                this.setHomePath(value)
                break
        }
        this.setState({
            pathTreeData: pathTreeDataClone,
            flag: flag
        })
    }

    // 绑定点击的节点对象
    changeCurClickTreeValue = val => {

        let {curClickTreeRecord} = this.state
        // console.log("------=上一个元素", curClickTreeRecord)
        // console.log("------=目前点击的元素", val)

        if (!!curClickTreeRecord.id && parseInt(curClickTreeRecord.id) != 0) {
            let params = {
                "log_url": baseURL + "/statistics/page/record?key=" + curClickTreeRecord.id,
                "log_type": "leave"
            }
            //埋点leave
            record({
                params
            }).then()
        }
        //埋点点击
        let params = {
            "log_url": baseURL + "/statistics/page/record?key=" + val.id,
            "log_type": "enter"
        }
        record({
            params
        }).then()

        let obj = {
            curClickTreeRecord: val
        }
        if (val.tree_level == 1) {
            obj["curClickTreeValue"] = val
            // console.log("curClickTreeValue-val--------------", val)
        }
        this.setState({
            ...obj
        })
        this.props.history.push({
            pathname: `/admin/dataGasStation/dataVisualization/${val.id}`,
        })
    }

    // 新增或编辑目录
    addOrEdit = val => {
        this.setState({
            dialogVisible: val
        })
    }

    // 新增或编辑报表
    reportAddOrEdit = val => {
        this.setState({
            reportDialogVisible: val
        })
    }

    // 打开排序弹窗
    openSortWin = val => {
        this.setState({
            sortDialogVisible: val
        })
    }

    // 打开或关闭左侧选择框
    changeLayoutSpan = () => {
        if (this.state.layoutSpan === 0) {
            this.setState({
                layoutSpan: 1
            })
        } else if (this.state.layoutSpan === 1) {
            this.setState({
                layoutSpan: 0
            })
        }
    }

    render() {

        let {
            treeData,
            treeOptions,
            curClickTreeValue,
            curClickTreeRecord,
            isEdit,
            dialogVisible,
            reportDialogVisible,
            sortDialogVisible,
            pathTreeData,
            staffUserList,
            layoutSpan,
            homePath,
            homePathId,
        } = this.state

        return (
            <div>
                <SdwBreadCrumbs
                    history={this.props.history}
                    data={[
                        {
                            title: '数据加油站',
                            path: '/admin/dataGasStation/dataSearch'
                        }, {
                            title: '数据可视化',
                        }, {
                            title: curClickTreeValue.path_name
                        }
                    ]}
                />

                <div className='datavisualization-flex-left-right'>
                    <div className={layoutSpan === 0 ? 'datavisualization-left-nav' : 'datavisualization-left-hide'}>
                        <div className='datavisualization-left-switch'>
                            {curClickTreeRecord.pid === 0 || curClickTreeRecord.pid === undefined ?
                                <div className='title'>选择报表</div> :
                                <div>
                                    <SdwSwitch
                                        value={homePath.id === curClickTreeValue.id ? 1 : 0}
                                        changeValue={val => this.setPathTreeData('flag', val)}
                                    />
                                    <span className='switch_text'>当前报表设为默认报表</span>
                                </div>
                            }
                            {
                                hasPriv('getPathTree') &&
                                <img
                                    className='datavisualization-right-img-sort'
                                    src={sortImg}
                                    onClick={() => this.openSortWin(true)}
                                />
                            }
                        </div>
                        {
                            hasPriv('getPathTree') && treeData.length === 0 ?
                                <span className='datavisualization-button-plus'
                                      onClick={() => {
                                          this.addOrEdit(true)
                                      }}
                                >添加</span> :
                                <SdwTreeNew
                                    width={310}
                                    showFilterInput={true}
                                    filterInputWidth={310}
                                    inputPlaceholder='请输入'
                                    data={treeData}
                                    options={treeOptions}
                                    homePathId={homePathId}
                                    operateFun={this.operateFun}
                                    changeValue={this.changeCurClickTreeValue}
                                />
                        }
                    </div>

                    <div className='datavisualization-right-content'>
                        <div>
                            {
                                layoutSpan === 1 ?
                                    <>
                                        <img
                                            className='datavisualization-right-img-close'
                                            src={openImg}
                                            onClick={this.changeLayoutSpan}
                                        />
                                    </>
                                    :
                                    <>
                                        <img
                                            className='datavisualization-right-img-open'
                                            src={closeImg}
                                            onClick={this.changeLayoutSpan}
                                        />
                                    </>
                            }
                        </div>
                        <div className='datavisualization-right-content-center'>
                            <iframe
                                width='100%'
                                height='100%'
                                frameBorder='0'
                                src={!curClickTreeValue.uri ? baseURL + '/grafana' + homePath.uri : baseURL + '/grafana' + curClickTreeValue.uri}
                            ></iframe>
                        </div>
                    </div>

                    {/* 新增或编辑目录弹窗 */}
                    {
                        dialogVisible &&
                        <CatalogueWin
                            title={isEdit ? '编辑报表分类' : '新增报表分类'}
                            dialogVisible={dialogVisible}
                            item={pathTreeData}
                            handleSubmit={this.savePath}
                            setPathTreeData={this.setPathTreeData}
                            staffUserList={staffUserList}
                            staffItems={this.staffItems}
                            closeDialog={() => {
                                this.addOrEdit(false)
                            }}
                        />
                    }

                    {/* 新增或编辑报表弹窗 */}
                    {
                        reportDialogVisible &&
                        <ReportWin
                            title={isEdit ? '编辑报表配置' : '新增报表配置'}
                            reportDialogVisible={reportDialogVisible}
                            isEdit={isEdit}
                            item={pathTreeData}
                            treeData={treeData}
                            staffItems={this.staffItems}
                            staffUserList={staffUserList}
                            handleSubmit={this.savePath}
                            setPathTreeData={this.setPathTreeData}
                            closeDialog={() => {
                                this.reportAddOrEdit(false)
                            }}
                        />
                    }

                    {/* 排序弹窗 */}
                    {
                        sortDialogVisible &&
                        <SortWin
                            sortDialogVisible={sortDialogVisible}
                            getPathTree={this.getPathTree}
                            closeDialog={() => {
                                this.openSortWin(false)
                            }}
                        />
                    }

                </div>
            </div>
        )
    }
}

export default DataVisualization
