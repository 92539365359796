import { request } from '../utils/request'
import { ajaxPost } from "./AjaxApi";
import { requestOptions } from '../config/config'

const baseURL = requestOptions.basename

export function getStandardSelects (params) {
  return request({ url: '/metadata/tableApi/getStandardSelects', method: 'get', params: params })
}

// 获取主题列表
export const getTopicBusiness = (params = {}) => {
  return request({ url: '/dataset/common/getTopicBusiness', method: 'get', data: params })
}

// 排名维度
export const getTablecfgFieldsAndStandardList = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgFieldsAndStandardList', method: 'get', params: params })
}

// 来源表
export const getAllTablecfg = (params = {}) => {
  return request({ url: '/metadata/tableApi/getAllTablecfg', method: 'get', params: params })
}

// 获取所有的表
export const getTablecfgList = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgList', method: 'get', params: params })
}

// 获取该表的所有的字段
export const getTablecfgFields = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgFields', method: 'get', params: params })
}

// 获取分页数据
export const getPage = (params = {}) => {
  return request({ url: '/dataset/targetV2/items', method: 'get', params: params })
}

// 获取分页数据
export const getTargetPage = (params = {}) => {
  return request({ url: '/dataset/targetV2/getTargetPage', method: 'get', params: params })
}

// 获取所有的计算方式
export const getPublicCfg = (params = {}) => {
  return request({ url: '/dataset/common/getPublicCfg', method: 'get', data: params })
}

/**
 * 添加，修改数据
 * @param {object} params
 * @param {func} callback
 */
export function storeOrUpdateAtomTarget (params, callback) {
  return request({ url: '/dataset/process/addOrUpdateDimen', method: 'get', params: params })
}

// 获取所有原子指标选项
export const getAllAtomTarget = (params = {}) => {
  return request({ url: '/dataset/atomTargetV2/getAllAtomTarget', method: 'get', params: params })
}

/**
 * 获取所有修饰词记录
 * @param {object} params
 * @param {func} callback
 */
export const getAllModifier = (params = {}) => {
  return request({ url: '/dataset/modifier/getAllModifier', method: 'get', params: params })
}

/**
 * 根据原子指标ID获取关联表所有字段
 * @param {object} params
 * @param {func} callback
 */
export const getTableFieldsByAtomId = (params = {}) => {
  return request({ url: '/dataset/common/getTableFieldsByAtomId', method: 'get', params: params })
}

/**
 * 获取所有维度映射数据
 * @param {object} params
 * @param {func} callback
 */
export const getAllDimMapping = (params = {}) => {
  return request({ url: '/dataset/common/getAllDimMapping', method: 'get', params: params })
}

/**
 * 获取所有派生指标
 * @param {object} params
 * @param {func} callback
 */
export const getAllTarget = (params = {}) => {
  return request({ url: '/dataset/targetV2/getAllTarget', method: 'get', params: params })
}



/**
 * 添加，修改派生指标
 * @param {object} params
 * @param {func} callback
 */
export function storeOrUpdateTarget (params, callback) {
  return request({url: "/dataset/targetV2/storeOrUpdate", method: 'post', data: params})
}

export function checkTarget (params, callback) {
  return request({url: "/dataset/targetV2/checkTarget", method: 'post', data: params})
}




/**
 * 修改衍生指标
 * @param {object} params
 * @param {func} callback
 */
export function storeOrUpdateDerive (params, callback) {
  return request({url: "/dataset/targetV2/storeOrUpdateDerive", method: 'post', data: params})
}

// 审批申请接口
export function storeOrUpdateAuth (params, callback) {
  return request({url: "dataset/targetV2/storeOrUpdateAuth", method: 'post', data: params})
}

/**
 * 修改派生指标弹窗确认
 * @param {object} params
 */
export function getChildTarget (params) {
  return request({url: "/dataset/targetV2/getChildTarget", method: 'get', params: params})
}


/**
 * 获取派生指标详情
 * @param {object} params
 * @param {func} callback
 */
export const getTarget = (params = {}) => {
  return request({ url: '/dataset/targetV2/item', method: 'get', params: params })
}


/**
 * 获取指标计算结果
 * @param {object} params
 * @param {func} callback
 */
export const getTargetResults = (params = {}) => {
  return request({ url: '/dataset/targetV2/getTargetResults', method: 'get', params: params, timeout: 30000 })
}

/**
 * 跑指标计算结果
 * @param {object} params
 * @param {func} callback
 */
export function runTargetResult (params, callback) {
  return ajaxPost(baseURL + "/dataset/targetV2/runTargetResult", params, callback);
}

// 提交重跑--提交审批
export function runTargetResultAuth (params, callback) {
  return ajaxPost(baseURL + "/dataset/targetV2/runTargetResultAuth ", params, callback);
}

export const getTargetLogs = (params = {}) => {
  return request({ url: '/dataset/targetV2/getTargetLogs', method: 'get', params: params })
}

export const getDataKinship = (params = {}) => {
  return request({ url: '/dataset/targetV2/getDataKinship', method: 'get', params: params })
}
