import React, {PureComponent} from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import SdwMessage from '@common/Message'
import {getTableApiItems} from '@api/dataAccess'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../../index.css'
import {requestOptionsLOG} from '@config/config'

const baseURL = requestOptionsLOG.basename

class DistributeWin extends PureComponent {

    state = {
        params: {
            topic: '',
            deliveryType: 'STORE', // 分发类型
            parseId: 0, // 解析配置id
            storeTableId: 0, // 入库表id
            storeTableName: '', // 入库表英文名
            storeTableNameZh: '', // 入库表中文名
            extra: '',
            enable: false,
            rtx: ''
        },
        sholdCheckValidate: false,
        topicList: [],
        targetTableList: [],
    }

    componentDidMount() {
        this.initData()
        this.queryInputFileConfigList()
        this.getTargetTableList()
    }

    initData = () => {
        let {isEdit, currentItem} = this.props
        if (isEdit) {
            this.queryDeliveryConfig(currentItem.id)
        } else {
            this.setParamsData("parseId", currentItem.id)
        }
    }

    // 查询分发配置信息
    queryDeliveryConfig = (id) => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/parseConfig/queryDeliveryConfig/' + id,
            {
                method: "GET",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then(data => {
                if (data && data.code === 100) {
                    let items = data.data || {}
                    let params = {
                        id: items.id,
                        topic: items.topic,
                        deliveryType: items.deliveryType, // 分发类型
                        parseId: items.parseId, // 解析配置id
                        storeTableId: items.storeTableId, // 入库表id
                        storeTableName: items.storeTableName, // 入库表英文名
                        storeTableNameZh: items.storeTableNameZh, // 入库表中文名
                        extra: items.extra,
                        enable: items.enable,
                        rtx: rtx,
                    }
                    this.setState({
                        params: params
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 获取落地数据表
    getTargetTableList = () => {
        // 目标表参数
        let paramData = {
            limit: 10000,
            search: JSON.stringify({
                data_set_type: {
                    value: 'es',
                    operator: '='
                },
                warehouse_level: {
                    value: 'ODS',
                    operator: '='
                },
                enable: {
                    value: '1',
                    operator: '='
                }
            })
        }

        // 目标表
        getTableApiItems(paramData).then(res => {
            const code = _.get(res, 'data.code')
            if (code === 0) {
                const items = _.get(res, 'data.data.items')
                let curArr = _.isArray(items) ? items : []
                curArr = curArr.map(i => {
                    let curName = !!i.database_name ? `${i.database_name}.${i.table_name}` : `${i.table_name}`
                    return {
                        id: parseInt(i.id),
                        text: `${curName}（${i.data_set_chinese}）`,
                        store_table_name_ch: i.data_set_chinese,
                        store_table_name_en: `${i.database_name}.${i.table_name}`,
                        topic: i.topic,
                        business: i.business,
                        data_set_type: i.data_set_type
                    }
                })
                this.setState({
                    targetTableList: curArr
                })
            } else {
                SdwMessage.error('获取目标表数据失败')
            }
        }).catch(error => {
            SdwMessage.error('获取目标表接口异常：' + error)
        })
    }

    // 查询消费topic
    queryInputFileConfigList = () => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/parseConfig/queryConsumeTopic',
            {
                method: "GET",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then((data) => {
                let record = data.data
                this.setState({
                    topicList: record.map(i => {
                        return {id: i.topic, text: i.topic}
                    })
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 更新或写入分发配置
    submitClick = () => {
        let {params} = this.state
        let rtx = this.props.userInfo.name
        if (!params.topic || (params.deliveryType == 'STORE' && !params.storeTableId)) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        fetch(baseURL + '/sdw/filebeat/parseConfig/upsertDeliveryConfig',
            {
                method: "POST",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                if (data && data.code === 100 || data.code === 101) {
                    this.props.changeVisible(false)
                    this.props.distributeWinSubmit(data)
                } else {
                    SdwMessage.error(data.message)
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    setParamsData = (type, value) => {
        let {params} = this.state
        let obj = {}
        obj[type] = value
        let paramsClone = Object.assign({}, params, obj)
        this.setState({
            params: paramsClone
        })
    }

    render() {

        let {
            params,
            targetTableList,
            topicList,
            sholdCheckValidate,
        } = this.state

        let {
            isEdit,
            distributeVisible,
            changeVisible,
            currentItem
        } = this.props

        return (
            <SdwDrawer
                title={isEdit ? `编辑分发消费配置 (${currentItem.parseId})` : `新增分发消费配置 (${currentItem.id})`}
                visible={distributeVisible}
                onIconClick={() => changeVisible(false)}
                onCancelClick={() => changeVisible(false)}
                onSureClick={this.submitClick}
            >
                <SdwDrawer.Body>
                    <div className='logAccess-win_warp'>
                        <div>
                            <span className='label required'>日志topic</span>
                            <SdwSelect
                                data={topicList}
                                // disabled={isEdit}
                                readOnly={false}
                                placeholder="请选择日志topic"
                                value={params.topic}
                                onChange={val => this.setParamsData('topic', val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '日志topic不能为空'}
                            />
                        </div>
                        <div>
                            <span className='label required'>消费方式</span>
                            <SdwSelect
                                placeholder='请选择消费方式'
                                value={params.deliveryType}
                                data={[
                                    {
                                        id: 'STORE',
                                        text: '入库'
                                    }, {
                                        id: 'INDIVIDUAL',
                                        text: '自定义'
                                    }
                                ]}
                                onChange={val => this.setParamsData('deliveryType', val)}
                            />
                        </div>
                        {
                            params.deliveryType == 'STORE' &&
                            <div>
                                <span className='label required'>落地数据表</span>
                                <SdwSelect
                                    data={targetTableList}
                                    // disabled={isEdit}
                                    readOnly={false}
                                    placeholder="请选择落地数据表"
                                    value={params.storeTableId}
                                    onChange={(val, item) => {
                                        this.setState({
                                            params: _.assign({}, params, {
                                                storeTableId: val,
                                                storeTableNameZh: item.store_table_name_ch,
                                                storeTableName: item.store_table_name_en,
                                            })
                                        })
                                    }}
                                    sholdCheckValidate={sholdCheckValidate}
                                    validateFun={value => !!value ? true : '落地数据表不能为空'}
                                />
                            </div>
                        }
                        <div>
                            <span className='label'>备注</span>
                            <SdwInput
                                type='textarea'
                                placeholder='请输入备注'
                                value={params.extra}
                                onChange={val => {
                                    this.setParamsData('extra', val)
                                }}
                            />
                        </div>
                        <div>
                            <span className='label required'>是否有效</span>
                            <SdwSwitch
                                value={params.enable}
                                changeValue={val => {
                                    this.setParamsData('enable', val)
                                }}
                            />
                        </div>
                    </div>
                </SdwDrawer.Body>
            </SdwDrawer>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(DistributeWin))
