import React from 'react'


function UILayout(props) {
  let top = null, sidebar = null, content = null

  props.children.forEach(child => {
    if (child.type === UILayout.Top) {
      top = child
    } else if (child.type === UILayout.Sidebar) {
      sidebar = child
    } else if (child.type === UILayout.Content) {
      content = child
    }
  })

  return (
    <div className="page-wrap home-page">
      <div className="page-header">{top}</div>
      <div className="page-main">
        {/* <div className="layout-sidebar">{sidebar}</div> */}
        <div className="page-main__wrap">
          {content}
        </div>
        <div className="page-footer">
          <div className="page-footer__wrap">
            <div className="page-footer-copyRight">{`Copyright © 1998-${new Date().getFullYear()} Tencent Inc. All Rights Reserved 腾讯公司 客户服务部`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

UILayout.Top = ({ children }) => children
// UILayout.Sidebar = ({ children }) => children
UILayout.Content = ({ children }) => children

export default UILayout
 