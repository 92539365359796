import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import _ from 'lodash'

import './index.css'

const EMPTY_SELECT_OBJ = {
  ids: [],
  texts: [],
  item: {}
}

class SdwCascader extends Component {
  cascaderInputRef = React.createRef()
  cascaderInputWrapRef = React.createRef()

  static defaultProps = {
    width: '80%',
    listWidth: 0,
    listMaxHeight: 300,
    height: 40,
    rows: 3,
    cols: 28,
    placeholder: '全部',
    clearable: true,
    value: {
      id: '',
      text: ''
    },
    disabled: false,
    readOnly: true,
    data: [],
    canChooseParent: false  // 默认只能选择叶子节点
  }

  state = {
    isValidatePass: true,
    showcascaderList: false,
    showClearIcon: false,
    validateText: '',
    isFilter: false,
    curSelectObject: EMPTY_SELECT_OBJ, // 当前选中的级联对象
    curInputVal: '', // 模糊匹配输入值
    levelDataList: [], // 当前层级 [[firstList], [secondList], ...]
    beforeLevel: 0, // 记录上次的层级,
    fliterDataList: [], // 已经将所有的叶子节点组合到一个一维数组中
    olderLevelDataList: [], // 模糊搜索前一个 curLevelDataList 数组
    sholdCheckValidate: false
  }

  componentDidMount() {
    document.addEventListener('click', this.handleGlobalClose)
    this.setState(({
      levelDataList: [this.props.data]
    }))

    // 设置可以模糊搜索后
    if (!this.props.readOnly) {
      let fliterDataList = this.formatFlatData({
        id: [],
        text: [],
        item: {}
      }, this.props.data).flat(Infinity)

      this.setState({ fliterDataList })
    }
  }

  formatFlatData = (parentItem, list) => {
    return list.map(item => {
      let curItem = {
        id: [...parentItem.id, item.id],
        text: [...parentItem.text, item.text],
        item: item
      }

      if (item.children && item.children.length) {
        return this.formatFlatData(curItem, item.children)
      }

      return curItem
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClose)
  }

  componentDidUpdate (prevProps, prevState) {
    let { value, sholdCheckValidate } = this.props
    if (!this.state.isFilter && Array.isArray(value.texts) && value.texts.length && ((value.texts && !this.state.curInputVal) || prevProps.value.texts !== value.texts)) {
      this.setState({
        curInputVal: value.texts.join(' / ') || ''
      })
    }
    
    if (prevProps.sholdCheckValidate !== sholdCheckValidate) {
      this.validate(value)
    }

    if (prevProps.data.length !== this.props.data.length) {
      this.setState(({
        levelDataList: [this.props.data]
      }))
    }
  }

  handleGlobalClose = e => {
    const node = ReactDOM.findDOMNode(this.cascaderInputWrapRef.current)

    if (!node.contains(e.target)) {
      let { value } = this.props
      this.setState({
        showcascaderList: false,
        curInputVal: value.texts && value.texts.join(' / ') || ''
      })

      // this.validate(value)
    }
  }

  handleClearInput = e => {
    e.preventDefault()
    this.setState(state => ({
      curInputVal: '',
      curSelectObject: EMPTY_SELECT_OBJ,
      levelDataList: state.levelDataList.slice(0, 1),
      beforeLevel: 0,
    }))

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({})
    }
    
    this.validate({})
    this.cascaderInputRef.current.focus()
  }

  handleClick = () => {
    this.setState(state => ({
      showcascaderList: !state.showcascaderList,
      isFilter: false,
    }))
  }

  findExtraList = (list, ids) => {
    if (!(ids && ids.length)) return []
    let id = ids.shift()
    let childrenList = list.filter(i => i.id === id)[0].children || []
    return [childrenList, ...this.findExtraList(childrenList, ids)]
  }

  handleItemClick = (e, item, level, isFliter = false) => {
    let { levelDataList, beforeLevel, curSelectObject, olderLevelDataList } = this.state
    let { data } = this.props

    let curLevelDataList = _.cloneDeep(levelDataList)
    let selectObj = EMPTY_SELECT_OBJ

    if (isFliter) {
      let ids = Object.assign([], item.id)
      ids.pop()
      let extraList = ids.length && this.findExtraList(data, ids) || []
      curLevelDataList = [data, ...extraList]

      // 修改当前的展开level
      level = curLevelDataList.length
      selectObj = {
        ids: item.id,
        texts: item.text,
        clickItem: item,
      }
    } else {
      let itemChildren = item.children ? item.children : []
      if (level > beforeLevel) {
        curLevelDataList.push(itemChildren)
      } else {
        curLevelDataList = _.cloneDeep(curLevelDataList.splice(0, level))

        if (_.isArray(itemChildren) && itemChildren.length) curLevelDataList.push(itemChildren)
      }
  
      selectObj = {
        ids: [...curSelectObject.ids.slice(0, level - 1), item.id],
        texts: [...curSelectObject.texts.slice(0, level - 1), item.text],
        clickItem: item,
      }
    }

    this.setState({
      olderLevelDataList: curLevelDataList,
      levelDataList: curLevelDataList,
      beforeLevel: level,
      showClearIcon: false,
      curSelectObject: selectObj,
      // curInputVal: selectObj.texts.join(' / '),
      showcascaderList: !(item.children && item.children.length)
    })

    // 如果只能选择 叶子节点，这里就只能叶子节点可以赋值
    let canNotChooseParent = !this.props.canChooseParent && item.children && _.isArray(item.children) && !!item.children.length
    if (!canNotChooseParent && typeof this.props.onChange === 'function') {
      this.setState({
        curInputVal: selectObj.texts.join(' / ')
      })

      this.props.onChange(selectObj)
    }

    this.validate(selectObj)
  }

  validate = curRecord => {

    // 如果没有传入valiateFun进行校验，直接跳过
    if (typeof this.props.validateFun !== 'function') {
      return
    }

    let res = this.props.validateFun(curRecord)

    // validateFun只有返回true，才会校验通过
    if (res === true) {
      this.setState({
        isValidatePass: res,
        validateText: ''
      })
    } else {
      this.setState({
        isValidatePass: false,
        validateText: res
      })
    }
  }

  render() {
    let {
      placeholder,
      disabled,
      width,
      listWidth,
      height,
      listMaxHeight,
      clearable,
      readOnly,
    } = this.props

    let { 
      isValidatePass,
      validateText,
      showcascaderList,
      showClearIcon,
      curInputVal,
      isFilter,
      levelDataList,
      curSelectObject,
      fliterDataList,
    } = this.state

    // 模糊搜索进行过滤
    let filterData = levelDataList
    if (isFilter) {
      filterData = [fliterDataList.filter(
        item => Array.isArray(item.text) && 
                item.text.some(text => text.indexOf(curInputVal) !== -1))]
    }

    let listWrapWidth = (isFilter && '100%') || 
                        listWidth || 
                        (typeof width === 'number' && (width / 3)) || 
                        200

    let inputClassName = isValidatePass ? 'sdw-cascader__wrap' : 'sdw-cascader__wrap sdw-error-input'
    if (showcascaderList) {
      inputClassName += ' sdw-select__show-selected'
    }

    let iconClassName = 'sdw-cascader__icon'
    if (clearable && showClearIcon && curInputVal) {
      iconClassName += ' sdw-cascader-clearable'
    } else if (showcascaderList) {
      iconClassName += ' sdw-cascader__drop-up sdw-cascader__drop-down'
    } else {
      iconClassName += ' sdw-cascader__drop-down'
    }
    
    return (
      <div
        ref={this.cascaderInputWrapRef}
        className="sdw-cascader__outer-div-wrap"
        style={{ width }}
        onClick={this.handleClick}
        onMouseEnter={() => this.setState({ showClearIcon: true })}
        onMouseLeave={() => this.setState({ showClearIcon: false })} >
        <input
          type='text'
          ref={this.cascaderInputRef}
          readOnly={readOnly}
          value={curInputVal}
          disabled={disabled}
          placeholder={placeholder}
          style={{ 
            width: '100%',
            height
          }}
          onInput={e => this.setState({
            isFilter: true,
            showcascaderList: true,
            curInputVal: e.target.value,
          })}
          className={inputClassName}
          />

        {
          !disabled && <i className={iconClassName} onClick={showClearIcon ? this.handleClearInput : null}></i>
        }
        {
          !isValidatePass && <div className='sdw-error-input__tip'>{validateText}</div>
        }
        {
          showcascaderList && 
          ( 
            <div className='sdw-cascader-list-wrap'>
              {
                filterData.map((levelData, index) => (
                  levelData && levelData.length ?
                  <CascaderItem
                    key={index}
                    level={index}
                    isFilter={isFilter}
                    itemList={levelData}
                    listMaxHeight={listMaxHeight}
                    curSelectObject={curSelectObject}
                    listWrapWidth={listWrapWidth}
                    handleItemClick={this.handleItemClick}
                    onMouseEnter={() => this.setState({ showClearIcon: false })}
                  />
                  :
                  (
                    index === 0 ? 
                    <div 
                      key={index}
                      className='sdw-cascader-list__empty'
                      style={{ background: '#ddd' }}>
                      暂无匹配数据
                    </div> : null
                  ) 
                ))
              }
            </div>            
          )
        }
      </div>
    )
  }
}

SdwCascader.propTypes = {
  data: PropTypes.array,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listMaxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  validateFun: PropTypes.func,
  sholdCheckValidate: PropTypes.bool, // true: 手动触发必填项的检查
  canChooseParent: PropTypes.bool, // true: 可以选择非叶子节点
}

export default SdwCascader

function CascaderItem (props) {
  return (
    <div 
      className='sdw-cascader-list__area-wrap'
      style={{ 
        width: props.listWrapWidth,
        maxHeight: props.listMaxHeight
      }}>
      { 
        props.itemList.map((item, index) => {
          let isSelected = props.curSelectObject.ids && 
          props.curSelectObject.ids[props.level] == item.id
          return (
            <div
              key={index}
              className={
                'sdw-cascader-list__area ' + (isSelected ? ' is_selected' : '')
              }
              onClick={e => props.handleItemClick(e, item, (props.level + 1), props.isFilter)}
              title={props.isFilter ? item.text.join(' / ') : item.text}
            >
              {
                props.isFilter ? item.text.join(' / ') : item.text
              }
              {
                item.children && item.children.length &&
                <i className='sdw-cascader__list-item-right-icon' />
              }
            </div>
          )
        })
      }
    </div>
  )
}
