import React from 'react'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import SdwMessage from '@common/Message'
import SdwTable from '@common/Table'
import SdwPagination from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import MachineWin from './StepWin/MachineWin'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../index.css'
import {requestOptionsLOG} from '@config/config'
const baseURL = requestOptionsLOG.basename

class IpAccessConfiguration extends React.PureComponent {

    state = {
        machineVisible: false, // 新增或编辑机器配置弹窗
        search:{
            page: 1,
            size: 10,
            ip: '',
            createRtx: '',
            enable: '',
            status: '',
        },
        tableData: {
            configList: [],
            total: 0,
        },
        current_page: 1,
        currentItem: {},
        isEdit: false,
    }

    componentDidMount() {
        this.queryFilebeatConfig()
    }

    // 查询IP配置列表和IP配置详情
    queryFilebeatConfig = () => {
        let {search} = this.state
        let params = {}
        let rtx = this.props.userInfo.name
        for(let key in search) {
            if (search[key]) {
                params[key] = search[key]
            }
        }
        fetch(baseURL + '/sdw/filebeat/filebeat/queryFilebeatConfig',
            {
                method: "POST",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
                res.json().then(data => {
                    if (data &&data.code === 100) {
                        let items = data.data || {}
                        this.setState({
                            tableData: items
                        })
                    }
                })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }


    getColumns = () => {
        let self = this
        return [
            {
                title: '日志服务器IP',
                width: 90,
                dataIndex: "ip"
            },
            {
                title: '是否有效',
                width: 106,
                render: data => (
                    <span className={
                        Boolean(data.enable) ? 'enable-comp_is-effect' : 'enable-comp_no-effect'
                    }>
                        {Boolean(data.enable) ? '有效' : '无效'}
                    </span>
                )
            },
            {
                title: 'filebeat安装状态',
                width: 106,
                render: data => (
                    <span className={
                        Boolean(data.installStatus == 202) ? 'installStatus-comp_is-effect' : 'installStatus-comp_no-effect'
                    }>
                        {Boolean(data.installStatus == 202) ? '已安装' : '未安装'}
                    </span>
                )
            },
            {
                title: 'filebeat运行状态',
                width: 92,
                render: data => (
                    <span className={
                        Boolean(data.status == 200) ? 'status-comp_is-effect' : 'status-comp_no-effect'
                    }>
                        {Boolean(data.status == 200) ? '正常' : '异常'}
                    </span>
                )
            },
            {
                title: 'filebeat版本号',
                width: 106,
                dataIndex: "version"
            },
            {
                title: '服务器负责人',
                width: 96,
                dataIndex: "principal"
            },
            {
                title: '修改人',
                width: 96,
                dataIndex: "updateRtx"
            },
            {
                title: '修改时间',
                width: 130,
                dataIndex: "updateTime"
            },
            {
                title: '操作',
                width: 38,
                render: data => (
                    <>
                        <SdwButton
                            text='编辑'
                            onClick={() => self.machineAddOrEdit(true, data)}
                        />
                    </>
                )
            }
        ]
    }

    // 新增或编辑机器配置弹窗
    machineAddOrEdit = (isEdit, record = {}) => {
        this.setState({
            isEdit: isEdit,
            machineVisible: true,
            currentItem: record,
        })
    }

    setSearch = (type, val) => {
        let {search} = this.state
        let obj = {}
        obj[type] = val
        if (type != 'page') {
            obj['page'] = 1
        }
        let searchClone = Object.assign({}, search, obj)
        this.setState({
            search: searchClone
        }, ()=>{
            this.queryFilebeatConfig()
        })
    }

    render() {
        let {
            machineVisible,
            tableData,
            search,
            isEdit,
            currentItem,
        } = this.state

        console.log("=====tableData", tableData)
        console.log("-------search", search)

        return (
            <>
                <BreadCrumbsAndTablde>
                    <BreadCrumbsAndTablde.LeftCrumbs>
                        <SdwInput
                            label='日志服务器IP'
                            width={260}
                            value={search.ip}
                            onChange={val => this.setSearch("ip", val)}
                        />
                        <SdwSelect
                            label='是否有效'
                            width={200}
                            data={[{
                                id: 'true',
                                text: '有效'
                            },{
                                id: 'false',
                                text: '无效'
                            }]}
                            value={search.enable}
                            onChange={val => this.setSearch("enable", val)}
                        />
                        <SdwSelect
                            label='filebeat运行状态'
                            width={200}
                            data={[{
                                id: 200,
                                text: '正常'
                            },{
                                id: 201,
                                text: '异常'
                            },]}
                            value={search.status}
                            onChange={val => this.setSearch("status", val)}
                        />
                        <SdwInput
                            label='创建人'
                            width={260}
                            value={search.createRtx}
                            onChange={val => this.setSearch("createRtx", val)}
                        />
                    </BreadCrumbsAndTablde.LeftCrumbs>
                    <BreadCrumbsAndTablde.RightCrumbs>
                        <SdwButton
                            text='新增'
                            type='submit'
                            iconClass='iconfont icon-add'
                            onClick={() => this.machineAddOrEdit(false)}
                        />
                    </BreadCrumbsAndTablde.RightCrumbs>
                    <BreadCrumbsAndTablde.CenterWrap>
                        <SdwTable
                            columns={this.getColumns()}
                            dataSource={tableData.configList || []}
                        />
                        <SdwPagination
                            total={tableData.total || 0}
                            currentPage={tableData.current_page || 1}
                            pageCount={tableData.per_page}
                            getClickPage={val => this.setSearch("page", val)}
                            pageCountChange={val => this.setSearch("size", val)}
                        />
                    </BreadCrumbsAndTablde.CenterWrap>
                </BreadCrumbsAndTablde>

                {/* 新增或编辑机器配置弹窗 */}
                {machineVisible &&
                    <MachineWin
                      isEdit={isEdit}
                      currentItem={currentItem}
                      machineVisible={machineVisible}
                      changeVisible = {(bool)=>{
                          this.setState({ machineVisible: bool })
                      }}
                      iniList = {this.queryFilebeatConfig}
                    />
                }
            </>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(IpAccessConfiguration))
