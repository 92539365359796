import Select from '@common/Select'
import Input from '@common/Input'
import React from 'react'

export default props => {
    return (
        <>
      <span className="editorTwo-input-map left body-left">
        <Select
            value={props.data.queue}
            data={props.dataList}
            onChange={val => props.changeConfigGroup(props.index + 1, 'queue', val, props.group_type)}
            width={150}
        />
      </span>
            <span className="editorTwo-input-map right">
        <Input
            placeholder={props.group_type == "twoGroup" ? "请输入分组名称（八个字内）" : "请输入分组名称（四个字内）"}
            width={450}
            value={props.data.name}
            onChange={val => props.changeConfigGroup(props.index + 1, 'name', val, props.group_type)}
            sholdCheckValidate={props.sholdCheckValidate}
            validateFun={val => {
                if (!val) {
                    return "分组名称不能为空"
                }
                if (props.group_type == "twoGroup" && val.length > 8) {
                    return '输入最大长度为8'
                } else if (props.group_type == "threeGroup" && val.length > 4) {
                    return '输入最大长度为4'
                }
                return true
            }}
        />
      </span>

            {props.dataList.length == (props.index + 1) && (
                <i onClick={() => props.addConfigRules(props.group_type)} className="el-icon-plus"></i>)}
            <i onClick={() => props.onDelConfigRule(props.index, props.group_type)} className="el-icon-minus"></i>
        </>
    )
}
