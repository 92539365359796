import { request } from '../utils/request'

// 获取主题域管理数据
export function getManageTopics (params) {
  return request({ url: '/statistics/dataMarket/getManageTopics', method: 'get', params: params })
}

// 编辑主题域获取 实时 数据
export function getTopicInfoById (params) {
  return request({ url: '/statistics/dataMarket/getTopicInfoById', method: 'get', params: params })
}

// 新增/编辑主题域
export function storeTopic (params) {
  return request({ url: '/statistics/dataMarket/storeTopic', method: 'post', data: params })
}
