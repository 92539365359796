import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwInput from '@common/Input'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import { Button } from 'element-react'
import { getManageTopics } from '@services/management'
import { getPublicCfg } from '@services/atom'
import { searchAppInfo, searchAppInfoManage } from '@services/application'
import SdwMessage from '@common/Message'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import AddApplicationWin from './AddApplicationWin'
import ReRunWin from './ReRunWin'
import SdwSelect from '@common/Select/index'
import SdwLoading from '@common/Loadding'

class Application extends React.Component {

  state = {
    tableName: '',
    creactPerson: '',
    curPage: this.props.pageInfo || 1,
    curLimit: 10,
    dialogVisible: false,
    reRunVisible: false,
    topicData: [],
    runCycleData: [],
    allAppInfos: {},
    curRecord: {},
    tableShowData: false,
    publishType: ''
  }

  componentDidMount () {
    this.initData()
  }

  initData = async () => {
    await getManageTopics().then(res => {
      let jsonData = res && res.data || {}
      if (jsonData && jsonData.code === 0 && Array.isArray(jsonData.data)) {
        this.setState({
          topicData: jsonData.data
        })
      }
    }, () => SdwMessage.error("接口异常"))

    this.onSearchAppInfo()

    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }

        this.setState({ runCycleData: cfgData.runCycle })
      }
    }, () => SdwMessage.error("接口异常"))
  }

  onSearchAppInfo = () => {
    let { curLimit, curPage } = this.state
    this.setState({tableShowData: false})
    if (hasPriv('searchAppInfo')) {
      searchAppInfo({
        ...!!curLimit ? {
          limit: curLimit,
        } : {},
        ...!!curPage ? {
          page: curPage
        } : {}
      }).then(res => {
        this.setState({tableShowData: true})
        let jsonData = res && res.data || {}
        if (jsonData && jsonData.code === 0 && jsonData.data) {
          this.setState({
            allAppInfos: jsonData.data
          })
        }
      }, () => SdwMessage.error("接口异常"))
      return
    }
    if (hasPriv('searchAppInfoManage')) {
      searchAppInfoManage({
        ...!!curLimit ? {
          limit: curLimit,
        } : {},
        ...!!curPage ? {
          page: curPage
        } : {}
      }).then(res => {
        this.setState({tableShowData: true})
        let jsonData = res && res.data || {}
        if (jsonData && jsonData.code === 0 && jsonData.data) {
          this.setState({
            allAppInfos: jsonData.data
          })
        }
      }, () => SdwMessage.error("接口异常"))
      return
    }
  }

  componentDidUpdate (prevProps, prevStates, snapshot) {
    if (
      !_.isEqual(prevStates.curPage, this.state.curPage) ||
      !_.isEqual(prevStates.curLimit, this.state.curLimit)
    ) {
      this.initTableData()
    }
  }

  getcolumns = () => {
    let self = this
    const columns = [
      {
        title: "应用表名",
        width: 460,
        // dataIndex: "table_name",
        render: (data, record) => {
          return (
            <span>{`${data.table_name}（${data.table_name_cn}）`}</span>
          )
        }
      },
      {
        title: "主题域/业务",
        width: 160,
        // dataIndex: "topic",
        render: (data, record) => {
          let topicData = self.state.topicData.filter(i => i.topic_id == data.topic)
          let topicName = topicData[0] && topicData[0].topic_name || '-'
          return (
            <span>{topicName}</span>
          )
        }
      },
      {
        title: "包含指标数",
        width: 80,
        dataIndex: "target_count",
      },
      {
        title: "发布状态",
        width: 74,
        // dataIndex: "publish_state",
        render: (data, record) => (
          <span>{!!data.publish_state ? '已发布' : '未发布'}</span>
        )
      },
      {
        title: "创建人",
        width: 96,
        dataIndex: "create_user",
      },
      {
        title: "负责人",
        width: 96,
        dataIndex: "resp_user",
      },
      {
        title: "创建时间",
        width: 158,
        dataIndex: "create_time",
      },
      {
        title: "修改人",
        width: 96,
        dataIndex: "modify_user",
      },
      {
        title: "修改时间",
        width: 158,
        dataIndex: "modify_time",
      },
      ...(hasPriv('storeNewAppTable') || hasPriv('runTargetResult')) ?
      [{
        title: "操作",
        width: 80,
        render: (data, record) => {
          return (
            <>
              {
                hasPriv('updateNewAppTable') &&
                <Button
                  type="text"
                  size="small"
                  onClick={() => self.handleEdit(data)}
                  style={{ "fontSize": "14px" }}
                >编辑</Button>
              }
              {
                hasPriv('runTargetResult') &&
                <Button
                  type="text"
                  size="small"
                  onClick={() => self.handleReRun(data)}
                  style={{ "fontSize": "14px" }}
                >重跑</Button>
              }
            </>
          )
        },
      }] : []
    ]

    return columns
  }

  initTableData = () => {
    let {
      tableName,
      creactPerson,
      curPage,
      curLimit,
      publishType
    } = this.state

    // DO search
    let params = {
      limit: curLimit,
      page: curPage,
      ...(!!tableName || !!creactPerson || publishType !== '') ? {
        search: JSON.stringify({
          ...!!tableName ? {
            table_name: {
              value: `%${tableName}%`,
              operator: 'like'
            }
          } : {},
          ...!!creactPerson ? {
            create_user: {
              value: `%${creactPerson}%`,
              operator: 'like'
            }
          } : {}
        }),
        orSearch: JSON.stringify({
          ...!!tableName ? {
            table_name_cn: {
              value: `%${tableName}%`,
              operator: 'like'
            }
          } : {},
          ...!!creactPerson ? {
            create_user: {
              value: `%${creactPerson}%`,
              operator: 'like'
            }
          } : {},
          ...(publishType !== '') ? {
            publish_state: {
              value: publishType,
              operator: '='
            }
          } : {}
        })
      } : {}
    }

    this.setState({tableShowData: false})
    if (hasPriv('searchAppInfo')) {
      searchAppInfo(params).then(res => {
        this.setState({tableShowData: true})

        let jsonData = res && res.data || {}
        if (jsonData && jsonData.code === 0 && jsonData.data) {
          this.setState({
            allAppInfos: jsonData.data
          })
        }
      }, () => SdwMessage.error("接口异常"))
      return
    }
    if (hasPriv('searchAppInfoManage')) {
      searchAppInfoManage(params).then(res => {
        this.setState({tableShowData: true})

        let jsonData = res && res.data || {}
        if (jsonData && jsonData.code === 0 && jsonData.data) {
          this.setState({
            allAppInfos: jsonData.data
          })
        }
      }, () => SdwMessage.error("接口异常"))
      return
    }
  }

  changeDialogVisible = flag => {
    this.setState({
      dialogVisible: flag
    })
  }

  changeReRunVisible = flag => {
    this.setState({
      reRunVisible: flag
    })
  }

  handleEdit = data => {
    this.props.history.push({
      pathname: '/admin/factory/applicationDetail',
      state: data
    })
  }

  handleReRun = data => {
    this.setState({
      curRecord: data
    })
    this.changeReRunVisible(true)
  }


  render() {
    let {
      tableName,
      creactPerson,
      dialogVisible,
      reRunVisible,
      topicData,
      runCycleData,
      allAppInfos,
      curRecord,
      tableShowData,
      publishType
    } = this.state

    let curTopicData = topicData.map(i => {
      return {
        id: i.topic_id,
        text: i.topic_name
      }
    })

    let curRunCycleData = runCycleData.map(i => {
      return {
        id: i.id,
        text: i.name
      }
    })

    return (
      <div className="Application">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/atom'
            }, {
              title: '应用表管理'
            }
          ]}
        />

        <div className="page-mainbody">
          <div className="page-search">
            <div className="input-wrap" style={{ lineHeight: '44px' }}>
              <div className="ele-search-input">
                <SdwInput
                  width={180}
                  label="应用表名"
                  placeholder="支持中文、英文"
                  value={tableName}
                  onEnterKeyDown={this.initTableData}
                  onBlur={this.initTableData}
                  onChange={val => this.setState({
                    curPage: 1,
                    tableName: val
                  })}
                />
              </div>
              <div className="ele-search-input">
                <SdwInput
                  width={180}
                  label="创建人"
                  placeholder="请输入rtx名字"
                  value={creactPerson}
                  onEnterKeyDown={this.initTableData}
                  onBlur={this.initTableData}
                  onChange={val => this.setState({
                    curPage: 1,
                    creactPerson: val
                  })}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  width={180}
                  label="发布状态"
                  value={publishType}
                  data={[
                    {
                      id: 1,
                      text: '已发布'
                    }, {
                      id: 0,
                      text: '未发布'
                    }
                  ]}
                  onChange={val => this.setState({
                    curPage: 1,
                    publishType: val
                  }, this.initTableData)}
                />
              </div>

              <div className="btn-group">
                {
                  hasPriv('storeNewAppTable') &&
                  <Button
                    type="primary"
                    icon='plus'
                    onClick={ () => this.changeDialogVisible(true) }
                  >新增</Button>
                }
              </div>
            </div>
          </div>

          <div className="page-table">
            <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
              <SdwTable
                tdWhiteSpace={true}
                columns={this.getcolumns()}
                dataSource={allAppInfos && allAppInfos.items || []}
              />
            </SdwLoading>

            <SdwPagenation
              total={allAppInfos.total}
              currentPage={allAppInfos.current_page}
              pageCountList={[10, 15, 20, 40]}
              pageCount={allAppInfos.per_page}
              getClickPage={page => {
                this.setState({
                  curPage: page
                })
              }}
              pageCountChange={limit => {
                this.setState({
                  curLimit: limit
                })
              }}
            />
          </div>
        </div>

        {
          dialogVisible&&<AddApplicationWin
            dialogVisible={dialogVisible}
            curTopicData={curTopicData}
            runCycleData={curRunCycleData}
            searchAppInfo={this.onSearchAppInfo}
            changeDialogVisible={this.changeDialogVisible}
          />
        }

        <ReRunWin
          record={curRecord}
          reRunVisible={reRunVisible}
          changeReRunVisible={this.changeReRunVisible}
        />
      </div>
    )
  }
}

const mapState = state => ({
  pageInfo: state.page.pageInfo
})

export default withRouter(connect(mapState)(Application))
