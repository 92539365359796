import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import SdwButton from '@common/Button'
import _ from 'lodash'

import './index.css'

const DEFAULT_POSITION = {
  x: 0,
  y: 0
}

const DEFAULT_MARGINOPTION = {
  left: 0,
  top: 0
}

class Dialog extends React.Component {
  contentRef = React.createRef()

  state = {
    isMouseDown: false,
    position: DEFAULT_POSITION,
    marginOption: DEFAULT_MARGINOPTION
  }

  static defaultProps = {
    visible: false,
    hideHeader: false,
    title: '',
    cancelText: '',
    sureText: '',
    hideCancelButton: false,
    hideSureButton: false,
    onCancelClick: () => ({}),
    onSureClick: () => ({}),
    changeVisible: () => ({}),
    sureBtnDisabled: false,
    cancelBtnDisabled: false,
    cusWrapClass: ''
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.position, this.state.position)) {

      if (!prevState.position.x || !this.props.visible) return

      let { marginOption } = this.state
      this.setState({
        marginOption: {
          left: marginOption.left + this.state.position.x - prevState.position.x,
          top: marginOption.top + this.state.position.y - prevState.position.y
        }
      })
    }

    if (!_.isEqual(prevProps.visible, this.props.visible) && !this.props.visible) {
      this.setState({
        position: DEFAULT_POSITION,
        marginOption: DEFAULT_MARGINOPTION
      })
    }
  }

  handleGlobalClose = e => {
    const ContentNode = ReactDOM.findDOMNode(this.contentRef.current)

    if (ContentNode && !ContentNode.contains(e.target)) {
      this.props.changeVisible(false)
    }
  }

  render () {
    let { isMouseDown, marginOption } = this.state

    let {
      visible,
      title,
      cancelText,
      sureText,
      hideCancelButton,
      hideSureButton,
      onCancelClick,
      onSureClick,
      width,
      height,
      hideHeader,
      sureBtnDisabled,
      cancelBtnDisabled,
      cusWrapClass,
      zIndex
    } = this.props

    let bodyHeight = height > 180 ? height - 176 : ''

    return ReactDOM.createPortal(
      (
        visible &&
        <div
          style={zIndex ? {zIndex:zIndex}:{}}
          className={`sdw-dialog-win__wrap ${cusWrapClass}`}
          onClick={e => this.handleGlobalClose(e)}
        >
          <div
            className="sdw-dialog-win__content-wrap"
            style={{
              width,
              height,
              marginTop: marginOption.top,
              marginLeft: marginOption.left,
              paddingBottom: (hideCancelButton && hideSureButton) ? 40 : 80
            }}
            ref={this.contentRef}
          >
            {
              !hideHeader &&
              <div
                className="sdw-dialog-win__content-header"
                onMouseDown={() => this.setState({ isMouseDown: true })}
                onMouseUp={() => this.setState({ isMouseDown: false })}
                onMouseLeave={() => this.setState({ isMouseDown: false })}
                onMouseMove={e => {
                  if (isMouseDown) {
                    this.setState({
                      position: {
                        x: e.screenX,
                        y: e.screenY
                      }
                    })
                  }
                }}
              >
                <span>{title}</span>
                <span className="cancel-img" onClick={onCancelClick}></span>
              </div>
            }

            <div style={{
              height: bodyHeight,
              maxHeight: window.innerHeight - 260
            }} className="sdw-dialog-win__content-body">
              {this.props.children}
            </div>

            <div className="sdw-dialog-win__content-footer">
              {
                !hideCancelButton &&
                  <SdwButton
                      type={'cancel'}
                      text={cancelText || '取消'}
                      onClick={onCancelClick}
                      disabled={cancelBtnDisabled}
                  />
              }
              <span style={{marginLeft: 8}}></span>
              {
                !hideSureButton &&
                  <SdwButton
                      type={'submit'}
                      text={sureText || '确定'}
                      onClick={onSureClick}
                      disabled={sureBtnDisabled}
                  />
              }
            </div>
          </div>
        </div>
      ),
      document.body
    )
  }
}

Dialog.propTypes = {
  visible: PropTypes.bool,
  changeVisible: PropTypes.func,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  sureText: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  hideSureButton: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onSureClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sureBtnDisabled: PropTypes.bool,
  cancelBtnDisabled: PropTypes.bool,
  cusWrapClass: PropTypes.string
}

export default Dialog
