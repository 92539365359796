import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwLayout from '@common/Layout'
import { getTablecfgDetail } from '@services/metaData'
import SdwMessage from '@common/Message'
import SdwInput from '@common/Input'
import SdwInput3 from '@common/Input3'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import SdwTable from '@common/Table'
import DbBaseInfo from './components/DbBaseInfo'
import EditBaseDbWin from './components/EditBaseDbWin'
import { getTopic } from '@utils/topic'
import { getFormDataParams } from '@utils/submit'
import { updateTableField } from '@services/metaData'
import SdwEmpty from '@common/Empty'
import '../style/base.css'

const BOOL_MAP = [{
  id: 1,
  text: '是'
}, {
  id: 0,
  text: '否'
}]

const DATA_SET_TYPE_MAP = {
  es: [{
    id: 'ES',
    text: 'ES',
    disabled: true
  }, {
    id: 'keyword',
    text: 'keyword'
  }, {
    id: 'esdate',
    text: 'date'
  }, {
    id: 'double',
    text: 'double'
  }],
  mysql: [{
    id: 'Mysql',
    text: 'Mysql',
    disabled: true
  }, {
    id: 'varchar',
    text: 'varchar'
  }, {
    id: 'int',
    text: 'int'
  }, {
    id: 'tinyint',
    text: 'tinyint'
  }, {
    id: 'datetime',
    text: 'datetime'
  }, {
    id: 'date',
    text: 'date'
  }, {
    id: 'timestamp',
    text: 'timestamp'
  }]
}

class BaseInfo extends React.PureComponent {

  state = {
    baseData: {},
    keyWord: '',
    visible: false,
    enable: '',
    curTitle: '',
    curClickData: {},
    standardSelctDataDic: {}, // 叶子节点id隐射的级联对象
    standardSelectsOptions: [], // 主题/业务/子业务 级联对象
    standardOptions: []
  }

  getColumns = () => {
    let { standardSelctDataDic } = this.state
    return [{
      title: "字段ID",
      width: 120,
      dataIndex: "field_name",
    }, {
      title: "数据格式",
      width: 100,
      // dataIndex: "field_type",
      render: data => {
        const DATA_SET_TYPE = _.get(this.props, 'databaseInfo.data_set_type')
        return (
          <SdwSelect
            placeholder='请选则字段类型'
            value={data.field_type}
            clearable={false}
            data={DATA_SET_TYPE_MAP[DATA_SET_TYPE]}
            onChange={val => this.onSubmit('field_type', val, data)}
          />
        )
      }
    }, {
      title: "字段中文名",
      // dataIndex: "field_name_ch",
      render: data => (
        <SdwInput3
          placeholder='点击输入名称'
          value={data.field_name_ch}
          onBlur={val => this.onSubmit('field_name_ch', val, data)}
        />
      )
    }, {
      title: "数据标准",
      width: 150,
      tip: data => {
        let fieldStandard = !_.isEmpty(standardSelctDataDic) ? standardSelctDataDic[data.field_ref_standard] : {}
        if (!!_.isEmpty(fieldStandard)) {
          return ''
        }
        let { content, id, field_define } = fieldStandard

        return (
          <>
            <span>{`[${id}] ${content}`}</span><br/>
            <span>{field_define}</span>
          </>
        )
      },
      // render: data => {
      //   let fieldStandard = !_.isEmpty(standardSelctDataDic) ? standardSelctDataDic[data.field_ref_standard] : {}
      //   let text = '-'
      //   if (!_.isEmpty(fieldStandard) && !!fieldStandard.content) {
      //     text = fieldStandard.content
      //   }
      //   return (
      //     <span>{text}</span>
      //   )
      // }
      render: data => (
        <SdwSelect
          width={250}
          placeholder='请选择数据标准'
          value={data.field_ref_standard}
          readOnly={false}
          clearable={false}
          data={this.state.standardOptions}
          onChange={val => this.onSubmit('field_ref_standard', val, data)}
        />
      )
    }, {
      title: "是否敏感",
      // dataIndex: "is_sensitive",
      render: data => (
        <SdwSelect
          width={100}
          placeholder='请选择'
          data={BOOL_MAP}
          clearable={false}
          value={data.is_sensitive}
          onChange={val => this.onSubmit('is_sensitive', val, data)}
        />
      )
    }, {
      title: "是否有效",
      // dataIndex: "enable",
      render: data => (
        <SdwSelect
        width={100}
          placeholder='请选择'
          data={BOOL_MAP}
          clearable={false}
          value={data.enable}
          onChange={val => this.onSubmit('enable', val, data)}
        />
      )
    }, {
      title: "备注",
      // dataIndex: "comments",
      render: data => (
        <SdwInput3
          placeholder='点击输入备注'
          value={data.comments}
          onBlur={val => this.onSubmit('comments', val, data)}
        />
      )
    // }, {
    //   title: "操作",
    //   width: 50,
    //   fiexd: true,
    //   render: (data, record) => (
    //     <>
    //       <SdwButton
    //         text="修改"
    //         onClick={ () => this.setState({
    //           visible: true,
    //           curTitle: `编辑字段（${data.id}）`,
    //           curClickData: data
    //         }) }
    //       />
    //     </>
    //   )
    }]
  }

  componentDidMount() {
    this.initData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.databaseInfo.id !== this.props.databaseInfo.id) {
      let ID = this.props.databaseInfo.id
      if (!!ID) {
        this.initData()
      }
    }
  }

  initData = async () => {
    this.onGetTablecfgDetail()

    let topicData = await getTopic()
    let standardOptions = []

    if (topicData && !_.isEmpty(topicData.standardSelctDataDic)) {
      standardOptions = _.values(topicData.standardSelctDataDic).map(i => ({
        id: i.id,
        text: i.content
      }))
    }

    if (_.isEmpty(topicData)) return
    this.setState({
      standardSelectsOptions: topicData.topicDataList,
      standardSelctDataDic: topicData.standardSelctDataDic,
      standardOptions
    })
  }

  onGetTablecfgDetail = () => {
    let id = this.props.databaseInfo.id

    if (!id) return
    getTablecfgDetail({id}).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        this.setState({ baseData: record })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }

  onSubmit = (key, val, data) => {

    // 该字段没有修改，不下发请求
    if (data[key] == val) return

    // 下发字段
    let paramsList = ['id', 'data_set_id', 'field_name', 'field_type', 'field_name_ch',
    'is_sensitive', 'enable','comments', 'field_ref_directory', 'field_ref_standard']

    let formData = getFormDataParams(_.assign({}, data, {
      [key]: val
    }), paramsList)

    updateTableField(formData).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        SdwMessage.success('编辑成功')
        this.onGetTablecfgDetail() // 重新获取表数据
      } else {
        let msg = _.get(res, 'data.msg') || '编辑成功失败'
        SdwMessage.error(msg)
      }
    })
    .catch(msg => {
      SdwMessage.error('请求失败 ' + msg)
    })
  }

  jumpToSearchEport = () => {
    let id = this.props.databaseInfo.id
    this.props.history.push({
      pathname: `/admin/dataGasStation/searchEport/${id}`,
      state: {curMenuIndex: 0}
    })
  }

  render () {
    let { keyWord, baseData, visible, curTitle, curClickData, standardSelctDataDic, standardSelectsOptions, enable } = this.state
    let { curEnabled } = this.props

    let feilds = _.cloneDeep(baseData.fields)
    let dataSource = _.isArray(feilds) ? feilds : []
    if (enable !== '') {
      dataSource = dataSource.filter(i => i.enable == enable)
    } else {
      dataSource = _.isArray(feilds) ? feilds : []
    }
    dataSource = dataSource.filter(i => i.field_name.indexOf(keyWord) !== -1).slice(0, 500)

    return (
      <>
        <SdwLayout.Row gutter={24}>
          <div className="table-detail-base__left-base-info-warp">
            <DbBaseInfo
              record={baseData.detail || {}}
            />
          </div>
          <SdwLayout.Col span={20}>
            {
              !curEnabled ?
              <SdwEmpty
                height={window.innerHeight - 500}
                icon='noAuth'
                text='该表为无效表'
              /> :
              <>
                <div className="table-detail-base__opr-wrap">
                  <SdwInput
                    label='字段信息'
                    value={keyWord}
                    placeholder="输入关键词搜索字段ID"
                    width={260}
                    onChange={val => this.setState({keyWord: val})}
                  />

                  <span style={{
                    marginLeft: 8
                  }}>
                    <SdwSelect
                      label='有效性'
                      value={enable}
                      width={200}
                      data={[{
                        id: '',
                        text: '全部'
                      }, {
                        id: '0',
                        text: '否'
                      }, {
                        id: '1',
                        text: '是'
                      }]}
                      onChange={val => this.setState({enable: val})}
                    />
                  </span>

                   <span className="table-detail-base__opr-add">
                    <SdwButton
                      type="outline"
                      text="明细查询"
                      onClick={() => this.jumpToSearchEport()}
                    />
                  </span>

                  {/* <span className="table-detail-base__opr-add">
                    <SdwButton
                      type="submit"
                      text="新增"
                      disabled={true}
                      iconClass="el-icon-plus"
                      onClick={ () => this.setState({
                        visible: true,
                        curTitle: `新增字段`,
                        curClickData: {}
                      }) }
                    />
                  </span> */}
                </div>
                <div style={{
                  overflow: 'auto'
                }}>
                  <div style={{
                    minWidth: 1600,
                  }}>
                    <SdwTable
                      setOverflow='visible'
                      columns={ this.getColumns() }
                      dataSource={ dataSource }
                    />
                  </div>
                </div>
              </>
            }
          </SdwLayout.Col>
        </SdwLayout.Row>

        {
          visible &&
          <EditBaseDbWin
            visible={visible}
            curTitle={curTitle}
            changeVisible={bool => this.setState({ visible: bool })}
            curClickData={curClickData}
            baseDetail={baseData.detail}
            freshTableData={this.onGetTablecfgDetail}
            standardSelctDataDic={standardSelctDataDic}
            standardSelectsOptions={standardSelectsOptions}
          />
        }
      </>
    )
  }
}

const mapState = state => ({
  databaseInfo: state.database.databaseInfo
})

export default connect(mapState)(BaseInfo)
