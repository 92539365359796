// 公共常量

export const EXTRA_MAP = {
  primordial: 1,
  atom: 2,
  derive: 3
}

export const EXTRA_MAP_TEXT = {
  [EXTRA_MAP.primordial]: "原生字段数",
  [EXTRA_MAP.atom]: "原子指标数",
  [EXTRA_MAP.derive]: "派生指标数"
}

export const TABLE_EXTRA_MAP_TEXT = {
  [EXTRA_MAP.primordial]: "原生字段",
  [EXTRA_MAP.atom]: "原子指标",
  [EXTRA_MAP.derive]: "派生指标"
}
