import { useRef, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSwitch from '@common/Switch'
import Form from '@common/Form_D'
import SdwMessage from '@common/Message'
import InputTip from '@common/InputTip'
import { insertArchiveCodeConf, updateArchiveCodeConf, getArchiveCodeArchivesSelect, getArchiveCodeL4Query } from '@services/operationConfiguration'

function notEmpty(val) {
  return !!val
}

const rules = {
  archive_name: [
    { func: notEmpty, message: '请填写归档项' }
  ],
  ai_code: [
    { func: notEmpty, message: '请填写L4' }
  ]
}

function getVal(str, s) {
  if (!!str && !!s && str.indexOf(s) === -1) return ''
  return str.split(s)[0] || ''
}

export default function EditWin(props) {
  const formRef = useRef(null)
  const { isEdit, data, showEditWin, setShowEditWin, updatePage } = props

  const [formData, setFormData] = useState({})
  const [searchData, setSearchData] = useState({})
  const [timer, setTimer] = useState(null)
  const [archiveOption, setArchiveOption] = useState([])
  const [l4Option, setL4Option] = useState([])

  useEffect(() => {
    let record = isEdit ? _.cloneDeep(data) : {enable: 0}
    setFormData(record)
    if (isEdit) {
      if (!!data.archive_name) onArchiveChange(data.archive_name)
      if (!!data.service_id && !!data.ai_code) onL4Change(data.service_id, data.ai_name)
    }
  }, [data, isEdit])

  function setFormDataProps(key, val) {
    let cloneFormData = _.cloneDeep(formData)
    cloneFormData[key] = val
    setFormData(cloneFormData)
  }

  function handleSubmit()  {
    let api = isEdit ? updateArchiveCodeConf : insertArchiveCodeConf
    formRef.current.validate(valid => {
      if (valid) {
        // console.log('*******isEdit: ', isEdit, formData);
        api({
          field: _.assign({}, formData, {
            ai_level: 'L4'
          })
        }).then(res => {
          const code = _.get(res, 'data.code')
          if (code === 0) {
            let msg = isEdit ? '编辑配置成功' : '添加配置成功'
            SdwMessage.success(msg)
            setShowEditWin(false)
            updatePage()
          } else {
            let msg = isEdit ? '编辑配置失败' : '添加配置失败'
            SdwMessage.error(msg)
          }
        }).catch(() => SdwMessage.error("接口异常"))
      }
    })
  }

  function onArchiveChange(val) {
    setSearchData(r => _.assign({}, r, {'archive_name': val}))

    // 查询候选项
    if (timer) clearTimeout(timer)
    let t = setTimeout(() => {
      getArchiveCodeArchivesSelect({
        query: val
      }).then(res => {
        const code = _.get(res, 'data.code')
        if (code === 0) {
          const list = _.get(res, 'data.data')
          if (_.isArray(list)) setArchiveOption(list)
        } else {
          const msg = _.get(res, 'data.msg') || '请求数据失败！'
          SdwMessage.error(msg)
        }
      }).catch(() => SdwMessage.error('请求异常！'))

      clearTimeout(timer)
    }, 500)
    setTimer(t)
  }
  
  function onArchiveClick(item) {
    const { id, text, parentCodeList } = item
    const service_id = getVal(parentCodeList, '->')

    if (service_id) onL4Change(service_id)

    setSearchData(r => _.assign({}, r, {'archive_name': text}))
    setFormData(r => _.assign({}, r, {
      archive_name: text,
      archive_code: id,
      service_id,
      service_name: getVal(text, '->')
    }))
  }

  function onl4Click(item) {
    const { id, text, codeName } = item

    setSearchData(r => _.assign({}, r, {'L4': text}))
    setFormData(r => _.assign({}, r, {
      'ai_name': codeName,
      'ai_code': id
    }))
  }

  // 查询候L4选项
  function onL4Change(service_id, query = '') {
    setSearchData(r => _.assign({}, r, {'L4': query}))
    if (!service_id) return
    if (timer) clearTimeout(timer)
    let t = setTimeout(() => {
      getArchiveCodeL4Query({
        service_id,
        query
      }).then(res => {
        const code = _.get(res, 'data.code')
        if (code === 0) {
          const data = _.get(res, 'data.data') || []
          setL4Option(data)
        } else {
          const msg = _.get(res, 'data.msg') || '请求数据失败！'
          SdwMessage.error(msg)
        }
      }).catch(() => SdwMessage.error('请求异常！'))

      clearTimeout(timer)
    }, 500)
    setTimer(t)
  }

  const arOptions = useMemo(() => {
    return archiveOption.map(i => ({
      id: i.code_id,
      text: i.parent_name_list,
      parentCodeList: i.parent_code_list
    }))
  }, [archiveOption])

  const L4Options = useMemo(() => {
    return l4Option.map(i => ({
      id: i.codeId,
      text: `${i.codeName}(${i.codeId})`,
      codeName: i.codeName
    }))
  }, [l4Option])

  return (
    <>
      <SdwDrawer
        title={ isEdit ? '编辑配置' : '添加配置' }
        visible={showEditWin}
        onIconClick={() => setShowEditWin(false)}
        onCancelClick={() => setShowEditWin(false)}
        onSureClick={handleSubmit}
      >
        <SdwDrawer.Body>
          <Form labelWidth={'100px'} labelPosition={'left'} ref={formRef} model={formData} rules={rules}>
            <Form.FormItem label="归档项" prop="archive_name" required>
              <InputTip
                placeholder="请输入id检索或名称检索"
                width='100%'
                readOnly={false}
                value={searchData.archive_name}
                onChange={val => onArchiveChange(val)}
                onClick={item => onArchiveClick(item)}
                onClear={() => setFormDataProps('archive_name', '')}
                options={arOptions}
              />
            </Form.FormItem>
            <Form.FormItem label="L4" prop="ai_code" required>
              <InputTip
                placeholder={`${!formData.archive_code ? '请先选择归档项' : '请输入L4的id'}`}
                width='100%'
                readOnly={false}
                disabled={!formData.archive_code}
                value={searchData.L4}
                onChange={val => onL4Change(formData.service_id, val)}
                onClick={item => onl4Click(item)}
                onClear={() => setFormDataProps('archive_name', '')}
                options={L4Options}
              />
            </Form.FormItem>
            <Form.FormItem label="产品id" prop="service_id">
              <SdwInput
                placeholder="请选择归档项"
                width='100%'
                disabled
                value={formData.service_id}
                onChange={val => setFormDataProps('service_id', val)}
              />
            </Form.FormItem>
            <Form.FormItem label="产品名称" prop="service_name">
              <SdwInput
                placeholder="请选择归档项"
                width='100%'
                disabled
                value={formData.service_name}
                onChange={val => setFormDataProps('service_name', val)}
              />
            </Form.FormItem>            
            <Form.FormItem label="是否可用">
              <SdwSwitch
                value={formData.enable}
                changeValue={val => setFormDataProps('enable', val)}
              />
            </Form.FormItem>
          </Form>
        </SdwDrawer.Body>
      </SdwDrawer>
    </>
  )
}