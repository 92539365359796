import React, {PureComponent} from 'react'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import SdwMessage from '@common/Message'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../../index.css'
import {requestOptionsLOG} from '@config/config'
const baseURL = requestOptionsLOG.basename

class CollectionTask extends PureComponent {

    state = {
        tagList: [],
        params: {
            inputFileId: '',
            inputTag: '',
            cmd: '',
            extra: '',
            enable: false,
            rtx: '',
        },
        sholdCheckValidate: false,
    }

    componentDidMount() {
        this.iniData()
    }

    iniData = () => {
        let {isEdit, currentItem} = this.props
        if (isEdit) {
            let params = {
                id: parseInt(currentItem.id),
                inputFileId: parseInt(currentItem.inputFileId),
                inputTag: currentItem.inputTag,
                cmd: currentItem.cmd || '',
                extra: currentItem.extra || '',
                enable: currentItem.enable,
                rtx: this.props.userInfo.name,
            }
            this.setState({
                params: params
            })
            this.queryValidInputTag(currentItem.id)
        } else {
            this.queryInputFileConfigList(currentItem.id)
        }
    }

    // 查询未使用的采集标识
    queryValidInputTag = (id = "") => {
        let str = ""
        if (!!id) {
            str = "?parseId="+id+"&type=input"
        }
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/inputTag/queryValidInputTag' + str,
          {
              method: "GET",
              mode: "cors",
              headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx}
          }).then(res => {
            res.json().then((data) => {
                let record = data.data
                console.log('tableData-----------------------', record)
                if (Array.isArray(record)) {
                    console.log("record=======", record)
                    this.setState({
                        tagList: record.map(i => {
                            return {id: i.inputTag, text: i.inputTag, cmd: i.cmd, item: i}
                        })
                    })
                }

            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 查询已被解析配置绑定且不在当前文件下的采集标识
    queryInputFileConfigList = (id = "") => {
        let str = ""
        if (!!id) {
            str = "?inputFileConfigId=" + id
        }
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/inputTag/queryBindedInputTag' + str,
            {
                method: "GET",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then((data) => {
                let record = data.data
                console.log('queryInputFileConfigList===tableData-----------------------', record)
                if (Array.isArray(record)) {
                    this.setState({
                        tagList: record.map(i => {
                            return {id: i.inputTag, text: i.inputTag, cmd: i.cmd, item: i}
                        })
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    setParamsData = (type, value) => {
        let {params} = this.state
        let obj = {}
        obj[type] = value
        let paramsClone = Object.assign({}, params, obj)
        this.setState({
            params: paramsClone
        })
    }

    // 写入或更新采集任务配置
    submitClick = () => {
        let {params} = this.state
        let {currentItem, isEdit} = this.props
        console.log('currentItem===11111', currentItem)
        let rtx = this.props.userInfo.name
        if (!params.inputTag) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        params.enable = params.enable == 1 ? true : false
        params.inputFileId = parseInt(currentItem.id)
        if (isEdit) {
            params.id = parseInt(currentItem.id)
            params.inputFileId = parseInt(currentItem.inputFileId)
        }
        fetch(baseURL + '/sdw/filebeat/input/upsertInputConfig',
            {
                method: "POST",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                if (data && data.code === 100 || data.code === 101) {
                    this.props.changeVisible(false)
                    this.props.collectionTaskSubmit(data)
                } else {
                    SdwMessage.error(data.message)
                    // this.props.changeVisible(false)
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    render() {

        let {
            params,
            sholdCheckValidate,
            tagList,
        } = this.state

        let {
            isEdit,
            changeVisible,
            taskVisible,
        } = this.props

        // console.log('tagList==========', tagList)

        return (
            <SdwDrawer
                title={isEdit ? "编辑采集任务" : "新增采集任务"}
                visible={taskVisible}
                onIconClick={() => changeVisible(false)}
                onCancelClick={() => changeVisible(false)}
                onSureClick={this.submitClick}
            >
                <SdwDrawer.Body>
                    <div className='logAccess-win_warp'>
                        <div>
                            <span className='label required'>采集标识</span>
                            <SdwSelect
                                placeholder='请选择采集标识'
                                data={tagList}
                                value={params.inputTag}
                                onChange={(val, item) => {
                                    this.setState({
                                        params: Object.assign({}, params, {
                                            inputTag: val,
                                            cmd: item.cmd || ''
                                        })
                                    })
                                }}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '采集标识不能为空'}
                            />
                        </div>
                        <div>
                            <span className='label'>CMD</span>
                            <SdwInput
                                placeholder='请选择采集标识'
                                disabled={true}
                                value={params.cmd}
                            />
                        </div>
                        <div>
                            <span className='label'>备注</span>
                            <SdwInput
                                type='textarea'
                                placeholder='请输入备注'
                                value={params.extra}
                                onChange={val => this.setParamsData('extra', val)}
                            />
                        </div>
                        <div>
                            <span className='label required'>是否有效</span>
                            <SdwSwitch
                                value={params.enable}
                                changeValue={val => this.setParamsData('enable', val)}
                            />
                        </div>
                    </div>
                </SdwDrawer.Body>
            </SdwDrawer>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(CollectionTask))
