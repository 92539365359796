import React from 'react'
import _ from 'lodash'
import './index.css'


export default function ConfirmWin(props) {
  let confirmData = props.confirmData
  return (
    <div className='confirm-win__label'>
      {
        !props.hideTitle &&
        <span className='title'>{props.title}</span>
      }

      <div className='main'>
        {
          confirmData.insert != null &&
          <div className='type-name'>新增</div>
        }
        {
          confirmData.insert != null &&
          confirmData.insert.map(i => (
            <div key={i.id}>{`${i.id} ${i.target_name} （${i.target_name_cn}）`}</div>
          ))
        }
        {
          confirmData.modify != null &&
          <div className='type-name'>更新</div>
        }
        {
          confirmData.modify != null &&
          confirmData.modify.map(i => (
            <div key={i.id}>{`${i.id} ${i.target_name} （${i.target_name_cn}）`}</div>
          ))
        }
        {
          confirmData.delete != null &&
          <div className='type-name'>删除</div>
        }
        {
          confirmData.delete != null &&
          confirmData.delete.map(i => (
            <div key={i.id}>{`${i.id} ${i.target_name} （${i.target_name_cn}）`}</div>
          ))
        }
      </div>
    </div>
  )
}
