import { useState, useEffect } from 'react'
import _ from 'lodash'
import Input from '@common/Input'
import Table from '@common/Table'
import Button from '@common/Button'
import Enable from '@common/Enable'
import Pagenation from '@common/Pagenation'
import SdwMessage from '@common/Message'
import EditWin from './EditWin'
import { getMonitorSettingList } from '@services/monitorSetting'
import { getStaffs } from '@services/metaData'
import { MONITOR_TYPE } from '@utils/monitor'

export default function MonitorConfig(props) {

  const [tableData, setTableData] = useState({})
  const [searchParams, setSearchParams] = useState({
    page: 1,
    limit: 10
  })
  const [showWin, setShowWin] = useState(false)
  const [curRecord, setCurRecord] = useState({})
  const [title, setTitle] = useState('')
  const [allMembers, setAllMembers] = useState([])

  useEffect(() => {
    getStaffs().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        let list = _.isArray(data) ? data : []
        const Options = list.map(i => ({
          id: i.value,
          text: i.name
        }))
        setAllMembers(Options)
      }
    })
  }, [])

  useEffect(() => {
    initData()
  }, [searchParams])

  function getcolumns() {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 50
      },{
        title: "类型",
        render: data => <span>{!!data.monitor_type ? MONITOR_TYPE[data.monitor_type] : '-'}</span>,
        width: 100
      },{
        title: "标题",
        dataIndex: "title",
        width: 180
      },{
        title: "推送频率",
        dataIndex: "repeat_cycle",
        width: 100
      },{
        title: "更新人",
        dataIndex: "modify_user",
        width: 100
      },{
        title: "更新时间",
        dataIndex: "modify_time",
        width: 140
      },{
        title: "状态",
        render: data => <Enable enable={data.enable}/>,
        width: 100
      },{
        title: "操作",
        width: 100,
        render: data => (
          <Button
            text='编辑'
            onClick={() => {
              setShowWin(true)
              setCurRecord(data)
            }}
          />
        )
      },
    ]
  }

  function initData() {
    getMonitorSettingList({
      ...searchParams,
      search: JSON.stringify({
        ...title ? {
          title: {
            value: title,
            operator: 'like'
          }
        } : {}
      })
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        if (!_.isEmpty(data)) setTableData(data)
      } else {
        const msg = _.get(res, 'data.msg') || '获取数据失败！'
        SdwMessage.error(msg)
      }
    }).catch(err => SdwMessage.error('接口异常' + err))
  }

  return (
    <div style={{ padding: '16px 0' }}>
      <div style={{ position: 'relative', marginBottom: 16 }}>
        <Input
          label='标题'
          width={260}
          value={title}
          onChange={val => {
            setSearchParams(r => _.assign({}, r, {page: 1}))
            setTitle(val)
          }}
          onBlur={initData}
        />

        <span style={{ position: 'absolute', right: 0 }}>
          <Button
            iconClass="iconfont icon-add"
            text='新增'
            type='submit'
            onClick={() => {
              setShowWin(true)
              setCurRecord({})
            }}
          />
        </span>
      </div>
      <div>
        <Table
          columns={getcolumns()}
          dataSource={_.isArray(tableData.items) ? tableData.items : []}
        />

        <Pagenation
          total={ tableData.total }
          currentPage={ tableData.current_page }
          pageCountList={[10, 15, 20, 40]}
          pageCount={ tableData.per_page }
          getClickPage={ page => setSearchParams(r => _.assign({}, r, {page})) }
          pageCountChange={ limit => setSearchParams(r => _.assign({}, r, {limit})) }
        />
      </div>

      {
        showWin &&
        <EditWin
          data={curRecord}
          allMembers={allMembers}
          showWin={showWin}
          setShowWin={setShowWin}
          updatePage={initData} // 重新获取数据
        />
      }
    </div>
  )
}
