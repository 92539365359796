import { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import './index.css'

function UpLoad(props) {
  const { height, width, placeholder, tip } = props

  const [files, setFiles] = useState({})

  function onFileChage(e) {
    let file = e.target.files 
    setFiles(file)

    let formData = new FormData()
    formData.append("upload-file", file[0])
    formData.append("fileName", _.get(file, '[0].name'))
    props.changeValue(file, formData)
  }

  const NAME = files && files[0] && `已选择文件：${files[0].name}` || placeholder
  return (
    <div style={{
      display: 'inline-block',
      position: 'relative',
      height: typeof height  === 'number' ? height + 'px' : height,
      width
    }}>
      <span className="comp_model-training__file-upload-wrap" style={{
        lineHeight: typeof height  === 'number' ? height + 'px' : height
      }}>
        <span className="upload-btn" style={{
          width
        }}>{ NAME }</span>
        <input className="upload-input" style={{
          height,
          width
        }} type="file" accept={props.accees} onChange={e => onFileChage(e)} />
      </span>
      {
        !!tip &&
        <span style={{ 
          position: 'absolute',
          whiteSpace: 'nowrap',
          bottom: -24,
          color: '#999'
        }}>{ tip }</span>
      }
    </div>
  )
}

UpLoad.propTypes = {
  accees: PropTypes.string,
  placeholder: PropTypes.string,
  tip: PropTypes.string,
  changeValue: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

UpLoad.defaultProps = {
  accees: '*',
  width: '',
  placeholder: '请选择上传文件',
  tip: '',
  height: 32,
  changeValue: _.noop,
}

export default UpLoad