// 创建 or 编辑 表配置

import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import SdwMulRadiobox from '@common/MulRadiobox'
import SdwMessage from '@common/Message/index'
import { getTablecfgById, getBusinessStatisticByTopic, updateTablecfg, storeTablecfg } from '@services/metaData'
import { LEVEL_TEXT_MAP } from './common'
import { validateFun, getFormDataParams } from '@utils/submit'

import './style/editMetadataWin.css'

class EditMetadataWin extends React.PureComponent {

  state ={
    curDbData: {
      enable: 0,
      pattern: '_no_pattern',
      warehouse_level: 'DIM'
    }, // 当前弹窗数据
    businessList: [],
    sholdCheckValidate: false
  }

  componentDidMount() {
    let { curData } = this.props
    let dbID = curData.id

    if (!!dbID) {
      this.initData(dbID)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.curDbData.topic !== this.state.curDbData.topic) {
      let { topic } = this.props.topicInfo
      let topicData = topic.filter(i => i.topic_name == this.state.curDbData.topic)[0] || {}
      let topicID = topicData.id

      // 根据topicID请求对应的 业务
      getBusinessStatisticByTopic({
        id: topicID
      })
        .then(res => {
          if (res && res.data && res.data.code === 0) {
            let record = res.data.data || {}
            let list = Array.isArray(record.list) ? record.list : []
            this.setState({ businessList: list })
          } else {
            SdwMessage.error('获取业务信息失败')
          }
        })
    }
  }

  initData = id => {
    getTablecfgById({id}).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        this.setState({ curDbData: record })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    })
    .catch(msg => {
      SdwMessage.error('请求失败 ' + msg)
    })
  }

  updateCurDbData = (key, val) => {
    let obj = _.cloneDeep(this.state.curDbData)

    if (key === 'owner') {
      val = val.map(i => i.id).join(';')
    }
    obj[key] = val

    // 新建表
    if (key === 'add_type') {
      obj.data_set_type = val === 1 ? 'es' : ''
      obj.table_name = val === 1 ? '_doc' : ''
      obj.address = val === 1 ? '9.138.64.12:8081,9.138.70.60:9400,9.138.70.65:9400' : ''
    }

    this.setState({ curDbData: obj })
  }

  onSubmit = () => {
    let { curDbData } = this.state

    // 校验
    let validataList = ['data_set_type', 'database_name', 'table_name', 'warehouse_level',
    'address', 'topic', 'business', 'owner', 'deadline', 'data_set_chinese']

    // 下发字段
    let paramsList = ['data_set_type', 'data_set_chinese', 'database_name', 'table_name', 'warehouse_level', 'pattern',
    'address', 'topic', 'business', 'username', 'password', 'owner', 'deadline', 'comments', 'time_field',
    'sec_id', 'enable']

    if (!curDbData.id) {
      // 新增字段
      validataList.push('add_type')
      paramsList.push('add_type')
    } else {
      // 编辑字段
      paramsList.push('id')
    }

    let isValidatePass = validataList.every(i => !!curDbData[i])

    if (!isValidatePass) {
      this.setState({ sholdCheckValidate: true })
      return
    }

    let formData = getFormDataParams(curDbData, paramsList)

    let api = !!curDbData.id ? updateTablecfg : storeTablecfg

    api(formData).then(res => {
      let oprText = !!curDbData.id ? '编辑' : '创建'
      if (res && res.data && res.data.code === 0) {
        SdwMessage.success(`${oprText}成功`)
        this.props.changeVisible(false)
        this.props.freshTableData() // 重新获取表数据
      } else {
        let msg = res.data.msg || `${oprText}失败`
        SdwMessage.error(msg)
      }
    })
    .catch(msg => {
      SdwMessage.error('请求失败 ' + msg)
    })
  }

  render () {
    let {
      curTitle, visible, changeVisible, topicInfo, staffUserList
    } = this.props

    let { curDbData, businessList, sholdCheckValidate } = this.state

    // 是否是编辑界面
    const isEdit = !!curDbData.id

    // 是否是es
    const isEs = curDbData.data_set_type === 'es'
    const isMysql = curDbData.data_set_type === 'mysql'

    // 新建表
    const isCreateTable = curDbData.add_type === 1
    let topicOptions = Array.isArray(topicInfo.topic) ? topicInfo.topic.map(i => {
      return {
        text: i.topic_name,
        id: i.topic_name,
        topicID: i.id
      }
    }) : []

    let owner = curDbData.owner || ''
    let curOwner = owner.split(';').filter(i => !!i)
    let curOwnerObj = staffUserList.filter(i => curOwner.includes(i.value))

    return (
      <SdwDrawer
        title={curTitle}
        visible={visible}
        onIconClick={() => changeVisible(false)}
        onCancelClick={() => changeVisible(false)}
        onSureClick={this.onSubmit}
        sureText={isCreateTable ? '新增并接入' : '确定'}
      >
        <SdwDrawer.Body>
          {
            !isEdit &&
            <div className='meta-win__lable-wrap'>
              <span className="meta-win__lable-title required">新增方式</span>
              <SdwSelect
                placeholder='请选则新增方式'
                sholdCheckValidate={sholdCheckValidate}
                validateFun={val => validateFun(val, '请选择新增方式')}
                value={curDbData.add_type}
                clearable={false}
                data={[{
                  id: 1,
                  text: '新建表'
                }, {
                  id: 2,
                  text: '接入表'
                }]}
                onChange={val => this.updateCurDbData('add_type', val)}
              />
            </div>
          }
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">表中文名</span>
            <SdwInput
              placeholder='请输入表中文名'
              value={curDbData.data_set_chinese}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请输入表中文名')}
              onChange={val => this.updateCurDbData('data_set_chinese', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">仓库类型</span>
            <SdwSelect
              placeholder='请选则仓库类型'
              disabled={isCreateTable}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请选择仓库类型')}
              value={curDbData.data_set_type}
              clearable={false}
              data={[{
                id: 'es',
                text: 'es'
              }, {
                id: 'mysql',
                text: 'mysql'
              }]}
              onChange={val => {
                this.updateCurDbData('data_set_type', val)
              }}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">{isEs ? '索引(index)' : '数据库名'}</span>
            <SdwInput
              //width={!isEs ? '80%' : 'calc(80% - 200px)'}
              width={'calc(80% - 200px)'}
              placeholder='请输入'
              value={curDbData.database_name}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请输入' + isEs ? '索引(index)' : '数据库名')}
              onChange={val => this.updateCurDbData('database_name', val)}
            />
            {
              <SdwSelect
                width={200}
                value={curDbData.pattern}
                clearable={false}
                data={[{
                  id: '_no_pattern',
                  text: '不分索引(_no_pattern)'
                }, {
                  id: '_yearly',
                  text: '按年索引(_yearly)'
                }, {
                  id: '_monthly',
                  text: '按月索引(_monthly)'
                }, {
                  id: 'monthly',
                  text: '按月索引(monthly)'
                }, {
                  id: '_daily',
                  text: '按天索引(_daily)'
                }, {
                  id: 'daily',
                  text: '按天索引(daily)'
                }]}
                onChange={val => this.updateCurDbData('pattern', val)}
              />
            }
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">{isEs ? '类型(type)' : '表名'}</span>
            <SdwInput
              placeholder='请输入'
              value={curDbData.table_name}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请输入' + isEs ? '类型(type)' : '表名')}
              onChange={val => this.updateCurDbData('table_name', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">仓库层级</span>
            <SdwMulRadiobox
              options={_.keys(LEVEL_TEXT_MAP).map(key => {
                return {
                  value: key,
                  text: LEVEL_TEXT_MAP[key]
                }
              })}
              value={curDbData.warehouse_level}
              changeCheckedFun={val => this.updateCurDbData('warehouse_level', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">访问IP地址</span>
            <SdwInput
              placeholder='请输入IP地址'
              disabled={isCreateTable}
              value={curDbData.address}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请输入IP地址')}
              onChange={val => this.updateCurDbData('address', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title">用户名</span>
            <SdwInput
              placeholder='请输入用户名'
              value={curDbData.username}
              onChange={val => this.updateCurDbData('username', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title">密码</span>
            <SdwInput
              type='password'
              placeholder='请输入密码'
              value={curDbData.password}
              onChange={val => this.updateCurDbData('password', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">主题</span>
            <SdwSelect
              placeholder='请选则主题'
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请选择主题')}
              data={topicOptions}
              value={curDbData.topic}
              clearable={false}
              onChange={val => this.updateCurDbData('topic', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">业务</span>
            <SdwSelect
              placeholder={!curDbData.topic ? '请先选择主题' : '请选则业务'}
              disabled={!curDbData.topic}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请选择业务')}
              data={businessList.map(i => {
                return {
                  id: i.business_name,
                  text: i.business_name
                }
              })}
              value={curDbData.business}
              clearable={false}
              onChange={val => this.updateCurDbData('business', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">负责人</span>
            <SdwSelect
              placeholder={!!curOwnerObj.length ? '' : '请输入负责人'}
              readOnly={false}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请选择负责人')}
              isMultipleChoice={true}
              data={staffUserList.map(i => {
                return {
                  id: i.value,
                  text: i.name
                }
              })}
              value={curOwnerObj.map(i => {
                return {
                  id: i.value,
                  text: i.name
                }
              })}
              onChange={val => this.updateCurDbData('owner', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title required">生命周期</span>
            <SdwInput
              value={curDbData.deadline}
              type='number'
              sholdCheckValidate={sholdCheckValidate}
              validateFun={val => validateFun(val, '请输入生命周期')}
              onChange={val => this.updateCurDbData('deadline', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title">表描述</span>
            <SdwInput
              type="textarea"
              placeholder='请输入表描述'
              value={curDbData.comments}
              onChange={val => this.updateCurDbData('comments', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title">校验时间字段</span>
            <SdwInput
              placeholder='请输入校验时间字段'
              value={curDbData.time_field}
              onChange={val => this.updateCurDbData('time_field', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title">敏感权限ID</span>
            <SdwInput
              placeholder='请输入敏感权限ID'
              value={curDbData.sec_id}
              onChange={val => this.updateCurDbData('sec_id', val)}
            />
          </div>
          <div className='meta-win__lable-wrap'>
            <span className="meta-win__lable-title">是否有效</span>
            <SdwSwitch
              value={curDbData.enable}
              changeValue={ bool => this.updateCurDbData('enable', bool) }
            />
          </div>

          {/*
          <div className='meta-win__lable-wrap'>
            <span className={`meta-win__lable-title ${curDbData.data_set_type === 'es' ? 'required' : ''}`}>校验时间字段</span>
            <SdwInput
              placeholder='请输入校验时间字段'
              value={curDbData.time_field}
              sholdCheckValidate={curDbData.data_set_type === 'es' ? sholdCheckValidate : false}
              validateFun={curDbData.data_set_type === 'es' ? val => validateFun(val, '请输入校验时间字段') : _.noop}
              onChange={val => this.updateCurDbData('time_field', val)}
            />
          </div> */}
        </SdwDrawer.Body>
      </SdwDrawer>
    )
  }
}

const mapState = state => ({
  topicInfo: state.topic.topicInfo,
  staffUserList: state.user.staffUserList,
})

export default connect(mapState)(EditMetadataWin)
