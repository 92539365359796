import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwButton from '@common/Button'
import DataAccessPage from './accessType/DataAccessPage'
import DataLog from './accessType/DataLog'
import DataAccessTest from './accessType/DataAccessTest'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import './style/dataAccess.css'

const COMPONENTS = {
  0: DataAccessPage,
  1: DataLog
}

class DataAccess extends React.PureComponent {

  state = {
    curIndex: 0
  }

  componentDidMount() {
    const curPageIndex = _.get(this.props, 'location.state.curPageIndex') || 0
    if (curPageIndex) this.setState({ curIndex: curPageIndex })
    this.props.updateCurrentPage(this.props.pageMap.main)
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const prevIsEdit = _.get(prevProps, 'location.state.isEdit')
  //   const curIsEdit = _.get(this.props, 'location.state.isEdit')
  //   if (prevIsEdit !== curIsEdit) {
  //     console.log('curIsEdit: ', this.props);
  //   }
  // }

  render () {
    let { curIndex } = this.state
    let { pageMap, currentPage } = this.props

    const CurCom = COMPONENTS[curIndex]
    const id = _.get(this.props, 'location.state.id')

    const btnArr = [{
      text: '数据库',
      priv: 'getAllTasks'
    }, {
      text: '日志',
      priv: 'getCollectConfigList'
    }].filter(i => hasPriv(i.priv))

    return (
      <div className="page-main__wrap data-access">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '数据采集地',
              path: '/admin/dataCollect/dataManagement'
            }, {
              title: '数据接入',
              path: curIndex === 1 ? '/admin/dataCollect/dataManagement?tab=log' : '/admin/dataCollect/dataManagement'
            }, {
              title: '数据接入'
            }
          ]}
        />

        <div className="data-access__main-wrap">
          {
            currentPage === pageMap.main &&
            <>
              <div className="data-access__header">
                <span className="number-title__one"></span>
                <span className="config-title">接入类型</span>
                {
                  btnArr.length > 0 &&
                  btnArr.map(i => i.text).map((item, index) => (
                    <span key={item} style={{ marginRight: 16 }}>
                      <SdwButton
                        type={curIndex === index ? 'submit' : 'cancel'}
                        text={item}
                        disabled={id} // 有id表示编辑：不可以切换
                        onClick={() => this.setState({ curIndex: index })}
                      />
                    </span>
                  ))
                }
              </div>

              {/* 这里根据上面的选项来确定展示哪个组件 */}
              {
                CurCom && <CurCom {...this.props} />
              }
            </>
          }

          {
            currentPage === pageMap.logSecondPage &&
            <DataAccessTest {...this.props} />
          }

        </div>
      </div>
    )
  }
}

const mapState = state => ({
  pageMap: state.dataAccess.pageMap,
  currentPage: state.dataAccess.currentPage,
})

const mapDispatch = dispatch => ({
  updateCurrentPage: dispatch.dataAccess.updateCurrentPage
})

export default connect(mapState, mapDispatch)(DataAccess)
