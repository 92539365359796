import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwCheckbox from '@common/Checkbox'
import SdwEmpty from '@common/Empty'
import SdwButton from '@common/Button'
import { checkTarget } from '@services/api'
import './style/index.css'
import SdwInput from '@common/Input'
import SdwMessage from '@common/Message'

class IndicatorAttributes extends React.PureComponent {

  state = {
    targetDimension:[],
    targetTime:[],
    filterStr:"",
    curShowSelectedTarget:[],
  }

  componentDidMount() {
    let {targetDimension, targetTime, enterIndexDetailEdit} = this.props
    if (enterIndexDetailEdit) {
      this.setState({
        targetDimension:[],
        targetTime:[]
      })
    } else {
      this.setState({
        targetDimension:targetDimension,
        targetTime:targetTime
      })
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.targetDimension != this.props.targetDimension ||
      prevProps.targetTime != this.props.targetTime||
      prevProps.filterStr != this.props.filterStr
    ) {
      let {targetDimension, targetTime, filterStr} = this.props
      this.setState({
        targetDimension:targetDimension,
        targetTime:targetTime,
        filterStr:filterStr
      })
      this.setState({
        targetDimension:targetDimension,
        targetTime:targetTime,
        filterStr:filterStr
      })
    }
  }

  updateSelectedTarget = (targetTime, targetDimension) => {
    //let { curShowSelectedTarget, curClickTreeValue, currentData, apiTemplateClickTreeValue } = this.props
    let { currentData, apiTemplateClickTreeValue, curClickTreeValue } = this.props
    let { curShowSelectedTarget} = this.state
    console.log("========indicatorA====currentData", currentData)
    console.log("========indicatorA====apiTemplateClickTreeValue", apiTemplateClickTreeValue)
    console.log("========indicatorA====curClickTreeValue", curClickTreeValue)
    let checkedTime = targetTime.filter(i => i.isChecked)
    let checkedDimension = targetDimension.filter(i => i.isChecked)

    if (!checkedTime.length || !checkedDimension.length) {
      this.updateCurShowSelectedTarget([])
      return
    }

    let addTarget = []
    checkedTime.forEach(time => {
      checkedDimension.forEach(dimen => {
        let oriTarget = curShowSelectedTarget[curShowSelectedTarget.findIndex(i => i.time === time.id && i.dimension === dimen.id)] || {}
        addTarget.push({
          'template_id': currentData.id,
          'target_id': curClickTreeValue.id,
          'dimension_value': dimen.name_en,
          'time_cycle': time.id,
          'is_edit_target': 0,
          //下面的参数是不需要的 不删除是因为担心后面需要又要加上
          'name_cn': curClickTreeValue.target_name_cn,
          'name': curClickTreeValue.target_name,
          'target_type': oriTarget.target_type || 1,  // 1:普通指标 2:top指标
          'target_top': (oriTarget.top_count || typeof oriTarget.top_count === 'number') ? oriTarget.top_count : null
        })
      })
    })
    this.updateCurShowSelectedTarget(addTarget)
  }
  updateCurShowSelectedTarget = (curShowSelectedTarget) => {
    this.setState({
      curShowSelectedTarget:curShowSelectedTarget
    })
  }
  updateTargetDimension = (targetDimension) => {
    this.setState({
      targetDimension:targetDimension
    })
  }
  updateTargetTime = (targetTime) => {
    this.setState({
      targetTime:targetTime
    })
  }
  updateFilterStr = (filterStr) => {
    this.setState({
      filterStr:filterStr
    })
  }
  onclickSubmit = () => {

    let {curShowSelectedTarget, filterStr} = this.state
    let { currentData, curClickTreeValue} = this.props
    if (curShowSelectedTarget.length == 0) {
      SdwMessage.error("请先完善数据后再执行添加操作")
      return
    }
    let params = {
      "template_id": currentData.id,   //模块id
      "target_id": curClickTreeValue.id,   //指标id
      "time_cycles": [],   //时间周期，数组多选
      "dimension_values": [],   //维度，数组多选
      "filter_str": filterStr   //过滤
    }
    let curShowSelectedTargetClone = _.cloneDeep(curShowSelectedTarget)
    for (let i in curShowSelectedTargetClone) {
      params.time_cycles.push(curShowSelectedTargetClone[i].time_cycle)
      params.dimension_values.push(curShowSelectedTargetClone[i].dimension_value)
      curShowSelectedTargetClone[i].filter_str = filterStr
    }

    checkTarget({
      ...params
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          console.log("===========submit", curShowSelectedTargetClone)
          this.props.updateCurShowSelectedTarget(curShowSelectedTargetClone)
        } else {
          SdwMessage.error("该模块已有相同条件数据")
        }
      }, () => SdwMessage.error("接口异常"))

  }
  render () {
    let {targetDimension, targetTime} = this.state
    let {filterStr} = this.state
    return (
      <>
        <div className="title">指标属性</div>

        <div className="label-box">
          <div className="label-box__title">时间</div>
          <div className="label-box__main">
            {
              (_.isArray(targetTime) && !!targetTime.length) ?
              targetTime.map((item, index) => (
                <div key={index} className="application-detail__checkbox-wrap">
                  <SdwCheckbox
                    text={item.name}
                    isChecked={item.isChecked}
                    changeCheckedFun={() => {
                      let curArr = _.cloneDeep(targetTime)
                      curArr[index].isChecked = !curArr[index].isChecked
                      this.updateTargetTime(curArr)
                      this.updateSelectedTarget(curArr, targetDimension)
                    }}
                  />
                </div>
              )) :
                  <SdwEmpty
                    height="160px"
                    text="请从左侧选择指标"
                    hideImage={true}
                  />
            }
          </div>
        </div>

        <div className="label-box">
          <div className="label-box__title">维度</div>
          <div className="label-box__main">
            {
              (_.isArray(targetDimension) && !!targetDimension.length) ?
              targetDimension.map((item, index) => (
                <div key={index} className="application-detail__checkbox-wrap">
                  <SdwCheckbox
                    text={item.name}
                    isChecked={item.isChecked}
                    changeCheckedFun={() => {
                      let curArr = _.cloneDeep(targetDimension)
                      curArr[index].isChecked = !curArr[index].isChecked
                      this.updateTargetDimension(curArr)
                      this.updateSelectedTarget(targetTime, curArr)
                    }}
                  />
                </div>
              )) :
                <SdwEmpty
                  height="160px"
                  text="请从左侧选择指标"
                  hideImage={true}
                />
            }
          </div>
        </div>

        <div className="label-box">
          <div className="label-box__title">过滤</div>
          <div>
            <SdwInput
              width={302}
              type="textarea"
              value={filterStr}
              placeholder="请输入"
              onChange={val => this.updateFilterStr(val)}
            />
          </div>
        </div>

        <div className="label-box">
          <div className="label-box__button_add">
            <SdwButton
                type="submit"
                iconClass=""
                text="添加"
                onClick={this.onclickSubmit}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapState = state => ({
  targetTime: state.apiIndex.targetTime,
  targetDimension: state.apiIndex.targetDimension,
  curShowSelectedTarget: state.apiIndex.curShowSelectedTarget,
  curClickTreeValue: state.apiIndex.curClickTreeValue,
  apiTemplateClickTreeValue: state.apiIndex.apiTemplateClickTreeValue,
  filterStr: state.apiIndex.filterStr,
  currentData: state.apiIndex.currentData,
})

const mapDispatch = dispatch => ({
  updateTargetTime: dispatch.apiIndex.updateTargetTime,
  updateTargetDimension: dispatch.apiIndex.updateTargetDimension,
  updateCurShowSelectedTarget: dispatch.apiIndex.updateCurShowSelectedTarget,
  updateApiTemplateClickTreeValue: dispatch.apiIndex.updateApiTemplateClickTreeValue,
  updateFilterStr: dispatch.apiIndex.updateFilterStr,
  updateCurrentData: dispatch.apiIndex.updateCurrentData,
})

export default connect(mapState, mapDispatch)(IndicatorAttributes)
