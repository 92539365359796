import React, { PureComponent } from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import ReactJson from 'react-json-view'

import 'element-theme-default'
import './style/index.css'
import { getTargetResults } from '@api/Derived'
import SdwMessage from '@common/Message'
import { getUserTraceDetail } from '@customHooks/api'
import SdwLayout from '@common/Layout'
import SdwInput from '@common/Input'
import Select from '@common/Select'
import { accountMap } from '@pages/Monitor/UserSearch/common'
import Input from '@common/Input'
import Button from '@common/Button'


class DisplayTraceDetail extends PureComponent {

  state = {
    jsonData:{},
    jsonDataStr:"",
    account:"",
    accountType:"",
  }

  componentDidMount() {
    this.getUserTraceDetail()

  }


  getUserTraceDetail = () => {
    let {account, accountType} = this.props
    let params = {
      account:account,
      accountType:accountType
    }
    console.log("---------", params)
    getUserTraceDetail(params).then(res => {
      if (res.data.code === 0) {
        let jsonData = res.data.data || ""
        let jsonDataStr = JSON.stringify(jsonData)
        console.log("----res---jsonDataStr", jsonDataStr)
        this.setState({
          jsonData:jsonData,
          jsonDataStr:jsonDataStr,
        })
      } else {
        SdwMessage.error("查询异常：" + (res.data.msg || "请求超时"))
      }
    })
  }
  render () {
    let {jsonData, jsonDataStr, accountType, account} = this.state
    //jsonData = {}
    let {visible} = this.props
    //console.log("-----jsonDataStr", jsonDataStr)
    return (
      <div style={{marginTop: 15}}>
        <SdwDrawer
          title={"轨迹详情"}
          visible={visible}
          onIconClick={this.props.onCancelClick}
          onCancelClick={this.props.onCancelClick}
          onSureClick={this.props.onCancelClick}

        >
          <SdwDrawer.Body>
           <div style={{width:"100%"}}>
             <div className="warning-title">数据量较大，请耐心等待加载完成</div>
             <div  className="modal" style={{height:"650px"}}>
               <ReactJson
                 name={null}
                 //theme="isotope"
                 collapsed={4}
                 displayObjectSize={false}
                 //collapseStringsAfterLength={10}
                 displayDataTypes={false}
                 src={jsonData}
                 //enableClipboard={false}
                 shouldCollapse={false}
               />
             </div>
           </div>
          </SdwDrawer.Body>
        </SdwDrawer>
      </div>

    )
  }
}

export default DisplayTraceDetail
