import React from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import SdwTreeNew from '@common/TreeNew'
import SdwMessage from '@common/Message'
import {getTemplateConfList, saveTemplateConf, delTemplateConf} from '@services/api'
import DialogShowTemplateConfig from './DialogShowTemplateConfig'
import Dialog from '@common/Dialog'
import AddApiWin from '../AddApiWin'
import editImg from './img/icon_16_bj@2x.png'
import {hasPriv} from '@components/FrontendAuth/AuthMap'
import './style/index.css'

class TagTree extends React.PureComponent {

    state = {
        allTargetData: [],
        checkedTargetIDs: [],
        treeOptions: {
            children: 'children',
            label: 'templateName'
        },
        dialogEditVisible: false, //编辑API信息的弹框
        dialogVisible: false, //账号专区API的弹框
        dialogDelVisible: false, //删除账号专区API的弹框
        templateConfItem: {},
        runCycleData: [],
    }

    componentDidMount() {
        this.getTemplateList()
        // this.getTopicList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 总的指标有所更改,界面需要标记出已编辑的指标
        if (prevProps.currentData !== this.props.currentData) {
            this.getTemplateList()
        }
    }

    getTemplateList = () => {
        let currentData = this.props.currentData
        //console.log("TagTree===============apiDetail_---tagTree", currentData)
        if (!!currentData && !!currentData.id) {
            getTemplateConfList({
                api_id: currentData.id
            }).then(res => {
                if (res && res.data && res.data.code === 0) {
                    let targetData = res.data.data || []
                    targetData.map(i => {
                        i.isAddIconShow = true
                        i.isEditIconShow = true
                        i.isDelIconShow = true
                        i.templateName = i.template_name
                        if (i.children != null) {
                            i.children.map(j => {
                                j.isAddIconShow = true
                                j.isEditIconShow = true
                                j.isDelIconShow = true
                                j.templateName = `${j.template_name}（id:${j.id}）`
                                return j
                            })
                        }
                        return i
                    })
                    this.setState({
                        templateConfItem: {
                            "sholdCheckValidate": false,
                            "api_id": currentData.id,
                            "id": 0,
                            "data": {
                                "pid": 0,
                                "template_name": "页面",
                            }
                        },
                        allTargetData: targetData
                    })
                }
            }, () => SdwMessage.error("接口异常"))
        }
    }

    // getTopicList = () => {
    //   let curTopicData = this.props.curTopicData
    // }

    addTreeCustomClass = (list, ids) => {
        return list.map(item => {
            let curItem = _.cloneDeep(item)
            if (item.id && ids.includes(item.id)) {
                curItem = {
                    ...curItem,
                    customClass: 'sdw-application-detail__tree-is-edit'
                }
            } else {
                curItem = {
                    ...curItem,
                    customClass: ''
                }
            }

            if (item.children && item.children.length) {
                curItem.children = this.addTreeCustomClass(item.children, ids)
            }

            return curItem
        })
    }

    //修改账号专区API的方法
    changeCurClickTreeValue = val => {
        if (val.tree_level == 1) {
            this.props.updateCurClickTreeValue(val)
        }
    }

    operateFun = (type, item) => {
        let templateConfItem = {
            "sholdCheckValidate": false,
            "api_id": item.api_id,
            "id": 0,
            "data": {
                "pid": item.pid,
                "template_name": item.template_name,
            }
        }
        switch (type) {
            case "add":
                if (!item.pid) {
                    templateConfItem.data.template_name = "页面"
                } else {
                    templateConfItem.data.template_name = "模块"
                }
                break
            case "edit":
                templateConfItem.id = item.id
                break
            case "del":
                templateConfItem.id = item.id
                this.delTemplateConf(true)
                break
        }
        if (type == "add" || type == "edit") {
            this.addTemplateConf(true)
        }
        this.setState({
            templateConfItem: templateConfItem,
        })
    }

    saveTemplate = () => {
        let item = {...this.state.templateConfItem}
        if (!item.data.template_name) {
            item.sholdCheckValidate = true
            return
        }
        saveTemplateConf({
            "api_id": item.api_id,
            ...!!item.id ? {
                id: item.id
            } : {},
            "data": {
                "template_name": item.data.template_name,
                "pid": item.data.pid
            }
        }).then(res => {
            if (res && res.data && res.data.code === 0) {
                this.getTemplateList()
                this.addTemplateConf(false)
            } else {
                SdwMessage.error(res.data.msg)
            }
        }, () => SdwMessage.error("接口异常"))
    }

    //删除页面
    delTemplate = () => {
        let item = {...this.state.templateConfItem}
        if (!item.id) {
            return
        }
        delTemplateConf({
            "id": item.id,
        }).then(res => {
            if (res && res.data && res.data.code === 0) {
                this.getTemplateList()
                this.props.updatePageApiTargets([])
                this.delTemplateConf(false)
            } else {
                SdwMessage.error(res.data.msg)
            }
        }, () => SdwMessage.error("接口异常"))
    }

    //弹框-编辑页面
    addTemplateConf = val => {
        this.setState({
            dialogVisible: val
        })
    }

    //弹框-删除页面
    delTemplateConf = val => {
        this.setState({
            dialogDelVisible: val
        })
    }

    //过滤型-设置数据
    setTemplateConfValue = (type, value) => {
        let templateConfItem = {...this.state.templateConfItem}
        let templateConfItemOne = _.cloneDeep(templateConfItem)
        switch (type) {
            case "template_name":
                templateConfItemOne.data.template_name = value
                break
        }
        templateConfItemOne.sholdCheckValidate = true
        this.setState({
            templateConfItem: templateConfItemOne
        })
    }

    //编辑API信息弹窗
    changeDialogVisible = flag => {
        this.setState({
            dialogEditVisible: flag
        })
    }

    iniPropsData = () => {
        this.props.initData()
    }

    render() {
        let {
            templateConfItem,
            allTargetData,
            treeOptions,
            dialogEditVisible, //编辑API信息弹框
            dialogVisible, //添加账号专区API弹框
            dialogDelVisible, //删除账号专区API弹框
        } = this.state

        let {currentData, curTopicData, cfgData} = this.props

        let runCycleData = cfgData["runCycle"]

        let topicName = ""
        let topicServiceName = ""
        curTopicData.forEach(item => {
            if (parseInt(item.id) == currentData.topic) {
                topicName = item.text
                for (let i = 0; i < item.children.length; i++) {
                    let sid = parseInt(item.children[i].value)
                    let label = item.children[i].label
                    if (sid == currentData.service) {
                        topicServiceName = label
                    }
                }
            }
        })
        currentData.topicBusiness = {
            "ids": [currentData.topic, currentData.service],
            "texts": [topicName, topicServiceName],
            "clickItem": {id: currentData.service, text: topicServiceName},
        }
        let curRunCycleData = []
        if (runCycleData) {
            curRunCycleData = runCycleData.map(i => {
                return {
                    id: i.id,
                    text: i.name
                }
            })
        }
        // 总的指标
        //console.log("TagTree====+==========apiDetail___tagTree---allTargetData", allTargetData)
        return (
            <>
                <div className="title" style={{position: 'relative'}}>
                    <span>{currentData.table_name_cn}（{currentData.table_name}）</span>
                    {
                        hasPriv('updateAppConf') &&
                        <img
                            className="sdw-edit__img"
                            src={editImg}
                            onClick={() => this.changeDialogVisible(true)}
                        ></img>
                    }
                </div>

                <div style={{marginTop: '16px', height: '100%'}}>
                    {
                        allTargetData.length == 0 ?
                            <span className='sdw-collapse-listitem-plus' onClick={() => {
                                this.addTemplateConf(true)
                            }}>添加</span> :
                            <SdwTreeNew
                                showFilterInput={true}
                                emptyChildrenIsShow={true}
                                operateFun={this.operateFun}
                                filterInputWidth={298}
                                data={allTargetData}
                                options={treeOptions}
                                inputPlaceholder="请输入"
                                changeValue={this.changeCurClickTreeValue}
                            />
                    }

                    {/**编辑API信息弹窗 */}
                    {
                        dialogEditVisible && <AddApiWin
                            dialogVisible={dialogEditVisible}
                            runCycleData={curRunCycleData}
                            currentData={currentData}
                            initData={this.iniPropsData}
                            changeDialogVisible={this.changeDialogVisible}
                            isEdit={true}
                        />
                    }

                    {/**编辑账号专区弹窗 */}
                    {
                        dialogVisible &&
                        <DialogShowTemplateConfig
                            dialogVisible={dialogVisible}
                            item={templateConfItem}
                            handleSubmit={this.saveTemplate}
                            setTemplateConfValue={this.setTemplateConfValue}
                            closeDialog={() => {
                                this.addTemplateConf(false)
                            }}
                        />
                    }
                    {/**删除账号专区弹窗 */}
                    {
                        dialogDelVisible &&
                        <Dialog
                            title='提示'
                            width={400}
                            visible={dialogDelVisible}
                            changeVisible={this.delTemplateConf}
                            onCancelClick={() => this.delTemplateConf(false)}
                            onSureClick={this.delTemplate}
                        >
                            <div><span>{templateConfItem.data.template_name}</span>将被删除，是否确认</div>
                        </Dialog>
                    }
                </div>
            </>
        )
    }
}

const mapState = state => ({
    pageApiTargets: state.api.pageApiTargets,
    curClickTreeValue: state.api.curClickTreeValue,
})

const mapDispatch = dispatch => ({
    updatePageApiTargets: dispatch.api.updatePageApiTargets,
    updateCurClickTreeValue: dispatch.api.updateCurClickTreeValue,
})

export default connect(mapState, mapDispatch)(TagTree)
