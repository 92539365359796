// 元数据管理相关 redux
import produce from 'immer'

const state = {
  databaseInfo: {},
  publicCfgInfo: {}
}

const updateDatabaseInfo = produce((state, payload) => {
  state.databaseInfo = payload
})

const updatePublicCfgInfo = produce((state, payload) => {
  state.publicCfgInfo = payload
})

const reducers = {
  updateDatabaseInfo,
  updatePublicCfgInfo
}

export default { state, reducers }
