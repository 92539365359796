// 对应下发参数
export const LEVEL_TYPE_MAP = {
  DIM: 'DIM',
  ODS: 'ODS',
  DWD: 'DWD',
  DWS: 'DWS',
  APP: 'APP'
}

export const LEVEL_TEXT_MAP = {
  [LEVEL_TYPE_MAP.DIM]: 'DIM（维度层）',
  [LEVEL_TYPE_MAP.ODS]: 'ODS（贴源层）',
  [LEVEL_TYPE_MAP.DWD]: 'DWD（明细层）',
  [LEVEL_TYPE_MAP.DWS]: 'DWS（汇总层）',
  [LEVEL_TYPE_MAP.APP]: 'APP（应用层）'
}

export const ALL = 'all'

export const PENDING = 'pending'

export const STATE_MAP = {
  0: '审批中',
  1: '通过',
  2: '驳回'
}

// 修改点：map映射关系
export const UPDATE_CONTEXT_MAP = {
  target_name: '英文名',
  target_name_cn: '中文名',
  target_type: '指标类型',
  source_targets: '原子指标',
  decoration: '修饰词',
  decoration_ids: '修饰词id',
  group_by_type: '桶类型',
  group_by_field: '聚合字段',
  metric_type: '计算公式',
  metric_field: '度量字段',
  complex_script: '复合方式',
  enable: '是否启用',
  create_user: '创建人',
  create_time: '创建时间',
  modify_user: '修改人',
  modify_time: '修改时间',
  depth: '指标依赖层级',
  store_dimension: '关联维度',
  field_ref: '多个字段逗号隔开',
  topic: '指标主题',
  comments: '指标描述',
  target_unit: '单位',
  service: '所属业务',
  time_schedule: '时间周期',
  cal_fields: '派生复合指标计算字段',
  cal_expression: '派生复合指标计算公式',
  store_dimension_cn: '中文名',
  empty_drop: '是否空值删除（0否，1是）',
  delay_expression: '延时重跑计算公式JsonList',
  granular_category: '颗粒化类别',
  is_granular: '是否颗粒化指标',
  topicBusiness: '主题域/业务',
}

export const TARGET_TYPE_MAP = {
  1: '基础型',
  2: '复合型',
  3: '代码型',
}

export const MSG_TYPE_MAP = {
  '1': '指标新增',
  '2': '指标修改',
  '3': '指标重跑',
}