// 暴露一些公共函数

/**
 * 用于统一处理是否显示 title 的函数，一般比较短的 string 没必要悬浮提示
 * @param {String} str 传入的字符串
 * @param {Number} len 长度
 * @returns 默认20，超过len的长度的字符串返回字符串本身，否则返回 ''
 */
export function getTitle(str, len = 20) {
  return String(str).length > len ? str : ''
}

/**
 * 防抖函数
 * @param {Function} fn 传入的防抖函数
 * @param {Number}} t 毫秒数
 */
let timer = null
export function debounce(fn, t = 500) {

  if (typeof fn !== 'function') {
    throw TypeError('fn is not function')
  }

  return function () {
    const ags = [...arguments]

    // 清除上一个timer，防抖
    if (timer) clearTimeout(timer)

    timer = setTimeout(() => {
      fn.apply(this, ags)

      // 清除本次timer，防内存泄漏
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }, t);
  }
}