/* eslint-disable */
const {
  REACT_APP_HTML_PREFIX,
  REACT_APP_WEB_PREFIX,
  REACT_APP_YZF_PREFIX,
  REACT_APP_LOG_PREFIX,
  REACT_APP_WEBSOCKET_PREFIX
} = process.env || {}

// Log process.env to window for debug
window.__app_env__ = process.env

export const routerOptions = {
  basename: REACT_APP_HTML_PREFIX, // 页面挂载路由地址
  // forceRefresh: '',
  // getUserConfirmation: '',
  // keyLength: '',
}
var k_host = window.location.host
let hostUrl = REACT_APP_WEB_PREFIX
if (k_host == "sdw.oa.com") {
  hostUrl = "http://sdw.oa.com"
}
// console.log("====k_host", hostUrl)

export const requestOptions = {
  basename: hostUrl, // 接口请求前缀
}

export const requestOptionsYZF = {
  basename: REACT_APP_YZF_PREFIX, // 云智服接口请求前缀
}

export const requestOptionsLOG = {
  basename: REACT_APP_LOG_PREFIX, // 日志接入模块接口请求前缀
}

export const requestOptionsWEBSOCKET = {
  basename: REACT_APP_WEBSOCKET_PREFIX, // WebSocket接口请求前缀
}
