import React, { Component } from 'react'
import _ from 'lodash'
import SdwInput from '@common/Input'
import './index.css'

class InputTip extends Component {

  inputRef = React.createRef()

  static defaultProps = {
    onClick: _.noop,
    options: []
  }

  state = {
    showOptions: false
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocClick)
  }

  onDocClick = () => {
    if (!this.state.showOptions) return
    this.inputRef.current.handleChange({target: {value: ''}})
    this.setState({ showOptions: false })
  }

  handleFocus = () => {
    this.setState({ showOptions: true })
  }

  handleClick = item => {
    this.props.onClick(item)
    this.setState({ showOptions: false })
  }

  render() {
    const { options, value } = this.props
    const { showOptions } = this.state

    return (
      <div 
        className='sdw-input-tip__wrap' 
        style={{ width: this.props.width }}
        onClick={e => e.stopPropagation()}
      >
        <SdwInput
          ref={this.inputRef}
          {...this.props}
          onFocus={this.handleFocus}
        />
        {
          showOptions &&
          <>
            {
              _.isArray(options) &&
              options.length > 0 ?
              <div className='sdw-input-tip__wrap--tip-div'>
                {
                  options.map(item => (
                    <div
                      key={item.id} 
                      className={`sdw-input-tip__wrap--tip-div--item ${value === item.text ? 'current' : ''}`} 
                      onClick={() => this.handleClick(item)} 
                    >{item.text}</div>
                  ))
                }
              </div> :
              <div className='sdw-input-tip__wrap--tip-div'>
                <div className='sdw-input-tip__wrap--tip-div--item'>暂无匹配项</div>
              </div>
            }
          </>
        }
      </div>
    )
  }
}

export default InputTip