export default function copyToClipboard(value) {
  const input = document.createElement('input')
  input.style.position = 'absolute'
  input.style.left = '10000px'
  input.style.bottom = '10000px'
  input.value = value
  document.body.append(input)
  input.select()
  document.execCommand('copy')
  input.parentNode.removeChild(input)
}
