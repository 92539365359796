import { request } from '../utils/request'

export function GetHotEventMap (params) {
  //http://p.cm.com/dataset/process/GetHotEventMap?start_time=2021-01-20
  return request({ url: '/dataset/process/GetHotEventMap', method: 'get', params: params })
}

export function GetHotEventChooseList (params) {
  return request({ url: '/dataset/process/GetHotEventChooseList', method: 'get', params: params })
}

export function GetHotEventOne (params) {
  return request({ url: '/dataset/process/GetHotEventOne', method: 'get', params: params })
}

export function GetHotEventUrl (params) {
  return request({ url: '/dataset/process/GetHotEventUrl', method: 'get', params: params })
}
