import { formatJsonParse } from "@utils/formatJson"
import _ from "lodash"
import { Fragment, useMemo } from "react"
import './index.css'

const trajectMap = {
  ALLINONE: [
    'aio_open_num', // 打开台本【x】次
    'aio_create_num', // 提单【x】次
    'aio_reject1_num', // 资料驳回【x】次
    'aio_reject2_num', // 坐实驳回【x】次
    'aio_release1_num', // 客服解除【x】次
    'aio_release2_num', // 客服解封【x】次
  ],
  IVR: [
    // 'ivr_request_skill_num', // 请求【x】技能组【x】次
    // 'ivr_service_skill_num', // 接入【x】技能组【x】次
    'ivr_re_se_skill_num', // 【x技能组】 请求【x】次 接入【x】次
    'ivr_tool_num', // 下发工具【x】次
  ],
  AI: [
    'ai_l4_num', // 命中【x】【x】次
    'ai_risk_num', // 进入风险看板【x】次
  ],
  FAQ: [
    'faq_service_num', // 访问【x】【x】次
  ],
  WSS: [
    'wss_service_num', // 访问【x】【x】次
  ],
  ASYNC: [
    'async_risk_num', // 访问【x】【x】次
  ]
}

// 不为0或Nan的数字类型: 返回bool值
function isEffectNumber(num) {
  return !!num && typeof Number(num) === 'number' && !isNaN(Number(num)) && Number(num) !==0
}

function getText(key, p1, p2) {
  if (!key) return ''
  let res = ''
  switch (key) {
    case 'aio_open_num':
      res = <span>打开台本<span className='blue-text'> {p1} </span>次</span>
      break;

    case 'aio_create_num':
      res = <span>提单<span className='blue-text'> {p1} </span>次</span>
      break;

    case 'aio_reject1_num':
      res = <span>资料驳回<span className='blue-text'> {p1} </span>次</span>
      break;

    case 'aio_reject2_num':
      res = <span>坐实驳回<span className='blue-text'> {p1} </span>次</span>
      break;

    case 'aio_release1_num':
      res = <span>客服解除<span className='blue-text'> {p1} </span>次</span>
      break;

    case 'aio_release2_num':
      res = <span>客服解封<span className='blue-text'> {p1} </span>次</span>
      break;

    // case 'ivr_request_skill_num':
    //   res = <span><span className='gray'>请求</span>{p1}<span className='blue-text'> {p2} </span>次</span>
    //   break;

    // case 'ivr_service_skill_num':
    //   res = <span><span className='gray'>接入</span>{p1}<span className='blue-text'> {p2} </span>次</span>
    //   break;
    // 'ivr_re_se_skill_num', // 【x技能组】 请求【x】次 接入【x】次
    case 'ivr_re_se_skill_num':
      // console.log('---------------------p2: ', p2);
      const arr = String(p2).split(',')
      // console.log('---------------------arr: ',arr);
      res = <span>{p1}{
        isEffectNumber(arr[0]) && <><span className='gray'> 请求</span><span className='blue-text'>{+arr[0]} </span>次</>
      }{
        isEffectNumber(arr[1]) && <><span className='gray'> 接入</span><span className='blue-text'>{+arr[1]} </span>次</>
      }</span>
      break;

    case 'ivr_tool_num':
      res = <span><span className='gray'>下发工具</span><span className='blue-text'> {p1} </span>次</span>
      break;

    case 'ai_l4_num':
      res = <span><span className='gray'>命中</span>{p1}<span className='blue-text'> {p2} </span>次</span>
      break;

    case 'ai_risk_num':
      res = <span>进入风险看板<span className='blue-text'> {p1} </span>次</span>
      break;

    case 'faq_service_num':
      res = <span><span className='gray'>访问</span>{p1}<span className='blue-text'> {p2} </span>次</span>
      break;

    case 'wss_service_num':
      res = <span><span className='gray'>访问</span>{p1}<span className='blue-text'> {p2} </span>次</span>
      break;
    case 'async_risk_num':
      res = <span><span className='gray'>访问</span>{p1}<span className='blue-text'> {p2} </span>次</span>
      break;
    default:
      break;
  }

  return res
}

const ALL = 'all'

export default function TrajectInfo(props) {
  const {
    callInfo = {},
    channelValue = ALL
  } = props

  const curRecord = useMemo(() => {
    return _.keys(callInfo).reduce((prev, key) => {
      const curItem = callInfo[key]
      if (typeof curItem === 'number' && curItem !== 0) {
        prev[key] = curItem
      } else if (typeof curItem === 'string') {
        const jsonparseItem = formatJsonParse(curItem, {})

        if (!_.isEmpty(jsonparseItem)) {
          prev[key] = jsonparseItem
        }
      }
      return prev
    }, {})
  }, [callInfo])

  // console.log('-------curRecord: ', curRecord);
  return (
    <>
      <div className='title'>近30天轨迹</div>
      <div className='desc'>
        {
          _.keys(trajectMap).map((key, index) => {
            const curTraject = channelValue === ALL ? trajectMap : {
              [channelValue]: trajectMap[channelValue]
            }

            return (
              <div key={key + index} className='service__traject-info__item-wrap'>
                {
                  _.isArray(curTraject[key]) && curTraject[key].some(tjkey => !!curRecord[tjkey]) &&
                  <div className='left-label'>{ key }</div>
                }
                <div>
                  {
                    _.isArray(curTraject[key]) &&
                    curTraject[key].map(childKey => {
                      const childRecord = curRecord[childKey]
                      let childTextArr = []
                      if (typeof childRecord === 'number') {
                        childTextArr = [getText(childKey, childRecord)]
                      } else if (!_.isEmpty(childRecord)) {
                        childTextArr = _.keys(childRecord).reduce((prev, crkey) => {
                          prev = prev.concat([getText(childKey, crkey, childRecord[crkey])])
                          return prev
                        }, [])
                      }
                      return (
                        _.isArray(childTextArr) &&
                        childTextArr.length > 0 &&
                        childTextArr.map((text, j) => (
                          <Fragment key={childKey + j}>
                            <span>{ text }</span>
                            <span>；</span>
                          </Fragment>
                        ))
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  )
}
