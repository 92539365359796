import React from 'react'
import Tabs from '@common/Tabs'
import Button from '@common/Button'
import Dialog from '@common/Dialog'
import BreadCrumbs from '@common/BreadCrumbs'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import BasicPage from '@pages/LabelProcess/UserLabel/UserLabelDetail/Basic'
import InfoPage from '@pages/LabelProcess/UserLabel/UserLabelDetail/Info'
import DetailPage from '@pages/LabelProcess/UserLabel/UserLabelDetail/Detail'
import LogPage from '@pages/LabelProcess/UserLabel/UserLabelDetail/Log'
import {searchDetail, switchStatus} from '@services/labelProcess'
import {getPublicCfg} from '@services/Derived'
import {hasPriv} from '@components/FrontendAuth/AuthMap'

import './index.css'
import editImg from '../img/icon_16_bj@2x.png'

const Menu = [
    {
        title: '基础信息',
        path: '/BasicPage',
        component: BasicPage,
    }, {
        title: '加工信息',
        path: '/InfoPage',
        component: InfoPage,
    }, {
        title: '运行详情',
        path: '/DetailPage',
        component: DetailPage
    }, {
        title: '变更记录',
        path: '/LogPage',
        component: LogPage
    }
]

class UserLabelDetail extends React.PureComponent {

    state = {
        visible: false,
        labelVisible: false,
        labelStopVisible: false,
        labelDetail: {
            rule_data: {}
        },
        cfgData: {},
    }

    componentDidMount() {
        this.initData()
        this.getPublicCfg()
    }

    initData = () => {
        let ID = this.props.match.params.id
        if (!!ID) this.searchDetail(ID)
    }

    getPublicCfg = () => {
        getPublicCfg().then(res => {
            if (res.data.code === 0 && res.data.data) {
                let cfgData = {}
                for (let i in res.data.data) {
                    cfgData[i] = res.data.data[i]
                }
                this.setState({cfgData: cfgData})
            }
        })
    }

    searchDetail = id => {
        let params = {
            id: id
        }
        searchDetail(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let labelDetail = res.data.data || {}
                // console.log("searchDetail----labelDetail", labelDetail)
                this.setState({
                    labelDetail: labelDetail
                })
            }
        })
    }

    submitTask = () => {
        let labelDetail = this.state.labelDetail
        if (labelDetail.is_test_pass === 0) { //测试未通过
            this.setState({
                visible: true
            })
        } else { //测试已通过
            this.setState({
                labelVisible: true
            })
        }
    }

    changeDialogVisible = (type, bool) => {
        let obj = {}
        switch (type) {
            case "visible":
                obj = {visible: bool}
                break
            case "labelVisible":
                obj = {labelVisible: bool}
                break
            case "labelStopVisible":
                obj = {labelStopVisible: bool}
                break
        }
        this.setState({
            ...obj
        })
    }

    jumpToEdit = id => {
        this.props.history.push()
        this.props.history.push({
            pathname: `/admin/labelProcess/userLabelWin/${id}`,
            state: {
                backPath: "detail"
            }
        })
    }

    useLabel = (type, status) => {
        let {labelDetail} = this.state
        let params = {
            id: labelDetail.id,
            status: status
        }
        switchStatus(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                this.searchDetail(labelDetail.id)
                this.changeDialogVisible(type, false)
            }
        })
    }

    render() {
        let {
            labelDetail,
            visible,
            labelVisible,
            labelStopVisible,
            cfgData
        } = this.state
        // console.log("-----------cfgData", cfgData)
        if (cfgData.hasOwnProperty("label_type")) {
            cfgData["label_type"].forEach(element => {
                if (element.value == labelDetail.label_type) {
                    labelDetail.label_type_ch = element.name
                }
            });
        }
        // console.log("-=-----labelDetail", labelDetail)
        // console.log("-=-----cfgData", cfgData)

        return (
            <div className='label-detail__wrap'>
                <BreadCrumbs
                    history={this.props.history}
                    data={[
                        {
                            title: '标签加工厂',
                            path: '/admin/labelProcess/ticketLabel'
                        }, {
                            title: '用户标签',
                            path: '/admin/labelProcess/userLabel'
                        }, {
                            title: '标签详情'
                        }
                    ]}
                />

                <div className='main-info__1' style={{marginTop: -16}}>
                    <BreadCrumbsAndTablde>
                        <BreadCrumbsAndTablde.LeftCrumbs>
                            <div className='main-info__title'>
                                <b style={{fontSize: '18px'}}>
                                    <span>{`${labelDetail.tag_name_ch}(${labelDetail.label_name})`}</span>
                                </b>
                                <Button
                                    text='编辑标签'
                                    onClick={() => this.jumpToEdit(labelDetail.id)}
                                ></Button>
                                <img
                                    className="main-info__img"
                                    src={editImg}
                                    onClick={() => this.jumpToEdit(labelDetail.id)}
                                ></img>

                                <div style={{fontSize: '14px', color: '#999999'}}>
                                    <span>{labelDetail.intro || '-'}</span>
                                </div>

                                <div className='main-info__wrap'>
                                    <span className='main-info__label'>实体：用户</span>
                                    <span
                                        className='main-info__label'>加工类型：{labelDetail.label_type_ch || '-'}</span>
                                </div>
                            </div>
                        </BreadCrumbsAndTablde.LeftCrumbs>

                        <BreadCrumbsAndTablde.RightCrumbs>
                            <div style={{margin: '20px'}}>
                                {
                                    labelDetail.enable == 1 ?
                                        <Button
                                            disabled={hasPriv("userLabelSwitchStatus") ? false : true}
                                            text='已启用'
                                            type='outline'
                                            onClick={() => {
                                                this.changeDialogVisible("labelStopVisible", !labelStopVisible)
                                            }}
                                        />
                                        :
                                        <Button
                                            disabled={hasPriv("userLabelSwitchStatus") ? false : true}
                                            text='启用标签'
                                            type='outline'
                                            onClick={this.submitTask}
                                        />
                                }

                            </div>
                        </BreadCrumbsAndTablde.RightCrumbs>
                    </BreadCrumbsAndTablde>
                </div>

                <div className='main-info__2' style={{marginTop: 16}}>
                    <Tabs
                        labelDetail={labelDetail}
                        menu={Menu}
                    />
                </div>

                <Dialog
                    title='启用标签'
                    width={500}
                    visible={visible}
                    //changeVisible={this.changeDialogVisible}
                    onCancelClick={() => this.changeDialogVisible("visible", !visible)}
                    onSureClick={() => this.changeDialogVisible("visible", !visible)}
                >
                    <div>暂未测试通过，请先完成调试</div>
                </Dialog>


                <Dialog
                    title='启用标签'
                    width={500}
                    visible={labelVisible}
                    onCancelClick={() => this.changeDialogVisible("labelVisible", !labelVisible)}
                    onSureClick={() => {
                        this.useLabel("labelVisible", true)
                    }}
                >
                    <div>确认后标签将进行例行采样，是否确认？</div>
                </Dialog>

                <Dialog
                    title='停止启用标签'
                    width={500}
                    visible={labelStopVisible}
                    onCancelClick={() => this.changeDialogVisible("labelStopVisible", !labelStopVisible)}
                    onSureClick={() => this.useLabel("labelStopVisible", false)}
                >
                    <div>确认后标签将停止例行采样，是否确认？</div>
                </Dialog>

            </div>
        )
    }
}

export default UserLabelDetail
