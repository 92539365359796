import { useState, useEffect } from 'react'
import Table from '@common/Table'
import Pagenation from '@common/Pagenation'
import Message from '@common/Message'
import { getTargetLogs } from '@services/Derived'
import { run_status_map } from '@pages/commomContext/derived'
import _ from 'lodash'

function getcolumns() {
  return [{
    title: "任务id",
    dataIndex: "id",
    width: 50
  }, {
    title: "指标id",
    dataIndex: "oper_id",
    width: 50
  }, {
    title: "提交人",
    dataIndex: "create_user",
    width: 70
  }, {
    title: "提交时间",
    dataIndex: "create_time",
    width: 100
  }, {
    title: "完成时间",
    dataIndex: "finish_time",
    width: 100
  }, {
    title: "运行状态",
    width: 50,
    render: data => <span>{run_status_map[data.status] || '-'}</span>
  }, {
    title: "运行时长(s)",
    dataIndex: "cost_time",
    width: 65
  }, {
    title: "入库记录",
    dataIndex: "result_total",
    width: 50
  }, {
    title: "入库日志",
    dataIndex: "run_result",
    width: 300
  }]
}

function LogPage(props) {

  const [curData, setCurData] = useState({})
  const [search, setSearch] = useState({
    limit: 10,
    page: 1
  })

  useEffect(() => {
    const curID = _.get(props, 'match.params.id')
    if (!curID) return
    getTargetLogs(_.assign({
      search: JSON.stringify({
        oper_id: {
          value: curID,
          operator: '='
        }
      })
    }, search)).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        if (_.isEmpty(data)) return
        setCurData(data)
      } else {
        const msg = _.get(res, 'data.msg') || '数据请求失败！'
        Message.error(msg)
      }
    }).catch(err => Message.error('接口异常 ' + err))
  }, [search, props.baseInfo])

  return (
    <div style={{ padding: 16 }}>
      <Table
        columns={getcolumns()}
        dataSource={ _.isArray(curData.items) && curData.items || [] }
      />

      <Pagenation
        total={curData.total}
        currentPage={curData.page}
        getClickPage={page => setSearch(r => _.assign({}, r, {page}))}
        pageCountChange={limit => setSearch(r => _.assign({}, r, {limit}))}
      />
    </div>
  )
}

export default LogPage
