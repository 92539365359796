import React from 'react'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwEnable from '@common/Enable'
import SdwButton from '@common/Button'
import SdwMessage from '@common/Message'
import SdwTable from '@common/Table'
import SdwPagination from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import CollectionWin from './StepWin/CollectionWin'
import {getStandardSelects} from '@services/Derived'
import {getBusinessStatisticByTopic} from '@services/dataStandars'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../index.css'
import {requestOptionsLOG} from '@config/config'
const baseURL = requestOptionsLOG.basename

class LogIdConfiguration extends React.PureComponent {

    state = {
        collectionVisible: false,
        tableData: {},
        topicData: [], // 主题域
        businessData: [], // 业务
        businessTopicId: '', // 已选主题域的id
        businessList: [], // 主题域对应的业务
        search: {
            page: 1,
            size: 10,
            cmd: '',
            inputTag: '',
            business: '',
            businessTopic: '',
            createRtx: ''
        },
        isEdit: false,
        currentItem: {}
    }

    componentDidMount() {
        this.queryInputTag()
        this.getStandardSelects()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.businessTopicId !== this.state.businessTopicId) {
            this.getBusinessStatisticByTopic()
        }
    }

    // 查询采集标识列表或采集标识详情信息
    queryInputTag = () => {
        let {search} = this.state
        let params = {}
        let rtx = this.props.userInfo.name
        for(let key in search) {
            if (search[key]) {
                params[key] = search[key]
            }
        }
        fetch(baseURL + '/sdw/filebeat/inputTag/queryInputTag',
            {
                method: "POST",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                let record = data.data || {}
                this.setState({
                    tableData: record
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 获取主题/业务列表
    getStandardSelects = () => {
        getStandardSelects().then((res) => {
            if (res && res.data && res.data.code === 0){
                let topicRecord = res.data.data || []
                this.setState({
                    topicData: topicRecord.topic,
                    businessData: topicRecord.business
                })
            }
        })
    }

    // 根据主题域ID请求对应的业务
    getBusinessStatisticByTopic = () => {
        let { businessTopicId } = this.state
        getBusinessStatisticByTopic({id: businessTopicId}).then(res => {
            if (res && res.data && res.data.code === 0) {
                let record = res.data.data && res.data.data.list.map(i => {
                    return {
                        id: i.business_name,
                        text: i.business_name
                    }
                }) || []
                this.setState({
                    businessList: record
                })
            }
        })
    }

    getColumns = () => {
        let self = this
        return [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     width: 34
            // },
            {
                title: 'CMD',
                dataIndex: 'cmd',
                width: 42
            },
            {
                title: '采集标识',
                dataIndex: 'inputTag',
                width: 164
            },
            {
                title: '中文名',
                dataIndex: 'inputName',
                width: 180
            },
            {
                title: '主题域',
                dataIndex: 'businessTopic',
                width: 90
            },
            {
                title: '业务',
                dataIndex: 'business',
                width: 100
            },
            {
                title: '解析配置',
                width: 90,
                render: data => (
                    <>
                        <SdwButton
                            disabled={data.parseStatus}
                            text={data.parseStatus ? '已配置' : '点击配置'}
                            onClick={() => self.jumpToTabs('resolve')}
                        />
                    </>
                )
            },
            {
                title: '分发配置',
                width: 90,
                render: data => (
                    <>
                        <SdwButton
                            disabled={data.deliveryStatus}
                            text={data.deliveryStatus ? '已配置' : '点击配置'}
                            onClick={() => self.jumpToTabs('resolve')}
                        />
                    </>
                )
            },
            {
                title: '日志文件配置',
                width: 90,
                render: data => (
                    <>
                        <SdwButton
                            disabled={data.accessFileStatus}
                            text={data.accessFileStatus ? '已配置' : '点击配置'}
                            onClick={() => self.jumpToTabs('collection')}
                        />
                    </>
                )
            },
            {
                title: '采集任务配置',
                width: 90,
                render: data => (
                    <>
                        <SdwButton
                            disabled={data.accessStatus}
                            text={data.accessStatus ? '已配置' : '点击配置'}
                            onClick={() => self.jumpToTabs('collection')}
                        />
                    </>
                )
            },
            // {
            //     title: '是否已使用',
            //     render: data => (
            //         <span className={
            //             Boolean(data.used) ? 'enable-comp_is-effect' : 'enable-comp_no-effect'
            //         }>
            //             {Boolean(data.used) ? '已使用' : '未使用'}
            //         </span>
            //     ),
            //     width: 70
            // },
            {
                title: '是否有效',
                render: data => <SdwEnable enable={data.enable} />,
                width: 70
            },
            // {
            //     title: '负责人',
            //     dataIndex: 'principal',
            //     width: 96
            // },
            {
                title: '修改人',
                dataIndex: 'updateRtx',
                width: 96
            },
            {
                title: '修改时间',
                dataIndex: 'updateTime',
                width: 148
            },
            {
                title: '操作',
                width: 50,
                render: data => (
                    <>
                        <SdwButton
                            text='编辑'
                            onClick={() => self.collectionAddOrEdit(true, data)}
                        />
                    </>
                )
            }
        ]
    }

    setSearch = (type, val) => {
        let {search} = this.state
        let obj = {}
        obj[type] = val
        if (type != 'page') {
            obj['page'] = 1
        }
        let searchClone = Object.assign({}, search, obj)
        this.setState({
            search: searchClone
        }, ()=>{
            this.queryInputTag()
        })
    }

    // 新增或编辑采集标识弹窗
    collectionAddOrEdit = (isEdit, record = {}) => {
        this.setState({
            isEdit: isEdit,
            collectionVisible: true,
            currentItem: record,
        })
    }

    // 跳转tabs
    jumpToTabs = (val) => {
        if (val == 'resolve') {
            this.props.updateTabsInfo(1)
        } else if (val == 'collection') {
            this.props.updateTabsInfo(2)
        }
        this.props.history.push('/admin/dataCollect')
        this.props.history.goBack()
    }

    render() {

        let {
            collectionVisible,
            tableData,
            topicData, // 全部主题域
            businessData, // 全部业务
            businessList, // 主题域对应的业务
            search,
            isEdit,
            currentItem
        } = this.state

        // console.log("-------search", search)
        // console.log("currentItem---------------", currentItem)

        return (
            <>
                <BreadCrumbsAndTablde>
                    <BreadCrumbsAndTablde.LeftCrumbs>
                        <SdwInput
                            placeholder='请输入'
                            label='CMD'
                            width={260}
                            value={search.cmd}
                            onChange={val => {
                                if (val == "") {
                                    this.setSearch('cmd', "")
                                } else if (!isNaN(val)) {
                                    this.setSearch('cmd', parseInt(val))
                                }
                            }}
                        />
                        <SdwInput
                            placeholder='请输入'
                            label='采集标识'
                            width={260}
                            value={search.inputTag}
                            onChange={val => this.setSearch('inputTag', val)}
                        />
                        <SdwSelect
                            placeholder='请选择'
                            label='主题域'
                            width={200}
                            readOnly={false}
                            value={search.businessTopic}
                            data={topicData.map(i => {
                                return {
                                    id: i.topic_name,
                                    text: i.topic_name,
                                    topic_id: i.id
                                }
                            })}
                            onChange={(val, item) => {
                                this.setSearch('businessTopic', item.text)
                                this.setState({
                                    businessTopicId: item.topic_id
                                })
                            }}
                        />
                        <SdwSelect
                            placeholder='请选择'
                            label='业务'
                            width={200}
                            readOnly={false}
                            value={search.business}
                            data={search.businessTopic ? businessList : businessData.map(i => {
                                return {
                                    id: i.business_name,
                                    text: i.business_name
                                }
                            })}
                            onChange={(val, item) => this.setSearch('business', item.text)}
                        />
                        <SdwInput
                            placeholder='请输入'
                            label='创建人'
                            width={260}
                            value={search.createRtx}
                            onChange={val => this.setSearch('createRtx', val)}
                        />
                    </BreadCrumbsAndTablde.LeftCrumbs>
                    <BreadCrumbsAndTablde.RightCrumbs>
                        <SdwButton
                            text='新增'
                            type='submit'
                            iconClass='iconfont icon-add'
                            onClick={() => this.collectionAddOrEdit(false, {})}
                        />
                    </BreadCrumbsAndTablde.RightCrumbs>
                    <BreadCrumbsAndTablde.CenterWrap>
                        <SdwTable
                            columns={this.getColumns()}
                            dataSource={tableData.configList || []}
                        />
                        <SdwPagination
                            total={tableData.total || 0}
                            currentPage={tableData.current_page || 1}
                            pageCount={tableData.per_page}
                            getClickPage={val => this.setSearch("page", val)}
                            pageCountChange={val => this.setSearch("size", val)}
                        />
                    </BreadCrumbsAndTablde.CenterWrap>
                </BreadCrumbsAndTablde>

                {/* 新增或编辑采集标识弹窗 */}
                {collectionVisible &&
                    <CollectionWin
                        isEdit={isEdit}
                        currentItem={currentItem}
                        topicData={topicData}
                        businessData={businessData}
                        iniDataList={this.queryInputTag}
                        collectionVisible={collectionVisible}
                        changeVisible={bool => this.setState({collectionVisible: bool})}
                    />
                }
            </>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo,
    tabsInfo: state.page.tabsInfo
})

const mapDispatch = dispatch => ({
    updateTabsInfo: dispatch.page.updateTabsInfo
})

export default withRouter(connect(mapState, mapDispatch)(LogIdConfiguration))
