import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const switchRoutes = ({ routes, type }) => {
  // 不使用匿名简写为了 React Dev Tool 显示出相应的名字
  const initRoute = routes[0]
  return function SwitchRoutes() {
    let Comp = type || React.Fragment
    return (
      <Switch>
        {routes.map(route => <Route key={route.path} {...route} />)}
        <Redirect to={initRoute.path} from="*"/>
      </Switch>
    )
  }
}

export default switchRoutes
