import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getItems } from '@services/metaData'
import SdwTable from '@common/Table'
import SdwLoading from '@common/Loadding'
import SdwEmpty from '@common/Empty'

class Review extends React.PureComponent {

  state = {
    tableList: [],
    isLoading: true
  }

  componentDidMount() {
    console.log("==-===", this.props)
    let curEnabled = this.props.curEnabled
    if (curEnabled) {
      getItems({
        id: this.props.databaseInfo.id,
        limit: 50
      }).then(res => {
        if (res && res.data && res.data.code === 0) {
          let record = res.data.data || {}
          let tableList = Array.isArray(record.items) ? record.items : []
          this.setState({
            tableList: tableList,
            isLoading: false
          })
        }
      })
    }
  }

  getcolumns = () => {
    let { tableList } = this.state
    if (!tableList.length) return []

    let record = tableList[0]
    return _.keys(record).map(key => {
      return {
        title: key,
        dataIndex: key
      }
    })
  }

  render () {
    let { tableList, isLoading } = this.state
    let { curEnabled } = this.props
    return (
      !curEnabled ?
        <SdwEmpty
          //height={window.innerHeight - 500}
          icon='noAuth'
          text='该表为无效表'
        /> :
      <SdwLoading loadding={isLoading}>
        <SdwTable
          columns={this.getcolumns()}
          dataSource={tableList}
        />
      </SdwLoading>
    )
  }
}

const mapState = state => ({
  databaseInfo: state.database.databaseInfo
})

export default connect(mapState)(Review)
