import { request } from '../utils/request'

export const setMonitorServerConfig = params => {
  return request({ url: '/collection/dbimport/setMonitorServerConfig', method: 'post', data: params })
}

export const getItermSelects = params => {
  return request({ url: '/statistics/dataMarket/getItermSelects', method: 'get', params: params })
}

export const getBusinessStatisticByTopic = params => {
  return request({ url: '/statistics/dataMarket/getBusinessStatisticByTopic', method: 'get', params: params })
}

export const getMonitorServerConfigList = params => {
  return request({ url: '/collection/dbimport/getMonitorServerConfigList', method: 'get', params: params })
}
