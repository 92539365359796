import React, { PureComponent } from 'react'
import _ from 'lodash'
import iconOrder01 from './img/icon-order-01.png'
import iconOrder02 from './img/icon-order-02.png'
import iconOrder03 from './img/icon-order-03.png'
import icon01 from './img/icon-01.png'
import icon02 from './img/icon-02.png'
import icon03 from './img/icon-03.png'
import icon04 from './img/icon-04.png'
import icon12 from './img/icon_12_right@2x.png'
import { getCommonTopics, getCountedFields } from '@services/dictionary'
import * as echarts from 'echarts'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwEmpty from '@common/Empty'

import './style/dictionary.css'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

const ICON_MAP = {
  1: icon03,
  2: icon02,
  3: icon01,
  4: icon04,
}

function getPathName(id) {
  let pathName = ''

  switch (id) {
    case 1:
      pathName = '/admin/dataMarket/standard'
      break;

    case 2:
      pathName = '/admin/factory/atom'
      break;

    case 3:
      pathName = '/admin/factory/derived'
      break;

    default:
      break;
  }

  return pathName
}

class Dictionary extends PureComponent {

  state = {
    comonDomain: [],
    countedFields: [],
    reloadState: 0
  }

  async componentDidMount() {
    await getCommonTopics()
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          this.setState({
            comonDomain: res.data.data || []
          })
        }
      }).catch(res => {
        Window.location.reload();
      })
    
    await getCountedFields()
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          this.setState({
            countedFields: res.data.data || []
          })
        }
      })
    
    this.initPieData()
  }

  initPieData = () => {
    let { countedFields } = this.state

    if (countedFields.length) {
      countedFields.forEach((record, index) => {
        echarts.init(document.querySelector(`#m-chart__content${++index}`))
          .setOption({
            color: record.colors,
            tooltip: {
              trigger: 'item',
              formatter: '{b}: {c} ({d}%)'
            },
            legend: {
              icon: "circle",
              itemWidth: 8,  // 设置宽度
              itemHeight: 8, // 设置高度
              orient: 'horizontal',
              bottom: 0,
              data: record.data,
              textStyle: {
                fontSize: 14
              }
            },
            series: [
              {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: true,
                  position: 'center',
                  fontSize: '30',
                  fontWeight: 'bold',
                  formatter: () => record.total
                },
                emphasis: {
                  label: {
                    show: true,
                    formatter: () => record.total
                  }
                },
                labelLine: {
                  show: false
                },
                data: record.data
              }
            ]
          })
      })
    }
  }

  jumpToDomainDetail = domain => {
    if (!hasPriv('getTopicInfoById')) return
    this.props.history.push({
      pathname: '/admin/dataMarket/domaindetail',
      state: {
        topicid: domain.topic_id,
        seclevel: 'dictionary'
      }
    })
  }

  jumpToDataStandard = id => {
    let pathName = getPathName(id)

    if (!pathName) return
    this.props.history.push({
      pathname: pathName,
      state: {
        typeID: id
      }
    })
  }

  goToDataStandard = (item, domain) => {
    const standardType = item.standard_type
    let pathName = getPathName(standardType)

    if (!pathName) return
    this.props.history.push({
      pathname: pathName,
      state: {
        typeID: item.standard_type,
        topicID: domain.topic_id
      }
    })
  }

  render() {
    let { comonDomain, countedFields } = this.state

    return (
      <>
        <SdwBreadCrumbs 
          history={this.props.history}
          data={[
            {
              title: '数据集市',
              path: '/admin/dataMarket/dictionary'
            }, {
              title: '数据字典'
            }
          ]}
        />
        <div className="page-chart__wrap sdw-dictionary__wrap">
          {
            _.isArray(countedFields) && countedFields.length ?
            <div className="m-chart-sector__list">
              <div className="m-chart-sector">
                <div className="m-chart__header">
                  <div className="m-chart__title">
                    <div className="title-icon"><img className="pc-image" src={iconOrder01} /></div>
                    <div className="title-txt" title="查看原生字段" onClick={() => this.jumpToDataStandard(1)}>原生字段</div>
                    <div className="title-desc">原油</div>
                  </div>
                </div>
                <div 
                  title="查看原生字段"
                  onClick={() => this.jumpToDataStandard(1)}
                  className="m-chart-sector__canvas-wrap"
                  id="m-chart__content1" >
                </div>
              </div>

              <div className="m-chart-sector">
                <div className="m-chart__header">
                  <div className="m-chart__title">
                    <div className="title-icon"><img className="pc-image" src={iconOrder02} /></div>
                    <div className="title-txt" title="查看原子指标" onClick={() => this.jumpToDataStandard(2)}>原子指标</div>
                    <div className="title-desc">半成品</div>
                  </div>
                </div>
                <div 
                  title="查看原子指标"
                  onClick={() => this.jumpToDataStandard(2)}
                  className="m-chart-sector__canvas-wrap"
                  id="m-chart__content2" >
                </div>
      
              </div>
              <div className="m-chart-sector">
                <div className="m-chart__header">
                  <div className="m-chart__title">
                    <div className="title-icon"><img className="pc-image" src={iconOrder03} /></div>
                    <div className="title-txt" title="查看派生指标" onClick={() => this.jumpToDataStandard(3)}>派生指标</div>
                    <div className="title-desc">成品油</div>
                  </div>
                </div>
                <div
                  title="查看派生指标"
                  onClick={() => this.jumpToDataStandard(3)}
                  className="m-chart-sector__canvas-wrap"
                  id="m-chart__content3" >
                </div>
                
              </div>
            </div> : 
            <SdwEmpty />
          }
        </div>
        <div className="page-panel__wrap">
          <div className="m-title size-small" style={{ position: 'relative' }}>
            <div className="m-title__txt">常见主题域</div>
            {
              hasPriv('/admin/dataMarket/management') &&
              <div
                className="m-title__txt more-button"
                onClick={() => this.props.history.push('/admin/dataMarket/management')}
              >
                更多
                <img style={{ display: 'inline-block', height: '16px', verticalAlign: 'middle', marginLeft: '4px' }} src={icon12} />
              </div>
            }
          </div>
          <div className="m-panel__list">
            {
              _.isArray(comonDomain) && comonDomain.length ?
              comonDomain.map(domain => (
                <div key={domain.topic_id} className="m-panel-theme dictionary__panel-theme">
                  <div className="panel-theme__hd">
                    <div className="panel-title">
                      <div
                        className="panel-title__txt"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.jumpToDomainDetail(domain)}
                      >
                        <img className="dictionary__pc-image" src={ICON_MAP[domain.topic_id]} />
                        {domain.title}
                        <i className="dictionary__right-img" />
                      </div>
                    </div>
                    <div className="panel-data-status">
                      {
                        domain.data.map(item => (
                          <div key={item.value} className="data-status__item" onClick={() => this.goToDataStandard(item, domain)}>
                            <div className="data-status__value">{item.value}</div>
                            <div className="data-status__desc ellipsis">{item.name}</div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="panel-theme__bd">
                    <div className="panel-theme-target">
                      <div className="target__hd">
                        <div className="target__row">
                          <div className="target-txt">字段名</div>
                          <div className="target-txt">类型</div>
                        </div>
                      </div>
                      <div className="target__bd">
                        {
                          domain.list.map((item, index) => (
                            <div key={index} className="target__row" onClick={() => {
                              if (!hasPriv('getFieldById')) return
                              this.props.history.push({
                                pathname: '/admin/dataMarket/standardinfo',
                                state: {
                                  id: item.id
                                }
                              })
                            }}>
                              <div className="target-txt ellipsis" style={{ paddingRight: '8px' }}>{item.field_name_ch}</div>
                              <div className="target-txt">{item.standard_type}</div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )) : 
              <SdwEmpty />
            }
          </div>
        </div>
      </>
    )
  }
}

export default Dictionary
