import React from 'react'
import _ from 'lodash'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import SdwDialog from '@common/Dialog'
import SdwMessage from '@common/Message'
import BreadCrumbs from '@common/BreadCrumbs'
import {getTableApiItems} from '@api/dataAccess'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './index.css'
import {requestOptionsLOG} from '@config/config'

const baseURL = requestOptionsLOG.basename

class setupTwo extends React.PureComponent {

    state = {
        deliveryConfigList: [], // 分发配置
        topicList: [], // 日志topic
        targetTableList: [], // 落地数据表
        dialogDelVisible: false,
        delKey: '',
        parseId: '', // 根据cmd获取到的parseId
    }

    componentDidMount() {
        this.initData()
        this.queryInputFileConfigList()
        this.getTargetTableList()
        this.queryParseIdByCmd()
    }

    initData = () => {
        let query = this.props.location.state || {}
        if (!_.isEmpty(query.deliveryInnerConfigDTOS)) {
            this.setState({
                deliveryConfigList: query.deliveryInnerConfigDTOS
            })
        }
    }

    // 获取日志topic
    queryInputFileConfigList = () => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/parseConfig/queryConsumeTopic',
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then((data) => {
                let record = data.data
                this.setState({
                    topicList: record.map(i => {
                        return {id: i.topic, text: i.topic}
                    })
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 获取落地数据表
    getTargetTableList = () => {
        // 目标表参数
        let paramData = {
            limit: 10000,
            search: JSON.stringify({
                data_set_type: {
                    value: 'es',
                    operator: '='
                },
                warehouse_level: {
                    value: 'ODS',
                    operator: '='
                },
                enable: {
                    value: '1',
                    operator: '='
                }
            })
        }
        // 目标表
        getTableApiItems(paramData).then(res => {
            const code = _.get(res, 'data.code')
            if (code === 0) {
                const items = _.get(res, 'data.data.items')
                let curArr = _.isArray(items) ? items : []
                curArr = curArr.map(i => {
                    let curName = !!i.database_name ? `${i.database_name}.${i.table_name}` : `${i.table_name}`
                    return {
                        id: parseInt(i.id),
                        text: `${curName}（${i.data_set_chinese}）`,
                        store_table_name_ch: i.data_set_chinese,
                        store_table_name_en: `${i.database_name}.${i.table_name}`,
                        topic: i.topic,
                        business: i.business,
                        data_set_type: i.data_set_type
                    }
                })
                this.setState({
                    targetTableList: curArr
                })
            } else {
                SdwMessage.error('获取目标表数据失败')
            }
        }).catch(error => {
            SdwMessage.error('获取目标表接口异常：' + error)
        })
    }

    // 根据cmd获取parseId
    queryParseIdByCmd = () => {
        let str = '?cmd=' + this.props.location.state.cmd
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/parseConfig/queryParseIdByCmd' + str,
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then((data) => {
                let record = data.data
                this.setState({
                    parseId: record
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 上一步
    jumpToOne = () => {
        let {parseId} = this.state
        this.props.history.push({
            pathname: '/admin/dataCollect/logAccessDetail',
            state: {
                id: parseId
            }
        })
    }

    addVirtualGroup = () => {
        let item = {}
        let deliveryConfigList = [...this.state.deliveryConfigList]
        deliveryConfigList.push(item);
        this.setState({deliveryConfigList: deliveryConfigList})
    }

    removeVirtualGroup = (index) => {
        let deliveryConfigList = [...this.state.deliveryConfigList]
        deliveryConfigList.splice(index, 1);
        this.setState({deliveryConfigList: deliveryConfigList, dialogDelVisible: false})
    }

    delVirtualGroup = (val, key) => {
        this.setState({
            delKey: key,
            dialogDelVisible: val,
        })
    }

    updateVirtualGroup = (val, type, index, item) => {
        // 日志topic
        if (type == 'logTopic') {
            let deliveryConfigList = [...this.state.deliveryConfigList]
            deliveryConfigList[index].topic = val
            this.setState({deliveryConfigList: deliveryConfigList})
        }
        // 分发方式
        if (type == 'deliveryType') {
            let deliveryConfigList = [...this.state.deliveryConfigList]
            deliveryConfigList[index].deliveryType = val
            this.setState({deliveryConfigList: deliveryConfigList})
        }
        // 落地数据表
        if (type == 'storeTable') {
            let deliveryConfigList = [...this.state.deliveryConfigList]
            deliveryConfigList[index].storeTableId = val
            deliveryConfigList[index].storeTableName = item.store_table_name_en
            deliveryConfigList[index].storeTableNameZh = item.store_table_name_ch
            this.setState({deliveryConfigList: deliveryConfigList})
        }
    }

    // 完成按钮点击事件
    submitFinish = () => {
        let {deliveryConfigList} = this.state
        if (_.isEmpty(deliveryConfigList)) {
            this.props.history.push('/admin/dataCollect/logAccess')
        } else {
            this.submitClick('finish')
        }
    }

    // 批量更新或写入分发配置
    submitClick = (val) => {
        let {deliveryConfigList, parseId} = this.state
        let rtx = this.props.userInfo.name
        let params = deliveryConfigList.map(i => {
            i.parseId = parseId
            i.rtx = rtx
            i.enable = true
            return i
        })
        fetch(baseURL + '/sdw/filebeat/parseConfig/batchUpsertDeliveryConfig',
            {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                if (data && data.code === 100) {
                    SdwMessage.success('分发配置成功')
                    if (val == 'finish') {
                        this.props.history.push('/admin/dataCollect/logAccess')
                    } else if (val == 'collection') {
                        this.props.updateTabsInfo(2)
                        this.props.history.push('/admin/dataCollect/logAccess')
                    }
                } else if (data.code === -605) {
                    SdwMessage.error('分发方式未配置')
                } else {
                    SdwMessage.error(data.message)
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    render() {

        let {
            dialogDelVisible,
            delKey,
            deliveryConfigList,
            topicList,
            targetTableList
        } = this.state

        return (
            <>
                <BreadCrumbs
                    history={this.props.history}
                    data={[
                        {
                            title: '数据采集地',
                            path: '/admin/dataCollect/dataManagement'
                        }, {
                            title: '日志接入',
                            path: '/admin/dataCollect/logAccess'
                        }, {
                            title: '新增日志接入'
                        }
                    ]}
                />

                <div className='log-access-detail-main__body_two'>

                    {/* 步骤条 */}
                    <div className='log-access-step-container'>
                        <ol className='log-access-step-area'>
                            <li>
                                <div className='log-access-step-inner_2'>
                                    <i className='log-access-step-num_2'></i>
                                    <span>内容存储配置</span>
                                </div>
                            </li>
                            <li className='success'>
                                <div className='log-access-step-inner_2'>
                                    <i className='log-access-step-num_2'></i>
                                    <span>分发配置</span>
                                </div>
                            </li>
                        </ol>
                    </div>


                    <div className='log-access-detail-form'>
                        <div className='log-access-detail-title-main'>
                            <span className='log-access-detail-title-bar'>分发配置</span>
                            <span className='log-access-detail-title-des'>用户消费分发规则</span>
                        </div>
                        <div className='log-access-detail-box-main'>
                            <div>
                                {
                                    deliveryConfigList.length == 0 && <span
                                        className='add-config-rule-btn'
                                        onClick={() => this.addVirtualGroup()}
                                    >添加分发配置</span>
                                }
                                {
                                    deliveryConfigList instanceof Array && deliveryConfigList.map((item, k) => {
                                        return (
                                            <div key={k} style={{marginBottom: 20}}>
                                                <div style={{marginBottom: 10}}>
                                                    <span className='label required'>日志topic{k + 1}</span>
                                                    <SdwSelect
                                                        placeholder='请选择日志topic'
                                                        width={600}
                                                        data={topicList}
                                                        value={item.topic}
                                                        onChange={val => {
                                                            this.updateVirtualGroup(val, 'logTopic', k)
                                                        }}
                                                    />
                                                </div>
                                                <div style={{display: 'inline-block'}}>
                                                    <span className='label required'>分发配置{k + 1}</span>
                                                    <SdwSelect
                                                        borderTopRightRadius={0}
                                                        borderBottomRightRadius={0}
                                                        placeholder='请选择分发方式'
                                                        width={180}
                                                        value={item.deliveryType}
                                                        data={[{
                                                            id: 'STORE',
                                                            text: '入库'
                                                        }, {
                                                            id: 'INDIVIDUAL',
                                                            text: '自定义'
                                                        }]}
                                                        onChange={val => {
                                                            this.updateVirtualGroup(val, 'deliveryType', k)
                                                        }}
                                                    />
                                                    <SdwSelect
                                                        disabled={item.deliveryType == 'INDIVIDUAL'}
                                                        borderTopLeftRadius={0}
                                                        borderBottomLeftRadius={0}
                                                        placeholder='请选择落地数据表'
                                                        width={420}
                                                        readOnly={false}
                                                        value={item.storeTableId}
                                                        data={targetTableList}
                                                        onChange={(val, item) => {
                                                            this.updateVirtualGroup(val, 'storeTable', k, item)
                                                        }}
                                                    />
                                                </div>
                                                <i onClick={() => this.addVirtualGroup(k)} className='el-icon-plus'
                                                   style={{marginLeft: 8}}></i>
                                                <i onClick={() => this.delVirtualGroup(true, k)}
                                                   className='el-icon-minus'></i>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className='log-access-detail-form-button'>
                            <SdwButton
                                type='cancel'
                                text='上一步'
                                onClick={() => this.jumpToOne()}
                            />
                            <span style={{marginLeft: 8}}></span>
                            <SdwButton
                                type='cancel'
                                text='完成'
                                onClick={() => this.submitFinish()}
                            />
                            <span style={{marginLeft: 8}}></span>
                            <SdwButton
                                type='submit'
                                text='去配置日志文件'
                                onClick={() => this.submitClick('collection')}
                            />
                        </div>
                    </div>
                </div>

                {dialogDelVisible &&
                    <SdwDialog
                        title={`删除分发配置${delKey + 1}`}
                        width={500}
                        visible={dialogDelVisible}
                        changeVisible={this.delVirtualGroup}
                        onCancelClick={() => this.delVirtualGroup(false)}
                        onSureClick={() => this.removeVirtualGroup(delKey)}
                        cusWrapClass={'dialog-del-visible'}
                    >
                        <div>确认删除后所属配置的采集日志将无法发送，是否确认？</div>
                    </SdwDialog>
                }

            </>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo,
    tabsInfo: state.page.tabsInfo
})

const mapDispatch = dispatch => ({
    updateTabsInfo: dispatch.page.updateTabsInfo
})

export default withRouter(connect(mapState, mapDispatch)(setupTwo))
