import React, { PureComponent } from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import SdwCascader from '@common/Cascader'
import { Input, Tabs, Select, Button } from 'element-react'
import SdwMessage from '@common/Message'
import {
  getTablecfgFields, storeOrUpdateTarget, checkTarget, getTableFieldsByAtomId, getAllModifier, getAllTarget,
  getChildTarget, storeOrUpdateAuth
} from '@services/Derived'
import { getMetricTypeConf } from '@services/application'
import SdwDialog from '@common/Dialog'
import Textarea from '@common/Textarea'
import ConfirmWin from '@components/ConfirmWin'

import 'element-theme-default'
import './style/EditData.css'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

// 本指标为颗粒指标
const CUR_GRANULAR = 'cur_granular'

class EditData extends PureComponent {

  state = {
    isEdit: false, // 是否是编辑 or 新增
    comment: '',
    commentError: {
      isError: false,
      msg: ''
    },
    id: "",
    target_name_cn: "",
    target_name: "",
    topicBusiness: "",
    target_unit: "",
    granular_category: "", // 所属颗粒化指标id
    is_granular: 0, // 是否颗粒化指标。0否，1是
    comments: "",
    source_targets: "",
    decoration_ids: [],
    decoration: "",
    store_dimension: [{ id: [] }],
    complex_script: "",
    complex_params: "",
    enable: 0,
    time_schedule: [{ run_cycle: '', time_cycle: '' }],
    cal_expression: [],

    target_type: "1",
    tableFields: [],
    defaultItem: {
      time_schedule: { run_cycle: '', time_cycle: '' },
      store_dimension: { id: [] }
    },
    dimNames: {},
    fields: [],
    modifier: [],
    targetData: [],
    targetSeachData: [],
    targetDataSelectVal: "",
    searchKey: "",
    expression: "",
    btnList: ['+', '-', '*', '/', '(', ')'],
    selectedItem: [],
    selectedItemId: [],
    calculateNum: 0,
    calculateNumState: false,
    sholdCheckValidate: false,
    showConfirmWin: false,
    confirmData: [],
    metric_type: '',
    metricTypeMap: {}, // 计算公式类型
    allEnableTargetArr: [],
    warningVisible:false,
    errorMsgContent:"",
  }

  componentDidMount () {
    this.getAllTarget()
    this.initData()
    this.getMetricTypeConf()
    this.getAllEnableTarget()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.record.id !== this.props.record.id) {
      this.initData()
    }
  }

  getAllEnableTarget = () => {
    getAllTarget({
      search: JSON.stringify({
        // target_type != 4   and  enable = 1 and  is_granular=1
        target_type: {
          value: 4,
          operator: '!='
        },
        enable: {
          value: 1,
          operator: '='
        },
        is_granular: {
          value: 1,
          operator: '='
        }
      })
    }).then(res => {
      const curID = _.get(this.props, 'record.id')
      let arr = _.get(res, 'data.data')
      arr = _.isArray(arr) ? arr : []
      arr = arr.reduce((prev, item) => {
        if (item.id != curID) {
          prev.push({
            ...item,
            text: `${item.target_name}(${item.target_name_cn})`
          })
        }
        return prev
      }, [])

      // 新增或者编辑，都需要展示下面这个选项
      arr.unshift({
        id: CUR_GRANULAR,
        text: '本指标为颗粒指标'
      })
      this.setState({
        allEnableTargetArr: arr
      })
    }, () => SdwMessage.error("接口异常"))
  }

  getMetricTypeConf = () => {
    getMetricTypeConf()
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let metricTypeMap = _.get(res, 'data.data.metric_type')
          this.setState({
            metricTypeMap
          })
        } else {
          let msg = _.get(res, 'data.msg')
          if (!!msg) SdwMessage.error(msg)
        }
      })
      .catch(err => SdwMessage.error('请求计算公式出错 ' + err))
  }

  initData = () => {
    const curRecord = this.props.record || {}
    console.log("----------curRecord", curRecord)
    let cal_fields = curRecord.cal_fields || ""
    cal_fields = cal_fields == "" ? [] : JSON.parse(cal_fields)

    let cal_expression = curRecord.cal_expression || ""
    cal_expression = cal_expression == "" ? [] : JSON.parse(cal_expression)

    const isEdit = !!_.get(this.props, 'record.id')

    this.setState({
      isEdit,
      id: curRecord.id || 0,
      target_name_cn: curRecord.target_name_cn,
      target_name: curRecord.target_name,
      topicBusiness: curRecord.topicBusiness || [],
      target_unit: curRecord.target_unit,
      is_granular: curRecord.is_granular,
      granular_category: curRecord.is_granular == 1 ? CUR_GRANULAR : curRecord.granular_category,
      comments: curRecord.comments,
      source_targets: curRecord.source_targets,
      decoration_ids: curRecord.decoration_ids || [],
      decoration: curRecord.decoration,
      store_dimension: curRecord.store_dimension instanceof Array ? curRecord.store_dimension : [{ id: [] }],
      complex_script: curRecord.complex_script || "",
      complex_params: curRecord.complex_params || "",
      enable: curRecord.enable || 0,
      time_schedule: curRecord.time_schedule instanceof Array ? curRecord.time_schedule : [{ run_cycle: '', time_cycle: '' }],
      cal_expression: cal_expression,
      cal_fields: cal_fields,
      target_type: curRecord.target_type || "1",
      selectedItem: cal_fields.join(""),
      selectedItemId: cal_expression.join(""),
      metric_type: curRecord.metric_type || '',
    })

    if(
      curRecord.target_type == "1" ||
      curRecord.target_type == "3" && !!curRecord.source_targets
    ) {
      this.handleTargetChange(curRecord.source_targets)
    }
    }

  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    if (label == "主题域/业务" && Array.isArray(curValue.ids) && curValue.ids.length) {
      return true
    }
    return `${label}不能为空`
  }

  // 获取所有的派生指标数据
  getAllTarget = () => {
    let that = this
    getAllTarget().then(res => {
      let targetData = res.data.data || []
      that.setState({
        targetData: targetData,
        targetSeachData: targetData
      })
    })
  }

  // 获取指定的表的字段
  getTablecfgFields = (related_table_id) => {
    this.setState({related_table_id: related_table_id})
    let that = this
    let parame = {}
    parame.id = related_table_id
    getTablecfgFields(parame).then(res => {
      let tableFields = res.data.data || []
      that.setState({tableFields: tableFields})
    })
  }

  handleSureClick = () => {
    let {
      id,
      target_name_cn,
      target_name,
      target_unit,
      is_granular,
      granular_category,
      comments,
      source_targets,
      metric_type,
      decoration_ids,
      decoration,
      store_dimension,
      complex_script,
      complex_params,
      enable,
      time_schedule,
      cal_expression,
      cal_fields,
      target_type,
      topicBusiness,
      comment
    } = this.state

    let isValidatePass =
      !!target_name_cn &&
      !!target_name &&
      topicBusiness && Array.isArray(topicBusiness.ids) && topicBusiness.ids.length > 0

    if (!isValidatePass) {
      // 手动触发必填项的检查
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    let dataid = this.props.record.id || 0

    let params = { id: dataid, field: {}}

    let comitFieldData = {
      target_name_cn,
      target_name,
      target_unit,
      is_granular,
      granular_category,
      comments,
      source_targets: source_targets,
      decoration_ids,
      decoration,
      store_dimension,
      complex_script: complex_script,
      complex_params: complex_params,
      enable,
      time_schedule,
      cal_expression,
      cal_fields,
      target_type,
      topicBusiness,
      metric_type: metric_type,
    }

    let formData = new FormData()
    for (let i in comitFieldData) {
      let val = comitFieldData[i]
      if (i === 'id') {
        continue
      }

      if (i === 'granular_category' && val == CUR_GRANULAR) {
        val = ''
      }

      if (i === 'topicBusiness') {
        params.field['topic'] = val["ids"][0] || ''
        params.field['service'] = val["ids"][1] || ''
      }

      if(i === "store_dimension") {
        params.field['store_dimension'] = store_dimension ? JSON.stringify(store_dimension) : ''
        continue
      }

      if(i === "time_schedule") {
        params.field['time_schedule'] = time_schedule ? JSON.stringify(time_schedule) : ''
        continue
      }

      if(i === "decoration_ids") {
        let decoration_ids = val ? val.join(",") : ''
        params.field['decoration_ids'] = decoration_ids
        continue
      }

      if(i === "cal_fields") {
        params.field[i] = JSON.stringify(val)
        continue
      }

      if(i === "cal_expression") {
        params.field[i] = JSON.stringify(val)
        continue
      }

      params.field[i] = val
    }

    for (let i in params) {
      formData.append(i, params[i])
    }



    if (!hasPriv('derivedCreateOrUpdate')) {
      // 没有编辑权限，说明只能提交申请接口（审批接口）
      storeOrUpdateAuth({
        ...params,
        comment
      }).then((res) => {
        if (_.get(res, 'data.code') === 0) {
          let msg = '提交审批成功'
          SdwMessage.success(msg)
          this.setState({ showConfirmWin: false })
          // 告诉父级点击了确定按钮
          this.props.onSureClick()
        } else {
          SdwMessage.error(_.get(res, 'data.msg') || '提交审批失败');
        }
      }).catch(err => SdwMessage.error('接口异常 ' + err))
      return
    }

    //如果有权限那么就去校验指标
    // 有编辑权限，直接走编辑接口（老接口，非提交申请接口）
    checkTarget(params).then((res) => {

      if (res && res.data && res.data.code === 0) {
        // 告诉父级点击了确定按钮
        this.saveSureAction(id)
      } else {
        this.setState({
          errorMsgContent:res.data.msg,
          warningVisible:true,
        })
        //SdwMessage.error(res.data.msg);
      }
    }).catch(err => SdwMessage.error('接口异常 ' + err))
  }

  saveSureAction = (id) => {
    if (!id) {
      // 新增
      this.handleStoreOrUpdateTarget()
      return
    }

    // 1、弹窗二次确认(只有编辑才需要)
    getChildTarget({id}).then((res) => {

      if (res && res.data && res.data.code === 0) {
        // 2、确认后才编辑数据
        let list = Array.isArray(res.data.data) ? res.data.data : []

        // 这里list没有长度：表明没有关联的指标，不用弹出确认框
        if (hasPriv('derivedCreateOrUpdate') && !list.length) {
          this.handleStoreOrUpdateTarget()
          return
        }

        this.setState({ confirmData: list, showConfirmWin: true })
      }
    })
  }

  handleConfirm = () => {
    let { comment } = this.state
    comment = _.trim(comment)

    const isToBig = String(comment).length >= 10000
    if (!comment || isToBig) {
      this.setState({
        commentError: {
          isError: true,
          msg: isToBig ? '输入最大长度不超过10000字' : '请填写修改原因'
        }
      })
      return
    }

    this.handleStoreOrUpdateTarget()
  }

  handleChangeComment = val => {
    this.setState({
      commentError: {
        isError: false,
        msg: ''
      }
    })

    this.setState({
      comment: val
    })
  }

  handleStoreOrUpdateTarget = () => {
    let {
      id,
      target_name_cn,
      target_name,
      target_unit,
      is_granular,
      granular_category,
      comments,
      source_targets,
      metric_type,
      decoration_ids,
      decoration,
      store_dimension,
      complex_script,
      complex_params,
      enable,
      time_schedule,
      cal_expression,
      cal_fields,
      target_type,
      topicBusiness,
      comment
    } = this.state

    let isValidatePass =
      !!target_name_cn &&
      !!target_name &&
      topicBusiness && Array.isArray(topicBusiness.ids) && topicBusiness.ids.length > 0

    if (!isValidatePass) {
      // 手动触发必填项的检查
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    let dataid = this.props.record.id || 0

    let params = { id: dataid, field: {}}

    let comitFieldData = {
      target_name_cn,
      target_name,
      target_unit,
      is_granular,
      granular_category,
      comments,
      source_targets: source_targets,
      decoration_ids,
      decoration,
      store_dimension,
      complex_script: complex_script,
      complex_params: complex_params,
      enable,
      time_schedule,
      cal_expression,
      cal_fields,
      target_type,
      topicBusiness,
      metric_type: metric_type,
    }

    let formData = new FormData()
    for (let i in comitFieldData) {
      let val = comitFieldData[i]
      if (i === 'id') {
        continue
      }

      if (i === 'granular_category' && val == CUR_GRANULAR) {
        val = ''
      }

      if (i === 'topicBusiness') {
        params.field['topic'] = val["ids"][0] || ''
        params.field['service'] = val["ids"][1] || ''
      }

      if(i === "store_dimension") {
        params.field['store_dimension'] = store_dimension ? JSON.stringify(store_dimension) : ''
        continue
      }

      if(i === "time_schedule") {
        params.field['time_schedule'] = time_schedule ? JSON.stringify(time_schedule) : ''
        continue
      }

      if(i === "decoration_ids") {
        let decoration_ids = val ? val.join(",") : ''
        params.field['decoration_ids'] = decoration_ids
        continue
      }

      if(i === "cal_fields") {
        params.field[i] = JSON.stringify(val)
        continue
      }

      if(i === "cal_expression") {
        params.field[i] = JSON.stringify(val)
        continue
      }

      params.field[i] = val
    }

    for (let i in params) {
      formData.append(i, params[i])
    }

    // 有编辑权限，直接走编辑接口（老接口，非提交申请接口）
    storeOrUpdateTarget(params).then((res) => {

      if (res && res.data && res.data.code === 0) {
        let msg = id === 0 ? '新建成功' : '编辑成功'
        SdwMessage.success(msg)
        this.setState({ showConfirmWin: false })
        // 告诉父级点击了确定按钮
        this.props.onSureClick()
      } else {
        SdwMessage.error(res.data.msg);
      }
    }).catch(err => SdwMessage.error('接口异常 ' + err))
  }

  enableStateChange = (val) => {
    if(val) {
      this.setState({enable: 1})
    }else {
      this.setState({enable: 0})
    }
  }

  tabConfigChange = (tab) => {
    if(this.state.id == 0) {
      this.setState({target_type: tab.props.name})
    }
  }

  configDataChange = (val, type, index) => {
    if(type == "store_dimension") {
      let store_dimension = [...this.state.store_dimension]
      store_dimension[index].id = val
      this.setState({store_dimension: store_dimension})
    }

    if(type == "time_schedule_time_cycle") {
      let time_schedule = [...this.state.time_schedule]
      time_schedule[index].time_cycle = val
      this.setState({time_schedule: time_schedule})
    }

    if(type == "store_dimension_run_cycle") {
      let time_schedule = [...this.state.time_schedule]
      time_schedule[index].run_cycle = val
      this.setState({time_schedule: time_schedule})
    }

    if(type == "targetDataSelectVal") {
      this.setState({targetDataSelectVal: val})
    }
  }

  addVirtualProperty = (index) => {
    let item = {
      id: []
    }

    let store_dimension = [...this.state.store_dimension]
    store_dimension.push(item);
    this.setState({store_dimension: store_dimension})
  }

  removeVirtualProperty = (index) => {
    let store_dimension = [...this.state.store_dimension]
    store_dimension.splice(index, 1);
    this.setState({store_dimension: store_dimension})
  }

  addVirtualProperty2 = (index) => {
    let item = { run_cycle: '', time_cycle: '' }

    let time_schedule = [...this.state.time_schedule]
    time_schedule.push(item);
    this.setState({time_schedule: time_schedule})
  }

  removeVirtualProperty2 = (index) => {
    let time_schedule = [...this.state.time_schedule]
    time_schedule.splice(index, 1);
    this.setState({time_schedule: time_schedule})
  }


  // 原子指标相应修饰词和维度（维度关联的表的字符段）的关联进行变化
  handleTargetChange = (id) => {
    this.setState({source_targets: id}, () => {
      this.getTableFieldsByAtomId(id)
      this.handleFetchModifier(id)
    })
  }

  getTableFieldsByAtomId = (id) => {
    getTableFieldsByAtomId({ id: id }).then(res => {
      if (res.data.code === 0) {
        let fields = res.data.data
        this.setState({
          fields: fields
        })
      }
    })
  }

  handleFetchModifier = (id) => {
    let tid = ''
    if (this.props.atomTarget instanceof Array) {
      this.props.atomTarget.map(item => {
        if (item.id == id) {
          tid = item.related_table_id
        }
      })
    }
    if (!tid) {
      return
    }
    let params = {}
    params.search = {
      source_id: { operator: "=", value: tid },
      enable: { operator: "=", value: '1' }
    }
    getAllModifier(params).then(res => {
      if (res.data.code === 0) {
        let modifier = res.data.data || []
        this.setState({modifier: [...modifier]})
      }
    })
  }

  dimensionData = () => {
    let d = []
    if (!this.state.source_targets) return d
    let tid = ''

    // 表字段
    if (this.state.fields instanceof Array) {
      this.state.fields.map((item) => {
        this.state.dimNames[item.field_name] = item.field_name_ch || item.field_name
        if (!tid && !!item.related_table_id) tid = item.related_table_id // 依赖的表id（同一个接口的related_table_id应该唯一）
        d.push({ id: item.field_name, name: item.field_name + "(" + item.field_name_ch + ")" })
      })
    }

    // 维度字段
    if (this.props.dimension instanceof Array) {
      this.props.dimension.map((item) => {
        this.state.dimNames[item.id] = item.map_name_en || item.name
        if (!item.apply_table) {
          return
        }
        const tableid = JSON.parse(item.apply_table)
        if (tableid && tableid != undefined && tableid instanceof Array && tableid.indexOf(tid) >= 0) {
          d.push({ id: item.id, name: item.name + "(" + item.map_name_en + ")" })
        }
      })
    }

    return d
  }

  calculateChange = (val) => {
    this.setState({searchKey: val})
    let targetSeachData = [...this.state.targetData]
    let newData = []

    for(let i=0; i<targetSeachData.length; i++) {
      let target_name_cn = targetSeachData[i].target_name_cn
      let target_name = targetSeachData[i].target_name
      if(target_name_cn && target_name && (target_name_cn.indexOf(val) >= 0 || target_name.indexOf(val) >= 0)) {
        newData.push(targetSeachData[i])
      }
    }

    this.setState({targetSeachData: newData})
  }

  calculateCulSelect = (val) => {
    let selectedItem = [...this.state.cal_fields]
    selectedItem.push(val)

    let selectedItemId = [...this.state.cal_expression]
    selectedItemId.push(val)

    let expressionField = selectedItem.join('')
    let expression = selectedItemId.join('')

    this.setState({
      selectedItem: expressionField,
      selectedItemId: expression,
      cal_fields: selectedItem,
      cal_expression: selectedItemId,
      calculateNumState: false
    })
  }

  calculateCulBack = () => {
    let selectedItem = [...this.state.cal_fields]
    selectedItem.pop()

    let selectedItemId = [...this.state.cal_expression]
    selectedItemId.pop()

    let expressionField = selectedItem.join('')
    let expression = selectedItemId.join('')

    this.setState({
      selectedItem: expressionField,
      selectedItemId: expression,
      cal_fields: selectedItem,
      cal_expression: selectedItemId,
    })
  }

  calculateCulClick (row) {
    let selectedItem = [...this.state.cal_fields]
    let selectedItemId = [...this.state.cal_expression]

    selectedItem.push("[" + row.target_name_cn + "]")
    selectedItemId.push("[" + row.id + "]")

    let expressionField = selectedItem.join('')
    let expression = selectedItemId.join('')

    this.setState({
      selectedItem: expressionField,
      selectedItemId: expression,
      cal_fields: selectedItem,
      cal_expression: selectedItemId,
    })
  }

  calculateCulShowAddNum = () => {
    if(this.state.calculateNumState) {
      this.setState({calculateNumState: false})
    }else {
      this.setState({calculateNumState: true})
    }
    this.setState({calculateNum: 0})
  }

  calculateNumChange = (num) => {
    this.setState({calculateNum: num})
  }

  handleGranular = val => {
    let is_granular =0
    if (val === CUR_GRANULAR) {
      is_granular = 1
    }

    this.setState({
      is_granular,
      granular_category: val
    })
  }

  render () {
    let {
      id,
      target_name_cn,
      target_name,
      topicBusiness,
      target_unit,
      granular_category,
      comments,
      source_targets,
      decoration_ids,
      decoration,
      store_dimension,
      complex_script,
      complex_params,
      enable,
      time_schedule,
      searchKey,
      target_type,
      targetSeachData,
      sholdCheckValidate,
      btnList,
      calculateNum,
      calculateNumState,
      selectedItem,
      showConfirmWin,
      confirmData,
      metricTypeMap,
      metric_type,
      comment,
      commentError,
      allEnableTargetArr,
      warningVisible,
      errorMsgContent,
    } = this.state

    // 主题列表渲染成组件需要的数据
    let topicData = this.props.topicData.map(i => {
      return {
        id: i.value,
        text: i.label,
        children: i.children && i.children.map(si => { return {id:si.value, text:si.label} })
      }
    })

    // 单位选项
    let unitList = []
    if(this.props.cfgData.hasOwnProperty("unit")) {
      unitList = this.props.cfgData.unit.map(i => {
        return {
          id: i.name,
          text: i.name
        }
      })
    }

    // runCycle
    let runCycle = []
    if(this.props.cfgData.hasOwnProperty("runCycle")) {
      runCycle = this.props.cfgData.runCycle.map(i => {
        return {
          id: i.id + '',
          text: i.name
        }
      })
    }

    // timeCycle
    let timeCycle = []
    if(this.props.cfgData.hasOwnProperty("timeCycle")) {
      timeCycle = this.props.cfgData.timeCycle.map(i => {
        return {
          id: i.id + '',
          text: i.name
        }
      })
    }

    // 原子指标选项
    let atomTarget = this.props.atomTarget.map(i => {
      return {
        id: i.id + '',
        text: i.target_name + '(' + i.target_name_ch + ')',
      }
    })

    // 关联维度
    let dimensionData = this.dimensionData()

    // 有效无效状态
    let enableState = enable == 1 ? true : false

    /**
     * 1、走确定接口：新增弹窗新增指标、有编辑权限进入编辑弹窗
     * 2、需要提交审批：无编辑权限进入编辑弹窗
     */
    let sureText = '确定'

    // 无编辑权限：进入弹窗，一定不是新增，而是编辑进入，此时，没有编辑权限，编辑提交需要进行审批
    if (!hasPriv('derivedCreateOrUpdate')) {
      sureText = '提交审批'
    }

    // 审批中的状态
    let sureBtnDisabled = false
    if (_.get(this.props, 'record.isProcess')) {
      sureText = '审批中'
      sureBtnDisabled = true
    }

    return (
      <div className="editorMain editorMain-Derived">
        <SdwDrawer
          title={this.props.title + ` ${!!_.get(this.props, 'record.id') ? _.get(this.props, 'record.id') : ''}`}
          visible={this.props.visible}
          onIconClick={this.props.onIconClick}
          onCancelClick={this.props.onCancelClick}
          onSureClick={this.handleSureClick}
          sureText={sureText}
          sureBtnDisabled={sureBtnDisabled}
        >
          <SdwDrawer.Body>
            <div className="form edit-data-win-wrap">
              <div className="formInput">
                <span className="label required">中文名</span>
                <div className="content">
                  <SdwInput
                    value={target_name_cn}
                    validateFun={value => this.validateFun(value, '中文名')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({target_name_cn: val})}
                  />
                </div>
              </div>

              <div className="formInput">
                <span className="label required">英文名</span>
                <div className="content">
                  <SdwInput
                    value={target_name}
                    validateFun={value => this.validateFun(value, '英文名')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({target_name: val})}
                  />
                </div>
              </div>

              <div className="formInput formInput-topicBusiness">
                <span className="label required">主题域/业务</span>
                <div className="content">
                  <SdwCascader
                    listWidth={200}
                    value={topicBusiness}
                    validateFun={value => this.validateFun(value, '主题域/业务')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({topicBusiness: val})}
                    data={topicData}
                  />
                </div>
              </div>

              <div className="formInput">
                <span className="label">单位</span>
                <div className="content">
                  <SdwSelect
                    value={target_unit}
                    onChange={val => this.setState({target_unit: val})}
                    data={unitList}
                  />
                </div>
              </div>

              <div className="formInput">
                <span className="label">描述</span>
                <div className="content">
                  <SdwInput
                    type="textarea"
                    value={comments}
                    placeholder="按人员维度、中心维度、地区维度计算近1天、近30天的建单量"
                    onChange={val => this.setState({comments: val})}
                  />
                </div>
              </div>

              <div className="formInput">
                <span className="label">颗粒指标</span>
                <div className="content">
                  <SdwSelect
                    value={granular_category == CUR_GRANULAR ? granular_category : +granular_category}
                    readOnly={false}
                    onChange={this.handleGranular}
                    data={allEnableTargetArr}
                  />
                </div>
              </div>

              <div disabled className="tabConfig">
                <Tabs activeName={target_type} onTabClick={ (tab) => { this.tabConfigChange(tab) }} >
                  <Tabs.Pane disabled={id > 0 && target_type!=1 ? true:false} label="基础型" name="1"></Tabs.Pane>
                  <Tabs.Pane disabled={id > 0 && target_type!=2 ? true:false} label="复合型" name="2"></Tabs.Pane>
                  <Tabs.Pane disabled={id > 0 && target_type!=3 ? true:false} label="代码型" name="3"></Tabs.Pane>
                </Tabs>
              </div>

              {/** target_type 1 */}
              {target_type == "1" && (
                <div className="formInput">
                  <span className="label required">原子指标</span>
                  <div className="content">
                    <SdwSelect
                      value={source_targets}
                      readOnly={false}
                      validateFun={value => this.validateFun(value, '原子指标')}
                      sholdCheckValidate={sholdCheckValidate}
                      onChange={val => { this.handleTargetChange(val) } }
                      data={atomTarget}
                    />
                  </div>
                </div>
              )}

              {target_type == "1" && (
                <div className="formInput formInput-decoration_ids">
                  <span className="label">修饰词</span>
                  <div className="content content-decoration_ids" style={{"width": "80%","marginLeft": "100px"}}>
                    <div className="content-decoration_ids-left" style={{"width": "50%", "boxSizing": "border-box", "display": "inline-block"}}>
                      <Select value={decoration_ids} multiple={true} onChange={val => this.setState({decoration_ids: val})}>
                        {
                          this.state.modifier instanceof Array && this.state.modifier.map(el => {
                            return <Select.Option key={el.id} label={el.modifier_name} value={el.id+''} />
                          })
                        }
                      </Select>
                    </div>

                    <div className="content-decoration_ids-right" style={{"width": "50%", "boxSizing": "border-box", "display": "inline-block"}}>
                      <SdwInput
                        value={decoration}
                        onChange={val => this.setState({decoration: val})}
                        placeholder="手动输入过滤逻辑"
                      />
                    </div>
                  </div>
                </div>
              )}

              {target_type == "1" && (
                <div className="formInput formInput-store_dimension">
                  <span className="label required">关联维度</span>
                  <div className="content">
                    {store_dimension instanceof Array && store_dimension.map((row, k) => {
                      return (
                        <div key={k} className="content-content" style={{ marginBottom: 10 }}>

                          {/* 查看element-react组件源码，他是按照option的顺序显示的value值，不符合交互预期，故使用自己封装的多选下拉组件 */}
                          {/* <Select filterable={true} value={store_dimension[k].id} multiple={true}  onChange={val => this.configDataChange(val, 'store_dimension', k)}>
                            {
                              dimensionData instanceof Array && dimensionData.map(el => {
                                return <Select.Option key={el.id} label={el.name} value={el.id} />
                              })
                            }
                          </Select> */}
                          <SdwSelect
                            placeholder="请选择"
                            isMultipleChoice={true} // 下拉多选
                            value={_.isArray(_.get(store_dimension, `[${k}].id`)) && store_dimension[k].id.map(i => {
                              return {
                                id: i,
                                text: _.isArray(dimensionData) && dimensionData.find(j => j.id === i) && dimensionData.find(j => j.id === i).name
                              }
                            })} // 数组对象
                            readOnly={false} // 配置为false：可以模糊搜索
                            width='90%'
                            data={dimensionData.map(i => {
                              return {
                                id: i.id,
                                text: i.name
                              }
                            })}
                            onChange={val => this.configDataChange(val.map(i => i.id), 'store_dimension', k)}
                          />

                          <i onClick={() => this.addVirtualProperty(k)} className="el-icon-plus" style={{ marginLeft: 8 }}></i>
                          {k > 0 && (<i onClick={() => this.removeVirtualProperty(k)} className="el-icon-minus"></i>)}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}

              {target_type == "2" && (
                <div className="formInput formInput-calculate">
                  <span className="label">计算逻辑</span>
                  <span className="content">
                    {/** 派生指标搜索 targetSeachData */}
                    <div className="calculate">
                      <div className="calculate-title">计算字段</div>
                      <div className="calculate-targetData">
                        <SdwInput
                          value={searchKey}
                          placeholder="请输入派生指标"
                          onChange={val => {this.calculateChange(val)}}
                        />
                        <div className="calculateList">
                          <ul>
                          {targetSeachData instanceof Array && targetSeachData.map((row, k) => {
                            return (
                              <li onClick={row => {this.calculateCulClick(targetSeachData[k])}}>{row.target_name_cn} ({row.target_name})</li>
                            )
                          })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/** 计算公式 btnList */}
                    <div className="calculate calculate-cul">
                      <div className="calculate-title">计算公式</div>
                      <div className="calculate-menu">
                          <ul>
                            {btnList instanceof Array && btnList.map((row, k) => {
                              return (
                                <li className="calculate-icon" onClick={row => {this.calculateCulSelect(btnList[k])}}>{row}</li>
                              )
                            })}

                            <li className="calculate-back" onClick={() => {this.calculateCulBack()}}>后退</li>

                            <li className="calculate-back calculate-add-num">
                              {!calculateNumState &&
                                <i className="el-icon-arrow-down" onClick={() => {this.calculateCulShowAddNum()}}></i>
                              }

                              {calculateNumState &&
                                <i className="el-icon-arrow-up" onClick={() => {this.calculateCulShowAddNum()}}></i>
                              }

                              {calculateNumState &&
                                <div className="add-num-input">
                                  <Input
                                    value={calculateNum}
                                    autosize={{ minRows: 9, maxRows: 9}}
                                    onChange={(val) => {this.calculateNumChange(val)}}
                                  />
                                  <Button onClick={() => {this.calculateCulSelect(calculateNum)}}>添加</Button>
                                </div>
                              }
                            </li>
                          </ul>
                      </div>
                      <div className="calculate-val">
                        <Input
                          disabled
                          value={selectedItem}
                          type="textarea"
                          autosize={{ minRows: 9, maxRows: 9}}
                          placeholder="请选择字段和表达式"
                        />
                      </div>
                    </div>
                  </span>
                </div>
              )}

              {target_type == "3" && (
                <>
                  <div className="formInput">
                    <span className="label">计算公式</span>
                    <span>
                      <SdwSelect
                        width="40%"
                        placeholder={"请选择"}
                        value={metric_type}
                        data={_.keys(metricTypeMap).map(key => {
                          return {
                            id: key,
                            text: metricTypeMap[key]
                          }
                        })}
                        onChange={val => this.setState({metric_type: val})}
                      />
                    </span>
                    <span className="content" style={{marginLeft: 8}}>
                      <SdwInput
                        width="40%"
                        placeholder="手动输入计算公式"
                        value={complex_script}
                        onChange={val => this.setState({complex_script: val})}
                      />
                    </span>
                  </div>
                  <div className="formInput">
                    <span className="label">参数配置</span>
                    <div className="content">
                      <SdwInput
                        width="80%"
                        placeholder="请输入相关参数"
                        value={complex_params}
                        onChange={val => this.setState({complex_params: val})}
                      />
                    </div>
                  </div>
                  <div className="formInput">
                    <span className="label">原子指标</span>
                    <div className="content">
                      <SdwSelect
                        value={source_targets}
                        placeholder={"请选择"}
                        readOnly={false}
                        onChange={val => { this.handleTargetChange(val) } }
                        data={atomTarget}
                      />
                    </div>
                  </div>

                  <div className="formInput formInput-store_dimension">
                    <span className="label">关联维度</span>
                    <div className="content">
                    {store_dimension instanceof Array && store_dimension.map((row, k) => {
                      return (
                        <div key={k} className="content-content" style={{ marginBottom: 10 }}>
                          <SdwSelect
                            placeholder="请选择"
                            isMultipleChoice={true} // 下拉多选
                            value={_.isArray(_.get(store_dimension, `[${k}].id`)) && store_dimension[k].id.map(i => {
                              return {
                                id: i,
                                text: _.isArray(dimensionData) && dimensionData.find(j => j.id === i) && dimensionData.find(j => j.id === i).name
                              }
                            })} // 数组对象
                            readOnly={false} // 配置为false：可以模糊搜索
                            width='90%'
                            data={dimensionData.map(i => {
                              return {
                                id: i.id,
                                text: i.name
                              }
                            })}
                            onChange={val => this.configDataChange(val.map(i => i.id), 'store_dimension', k)}
                          />

                          <i onClick={() => this.addVirtualProperty(k)} className="el-icon-plus" style={{ marginLeft: 8 }}></i>
                          {k > 0 && (<i onClick={() => this.removeVirtualProperty(k)} className="el-icon-minus"></i>)}
                        </div>
                      )
                    })}
                  </div>
                  </div>
                </>
              )}

              <div className="formInput formInput-time_schedule">
                <span className="label required">时间周期</span>
                <div className="content">
                  {time_schedule instanceof Array && time_schedule.map((row, k) => {
                    return (
                      <div className="formInput-time_schedule-content">
                        <Select placeholder="请选择时间周期" value={time_schedule[k].time_cycle} onChange={val => this.configDataChange(val, 'time_schedule_time_cycle', k)}>
                          {
                            timeCycle.map(el => {
                              return <Select.Option key={el.id} label={el.text} value={el.id} />
                            })
                          }
                        </Select>

                        <Select placeholder="请选择运行频率" value={time_schedule[k].run_cycle} onChange={val => this.configDataChange(val, 'store_dimension_run_cycle', k)}>
                          {
                            runCycle.map(el => {
                              return <Select.Option key={el.id} label={el.text} value={el.id} />
                            })
                          }
                        </Select>

                        <i onClick={() => this.addVirtualProperty2(k)} className="el-icon-plus"></i>
                        {k > 0 && (<i onClick={() => this.removeVirtualProperty2(k)} className="el-icon-minus"></i>)}
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="formInput formInput-enalble">
                <span className="label">是否启用</span>
                <SdwSwitch
                    value={enableState}
                    changeValue={val => {this.enableStateChange(val)}}
                />
              </div>

            </div>
          </SdwDrawer.Body>
        </SdwDrawer>

        {
          warningVisible &&
          <SdwDialog
            title='提示'
            hideHeader={true}
            visible={warningVisible}
            changeVisible={() => this.setState({warningVisible: false})}
            onCancelClick={() => this.setState({warningVisible: false})}
            onSureClick={() => this.setState({warningVisible: false})}
          >
            <ConfirmWin
              title='请检查指标配置'
              confirmData={errorMsgContent}
            />
          </SdwDialog>
        }
        {
          hasPriv('derivedCreateOrUpdate') ?
          (
            // 有编辑权限的弹窗
            showConfirmWin &&
            <SdwDialog
              title='提示'
              hideHeader={true}
              visible={showConfirmWin}
              changeVisible={bool => this.setState({showConfirmWin: bool})}
              onCancelClick={() => this.setState({showConfirmWin: false})}
              onSureClick={this.handleStoreOrUpdateTarget}
            >
              <ConfirmWin
                title='以下为该派生指标相关的派生指标，请确认是否改动！'
                confirmData={confirmData}
              />
            </SdwDialog>
          ) : (
            // 没有编辑权限的弹窗
            <SdwDialog
              title='指标修改审批'
              width={666}
              visible={showConfirmWin}
              changeVisible={bool => this.setState({showConfirmWin: bool})}
              onCancelClick={() => this.setState({showConfirmWin: false})}
              onSureClick={this.handleConfirm}
            >
              <div style={{
                marginBottom: 8
              }} className='required'>修改原因</div>
              <div>
                <Textarea
                  value={comment}
                  onChange={this.handleChangeComment}
                  placeholder='请填写修改原因'
                  error={commentError}
                />
              </div>

              {
                Array.isArray(confirmData) &&
                confirmData.length > 0 &&
                <>
                  <div style={{
                    marginTop: 16
                  }}>相关派生指标</div>
                  <ConfirmWin
                    hideTitle
                    confirmData={confirmData}
                  />
                </>
              }
            </SdwDialog>
          )
        }
      </div>
    )
  }
}

export default EditData
