import React, {PureComponent} from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwMessage from '@common/Message'
import SdwSwitch from '@common/Switch'
import {getStaffs} from '@api/alertRules'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../../index.css'
import {requestOptionsLOG} from '@config/config'

const baseURL = requestOptionsLOG.basename

class LogDetails extends PureComponent {

    state = {
        logServerList: [],
        params: {
            path: '',
            ipList: [],
            enable: 0,
            principal: '',
            rtx: '',
            extra: '',
            filebeatAdvancedConfigVOS: []
        },
        staffsList: [],
        advancedConfigList: [{id: 0}], // filebeat高级配置
        sholdCheckValidate: false,
        ipListData: [],
        allMembers: [], // rtx
        optionsList: [],
        filebeatConfigItemValueDTOS: []
    }

    componentDidMount() {
        this.initData()
        this.queryValidIpList()
        this.queryAdvancedConfig()
        this.getStaffs()
    }

    initData = () => {
        let {isEdit, currentItem} = this.props
        if (isEdit) {
            this.queryInputFileConfig(currentItem.id)
        }
    }

    // 查询采集文件配置信息
    queryInputFileConfig = (id) => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/input/queryInputFileConfig/' + id,
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then(data => {
                if (data && data.code === 100) {
                    let items = data.data || []
                    let params = {
                        id: items.id,
                        path: items.path || '',
                        ipList: (items.ipList || []).map(i => {
                            return {id: i, text: i}
                        }),
                        principal: '',
                        enable: items.enable || '',
                        extra: items.extra || '',
                        rtx: rtx,
                    }
                    this.setState({
                        params: params,
                        staffsList: (items.principal.split(',') || []).map(i => {
                            return {id: i, text: i}
                        }),
                        filebeatConfigItemValueDTOS: items.inputFileAdvancedConfigDTOS || []
                    }, ()=> {
                        this.queryAdvancedConfig()
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 查询高级配置信息
    queryAdvancedConfig = () => {
        let str = '?configLocation=' + 'ACCESS_CONFIGURATION'
        fetch(baseURL + '/sdw/filebeat/filebeat/queryAdvancedConfig' + str,
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then(res => {
            res.json().then((data) => {
                let list = data.data || []
                let {isEdit} = this.props
                let {filebeatConfigItemValueDTOS} = this.state
                this.setState({
                    optionsList: list
                })
                // 编辑状态下初始化
                if (isEdit) {
                    this.setState({
                        advancedConfigList: !_.isEmpty(filebeatConfigItemValueDTOS) && filebeatConfigItemValueDTOS.map(item => {
                            let filebeatConfigItemValueDTOS = []
                            // let configType = ''
                            list.forEach(i => {
                                if (i.id == item.configId) {
                                    filebeatConfigItemValueDTOS = i.filebeatConfigItemValueDTOS
                                    // configType = i.configType
                                }
                            })
                            return {
                                ...item,
                                // configType,
                                filebeatConfigItemValueDTOS: filebeatConfigItemValueDTOS.map(i => {
                                    return {
                                        id: i.id,
                                        text: i.configItemName,
                                        configItemId: i.configItemId,
                                        configItemValue: i.configItemValue
                                    }
                                }) || []
                            }
                        }) || [{id: 0}]
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 获取rtx
    getStaffs = () => {
        getStaffs().then(res => {
            if (res && res.data && res.data.code === 0) {
                let list = _.isArray(res.data.data) ? res.data.data : []
                this.setState({
                    allMembers: list.map(i => {
                        return {id: i.value, text: i.name}
                    })
                })
            }
        })
    }

    // 查询有效的机器ip
    queryValidIpList = () => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/filebeat/queryValidIpList',
            {
                method: "GET",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then(data => {
                if (data && data.code === 100) {
                    let items = data.data || []
                    this.setState({
                        ipListData: items.map(i => {
                            return {
                                id: i,
                                text: i
                            }
                        })
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    addVirtualGroup = () => {
        let item = {}
        let advancedConfigList = [...this.state.advancedConfigList]
        advancedConfigList.push(item);
        this.setState({advancedConfigList: advancedConfigList})
    }

    removeVirtualGroup = (index) => {
        let advancedConfigList = [...this.state.advancedConfigList]
        advancedConfigList.splice(index, 1);
        this.setState({advancedConfigList: advancedConfigList})
    }

    updateVirtualGroup = (val, type, index, item) => {

        let {optionsList} = this.state

        if (type == 'configId') {
            let advancedConfigList = [...this.state.advancedConfigList]
            optionsList.forEach(i => {
                if (i.configType == 'CONFIG_TYPE_SELECT') {
                    if (i.id == val) {
                        advancedConfigList[index].filebeatConfigItemValueDTOS = i.filebeatConfigItemValueDTOS.map(j => {
                            return {
                                ...j,
                                text: j.configItemName
                            }
                        })
                    }
                }
            })
            advancedConfigList[index].id = val
            advancedConfigList[index].configId = val
            advancedConfigList[index].configName = item.text
            // advancedConfigList[index].configType = item.configType
            this.setState({advancedConfigList: advancedConfigList})
        }

        if (type == 'configValueId') {
            let advancedConfigList = [...this.state.advancedConfigList]
            advancedConfigList[index].configValueId = val
            this.setState({advancedConfigList: advancedConfigList})
        }
    }

    setParamsData = (type, value) => {
        let {params} = this.state
        let obj = {}
        obj[type] = value
        let paramsClone = Object.assign({}, params, obj)
        this.setState({
            params: paramsClone
        })
    }

    // 写入或更新采集文件配置信息
    submitClick = () => {
        let {params, staffsList, advancedConfigList} = this.state
        let rtx = this.props.userInfo.name
        if (!params.path || params.ipList.length == 0 || staffsList.length == 0) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        params.ipList = params.ipList.map(i => {
            return i.text
        })
        params.principal = staffsList.map(i => {
            return i.id
        }).join(',')
        params.enable = params.enable == 1 ? true : false
        advancedConfigList.map(i => {
            if (!_.isEmpty(i.filebeatConfigItemValueDTOS)) {
                i.filebeatConfigItemValueVOS = i.filebeatConfigItemValueDTOS.filter(j => {
                    return j.id === i.configValueId
                })
                delete i.filebeatConfigItemValueDTOS
            }
            return i
        })
        params.filebeatAdvancedConfigVOS = advancedConfigList
        fetch(baseURL + '/sdw/filebeat/input/upsertInputFile',
            {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                if (data && data.code === 100 || data.code === 101) {
                    this.props.changeVisible(false)
                    this.props.collectionTaskSubmit(data)
                } else {
                    SdwMessage.error(data.message)
                    // this.props.changeVisible(false)
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    render() {
        let {
            params,
            staffsList,
            sholdCheckValidate,
            allMembers,
            advancedConfigList,
            optionsList
        } = this.state

        let {
            isEdit,
            logVisible,
            changeVisible,
        } = this.props

        console.log("render====advancedConfigList", advancedConfigList)

        return (
            <SdwDrawer
                title={isEdit ? "编辑日志文件" : "新增日志文件"}
                visible={logVisible}
                onIconClick={() => changeVisible(false)}
                onCancelClick={() => changeVisible(false)}
                onSureClick={this.submitClick}
            >
                <SdwDrawer.Body>
                    <div className='logAccess-win_warp'>
                        <div>
                            <span className='label required'>日志文件路径</span>
                            <SdwInput
                                placeholder='请输入日志文件路径'
                                value={params.path}
                                onChange={val => this.setParamsData("path", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value ? true : '任务名称不能为空'}
                            />
                        </div>
                        <div>
                            <span className='label required'>日志服务器</span>
                            <SdwSelect
                                placeholder="请选择日志文件所在服务器ip"
                                isMultipleChoice={true} // 下拉多选
                                value={params.ipList} // 数组对象
                                readOnly={false} // 配置为false：可以模糊搜索
                                data={this.state.ipListData}
                                onChange={val => {
                                    this.setParamsData("ipList", val)
                                }}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value.length ? true : '服务器ip不能为空'}
                            />
                        </div>
                        <div>
                            <span className='label'>高级配置</span>
                            <div className='content'>
                                {
                                    advancedConfigList instanceof Array && advancedConfigList.map((item, k) => {
                                        return (
                                            <div key={k} style={{marginBottom: 10}}>
                                                <SdwSelect
                                                    placeholder='请选择'
                                                    width={'40%'}
                                                    data={optionsList.map(i => {
                                                        return {
                                                            id: i.id,
                                                            text: i.configName,
                                                            // configType: i.configType
                                                        }
                                                    })}
                                                    value={item.configId}
                                                    onChange={(val, item) => this.updateVirtualGroup(val, 'configId', k, item)}
                                                />
                                                <span style={{marginRight: 8}}></span>
                                                <SdwSelect
                                                    placeholder='请先选择'
                                                    width={'40%'}
                                                    data={item.filebeatConfigItemValueDTOS}
                                                    value={item.configValueId}
                                                    onChange={val => this.updateVirtualGroup(val, 'configValueId', k, item)}
                                                />
                                                <i onClick={() => this.addVirtualGroup(k)} className='el-icon-plus'
                                                   style={{marginLeft: 8}}></i>
                                                {k > 0 && (<i onClick={() => this.removeVirtualGroup(k)}
                                                              className='el-icon-minus'></i>)}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span className='label required'>负责人</span>
                            <SdwSelect
                                readOnly={false}
                                isMultipleChoice={true}
                                placeholder='请输入日志文件负责人(对接开发人员)'
                                data={allMembers}
                                value={staffsList}
                                onChange={val => this.setState({staffsList: val})}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={value => !!value.length ? true : '负责人不能为空'}
                            />
                        </div>
                        <div>
                            <span className='label'>备注</span>
                            <SdwInput
                                type='textarea'
                                placeholder='请输入备注'
                                value={params.extra}
                                onChange={val => {
                                    this.setParamsData('extra', val)
                                }}
                            />
                        </div>
                        <div>
                            <span className='label required'>是否启用</span>
                            <SdwSwitch
                                value={params.enable}
                                changeValue={val => {
                                    this.setParamsData('enable', val)
                                }}
                            />
                        </div>
                    </div>
                </SdwDrawer.Body>
            </SdwDrawer>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(LogDetails))
