// 应用管理
import { request } from '../utils/request'

export function pageAppTables (params) {
  return request({ url: '/statistics/targetApi/pageAppTables', method: 'post', data: params })
}

export function pageAppTablesManage (params) {
  return request({ url: '/statistics/targetApi/pageAppTablesManage', method: 'post', data: params })
}

export function saveAppConf (params) {
  return request({ url: '/statistics/targetApi/saveAppConf', method: 'post', data: params })
}

export function updateAppConf (params) {
  return request({ url: '/statistics/targetApi/updateAppConf', method: 'post', data: params })
}

export function getTemplateConfList (params) {
  return request({ url: '/statistics/targetApi/getTemplateConfList', method: 'get', params: params})
}

export function saveTemplateConf (params) {
  return request({ url: '/statistics/targetApi/saveTemplateConf', method: 'post', data: params })
}
export function delTemplateConf (params) {
  return request({ url: '/statistics/targetApi/delTemplateConf', method: 'post', data: params })
}
//指标列表
export function pageApiTargets (params) {
  return request({ url: '/statistics/targetApi/pageApiTargets', method: 'post', data: params, timeout: 10000 })
}
//指标列表
export function previewTargets (params) {
  return request({ url: '/statistics/targetApi/previewTargets', method: 'post', data: params })
}
//指标属性检查
export function checkTarget (params) {
  return request({ url: '/statistics/targetApi/checkTarget', method: 'post', data: params })
}
//指标保存
export function saveApiTargets (params) {
  return request({ url: '/statistics/targetApi/saveApiTargets', method: 'post', data: params })
}
//指标删除
export function deleteApiTargets (params) {
  return request({ url: '/statistics/targetApi/deleteApiTargets', method: 'post', data: params })
}

//扩展字段-分组 一级分组过滤名称
export function getGroupTree (params) {
  return request({ url: '/statistics/masterTarget/getGroupTree', method: 'get', params: params})
}

//指标api模块重跑
export function runTargetResult (params) {
  return request({ url: '/statistics/targetApi/runTargetResult', method: 'post', data: params })
}

//获取修改的指标
export function getModifyTargets (params) {
  return request({ url: '/statistics/targetApi/getModifyTargets', method: 'get', params: params})
}
//发布API应用表
export function publishApi (params) {
  return request({ url: '/statistics/targetApi/publishApi', method: 'post', data: params })
}

//API应用表关联表
export function storeRelateTarget (params) {
  return request({ url: '/statistics/targetApi/storeRelateTarget', method: 'post', data: params })
}

//查询API应用表关联表详情
export function getRelateTarget (params) {
  return request({ url: '/statistics/targetApi/getRelateTarget', method: 'get', params: params })
}

//获取模块下的所有可以延迟重跑的指标
export function getDelayTargets (params) {
  return request({ url: '/statistics/targetApi/getDelayTargets', method: 'get', params: params })
}

//保存延迟重跑指标
export function saveApiDelay (params) {
  return request({ url: '/statistics/targetApi/saveApiDelay', method: 'post', data: params })
}

//查询模块详情
export function getTemplateDetail (params) {
  return request({ url: '/statistics/targetApi/getTemplateDetail', method: 'get', params: params })
}

// 获取所有的计算方式
export const getPublicCfg = (params = {}) => {
  return request({ url: '/dataset/common/getPublicCfg', method: 'get', data: params })
}
