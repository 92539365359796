import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './index.css'

class SdwInput extends Component {
  inputRef = React.createRef()

  static defaultProps = {
    width: '100%',
    height: 40,
    placeholder: '请输入',
    clearable: true,
    value: '',
    label: '',
    type: 'text',
    disabled: false,
    error: {
      isError: false,
      msg: ''
    },

    onChange: () => {},
    onBlur: () => {},
  }

  state = {
    inputValue: this.props.value || '',
    showClearIcon: false,
    isOnFocus: false,
    inputValueIsChange: false,
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.value !== this.state.inputValue) {
  //     this.setState({
  //       inputValue: this.props.value
  //     })
  //   }
  // }

  handleChange = e => {
    if (this.props.disabled) return
    let value = e.target.value
    this.setState({
      inputValue: value,
      inputValueIsChange: true
    })
    this.props.onChange(value)
  }
  
  handleBlur = e => {
    this.setState({
      isOnFocus: false,
      inputValueIsChange: false
    })
    this.props.onBlur(e.target.value)
  }

  handleClearInput = e => {
    e.preventDefault()
    this.setState({ inputValue: '' })
    this.props.onChange('')
    this.props.onBlur('')
  }

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      this.inputRef.current.blur()
    }
  }

  render() {
    let { type, placeholder, disabled, width, height, label, clearable, error } = this.props
    let { inputValue, showClearIcon, isOnFocus, inputValueIsChange } = this.state
    let inputClassName = !error.isError ? 'sdw-input3__wrap' : 'sdw-input3__wrap sdw-input3-error'

    let outDivClassName = 'sdw-input3__outer-div-warp'
    if (isOnFocus) outDivClassName += ' sdw-input3__on-focus'
    if (error.isError) outDivClassName += ' sdw-input3-error__wrap'
    
    return (
      <div className={outDivClassName} style={{ width, height }}>
        {
          !!label && <div className="sdw-input3-operation-label">{label}</div>
        }
        <div
          className="sdw-input3__div-wrap"
          style={{ width, height }}
          onMouseEnter={() => this.setState({ showClearIcon: true })}
          onMouseLeave={() => this.setState({ showClearIcon: false })}
        >
          <input
            ref={this.inputRef}
            value={inputValue}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            style={{ width, height }}
            className={inputClassName}
            onKeyDown={e => this.handleKeyDown(e)}
            onChange={e => this.handleChange(e)}
            onFocus={() => this.setState({ isOnFocus: true })}
            onBlur={e => {
              this.handleBlur(e)
              
            }}
          />
          {
            !disabled && clearable && showClearIcon && !!inputValue && 
            <i className='sdw-input3-clearable' onClick={this.handleClearInput}></i>
          }
          {
            !inputValueIsChange && error.isError && <div className='sdw-input3-error__tip'>{error.msg}</div>
          }
        </div>
      </div>
    )
  }
}

SdwInput.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func, // 按下enter键触发也会触发blur事件（看 handleKeyDown 函数）
  error: PropTypes.object, // 错误校验对象
}

export default SdwInput
