import _ from 'lodash'
import switchRoutes from './switchRoutes'
import MonitoringEye from '@pages/Monitor/MonitoringEye'
import MonitoringEyeDetail from '@pages/Monitor/MonitoringEye/MonitoringEyeDetail'
import QualityControl from '@pages/Monitor/QualityControl'
import EarlyWarningRules from '@pages/Monitor/QualityControl/EarlyWarningRules'
import BusinessIndicatorsOperation from '@pages/Monitor/BusinessIndicatorsOperation'
import MonitorManagement from '@pages/Monitor/MonitorManagement'
import KeyIndicatorAllocation from '@pages/Monitor/KeyIndicatorAllocation'

export const routerPath = '/admin/monitor'

export const bar = {
  rootUrl: routerPath,
  activeClassName: 'current',
  className: 'sdw-nav-item',
  name: '智慧运营台',
  children: [
    {
      icon: '',
      title: '分析监控眼',
      path: `${routerPath}/monitorManagement`,
    }, {
      icon: '',
      title: '热词监控眼',
      path: `${routerPath}/monitoringEye`,
    }, {
      icon: '',
      title: '预警监控眼',
      path: `${routerPath}/qualityControl`,
    }, {
      icon: '',
      title: '关键指标配置',
      path: `${routerPath}/keyIndicatorAllocation`,
    }, {
      icon: '',
      title: '运营配置',
      path: `${routerPath}/operationConfiguration`,
    }
    // 暂时隐藏
    // , {
    //   icon: '',
    //   title: '规则引擎',
    //   path: `http://kfrule.woa.com`,
    //   gotoPathState: 1,
    // }
  ],
  order: 5
}

const pages = {
  monitorManagement: {
    icon: 'icon-278',
    title: '分析监控眼',
    path: `${routerPath}/monitorManagement`,
    component: MonitorManagement,
  },
  MonitoringEye: {
    icon: 'icon-278',
    title: '热词监控眼',
    path: `${routerPath}/monitoringEye`,
    component: MonitoringEye,
  },
  MonitoringEyeDetail: {
    icon: 'icon-278',
    title: '热词监控眼详情',
    path: `${routerPath}/monitoringEyeDetail/:id`,
    component: MonitoringEyeDetail,
  },
  QualityControl: {
    icon: 'icon-278',
    title: '预警监控眼',
    path: `${routerPath}/qualityControl`,
    component: QualityControl
  },
  EarlyWarningRules: {
    icon: 'icon-278',
    title: '预警规则管理',
    path: `${routerPath}/earlyWarningRules`,
    component: EarlyWarningRules
  },
  KeyIndicatorAllocation: {
    icon: 'icon-278',
    title: '关键指标配置',
    path: `${routerPath}/keyIndicatorAllocation`,
    component: KeyIndicatorAllocation
  },
  BusinessIndicators: {
    icon: 'icon-278',
    title: '运营配置',
    path: `${routerPath}/operationConfiguration`,
    component: BusinessIndicatorsOperation
  }
}

// export const menus = [pages['home'], pages['voice'], pages['history'], pages['customer'], pages['worker']]

export const Router = switchRoutes({ routes: _.values(pages) })
