import React, { PureComponent } from 'react'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import { Mapping } from '../util'
import { getTablecfgFieldsAndStandardList } from '@services/Derived'
import SdwInput from '@common/Input'
import SdwRadiobox from '@common/Radiobox'
import { getIndicatorConfItems } from '@api/operationConfiguration'
import _ from 'lodash'

class AddArea extends PureComponent {
  state = {
    "measure_type_arr":[{"id":0,text:"求和"}, {"id":1,text:"计数"}],
  }
  render() {
    const { item, index, rankDimensionData ,timeCycle, baseInfo} = this.props
    const { complex_script, store_dimension, group_by_field, time_schedule } = item
    let time_schedule_obj = JSON.parse(baseInfo.time_schedule)
    let timeCycles = []
    for (let i in timeCycle) {
      for (let j in time_schedule_obj) {
        if (time_schedule_obj[j].time_cycle == timeCycle[i].id) {
          timeCycles.push(timeCycle[i])
        }
      }
    }
    let time_schedules = []
    for (let i in timeCycle) {
      for (let j in time_schedule) {
        if (time_schedule[j].time_cycle == timeCycle[i].id) {
          time_schedules.push(timeCycle[i])
        }
      }
    }
    const rankDimention = (store_dimension.length > 0 && store_dimension[0].id[0]) || ''
    // function
    const { showOrhideContent, setRankDimension, setAreaData, addVirtualArea, removeVirtualArea, enableStateChange, setAddAreaValue } = this.props
    return (
      <div>
        <div className="sdw-collapse-listitem">
          <div className="sdw-collapse-listitem-title" onClick={() => showOrhideContent(item, index, 'addData')}>
            <div className='sdw-collapse-listitem-title-text' style={{'color': item.enable ? '#265CF0' : '' }}>衍生指标-区间型{complex_script === 'asc' ? '(升序)' : complex_script === 'desc' ? '(降序)' : ''}{!!item.id ? `（${item.id}）`: ''}</div>
            <div><i className={`sdw-collapse__icon sdw-collapse__drop-down ${item.isShow ? 'sdw-collapse__drop-up' : ''}`}></i></div>
          </div>
          <div className="sdw-collapse-listitem-content" style={{ 'display': item.isShow ? 'block' : 'none' }}>
            <div className="form data-editor-win-wrap">
              <div className="formInput">
                <span className="label required">指标命名</span>
                <span className="content">
                  <SdwInput
                    width={'36%'}
                    placeholder={"请输入英文名"}
                    value={item.target_name}
                    validateFun={value => !!value ? true : '英文名不能为空'}
                    sholdCheckValidate={item.disabledCheck}
                    onChange={val => setAddAreaValue(index, "target_name", val)}
                  />
                  <span style={{ marginLeft: '1px' }} />
                  <SdwInput
                    width={'36%'}
                    placeholder={"请输入中文名"}
                    value={item.target_name_cn}
                    validateFun={value => !!value ? true : '中文名不能为空'}
                    sholdCheckValidate={item.disabledCheck}
                    onChange={val => setAddAreaValue(index, "target_name_cn", val)}
                  />
                </span>
              </div>
              <div className="formInput formInput-time_schedule">
                <span className="label required">指标属性</span>
                <div className="content ">
                  <div className="formInput-time_schedule-content">
                    <SdwSelect
                      clearable={false}
                      isMultipleChoice={false}
                      placeholder={'请选择维度'}
                      value={rankDimention}
                      data={rankDimensionData}
                      validateFun={value => !!value ? true : '维度不能为空'}
                      sholdCheckValidate={item.disabledCheck}
                      onChange={val => setRankDimension(index, val)} />
                    <span style={{ marginLeft: '1px' }} />
                    <SdwSelect
                      clearable={false}
                      isMultipleChoice={true}
                      placeholder={'请选择时间周期'}
                      value={time_schedules}
                      data={timeCycles}
                      onChange={val =>setAddAreaValue(index, "time_schedule",val)}
                      sholdCheckValidate={item.disabledCheck}
                      validateFun={value => {
                        return  !!value ? true : '时间周期不能为空'
                      }}
                    />

                </div>
              </div>
                <div className="formInput rank_type">
                <span className="label required">区间类型</span>
                <span className="content">
                  <SdwRadiobox
                    options={[{
                      text: '值区间',
                      value: "target_value"
                    }, {
                      text: '维度区间',
                      value: "service_value"
                    }]}
                    value={group_by_field}
                    sholdCheckValidate={item.disabledCheck}
                    changeCheckedFun={val => setAddAreaValue(index, "group_by_field",val)}
                  />
                </span>
              </div>
              </div>
              <div className="formInput">
                <span className="label required">统计方式</span>
                <span className="content">
                   <div className="formInput-time_schedule-content">
                     <SdwInput
                       width={'36%'}
                       disabled={group_by_field=="target_value"?true:false}
                       placeholder={"区间字段"}
                       value={group_by_field=="target_value"?"":item.metric_field}
                       validateFun={value => {
                         if (group_by_field=="target_value"){
                           return true
                         }
                         return !!value ? true : '区间字段不能为空'
                       }}
                       sholdCheckValidate={item.disabledCheck}
                       onChange={val => setAddAreaValue(index, "metric_field", val)}
                     />
                     <SdwSelect
                       placeholder="请选择度量方式"
                       value={item.complex_script}
                       width={250}
                       readOnly={false}
                       data={[{
                         id: "sum",
                         text: '求和',
                       }, {
                         id: "value_count",
                         text: ' 计数',
                       }]}
                       sholdCheckValidate={item.disabledCheck}
                       validateFun={value => !!value ? true : '度量方式不能为空'}
                       onChange={val => setAddAreaValue(index, "complex_script", val)}
                     />
                   </div>

                </span>
              </div>
              <div className="formInput formInput-time_schedule">
                <span className="label required">区间设置</span>
                <div className="content">
                  {
                    Array.isArray(item.cal_expression) && item.cal_expression.map((row, k) => {
                      return (
                        <div className="formInput-time_schedule-content" key={k}>
                          <SdwInput
                            width={'10%'}
                            placeholder={"区间名称"}
                            value={row.range_name}
                            validateFun={value => !!value ? true : '区间名称不能为空'}
                            sholdCheckValidate={item.disabledCheck}
                            onChange={val => setAreaData(val, 'range_name', index , k)}
                          />
                          <SdwInput
                            width={'10%'}
                            placeholder={"请输入"}
                            value={row.gte}
                            validateFun={(value)=>{
                              if (!value) {
                                return '此处不能为空'
                              }
                              let val = Number(value)
                              if (isNaN(val)) {
                                return '只能填写数值'
                              }
                            }}
                            sholdCheckValidate={item.disabledCheck}
                            onChange={val => setAreaData(val, 'gte', index , k, "start")}
                          />
                          <SdwInput
                            width={'10%'}
                            placeholder={"请输入"}
                            value={row.lt}
                            validateFun={(value)=>{
                              if (!value) {
                                return '此处不能为空'
                              }
                              let val = Number(value)
                              if (isNaN(val)) {
                                return '只能填写数值'
                              }
                            }}
                            sholdCheckValidate={item.disabledCheck}
                            onChange={val => setAreaData(val, 'lt', index, k, "end")}
                          />
                          <i onClick={() => addVirtualArea(index)} className="el-icon-plus"></i>
                          { k > 0 && (<i onClick={() => removeVirtualArea(k, index)} className="el-icon-minus"></i>)}
                        </div>
                      )
                    })
                  }

                </div>
              </div>
              <div className="formInput">
                <span className="label">是否启用</span>
                <div className="content el-switch-enable">
                  <SdwSwitch
                    value={item.enable}
                    changeValue={val => enableStateChange(val, item, index,'addData')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddArea
