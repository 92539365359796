import UILayout from '@components/Layout/UILayout'
import UILayoutNavbar from '@components/Layout/UILayoutNavbar'
// import UILayoutSidebar from '@components/Layout/UILayoutSidebar'
import FrontendAuth from '@components/FrontendAuth'
//import CountPage from '@pages/Count/CountPage'
import NoRight from '@pages/NoRight'
import React, { PureComponent } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { login } from '@services/userService'
import { getItermSelects } from '@services/dataStandars'
import { record } from '@services/dataVisualization'

// 获取routers下所有的xxxxxRouter.js
const modules = require.context('./', true, /Router\.js$/)
const Routers = modules.keys().map(key => modules(key))

// 顶部导航栏
const bars = Routers.map(router => {
  return router.bar
})

class Admin extends PureComponent {

  state = {
    showComponets: false
  }

  // 跳转无权限页面
  jumpToNoRightPage = () => {
    this.props.history && this.props.history.push('/noright')
  }

  componentDidMount() {
    // 登录逻辑
    login().then(response => {
      let baseURL = response.config.baseURL
      if (response.data.code === 1005) {
        window.location.href = baseURL + '/auth/login/index?url=' + encodeURIComponent(window.location.href)
        return
      } else if (response.data.code === 0) {
        // 将权限存入storage
        let permissions = _.get(response, 'data.data.permissions')
        sessionStorage.setItem('permissions', permissions)

        // 已登录 | 登录成功 把userInfo存起来
        this.props.updateUserInfo(response.data.data)

        this.setState({
          showComponets: true
        })

        // 登录成功后，将topic、busines存入redux，很多地方使用的
        setTimeout(this.onGetItermSelects, 0);

        // // 登陆成功后，接入h5的自动化埋点
        // if (window.__app_env__.NODE_ENV === 'production') {
        //   const script = document.createElement("script")

        //   if (window.__app_env__.REACT_APP_DOMAIN.indexOf('test') !== -1) {
        //     script.src = "https://kftest.qq.com/touch/js/module/page_log.js" // 测试环境先用kftest
        //   } else {
        //     script.src = "https://kf.qq.com/touch/js/module/page_log.js" // 现网
        //   }
        //   script.async = true
        //   document.body.appendChild(script)
        // }
      } else {
        this.jumpToNoRightPage()
      }
    })
    .catch((error) => {
      // 定义code=500时表示异常返回
      // 跳转无权限页面
      this.jumpToNoRightPage()
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.onRecord()
  }

  onRecord = () => {
    let url = this.props.history.location.pathname
    let params = {
      'log_url': url,
      'log_type': 'enter'
    }
    record({params}).then()
  }

  onGetItermSelects = () => {
    const { updateTopic, updateBusiness, updateStandardType } = this.props
    getItermSelects().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const topic = _.get(res, 'data.data.topic')
        const business = _.get(res, 'data.data.business')
        const standardType = _.get(res, 'data.data.standard_type')

        if (!_.isEmpty(topic)) updateTopic(topic)
        if (!_.isEmpty(business)) updateBusiness(business)
        if (!_.isEmpty(standardType)) updateStandardType(standardType)
      }
    })
  }

  render() {
    // const location = useLocation()

    // let menus = []
    // for (const router of Routers) {
    //   if (location.pathname.slice(0, router.routerPath.length) === router.routerPath) {
    //     menus = router.menus
    //   }
    // }
    return (
      this.state.showComponets &&
      <UILayout>
        <UILayout.Top>
          <UILayoutNavbar bars={bars} />
        </UILayout.Top>
        <UILayout.Content>
          <Switch>
            {/* {Routers.map(router => <Route key={router.routerPath} path={router.routerPath} component={router.Router} />)}
            <Redirect to="/admin/dataMarket/dictionary" from="*" /> */}
            <FrontendAuth Routers={Routers} />
          </Switch>
        </UILayout.Content>
      </UILayout>
    )
  }
}

const mapState = state => ({
  user: state.user,
})

const mapDispatch = dispatch => ({
  updateUserInfo: dispatch.user.updateUserInfo,
  updateTopic: dispatch.topic.updateTopic,
  updateBusiness: dispatch.topic.updateBusiness,
  updateStandardType: dispatch.topic.updateStandardType,
})

const AdminRouter = connect(mapState, mapDispatch)(Admin)

const RootRouter = () => (
  <Switch>
    <Route path="/admin" component={AdminRouter} />
    {/* <Route path="/login" component={CountPage} />
    <Route path="/logout" component={CountPage} /> */}
    <Route path="/noright" exact component={NoRight} />
    <Redirect to="/admin" from="*" />
  </Switch>
)

export default RootRouter
