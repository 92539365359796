import React, { PureComponent } from 'react'
import { Tabs } from 'element-react'
import './index.css'
import Dimen from './dimen'
import DimenMap from './dimenMap'
import SdwBreadCrumbs from '../../../common/BreadCrumbs'

class Atom extends PureComponent {
  state = {
    activeName: '1',
    visible: false,
  }

  // 初始化
  componentDidMount() {

  }

  render () {
    var { activeName, visible } = this.state;
    return (
      <div className="machFactory Dimension">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '指标加工厂',
              path: '/admin/factory/atom'
            }, {
              title: '维度管理'
            }
          ]}
        />

        <div className="page-mainbody">
          {/** tab页 */}
          <Tabs activeName={activeName} onTabClick={ (tab) => console.log(tab.props.name) }>
            <Tabs.Pane label="维度" name="1">
              <Dimen />
            </Tabs.Pane>
            <Tabs.Pane label="映射关系" name="2">
              <DimenMap />
            </Tabs.Pane>
          </Tabs>
        </div>

      </div>
    )
  }
}

export default Atom
