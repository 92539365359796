import React, { PureComponent } from 'react'
import SdwTable from '../../../common/Table'
import SdwPagenation from '../../../common/Pagenation'
import { Button } from 'element-react'
import './index.css'
import { getPageMap, getTablecfgList, dimMappingAllList, getPropertySourceSqlTables, getDimenMapById } from '@services/dimen'
import EditData from './components/EditMapData'
import SdwSelect from '../../../common/Select'
import SdwInput from '../../../common/Input'
import SdwLoading from '@common/Loadding'

class Atom extends PureComponent {
  state = {
    dataList: [],
    search: {
      map_name_cn: { value: '', operator: 'like' },
      map_name_en: { value: '', operator: 'like' },
      id: { value: '', operator: '=' },
      source_dim: { value: '', operator: '=' },
      target_dim: { value: '', operator: '=' }
    },
    topicData: [],
    pagination: {
      total: 0,
      per_page: 10,
      pages: 0,
      current_page: 0,
    },
    topicDic: {},
    topicServerDic: {},
    curTitle: '',
    curRecord: {},
    visible: false,
    tableList: [],
    cfgData: {
      sourceDimList: [],
      targetDimList: []
    },
    sourceDimListDic: {},
    targetDimListDic: {},
    propertySourceSqlTables: [],
    tableShowData: false
  }

  // 初始化
  componentDidMount() {
    this.getOptions()
    this.getPageData()
    this.getTablecfgList()
    // 获取所有sql来源表选项列表
    this.getPropertySourceSqlTables()
  }

  // 获取所有的表
  getTablecfgList = () => {
    let that = this
    getTablecfgList({ limit: 10000, page: 0, search: { enable: { value: '1', operator: "=" } } }).then(res => {
      let tableList = res.data.data.items || []
      that.setState({tableList: tableList})
    })
  }

  /**
   * 获取所有sql来源表选项列表
   */
  getPropertySourceSqlTables () {
    const data = {}
    if(this.state.propertySourceSqlTables && this.state.propertySourceSqlTables.length > 0){
      return
    }

    getPropertySourceSqlTables(data, response => {
      if (response.data.code == 0) {
        let propertySourceSqlTables = []
        let tables = response.data.data
        for(let i=0; i<tables.length; i++) {
          let id = tables[i].id;
          let databaseName = tables[i].database_name;
          let tableName = tables[i].table_name;
          let dataSetChinese = tables[i].data_set_chinese;

          let item = {
            value: id,
            label: dataSetChinese + '[' + tableName +']',
            children: [],
          }

          propertySourceSqlTables.push(item)
        }
        this.setState({propertySourceSqlTables: propertySourceSqlTables})
      }
    })
  }

  getOptions = () => {
    dimMappingAllList().then(res => {
      if (res.data.code === 0) {
        let data = res.data.data || this.state.cfgData

        let cfgData =  {
          sourceDimList: [],
          targetDimList: []
        }
        cfgData.sourceDimList = data.source || []

        let sourceDimListDic = {}
        for (let i=0; i<cfgData.sourceDimList.length; i++) {
          let id = cfgData.sourceDimList[i].id;
          sourceDimListDic[id] = cfgData.sourceDimList[i];
        }

        cfgData.targetDimList = data.target || []

        this.setState({
          cfgData: cfgData,
          sourceDimListDic: sourceDimListDic
        })

        let targetDimListDic = {}
        for (let i=0; i<cfgData.targetDimList.length; i++) {
          let id = cfgData.targetDimList[i].id;
          targetDimListDic[id] = cfgData.targetDimList[i];
        }
        this.setState({
          targetDimListDic: targetDimListDic
        })
      }
    })
  }

  // 获取分页数据
  getPageData = () => {
    let that = this
    var params = {}
    params.page = this.state.pagination.current_page || 0
    params.limit = this.state.pagination.per_page
    params.search = {}
    params.orSearch = {}

    for (const i in this.state.search) {
      const dval = this.state.search[i]
      if (dval.value) {
        if(i != "id") {
          params.search[i] = dval
        }
        if(i == "id") {
          let param = { value: `%${dval.value}%`, operator: 'like' } // 支持模糊搜索
          params.orSearch[i] = dval
          params.orSearch["map_name_cn"] = param // 中文名支持模糊搜索
          params.orSearch["map_name_en"] = dval
        }
      }
    }

    that.setState({tableShowData: false})
    getPageMap(params).then((res) => {
      that.setState({tableShowData: true})

      let items = res.data.data.items

      if(!items) {
        items = []
      }

      for (let i=0; i<items.length; i++) {
        let sourceDimId = items[i].source_dim
        let targetDimId = items[i].target_dim

        items[i]["source_dim_name"] = ""
        if(this.state.sourceDimListDic.hasOwnProperty(sourceDimId)){
          items[i]["source_dim_name"] = this.state.sourceDimListDic[sourceDimId].dim_name_cn
        }

        items[i]["target_dim_name"] = ""
        if(this.state.targetDimListDic.hasOwnProperty(targetDimId)){
          items[i]["target_dim_name"] = this.state.targetDimListDic[targetDimId].dim_name_cn
        }
      }

      let pagination = that.state.pagination
      pagination.total = res.data.data.total
      pagination.per_page = res.data.data.per_page
      pagination.pages = res.data.data.pages
      pagination.current_page = res.data.data.current_page
      that.setState({
        dataList: [...items],
        pagination: {...pagination},
      })
    })
  }

  // 获取表标题
  getcolumns = () => {
    let selt = this
    const columns = [
      {
          title: "中文名",
          dataIndex: "map_name_cn",
      },
      {
          title: "英文名",
          dataIndex: "map_name_en",
      },
      {
          title: "来源维度",
          dataIndex: "source_dim_name",
      },
      {
          title: "目标维度",
          dataIndex: "target_dim_name",
      },
      {
          title: "修改人",
          dataIndex: "modify_user",
      },
      {
          title: "修改时间",
          dataIndex: "modify_time",
      },
      {
          title: "操作",
          width: "50",
          render: (data, record) => {
              return (
                  <div style={{'color': '#517df3', 'cursor': 'pointer'}} onClick={ () => { selt.onClickGetOneData(data, selt) } }>编辑</div>
              )
          },
      }
    ]
    return columns
  }

  // 获取编辑的行记录
  onClickGetOneData = (data, e) => {
    getDimenMapById({ id: data.id }).then(res => {
      if (res.data.code == 0) {
        if (res.data.data.form.length > 0) {

          let info = res.data.data.form[0]
          let attrs = res.data.data.propertyInfo

          let propertyTableId = [];
          if(info.tableFieldSelectList != "" && info.tableFieldSelectList) {
            let properList = JSON.parse(info.tableFieldSelectList) || []
            for(let i=0; i<properList.length; i++) {
              let selectdata = properList[i]
              let item = [selectdata[0], selectdata[1]]
              propertyTableId.push(item)
            }
          }
          if(propertyTableId.length == 0) {
            propertyTableId = [['', '']]
          }

          var virtualPropertyListDic = [
            {
              "id": "",
              "source_attr_id": "",
              "target_attr_id": "",
            }
          ]

          if(info.virtualPropertyListDic != "") {
            virtualPropertyListDic = []
            let plist = JSON.parse(info.virtualPropertyListDic)
            for(let i = 0; i<plist.length; i++) {
              virtualPropertyListDic[0] = {
                "id": "",
                "source_attr_id": "",
                "target_attr_id": "",
              }
              let val = JSON.parse(plist[i])
              virtualPropertyListDic[0].source_attr_id = ""
              if(val.hasOwnProperty("source_attr_id")) {
                virtualPropertyListDic[0].source_attr_id = val.source_attr_id
              }
              virtualPropertyListDic[0].target_attr_id = "";
              if(val.hasOwnProperty("target_attr_id")) {
                virtualPropertyListDic[0].target_attr_id = val.target_attr_id
              }
            }
          }

          var formdata = {
            "id": info.id,
            "map_name_cn": info.map_name_cn,
            "map_name_en": info.map_name_en,
            "source_dim": parseInt(info.source_dim)  || "",
            "target_dim": parseInt(info.target_dim)  || "",
            "propertyTableId": propertyTableId,
            "virtualPropertyList": virtualPropertyListDic,
            "virtualPropertyTwoList": [
              {
                "id": "",
                "source_attr_id": "",
                "target_attr_id": "",
              }
            ],
            "mapping_type": info.mapping_type,
          }

          if (attrs && attrs.length > 0) {
            formdata.virtualPropertyTwoList = [];
            for(let i=0; i<attrs.length; i++) {
              let item = {
                "id": attrs[i].id,
                "source_attr_id": attrs[i].source_attr_id  || "",
                "target_attr_id": attrs[i].target_attr_id  || "",
              }
              formdata.virtualPropertyTwoList.push(item);
            }
          }

          this.setState({
            curRecord: formdata,
            visible: true,
            curTitle: '编辑映射'
          })
        }
      }
    })
  }

  // 获取当前页码
  getClickPage = (pageNum) => {
    this.state.pagination.current_page = pageNum
    this.getPageData()
  }

  // 获取当前每页总条数
  pageCountChange = (per_page) => {
    this.state.pagination.per_page = per_page
    this.getPageData()
  }

  // 表格数据双向绑定
  SeachHandleChange(key, value) {
    this.state.pagination.current_page = 1

    if(key == "id") {
      this.state.search.id.value = value;
    }

    if(key == "source_dim") {
      this.state.search.source_dim.value = value;
      this.getPageData()
    }

    if(key == "target_dim") {
      this.state.search.target_dim.value = value;
      this.getPageData()
    }

    this.forceUpdate();
  }

  addOrEdit = (record = {}) => {
    this.setState({
      visible: true,
      curTitle: record.title,
      curRecord: record,
    })
  }

  onSureClick = () => {
    this.setState({
      visible: false,
    })
    this.getPageData()
  }

  render () {
    var { search, pagination, curTitle, visible, curRecord, topicData, tableList, cfgData, propertySourceSqlTables, sourceDimListDic, targetDimListDic, tableShowData } = this.state;

    let dataList = []
    for (let i=0; i<this.state.dataList.length; i++) {
      let items = this.state.dataList[i]
      let sourceDimId = items.source_dim
      let targetDimId = items.target_dim

      items["source_dim_name"] = ""
      if(this.state.sourceDimListDic.hasOwnProperty(sourceDimId)){
        items["source_dim_name"] = this.state.sourceDimListDic[sourceDimId].dim_name_cn
      }

      items["target_dim_name"] = ""
      if(this.state.targetDimListDic.hasOwnProperty(targetDimId)){
        items["target_dim_name"] = this.state.targetDimListDic[targetDimId].dim_name_cn
      }
      dataList.push(items)
    }

    return (
      <div>
        {/** 搜索区域 */}
        <div className="page-search">
          <div className="input-wrap">

            <span style={{ marginRight: '8px' }}>
              <SdwInput
                width={180}
                label="映射名称"
                placeholder="支持ID、中文、英文"
                value={search.id.value}
                onBlur={this.getPageData}
                onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                onChange={val => this.setState({
                  pagination:Object.assign({}, pagination, {
                    current_page:1
                  }),
                  search: Object.assign({}, search, {
                    id: Object.assign({}, search.id, {
                      value: val
                    })
                  })
                })}
              />
            </span>

            <span style={{ marginRight: '8px' }}>
              <SdwSelect
                placeholder="全部"
                width={180}
                label="来源维度"
                readOnly={false}
                value={search.source_dim.value}
                data={this.state.cfgData.sourceDimList.map(el => {
                  return {
                    id: el.id,
                    text: el.dim_name_cn + '[' + el.dim_name_en + ']'
                  }
                })}
                onChange={val => {
                  this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      source_dim: Object.assign({}, search.source_dim, {
                        value: val
                      })
                    })
                  }, this.getPageData)
                }}
              />
            </span>

            <span style={{ marginRight: '8px' }}>
              <SdwSelect
                placeholder="全部"
                width={180}
                label="目标维度"
                readOnly={false}
                value={search.target_dim.value}
                data={this.state.cfgData.targetDimList.map(el => {
                  return {
                    id: el.id,
                    text: el.dim_name_cn + '[' + el.dim_name_en + ']'
                  }
                })}
                onChange={val => {
                  this.setState({
                    pagination:Object.assign({}, pagination, {
                      current_page:1
                    }),
                    search: Object.assign({}, search, {
                      target_dim: Object.assign({}, search.target_dim, {
                        value: val
                      })
                    })
                  }, this.getPageData)
                }}
              />
            </span>

            <div className="btn-group" style={{ top: 0 }}>
              <Button
                type="primary"
                icon='plus'
                onClick={ () => this.addOrEdit({ title: '新增映射', curRecord:{} }) }
              >新增</Button>
            </div>
          </div>

        </div>

        {/** 分页显示数据区域 */}
        <div className="tableShowData">
          <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
            <SdwTable
              tdWhiteSpace={true}
              columns={this.getcolumns()}
              dataSource={dataList}
            />
          </SdwLoading>

          <SdwPagenation
            total={pagination.total}
            currentPage={pagination.current_page}
            pageCount={pagination.per_page}
            getClickPage={this.getClickPage}
            pageCountChange={this.pageCountChange}
          />
        </div>

        {/** 编辑弹窗 */}
        <EditData
          title={curTitle}
          visible={visible}
          record={curRecord}
          topicData={topicData}
          tableList={tableList}
          cfgData={cfgData}
          sourceDimListDic={sourceDimListDic}
          targetDimListDic={targetDimListDic}
          propertySourceSqlTables={propertySourceSqlTables}
          onIconClick={() => this.setState({ visible: false })}
          onCancelClick={() => this.setState({ visible: false })}
          onSureClick={() => {
            this.setState({ visible: false })
            this.onSureClick()
          }}
        />
      </div>
    )
  }
}

export default Atom
