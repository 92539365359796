import { useState, useEffect } from 'react'
import _ from 'lodash'
import SdwMessage from '@common/Message'
import SdwRadiobox from '@common/Radiobox'
import SdwSelect from '@common/Select'
import { getMonitorServerConfigList, setMonitorJoinLogConfig } from '@services/dataAccess'

export default function DataLogWin (props) {

  const [isBreakTable, setIsBreakTable] = useState(0)
  const [logArr, setLogArr] = useState([])
  const [logArrOptios, setLogArrOptios] = useState([])

  useEffect(() => {
    getMonitorServerConfigList({
      limit: 1000
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const items = _.get(res, 'data.data.items')
        
        if (_.isArray(items)) {
          setLogArrOptios(items.filter(j => j.is_valid).map(i => ({
            ...i,
            text: `${i.service_name}(${i.id})`
          })))
        }
      }
    })
  }, [])

  useEffect(() => {
    const monitorIDList = _.get(props, 'data.monitorIDList') || []
    const task_type = _.get(props, 'data.task_type') || 0
    if (_.isArray(logArrOptios) && logArrOptios.length && monitorIDList.length) {
      // console.log('monitorIDList: ', monitorIDList);
      setLogArr(logArrOptios.filter(i => monitorIDList.includes(i.id)))
    }
    setIsBreakTable(task_type)
  }, [logArrOptios, props.data])

  props.curRef.current = handleSubmit

  function handleSubmit() {
    // console.log('isBreakTable: ', isBreakTable, logArr, props.data);
    const monitorIDList = _.isArray(logArr) ? logArr.map(i => i.id).join() : ''
    const configID = _.get(props, 'data.id')
    if (!configID) return SdwMessage.info('参数错误，请联系管理员！')
    // if (isBreakTable === 0 && logArr.length > 5) return SdwMessage.info('最大支持5个！')
    setMonitorJoinLogConfig({
      configID: props.data.id,
      taskType: isBreakTable,
      ...isBreakTable === 0 ? {
        monitorIDList
      } : {}
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          SdwMessage.success('提交任务成功')
          props.changeVisible(false)
        } else {
          let msg = res && res.data && res.data.msg || ''
          SdwMessage.error('提交任务失败：' + msg)
        }
      })
      .catch(err => {
        SdwMessage.error('接口异常：' + err)
      })
  }

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <span style={{ display: 'inline-block', width: 78 }} className='required'>埋点方式：</span>
        <SdwRadiobox
          options={[{
            text: '日志文件',
            value: 0
          }, {
            text: 'HTTP',
            value: 1
          }]}
          value={isBreakTable}
          changeCheckedFun={val => setIsBreakTable(val)}
        />
        
        {
          isBreakTable === 0 &&
          <div style={{ marginTop: 16 }}>
            <SdwSelect
              placeholder="请选择需要接入的监听服务,可选择多个"
              isMultipleChoice={true}
              value={logArr}
              data={logArrOptios}
              readOnly={false}
              width={'100%'}
              listWidth={'92%'}
              onChange={setLogArr}
            />
          </div>
        }
      </div>
      {
        isBreakTable === 1 &&
        <>
          <span style={{ display: 'inline-block', width: 78 }}>CMD：</span>
          <span>{ _.get(props, 'data.id') || '' }</span>
        </>
      }
    </>
  )
}