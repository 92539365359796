import React, { PureComponent } from 'react'
import { Button, Dialog, Form, Input } from 'element-react'
import SdwMessage from '../../../common/Message'
import { storeNewBusiness } from '../../../services/subjectDomainDetail'
import { EXTRA_MAP, EXTRA_MAP_TEXT } from './../common'
import SdwTable from '../../../common/Table'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import 'element-theme-default'
import './style/standardDistribution.css'

class StandardDistribution extends PureComponent {
  formRef = React.createRef()
  
  state = {
    dialogVisible: false,
    dialogTitle: '',
    form: {},
    rules: {
      name: [
        { required: true, message: '请输入分类名称', trigger: 'blur' }
      ]
    },
    curStandardItem: {},
  }

  getColumns = () => {
    let self = this
    return [
      {
        title: "分类",
        dataIndex: "name"
      },
      // {
      //   title: "数据标准",
      //   dataIndex: "total"
      // },
      {
        title: EXTRA_MAP_TEXT[EXTRA_MAP.primordial],
        dataIndex: "primordial"
      },
      {
        title: EXTRA_MAP_TEXT[EXTRA_MAP.atom],
        dataIndex: "atom"
      },
      {
        title: EXTRA_MAP_TEXT[EXTRA_MAP.derive],
        dataIndex: "derive"
      },
      {
        title: "操作",
        width: 80,
        render: function (data, record) {
          return (
            (data.business_id === 0 || !hasPriv('storeNewBusiness')) ? 
            <span>-</span> : 
            <span>
              <Button 
                type="text" 
                size="small"
                onClick={() => self.handleEdit(data)}
                style={{"fontSize": "14px"}}
              >编辑</Button>
            </span>
          )
        }
      }
    ]
  }

  handleAdd = () => {
    this.formRef.current.resetFields()
    this.setState({
      dialogTitle: '新增分类',
      dialogVisible: true,
      form: {},
      curStandardItem: {},
    })
  }

  handleEdit = item => {
    this.formRef.current.resetFields()
    this.setState({
      dialogTitle: '编辑分类',
      dialogVisible: true,
      form: {
        name: item.name
      },
      curStandardItem: item,
    })
  }

  onSubmit = () => {
    this.formRef.current.validate((valid) => {
      if (valid) {
        let { curStandardItem, form } = this.state
        let { topic_id, topic_name } = this.props
        storeNewBusiness({
          ...curStandardItem.business_id ? {
            id: curStandardItem.business_id
          } : {},
          business_name: form.name,
          topic_id: topic_id,
          topic_name: topic_name,
        })
          .then(res => {
            if(res && res.data.code === 0) {
              let msg = curStandardItem.business_id ? '编辑成功' : '创建成功'
              SdwMessage.success(msg)
              this.setState({
                dialogVisible: false
              })
              this.props.onBusinessStatisticByTopic()
            } else {
              SdwMessage.error(res && res.data && res.data.msg)
            }
          })
      } else {
        SdwMessage.error('表单未校验通过!')
        return false
      }
    })
  }

  render () {
    let tableData = this.props.data.filter(i => i.type !== 'all')
    return (
      <div className="border-radius-4px">
        <div className="standard-distribution__title-wrap">
          <span>数据标准分布</span>
          {
            hasPriv('storeNewBusiness') &&
            <Button
              className="standard-distribution__title-button"
              type="text" 
              icon="plus"
              onClick={this.handleAdd}
            >添加分类</Button>
          }
        </div>

        <div style={{ padding: '20px', background: '#fff' }}>
          <SdwTable
            tbodyHeight="322px"
            columns={ this.getColumns() }
            dataSource={ tableData || [] }
          />
        </div>

        <Dialog
          title={this.state.dialogTitle}
          size="tiny"
          visible={ this.state.dialogVisible }
          onCancel={ () => this.setState({ dialogVisible: false }) }
          lockScroll={ false }
        >
          <Dialog.Body>
            <Form ref={this.formRef} model={this.state.form} rules={this.state.rules} labelWidth="100" className="demo-ruleForm">
              <Form.Item label="所属主题域">
                <Input disabled value={this.props.topic_name} />
              </Form.Item>
              <Form.Item label="分类名称" prop="name">
                <Input type="text" value={this.state.form.name} onChange={ value => this.setState({
                  form: { ...this.state.form, name: value }
                }) } />
              </Form.Item>
            </Form>
          </Dialog.Body>
          <Dialog.Footer className="dialog-footer">
            <Button onClick={ () => this.setState({ dialogVisible: false }) }>取消</Button>
            <Button type="primary" onClick={() => this.onSubmit()}>确定</Button>
          </Dialog.Footer>
        </Dialog>
      </div>
    )
  }
}

export default StandardDistribution
