import _ from 'lodash'

// 前端路由对应权限接口字段，以下是需要校验的前端路由（没有校验的路由默认允许访问）
export const authMap = { // 路由级别、导航菜单选项，需要进行路由拦截层面
  '/admin/factory/atom': ['dataset/atomTargetV2/items'], // 获取原子指标
  '/admin/dataMarket/standard': ['statistics/dataMarket/searchFields'], // 字段标准页搜索字段(数据标准)
  '/admin/factory/modifier': ['dataset/modifier/getModifierList'], // 查询修饰词
  '/admin/factory/dimension': ['dataset/process/dimensionList'], // 查询维度信息
  '/admin/factory/derived': ['dataset/targetV2/items'], // 获取所有派生指标
  '/admin/monitor/operationConfiguration': ['operation/indicatorOperationConf/getIndicatorConfItems', 'operation/indicatorOperationConf/getArchiveCodeConfItems'], // 获取指标运营配置、归档项L4配置
  '/admin/dataMarket/dictionary': ['statistics/dataMarket/getCommonTopics', 'statistics/dataMarket/getCountedFields'], // 数据字典页获取统计主题信息,数据字典页获取统计字段
  '/admin/dataMarket/management': ['statistics/dataMarket/getManageTopics'], // 主题域管理页获取主题域信息
  '/admin/factory/application': ['statistics/dataProcess/searchAppInfo', 'statistics/dataProcess/searchAppInfoManage'], // 获取所有应用表
  '/admin/factory/api': ['statistics/targetApi/pageAppTables', 'statistics/targetApi/pageAppTablesManage'], // 获取所有指标API
  '/admin/dataMarket/metadatas': ['metadata/tableApi/items'], // 获取元数据管理数据
  '/admin/monitor/monitorManagement': ['monitor/api/getPushLogList', 'monitor/api/getMonitorSettingList'], // 获取分析监控眼
  '/admin/dataCollect/dataManagement': ['collection/dbimport/getAllTasks', 'collection/dbimport/getCollectConfigList'], // 获取数据接入数据\日志管理
  '/admin/monitor/qualityControl': ['statistics/fieldAlarm/items'], // 获取所有告警信息（预警监控眼）
  '/admin/monitor/monitoringEye': ['dataset/process/GetHotEventMap'], // 获取所有监控眼信息（热词监控眼）
  '/admin/dataCollect/logManagement': ['collection/dbimport/getMonitorServerConfigList'], // 获取日志监听管理
  '/admin/dataCollect/dataGovern': ['collection/dataGovern/getAllModels', 'collection/dataGovern/getServiceConfig', 'collection/dataGovern/getAllUploadRecord'], // 手工上传
  '/admin/dataGasStation/dataSearch': ['metadata/tableApi/getTablecfgList', 'metadata/tableApi/exportTaskItems'], // 明细查询
  '/admin/labelProcess/labelManagement': ['tagmanage/standardManage/items', 'tagmanage/orderRule/items', 'tagmanage/taskFlow/name'], // 标签管理
  '/admin/monitor/keyIndicatorAllocation': ['statistics/masterTarget/pageTargetsGroup', 'statistics/masterTarget/getPageFocus'], // 关键指标配置
  '/admin/dataGasStation/userSearch': ['usertrace/userTrace/item'], // 用户查询
  '/admin/dataCollect/midTable': ['metadata/midTable/tablePages'], // 数据采集地-中间表
  '/admin/labelProcess/userLabel': ['tagmanage/userManager/items'], // 获取所有用户标签
}

// 页面相关操作权限映射
export const oprAuthMap = { // 页面级别的按钮，用于禁用、不展示页面、按钮层面
  'searchAppInfo': ['statistics/dataProcess/searchAppInfo'], // 应用表分页列表
  'searchAppInfoManage': ['statistics/dataProcess/searchAppInfoManage'], // 应用表新增负责人分页列表
  //应用表页面是两个权限有一个就能查看  列表数据展示逻辑是‘不同的权限点走不同的列表接口’
  'pageAppTables': ['statistics/targetApi/pageAppTables'], // 指标API分页列表
  'pageAppTablesManage': ['statistics/targetApi/pageAppTablesManage'], // 指标API新增负责人分页列表
  'atomCreateOrUpdate': ['dataset/atomTargetV2/storeOrUpdate'], // 增改原子指标
  'getAllModifier': ['dataset/modifier/getAllModifier'], // 获取所有修饰词
  'getAllDimensionList': ['dataset/process/dimensionAllList'], // 获取所有维度信息
  'derivedCreateOrUpdate': ['dataset/targetV2/storeOrUpdate'], // 增改派生指标
  'insertIndicatorConf': ['operation/indicatorOperationConf/insertIndicatorConf'], // 增加指标运营配置
  'updateIndicatorConf': ['operation/indicatorOperationConf/updateIndicatorConf'], // 更新指标运营配置
  'getCommonTopics': ['statistics/dataMarket/getCommonTopics'], // 数据字典页获取统计主题信息
  'getCountedFields': ['statistics/dataMarket/getCountedFields'], // 数据字典页获取统计字段
  'getFieldById': ['statistics/dataMarket/getFieldById'], // 获取标准字段详情
  'searchFields': ['statistics/dataMarket/searchFields'], // 字段标准页搜索字段
  'getTopicInfoById': ['statistics/dataMarket/getTopicInfoById'], // 获取主题域详情
  'storeNewBusiness': ['statistics/dataMarket/storeNewBusiness'], // 新建修改分类
  'runTargetResult': ['statistics/dataProcess/runTargetResult'], // 重跑应用表
  'storeNewAppTable': ['statistics/dataProcess/storeNewAppTable'], // 增加应用表
  'updateNewAppTable': ['statistics/dataProcess/updateNewAppTable'], // 修改应用表
  'updatePublishState': ['statistics/dataProcess/updatePublishState'], // 发布应用表
  'getTopicBusiness': ['dataset/common/getTopicBusiness'], // 获取主题分类信息
  'getIndicatorConfItems': ['operation/indicatorOperationConf/getIndicatorConfItems'], // 获取指标运营配置
  'getArchiveCodeConfItems': ['operation/indicatorOperationConf/getArchiveCodeConfItems'], // 获取归档项L4配置
  'insertArchiveCodeConf': ['operation/indicatorOperationConf/insertArchiveCodeConf'], // 添加配置
  'updateArchiveCodeConf': ['operation/indicatorOperationConf/updateArchiveCodeConf'], // 编辑配置
  'getPushLogList': ['monitor/api/getPushLogList'], // 获取监控眼管理--推送记录
  'getMonitorSettingList': ['monitor/api/getMonitorSettingList'], // 获取监控眼管理--监控配置
  'queryFormsConfPage': ['operation/formBusiness/queryFormsConfPage'], // 获取表单业务类型配置
  'saveFormConf': ['operation/formBusiness/saveFormConf'], // 修改/更新表单业务类型配置
  'getAllTasks': ['collection/dbimport/getAllTasks'], // 获取数据接入数据
  'getCollectConfigList': ['collection/dbimport/getCollectConfigList'], // 获取日志管理
  'getAllModels': ['collection/dataGovern/getAllModels'], // 表模版列表
  'downloadModel': ['collection/dataGovern/downloadModel'], // 下载按钮：表模版列表
  'getServiceConfig': ['collection/dataGovern/getServiceConfig'], // 经分业务数据上传
  'getAllUploadRecord': ['collection/dataGovern/getAllUploadRecord'], // 经分业务数据上传记录
  'downloadUploadRecord': ['collection/dataGovern/downloadUploadRecord'], // 下载按钮：经分业务数据上传记录
  'deleteUploadRecord': ['collection/dataGovern/deleteUploadRecord'], // 删除按钮：经分业务数据上传记录
  'getDataKinship': ['dataset/targetV2/getDataKinship'], // 详情按钮：派生指标是否有查看详情权限
  'getTablecfgList': ['metadata/tableApi/getTablecfgList'], // 明细查询 --> 数据列表
  'exportTaskItems': ['metadata/tableApi/exportTaskItems'], // 明细查询 --> 导出记录
  'standardManage': ['tagmanage/standardManage/items'], // 标签管理 --> 标签标准管理
  'orderRule': ['tagmanage/orderRule/items'], // 标签管理 --> 工单标签规则
  'taskFlow': ['tagmanage/taskFlow/name'], // 标签管理 --> 离线任务管理
  'storeOrUpdateDepth': ['dataset/targetV2/storeOrUpdateDepth'], // 指标加工厂 -- 派生指标 -- 指标详情  --> 重跑弹窗
  'applyApproval': ['dataset/common/applyApproval'], // 指标加工厂 -- 消息中心 -- 审批按钮权限
  'targetV2RunTargetResult': ['dataset/targetV2/runTargetResult'], // 指标加工厂 -- 重跑指标
  'storeOrUpdateDerive': ['dataset/targetV2/storeOrUpdateDerive'], // 指标加工厂 -- 编辑衍生指标
  'storeTablecfg': ['metadata/tableApi/storeTablecfg'], // 数据集市 -- 元数据管理 -- 创建表
  'updateTablecfg': ['metadata/tableApi/updateTablecfg'], // 数据集市 -- 元数据管理 -- 编辑表
  'getTablecfgDetailPermission': ['metadata/tableApi/getTablecfgDetailPermission'], // 数据集市 -- 元数据管理 -- 编辑表
  'saveTemplateConf': ['statistics/targetApi/saveTemplateConf'], // 数据加工厂 -- 指标API -- 增改权限点
  'saveAppConf': ['statistics/targetApi/saveAppConf'], // 数据加工厂 -- 指标API -- 增加指标
  'updateAppConf': ['statistics/targetApi/updateAppConf'], // 数据加工厂 -- 指标API -- 修改指标
  'publishApi': ['statistics/targetApi/publishApi'], // 数据加工厂 -- 指标API -- 发布指标
  'tableApiRunTargetResult': ['statistics/targetApi/runTargetResult'], // 数据加工厂 -- 指标API -- 发布指标
  'deleteApiTargets': ['statistics/targetApi/deleteApiTargets'], // 数据加工厂 -- 指标API -- 删除指标
  'userTraceDetail': ['usertrace/userTrace/detail'], // 用户查询轨迹-查看详情
  'saveUserLabel': ['tagmanage/userManager/saveConfig'], // 增改用户标签
  'userLabelDetail': ['tagmanage/userManager/searchDetail'], // 查看用户标签详情
  'userLabelSwitchStatus': ['tagmanage/userManager/switchStatus'], // 查看用户标签详情
  'getPathTree': ['visual/node/getPathTree'], // 数据加油站 -- 数据可视化 -- 获取树结构
  'insertPath': ['visual/node/insertPath'], // 数据加油站 -- 数据可视化 -- 新增节点
  'updatePath': ['visual/node/updatePath'], // 数据加油站 -- 数据可视化 -- 编辑节点
}

// 判断是否存在对应的权限
export function hasPriv(pathname = '') {
  // if(pathname == 'data-status__item') debugger // 打断点使用的
  const AllAuthMap = _.assign({}, authMap, oprAuthMap)
  let permissions = sessionStorage.getItem('permissions')
  try {
    permissions = JSON.parse(permissions)
  } catch (error) {
    console.log(error);
  }

  // 说明配置了权限，需要进行权限的校验，没配置的默认为true
  let shouldCheckAuth = _.isArray(AllAuthMap[pathname]) && AllAuthMap[pathname].length
  let hasPriv = (pathname && shouldCheckAuth) ? AllAuthMap[pathname].some(key => permissions && permissions[key]) : true

  return hasPriv
}
