import produce from "immer";

const state = {
    basicConfiguration: {},
    groupingConfiguration: {},
    staffing: {},
    formData: {
        group_name: "",
        remark: "",
        two_group: "",
        three_group: "",
        relate_module: "",
        enabled: 1,
        visible_list: [],
        focused_list: [],
        subscribe_info_dict: {
            real_time_change: [],
            change_day: [],
            once_day: [],
            change_week: [],
            once_week: [],
            change_month: [],
            once_month: []
        },
    },
}

const updateBasicConfiguration = produce((state, payload) => {
    state.basicConfiguration = payload
})

const updateGroupingConfiguration = produce((state, payload) => {
    state.groupingConfiguration = payload
})

const updateStaffing = produce((state, payload) => {
    state.staffing = payload
})

const updateFormData = produce((state, payload) => {
    state.formData = payload
})

const reducers = {
    updateBasicConfiguration,
    updateGroupingConfiguration,
    updateStaffing,
    updateFormData,
}

export default { state, reducers }
