import { useState, useEffect } from "react"
import _ from 'lodash'
import Message from '@common/Message'
import { commonApi } from '@utils/request'
import { getStandardManageItems } from '@services/labelManagement'
import { getSearchParams } from "@utils/submit"

export default function UseLabelName(labelName = '', idIsLabelLable = false) {
    const [standardManageItems, setStandardManageItems] = useState([])

    useEffect(() => {
        commonApi(getStandardManageItems, {
            limit: 100,
            search: getSearchParams({
                'a.label_field': labelName
            }, ['a.label_field'])
        }).then(data => {
            const items = _.get(data, 'items') || []
            setStandardManageItems(items.map(i => ({
                ...i,
                ...idIsLabelLable ? {
                    id: i.label_name
                } : {},
                text: `${i.label_field}(${i.label_name})`
            })))
        }).catch(err => Message.error(err))
    }, [labelName])

    return standardManageItems
}
