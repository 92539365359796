import React from 'react'
import _ from 'lodash'
import {Button} from 'element-react'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import Table from '@common/Table'
import Pagenation from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import BreadCrumbs from '@common/BreadCrumbs'
import Message from '@common/Message'
import {hasPriv} from '@components/FrontendAuth/AuthMap'
import {getItems, getRuleDataSource} from '@services/labelProcess'
import {getTopicBusiness} from '@services/Derived'
import {withRouter} from 'react-router-dom'
import './index.css'

class UserLabel extends React.PureComponent {

    state = {
        searchData: {
            page: 1,
            limit: 10
        },
        tableData: {},
        search: {
            "a.id": {"operator": "in", "value": ""}, // 标签ID
            "a.tag_name_ch": {"operator": "like", "value": ""}, // 标签名称
            "a.topic": {"operator": "=", "value": ""}, // 主题域
            "a.enable": {"operator": "=", "value": ""}, // 状态查询
            "b.data_source": {"operator": "=", "value": ""}, // 数据源
        },
        topicData: [],
        topicDic: {},
        ruleDataSourceList: [],
    }

    componentDidMount() {
        this.getTopicBusiness()
        this.getItems()
        this.getRuleDataSource()
    }

    getRuleDataSource = () => {
        let params = {
            limit: 2000
        }
        getRuleDataSource(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let record = res.data.data || []
                let dataSource = record.map(item => {
                    return {
                        id: item.id,
                        text: `${item.data_set_chinese}（${item.database_name}）`,
                        item: item
                    }
                })
                let newArr = {}
                let list = dataSource.reduce((arr, current) => {
                    if (!newArr[current.id]) {
                        newArr[current.id] = current.id
                        arr.push(current)
                    }
                    return arr
                }, [])
                this.setState({
                    ruleDataSourceList: list
                })
            }
        })
    }

    // 获取主题列表
    getTopicBusiness = () => {
        getTopicBusiness().then((res) => {
            if (res && res.data && res.data.code === 0) {
                this.setState({topicData: res.data.data || []})
                let topicDic = {}
                for (let i = 0; i < res.data.data.length; i++) {
                    let topicId = res.data.data[i].value
                    if (!topicDic.hasOwnProperty(topicId)) {
                        topicDic[topicId] = res.data.data[i]
                    }
                }
                this.setState({topicDic: topicDic})
            }
        })
    }

    getItems = () => {
        const {searchData} = this.state
        let params = {
            "search": {},
            ...searchData
        }
        for (const i in this.state.search) {
            const dval = this.state.search[i]
            if (dval.value) {
                if (i != "id") {
                    params.search[i] = dval
                }
            }
        }
        getItems(params).then((res) => {
            if (res && res.data && res.data.code === 0) {
                let items = res.data.data || {}
                this.setState({
                    tableData: items
                })
            }
        }).catch(error => {
            Message.error('接口异常：' + error)
        })
    }

    getColumns = () => {
        let self = this
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 38
            },
            {
                title: '标签名称',
                width: 140,
                render: data => (
                    <span>{`${data.tag_name_ch}（${data.label_name}）`}</span>
                )
            },
            {
                title: '主题域/业务',
                width: 120,
                render: data => {
                    let {topicDic} = self.state
                    let {topic, business} = data
                    let topicRecord = topicDic[topic]
                    let topicName = '-'
                    let children = []
                    let businessName = '-'
                    if (!!topicRecord) {
                        topicName = topicDic[topic].label
                        children = topicDic[topic].children || []
                        businessName = children.filter(i => i.value == business)[0] && children.filter(i => i.value == business)[0].label || ''
                    }
                    return (
                        <span>{`${topicName}/${businessName}`}</span>
                    )
                }
            },
            {
                title: '标签介绍',
                dataIndex: 'intro',
                width: 158
            },
            {
                title: '数据源',
                width: 240,
                render: data => (
                    <span>{
                        data.data_source.data_set_chinese && data.data_source.database_name ?
                            `${data.data_source.data_set_chinese}（${data.data_source.database_name}）` : '-'
                    }</span>
                )
            },
            {
                title: '状态',
                width: 54,
                render: data => (
                    <span className={
                        Boolean(+data.enable) ? 'enable-comp__title-label__is-effect' : 'enable-comp__title-label__no-effect'
                    }>
                        {Boolean(+data.enable) ? '已启用' : '未启用'}
                    </span>
                )
            },
            {
                title: '修改人',
                dataIndex: 'modify_user',
                width: 88
            },
            {
                title: '修改时间',
                dataIndex: 'modify_time',
                width: 144
            },
            {
                title: '操作',
                width: 68,
                render: data => (
                    <>
                        {
                            hasPriv("saveUserLabel") &&
                            <Button
                                plain={true}
                                type="text"
                                size="small"
                                onClick={() => self.addOrEdit(data.id)}
                                style={{"fontSize": "14px"}}
                            >编辑</Button>
                        }
                        {
                            hasPriv("userLabelDetail") &&
                            <Button
                                plain={true}
                                type="text"
                                size="small"
                                onClick={() => self.jumpToDetail(data)}
                                style={{"fontSize": "14px"}}
                            >详情</Button>
                        }
                    </>
                )
            },
        ]
    }

    addOrEdit = id => {
        this.props.history.push(`/admin/labelProcess/userLabelWin/${id}`)
    }

    jumpToDetail = record => {
        this.props.history.push(`/admin/labelProcess/userLabelDetail/${record.id}`)
    }

    render() {
        let {
            searchData,
            tableData,
            topicData,
            search,
            ruleDataSourceList,
        } = this.state

        // console.log("-------search", search)

        return (
            <>
                <BreadCrumbs
                    history={this.props.history}
                    data={[
                        {
                            title: '标签加工厂',
                            path: '/admin/labelProcess/ticketLabel'
                        },
                        {
                            title: '用户标签'
                        }
                    ]}
                />

                <div className='page-main__body'>
                    <BreadCrumbsAndTablde>
                        <BreadCrumbsAndTablde.LeftCrumbs>
                            <SdwInput
                                width={270}
                                label="标签ID"
                                placeholder="请输入标签ID"
                                value={search['a.id'].value}
                                onChange={val => {
                                    this.setState({
                                        searchData: Object.assign({}, searchData, {
                                            page: 1
                                        }),
                                        search: Object.assign({}, search, {
                                            'a.id': Object.assign({}, search['a.id'], {
                                                value: val
                                            })
                                        })
                                    }, this.getItems)
                                }}
                            />
                            <SdwInput
                                width={270}
                                label="标签名称"
                                placeholder="支持中文、英文"
                                value={search['a.tag_name_ch'].value}
                                onChange={val => {
                                    this.setState({
                                        searchData: Object.assign({}, searchData, {
                                            page: 1
                                        }),
                                        search: Object.assign({}, search, {
                                            'a.tag_name_ch': Object.assign({}, search['a.tag_name_ch'], {
                                                value: val
                                            })
                                        })
                                    }, this.getItems)
                                }}
                            />
                            <SdwSelect
                                placeholder="全部"
                                width={180}
                                label="所属主题域"
                                readOnly={false}
                                value={search["a.topic"].value}
                                data={topicData.map(el => {
                                    return {
                                        id: el.value,
                                        text: el.label
                                    }
                                })}
                                onChange={val => {
                                    this.setState({
                                        searchData: Object.assign({}, searchData, {
                                            page: 1
                                        }),
                                        search: Object.assign({}, search, {
                                            "a.topic": Object.assign({}, search["a.topic"], {
                                                value: val
                                            })
                                        })
                                    }, this.getItems)
                                }}
                            />
                            <SdwSelect
                                placeholder="请输入"
                                width={220}
                                listWidth={350}
                                label="数据源"
                                readOnly={false}
                                value={search["b.data_source"].value}
                                data={ruleDataSourceList}
                                onChange={val => {
                                    this.setState({
                                        searchData: Object.assign({}, searchData, {
                                            page: 1
                                        }),
                                        search: Object.assign({}, search, {
                                            "b.data_source": Object.assign({}, search["b.data_source"], {
                                                value: val
                                            })
                                        })
                                    }, this.getItems)
                                }}
                            />
                            <SdwSelect
                                placeholder="全部"
                                width={180}
                                label="状态"
                                readOnly={false}
                                value={search["a.enable"].value}
                                data={[{
                                    id: '',
                                    text: '全部'
                                }, {
                                    id: 1,
                                    text: '已启用'
                                }, {
                                    id: 2,
                                    text: '未启用'
                                }]}
                                onChange={val => {
                                    this.setState({
                                        searchData: Object.assign({}, searchData, {
                                            page: 1
                                        }),
                                        search: Object.assign({}, search, {
                                            "a.enable": Object.assign({}, search["a.enable"], {
                                                value: val
                                            })
                                        })
                                    }, this.getItems)
                                }}
                            />
                        </BreadCrumbsAndTablde.LeftCrumbs>

                        <BreadCrumbsAndTablde.RightCrumbs>
                            {
                                hasPriv("saveUserLabel") &&
                                <SdwButton
                                    text='新增标签'
                                    type='submit'
                                    iconClass='iconfont icon-add'
                                    onClick={() => this.addOrEdit(0)}
                                />
                            }
                        </BreadCrumbsAndTablde.RightCrumbs>

                        <BreadCrumbsAndTablde.CenterWrap>
                            <Table
                                columns={this.getColumns()}
                                dataSource={tableData.items || []}
                            />
                            <Pagenation
                                total={tableData.total || 0}
                                currentPage={tableData.current_page || 1}
                                pageCount={tableData.per_page}
                                getClickPage={page => {
                                    this.setState({
                                        searchData: _.assign({}, searchData, {page})
                                    }, this.getItems)
                                }}
                                pageCountChange={limit => {
                                    this.setState({
                                        searchData: _.assign({}, searchData, {limit})
                                    }, this.getItems)
                                }}
                            />
                        </BreadCrumbsAndTablde.CenterWrap>
                    </BreadCrumbsAndTablde>
                </div>
            </>
        )
    }
}

export default withRouter(UserLabel)
