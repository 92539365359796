import React, { PureComponent } from 'react'
import { Dropdown, Layout } from 'element-react'
import { getAllTopics, getBusinessStatisticByTopic } from '../../../services/subjectDomainDetail'

import StandardDistribution from '../components/StandardDistribution'
import TopicAllClass from '../components/TopicAllClass'
import BaseInfo from '../components/BaseInfo'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwLoading from '@common/Loadding'

import 'element-theme-default'
import './index.css'

const DEFAULT_DATA = {
  topicid: '',
  seclevel: 'management', // 默认从 主题域管理 跳转
}

const EXTRA_MAP = {
  primordial: 1,
  atom: 2,
  derive: 3
}

class SubjectDomainDetail extends PureComponent {
  state = {
    curTopicID: '', // 当前详情页的id
    curSecLevel: '', // 当前二级目录（数据字典 / 主题域管理）
    showData: false,
    dropdownMenu: [],
    curTitle: '',
    topicData: [],
    curSecLevelData: {
      text: '主题域管理',
      path: '/admin/dataMarket/management'
    }
  }

  componentDidMount () {
    let query = this.props.location.state || DEFAULT_DATA

    // 从数据字典跳转过来的
    if (query.seclevel === 'dictionary') {
      this.setState({
        curSecLevelData: {
          text: '数据字典',
          path: '/admin/dataMarket/dictionary'
        }
      })
    }
    
    this.setState({
      curTopicID: query.topicid,
      curSecLevel: query.seclevel,
    })

    this.initData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.curTopicID !== this.state.curTopicID) {
      this.onBusinessStatisticByTopic()
    }
  }

  onBusinessStatisticByTopic = () => {
    getBusinessStatisticByTopic({
      id: this.state.curTopicID
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          this.formatTopicData(res.data.data || {})
        }
      })
  }

  formatTopicData = resData => {
    let list = resData.list || []
    let formatData = list.map(item => {
      let extraList = item.data || []
      let extraMap = {}
      extraList.forEach(i => {
        extraMap[i.standard_type] = i.value
      })

      return {
        name: item.business_name,
        total: item.total || 0,
        primordial: extraMap[EXTRA_MAP.primordial] || 0,
        atom: extraMap[EXTRA_MAP.atom] || 0,
        derive: extraMap[EXTRA_MAP.derive] || 0,
        business_id: item.business_id
      }
    })
    
    let total = resData.total || []
    let totalFormat = {
      type: 'all',
      name: '全部',
      business_id: null,
      total: 0,
      primordial: 0,
      atom: 0,
      derive: 0,
    }
    total.forEach(j => {
      switch (j.standard_type) {
        case EXTRA_MAP.primordial:
          totalFormat.primordial = j.total
          break;
          
        case EXTRA_MAP.atom:
          totalFormat.atom = j.total
          break;
        
        case EXTRA_MAP.derive:
          totalFormat.derive = j.total
          break;
      
        default:
          totalFormat.total = j.total
          break;
      }
    })

    this.setState({
      topicData: [
        totalFormat,
        ...formatData,
      ]
    })
  }

  initData = () => {
    getAllTopics().then(res => {
      this.setState({
        showData: true
      })
      if (res && res.data && res.data.code === 0) {
        let resData = res.data.data || []
        let title = ''
        if (resData.length) {
          let record = resData.filter(i => i.id === this.state.curTopicID)[0] || []
          title = record && record.topic_name || ''
        }
        this.setState({
          curTitle: title,
          dropdownMenu: res.data.data || []
        })
      }
    })
      .catch(() => {
        this.setState({
          showData: true
        })
      })
  }

  changeTitle = command => {
    this.setState({
      curTopicID: command.id,
      curTitle: command.topic_name,
    })
  }

  jumpToDetail = id => {
    this.props.history.push({
      pathname: '/admin/dataMarket/standardinfo',
      state: { id }
    })
  }

  render () {
    let { 
      showData,
      dropdownMenu,
      curTopicID,
      curTitle,
      topicData,
      curSecLevelData,
    } = this.state

    return (
      <SdwLoading className="page-main__wrap" loadding={!showData}>
        <SdwBreadCrumbs 
          history={this.props.history}
          data={[
            {
              title: '数据集市',
              path: '/admin/dataMarket/dictionary'
            }, {
              title: curSecLevelData.text,
              path: curSecLevelData.path
            }, {
              title: '主题域详情'
            }
          ]}
        />

        <div className="subject-domain-detail__dropdown-menu_wrap">
          <Dropdown 
            menuAlign="start"
            onCommand={command => this.changeTitle(command)}
            menu={(
              <Dropdown.Menu>
                {
                  dropdownMenu.map(menu => (
                    <Dropdown.Item 
                      key={menu.id}
                      command={menu}
                    >{menu.topic_name || ''}</Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            )}
          >
            <span className="el-dropdown-link" style={{ fontSize: '30px', color: '#262626'}}>
              {
                curTitle
              }<i 
                style={{ fontSize: '18px', marginLeft: '14px' }}
                className="el-icon-arrow-down el-icon--right"></i>
            </span>
          </Dropdown>
        </div>

        <Layout.Row gutter={20}>
          <Layout.Col span="18">
            <StandardDistribution 
              topic_id={curTopicID} 
              topic_name={curTitle} 
              data={topicData} 
              onBusinessStatisticByTopic={this.onBusinessStatisticByTopic}/>
            
            <TopicAllClass 
              data={topicData} 
              curTopicID={curTopicID} 
              jumpToDetail={this.jumpToDetail}
            />
          </Layout.Col>
          <Layout.Col span="6">
            <BaseInfo id={curTopicID}/>
          </Layout.Col>
        </Layout.Row>

      </SdwLoading>
    )
  }
}

export default SubjectDomainDetail
