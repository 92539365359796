import React from 'react'
import noDataImg from './img/no_data@2x.png'
import planImg from './img/plan@2x.png'
import noAuthImg from './img/no_auth@2x.png'
import netAbnormalImg from './img/net_abnormal@2x.png'
import dataAbnormalImg from './img/data_abnormal@2x.png'
import emptyLeftImg from './img/empty_left@2x.png'
import noSearchImg from './img/no_search@2x.png'

import './index.css'

const IMG_MAP = {
  noData: noDataImg,
  plan: planImg,
  noAuth: noAuthImg,
  netAbnormal: netAbnormalImg,
  dataAbnormal: dataAbnormalImg,
  emptyLeft: emptyLeftImg,
  noSearch: noSearchImg,
}

export default props => {

  return (
    <div style={{ height: props.height }} className="sdw-empty__wrap">
      <div className="sdw-empty__center-img-wrap" style={{
        height: props.hideImage ? '20px' : '168px',
        width: !!props.innerWidth ? props.innerWidth : 200
      }}>
        {
          !props.hideImage && <img src={ IMG_MAP[props.icon || 'noData'] } />
        }
        {
          !props.hideText && <div>{!!props.text ? props.text : '暂无数据'}</div>
        }
        {
          props.children
        }
      </div>
    </div>
  )
}
