import React, { PureComponent } from 'react'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwCascader from '@common/Cascader'
import SdwSwitch from '@common/Switch'
import SdwMessage from '@common/Message'
import SdwDialog from '@common/Dialog'
import ConfirmWin from '@components/ConfirmWin'
import { getTablecfgFields, storeOrUpdateAtomTarget, getChildTarget } from '@services/atom'
import 'element-theme-default'
import './style/EditData.css'

class EditData extends PureComponent {

  state = {
    id: "",
    target_name_ch: "",
    target_name: "",
    topicBusiness: {},
    related_table_id: "",
    metric_field: "",
    time_field: "",
    metric_type: "",
    calculation: "",
    comments: "",
    target_type: 1,
    is_same_dim: 0,
    enable: 1,

    sholdCheckValidate: false,
    tableFields: [],
    showConfirmWin: false,
    confirmData: []
  }

  componentDidMount () {
    this.initData()
  }

  componentDidUpdate (prevProps, prevState) {

    if (prevProps.record.id !== this.props.record.id) {
      this.initData()
    }

    if (prevProps.record.related_table_id !== this.props.record.related_table_id) {
      this.getTablecfgFields(this.props.record.related_table_id)
    }
  }

  initData = () => {
    this.setState({
      id: this.props.record.id,
      target_name_ch: this.props.record.target_name_ch,
      target_name: this.props.record.target_name,
      topicBusiness: this.props.record.topicBusiness || {},
      related_table_id: this.props.record.related_table_id,
      metric_field: this.props.record.metric_field,
      time_field: this.props.record.time_field,
      metric_type: this.props.record.metric_type,
      calculation: this.props.record.calculation,
      comments: this.props.record.comments,
      enable: this.props.record.enable,
    })
  }

  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    if (label == "主题域/业务" && curValue.ids && curValue.ids.length) {
      return true
    }
    return `${label}不能为空`
  }

  // 获取指定的表的字段
  getTablecfgFields = (related_table_id) => {
    this.setState({related_table_id: related_table_id})
    let that = this
    let parame = {}
    parame.id = related_table_id
    getTablecfgFields(parame).then(res => {
      let tableFields = res.data.data || []
      that.setState({tableFields: tableFields})
    })
  }

  handleSureClick = () => {
    let { id } = this.state

    // 新增
    if (!id) {
      this.onStoreOrUpdateAtomTarget()
      return
    }

    // 1、弹窗二次确认(只有编辑才需要)
    getChildTarget({id}).then((res) => {

      if (res && res.data && res.data.code === 0) {
        // 2、确认后才编辑数据
        let list = Array.isArray(res.data.data) ? res.data.data : []

        // 这里list没有长度：表明没有关联的指标，不用弹出确认框
        if (!list.length) {
          this.onStoreOrUpdateAtomTarget()
          return
        }

        this.setState({ confirmData: list, showConfirmWin: true })
      }
    })
  }

  onStoreOrUpdateAtomTarget = () => {
    let {
      id,
      target_name_ch,
      target_name,
      topicBusiness,
      related_table_id,
      metric_field,
      time_field,
      metric_type,
      calculation,
      comments,
      target_type,
      is_same_dim,
      enable
    } = this.state

    let isValidatePass =
      (typeof related_table_id === 'number' || (!!related_table_id)) &&
      typeof topicBusiness === 'object' &&
      !!target_name_ch &&
      !!target_name &&
      !!metric_field &&
      !!time_field &&
      !!metric_type

    if (!isValidatePass) {
      // 手动触发必填项的检查
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    let dataid = this.props.record.id || 0

    let params = { id: dataid, field: {}}

    let comitFieldData = {
      target_name_ch,
      target_name,
      topicBusiness,
      related_table_id,
      metric_field,
      time_field,
      metric_type,
      calculation,
      comments,
      target_type,
      is_same_dim,
      enable
    }

    let formData = new FormData()
    for (let i in comitFieldData) {
      let val = comitFieldData[i]
      if (i === 'id') {
        continue
      }
      if (i === 'topicBusiness') {
        params.field['topic'] = val["ids"][0] || ''
        params.field['service'] = val["ids"][1] || ''
        continue
      }
      params.field[i] = val
    }

    for (let i in params) {
      formData.append(i, params[i])
    }

    // 编辑数据
    storeOrUpdateAtomTarget(params, (res) => {

      if (res && res.data && res.data.code === 0) {
        let msg = id === 0 ? '新建成功' : '编辑成功'
        SdwMessage.success(msg)
        this.setState({ showConfirmWin: false })
        // 告诉父级点击了确定按钮
        this.props.onSureClick()
      } else {
        SdwMessage.error(res.data.msg);
      }
    })
  }

  enableStateChange = (val) => {
    if(val) {
      this.setState({enable: 1})
    }else {
      this.setState({enable: 0})
    }
  }

  render () {
    let {
      target_name_ch,
      target_name,
      topicBusiness,
      related_table_id,
      metric_field,
      time_field,
      metric_type,
      calculation,
      comments,
      enable,

      sholdCheckValidate,
      showConfirmWin,
      confirmData
    } = this.state

    let width = this.props.record.width || 500

    // 主题列表渲染成组件需要的数据
    let topicData = this.props.topicData.map(i => {
      return {
        id: i.value,
        text: i.label,
        children: i.children && i.children.map(si => { return {id:si.value, text:si.label} })
      }
    })

    // 来源表
    let tableList = this.props.tableList.map(i => {
      return {
        id: i.id+'',
        text: i.data_set_chinese + "(" + i.database_name + "." + i.table_name + ")"
      }
    })

    // 度量字段
    let tableFields = this.state.tableFields.map(i => {
      return {
        id: i.field_name,
        text: i.field_name_ch + '(' + i.field_name + ')'
      }
    })

    // 时间字段
    let tableTimeFields = []
    for(let si=0; si<this.state.tableFields.length; si++) {
      let i = this.state.tableFields[si]
      if(i.field_type == 'date' || i.field_type == 'datetime' || i.field_type == 'timestamp' || i.field_type == 'esdate') {
        tableTimeFields.push ({
          id: i.field_name,
          text: i.field_name_ch + '(' + i.field_name + ')'
        })
      }
    }

    // 计算方式列表
    let calculationMethod = []
    if(this.props.cfgData.hasOwnProperty("calculationMethod")) {
      calculationMethod = this.props.cfgData.calculationMethod.map(i => {
        return {
          id: i.id,
          text: i.name
        }
      })
    }

    // 有效无效状态
    let enableState = enable == 1 ? true : false

    return (
      <div className="editorMain">
        <SdwDrawer
          title={this.props.title}
          visible={this.props.visible}
          onIconClick={this.props.onIconClick}
          onCancelClick={this.props.onCancelClick}
          onSureClick={this.handleSureClick}
        >
          <SdwDrawer.Body>
            <div className="form data-editor-win-wrap">
              <div className="formInput">
                <span className="label required">中文名称</span>
                <SdwInput
                  value={target_name_ch}
                  validateFun={value => this.validateFun(value, '中文名称')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({target_name_ch: val})}
                />
              </div>

              <div className="formInput">
                <span className="label required">英文名称</span>
                <SdwInput
                  value={target_name}
                  validateFun={value => this.validateFun(value, '英文名称')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({target_name: val})}
                />
              </div>

              <div className="formInput">
                <span className="label required">主题域/业务</span>
                <SdwCascader
                  value={topicBusiness}
                  validateFun={value => this.validateFun(value, '主题域/业务')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({topicBusiness: val})}
                  data={topicData}
                />
              </div>

              <div className="formInput">
                <span className="label required">来源表</span>
                <SdwSelect
                  value={related_table_id}
                  readOnly={false}
                  validateFun={value => this.validateFun(value, '来源表')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => {this.getTablecfgFields(val)}}
                  data={tableList}
                />
              </div>

              <div className="formInput">
                <span className="label required">度量字段</span>
                <SdwSelect
                  value={metric_field}
                  readOnly={false}
                  validateFun={value => this.validateFun(value, '度量字段')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({metric_field: val})}
                  data={tableFields}
                />
              </div>

              <div className="formInput">
                <span className="label required">时间字段</span>
                <SdwSelect
                  value={time_field}
                  readOnly={false}
                  validateFun={value => this.validateFun(value, '时间字段')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({time_field: val})}
                  data={tableTimeFields}
                />
              </div>

              <div className="formInput">
                <span className="label required">计算逻辑</span>
                <SdwSelect
                  value={metric_type}
                  validateFun={value => this.validateFun(value, '计算逻辑')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({metric_type: val})}
                  data={calculationMethod}
                />
              </div>

              <div className="formInput">
                <span className="label">过滤条件</span>
                <SdwInput
                  placeholder="请输入过滤条件，es语法"
                  value={calculation}
                  onChange={val => this.setState({calculation: val})}
                />
              </div>

              <div className="formInput">
                <span className="label">描述</span>
                <SdwInput
                  type="textarea"
                  value={comments}
                  size="large"
                  autosize={{ minRows: 4, maxRows: 4}}

                  placeholder="请输入简单描述指标含义"
                  onChange={val => this.setState({comments: val})}
                />
              </div>

              <div className="formInput">
                <span style={{ display: 'inline-block', width: '100px' }}>是否启用</span>
                <SdwSwitch
                    value={enableState}
                    changeValue={ val => {this.enableStateChange(val)}}
                />
              </div>

            </div>
          </SdwDrawer.Body>
        </SdwDrawer>

        {
          showConfirmWin &&
          <SdwDialog
            title='提示'
            hideHeader={true}
            visible={showConfirmWin}
            changeVisible={bool => this.setState({showConfirmWin: bool})}
            onCancelClick={() => this.setState({showConfirmWin: false})}
            onSureClick={this.onStoreOrUpdateAtomTarget}
          >
            <ConfirmWin
              title='以下为该原子指标相关的派生指标，请确认是否改动！'
              confirmData={confirmData}
            />
          </SdwDialog>
        }
      </div>
    )
  }
}

export default EditData
