import {useEffect, useState} from 'react'
import _ from 'lodash'
import Layout from '@common/Layout'
import {getPublicCfg} from '@services/Derived'

import '../index.css'

function InfoPage(prop) {

    const [cfgData, setCfgData,] = useState({})

    useEffect(() => {
        getPublicCfg().then(res => {
            if (res.data.code === 0 && res.data.data) {
                let cfgData = {}
                for (let i in res.data.data) {
                    cfgData[i] = res.data.data[i]
                }
                setCfgData(cfgData)
            }
        })
    }, [])


    let labelDetail = prop.labelDetail
    let ruleData = labelDetail.rule_data
    let dataSource = labelDetail.data_source

    if (cfgData.hasOwnProperty("label_process_type")) {
        cfgData["label_process_type"].forEach(element => {
            if (element.value == ruleData.deal_type) {
                ruleData.deal_type_ch = element.name
            }
        });
    }

    // console.log("ruleData=================", ruleData.handle)

    if (cfgData.hasOwnProperty("label_schedule_type")) {
        cfgData["label_schedule_type"].forEach(element => {
            if (element.value == ruleData.run_cycle) {
                ruleData.run_cycle_ch = element.name
            }
        });
    }

    if (cfgData.hasOwnProperty("label_multi_value")) {
        cfgData["label_multi_value"].forEach(element => {
            if (element.value == ruleData.multi_type) {
                ruleData.multi_type_ch = element.name
            }
        });
    }

    if (cfgData.hasOwnProperty("label_time_range")) {
        cfgData["label_time_range"].forEach(element => {
            if (element.value == ruleData.time_cycle) {
                ruleData.time_cycle_ch = element.name
            }
        });
    }


    return (
        <div className='page-info'>
            <div className='page-info__title'>
                <span>{
                    _.isEmpty(dataSource) && _.isEmpty(ruleData) ?
                        "-" : `${dataSource.data_set_chinese}(${dataSource.database_name})|${ruleData.deal_type_ch}`
                }</span>
            </div>

            <div className='page-info__content'>
                <Layout.Row gutter={20}>
                    <Layout.Col span="12">
                        <div>
                            <span className='info-title'>调度周期</span>
                            <span className='info-text'>{ruleData.run_cycle_ch || "-"}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span="12">
                        <div>
                            <span className='info-title'>关联字段</span>
                            <span className='info-text'>{ruleData.user_fields || "-"}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter={20}>
                    <Layout.Col span="12">
                        <div>
                            <span className='info-title'>提取字段</span>
                            <span className='info-text'>{ruleData.label_field || "-"}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span="12">
                        <div>
                            <span className='info-title'>时间范围</span>
                            <span className='info-text'>{
                                ruleData.time_field && ruleData.time_cycle_ch ?
                                    `${ruleData.time_field}(${ruleData.time_cycle_ch})` : "-"
                            }</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter={20}>
                    <Layout.Col span="12">
                        <div>
                            <span className='info-title'>过滤条件</span>
                            <span className='info-text'>{ruleData.filter || "-"}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span="12">
                        <div>
                            <span className='info-title'>多值处理</span>
                            <span className='info-text'>{ruleData.multi_type_ch || "-"}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter={20}>
                    <Layout.Col span="12">
                        <div>
                            <span className='info-title'>加工规则</span>
                            <span className='info-text'>{ruleData.handle || "-"}</span>
                        </div>
                    </Layout.Col>
                </Layout.Row>
            </div>
        </div>
    )
}

export default InfoPage
