import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwMessage from '@common/Message'
import SdwCascader from '@common/Cascader'
import { saveAppConf, updateAppConf} from '@services/api'
import { getTopicBusiness } from '@services/Derived'

import './style/addApiWin.css'
import { getStaffs } from '@api/alertRules'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

export default class AddApiWin extends React.Component {

    state = {
        table_name: '',
        table_name_cn: "",
        project: '',
        topicBusiness: {
            ids: [],
            texts:[],
            clickItem:[]
        },
        sholdCheckValidate: false,
        timeValue: 'day',
        resp_user: [],
        isAppInputValuePass: false,
        isZhNameValidateFunPass: false,
        isRefreshValuePass: false,
        isDomainValuePass: false,
        timeSelectList: [
            {
                id: 'year',
                text: 'year'
            }, {
                id: 'month',
                text: 'month'
            }, {
                id: 'day',
                text: 'day'
            }, {
                id: 'hour',
                text: 'hour'
            }
        ],
        topicData: [],
        allMembers:[],
        refreshValue:"",
    }

    componentDidMount() {
        this.initData()
        this.getStaff()
        this.getTopicBusiness()
    }

    initData = () => {
        //currentData
        let {currentData} = this.props
        console.log("currentData--------currentData", currentData)
        if (!!currentData) {
            let indexFlag = (currentData.table_name || '').lastIndexOf('_')
            this.setState({
                table_name:currentData.table_name.substr(0, indexFlag) || '',
                timeValue: indexFlag !== -1 ? currentData.table_name.substr(indexFlag + 1) : 'day',
                table_name_cn:currentData.table_name_cn,
                project:currentData.project,
                topicBusiness: currentData.topicBusiness,
                refreshValue:currentData.run_cycle,
                resp_user:currentData.resp_user.split(","),
            })
        }
    }
    getStaff = () => {
        getStaffs().then(res => {
            if (res && res.data && res.data.code === 0) {
                let list = _.isArray(res.data.data) ? res.data.data : []
                //list.push({name:"default_focus", value:"default_focus"})
                this.setState({
                    allMembers: list
                })
            }
        })
    }
    // 获取主题列表
    getTopicBusiness = () => {
        let that = this
        getTopicBusiness().then((res) => {
            that.setState({ topicData: res.data.data })
        })
    }

    appNameValidateFun = val => {
        if (!val) {
            return '该输入项不能为空'
        }
        if (val.length > 500) {
            return '输入最大长度500'
        }
        return true
    }

    tableNameValidateFun = val => {
        if (!val) {
            return '该输入项不能为空'
        }
        if (!/^[a-zA-Z_]{1,}$/.test(val)) {
            return '仅支持英文、下划线'
        }

        if (val.length > 200) {
            return '输入最大长度200'
        }
        return true
    }

    zhNameValidateFun = val => {
        if (!val) {
            return '该输入项不能为空'
        }
        if (val.length > 100) {
            return '输入最大长度100'
        }
        return true
    }

    resetData = () => {
        this.setState({
            table_name: '',
            timeValue: 'day',
            project: '',
            topicBusiness: '',
            refreshValue: ''
        })
    }

    handleSubmit = () => {
        let {
            table_name,
            table_name_cn,
            timeValue,
            project,
            topicBusiness,
            refreshValue,
            resp_user,
        } = this.state
        let rule1 = this.tableNameValidateFun(table_name)
        let rule2 = this.appNameValidateFun(table_name_cn)
        let rule3 = this.zhNameValidateFun(project)
        if (rule1 != true || rule2 != true || rule3 != true || topicBusiness.ids.length != 2|| resp_user.length == 0) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        let { isEdit } = this.props
        // ajax
        let paramsData = {
            table_name: `${table_name}_${timeValue}`,
            table_name_cn: table_name_cn,
            project: project,
            topic: topicBusiness.ids[0],
            service: topicBusiness.ids[1],
            run_cycle:refreshValue,
            resp_user: resp_user.join(",")
        }
        if (isEdit && hasPriv('updateAppConf')) {
            updateAppConf({
                id: this.props.currentData.id,
                data: paramsData
            })
              .then(res => {
                  if (res.data && res.data.code === 0) {
                      this.resetData()
                      this.props.changeDialogVisible(false)

                      // 新增/编辑成功，从新拉去新数据
                      if (isEdit) {
                          this.props.initData()
                      } else {
                          this.props.onSearchApiInfo()
                      }

                      let msg = isEdit ? '编辑成功' : '新增成功'
                      SdwMessage.success(msg)
                  } else {
                      let msg = res.data && res.data.msg
                      SdwMessage.error(msg);
                  }
              }, () => SdwMessage.error("接口异常"))
        } else if (hasPriv('saveAppConf')) {
            saveAppConf({
                data: paramsData
            })
              .then(res => {
                  if (res.data && res.data.code === 0) {
                      this.resetData()
                      this.props.changeDialogVisible(false)

                      // 新增/编辑成功，从新拉去新数据
                      if (isEdit) {
                          this.props.initData()
                      } else {
                          this.props.onSearchApiInfo()
                      }

                      let msg = isEdit ? '编辑成功' : '新增成功'
                      SdwMessage.success(msg)
                  } else {
                      let msg = res.data && res.data.msg
                      SdwMessage.error(msg);
                  }
              }, () => SdwMessage.error("接口异常"))
        }


    }
    validateFun = (curValue, label = '') => {
        if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
            return true
        }
        if (label == "主题域/业务" && Array.isArray(curValue.ids) && curValue.ids.length) {
            return true
        }
        return `${label}不能为空`
    }

    render() {
        let {
            dialogVisible,
            changeDialogVisible,
            isEdit,
            runCycleData,
        } = this.props

        let {
            topicData,
            timeSelectList,
            timeValue,
            resp_user,
            sholdCheckValidate,
            table_name,
            table_name_cn,
            project,
            topicBusiness,
            allMembers,
            refreshValue,
        } = this.state

        // 主题列表渲染成组件需要的数据
        topicData = topicData.map(i => {
            return {
                id: i.value,
                text: i.label,
                children: i.children && i.children.map(si => {
                    return { id: si.value, text: si.label }
                })
            }
        })
        console.log("-=-----------runCycleData", runCycleData)
        return (
            <div>
                <SdwDialog
                    title={isEdit ? '编辑指标API' : '新增指标API'}
                    visible={dialogVisible}
                    width={610}
                    changeVisible={() => changeDialogVisible(false)}
                    onCancelClick={() => changeDialogVisible(false)}
                    onSureClick={this.handleSubmit}
                >
                    <div className='add-api-win__label'>
                        <span className='label__title required'>API名称</span>
                        <SdwInput
                            placeholder='api_account_test'
                            width={320}
                            disabled={isEdit}
                            value={table_name}
                            onChange={val => this.setState({ table_name: val })}
                            sholdCheckValidate={sholdCheckValidate}
                            validateFun={this.tableNameValidateFun}
                        />
                        <span style={{ marginLeft: 2 }}>
                            <SdwSelect
                                width={100}
                                listWidth={100}
                                disabled={isEdit}
                                clearable={false}
                                value={timeValue}
                                data={timeSelectList}
                                onChange={val => this.setState({ timeValue: val })}
                            />
                        </span>
                    </div>
                    <div className='add-api-win__label'>
                        <span className='label__title required'>API中文名</span>
                        <SdwInput
                            placeholder='账号专区api'
                            width={422}
                            value={table_name_cn}
                            onChange={val => this.setState({ table_name_cn: val })}
                            sholdCheckValidate={sholdCheckValidate}
                            validateFun={this.appNameValidateFun}
                        />
                    </div>
                    <div className='add-api-win__label'>
                        <span className='label__title required'>主题域/业务</span>
                        <SdwCascader
                            listWidth={200}
                            width={422}
                            value={topicBusiness}
                            validateFun={value => this.validateFun(value, '主题域/业务')}
                            sholdCheckValidate={sholdCheckValidate}
                            onChange={val => {
                                console.log("-----val", val)
                                this.setState({ topicBusiness: val })
                            }}
                            data={topicData}
                        />
                    </div>
                    <div className='add-api-win__label'>
                        <span className='label__title required'>运营负责人</span>
                        {/*<SdwInput*/}
                        {/*    placeholder='请输入rtx'*/}
                        {/*    width={422}*/}
                        {/*    value={resp_user}*/}
                        {/*    onChange={val => this.setState({ resp_user: val })}*/}
                        {/*    sholdCheckValidate={sholdCheckValidate}*/}
                        {/*    validateFun={value => this.validateFun(value, '运营负责人')}*/}
                        {/*/>*/}
                        <SdwInput
                          isMultipleChoice={true}
                          multipleValue={resp_user}
                          multipleOption={allMembers}
                          width={422}
                          placeholder="请输入rtx"
                          validateFun={value => this.validateFun(value, '运营负责人')}
                          sholdCheckValidate={sholdCheckValidate}
                          onClear={() => this.setState({ resp_user:  [] })} // 这里的onClear只是清除所有时才触发
                          changeMultipleValue={val => this.setState({ resp_user: val })}
                        />
                    </div>
                    <div className='add-api-win__label'>
                        <span className='label__title required'>项目</span>
                        <SdwInput
                            placeholder='账号专区'
                            width={422}
                            value={project}
                            onChange={val => this.setState({ project: val })}
                            sholdCheckValidate={sholdCheckValidate}
                            validateFun={this.zhNameValidateFun}
                        />
                    </div>
                    <div className='add-api-win__label'>
                        <span className='label__title required'>更新频率</span>
                        <SdwSelect
                          placeholder="请选择"
                          width={422}
                          disabled={isEdit}
                          listWidth={418}
                          sholdCheckValidate={sholdCheckValidate}
                          clearable={false}
                          validateFun={value => this.validateFun(value, '更新频率')}
                          value={refreshValue}
                          data={runCycleData}
                          onChange={val => this.setState({ refreshValue: val })}
                        />
                    </div>
                </SdwDialog>
            </div>
        )
    }
}
