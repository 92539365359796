import './index.css'

export default function EnableComp(props) {
  const { enable } = props

  return (
    <>
      <span className={
        Boolean(+enable) ? 'enable-comp__title-label__is-effect' : 'enable-comp__title-label__no-effect'
      }>
        {
          Boolean(+enable) ? '有效' : '无效'
        }
      </span>
    </>
  )
}