import React from 'react'
import _ from 'lodash'
import Select from '@common/Select'
import Input from '@common/Input'
import Radiobox from '@common/Radiobox'
import Button from '@common/Button'
import Table from '@common/Table'
import SdwMessage from '@common/Message'
import BreadCrumbs from '@common/BreadCrumbs'
import {searchDetail, getTablecfgList, labelTest, saveRuleConfig} from '@services/labelProcess'
import {getPublicCfg} from '@services/Derived'
import {getTablecfgFields} from '@services/atom'
import testImg from '../img/icon_test-img@2x.png'
import './index.css'

class setupTwo extends React.PureComponent {

    state = {
        breadCrumbsTitle: "",
        labelDetail: {},
        ruleData: {},
        paramsData: {
            data_source_type: "table", // 数据源类型（字典label_data_source_type）
            data_source: "", // 数据源id
            deal_type: "", // 加工类型（字典label_process_type）
            run_cycle: "1d", // 调度周期（字典label_schedule_type）
            time_field: "", // 时间设定--取数时间字段
            time_cycle: "", // 时间设定-取数范围（字典label_time_range）
            user_fields: "", // 关联用户账号字段
            label_field: "", // 提取字段
            filter: "", // 过滤条件
            multi_type: "last", // 多值处理（字典label_multi_value）
            handle: "", // 加工规则（中间表字段配置）
            user_field_type: "open_id", // 关联账号类型，读public配置里面的
            is_sec: "0", // 1加密、0非加密
            enable: "1"
        },
        ruleDataSourceList: [],
        tableFields: [],
        sholdCheckValidate: false,
        testStatus: 0, // 0  -1未测试 1成功 2失败
        testWaringText: "", // 0  -1未测试 1成功 2失败
        relationFieldValue: "",
        userLabelList: [
            {
                account: '-',
                tagValue: '-'
            },
        ],
        scrpt: "",
        dicts: "",
        ruleNamePlaceholder: "请输入规则名称",
        backPath: "",
        cfgData: "",
    }

    componentDidMount() {
        this.iniData()
        this.getPublicCfg()
        this.getTablecfgList()
    }

    getPublicCfg = () => {
        getPublicCfg().then(res => {
            if (res.data.code === 0 && res.data.data) {
                let cfgData = {}
                for (let i in res.data.data) {
                    cfgData[i] = res.data.data[i]
                }
                this.setState({cfgData: cfgData})
            }
        })
    }

    iniData = () => {
        // console.log("==============props", this.props)
        if (this.props.location.state) {
            let ID = this.props.location.state.id
            let backPath = this.props.location.state.backPath
            // console.log("-----------------------ID",ID)
            // console.log("-----------------------backPath",backPath)
            if (backPath == "detail") {
                this.setState({
                    backPath: backPath
                })
            }
            if (!ID) {
                SdwMessage.error("页面异常")
            }
            this.searchDetail(ID)
        } else {
            SdwMessage.error("页面异常")
            this.props.history.push(`/admin/labelProcess/userLabel`)
        }
    }

    getTablecfgList = () => {
        let params = {limit: 20000, page: 0, search: {enable: {value: '1', operator: "="}}}
        getTablecfgList(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let ruleDataSourceList = res.data.data.items || []
                this.setState({
                    ruleDataSourceList: ruleDataSourceList.map(item => {
                        return {
                            id: item.id + "",
                            text: item.data_set_chinese + "(" + item.database_name + ")" + (item.table_name ? "." + item.table_name : ""),
                            item: item
                        }
                    }),
                })
            }
        })
    }

    getColumns = () => {
        return [
            {
                title: '用户账号',
                dataIndex: 'account',
            },
            {
                title: '标签',
                dataIndex: 'tagValue',
            }
        ]
    }

    searchDetail = id => {
        let params = {
            id: id
        }
        searchDetail(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let labelDetail = res.data.data || {}
                let ruleData = labelDetail.rule_data || {}
                let data_source = labelDetail.data_source || {}
                let scrpt = ""
                let dicts = ""
                let handle = {}
                if (!!ruleData.handle) {
                    handle = JSON.parse(ruleData.handle)
                    scrpt = handle.scrpt || ""
                    dicts = handle.dicts || ""
                }
                // console.log("ruleData=================", ruleData.handle)
                let stateParams = {
                    labelDetail: labelDetail,
                    ruleData: ruleData,
                    handle: handle,
                    data_source: data_source,
                    breadCrumbsTitle: this.props.location.state.breadCrumbsTitle
                }
                if (JSON.stringify(ruleData) != "{}") {
                    stateParams["paramsData"] = {
                        data_source_type: ruleData["data_source_type"], // 数据源类型（字典label_data_source_type）
                        data_source: ruleData["data_source"], // 数据源id
                        deal_type: ruleData["deal_type"], // 加工类型 （字典label_process_type）
                        run_cycle: ruleData["run_cycle"], // 调度周期  （字典label_schedule_type）
                        time_field: ruleData["time_field"], // 时间设定--取数时间字段
                        time_cycle: ruleData["time_cycle"], // 时间设定-取数范围（字典label_time_range）
                        user_fields: ruleData["user_fields"], // 关联用户账号字段
                        label_field: ruleData["label_field"], // 提取字段
                        filter: ruleData["filter"], // 过滤条件
                        multi_type: ruleData["multi_type"], // 多值处理（字典label_multi_value）
                        user_field_type: ruleData["user_field_type"],
                        is_sec: ruleData["is_sec"],
                        handle: ruleData["handle"], // 加工规则（中间表字段配置）
                        enable: ruleData["enable"]
                    }
                }
                this.setState({
                    scrpt: scrpt,
                    dicts: dicts,
                    ...stateParams
                }, () => {
                    this.getTablecfgFields(ruleData["data_source"])
                })
            }
        })
    }

    // 获取指定的表的字段
    getTablecfgFields = (related_table_id) => {
        if (related_table_id == "") {
            return
        }
        this.changeParamsValue("data_source", related_table_id)
        let that = this
        let parame = {}
        parame.id = related_table_id
        getTablecfgFields(parame).then(res => {
            let tableFields = res.data.data || []
            that.setState({tableFields: tableFields})
        })
    }

    changeParamsValue = (type, val) => {
        let {paramsData} = this.state
        let obj = {}
        obj[type] = val
        this.setState({
            paramsData: _.assign({}, paramsData, obj)
        })
    }

    ruleFormData = (type, val) => {
        switch (type) {
            case "data_source_type":
                if (!val) {
                    return '数据源类型不能为空'
                }
                break
            case "data_source":
                if (!val) {
                    return '数据源不能为空'
                }
                break
            case "deal_type":
                if (!val) {
                    return '加工类型不能为空'
                }
                break
            case "run_cycle":
                if (!val) {
                    return "调度周期不能为空"
                }
                break
            case "time_field":
                if (!val) {
                    return "取数时间字段不能为空"
                }
                break
            case "time_cycle":
                if (!val) {
                    return "取数范围不能为空"
                }
                break
            case "user_fields":
                if (!val) {
                    return "关联字段不能为空"
                }
                break
            case "label_field":
                if (!val) {
                    return "提取字段不能为空"
                }
                break
            case "filter":
                if (val.length > 10000) {
                    return '过滤条件输入最大长度为10000'
                }
                break
            default:
                break
        }
        return true
    }

    labelTest = () => {
        // let {relationFieldValue, labelDetail} = this.state
        let {paramsData, labelDetail, scrpt, dicts} = this.state
        if (this.ruleFormData("data_source_type", paramsData.data_source_type) !== true ||
            this.ruleFormData("data_source", paramsData.data_source) !== true ||
            this.ruleFormData("deal_type", paramsData.deal_type) !== true ||
            this.ruleFormData("time_field", paramsData.time_field) !== true ||
            this.ruleFormData("time_cycle", paramsData.time_cycle) !== true ||
            this.ruleFormData("user_fields", paramsData.user_fields) !== true ||
            this.ruleFormData("label_field", paramsData.label_field) !== true ||
            this.ruleFormData("filter", paramsData.filter) !== true) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        // console.log("save----paramsData", paramsData)
        // console.log("save----labelDetail", labelDetail)
        let handle = {}
        if (!!scrpt) {
            handle.scrpt = scrpt
        }
        if (!!dicts) {
            handle.dicts = dicts
        }
        paramsData.handle = JSON.stringify(handle)
        let params = {
            id: labelDetail.id,
            data: paramsData
        }
        labelTest(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                this.setState({
                    testStatus: 1,
                    testWaringText: "成功",
                    userLabelList: res.data.data.list || []
                })
            } else {
                this.setState({
                    testStatus: 2,
                    testWaringText: res.data.data,
                    userLabelList: [{account: '-', tagValue: '-'}]
                })
            }
        })
    }

    saveRuleConfig = () => {
        let {paramsData, backPath, labelDetail, scrpt, dicts} = this.state
        if (this.ruleFormData("data_source_type", paramsData.data_source_type) !== true ||
            this.ruleFormData("data_source", paramsData.data_source) !== true ||
            this.ruleFormData("deal_type", paramsData.deal_type) !== true ||
            this.ruleFormData("time_field", paramsData.time_field) !== true ||
            this.ruleFormData("time_cycle", paramsData.time_cycle) !== true ||
            this.ruleFormData("user_fields", paramsData.user_fields) !== true ||
            this.ruleFormData("label_field", paramsData.label_field) !== true ||
            this.ruleFormData("filter", paramsData.filter) !== true) {
            this.setState({
                sholdCheckValidate: true
            })
            return
        }
        // console.log("save----paramsData", paramsData)
        let handle = {}
        if (!!scrpt) {
            handle.scrpt = scrpt
        }
        if (!!dicts) {
            handle.dicts = dicts
        }
        paramsData.handle = JSON.stringify(handle)
        let params = {
            id: labelDetail.id,
            data: paramsData
        }
        saveRuleConfig(params).then(res => {
            if (res.data.code === 0) {
                SdwMessage.success({message: "标签编辑成功", duration: 2000})
                if (backPath == "detail") {
                    this.props.history.push(`/admin/labelProcess/userLabelDetail/${labelDetail.id}`)
                } else {
                    this.props.history.push(`/admin/labelProcess/userLabel`)
                }
            } else {
                SdwMessage.error("接口异常" + res.data.msg)
            }
        })
    }

    jumpToOne = id => {
        this.props.history.push(`/admin/labelProcess/userLabelWin/${id}`)
    }

    render() {
        let {
            breadCrumbsTitle,
            labelDetail,
            paramsData,
            sholdCheckValidate,
            ruleDataSourceList,
            testStatus,
            testWaringText,
            userLabelList,
            scrpt,
            dicts,
            ruleNamePlaceholder,
            cfgData,
        } = this.state

        //console.log("handle=============", handle)
        // console.log("--------------------labelDetail.is_test_pass", labelDetail.is_test_pass)

        let testClassName = ""
        if (testStatus === 0) {
            if (labelDetail.is_test_pass === -1) {
                testWaringText = "未测试"
                testClassName = "label-info__label"
            } else if (labelDetail.is_test_pass === 1) {
                testWaringText = "成功"
                testClassName = "label-info__success_label"
            } else if (labelDetail.is_test_pass === 0) {
                testWaringText = "未能获取数据"
                testClassName = "label-info__error_label"
            }
        } else {
            if (testStatus === 1) {
                testClassName = "label-info__success_label"
            } else if (testStatus === 2) {
                testClassName = "label-info__error_label"
            }
        }

        let tableTimeFields = []
        let tableListFields = []
        for (let si = 0; si < this.state.tableFields.length; si++) {
            let i = this.state.tableFields[si]
            tableListFields.push({
                id: i.field_name,
                text: i.field_name
            })
            if (i.field_type == 'date' || i.field_type == 'datetime' || i.field_type == 'timestamp' || i.field_type == 'esdate') {
                tableTimeFields.push({
                    id: i.field_name,
                    text: i.field_name
                })
            }
        }

        // console.log("-----------paramsData", paramsData)

        return (
            <>
                <BreadCrumbs
                    history={this.props.history}
                    data={[
                        {
                            title: '标签加工厂',
                            path: '/admin/labelProcess/ticketLabel'
                        }, {
                            title: '用户标签',
                            path: '/admin/labelProcess/userLabel'
                        }, {
                            title: breadCrumbsTitle
                        }
                    ]}
                />

                <div className='label-main__body'>

                    {/* 步骤条 */}
                    <div className='container'>
                        <ol className='step-area'>
                            <li>
                                <div className='step-inner_2'>
                                    <i className='step-num_2'></i>
                                    <span>标签信息配置</span>
                                </div>
                            </li>
                            <li className='success'>
                                <div className='step-inner_2'>
                                    <i className='step-num_2'></i>
                                    <span>标签规则配置</span>
                                </div>
                            </li>
                        </ol>
                    </div>

                    <div className='user-label-form'>
                        <div className='label-title-main'>
                            <span className='label-title-bar'>规则配置</span>
                            <span className='label-title-des'>用于编辑数据源及加工类型</span>
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>数据源类型</span>
                            <Select
                                placeholder='请选择数据源类型'
                                width={560}
                                readOnly={false}
                                value={paramsData.data_source_type}
                                data={cfgData ? cfgData["label_data_source_type"].map(el => {
                                    return {id: el.value, text: el.name}
                                }) : []}
                                onChange={val => this.changeParamsValue("data_source_type", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("data_source_type", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>数据源</span>
                            <Select
                                placeholder='请选择数据源'
                                width={560}
                                readOnly={false}
                                value={paramsData.data_source}
                                data={ruleDataSourceList}
                                onChange={val => this.getTablecfgFields(val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("data_source", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>加工类型</span>
                            <Select
                                placeholder='请选择加工类型'
                                width={560}
                                readOnly={false}
                                value={paramsData.deal_type}
                                data={cfgData ? cfgData["label_process_type"].map(el => {
                                    return {id: el.value, text: el.name}
                                }) : []}
                                onChange={val => this.changeParamsValue("deal_type", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("deal_type", val)}
                            />
                        </div>

                        <div className='label-title-main'>
                            <span className='label-title-bar'>规则详情</span>
                            <span className='label-title-des'>用于编辑标签具体采集规则</span>
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>调度周期</span>
                            <Radiobox
                                options={cfgData ? cfgData["label_schedule_type"].map((item) => {
                                    return {value: item.value, text: item.name}
                                }) : []}
                                value={paramsData.run_cycle}
                                changeCheckedFun={val => this.changeParamsValue("run_cycle", val)}
                            />
                        </div>

                        <div className='label-box-main'>
                            <span className='label required'>时间设定</span>
                            <Select
                                placeholder='请选择取数时间范围'
                                width={276}
                                readOnly={false}
                                value={paramsData.time_cycle}
                                data={cfgData ? cfgData["label_time_range"].map(el => {
                                    return {id: el.value, text: el.name}
                                }) : []}
                                onChange={val => this.changeParamsValue("time_cycle", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("time_cycle", val)}
                            />
                            <span style={{marginLeft: 8}}></span>
                            <Select
                                placeholder='请选择取数时间字段'
                                width={276}
                                readOnly={false}
                                value={paramsData.time_field}
                                data={tableTimeFields}
                                onChange={val => this.changeParamsValue("time_field", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("time_field", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>关联字段</span>
                            <Select
                                width={130}
                                borderTopRightRadius={0}
                                borderBottomRightRadius={0}
                                readOnly={false}
                                value={paramsData.user_field_type}
                                // data={[{ id: "openid", text: "openid"}, { id: "mobile", text: "手机号"},{ id: "qq", text: "QQ"},]}
                                data={cfgData ? cfgData["label_relate_fields"].map(el => {
                                    return {id: el.value, text: el.name}
                                }) : []}
                                onChange={val => this.changeParamsValue("user_field_type", val)}
                            />
                            <Select
                                placeholder='请选择用户账号对应字段'
                                width={292}
                                borderTopLeftRadius={0}
                                borderBottomLeftRadius={0}
                                readOnly={false}
                                value={paramsData.user_fields}
                                data={tableListFields}
                                onChange={val => this.changeParamsValue("user_fields", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("user_fields", val)}
                            />
                            <span style={{marginLeft: 8}}></span>
                            <Select
                                width={130}
                                readOnly={false}
                                value={paramsData.is_sec + ""}
                                data={[{id: "1", text: "加密"}, {id: "0", text: "非加密"}]}
                                onChange={val => this.changeParamsValue("is_sec", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label required'>提取字段</span>
                            <Select
                                placeholder='请选择提取标签字段'
                                width={560}
                                readOnly={false}
                                value={paramsData.label_field}
                                data={tableListFields}
                                onChange={val => this.changeParamsValue("label_field", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("label_field", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label'>过滤条件</span>
                            <Input
                                placeholder='请输入lucene语法'
                                type='textarea'
                                width={560}
                                value={paramsData.filter}
                                sholdCheckValidate={sholdCheckValidate}
                                onChange={val => this.changeParamsValue("filter", val)}
                                validateFun={val => this.ruleFormData("filter", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label'>多值处理</span>
                            <Select
                                placeholder='若不选择，默认取最新一条'
                                width={560}
                                readOnly={false}
                                value={paramsData.multi_type}
                                data={cfgData ? cfgData["label_multi_value"].map(el => {
                                    return {id: el.value, text: el.name}
                                }) : []}
                                onChange={val => this.changeParamsValue("multi_type", val)}
                                sholdCheckValidate={sholdCheckValidate}
                                validateFun={val => this.ruleFormData("multi_type", val)}
                            />
                        </div>
                        <div className='label-box-main'>
                            <span className='label_1'>加工规则</span>
                            <div className='label-group-wrap'>
                                <Select
                                    placeholder="请选择加工规则"
                                    width={267}
                                    value={scrpt}
                                    data={cfgData ? cfgData["label_function_methods"].map(el => {
                                        return {id: el.name, text: el.value.name, item: el}
                                    }) : []}
                                    onChange={(val, el) => {
                                        if (val == "") {
                                            this.setState({
                                                scrpt: val,
                                                ruleNamePlaceholder: "请输入规则名称"
                                            })
                                        } else {
                                            this.setState({
                                                scrpt: val,
                                                ruleNamePlaceholder: el.item.value.placeholder
                                            })
                                        }
                                    }}
                                />
                                <span style={{marginLeft: 8}}></span>
                                <Input
                                    value={dicts}
                                    placeholder={ruleNamePlaceholder}
                                    width={267}
                                    onChange={(val, el) => {
                                        // console.log("====", val)
                                        this.setState({
                                            dicts: val
                                        }, () => {
                                            console.log("----", this.state.dicts)
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className='label-title-main'>
                            <span className='label-title-bar'>标签加工测试</span>
                            <span className='label-title-des'>用于测试标签采集配置</span>
                        </div>

                        <div className='label-btn_box'>
                            <img
                                className='label-btn_img'
                                src={testImg}
                                onClick={this.labelTest}
                            />
                            <Button
                                text='点击测试'
                                onClick={this.labelTest}
                            />
                            <span className={testClassName}>{testWaringText}</span>
                        </div>

                        <div className='label-box-main'>
                            <span className='label-table'>
                                <Table
                                    columns={this.getColumns()}
                                    dataSource={userLabelList}
                                />
                            </span>
                        </div>

                        <div className='btn_box'>
                            <Button
                                type='cancel'
                                text='上一步'
                                onClick={() => this.jumpToOne(labelDetail.id)}
                            />
                            <span style={{marginLeft: 8}}></span>
                            <Button
                                type='submit'
                                text='完成'
                                onClick={this.saveRuleConfig}
                            />
                        </div>
                    </div>

                </div>

            </>

        )
    }
}

export default setupTwo
