import { useState, useEffect } from 'react'
import _ from 'lodash'
import MulRadiobox from '@common/MulRadiobox'
import CollapseWithTime from '@components/CollapseWithTime'
import TrajectInfo from './TrajectInfo'
import { commonApi } from "@utils/request"
import Message from '@common/Message'
import Loadding from '@common/Loadding'
import { relationAccount, getUserTraceItem } from '@services/userSearch'
import './index.css'

const ALL = 'all'

export default function Trajectory(props) {

  const [oriCurRelativeVal, setOriCurRelativeVal] = useState('')
  const [relativeVal, setRelativeVal] = useState('')
  const [channelValue, setChannelValue] = useState(ALL)
  const [relativeOptions, setRelativeOptions] = useState([
    {
      text: '当前帐号',
      value: ''
    }
  ])
  const [channelOptions, setChannelOptions] = useState([
    {
      text: '全部',
      value: ALL
    }
  ])
  const [traceInfo, setTraceInfo] = useState({})
  const [isLoadding, setIsLoadding] = useState(false)
  const [relationAccountMap, setRelationAccountMap] = useState({})
  const [callInfo, setCallInfo] = useState({})
  const [proTmp, setProTmp] = useState({})

  const basicInfo = _.get(props, 'userTranceItem.basicInfo') || {}
  const search = _.get(props, 'search') || {}

  useEffect(() => {
    const callInfo = _.get(props, 'userTranceItem.callInfo') || {}
    setCallInfo(callInfo)

    const proTmp = _.get(props, 'userTranceItem.pro_tmp') || {}
    setProTmp(proTmp)
  }, [props])

  useEffect(() => {
    // 给当前帐号一个标识
    const oriCurID = basicInfo.OPENID || basicInfo.PHONE || basicInfo.QQ
    if (!oriCurID) return
    setRelativeVal(oriCurID)
    setOriCurRelativeVal(oriCurID)
    setChannelValue(ALL)
  }, [basicInfo])

  useEffect(() => {
    if (!relativeVal) return

    if (!oriCurRelativeVal) return

    let params = {}
    if (oriCurRelativeVal === relativeVal) {
      params = search
    } else {
      const curRecord = relationAccountMap[relativeVal] || {}
      params = {
        account: curRecord.account,
        accountType: curRecord.type_id
      }
    }

    setIsLoadding(true)
    commonApi(getUserTraceItem, params).then(data => {
      const traceInfo = _.get(data, 'traceInfo') || {}
      setTraceInfo(traceInfo)

      const callInfo = _.get(data, 'callInfo') || {}
      setCallInfo(callInfo)

      const proTmp = _.get(data, 'pro_tmp') || {}
      setProTmp(proTmp)
    }).catch(err => Message.error(err))
    .finally(() => setIsLoadding(false))
  }, [relativeVal])

  useEffect(() => {
    if (_.isEmpty(basicInfo)) return
    commonApi(relationAccount, {
      qq: basicInfo.QQ,
      phone: basicInfo.PHONE,
      openid: basicInfo.OPENID,
      is_detail: 0
    }).then(data => {
      setRelationAccountMap(data)
      const keys = _.keys(data).map(key => key) || []
      let arr = []
      if (_.isArray(keys) && keys.length) {
        arr = keys.reduce((prev, key) => {
          prev.push({
            text: `${key}(${_.get(data[key], 'account_type') || '-'})`,
            value: key
          })
          return prev
        }, [])
      }
      setRelativeOptions([{
        text: '当前帐号',
        value: basicInfo.OPENID || basicInfo.PHONE || basicInfo.QQ
      }, ...arr])
    }).catch(err => Message.error(err))
  }, [basicInfo])

  useEffect(() => {
    // 数据从总接口来，首次不用额外请求
    if (props.isLoadding) {
      return
    }

    if (!oriCurRelativeVal) return

    let params = {}
    if (
      (!relativeVal && !_.isEmpty(search)) ||
      oriCurRelativeVal === relativeVal
    ) {
      params = search
    } else {
      const curRecord = relationAccountMap[relativeVal] || {}
      params = {
        account: curRecord.account,
        accountType: curRecord.type_id
      }
    }

    setIsLoadding(true)
    commonApi(getUserTraceItem, {
      ...params,
      ...ALL !== channelValue ? {
        product_set: channelValue
      } : {}
    }).then(data => {
      const record = _.get(data, 'traceInfo')
      setTraceInfo(record)
    }).catch(err => {
      Message.error(err)
    }).finally(() => setIsLoadding(false))
  }, [channelValue])

  useEffect(() => {
    if (!props.isLoadding) return
    const traceInfo = _.get(props, 'userTranceItem.traceInfo')
    // 首次使用外面的请求
    setTraceInfo(traceInfo)
  }, [props])

  useEffect(() => {
    let sum = 0
    let options = _.keys(proTmp).reduce((prev, key) => {
      if (!isNaN(+(proTmp[key])) && +proTmp[key] !== 0) {
        sum += +(proTmp[key])
        prev.push({
          text: `${key}(${proTmp[key]})`,
          value: key
        })
      }
      return prev
    }, [])
    setChannelOptions([{
      text: `全部(${sum})`,
      value: ALL
    }, ...options])
  }, [proTmp])

  return (
    <Loadding top={200} loadding={isLoadding} tipContent='拼命加载中...'>
      <div className='monitor__user-search__outer-wrap'>
        <div className='monitor__user-search__service__link-account-wrap'>
          <div className='label'>关联帐号</div>
          <MulRadiobox
            colorStyle={"light"}
            options={relativeOptions}
            value={relativeVal}
            changeCheckedFun={val => {
              setRelativeVal(val)
              setChannelValue(ALL)
            }}
          />
        </div>
        <div className='monitor__user-search__service__link-account-wrap'>
          <div className='label'>渠道选择</div>
          <MulRadiobox
            colorStyle={"light"}
            options={channelOptions}
            value={channelValue}
            changeCheckedFun={setChannelValue}
          />
        </div>
        <div className='monitor__user-search__service__desc-wrap'>
          <TrajectInfo
            callInfo={callInfo}
            channelValue={channelValue}
          />
        </div>
        <CollapseWithTime
          search={search}
          info={traceInfo || {}}
          config={
            {
              keys: ['traceTime', 'type', 'archivepath_name', 'desc'], // 显示字段，从左到右
              widths: [180, 200], // 显示字段对应的width
              contextKey: 'info', // context 对应字段
              contextKeys: ['time', 'content'], // 每一行展示字段
              contextwidths: [220], // 每一行展示字段对应width
            }
          }
        />
      </div>
    </Loadding>
  )
}
