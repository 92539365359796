import React, { useState, useEffect } from 'react'
import { Dropdown, Radio, Collapse, Button, Layout, Pagination } from 'element-react'
import { searchFields } from '../../../services/subjectDomainDetail'
import { EXTRA_MAP, EXTRA_MAP_TEXT } from './../common'
import SdwEmpty from '../../../common/Empty'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import 'element-theme-default'
import './style/topicAllClass.css'

export default props => {

  const [dropdownMenu, setDropdownMenu] = useState([{
    id: '',
    name: '全部'
  }])
  
  const [curTitle, setCurTitle] = useState('全部')

  const [radioVal, setRadioVal] = useState('全部（0）')

  const [allData, setAllData] = useState({})

  const [fieldsData, setFieldsData] = useState({})

  const [paginationData, setPaginationData] = useState({})

  const [curStandardType, setCurStandardType] = useState('')

  const [currentChange, setCurrentChange] = useState(1)

  useEffect(() => {
    let allRecord = props.data.filter(i => i.type === 'all')[0] || {
      total: 0,
      primordial: 0,
      atom: 0,
      derive: 0,
    }
    let dropdownMenu = props.data.length ? props.data : [{
      id: '',
      name: '全部'
    }]

    setAllData(allRecord)
    setRadioVal(`全部（${allRecord.total}）`)
    setDropdownMenu(dropdownMenu)
    onSearchFields()
  }, [props.data])

  useEffect(() => {
    onSearchFields()
  }, [curStandardType])
  
  useEffect(() => {
    onSearchFields()
  }, [currentChange])
  
  useEffect(() => {
    onSearchFields()
  }, [props.curTopicID])
  
  function handleCurStandardType (radioVal = '') {
    let standardType = ''
    if (new RegExp('原生字段').test(radioVal)) {
      standardType = 1
    } else if (new RegExp('原子指标').test(radioVal)) {
      standardType = 2
    } else if (new RegExp('派生指标').test(radioVal)) {
      standardType = 3
    }

    setCurStandardType(standardType)
  }

  function onSearchFields () {
    if (!props.curTopicID) return // id都没有，无效查询
    searchFields({
      limit: 10,
      page: currentChange,
      search: JSON.stringify({
        topic: {value: props.curTopicID, operator: '='},
        ...curStandardType ? {
          standard_type: {value: curStandardType, operator: '='}
        } : {}
      })
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let jsonData = res.data.data || {}
          let fieldsData = jsonData.items || []
          setFieldsData(fieldsData)
          setPaginationData({
            pages: jsonData.pages,
            total: jsonData.total,
            currentPage: jsonData.current_page,
          })
        }
      })
  }

  return (
    <div className="border-radius-4px" style={{ backgroundColor: '#fff', marginTop: '16px' }}>
      <div style={{ borderBottom: '1px solid #eee' }} className="topic-all-class__dropdown-menu_wrap">
        <Dropdown 
          menuAlign="start"
          onCommand={command => {
            setAllData(command)
            setRadioVal(`全部（${command.total}）`)
            setCurTitle(command.name)
          }}
          menu={(
            <Dropdown.Menu>
              {
                dropdownMenu.map(menu => (
                  <Dropdown.Item 
                    key={menu.id}
                    command={menu}
                  >{menu.name || ''}</Dropdown.Item>
                ))
              }
            </Dropdown.Menu>
          )}
        >
          <span className="el-dropdown-link" style={{ fontSize: '18px' }}>
            {
              curTitle
            }<i
              style={{ fontSize: '12px' }} 
              className="el-icon-arrow-down el-icon--right"></i>
          </span>
        </Dropdown>
      </div>
      
      <div style={{ padding: '0 20px 20px' }} className="topic-all-class__radio-group">
        <Radio.Group value={radioVal} onChange={value => {
          setRadioVal(value)
          handleCurStandardType(value)
        }}>
          <Radio.Button value={`全部（${allData.total || 0}）`} />
          <Radio.Button value={`原生字段（${allData.primordial || 0}）`} />
          <Radio.Button value={`原子指标（${allData.atom || 0}）`} />
          <Radio.Button value={`派生指标（${allData.derive || 0}）`} />
        </Radio.Group>
      </div>

      <div>
        {
          !!(fieldsData.length) ?
          <Collapse accordion value={1}>
            { 
              fieldsData.map((field, index) => (
                <Collapse.Item name={index + 1} key={index} title={`${field.field_name_ch}（${field.field_name}）`}>
                  <FieldItem field={field} />
                  {
                    hasPriv('getFieldById') &&
                    <div style={{ marginLeft: '10px' }}>
                      <Button 
                        type="text"
                        onClick={ () => props.jumpToDetail(field.id) }
                      >查看详情<i className="el-icon-arrow-right"></i></Button>
                    </div>
                  }
                </Collapse.Item>
              ))
            }
            <div className="topic-all-class__pagination-wrap">
              <Pagination 
                className="topic-all-class__pagination-item" 
                layout="prev, pager, next" 
                total={paginationData.total} 
                small={true}
                currentPage={currentChange}
                onCurrentChange={page => setCurrentChange(page)}
              />
            </div>
          </Collapse> :
          <SdwEmpty />
        }
      </div>
    </div>
  )
}

function FieldItem (props) {
  return (
    <>
      <Layout.Row gutter={20}>
        <Layout.Col span="24">
          <span className="field-item__title">定义</span>
          <span>{ props.field.field_define || '-' }</span>
        </Layout.Col>
      </Layout.Row>
      <Layout.Row gutter={20}>
        <Layout.Col span="12">
          <span className="field-item__title">字段类型</span>
          <span>{ EXTRA_MAP_TEXT[props.field.standard_type] || '-' }</span>
        </Layout.Col>
        <Layout.Col span="12">
          <span className="field-item__title">所属业务</span>
          <span>{ props.field.business_name || '-' }</span>
        </Layout.Col>
      </Layout.Row>
      <Layout.Row gutter={20}>
        <Layout.Col span="12">
          <span className="field-item__title">数据类型</span>
          <span>{ props.field.field_type || '-' }</span>
        </Layout.Col>
        <Layout.Col span="12">
          <span className="field-item__title">数据格式</span>
          <span>{ props.field.field_format || '-' }</span>
        </Layout.Col>
      </Layout.Row>
      <Layout.Row gutter={20}>
        <Layout.Col span="12">
          <span className="field-item__title">创建时间</span>
          <span>{ props.field.create_time || '-' }</span>
        </Layout.Col>
        <Layout.Col span="12">
          <span className="field-item__title">接入时间</span>
          <span>{ props.field.modify_time || '-' }</span>
        </Layout.Col>
      </Layout.Row>
    </>
  )
}
