import produce from 'immer'

const state = {
  allSelectedIndicator: [], // 所有的已选择指标---用于表二显示使用
  curShowSelectedTarget: [], // 当前指标tab展示的一个指标的所有数据

  // 已选指标---单个指标的已选指标---用与表一比较/显示使用
  oriAllSelectedTarget: [],
  comfirmVisible: false,
  currentClickTreeNode: {},
  curClickTreeValue: {},
  targetTime: [],
  targetDimension: [],
}

const updateAllSelectedIndicator = produce((state, payload) => {
  state.allSelectedIndicator = payload
})

const updateCurShowSelectedTarget = produce((state, payload) => {
  state.curShowSelectedTarget = payload
})

const updateOriAllSelectedTarget = produce((state, payload) => {
  state.oriAllSelectedTarget = payload
})

const changeComfirmVisible = produce((state, payload) => {
  state.comfirmVisible = payload
})

const updateCurrentClickTreeNode = produce((state, payload) => {
  state.currentClickTreeNode = payload
})

const updateCurClickTreeValue = produce((state, payload) => {
  state.curClickTreeValue = payload
})

const updateTargetTime = produce((state, payload) => {
  state.targetTime = payload
})

const updateTargetDimension = produce((state, payload) => {
  state.targetDimension = payload
})

const reducers = {
  updateAllSelectedIndicator,
  updateCurShowSelectedTarget,
  updateOriAllSelectedTarget,
  changeComfirmVisible,
  updateCurrentClickTreeNode,
  updateCurClickTreeValue,
  updateTargetTime,
  updateTargetDimension,
}

export default { state, reducers }
