import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Select from '@common/Select'
import './index.css'
import ConfigGroup from '@pages/Monitor/KeyIndicatorAllocation/IndexGroupConfiguration/StepWin/ConfigGroup'

class GroupingConfiguration extends PureComponent {

    state = {
        twoGroup: [],
        threeGroup: [],
        relateModule: [],
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formData != this.props.formData) {
            this.initData()
        }
    }

    initData = () => {
        let {formData} = this.props

        let two_group_obj = []
        let three_group_obj = []
        let relateModule = []
        let twoGroup = []
        let threeGroup = []

        if (formData.two_group) {
            two_group_obj = JSON.parse(formData.two_group)
            twoGroup = two_group_obj.map((i, k) => {
                return {queue: k+1, name: i.name, item : i}
            })
        }
        if (formData.three_group) {
            three_group_obj = JSON.parse(formData.three_group)
            threeGroup = three_group_obj.map((i, k) => {
                return {queue: k+1, name: i.name, item : i}
            })
        }
        if (formData.relate_module) {
            relateModule = JSON.parse(formData.relate_module)
        }
        this.setState({
            twoGroup: twoGroup,
            threeGroup: threeGroup,
            relateModule: relateModule,
        })
    }

    setFormValue = (type, val) => {
        let formData = this.props.formData
        let formDataClone = _.cloneDeep(formData)
        switch (type) {
            case "two_group":
                formDataClone.two_group = val
                break
            case "three_group_obj":
                formDataClone.three_group_obj = val
                break
            case "relate_module_obj":
                formDataClone.relate_module_obj = val
                break
        }
        this.props.updateFormData(formDataClone)
    }

    //修改分组的下拉值
    changeConfigGroup = (index, type, val, group_type) => {
        let group = []
        let { twoGroup, threeGroup} = this.state
        let { formData} = this.props
        if (group_type == "twoGroup") {
            group = twoGroup
        } else if (group_type == "threeGroup") {
            group = threeGroup
        }
        let formDataClone = _.cloneDeep(formData)
        let curArr = _.cloneDeep(group)
        if (type === 'queue') {
            let beforeVal = Number(index - 1)
            let currentVal = Number(val - 1)

            if (beforeVal < currentVal) {
                for (let j = beforeVal + 1; j <= currentVal; j++) {
                    curArr[j]['queue'] = curArr[j]['queue'] - 1
                }
            } else if (beforeVal > currentVal) {
                for (let j = beforeVal - 1; j >= currentVal; j--) {
                    curArr[j]['queue'] = curArr[j]['queue'] + 1
                }
            }
        }
        curArr[index - 1][type] = val
        if (group_type == "twoGroup") {
            if (curArr.length == 0) {
                formDataClone.two_group = ""
            } else {
                formDataClone.two_group = JSON.stringify(curArr.sort((a,b)=>a.queue - b.queue).map(i=>{
                    return {name:i.name}
                }))
            }
            this.setState({ twoGroup: curArr })
        } else if (group_type == "threeGroup") {
            if (curArr.length == 0) {
                formDataClone.three_group = ""
            } else {
                formDataClone.three_group = JSON.stringify(curArr.sort((a,b)=>a.queue - b.queue).map(i=>{
                    return {name:i.name}
                }))
            }
            this.setState({ threeGroup: curArr })
        }
        this.props.updateFormData(formDataClone)
    }
    //删除分组
    onDelConfigRule = (index, group_type) => {
        let group = []
        let { twoGroup, threeGroup} = this.state
        let { formData} = this.props
        if (group_type == "twoGroup") {
            group = twoGroup
        } else if (group_type == "threeGroup") {
            group = threeGroup
        }
        let formDataClone = _.cloneDeep(formData)
        let curArr = _.cloneDeep(group)
        let lastIndex = curArr.length - 1
        curArr.splice(index, 1)
        // 删除的不是最后一项，需要更新删除下面的序号
        if (lastIndex !== index) {
            curArr = curArr.map((item, k) => {
                if (k < index) {
                    return item
                } else {
                    return _.assign({}, item, {
                        queue: item.queue - 1
                    })
                }
            })
        }
        if (group_type == "twoGroup") {
            if (curArr.length == 0) {
                formDataClone.two_group = ""
            } else {
                formDataClone.two_group = JSON.stringify(curArr.sort((a,b)=>a.queue - b.queue).map(i=>{
                    return {name:i.name}
                }))
            }
            this.setState({ twoGroup: curArr })
        } else if (group_type == "threeGroup") {
            if (curArr.length == 0) {
                formDataClone.three_group = ""
            } else {
                formDataClone.three_group = JSON.stringify(curArr.sort((a,b)=>a.queue - b.queue).map(i=>{
                    return {name:i.name}
                }))
            }
            this.setState({ threeGroup: curArr })
        }
        this.props.updateFormData(formDataClone)
    }
    //添加分组
    addConfigRules = (group_type) => {
        let group = []
        let { twoGroup, threeGroup} = this.state
        let { formData} = this.props
        if (group_type == "twoGroup") {
            group = twoGroup
        } else if (group_type == "threeGroup") {
            group = threeGroup
        }
        let formDataClone = _.cloneDeep(formData)
        let curArr = _.cloneDeep(group)
        curArr.push(_.assign({}, {queue: 0, name: "", item : {}}, {
            queue: group.length + 1
        }))
        if (group_type == "twoGroup") {
            if (curArr.length == 0) {
                formDataClone.two_group = ""
            } else {
                formDataClone.two_group = JSON.stringify(curArr.sort((a,b)=>a.queue - b.queue).map(i=>{
                    return {name:i.name}
                }))
            }
            this.setState({ twoGroup: curArr })
        } else if (group_type == "threeGroup") {
            if (curArr.length == 0) {
                formDataClone.three_group = ""
            } else {
                formDataClone.three_group = JSON.stringify(curArr.sort((a,b)=>a.queue - b.queue).map(i=>{
                    return {name:i.name}
                }))
            }
            this.setState({ threeGroup: curArr })
        }
        this.props.updateFormData(formDataClone)
    }
    //删除关联模块
    onRelationModule = (index) => {
        let { relateModule} = this.state
        let {formData} = this.props

        let curArr = _.cloneDeep(relateModule)
        let formDataClone = _.cloneDeep(formData)

        curArr.splice(index, 1)
        this.setState({
            relateModule:curArr
        })
        formDataClone.relate_module = JSON.stringify(curArr)
        this.props.updateFormData(formDataClone)
    }
    //添加关联模块
    addRelationModule = () => {
        let { relateModule} = this.state
        let {formData} = this.props
        let curArr = _.cloneDeep(relateModule)
        let formDataClone = _.cloneDeep(formData)
        curArr.push({id: 1, type: 1})
        this.setState({ relateModule: curArr })
        formDataClone.relate_module = JSON.stringify(curArr)
        this.props.updateFormData(formDataClone)
    }
    setRelationModule = (k, type, val) => {
        let { relateModule} = this.state
        let { formData} = this.props
        let relateModuleClone = _.cloneDeep(relateModule)
        let formDataClone = _.cloneDeep(formData)
        switch (type) {
            case "id":
                relateModuleClone[k].id = val
                break
            case "type":
                relateModuleClone[k].type = val
                break
        }
        this.setState({
            relateModule:relateModuleClone
        })
        formDataClone.relate_module = JSON.stringify(relateModuleClone)
        this.props.updateFormData(formDataClone)
    }
    render() {
        let {
            twoGroup,
            threeGroup,
            relateModule,
        } = this.state
        let {
            sholdCheckValidate,
            formData
        } = this.props
        return (
            <div style={{ width: 800 }}>
                <span className='label-title-bar'>二级分组</span>
                <span className="label-title-des">（选填）用于顶部筛选</span>
                <div className="main-body">
                    <div className="form-add">

                        <span className="edit-content">
                            {twoGroup.length ==0 && <span
                              className="add-config-rule-btn"
                              onClick={()=>this.addConfigRules("twoGroup")}
                            >添加分组</span>}
                            {twoGroup.length >0 && twoGroup.sort((a, b)=>a.queue - b.queue).map((row, k) => {
                                let dataList = twoGroup.map((i, index) => {
                                    return {
                                        id: index+1,
                                        text: index + 1
                                    }
                                })
                                console.log("dataList", dataList)
                                return (
                                    <div key={"twoGroup"+k} className="content-one">
                                        <ConfigGroup
                                          data={row}
                                          sholdCheckValidate={sholdCheckValidate}
                                          index={k}
                                          group_type={"twoGroup"}
                                          dataList={dataList}
                                          changeConfigGroup={this.changeConfigGroup}
                                          onDelConfigRule={this.onDelConfigRule}
                                          addConfigRules={this.addConfigRules}
                                        />
                                    </div>
                                )
                            })}
                        </span>
                    </div>
                </div >

                <span className='label-title-bar'>三级分组</span>
                <span className="label-title-des">（选填）用于同组指标区分</span>
                <div className="main-body">
                    <div className="form-add">
                        <span className="edit-content">
                            {threeGroup.length ==0 && <span
                              className="add-config-rule-btn"
                              onClick={()=>this.addConfigRules("threeGroup")}
                            >添加分组</span>}
                            {threeGroup.length >0 && threeGroup.sort((a, b)=>a.queue - b.queue).map((row, k) => {
                                let dataList = threeGroup.map((i, index) => {
                                    return {
                                        id: index+1,
                                        text: index + 1
                                    }
                                })
                                console.log("dataList", dataList)
                                return (
                                  <div key={"threeGroup"+k} className="content-one">
                                      <ConfigGroup
                                        sholdCheckValidate={sholdCheckValidate}
                                        data={row}
                                        index={k}
                                        group_type={"threeGroup"}
                                        dataList={dataList}
                                        changeConfigGroup={this.changeConfigGroup}
                                        onDelConfigRule={this.onDelConfigRule}
                                        addConfigRules={this.addConfigRules}
                                      />
                                  </div>
                                )
                            })}
                        </span>
                    </div>
                </div>

                <span className='label-title-bar'>关联模块</span>
                <span className="label-title-des">（选填）用于底部关联，最多2个</span>
                <div className="main-body">
                    <div className="form-add">
                        <span className="edit-content">
                            {relateModule.length ==0 && <span
                              className="add-config-rule-btn"
                              onClick={()=>this.addRelationModule()}
                            >添加模块</span>}
                            {relateModule.length > 0 && relateModule.map((row, k) => {
                                return (
                                    <div key={k} className="content-one">
                                        <span className="editorTwo-input-map left body-left">
                                            <Select
                                                placeholder='请选择'
                                                value={row.id}
                                                sholdCheckValidate={sholdCheckValidate}
                                                validateFun={val => !!val?true:"模块不能为空"}
                                                onChange={val => this.setRelationModule(k, 'id', val)}
                                                data={[{
                                                    id: 1,
                                                    text: '经营分析入口'
                                                }, {
                                                    id: 2,
                                                    text: '帐号专区入口'
                                                }, {
                                                    id: 3,
                                                    text: '400专区入口'
                                                }
                                                ]}
                                                width={300}
                                            />
                                        </span>
                                        <span className="editorTwo-input-map right">
                                            <Select
                                                  sholdCheckValidate={sholdCheckValidate}
                                                  validateFun={val => !!val?true:"模块不能为空"}
                                                placeholder='请选择'
                                                onChange={val => this.setRelationModule(k, 'type', val)}
                                                value={row.type}
                                                data={[{
                                                    id: 1,
                                                    text: '独立入口样式'
                                                }, {
                                                    id: 2,
                                                    text: '1/2行样式'
                                                }
                                                ]}
                                                width={300}
                                            />
                                        </span>
                                        {relateModule.length == 1  && <i onClick={() => this.addRelationModule()} className="el-icon-plus"></i>}
                                        <i onClick={() => this.onRelationModule(k)} className="el-icon-minus"></i>
                                    </div>
                                )
                            })}
                        </span>
                    </div>
                </div>
            </div >
        )
    }
}

const mapState = state => ({
    formData: state.keyIndicatorAllocation.formData
})

const mapDispatch = dispatch => ({
    updateFormData: dispatch.keyIndicatorAllocation.updateFormData,
})

export default connect(mapState, mapDispatch)(GroupingConfiguration)
