import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Switch from '@common/Switch'
import Button from '@common/Button'
import './index.css'
import SdwInput from '@common/Input'
import _ from 'lodash'
import { channelItemSelects, getAlarmItemSelects, getStaffs } from '@api/alertRules'

class Staffing extends PureComponent {
    state = {
        allMembers:[],
        visible_list:"",
        subscribe_ing:"",
        real_time_change:"",
        sholdCheckValidate:true,
        usedStaff : [],
    }
    componentDidMount() {
        this.initData()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formData != this.props.formData) {
            let formData = this.props.formData
            let subscribe_info_dict = formData.subscribe_info_dict
            let usedStaff = []
            if (subscribe_info_dict.real_time_change.length > 0) {
                usedStaff = usedStaff.concat(subscribe_info_dict.real_time_change)
            }
            if (subscribe_info_dict.change_day.length > 0) {
                usedStaff = usedStaff.concat(subscribe_info_dict.change_day)
            }
            if (subscribe_info_dict.once_day.length > 0) {
                usedStaff = usedStaff.concat(subscribe_info_dict.once_day)
            }
            if (subscribe_info_dict.change_week.length > 0) {
                usedStaff = usedStaff.concat(subscribe_info_dict.change_week)
            }
            if (subscribe_info_dict.once_week.length > 0) {
                usedStaff = usedStaff.concat(subscribe_info_dict.once_week)
            }
            if (subscribe_info_dict.change_month.length > 0) {
                usedStaff = usedStaff.concat(subscribe_info_dict.change_month)
            }
            if (subscribe_info_dict.once_month.length > 0) {
                usedStaff = usedStaff.concat(subscribe_info_dict.once_month)
            }
            console.log("Staffing====usedStaff", usedStaff)
            this.setState({
                usedStaff:usedStaff
            })
        }
    }

    initData = () => {
        getStaffs().then(res => {
            if (res && res.data && res.data.code === 0) {
                let list = _.isArray(res.data.data) ? res.data.data : []
                list.push({name:"default_focus", value:"default_focus"})
                this.setState({
                    allMembers: list
                })
            }
        })
    }
    setFormValue = (type, val) => {
        let formData = this.props.formData
        let formDataClone = _.cloneDeep(formData)
        switch (type) {
            case "enabled":
                formDataClone.enabled = val
                break
            case "visible_list":
                formDataClone.visible_list = val
                break
            case "focused_list":
                formDataClone.focused_list = val
                break
            case "real_time_change":
                formDataClone.subscribe_info_dict.real_time_change = val
                break
            case "change_day":
                formDataClone.subscribe_info_dict.change_day = val
                break
            case "once_day":
                formDataClone.subscribe_info_dict.once_day = val
                break
            case "change_week":
                formDataClone.subscribe_info_dict.change_week = val
                break
            case "once_week":
                formDataClone.subscribe_info_dict.once_week = val
                break
            case "change_month":
                formDataClone.subscribe_info_dict.change_month = val
                break
            case "once_month":
                formDataClone.subscribe_info_dict.once_month = val
                break
        }
        this.props.updateFormData(formDataClone)
    }

    render() {
         let {
             allMembers,
             usedStaff
         } = this.state

        let {
             formData,
            saveSubmit,
         } = this.props
        console.log("staffing=======formData", formData)
        console.log("staffing=======usedStaff", usedStaff)
        let focused_list_members = allMembers.filter(i => formData.visible_list.indexOf(i.value) != -1)
        //let subscribe_info_members = formData.focused_list
        // let subscribe_info_members = focused_list_members.map(i => {
        //     if (usedStaff.indexOf(i.value) != -1) {
        //         return {name:i.name, value:i.value, disabled:true}
        //     }
        //     return {name:i.name, value:i.value, disabled:false}
        // })
        let subscribe_info_members = []
        for (let k in focused_list_members) {
            let i = focused_list_members[k]
            if (formData.focused_list.indexOf(i.value) != -1) {
                if (usedStaff.indexOf(i.value) != -1) {
                    subscribe_info_members.push({name:i.name, value:i.value, disabled:true})
                } else {
                    subscribe_info_members.push({name:i.name, value:i.value, disabled:false})
                }
            }
        }
        console.log("staffing=======formData", formData)
        console.log("staffing=======focused_list_members", focused_list_members)
        console.log("staffing=======subscribe_info_members", subscribe_info_members)
        return (
            <div className='access-body'>

                <span className='label-title-bar'>权限管理</span>
                <span className="label-title-des">用于控制指标组的可见范围</span>
                <div className='main-body'>
                    <div className='label-wrap'>
                        <span className='label'>可见范围：</span>
                        <SdwInput
                          isMultipleChoice={true}
                          multipleValue={formData.visible_list}
                          multipleOption={allMembers}
                          width={550}
                          placeholder="请输入值"
                          //validateFun={this.validateArrFun}
                          //sholdCheckValidate={sholdCheckValidate}
                          onClear={() => this.setFormValue("visible_list", [])} // 这里的onClear只是清除所有时才触发
                          changeMultipleValue={list => this.setFormValue("visible_list", list)}
                        />
                    </div>
                    <div className='label-wrap'>
                        <span className='label'>正在关注：</span>
                        <div>
                            <SdwInput
                              isMultipleChoice={true}
                              multipleValue={formData.focused_list}
                              multipleOption={focused_list_members}
                              width={550}
                              placeholder="请输入值"
                              //validateFun={this.validateArrFun}
                              //sholdCheckValidate={sholdCheckValidate}
                              onClear={() => this.setFormValue("focused_list", [])} // 这里的onClear只是清除所有时才触发
                              changeMultipleValue={list => this.setFormValue("focused_list", list)}
                            />
                        </div>
                    </div>
                    <div className="label-title-tip">如需调整关注人看到的列表顺序，请前往关注人管理设置</div>
                </div>

                <span className='label-title-bar'>订阅管理</span>
                <span className="label-title-des">用于对指标组订阅进行管理（人员不可重复出现在多个维度）</span>
                <div className='main-body'>

                    <div className='editor-main'>
                        <div className='collapse-borderstyle'>
                            <div className='collapse-listitem'>
                                <div className='collapse-listitem-title'>
                                    <div className='collapse-listitem-title-text'>
                                        <span>实时数据（每日最多推送一次）</span>
                                    </div>
                                </div>
                                <div className='collapse-listitem-content'>
                                    <div className='data-editor-win-wrap'>
                                        <span className='label'>异动提醒：</span>
                                        <SdwInput
                                          isMultipleChoice={true}
                                          multipleValue={formData.subscribe_info_dict.real_time_change}
                                          multipleOption={subscribe_info_members}
                                          width={550}
                                          placeholder="请输入值"
                                          //validateFun={this.validateArrFun}
                                          //sholdCheckValidate={sholdCheckValidate}
                                          onClear={() => this.setFormValue("real_time_change", [])} // 这里的onClear只是清除所有时才触发
                                          changeMultipleValue={list => this.setFormValue("real_time_change", list)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='editor-main'>
                        <div className='collapse-borderstyle'>
                            <div className='collapse-listitem'>
                                <div className='collapse-listitem-title'>
                                    <div className='collapse-listitem-title-text'>
                                        <span>日视图数据</span>
                                    </div>
                                </div>
                                <div className='collapse-listitem-content'>
                                    <div className='data-editor-win-wrap'>
                                        <span className='label'>异动提醒：</span>
                                        <SdwInput
                                          isMultipleChoice={true}
                                          multipleValue={formData.subscribe_info_dict.change_day}
                                          multipleOption={subscribe_info_members}
                                          width={550}
                                          placeholder="请输入值"
                                          //validateFun={this.validateArrFun}
                                          //sholdCheckValidate={sholdCheckValidate}
                                          onClear={() => this.setFormValue("change_day", [])} // 这里的onClear只是清除所有时才触发
                                          changeMultipleValue={list => this.setFormValue("change_day", list)}
                                        />
                                        <span className='label'>每日一次：</span>
                                        <SdwInput
                                          isMultipleChoice={true}
                                          multipleValue={formData.subscribe_info_dict.once_day}
                                          multipleOption={subscribe_info_members}
                                          width={550}
                                          placeholder="请输入值"
                                          //validateFun={this.validateArrFun}
                                          //sholdCheckValidate={sholdCheckValidate}
                                          onClear={() => this.setFormValue("once_day", [])} // 这里的onClear只是清除所有时才触发
                                          changeMultipleValue={list => this.setFormValue("once_day", list)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='editor-main'>
                        <div className='collapse-borderstyle'>
                            <div className='collapse-listitem'>
                                <div className='collapse-listitem-title'>
                                    <div className='collapse-listitem-title-text'>
                                        <span>周视图数据</span>
                                    </div>
                                </div>
                                <div className='collapse-listitem-content'>
                                    <div className='data-editor-win-wrap'>
                                        <span className='label'>异动提醒：</span>
                                        <SdwInput
                                          isMultipleChoice={true}
                                          multipleValue={formData.subscribe_info_dict.change_week}
                                          multipleOption={subscribe_info_members}
                                          width={550}
                                          placeholder="请输入值"
                                          //validateFun={this.validateArrFun}
                                          //sholdCheckValidate={sholdCheckValidate}
                                          onClear={() => this.setFormValue("change_week", [])} // 这里的onClear只是清除所有时才触发
                                          changeMultipleValue={list => this.setFormValue("change_week", list)}
                                        />
                                        <span className='label'>每周一次：</span>
                                        <SdwInput
                                          isMultipleChoice={true}
                                          multipleValue={formData.subscribe_info_dict.once_week}
                                          multipleOption={subscribe_info_members}
                                          width={550}
                                          placeholder="请输入值"
                                          //validateFun={this.validateArrFun}
                                          //sholdCheckValidate={sholdCheckValidate}
                                          onClear={() => this.setFormValue("once_week", [])} // 这里的onClear只是清除所有时才触发
                                          changeMultipleValue={list => this.setFormValue("once_week", list)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='editor-main'>
                        <div className='collapse-borderstyle'>
                            <div className='collapse-listitem'>
                                <div className='collapse-listitem-title'>
                                    <div className='collapse-listitem-title-text'>
                                        <span>月视图数据</span>
                                    </div>
                                </div>
                                <div className='collapse-listitem-content'>
                                    <div className='data-editor-win-wrap'>
                                        <span className='label'>异动提醒：</span>
                                        <SdwInput
                                          isMultipleChoice={true}
                                          multipleValue={formData.subscribe_info_dict.change_month}
                                          multipleOption={subscribe_info_members}
                                          width={550}
                                          placeholder="请输入值"
                                          //validateFun={this.validateArrFun}
                                          //sholdCheckValidate={sholdCheckValidate}
                                          onClear={() => this.setFormValue("change_month", [])} // 这里的onClear只是清除所有时才触发
                                          changeMultipleValue={list => this.setFormValue("change_month", list)}
                                        />
                                        <span className='label'>每月一次：</span>
                                        <SdwInput
                                          isMultipleChoice={true}
                                          multipleValue={formData.subscribe_info_dict.once_month}
                                          multipleOption={subscribe_info_members}
                                          width={550}
                                          placeholder="请输入值"
                                          //validateFun={this.validateArrFun}
                                          //sholdCheckValidate={sholdCheckValidate}
                                          onClear={() => this.setFormValue("once_month", [])} // 这里的onClear只是清除所有时才触发
                                          changeMultipleValue={list => this.setFormValue("once_month", list)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-switch">
                        <span style={{ display: 'inline-block', width: '100px' }}>是否启用</span>
                        <Switch
                          value={formData.enabled}
                          changeValue={val => this.setFormValue("enabled", val)}
                        />
                    </div>

                    <div className="footer-button">
                        <Button
                            type="submit"
                            text="保存配置"
                            onClick={saveSubmit}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

const mapState = state => ({
    formData: state.keyIndicatorAllocation.formData
})

const mapDispatch = dispatch => ({
    updateFormData: dispatch.keyIndicatorAllocation.updateFormData,
})

export default connect(mapState, mapDispatch)(Staffing)
