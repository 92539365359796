import { useRef, useState, useMemo, useEffect } from 'react'
import _ from 'lodash'
import Drawer from "@common/Drawer"
import Form from "@common/Form_D"
import Select from '@common/Select'
import Input from '@common/Input'
import Message from '@common/Message'
import Switch from '@common/Switch'
import { insertStandardManage, updateStandardManage, elementLabels } from '@services/labelManagement'
import { commonApi } from '@utils/request'

function notEmpty(val) {
  return !!val
}

const rules = {
  label_name: [
    { func: notEmpty, message: '请选择标签名称' }
  ],
  task_label_id: [
    { func: notEmpty, message: '请输入标签ID' }
  ],
  // label_field: [
  //   { func: notEmpty, message: '请输入标签英文名' }
  // ],
  label_type: [
    { func: notEmpty, message: '请选择标签类型' }
  ],
  standard_id: [
    { func: notEmpty, message: '请选择数据标准' }
  ],
  topic: [
    { func: notEmpty, message: '请选择所属主题域' }
  ],
  service: [
    { func: notEmpty, message: '请选择所属业务' }
  ],
}

export default function EditWin(props) {
  const {
    isEdit, record, showEditWin, setShowEditWin, updatePage, getTopicRecord, labelTypeArr
  } = props

  const formRef = useRef(null)
  const [formData, setFormData] = useState({
    is_display: 0
  })
  const [labelList, setLabelList] = useState([])

  useEffect(() => {
    if (_.isEmpty(record)) return
    setFormData(record)
  }, [record])

  useEffect(() => {
    commonApi(elementLabels).then(data => {
      const items = _.isArray(_.get(data, 'items')) ? _.get(data, 'items') : []
      console.log("==========label", items)
      if (items.length) setLabelList(items.map(i => ({
        ...i,
        text: `${i.label_name}(${i.label_field})`
      })))
    }).catch(err => Message.error(err))
  }, [])

  const topicArr = useMemo(() => {
    return _.isArray(_.get(getTopicRecord, 'topic')) ? _.get(getTopicRecord, 'topic').map(i => ({
      ...i,
      text: i.topic_name
    })) : []
  }, [getTopicRecord])

  const businessArr = useMemo(() => {
    return _.isArray(_.get(getTopicRecord, 'business')) ? _.get(getTopicRecord, 'business').filter(j => j.topic_id == formData.topic).map(i => ({
      ...i,
      text: i.business_name
    })) : []
  }, [getTopicRecord, formData.topic])

  const standardArr = useMemo(() => {
    return _.isArray(_.get(getTopicRecord, 'standard')) ? _.get(getTopicRecord, 'standard').map(i => ({
      ...i,
      text: `${i.field_name_ch}(${i.field_name})`
    })) : []
  }, [getTopicRecord])

  function updateFormData(key, val) {
    let cloneFormDate = _.cloneDeep(formData)
    cloneFormDate[key] = val
    setFormData(cloneFormDate)
  }

  function onSubmit() {
    formRef.current.validate(valid => {
      // console.log('*******valid, isEdit, formData: ', valid, isEdit, formData);
      const api = isEdit ? updateStandardManage : insertStandardManage
      if (valid) {
        api({
          field: formData,
          ...isEdit ? {
            id: record.id
          } : {}
        }).then(res => {
          const code = _.get(res, 'data.code')
          if (code === 0) {
            let msg = isEdit ? '编辑管理标签成功' : '添加管理标签成功'
            Message.success(msg)
            setShowEditWin(false)
            updatePage()
          } else {
            let tip = isEdit ? '编辑管理标签失败' : '添加管理标签失败'
            const msg = _.get(res, 'data.msg') || tip
            Message.error(msg)
          }
        }).catch(() => Message.error("接口异常"))
      }
    })
  }

  function handleLabelNameChange(val, item) {
     console.log('-----item: ', item);
    let cloneFormDate = _.cloneDeep(formData)
    //cloneFormDate['label_name'] = `${item.label_name}(${item.label_field})`
    cloneFormDate['label_name'] = item.label_name
    cloneFormDate['task_label_id'] = item.id
    cloneFormDate['label_field'] = item.label_field
    setFormData(cloneFormDate)
  }

  return (
    <Drawer
      title={`${isEdit ? '编辑' : '新增'}管理标签`}
      visible={showEditWin}
      onIconClick={() => setShowEditWin(false)}
      onCancelClick={() => setShowEditWin(false)}
      onSureClick={onSubmit}
    >
      <Drawer.Body>
        <Form labelWidth={'100px'} labelPosition={'left'} ref={formRef} model={formData} rules={rules}>
          <Form.FormItem label="标签名称" prop="label_name" required>
            <Select
              placeholder="请选择标签名称"
              width='100%'
              readOnly={false}
              value={Number(formData.task_label_id)}
              data={labelList}
              onChange={(val, item) => handleLabelNameChange(val, item)}
            />
          </Form.FormItem>
          <Form.FormItem label="标签ID" prop="task_label_id" required>
            <Input
              disabled
              placeholder="请选择标签名称"
              width='100%'
              value={formData.task_label_id}
            />
          </Form.FormItem>
          <Form.FormItem label="数据标准" prop="standard_id" required>
            <Select
              placeholder="请选择数据标准"
              width='100%'
              readOnly={false}
              value={formData.standard_id}
              data={standardArr}
              onChange={val => updateFormData('standard_id', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="标签类型" prop="label_type" required>
            <Select
              placeholder="请选择标签类型"
              width='100%'
              readOnly={false}
              value={formData.label_type}
              data={labelTypeArr}
              onChange={val => updateFormData('label_type', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="所属主题域" prop="topic" required>
            <Select
              placeholder="请选择所属主题域"
              width='100%'
              readOnly={false}
              value={formData.topic}
              data={topicArr}
              onChange={val => updateFormData('topic', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="所属业务" prop="service" required>
            <Select
              placeholder={!formData.topic ? '请先选择所属主题域' : '请选择所属业务'}
              width='100%'
              readOnly={false}
              disabled={!formData.topic}
              value={Number(formData.service)}
              data={businessArr}
              onChange={val => updateFormData('service', val)}
            />
          </Form.FormItem>
          <Form.FormItem label="坐席展示">
            <Switch
              value={Number(formData.is_display)}
              changeValue={val => updateFormData('is_display', val)}
            />
          </Form.FormItem>
        </Form>
      </Drawer.Body>
    </Drawer>
  )
}
