import React, { useRef, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSwitch from '@common/Switch'
import Form from '@common/Form_D'
import SdwMessage from '@common/Message'
import InputTip from '@common/InputTip'
import { getFormById, getItemsSelect, saveFormConf } from '@services/operationConfiguration'
import SdwSelect from '@common/Select'

function notEmpty(val) {
  return !!val
}

const rules = {
  form_id: [
    { func: notEmpty, message: '请填写表单ID' }
  ],
  form_name: [
    { func: notEmpty, message: '请填写表单名称' }
  ]
}

export default function EditWin(props) {
  const formRef = useRef(null)
  const { isEdit, data, showEditWin, setShowEditWin, updatePage } = props

  const [formData, setFormData] = useState({})
  const [searchData, setSearchData] = useState({})
  const [timer, setTimer] = useState(null)
  const [formConfigOption, setFormConfigOption] = useState([])
  const [formTypeData, setFormTypeData] = useState([])

  useEffect(() => {
    let record = isEdit ? _.cloneDeep(data) : {}
    setFormData(record)
    if (isEdit) {
      if (!!data.form_id) onFormConfigChange(data.form_id)
    }
  }, [data, isEdit])
  //获取表单类型
  useEffect(() => {
    getItemsSelect({}).then(res => {
      let code = _.get(res, 'data.code')
      if (code === 0) {
        let ret = _.get(res, 'data.data')
        let data = ret.form_business
        data = _.isArray(data) ? data : []
        formatSelectOptions(data)
      }
    })
  }, [])
  function formatSelectOptions(data) {
    data.unshift({
      value:"",
      name:"无业务类型"
    })
    const list = _.isArray(data) ? data.map(i => {
      return {
        id: i.value.toString(),
        text: i.name
      }
    }) : []
    setFormTypeData(list)
  }
  function setFormDataProps(key, val) {
    let cloneFormData = _.cloneDeep(formData)
    cloneFormData[key] = val
    setFormData(cloneFormData)
  }

  function handleSubmit()  {
    let api = saveFormConf
    let param = {field:formData}
    if (isEdit) {
      param.id = data.id
    }
    formRef.current.validate(valid => {
      if (valid) {
        // console.log('*******isEdit: ', isEdit, formData);
        api(param).then(res => {
          const code = _.get(res, 'data.code')
          if (code === 0) {
            let msg = isEdit ? '编辑配置成功' : '添加配置成功'
            SdwMessage.success(msg)
            setShowEditWin(false)
            updatePage()
          } else {
            let msg = isEdit ? '编辑配置失败' : '添加配置失败'
            SdwMessage.error(msg)
          }
        }).catch(() => SdwMessage.error("接口异常"))
      }
    })
  }
  //表单ID修改
  function onFormConfigChange(val) {
    setSearchData(r => _.assign({}, r, {'source_id': val}))
    // 查询候选项
    if (timer) clearTimeout(timer)
    let t = setTimeout(() => {
      getFormById({form_id: val}).then(res => {
        const code = _.get(res, 'data.code')
        if (code === 0) {
          const list = _.get(res, 'data.data')
          let data = []
          if (list) {
            data = list.items
          }
          if (_.isArray(data)) setFormConfigOption(data)
        } else {
          const msg = _.get(res, 'data.msg') || '请求数据失败！'
          SdwMessage.error(msg)
        }
      }).catch(() => SdwMessage.error('请求异常！'))

      clearTimeout(timer)
    }, 500)
    setTimer(t)
  }

  function onFormConfigClick(item) {
    const { id, text } = item
    setSearchData(r => _.assign({}, r, {'source_id': id, "name": text}))
    setFormData(r => _.assign({}, r, {
      form_id: id,
      form_name: text,
    }))
  }


  const arOptions = useMemo(() => {
    return formConfigOption.map(i => {
      if (isEdit && i.source_id == searchData.source_id) {
        let item = {
          id: i.source_id,
          text: i.name
        }
        onFormConfigClick(item)
      }
      return {
        id: i.source_id,
        text: i.name
      }
    })
  }, [formConfigOption])
  return (
    <>
      <SdwDrawer
        title={ isEdit ? '编辑配置' : '添加配置' }
        visible={showEditWin}
        onIconClick={() => setShowEditWin(false)}
        onCancelClick={() => setShowEditWin(false)}
        onSureClick={handleSubmit}
      >
        <SdwDrawer.Body>
          <Form labelWidth={'100px'} labelPosition={'left'} ref={formRef} model={formData} rules={rules}>
            <Form.FormItem label="表单ID" prop="form_id" required>
              <InputTip
                placeholder="请输入表单ID"
                width='100%'
                disabled={isEdit?true:false}
                readOnly={false}
                value={searchData.source_id}
                onChange={val => onFormConfigChange(val)}
                onClick={item => onFormConfigClick(item)}
                onClear={() => setFormDataProps('source_id', '')}
                options={arOptions}
              />
            </Form.FormItem>
            <Form.FormItem label="表单名称" prop="ai_code" required>
              <InputTip
                placeholder={"根据输入的表单id自动匹配名称展示，不支持点击"}
                width='100%'
                readOnly={false}
                disabled={true}
                value={searchData.name}
              />
            </Form.FormItem>
            <Form.FormItem label="表单类型" prop="service_name">
              <SdwSelect
                placeholder="请选择"
                value={formData.form_type}
                width={'95%'}
                listWidth={'100%'}
                readOnly={false}
                data={formTypeData}
                onChange={(val,item) => {
                  let cloneFormData = _.cloneDeep(formData)
                  cloneFormData["form_type"] = val
                  setFormData(cloneFormData)
                }}
              />
            </Form.FormItem>
            {/*<Form.FormItem label="是否可用">*/}
            {/*  <SdwSwitch*/}
            {/*    value={formData.enable}*/}
            {/*    changeValue={val => setFormDataProps('enable', val)}*/}
            {/*  />*/}
            {/*</Form.FormItem>*/}
          </Form>
        </SdwDrawer.Body>
      </SdwDrawer>
    </>
  )
}
