import { request } from '../utils/request'

export function getPushLogList (params) {
  return request({ url: '/monitor/api/getPushLogList', method: 'get', params: params })
}

export function getMonitorSettingList (params) {
  return request({ url: '/monitor/api/getMonitorSettingList', method: 'get', params: params })
}

export function getMonitorCfg (params) {
  return request({ url: '/monitor/api/getMonitorCfg', method: 'get', params: params })
}

export function getMonitorSubscribe (params) {
  return request({ url: '/monitor/api/getMonitorSubscribe', method: 'get', params: params })
}

export function setMonitorSetting (params) {
  return request({ url: '/monitor/api/setMonitorSetting', method: 'post', data: params })
}

export function setPushLog (params) {
  return request({ url: '/monitor/api/setPushLog', method: 'post', data: params })
}