import _ from 'lodash'
import DataManagement from '@pages/Knowledge/DataManagement'
import DataGovern from '@pages/Knowledge/DataGovern'
import MidTable from '@pages/Knowledge/MidTable'
import EditMidTable from '@pages/Knowledge/MidTable/EditMidTable'
import DataAccess from '@pages/Knowledge/DataManagement/DataAccess'
import LogManagement from '@pages/Knowledge/LogManagement'
import LogAccess from '@pages/Knowledge/LogAccess'
import LogAccessDetail from '@pages/Knowledge/LogAccess/ResolveDistributionConfiguration/LogAccessDetail'
import SetupTwo from '@pages/Knowledge/LogAccess/ResolveDistributionConfiguration/LogAccessDetail/setupTwo'
import switchRoutes from './switchRoutes'


export const routerPath = '/admin/dataCollect'

export const bar = {
  rootUrl: routerPath,
  activeClassName: 'current',
  className: 'sdw-nav-item',
  name: '数据采集地',
  children: [{
    icon: '',
    title: '日志监听管理',
    path: `${routerPath}/logManagement`,
  }, {
    icon: '',
    title: '日志接入',
    path: `${routerPath}/logAccess`,
  }, {
    icon: '',
    title: '数据接入',
    path: `${routerPath}/dataManagement`,
  }, {
    icon: '',
    title: '手工上传',
    path: `${routerPath}/dataGovern`,
  }, {
    icon: '',
    title: '中间表管理',
    path: `${routerPath}/midTable`,
  }],
  order: 1
}

const pages = {
  logManagement: {
    icon: '',
    title: '日志监听管理',
    path: `${routerPath}/logManagement`,
    component: LogManagement,
  },
  logAccess: {
    icon: '',
    title: '日志接入',
    path: `${routerPath}/logAccess`,
    component: LogAccess,
  },
  logAccessDetail: {
    icon: '',
    title: '编辑或新增日志接入第一步',
    path: `${routerPath}/logAccessDetail`,
    component: LogAccessDetail,
  },
  setupTwo: {
    icon: '',
    title: '编辑或新增日志接入第二步',
    path: `${routerPath}/setupTwo`,
    component: SetupTwo,
  },
  dataManagement: {
    icon: '',
    title: '数据接入',
    path: `${routerPath}/dataManagement`,
    component: DataManagement,
  },
  dataAccess: {
    icon: '',
    title: '数据接入',
    path: `${routerPath}/dataAccess`,
    component: DataAccess,
  },
  DataGovern: {
    icon: '',
    title: '手工上传',
    path: `${routerPath}/dataGovern`,
    component: DataGovern,
  },
  midTable: {
    icon: '',
    title: '中间表管理',
    path: `${routerPath}/midTable`,
    component: MidTable,
  },
  editMidTable: {
    icon: '',
    title: '中间表管理编辑',
    path: `${routerPath}/editMidTable/:id`,
    component: EditMidTable,
  }
}

export const menus = [pages['home'], pages['voice'], pages['history'], pages['customer'], pages['worker']]

export const Router = switchRoutes({ routes: _.values(pages) })
