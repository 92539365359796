import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import './index.css'

class SdwInput extends Component {
  inputRef = React.createRef()

  static defaultProps = {
    width: '80%',
    height: 40,
    rows: 3,
    cols: 28,
    placeholder: '请输入',
    clearable: true,
    value: '',
    label: '',
    errTip: '',
    type: 'text',
    disabled: false,
    sholdCheckValidate: false,
    multipleValue: [],
    multipleOption: [],
    onChange: _.noop,
    onBlur: _.noop,
    onFocus: _.noop,
    onClear: _.noop,
    onEnterKeyDown: _.noop,
  }

  state = {
    isValidatePass: true,
    validateText: '',
    showClearIcon: false,
    isOnFocus: false,
    hideSearchField: true,
    searchValue: ''
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      prevProps.sholdCheckValidate !== this.props.sholdCheckValidate ||
      prevProps.multipleValue !== this.props.multipleValue
    ) {
      this.validate(this.props.value)
    }

    if (this.props.value !== this.state.inputValue) {
      this.setState({
        inputValue: this.props.value
      })
    }
  }

  handleChange = e => {
    if (this.props.disabled) return
    let value = e.target.value

    this.props.onChange(value)

    if (!this.state.isValidatePass) {
      this.validate(value)
    }

    if (!value.length) {
      this.validate('')
    }
  }

  handleBlur = e => {
    this.validate(e.target.value)
    this.props.onBlur(e.target.value)
  }

  handleClearInput = e => {
    e.preventDefault()

    if (!this.props.isMultipleChoice) {
      this.props.onChange('')
    }

    this.props.onClear()
    this.validate('')
    this.inputRef.current.focus()
  }

  handleKeyDown = record => {
    if (record.keyCode === 13) {
      this.props.onEnterKeyDown()
      this.inputRef.current.blur()
    }
  }

  validate = value => {

    if (this.props.isMultipleChoice) {
      value = this.props.multipleValue
    }

    // 如果没有传入valiateFun进行校验，直接跳过
    if (typeof this.props.validateFun !== 'function' || !this.props.sholdCheckValidate) {
      this.setState({
        isValidatePass: true,
        validateText: ''
      })
      return
    }

    let res = this.props.validateFun(value)

    // validateFun只有返回true，才会校验通过
    if (res === true) {
      this.setState({
        isValidatePass: res,
        validateText: ''
      })
    } else {
      this.setState({
        isValidatePass: false,
        validateText: res
      })
    }
  }

  onInputSearchValChange(e) {
    let curVal = e.target.value

    // 当多选时，输入 yyyyan;darrenlai;xxx 格式，会先自动以 ； 号进行分割，然后逐一查看备选项中是否有匹配项，有就自动填充，没有就过滤掉
    if (this.props.isMultipleChoice && typeof curVal === 'string' && curVal.length) {
      let index = curVal.indexOf(';')

      if (index !== -1) {
        let { multipleValue, multipleOption, changeMultipleValue } = this.props
        let arr = curVal.split(';')

        if (arr.length) arr = arr.map(i => _.trim(i))

        let hasPerson = arr.filter(i => multipleOption.some(j => j.value === i) && !multipleValue.includes(i))

        if (hasPerson.length) {
          hasPerson = hasPerson.concat(_.cloneDeep(multipleValue)).filter(i => !!i)
          changeMultipleValue(hasPerson)
          curVal = ''
        }

      }
    }

    this.setState({
      searchValue: curVal,
      hideSearchField: !curVal
    })
  }

  onChangeMultipleValue = (obj, type) => {
    let { multipleValue } = this.props
    let list = [...multipleValue].filter(i => !!i)

    this.setState({
      searchValue: ''
    })

    if (
      !type || !obj ||
      typeof this.props.changeMultipleValue !== 'function'
    ) return

    if (type === 'add' && multipleValue.indexOf(obj.value) === -1) {
      list.push(obj.value)
    }

    if (type === 'del' && multipleValue.indexOf(obj.value) !== -1) {
      let index = list.findIndex(i => i === obj.value)
      list.splice(index, 1)
    }

    this.props.changeMultipleValue(list)
  }

  render() {
    let {
      value,
      type,
      placeholder,
      disabled,
      width,
      height,
      rows,
      cols,
      label,
      clearable,
      isMultipleChoice,
      multipleValue,
      multipleOption,
      errTip,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius
    } = this.props

    let { isValidatePass, validateText, showClearIcon, isOnFocus, hideSearchField, searchValue } = this.state

    let textareaClassName = isValidatePass ? 'sdw-textarea-input__wrap' : 'sdw-textarea-input__wrap sdw-error-input'
    let inputClassName = (isValidatePass && !errTip) ? 'sdw-input__wrap' : 'sdw-input__wrap sdw-error-input'

    let filterSearchFieldsArr = []
    let selectedFields = []
    if (isMultipleChoice) {
      filterSearchFieldsArr = multipleOption.filter(i => i.name.indexOf(searchValue) !== -1 && !multipleValue.includes(i.value))
      selectedFields = multipleOption.filter(i => multipleValue.some(j => j === i.value))
    }

    return (
      <div
        className={ `sdw-input__outer-div-wrap ${isOnFocus ? 'sdw-input__on-focus' : (
          isValidatePass ? '' : 'sdw-error-input__wrap'
        )}` }
        style={{ width }}
      >
        {
          !!label && <span className="operation-label-title">{label}</span>
        }
        <div
          onMouseEnter={() => this.setState({ showClearIcon: true })}
          onMouseLeave={() => this.setState({ showClearIcon: false })}
          className="sdw-input__div-wrap"
          style={{ width: '100%' }}
        >
          {
            type === 'textarea' ?
            <textarea
              ref={this.inputRef}
              rows={rows}
              cols={cols}
              value={value}
              style={{
                width: '100%'
              }}
              disabled={disabled}
              placeholder={placeholder}
              className={textareaClassName}
              onChange={e => this.handleChange(e)}
              onFocus={() => this.setState({ isOnFocus: true })}
              onBlur={e => {
                this.handleBlur(e)
                this.setState({ isOnFocus: false })
              }} />
            :
            (
              isMultipleChoice ?
              <span className={isValidatePass ? 'sdw-multiple-input__wrap' : 'sdw-multiple-input__wrap-error'}>
                {
                  selectedFields.map(item => (
                    <span key={item.value} className='sdw-input-tags tag'>
                      {item.name}
                      <i
                        className='sdw-input-tags__clear-icon'
                        onClick={() => this.onChangeMultipleValue(item, 'del')}
                      />
                    </span>
                  ))
                }
                <input
                  ref={this.inputRef}
                  value={searchValue}
                  type={type}
                  disabled={disabled}
                  placeholder={placeholder}
                  style={{
                    width: 200,
                    height,
                    marginLeft: 16
                  }}
                  className='sdw-input-tags search-input'
                  onKeyDown={v => this.handleKeyDown(v)}
                  onChange={e => this.onInputSearchValChange(e)}
                  onFocus={() => {
                    this.setState({ isOnFocus: true })
                    this.props.onFocus()
                  }}
                  onBlur={e => {
                    this.handleBlur(e)
                    this.setState({ isOnFocus: false })
                  }}
                />
                {
                  Array.isArray(filterSearchFieldsArr) &&
                  !!filterSearchFieldsArr.length &&
                  !hideSearchField &&
                  <div className="sdw-input__multiple-choice-div-wrap">
                    {
                      filterSearchFieldsArr.map((item, index) => {

                        return (
                          (item.disabled) ? <div title={item.name} key={index} className="ellipsis sdw-input__multiple-disabled-item">{item.name}</div>:
                          <div
                            key={index}
                            title={item.name}
                            className="ellipsis sdw-input__multiple-choice-item"
                            onClick={() => {
                              this.onChangeMultipleValue(item, 'add')
                              this.setState({
                                hideSearchField: true
                              })
                            }}>{item.name}</div>
                        )
                      })
                    }
                  </div>
                }
              </span> :
              <input
                ref={this.inputRef}
                value={value}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                style={{
                  width: '100%',
                  height,
                  borderTopLeftRadius: borderTopLeftRadius,
                  borderTopRightRadius: borderTopRightRadius,
                  borderBottomLeftRadius: borderBottomLeftRadius,
                  borderBottomRightRadius:borderBottomRightRadius
                }}
                className={inputClassName}
                onKeyDown={v => this.handleKeyDown(v)}
                onChange={e => this.handleChange(e)}
                onFocus={() => {
                  this.setState({ isOnFocus: true })
                  this.props.onFocus()
                }}
                onBlur={e => {
                  this.handleBlur(e)
                  this.setState({ isOnFocus: false })
                }}
              />
            )
          }
          {
            !disabled && clearable && showClearIcon &&
            (!isMultipleChoice ? !!value : !!multipleValue.length) &&
            <i className='sdw-input-clearable' onClick={this.handleClearInput}></i>
          }
          {
            !!errTip && <div className='sdw-error-input__tip'>{errTip}</div>
          }
          {
            // 有限使用 errTip
            (!errTip && !isValidatePass) && <div className='sdw-error-input__tip'>{validateText}</div>
          }
        </div>
      </div>
    )
  }
}

SdwInput.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  onChange: PropTypes.func,
  validateFun: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onEnterKeyDown: PropTypes.func, // 按下enter键触发的事件
  sholdCheckValidate: PropTypes.bool, // true: 手动触发必填项的检查
  isMultipleChoice: PropTypes.bool, // true: 支持'多选'
  multipleValue: PropTypes.array, // 多选绑定的数组value
  multipleOption: PropTypes.array, // 多选绑定的数组候选项
  changeMultipleValue: PropTypes.func, // 改变multipleValue函数
  errTip: PropTypes.string, // 仅仅展示错误信息
}

export default SdwInput
