import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Layout, Tabs } from 'element-react'
import { getFieldById, getFieldUpdateHistory, getItermSelects, getFieldRelativeTableInfo } from '@services/dataStandars'
import { TABLE_EXTRA_MAP_TEXT } from '../common'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwPopover from '@common/Popover'
import EditDataStandardWin from '../components/EditDataStandardWin'
import SdwEmpty from '@common/Empty'

import editImg from '../../../img/icon_16_bj@2x.png'

import 'element-theme-default'
import './index.css'

const LABEL_MAP = { 
  field_name: '字段名称', 
  field_name_ch: '字段中文名', 
  field_type: '字段类型', 
  field_define: '字段定义', 
  unit: '单位', 
  topic_name: '所属主题域', 
  business_name: '所属业务', 
  label: '标签', 
  field_format: '字段格式', 
  field_range: '值域', 
  owner: '负责人', 
  enable: '标准是否有效' 
}

class DataStandardDetail extends PureComponent {

  state = {
    baseInfo: {},
    curID: '',
    historyData: [],
    visible: false,
    standardOption: [],
    topicOption: [],
    fieldRelativeTableInfo: {},
    fieldUpdateHistoryDate: {},
    curHistoryPage: 1,
    curHistoryLimit: 10,
    curRelativePage: 1,
    curRelativeLimit: 10,
  }

  getcolumns = () => {
    let self = this
    return [
      {
        title: "数据库",
        dataIndex: "database_name"
      },
      {
        title: "表英文名",
        dataIndex: "table_name",
      },
      {
        title: "表中文名",
        dataIndex: "data_set_chinese",
      },
      {
        title: "数据层级",
        dataIndex: "warehouse_level",
      },
      {
        title: "关联字段数",
        // dataIndex: "field_num",
        render: (data, record) => {
          return (
            <SdwPopover tip={self.handleMouseEnter(data)}>
              <span 
                style={{ cursor: 'pointer', display: 'inline-block', width: '10px', height: '100%'}}
              >
                {data.field_num}
              </span>
            </SdwPopover>
          )
        },
      },
      { 
        title: "操作",
        width: 80,
        render: (data, record) => {
          return (
            <div style={{'color': '#517df3', 'cursor': 'pointer'}} onClick={ () => { self.onClickToTableInfo(data, self) } }>详情</div>
          )
        },
      }
    ]
  }

  handleMouseEnter = (data) => {
    let str = data && data.fields != null && data.fields.map(i => `${i.field_name}（${i.field_name_ch || '-'}）`).join('，')
    return str
  }

  // 跳转到表信息详情页
  onClickToTableInfo = (data, e) => {
    let tableId = data.id
    if(tableId && tableId > 0) {
      let url = "/html/metadata/tableDetail/" + tableId
      window.open(url)
    }
  }
  
  getLogColumns = () => {
    return [
      {
        title: "修改时间",
        dataIndex: "modify_time"
      },
      {
        title: "修改人",
        dataIndex: "modify_user",
      },
      {
        title: "修改字段",
        dataIndex: "label",
      },
      {
        title: "旧值",
        dataIndex: "old",
      },
      {
        title: "新值",
        dataIndex: "new",
      }
    ]
  }

  componentDidMount() {

    let id = this.props.location.state && this.props.location.state.id || ''
    if (id) {
      this.initBaseData(id)
      this.getHistory(id)
      this.getFieldTableInfo(id)
      this.setState({
        curID: id
      })
    }

    this.onGetItermSelects()
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      !_.isEqual(prevState.curHistoryPage, this.state.curHistoryPage) ||
      !_.isEqual(prevState.curHistoryLimit, this.state.curHistoryLimit)
    ) {
      this.getHistory(this.state.curID)
    }

    if (
      !_.isEqual(prevState.curRelativePage, this.state.curRelativePage) ||
      !_.isEqual(prevState.curRelativeLimit, this.state.curRelativeLimit)
    ) {
      this.getFieldTableInfo(this.state.curID)
    }
  }

  handleRefresh = () => {
    this.setState({
      curHistoryPage: 1,
      curRelativePage: 1
    }, () => {
      this.initBaseData(this.state.curID)
      this.getHistory(this.state.curID)
      this.getFieldTableInfo(this.state.curID)
    })
  }

  getFieldTableInfo = id => {
    let { curRelativePage, curRelativeLimit} = this.state
    getFieldRelativeTableInfo({
      id,
      page: curRelativePage,
      limit: curRelativeLimit,
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          this.setState({
            fieldRelativeTableInfo: res.data.data || {} // 暂时是数组形式，后面看接口是否去掉最外层的[]
          })
        }
      })
  }

  onGetItermSelects = () => {
    getItermSelects()
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let dataObj = res.data.data || {}
          if (Array.isArray(dataObj.standard_type) && dataObj.standard_type.length) {
            this.setState({
              standardOption: dataObj.standard_type
            })
          }
          if (Array.isArray(dataObj.topic) && dataObj.topic.length) {
            this.setState({
              topicOption: dataObj.topic
            })
          }
        }
      })
  }

  initBaseData = id => {
    getFieldById({ id }).then(res => {
      if (res && res.data && res.data.code === 0) {
        this.setState({
          baseInfo: res.data.data || {}
        })
      }
    })
  }

  getHistory = (id) => {
    if (!id) return

    let { curHistoryPage, curHistoryLimit } = this.state
    getFieldUpdateHistory({
      id,
      page: curHistoryPage,
      limit: curHistoryLimit
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let record = res.data.data || {}

          this.setState({
            // 这里主要是取分页数据
            fieldUpdateHistoryDate: record
          })

          if (_.isArray(record.items) && record.items.length > 0) {
            let list = []
            for (let item of record.items) {
              for (let editItem in item) {
                if (editItem !== 'modify_time' && editItem !== 'modify_user' && editItem !== 'create_user') {
                  let modify_user = ''
                  if (typeof item.modify_user === 'object' && item.modify_user !== null) {
                    modify_user = item.modify_user.new
                  } else {
                    modify_user = item.modify_user || ''
                  }
                  let label_cn = ''
                  label_cn = LABEL_MAP[editItem]
                  list.push({
                    modify_time: item.modify_time.new || '-',
                    modify_user: modify_user || '-',
                    new: item[editItem].new || '-',
                    old: item[editItem].old || '-',
                    label: label_cn || '-'
                  })
                }
              }
            }
            this.setState({
              historyData: list
            })
          }
        }
      })
  }

  render() {

    let { 
      baseInfo, 
      historyData,
      visible,
      standardOption,
      topicOption,
      fieldRelativeTableInfo,
      fieldUpdateHistoryDate,
    } = this.state

    return (
      <>
        <SdwBreadCrumbs 
          history={this.props.history}
          data={[
            {
              title: '数据集市',
              path: '/admin/dataMarket/dictionary'
            }, {
              title: '数据标准',
              path: '/admin/dataMarket/standard'
            }, {
              title: '标准详情'
            }
          ]}
        />

      <div className="data-standard-detail__wrap">
        <div className="base-info">
          <div className="base-info__title">
            <span>基本信息</span>
            <img 
              className="base-info__img" 
              src={editImg}
              onClick={() => this.setState({ visible: true })}
            ></img>
          </div>
          <div className="base-info__content">
            <Layout.Row gutter={20}>
              <Layout.Col span="12"><div className="grid-content bg-purple">
                <span className="title">英文名</span><span>{baseInfo.field_name || '-'}</span>
              </div></Layout.Col>
              <Layout.Col span="12"><div className="grid-content bg-purple">
                <span className="title">中文名</span><span>{baseInfo.field_name_ch || '-'}</span>
              </div></Layout.Col>
            </Layout.Row>
            <Layout.Row gutter={20}>
              <Layout.Col span="24"><div className="grid-content bg-purple">
                <span className="title">定义</span><span>{baseInfo.field_define || '-'}</span>
              </div></Layout.Col>
            </Layout.Row>
            <Layout.Row gutter={20}>
              <Layout.Col span="12"><div className="grid-content bg-purple">
                <span className="title">标签</span><span>{baseInfo.label || '-'}</span>
              </div></Layout.Col>
              <Layout.Col span="12"><div className="grid-content bg-purple">
                <span className="title">类型</span><span>{TABLE_EXTRA_MAP_TEXT[baseInfo.standard_type] || '-'}</span>
              </div></Layout.Col>
            </Layout.Row>
            <Layout.Row gutter={20}>
              <Layout.Col span="12"><div className="grid-content bg-purple">
                <span className="title">主题</span><span>{baseInfo.topic_name || '-'}</span>
              </div></Layout.Col>
              <Layout.Col span="12"><div className="grid-content bg-purple">
                <span className="title">业务</span><span>{baseInfo.business_name || '-'}</span>
              </div></Layout.Col>
            </Layout.Row>
            <Layout.Row gutter={20}>
              <Layout.Col span="12"><div className="grid-content bg-purple">
                <span className="title">数据类型</span><span>{baseInfo.field_type || '-'}</span>
              </div></Layout.Col>
              <Layout.Col span="12"><div className="grid-content bg-purple">
                <span className="title">格式</span><span>{baseInfo.field_format || '-'}</span>
              </div></Layout.Col>
            </Layout.Row>
            <Layout.Row gutter={20}>
              <Layout.Col span="24"><div className="grid-content bg-purple">
                <span className="title">负责人</span><span>{baseInfo.owner || '-'}</span>
              </div></Layout.Col>
            </Layout.Row>
          </div>
        </div>

        <div className="connect-table">
          <Tabs activeName="1" onTabClick={(tab) => {}}>
            <Tabs.Pane label="关联表" name="1">
              <div style={{ padding: '10px' }}>
                <SdwTable
                  tbodyHeight='320px'
                  columns={this.getcolumns()}
                  dataSource={fieldRelativeTableInfo.items || []}
                />
              </div>
              <SdwPagenation 
                pageCountList={[10, 20, 50]}
                total={fieldRelativeTableInfo.total}
                pageCount={ fieldRelativeTableInfo.per_page }
                currentPage={fieldRelativeTableInfo.current_page}
                getClickPage={page => this.setState({ curRelativePage: page })}
                pageCountChange={limit => this.setState({ curRelativeLimit: limit })}
              />
            </Tabs.Pane>
            <Tabs.Pane label="关联指标" name="2">
              <SdwEmpty
                height={390}
                icon="plan"
                text="规划中，敬请期待"
              />
            </Tabs.Pane>
          </Tabs>
        </div>

        <div className="standard-log">
          <div className="base-info__title">
            <span>修改日志</span>
          </div>
          <div style={{ padding: '10px' }}>
            <SdwTable
              tbodyHeight='320px'
              columns={this.getLogColumns()}
              dataSource={historyData}
            />
          </div>
          <SdwPagenation 
            pageCountList={[10, 20, 50]}
            showTotal={false}
            showGotoPage={false}
            showPageCountSelect={false}
            total={fieldUpdateHistoryDate.total}
            pageCount={ fieldUpdateHistoryDate.per_page }
            currentPage={fieldUpdateHistoryDate.current_page}
            getClickPage={page => this.setState({ curHistoryPage: page })}
            pageCountChange={limit => this.setState({ curHistoryLimit: limit })}
          />
        </div>
        <EditDataStandardWin
          title='修改数据标准'
          visible={visible}
          record={baseInfo}
          standardOption={standardOption}
          topicOption={topicOption}
          handleRefresh={this.handleRefresh}
          onIconClick={() => this.setState({ visible: false })}
          onCancelClick={() => this.setState({ visible: false })}
          onSureClick={() => {
            this.setState({ visible: false })
          }}
        />
      </div>
    </>
    )
  }
}

export default DataStandardDetail
