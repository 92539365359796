import React from 'react'
import _ from 'lodash'
import SdwInput from '@common/Input'
import SdwButton from '@common/Button'
import SdwMessage from '@common/Message'
import SdwEnable from '@common/Enable'
import SdwTable from '@common/Table'
import SdwPagination from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import DistributeWin from './StepWin/DistributeWin'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import StatusWin from '@pages/Knowledge/LogAccess/CollectionServiceManagement/StepWin/StatusWin'
import '../index.css'
import {requestOptionsLOG} from '@config/config'

const baseURL = requestOptionsLOG.basename

class ResolveDistributionConfiguration extends React.PureComponent {

    state = {
        currentItem: {},
        distributeVisible: false,
        statusVisible: false, // 链路检查弹窗
        search: {
            page: this.props.pageInfo || 1,
            size: 10,
            id: '',
            cmd: '',
            storeTableNameZh: '',
            storeTableName: '',
            createRtx: '',
            inputTag: '',
        },
        tableData: {},
        isEdit: false,
        socketList: {
            checkerList: [],
            sessionId: 0
        }
    }

    componentDidMount() {
        this.queryParseConfigList()
    }

    // 查询解析配置列表
    queryParseConfigList = () => {
        let {search} = this.state
        let rtx = this.props.userInfo.name
        let params = {}
        for (let key in search) {
            if (search[key]) {
                params[key] = search[key]
            }
        }
        fetch(baseURL + '/sdw/filebeat/parseConfig/queryParseConfigList',
            {
                method: "POST",
                mode: "cors",
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                let record = data.data || {}
                // console.log('tableData================================', record)
                this.setState({
                    tableData: record
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    getColumns = () => {
        let self = this
        return [
            {
                title: "checkTd",
                dataIndex: "id",
                checkTd: 1,
                width: 20,
            },
            {
                title: 'ID',
                dataIndex: 'id',
                checkChildren: true,
                width: 40
            },
            {
                title: 'CMD',
                dataIndex: 'cmd',
                width: 42
            },
            {
                title: '采集标识',
                dataIndex: 'inputTag',
                width: 200
            },
            {
                title: '表中文名',
                dataIndex: 'storeTableNameZh',
                width: 180
            },
            {
                title: '表英文名',
                dataIndex: 'storeTableName',
                width: 180
            },
            {
                title: '消费类型',
                render: data => {
                    let arr = {
                        'STORE': '入库',
                        'INDIVIDUAL': '自定义'
                    }
                    let msg = arr[data.deliveryType]
                    return <span>{msg ? msg : '-'}</span>
                },
                width: 56
            },
            {
                title: '状态',
                render: data => {
                    if (data.status) {
                        let arr = {
                            '700': '验证通过',
                            '701': '未验证',
                            '-704': '验证未通过',
                        }
                        let msg = arr[data.status]
                        return <span className={
                            (Boolean(data.status == '700') ? 'status-comp_is-effect' : 'status-comp_no-effect')
                        }>
                        {msg ? msg : '-'}
                    </span>
                    } else {
                        return <SdwEnable enable={data.enable}/>
                    }
                },
                width: 82
            },
            {
                title: '修改人',
                dataIndex: 'updateRtx',
                width: 96
            },
            {
                title: '修改时间',
                dataIndex: 'updateTime',
                width: 148
            },
            {
                title: '操作',
                width: 120,
                render: data => {
                    if (data.hasOwnProperty("parseId")) {
                        let dataClone = data
                        dataClone.id = dataClone.id.replace(/(^\s*)/g, "");
                        return <>
                            <SdwButton
                                text='编辑'
                                onClick={() => self.distributeAddOrEdit(true, dataClone)}
                            />
                        </>
                    } else {
                        return <>
                            <SdwButton
                                text='编辑'
                                onClick={() => self.logAccessAddOrEdit(true, data.id)}
                            />
                            <SdwButton
                                text='新增分发'
                                onClick={() => self.distributeAddOrEdit(false, data)}
                            />
                        </>
                    }
                }
            }
        ]
    }

    setSearch = (type, val) => {
        let {search} = this.state
        let obj = {}
        obj[type] = val
        if (type != 'page') {
            obj['page'] = 1
        }
        let searchClone = Object.assign({}, search, obj)
        this.setState({
            search: searchClone
        }, () => {
            this.queryParseConfigList()
        })
    }

    // 新增或编辑分发配置弹窗
    distributeAddOrEdit = (isEdit, record = {}) => {
        this.setState({
            deliveryIsEdit: isEdit,
            distributeVisible: true,
            currentItem: record,
        })
    }

    logAccessAddOrEdit = (isEdit, id = "") => {
        this.props.history.push({
            pathname: '/admin/dataCollect/logAccessDetail',
            state: {
                id: id,
                isEdit: isEdit
            }
        })
    }

    distributeWinSubmit = (data) => {
        this.queryParseConfigList()
        console.log("distributeWinSubmit====data", data)
        let code = data.code
        if (code == 101) {
            this.setState({
                statusVisible: true,
                socketList: data.data
            })
        }
    }

    render() {
        let {
            currentItem,
            distributeVisible,
            tableData,
            search,
            deliveryIsEdit,
            statusVisible,
            socketList
        } = this.state

        let tableDataClone = _.cloneDeep(tableData)
        tableDataClone.configList = tableDataClone.configList || []
        let list = tableDataClone.configList.map(i => {
            if (i.hasOwnProperty("deliveryConfigQueryDTOS") && !!i.deliveryConfigQueryDTOS) {
                i.deliveryConfigQueryDTOS = i.deliveryConfigQueryDTOS.map(j => {
                    j.id = " " + j.id
                    return j
                })
            }
            return i
        })

        return (
            <>
                <BreadCrumbsAndTablde>
                    <BreadCrumbsAndTablde.LeftCrumbs>
                        <SdwInput
                            label='ID'
                            width={260}
                            value={search.id}
                            onChange={val => this.setSearch('id', val)}
                        />
                        <SdwInput
                            label='CMD'
                            width={260}
                            value={search.cmd}
                            onChange={val => this.setSearch('cmd', val)}
                        />
                        <SdwInput
                            label='采集标识'
                            width={260}
                            value={search.inputTag}
                            onChange={val => this.setSearch('inputTag', val)}
                        />
                        <SdwInput
                            label='表中文名'
                            width={260}
                            value={search.storeTableNameZh}
                            onChange={val => this.setSearch('storeTableNameZh', val)}
                        />
                        <SdwInput
                            label='表英文名'
                            width={260}
                            value={search.storeTableName}
                            onChange={val => this.setSearch('storeTableName', val)}
                        />
                        <SdwInput
                            label='创建人'
                            width={260}
                            value={search.createRtx}
                            onChange={val => this.setSearch('createRtx', val)}
                        />
                    </BreadCrumbsAndTablde.LeftCrumbs>
                    <BreadCrumbsAndTablde.RightCrumbs>
                        <SdwButton
                            text='新增'
                            type='submit'
                            iconClass='iconfont icon-add'
                            onClick={() => this.logAccessAddOrEdit(false)}
                        />
                    </BreadCrumbsAndTablde.RightCrumbs>
                    <BreadCrumbsAndTablde.CenterWrap>
                        <SdwTable
                            tdWhiteSpace={true}
                            childrenString={"deliveryConfigQueryDTOS"}
                            columns={this.getColumns()}
                            dataSource={list || []}
                        />
                        <SdwPagination
                            total={tableData.total || 0}
                            currentPage={this.props.pageInfo || 1}
                            pageCount={tableData.per_page}
                            getClickPage={val => this.setSearch("page", val)}
                            pageCountChange={val => this.setSearch("size", val)}
                        />
                    </BreadCrumbsAndTablde.CenterWrap>
                </BreadCrumbsAndTablde>

                {/* 新增或编辑分发配置弹窗 */}
                {distributeVisible &&
                    <DistributeWin
                        isEdit={deliveryIsEdit}
                        currentItem={currentItem}
                        distributeVisible={distributeVisible}
                        changeVisible={(bool) => {
                            this.setState({distributeVisible: bool})
                        }}
                        distributeWinSubmit={this.distributeWinSubmit}
                    />
                }
                {statusVisible &&
                    <StatusWin
                        statusVisible={statusVisible}
                        socketList={socketList}
                        closeDialog={() => this.setState({statusVisible: false})}
                    />
                }
            </>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo,
    pageInfo: state.page.pageInfo
})

export default withRouter(connect(mapState)(ResolveDistributionConfiguration))
