import { useRef, useState, useEffect, useReducer } from 'react'
import _ from 'lodash'
import Drawer from "@common/Drawer"
import Form from "@common/Form_D"
import Select from '@common/Select'
import DateTimePicker from '@common/DateTimePicker'
import Message from '@common/Message'

function notEmpty(val) {
  return !!val
}

const rules = {
  standard: [
    { func: notEmpty, message: '请选择标签名称' }
  ],
  rule: [
    { func: notEmpty, message: '请选择标签规则' }
  ],
  range_time: [
    { func: notEmpty, message: '请选择时间范围' }
  ]
}

const initForm = {
  standard: '',
  rule: '',
  range_time: '',
}

export default function EditWin(props) {
  const { showEditWin, setShowEditWin, updatePage } = props
  
  const formRef = useRef(null)
  const [formData, setFormData] = useState(initForm)

  function updateFormData(key, val) {
    console.log('=-=-=-=-=-=-=-=-updateFormData: ', key, val);
    let cloneFormData = _.cloneDeep(formData)
    cloneFormData[key] = val
    setFormData(cloneFormData)
  }

  function onSubmit() {
    formRef.current.validate(valid => {
      console.log('*******valid, formData: ', valid, formData);
      // if (valid) {
      //   const params = {
      //     ...formData
      //   }
      //   api({
      //     data: params
      //   }).then(res => {
      //     const code = _.get(res, 'data.code')
      //     if (code === 0) {
      //       let msg = '添加配置成功'
      //       Message.success(msg)
      //       setShowEditWin(false)
      //       updatePage()
      //     } else {
      //       let tip = ='添加配置失败'
      //       const msg = _.get(res, 'data.msg') || tip
      //       Message.error(msg)
      //     }
      //   }).catch(() => Message.error("接口异常"))
      // }
    })
  }

  return (
    <Drawer
      title='新增离线标签加工任务'
      visible={showEditWin}
      onIconClick={() => setShowEditWin(false)}
      onCancelClick={() => setShowEditWin(false)}
      onSureClick={onSubmit}
    >
      <Drawer.Body>
        <Form labelWidth={'100px'} labelPosition={'left'} ref={formRef} model={formData} rules={rules}>
          <Form.FormItem label="标签名称" prop="standard" required>
            <Select
              placeholder="请选择标签名称"
              width='100%'
              readOnly={false}
              value={formData.standard}
              // data={topicArr}
              // onChange={(val, item) => updateFormData(r => _.assign({}, r, {
              //   standard: val,
              //   topic: item.text
              // }))}
            />
          </Form.FormItem>
          <Form.FormItem label="标签规则" prop="rule" required>
            <Select
              placeholder="请选择标签规则"
              width='100%'
              readOnly={false}
              value={formData.rule}
              // data={topicArr}
              // onChange={(val, item) => updateFormData(r => _.assign({}, r, {
              //   rule: val,
              //   topic: item.text
              // }))}
            />
          </Form.FormItem>
          <Form.FormItem label="时间范围" prop="range_time" required>
            <DateTimePicker
              showDateTimeRangePicker={true}
              placeholder='请选择时间范围'
              width='100%'
              value={formData.range_time}  // 绑定的value值：范围是数组：['2020-01-01', '2020-02-08']
              changeValue={val => updateFormData('range_time', val)}
            />
          </Form.FormItem>
        </Form>
      </Drawer.Body>
    </Drawer>
  )
}