import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import Select from '@common/Select'
import Input from '@common/Input'
import Button from '@common/Button'
import Table from '@common/Table'
import Pagenation from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import { getOrderRuleItems } from '@services/labelManagement'
import Message from '@common/Message/index'
import { commonApi } from '@utils/request'
import EditWin from './EditWin'
import StepWin from './StepWin'
import UseStandardItemSelects from '@customHooks/UseStandardItemSelects'
import { getSearchParams } from '@utils/submit'
import UseLabelName from '../common/UseLabelName'
import './index.css'

export default function TicketLabelRules() {
  const standardItemSelects = UseStandardItemSelects()

  const [curRecord, setCurRecord] = useState({})
  const [showEditWin, setShowEditWin] = useState(false)
  const [curEditItem, setCurEditItem] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [showSteps, setShowSteps] = useState(false)
  const [statusArr, setStatusArr] = useState([])
  const [ruleTypeArr, setRuleTypeArr] = useState([])
  const [pageConfig, setPageConfig] = useState({})
  const [searchParams, setSearchParams] = useState({})
  const [labelName, setLabelName] = useState('')
  const standardManageItems = UseLabelName(labelName, true)

  useEffect(() => {
    initData()
  }, [pageConfig, searchParams])

  useEffect(() => {
    const ruleConfigStatus = _.get(standardItemSelects, 'rule_config_status') || []
    const ruleType = _.get(standardItemSelects, 'rule_type') || []
    setStatusArr(ruleConfigStatus.map(i => ({
      id: String(i.value),
      text: i.name
    })))
    setRuleTypeArr(ruleType.map(i => ({
      id: String(i.value),
      text: i.name
    })))
  }, [standardItemSelects])

  function initData() {
    const params = _.assign({}, searchParams, {
      'a.create_user': searchParams.create_user,
      create_user: ''
    })
    commonApi(getOrderRuleItems, {
      ...pageConfig,
      search: getSearchParams(params, ['a.create_user'])
    }).then(data => {
      setCurRecord(data)
    }).catch(err => Message.error(err))
  }

  const getcolumns = useCallback(
    () => {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 20
        }, {
          title: '标签名称',
          dataIndex: 'label_name',
        }, {
          title: '规则名称',
          dataIndex: 'rule_name',
        }, {
          title: '规则类型',
          dataIndex: 'rule_type_ch',
        }, {
          title: '加工方式',
          dataIndex: 'process_way_ch',
        }, {
          title: '状态',
          // dataIndex: 'status_ch',
          render: data => <span className={`ticket-label-rules-${data.status}`}>{ data.status_ch }</span>
        }, {
          title: '创建人',
          dataIndex: 'create_user',
        }, {
          title: '修改时间',
          dataIndex: 'update_time',
        }, {
          title: '操作',
          render: data => (
            <>
              <Button
                text='编辑'
                onClick={() => onEdit(data)}
              />
              <Button
                text='提交任务'
                onClick={() => onSubmitTask(data)}
              />
            </>
          )
        }
      ]
    },
    [],
  )

  function onCreate() {
    setIsEdit(false)
    setCurEditItem({})
    setShowSteps(true)
  }

  function onEdit(item) {
    setIsEdit(true)
    setCurEditItem(item)
    setShowSteps(true)
  }

  function onSubmitTask(item) {
    // console.log('-----onSubmitTask: ', item);
    setCurEditItem(item)
    setShowEditWin(true)
  }

  function updateParams(key, val) {
    let cloneParams = _.cloneDeep(searchParams)
    cloneParams[key] = val
    setSearchParams(cloneParams)
  }

  return (
    <>
      <BreadCrumbsAndTablde>
        <BreadCrumbsAndTablde.LeftCrumbs>
          <Select
            label='标签名称'
            readOnly={false}
            value={searchParams.label_name}
            width={200}
            data={standardManageItems}
            onInputFilter={setLabelName}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('label_name', val)
            }}
          />

          <Select
            label='规则类型'
            value={searchParams.rule_type}
            width={200}
            data={ruleTypeArr}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('rule_type', val)
            }}
          />

          <Select
            label='状态'
            value={searchParams.status}
            width={200}
            data={statusArr}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('status', val)
            }}
          />

          <Input
            label='创建人'
            value={searchParams.create_user}
            width={200}
            onChange={val => {
              setPageConfig(r => _.assign({}, r, {
                page: 1
              }))
              updateParams('create_user', val)
            }}
          />
        </BreadCrumbsAndTablde.LeftCrumbs>
        <BreadCrumbsAndTablde.RightCrumbs>
          <Button
            text='新增任务'
            type='submit'
            iconClass='iconfont icon-add'
            onClick={onCreate}
          />
        </BreadCrumbsAndTablde.RightCrumbs>
        <BreadCrumbsAndTablde.CenterWrap>
          <Table
            columns={getcolumns()}
            dataSource={_.isArray(curRecord.items) ? curRecord.items : []}
          />

          <Pagenation
            total={ curRecord.total }
            currentPage={ curRecord.current_page }
            pageCount={ curRecord.per_page }
            getClickPage={ page => setPageConfig(r => _.assign({}, r, {
              page
            })) }
            pageCountChange={ limit => setPageConfig(r => _.assign({}, r, {
              limit
            })) }
          />
        </BreadCrumbsAndTablde.CenterWrap>
      </BreadCrumbsAndTablde>

      {/* 提交任务弹窗 */}
      <EditWin
        showEditWin={showEditWin}
        setShowEditWin={setShowEditWin}
        record={curEditItem}
        updatePage={initData}
      />

      {/* 新增任务 */}
      <StepWin
        showSteps={showSteps}
        hideStepsFunc={setShowSteps}
        isEdit={isEdit}
        record={curEditItem}
        updatePage={initData}
      />
    </>
  )
}
