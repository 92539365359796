import { useMemo, useEffect, useState } from 'react'
import _ from 'lodash'
import Layout from '@common/Layout'
import Message from '@common/Message'
import defaultUserImg from '../img/defaultUser.svg'
import userImg from '../img/user.png'
import { getUserLabelHbase } from '@services/userSearch'
import { commonApi } from '@utils/request'
import { USER_LABELS } from '../common'
import './index.css'

export default function BaseInfo(props) {

  const {
    userTranceItem = {}
  } = props

  const [userLabels, setUserLabels] = useState([])

  const formData = useMemo(() => {
    return userTranceItem.basicInfo || {}
  }, [userTranceItem.basicInfo])

  useEffect(() => {
    if (_.isEmpty(userTranceItem.basicInfo)) return
    const OPENID = _.get(userTranceItem, 'basicInfo.OPENID') || ''

    // 当前仅支持openid进行查询用户标签，故 accountType：0
    commonApi(getUserLabelHbase, {
      account: OPENID,
      accountType: '0',
    }).then(data => {
      let labels = _.isArray(_.get(data, 'labels')) ? _.get(data, 'labels') : []
      labels = labels.filter(i => !!i.tag_name && USER_LABELS.hasOwnProperty([i.tag_name]))
      setUserLabels(labels)
    }).catch(err => Message.error('用户标签查询失败 ' + err))
  }, [userTranceItem.basicInfo])

  return (
    <div className='monitor__user-search__outer-wrap'>
      <div className='monitor__user-search__base-info-wrap'>
        <img className='user-img' src={_.isEmpty(formData) ? defaultUserImg : userImg} />
        <div className='user-info'>
          <Layout.Row>
            <Layout.Col span={8}>
              <span className='label-title'>OPENID</span>
              <span>{ formData.OPENID || '-' }</span>
            </Layout.Col>
            <Layout.Col span={8}>
              <span className='label-title'>手机号码</span>
              <span>{ formData.PHONE || '-' }</span>
            </Layout.Col>
            <Layout.Col span={8}>
              <span className='label-title'>QQ账号</span>
              <span>{ formData.QQ || '-' }</span>
            </Layout.Col>
          </Layout.Row>

          <Layout.Row>
            <Layout.Col span={8}>
              <span className='label-title'>微信账号</span>
              <span>{ formData.WX || '-' }</span>
            </Layout.Col>
            <Layout.Col span={8}>
              <span className='label-title'>用户姓名</span>
              <span>{ formData.NAME || '-' }</span>
            </Layout.Col>
            <Layout.Col span={8}>
              <span className='label-title'>身份证号</span>
              <span>{ formData.USERID || '-' }</span>
            </Layout.Col>
          </Layout.Row>
        </div>
      </div>
      <div className='monitor__user-search__user-label-wrap'>
        <span className='user-label-title'>用户标签</span>
        {
          (
            _.isArray(userLabels) &&
            userLabels.length > 0
          ) ?
          <>
            {
              userLabels.map((item) => (
                <span key={item.tag_name} className='user-label-item'>{ `${!!USER_LABELS[item.tag_name] ? (USER_LABELS[item.tag_name] + '-') : ''}${item.tag_value}` }</span>
              ))
            }
          </> : 
          <span style={{ marginLeft: 16 }}>-</span>
        }
      </div>
    </div>
  )
}