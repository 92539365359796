import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import SdwInput from '@common/Input'
import SdwEmpty from '@common/Empty'

import './index.css'

class Tree extends React.Component {

  state = {
    domainInputVal: '', // 模糊搜索值
    currentItem: '',
    expendList: [], // 存放展开的组
    allTreeGroupList: [], // 存储所有非叶子节点，用于模糊搜索展开分组使用
  }

  static defaultProps = {
    showFilterInput: false,
    width: 340,
    data: [],
    options: {
      children: 'children',
      label: 'label'
    },
    inputPlaceholder: '请输入'
  }

  componentDidMount() {

    // 展开所有的分组
    if (this.props.showFilterInput) {
      let curList = this.getCurList(this.props.data)
      this.setState({
        allTreeGroupList: curList
      })
    }
  }

  componentDidUpdate (prevPros, prevState) {

    // 展开所有的分组
    if (this.props.showFilterInput && !_.isEqual(prevPros.data, this.props.data)) {
      let curList = this.getCurList(this.props.data)
      this.setState({
        allTreeGroupList: curList
      })
    }

    let { allTreeGroupList, domainInputVal, expendList } = this.state

    if (prevState.domainInputVal !== this.state.domainInputVal) {

      if (!domainInputVal) {
        this.setState({
          expendList: []
        })
      } else {
        this.setState({
          expendList: allTreeGroupList
        })
      }
    }
  }

  getCurList = (data, list = []) => {
    let { options } = this.props

    if (Array.isArray(data) && data.length) {
      data.forEach(item => {
        if (item[options.children] && item[options.children].length) {
          list.push(item[options.label])

          if (item[options.children] && item[options.children].length) {
            let childList = this.getCurList(item[options.children], list)
            
            if (childList && childList.length) {
              list.push(...childList)
            }
          }
        }
      })
      return Array.from(new Set(list))
    }
  }

  onGroupClick = group => {
    let { expendList } = this.state

    let { options, changeValue } = this.props

    let curExpendList = [...expendList]
    let index = curExpendList.indexOf(group[options.label])
    if (index !== -1) {
      curExpendList.splice(index, 1)
    } else {
      curExpendList.push(group[options.label])
    }

    this.setState({
      currentItem: group,
      expendList: curExpendList
    })

    if (typeof changeValue === 'function') {
      changeValue(group)
    }
  }

  formatTreeDate = (list, tree_level) => {

    let { options } = this.props

    // 给每一项补充level, 给每一级设置paddingLeft使用的
    list = list.map(item => {
      item = {
        ...item,
        tree_level: tree_level
      }

      if (item[options.children] && item[options.children].length) {
        let index = tree_level + 1
        item[options.children] = this.formatTreeDate(item[options.children], index)
      }

      return item
    })

    return list
  }

  // 将叶子节点不是模糊搜索中的去除
  filterTreeData = list => {
    let record = [...list]

    record = record.map(item => {

      if (item[this.props.options.children] && item[this.props.options.children].length) {
        item[this.props.options.children] = this.filterTreeData(item[this.props.options.children]).filter(i => i)
        return item
      }
      
      if (item[this.props.options.label].indexOf(this.state.domainInputVal) > -1) {
        return item
      }

      return ''
    })

    return record
  }

  // 将非叶子节点且没有叶子元素的去除
  onSplice = list => {

    let data = [...list]

    // 倒序删除
    for (let j = data.length - 1; j >= 0; j--) {
      for (let i = j; i >= 0; i--) {
        let item = data[i];
        if (item && item[this.props.options.children]) {
          if (!item[this.props.options.children].length) {
            data.splice(i, 1)
          } else {
            item[this.props.options.children] = this.onSplice(item[this.props.options.children])
          }
        }
      }
    }

    return data
  }

  render () {

    let {
      domainInputVal,
      currentItem,
      expendList,
    } = this.state

    let {
      showFilterInput,
      filterInputWidth,
      data,
      options,
      inputPlaceholder,
      width
    } = this.props

    let treeData = this.formatTreeDate([...data], 0)

    // 过滤tree的数据
    if (showFilterInput) {
      treeData = this.filterTreeData(treeData)
      treeData = this.onSplice(treeData)
    }

    return (
      <div className="sdw-tree-wrap" style={{ width: width }}>
        {
          showFilterInput &&
          <div style={{ marginBottom: 8 }}>
            <SdwInput
              placeholder={inputPlaceholder}
              width={filterInputWidth}
              value={domainInputVal}
              onChange={val => {
                this.setState({ domainInputVal: val })
              }}
            />
          </div>
        }

        <div className="sdw-tree__body-wrap">
          {
            !!treeData.length &&
            TreeItem(treeData, options, currentItem, expendList, this.onGroupClick)
          }
          {
            !treeData.length &&
            <SdwEmpty
              text={!!domainInputVal ? '暂无匹配项' : '暂无数据'}
              icon={!!domainInputVal ? 'noSearch' : 'noData'}
            />
          }
        </div>
      </div>
    )
  }
}

Tree.propTypes = {
  filterInputWidth: PropTypes.number,
  showFilterInput: PropTypes.bool,
  data: PropTypes.array,
  options: PropTypes.object,
  inputPlaceholder: PropTypes.string,
  changeValue: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Tree

function TreeItem (data, options, currentItem, expendList, onGroupClick) {
  return (
    !!data.length &&
    data.map((group, index) => {
      let hasChildren = !!group[options.children] && !!group[options.children].length
      return (
        <div key={index}>
          <div
            style={{
              paddingLeft: group.tree_level * 30
            }}
            className={
              'ellipsis' +
              (hasChildren ? ' group-has-children' : ' group-has-no-children') +
              (currentItem[options.label] === group[options.label] ? ' current' : '') +
              (!!group.customClass ? ` ${group.customClass}` : '') +
              (hasChildren && expendList.includes(group[options.label]) ? ' expend' : '')
            }
            onClick={() => onGroupClick(group)}
          >{group[options.label]}</div>

          {
            hasChildren &&
            expendList.includes(group[options.label]) &&
            TreeItem(group[options.children], options, currentItem, expendList, onGroupClick)
          }
        </div>
      )
    })
  )
}
