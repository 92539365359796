import React, { Component } from 'react'

import SdwBreadCrumbs from '@common/BreadCrumbs'
import BusinessIndicatorsOperation from "./IndicatorOperationConfiguration/BusinessIndicatorsOperation";
import ArchiveL4 from './ArchiveL4'
import FormConfig from './FormConfig'

import "./style/index.css";
import _ from 'lodash';
import { hasPriv } from '@components/FrontendAuth/AuthMap'

const MENU_MAP = {
  IOC: 'IOC',
  ARCHIVE: 'ARCHIVE',
  FORM: 'FORM',
}

const MENU_TEXT_MAP = {
  IOC: '指标运营配置',
  ARCHIVE: '归档项L4配置',
  FORM: '表单业务类型配置',
}

export default class OperationConfiguration extends Component {
  state = {
    checkItem: hasPriv('getIndicatorConfItems') ? MENU_MAP.IOC : MENU_MAP.ARCHIVE
  }
  switchItem = (name) => {
    if (name === this.state.checkItem) return
    this.setState({
      checkItem: name
    })
  }
  render() {
    let { checkItem } = this.state
    return (
      <div>
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '智慧运营台',
              path: '/admin/monitor/monitorManagement'
            }, {
              title: '运营配置'
            }
          ]}
        />
        <div className="operationconfiguration-flex-left-right">
          <div className="operationconfiguration-left-nav">
            <div className="operationconfiguration-left-nav-content">
              {
                _.keys(MENU_MAP).map(menuKey => {
                  let isShow = false
                  if (menuKey === MENU_MAP.IOC) isShow = hasPriv('getIndicatorConfItems')
                  if (menuKey === MENU_MAP.ARCHIVE) isShow = hasPriv('getArchiveCodeConfItems')
                  if (menuKey === MENU_MAP.FORM) isShow = hasPriv('queryFormsConfPage')

                  return isShow ? (
                    <div
                      key={menuKey}
                      className={`operationconfiguration-left-nav-item ${checkItem === menuKey ? 'operationconfiguration-left-nav-checkitem' : ''}`}
                      onClick={() => this.switchItem(menuKey)}
                    >
                      {MENU_TEXT_MAP[menuKey]}
                    </div>
                  ) : null
                })
              }
            </div>
          </div>
          <div className="operationconfiguration-right-content">
            { !!checkItem && checkItem === MENU_MAP.IOC && hasPriv('getIndicatorConfItems') && <BusinessIndicatorsOperation /> }
            { !!checkItem && checkItem === MENU_MAP.ARCHIVE && hasPriv('getArchiveCodeConfItems') && <ArchiveL4 /> }
              { !!checkItem && checkItem === MENU_MAP.FORM && hasPriv('queryFormsConfPage') && <FormConfig /> }
          </div>
        </div>
      </div>
    )
  }
}
