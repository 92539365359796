import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Button } from 'element-react'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import SdwInput from '@common/Input'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwSelect from '@common/Select'
import SdwDrawer from '@common/Drawer'
import EditControlWin from './EditControlWin'
import OverViewWin from './OverViewWin'
import { getAlarmCfgList, getAllTablecfg } from '@services/alertRules'
import { MODULE_ID_OPTIONS, OPR_TYPE_MAP } from "./common";

import './style/index.css'

class MonitorControl extends PureComponent {
  editControlWinRef = React.createRef()

  state = {
    oprType: OPR_TYPE_MAP.edit,
    alarm_rule_name: '',
    module_id: '',
    ruleID: '',
    dataSource: '',
    enableValue: '',
    ruleName: '',
    alarmField: '',
    tableData: {},
    curPage: 1,
    curLimit: 10,
    enableOptios: [{
      value: 1,
      label: '是'
    }, {
      value: 0,
      label: '否'
    }],
    dataSourceOptions: [],
    showEditControlWin: false,
    currentAlarmID: '',
    isEdit: false,
    showOverViewWin: false
  }

  componentDidMount () {
    this.getAlarmCfgList()
    this.getAllTablecfg()
  }

  componentDidUpdate (prevProps, prevState) {

    if (
      !_.isEqual(prevState.curPage, this.state.curPage) ||
      !_.isEqual(prevState.curLimit, this.state.curLimit)
    ) {
      this.getAlarmCfgList()
    }
  }

  getAllTablecfg = () => {
    getAllTablecfg().then(res => {
      if (res && res.data && res.data.code === 0) {
        let list = _.isArray(res.data.data) ? res.data.data : []
        this.setState({
          dataSourceOptions: list.map(i => {
            return {
              id: i.id,
              text: `${i.database_name}(${i.data_set_chinese})`
            }
          })
        })
      }
    })
  }

  getAlarmCfgList = () => {
    let { curPage, curLimit, ruleID, ruleName, dataSource, alarmField, enableValue, module_id, alarm_rule_name } = this.state

    let searchParams = JSON.stringify({
      ...module_id ? {
        module: {
          value: module_id,
          operator: '='
        }
      } : {},
      ...alarm_rule_name ? {
        alarm_rule_name: {
          value: `%${alarm_rule_name}%`,
          operator: 'like'
        }
      } : {},
      ...ruleID ? {
        id: {
          value: ruleID,
          operator: '='
        }
      } : {},
      ...ruleName ? {
        alarm_title: {
          value: `%${ruleName}%`,
          operator: 'like'
        }
      } : {},
      ...dataSource ? {
        fk_id: {
          value: dataSource,
          operator: '='
        }
      } : {},
      ...alarmField ? {
        alarm_field: {
          value: `%${alarmField}%`,
          operator: 'like'
        }
      } : {},
      ...!_.isEqual(enableValue, '') ? {
        enable: {
          value: enableValue,
          operator: '='
        }
      } : {}
    })

    getAlarmCfgList({
      page: curPage,
      limit: curLimit,
      search: searchParams
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          this.setState({
            tableData: res.data.data || {}
          })
        }
      })
  }

  getColumns = () => {
    let { dataSourceOptions } = this.state
    let self = this
    return [
      {
        title: "ID",
        width: 36,
        dataIndex: "id"
      },
      {
        width: 318,
        title: "规则名称",
        render: data => (
          <span>{ data.alarm_rule_name }</span>
        )
      },
      {
        width: 70,
        title: "所属模块",
        render: data => {
          let text = _.get(MODULE_ID_OPTIONS.filter(i => i.id === data.module), '[0].text')
          return (
            <span>{ !!text ? text : '-' }</span>
          )
        }
      },
      {
        width: 96,
        title: "预警数据源",
        render: data => (
          <span>{ _.isArray(dataSourceOptions) ? (
            _.get(dataSourceOptions.filter(i =>i.id === data.fk_id)[0], 'text') || '-'
          ) : data.dimension_field }</span>
        )
      },
      {
        width: 96,
        title: "预警字段",
        dataIndex: "alarm_field"
      },
      {
        width: 70,
        title: "维度转义",
        dataIndex: "dimension_value_name"
      },
      {
        width: 56,
        title: "创建任务",
        render: data => (
          <span>{ !data.is_task ? '否' : '是' }</span>
        )
      },
      {
        title: "是否有效",
        width: 56,
        render: function (data, record) {
          return (
            <>
              {
                _.isEqual(typeof data.enable, 'number') && <span className={
                  data.enable ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                }></span>
              }
              <span>{_.isEqual(typeof data.enable, 'number') ?
              (data.enable ? '有效' : '无效') : '-'}</span>
            </>
          )
        }
      },
      {
        title: "修改人",
        width: 58,
        dataIndex: "modify_user"
      },
      {
        width: 106,
        title: "修改时间",
        dataIndex: "modify_time"
      },
      {
        width: 68,
        title: "操作",
        render: function (data, record) {
          return (
            <span>
              <Button
                plain={true}
                type="text"
                size="small"
                onClick={ () => self.handleEdit(data, OPR_TYPE_MAP.edit) }
              >编辑</Button>
              <Button
                plain={true}
                type="text"
                size="small"
                onClick={ () => self.handleEdit(data, OPR_TYPE_MAP.copy) }
              >复制</Button>
              {/* 这期暂时不做，下个版本做 */}
              {/* <Button
                plain={true}
                type="text"
                size="small"
                onClick={ () => self.handleOverView(data) }
              >预警预览</Button> */}
            </span>
          )
        }
      }
    ]
  }

  handleEdit = (data, oprType = OPR_TYPE_MAP.edit) => {
    this.setState({
      currentAlarmID: data.id,
      showEditControlWin: true,
      isEdit: true,
      oprType
    })
  }

  handleOverView = data => {
    this.setState({
      currentAlarmID: data.id,
      showOverViewWin: true
    })
  }

  render () {

    let {
      enableOptios,
      dataSourceOptions,
      ruleID,
      dataSource,
      enableValue,
      alarmField,
      tableData,
      ruleName,
      showEditControlWin,
      isEdit,
      currentAlarmID,
      module_id,
      alarm_rule_name,
      oprType,
      showOverViewWin
    } = this.state

    return (
      <div>
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '智慧运营台',
              path: '/admin/monitor/monitorManagement'
            }, {
              title: '预警监控眼',
              path: '/admin/monitor/qualityControl'
            }, {
              title: '预警规则管理'
            }
          ]}
        />

        <div className="page-mainbody">
          <div className="sdw-monitor-eye__wrap page-search" style={{ lineHeight: '44px' }}>
            <span className="sdw-monitor-eye__label">
              <SdwInput
                placeholder="使用;分隔"
                value={ruleID}
                width={160}
                label="规则ID"
                onChange={val => this.setState({curPage: 1, ruleID: val})}
                onBlur={this.getAlarmCfgList}
              />
            </span>
            <span className="sdw-monitor-eye__label">
              <SdwInput
                placeholder="请输入预警规则"
                value={alarm_rule_name}
                width={160}
                label="预警规则"
                onChange={val => this.setState({curPage: 1, alarm_rule_name: val})}
                onBlur={this.getAlarmCfgList}
              />
            </span>

            <span className="sdw-monitor-eye__label">
              {/* <SdwInput
                value={ruleID}
                width={160}
                label="规则ID"
                onChange={val => this.setState({ruleID: val})}
                onBlur={this.getAlarmCfgList}
                onEnterKeyDown={this.getAlarmCfgList}
              /> */}
              <SdwSelect
                placeholder="请选择"
                value={module_id}
                width={160}
                label="所属模块"
                readOnly={false}
                data={MODULE_ID_OPTIONS}
                onChange={val => this.setState({curPage: 1, module_id: val}, this.getAlarmCfgList)}
              />
            </span>

            <span className="sdw-monitor-eye__label">
              {/* <SdwInput
                value={ruleName}
                width={160}
                label="规则名称"
                onChange={val => this.setState({ruleName: val})}
                onBlur={this.getAlarmCfgList}
                onEnterKeyDown={this.getAlarmCfgList}
              /> */}
              <SdwSelect
                placeholder="请选择"
                value={[]}
                width={160}
                label="创建任务"
                readOnly={false}
                data={[{
                  id:1,
                  text:"是"
                },{
                  id:0,
                  text:"否"
                }]}
                onChange={val => console.log(val)}
              />
            </span>

            <span className="sdw-monitor-eye__label">
              <SdwSelect
                placeholder="请选择"
                value={dataSource}
                width={160}
                listWidth={300}
                label="数据源"
                readOnly={false}
                data={dataSourceOptions}
                onChange={val => this.setState({curPage: 1, dataSource: val}, this.getAlarmCfgList)}
              />
            </span>

            {/* <span className="sdw-monitor-eye__label">
              <SdwInput
                value={alarmField}
                width={160}
                label="预警字段"
                onChange={val => this.setState({alarmField: val})}
                onBlur={this.getAlarmCfgList}
                onEnterKeyDown={this.getAlarmCfgList}
              />
            </span> */}

            <span className="sdw-monitor-eye__label">
              <SdwSelect
                placeholder="请选择"
                value={enableValue}
                width={160}
                label="是否有效"
                data={enableOptios.map(el => {
                  return {
                    id: el.value,
                    text: el.label
                  }
                })}
                onChange={val => this.setState({curPage: 1, enableValue: val}, this.getAlarmCfgList)}
              />
            </span>

            <span className="btn-group">
              <button
                className="sure-button el-icon-plus"
                icon="plus"
                onClick={() => this.setState({
                  showEditControlWin: true,
                  isEdit: false
                })}
              >添加规则</button>
            </span>
          </div>

          <SdwTable
            columns={ this.getColumns() }
            dataSource={ _.isArray(tableData.items) ? tableData.items : [] }
          />

          <div style={{ marginTop: '16px' }}>
            <SdwPagenation
              total={ tableData.total }
              currentPage={ tableData.current_page }
              pageCountList={[10, 15, 20, 40]}
              pageCount={ tableData.per_page }
              getClickPage={ page => {
                this.setState({
                  curPage: page
                })
              } }
              pageCountChange={ limit => {
                this.setState({
                  curLimit: limit
                })
              } }
            />
          </div>
        </div>

        <SdwDrawer
          title={ (isEdit && oprType === OPR_TYPE_MAP.edit) ? '编辑规则' : '添加预警规则' }
          visible={showEditControlWin}
          onIconClick={() => this.setState({ showEditControlWin: false })}
          onCancelClick={() => this.setState({ showEditControlWin: false })}
          onSureClick={() => this.editControlWinRef.current.handleSubmit()}
        >
          <SdwDrawer.Body>
            <EditControlWin
              ref={this.editControlWinRef}
              isEdit={isEdit}
              oprType={oprType}
              currentAlarmID={currentAlarmID}
              dataSourceOptions={dataSourceOptions}
              changeVisibleFun={flag => this.setState({ showEditControlWin: flag })}
              getAlarmCfgList={this.getAlarmCfgList}
            />
          </SdwDrawer.Body>
        </SdwDrawer>

        {
          showOverViewWin &&
          <OverViewWin
            showOverViewWin={showOverViewWin}
            currentAlarmID={currentAlarmID}
            changeVisible={bool => this.setState({showOverViewWin: bool})}
          />
        }
      </div>
    )
  }
}

export default MonitorControl
