import React, {PureComponent} from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import SdwMessage from '@common/Message'
import {Dropdown, Tabs} from 'element-react'
import {getPathTree, setNodesSort} from '@services/dataVisualization'
import './index.css'

class SortWin extends PureComponent {

    state = {
        treeList: [],
        childrenObj: {},
        curChildrenList:[],
        treeListId: '', // 当前子节点对应的父级id
        treeListName: '', // 当前子节点对应的父级名字
        onTabClickName: 1, // 当前点击的Tab
    }

    componentDidMount() {
        this.getPathTree()
    }

    // 获取父节点
    getPathTree = () => {
        getPathTree().then((res) => {
            if (res && res.data && res.data.code === 0) {
                let items = res.data.data || []
                // console.log("items==========================", items)
                let tree_list = []
                let n = 0
                items.forEach(i => {
                    n = n + 1
                    tree_list.push({
                        queue: n, name: i.path_name, id: i.id
                    })
                })
                const childrenObj = {};
                items.forEach(item => {
                    childrenObj[item.id] = item.children;
                })
                // console.log('tree_list==========', tree_list)
                // console.log('childrenObj==========', childrenObj)
                this.setState({
                    treeList: tree_list,
                    childrenObj,
                    // treeListName: tree_list[0]?.name,
                }, ()=>{
                    this.handleSelectChange(this.state.treeList[0])
                })
            }
        }).catch(error => {
            SdwMessage.error('接口异常' + error)
        })
    }

    // 父节点排序
    changeTreeList = (index, type, val) => {
        let {treeList} = this.state
        let treeListClone = _.clone(treeList)
        if (type === 'queue') {
            let beforeVal = Number(index - 1)
            let currentVal = Number(val - 1)
            if (beforeVal < currentVal) {
                for (let j = beforeVal + 1; j <= currentVal; j++) {
                    treeListClone[j]['queue'] = treeListClone[j]['queue'] - 1
                }
            } else if (beforeVal > currentVal) {
                for (let j = beforeVal - 1; j >= currentVal; j--) {
                    treeListClone[j]['queue'] = treeListClone[j]['queue'] + 1
                }
            }
        }
        treeListClone[index - 1][type] = val
        this.setState({treeList: treeListClone})
    }

    // 子节点排序
    changeTreeChildrenList = (index, type, val) => {
        let {curChildrenList} = this.state
        let curChildrenListClone = _.clone(curChildrenList)
        if (type === 'queue') {
            let beforeVal = Number(index - 1)
            let currentVal = Number(val - 1)
            if (beforeVal < currentVal) {
                for (let j = beforeVal + 1; j <= currentVal; j++) {
                    curChildrenListClone[j]['queue'] = curChildrenListClone[j]['queue'] - 1
                }
            } else if (beforeVal > currentVal) {
                for (let j = beforeVal - 1; j >= currentVal; j--) {
                    curChildrenListClone[j]['queue'] = curChildrenListClone[j]['queue'] + 1
                }
            }
        }
        curChildrenListClone[index - 1][type] = val
        this.setState({curChildrenList: curChildrenListClone})
    }

    // 获取当前父级下面的子节点
    handleSelectChange = (val) => {
        // console.log('handleSelectChange----id===', val)
        this.setState({
            treeListId: val.id,
            treeListName: val.name
        })

        let {childrenObj} = this.state
        if (childrenObj[val.id] != null) {
            const items = childrenObj[val.id].map(i => ({
                ...i,
                id: i.id,
                text: i.path_name,
            }))
            let curList = []
            let n = 0
            items.forEach(j => {
                n = n + 1
                curList.push({
                    queue: n, name: j.path_name, id: j.id
                })
            })
            this.setState({
                curChildrenList: curList
            })
        }
    }

    closeDialog = () => {
        this.props.closeDialog()
    }

    // 获取当前Tab
    onTabClick = (item) => {
        this.setState({
            onTabClickName: item.props.name
        })
    }

    // 保存排序
    setNodesSort = () => {
        let {treeList, curChildrenList, onTabClickName, treeListId} = this.state

        let params = {
            'pid': onTabClickName === 1 ? 0 : treeListId,
            'sorts': onTabClickName === 1 ? treeList.map(i => {return i.id}) : curChildrenList.map(i => {return i.id})
        }

        setNodesSort(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let msg = '排序成功'
                SdwMessage.success(msg)
                this.closeDialog(false)
                this.props.getPathTree()
            } else {
                SdwMessage.error('排序失败')
            }
        })
    }

    render() {

        let {sortDialogVisible} = this.props

        let {treeList, curChildrenList, treeListName} = this.state

        return (
            <div>
                <SdwDialog
                    cusWrapClass='sort-win-dialog__wrap'
                    visible={sortDialogVisible}
                    width={600}
                    height={800}
                    onSureClick={this.setNodesSort}
                    onCancelClick={() => this.closeDialog()}
                >
                    <div className='sort-win-dialog__label'>
                        <Tabs activeName='1' onTabClick={this.onTabClick}>
                            <Tabs.Pane label='报表分类排序' name='1'>
                                <div className='report-sort-win__wrap' >
                                    {
                                        !!treeList.length &&
                                        treeList.sort((a, b) => a.queue - b.queue).map((item, index) => {
                                            let dataList = treeList.map((i, index1) => {
                                                return {
                                                    id: index1 + 1,
                                                    text: index1 + 1
                                                }
                                            })
                                            return (
                                                <div style={{marginBottom: 12}}>
                                                    <span>
                                                        <SdwSelect
                                                            value={item.queue}
                                                            data={dataList}
                                                            width={180}
                                                            listWidth={180}
                                                            onChange={val => this.changeTreeList(index + 1, 'queue', val)}
                                                        />
                                                    </span>
                                                    <span style={{marginLeft: 8}}>
                                                        <SdwInput
                                                            disabled={true}
                                                            value={item.name}
                                                            width={320}
                                                            onChange={val => this.changeTreeList(index + 1, 'name', val)}
                                                        />
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Tabs.Pane>
                            <Tabs.Pane label='报表排序' name='2'>
                                <div className='report-sort-win__wrap'>
                                    <Dropdown
                                        trigger='click'
                                        menu={(
                                            <Dropdown.Menu>
                                                {
                                                    treeList.map(menu => (
                                                        <Dropdown.Item
                                                            key={menu.id}
                                                            command={menu}
                                                        >{menu.name || ''}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        )}
                                        onCommand = {command => this.handleSelectChange(command)}
                                    >
                                        <span className="el-dropdown-link">
                                            {treeListName}
                                            <i className="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                                    </Dropdown>
                                    <div>
                                        <div style={{marginTop: 12}}>
                                            {
                                                !!curChildrenList.length &&
                                                curChildrenList.sort((a, b) => a.queue - b.queue).map((item, index) => {
                                                    let dataList = curChildrenList.map((i, index1) => {
                                                        return {
                                                            id: index1 + 1,
                                                            text: index1 + 1
                                                        }
                                                    })
                                                    return (
                                                        <div style={{marginBottom: 12}}>
                                                            <span>
                                                                <SdwSelect
                                                                    value={item.queue}
                                                                    data={dataList}
                                                                    width={180}
                                                                    listWidth={180}
                                                                    onChange={val => this.changeTreeChildrenList(index + 1, 'queue', val)}
                                                                />
                                                            </span>
                                                            <span style={{marginLeft: 8}}>
                                                                <SdwInput
                                                                    disabled={true}
                                                                    value={item.name}
                                                                    width={320}
                                                                    onChange={val => this.changeTreeChildrenList(index + 1, 'name', val)}
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Tabs.Pane>
                        </Tabs>
                    </div>
                </SdwDialog>
            </div>
        )
    }
}

export default SortWin
