import React from 'react'
import _ from 'lodash'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import { STRATEGY_TYPE_MAP, FIXED_VALUE, FIXED_VALUE_LESS, DEFAULT_ITEM_OBJ, DEFAULT_VARIATION_OBJ } from './common'

import './style/warn-strategy.css'

export default class WarnStrategy extends React.Component {

  validateFun = val => {
    if (!!val) {
      return true
    }
    return '该输入项不能为空'
  }
  validateIntegerFun = val => {
    if(/-/.test(val)){
      return '请输入正数'
    }
    if (!!val) {
      return true
    }
    return '该输入项不能为空'
  }

  addPolicy = () => {
    this.props.updateWarnStrategyList(this.props.firstIndex, STRATEGY_TYPE_MAP.add)
  }

  removePolicy = index => {
    this.props.updateWarnStrategyList(this.props.firstIndex, STRATEGY_TYPE_MAP.del, index)
  }

  onUpdate = (index, type, val) => {
    let { strategyData, firstIndex } = this.props
    let curObj = _.cloneDeep(strategyData[index])

    if (['rule_name', 'operator'].includes(type)) {

      // 当前的rule_name：fixed_value\variation
      let ori_rule_name = curObj.rule_name
      
      // 如果当前未改变，直接返回，不做更新
      if (ori_rule_name === val) return
  
      // 是否是固定值变为突增突降
      let isFixValue = [FIXED_VALUE, FIXED_VALUE_LESS].includes(val)
  
      // variation
      if (isFixValue) {
        curObj = _.cloneDeep(DEFAULT_ITEM_OBJ)
      } else {
        curObj = _.cloneDeep(DEFAULT_VARIATION_OBJ)
      }
    }

    curObj[type] = val

    this.props.updateWarnStrategyList(firstIndex, STRATEGY_TYPE_MAP.update, index, curObj)
  }

  render () {

    let {
      strategyData,
      sholdCheckValidate
    } = this.props

    return (
      <span>
        {
          !!strategyData.length &&
          strategyData.map((strategy, index) => {

            // 是否是固定值
            let isFixValue = [FIXED_VALUE, FIXED_VALUE_LESS].includes(strategy.rule_name)

            return (
              <span
                className="sdw-warn-strategy__wrap cycle_time"
                key={index} 
                style={{
                  marginBottom: 14,
                  display: 'inline-block'
                }}>
                <span style={{ marginRight: 10 }}>
                  <SdwSelect
                    value={isFixValue ? strategy.rule_name : strategy.operator}
                    width={120}
                    clearable={false}
                    validateFun={this.validateFun}
                    sholdCheckValidate={sholdCheckValidate}
                    data={[
                      {
                        id: FIXED_VALUE,
                        text: '固定值大于'
                      }, {
                        id: FIXED_VALUE_LESS,
                        text: '固定值小于'
                      }, {
                        id: 'increase',
                        text: '突增'
                      }, {
                        id: 'decrease',
                        text: '突降'
                      }
                    ]}
                    onChange={val => this.onUpdate(index, [FIXED_VALUE, FIXED_VALUE_LESS].includes(val) ? 'rule_name' : 'operator', val)}
                  />
                </span>
                {
                  isFixValue ? 
                  <>
                    <SdwInput
                      label="大于"
                      type='number'
                      value={strategy.left_value}
                      width={80}
                      validateFun={this.validateFun}
                      sholdCheckValidate={[FIXED_VALUE].includes(strategy.rule_name) ? sholdCheckValidate : false}
                      onChange={val => this.onUpdate(index, 'left_value', val)}
                    />
                    <span style={{ marginLeft: 10 }}>
                      <SdwInput
                        label="小于"
                        type='number'
                        value={strategy.right_value}
                        width={80}
                        validateFun={this.validateFun}
                        sholdCheckValidate={[FIXED_VALUE_LESS].includes(strategy.rule_name) ? sholdCheckValidate : false}
                        onChange={val => this.onUpdate(index, 'right_value', val)}
                      />
                    </span>
                    <span style={{ marginLeft: 10 }}>
                      <SdwInput
                        label="关联天数"
                        type='number'
                        value={strategy.data_cycle}
                        width={180}
                        validateFun={this.validateIntegerFun}
                        sholdCheckValidate={sholdCheckValidate}
                        onChange={val => this.onUpdate(index, 'data_cycle', val)}
                      />
                    </span>
                  </> :
                  <>
                    <SdwInput
                      label="天数"
                      value={strategy.data_cycle}
                      width={80}
                      type='number'
                      validateFun={this.validateIntegerFun}
                      sholdCheckValidate={sholdCheckValidate}
                      onChange={val => this.onUpdate(index, 'data_cycle', val)}
                    />
                    <span style={{ marginLeft: 10 }}>
                      <SdwInput
                        label="触发最低倍数"
                        value={strategy.value}
                        width={220}
                        type='number'
                        validateFun={this.validateFun}
                        sholdCheckValidate={sholdCheckValidate}
                        onChange={val => this.onUpdate(index, 'value', val)}
                      />
                    </span>
                  </>
                }
                
  
                <i onClick={this.addPolicy} className="el-icon-plus"></i>
                {
                  index > 0 && 
                  <i onClick={() => this.removePolicy(index)} className="el-icon-minus"></i>
                }
                {
                  index > 0 && <br/>
                }
              </span>
            )
          })
        }
      </span>
    )
  }
}