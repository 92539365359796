import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

class Button extends React.PureComponent {

  static defaultProps = {
    type: 'text',
    iconClass: '',
    style: {}
  }

  render () {
    let { type, iconClass, text, disabled, onClick, style, tip } = this.props

    return (
      <span 
        className={`sdw-button-wrap__${type} ${iconClass} ${disabled ? 'disabled' : ''}`}
        style={style}
        title={!!tip ? tip : ''}
        onClick={() => {
          if (disabled || typeof onClick !== 'function') return
          onClick()
        }}
      >
        <span style={{ marginLeft: 4 }}>{ text }</span>
      </span>
    )
  }
}

Button.propTypes = {
  type: PropTypes.string, // submit、cancel、text、normal --->  默认为text：table中的蓝色字体按钮
  iconClass: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object, // 自定义一些样式：font-size...
  tip: PropTypes.string, // 鼠标悬浮提示语
}

export default Button