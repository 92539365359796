import React, {PureComponent} from 'react'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwDialog from '@common/Dialog'
import SdwSwitch from '@common/Switch'
import './index.css'

class CatalogueWin extends PureComponent {

    validateFun = val => {
        if (!val) {
            return '该输入项不能为空'
        }
        return true
    }

    closeDialog = () => {
        this.props.closeDialog()
    }

    render() {
        let {item, staffUserList, dialogVisible, title, handleSubmit, setPathTreeData, staffItems} = this.props
        let curPermissionsObj = item.data.permissions || []

        return (
            <div>
                <SdwDialog
                    title={title}
                    visible={dialogVisible}
                    width={1200}
                    onSureClick={handleSubmit}
                    onCancelClick={() => this.closeDialog()}
                    lockScroll={false}
                >
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title required'>分类名称</span>
                        <SdwInput
                            width={1000}
                            listWidth={1000}
                            placeholder={'请输入'}
                            value={item.data.path_name}
                            onChange={val => setPathTreeData('path_name', val)}
                            sholdCheckValidate={item.sholdCheckValidate}
                            validateFun={this.validateFun}
                        />
                    </div>
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title'>权限控制</span>
                        <SdwSelect
                            width={1000}
                            listWidth={1000}
                            placeholder={'请输入'}
                            readOnly={false}
                            isMultipleChoice={true}
                            data={staffUserList.map(i => {
                                return {
                                    id: i.LoginName,
                                    text: i.FullName
                                }
                            })}
                            value={curPermissionsObj}
                            getCurrentVal={val => staffItems(val)}
                            onChange={val => setPathTreeData('permissions', val)}
                        />
                    </div>
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title'>备注</span>
                        <SdwInput
                            type='textarea'
                            width={1000}
                            listWidth={1000}
                            placeholder={'请输入'}
                            value={item.data.remark}
                            onChange={val => setPathTreeData('remark', val)}
                        />
                    </div>
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title required'>是否有效</span>
                        <SdwSwitch
                            value={item.data.enabled}
                            changeValue={val => setPathTreeData('enabled', val)}
                        />
                    </div>
                </SdwDialog>
            </div>
        )
    }
}

export default CatalogueWin
