// @file 查询导出模块
import { useState, useEffect, useMemo, useCallback } from 'react'
import _ from 'lodash'
import BreadCrumbs from '@common/BreadCrumbs'
import Message from '@common/Message'
import DropDown from '@common/DropDown'
import Table from '@common/Table'
import Input from '@common/Input'
import Button from '@common/Button'
import Loadding from '@common/Loadding'
import Select from '@common/Select'
import Dialog from '@common/Dialog'
import {
  getMysqlTables,
  getEsIndex,
  getTables,
  getTablecfgList,
  getItemsNew,
  getTablecfgFieldsNew,
  getTablecfgFieldsAndStandardList,
  exportItemsData
} from '@services/dataSearch'
import { commonApi } from '@utils/request'
import { getRangeDateTime } from '@utils/formatTime'

import './index.css'
import { getTablecfgDetailPermission, getTablecfgDetailAuth, getMetaDataInfo } from '@api/metaData'
import SdwMessage from '@common/Message'
import Empty from '@common/Empty'
import AuthText from '@pages/Market/Metadata/TableDetails/common/AuthText'
import AuthApply from '@pages/Market/Metadata/TableDetails/common/AuthApply'
import { connect } from 'react-redux'
import { hasPriv } from '@components/FrontendAuth/AuthMap'
import delImg from '../../../../img/icon_16_sc@2x.png'
import DateTimePicker from '@common/DateTimePicker'

const dataSetTypeMap = {
  es: 'es',
  mysql: 'mysql'
}

const TIME_ARR = ['date', 'datetime', 'timestamp', 'date(7)','esdate']

const ZERO_NUMBER = 0

let timer = null

function SearchEport(props) {
  const curID = _.get(props, 'match.params.id')
  var isLocation = false
  if (props.location.state && props.location.state.data) {
    isLocation = true
  }
  var functionName = ""
  const [curRecord, setCurRecord] = useState({}) // 当前数据项
  const [dropMenu, setDropMenu] = useState([])
  const [curDropDownItem, setCurDropDownItem] = useState('')
  const [tableRecord, setTableRecord] = useState({}) // 右侧table数据
  const [allCheckList, setAllCheckList] = useState([]) // 会跟着过滤选项改变
  const [checkedArr, setCheckedArr] = useState([]) // 左侧已选中的勾选项数组
  const [fieldArr, setFieldArr] = useState([]) // 已选的字段列表
  const [isLoadding, setIsLoadding] = useState(false)
  const [selectMethod, setSelectMethod] = useState("condition")
  const [clickButtonMap, setClickButtonMap] = useState([])
  const [fieldName, setFieldName] = useState("")
  const [searchList, setSearchList] = useState([  // 搜索查询条件，默认第一个一定是时间过滤条件
    {
      indexID: ZERO_NUMBER // 前端标识
    }
  ])
  const [searchCustomList, setSearchCustomList] = useState({
    data_time: "",
    field: {},
    selectValue: "",
  })
  const [visible, setVisible] = useState(false)
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    msg: ''
  })
  const [allFiledArr, setAllFiledArr] = useState([])
  const [code, setCode] = useState("")
  const [isApplyAuth, showAuthApply] = useState(false)
  // 查询到的总条数
  const tableRecordTotal = useMemo(() => {
    return _.get(tableRecord, 'total') || 0
  }, [tableRecord])

  // 单次导出不能超过10万条
  const isTotalHeigh = useMemo(() => {
    return tableRecordTotal > 100000
  }, [tableRecordTotal])
  useEffect(() => {
    let clickButtonMapTmp = [
      {text:"按条件查询", type:"condition", className:"opr-right-wrap-li"},
      {text:"自定义查询", type:"custom", className: "opr-right-wrap-li"}
    ]
    if (curRecord.data_set_type == "mysql") {
      clickButtonMapTmp[1].className = "opr-right-wrap-li disable-clicked"
    }
    setClickButtonMap(clickButtonMapTmp)
  }, [curRecord])

  useEffect(() => {
    if (!curID) return
    let auth = hasPriv('getTablecfgDetailPermission')
    if (auth == false) {
      getTablecfgDetailAuth({id:curID}).then(res => {
        if (res && res.data ) {
          setCode(res.data.code)
        } else {
          let msg = res.data.msg || '请求失败'
          SdwMessage.error(msg)
        }
      }).catch(error => SdwMessage.error('请求失败 ' + error))
    } else {
      setCode(0)
    }
  }, [isApplyAuth])

  //下拉的过滤字段列表
  useEffect(() => {
    if (!curID) return
    commonApi(getTablecfgFieldsAndStandardList, {
      id: curID
    }).then(data => {
      const field = _.get(data, 'field') || []
      if (!_.isArray(field)) return

      let searchListClone = []
      let searchCustomListClone = {
        data_time: "",
        field: {},
        selectValue: "",
      }
      let search = {}
      let selectMethod = ""
      if (isLocation == true) {
        let propsData = props.location.state.data
        selectMethod = propsData.type || "condition"
        setSelectMethod(selectMethod)
        search = JSON.parse(propsData.search)
      }
      let allFilterArray = field.map(i => {

        if (isLocation == true) {

          for (let key in search) {
            if (key == i.field_name) {
              console.log("=====search[key]", search[key])
              if (selectMethod == "condition") { //条件查询
                let selectItem = {}
                selectItem = {
                  equal: search[key].type,
                  field_name: i.field_name,
                  field_type: i.field_type,
                  indexID: searchListClone.length,
                  key: i.id,
                }
                if (i.field_type == "date") {
                  selectItem["date"] = [search[key].value["gte"], search[key].value["lt"]]
                } else {
                  selectItem["value"] = search[key].value
                }
                searchListClone.push(selectItem)
              } else {//自定义查询
                console.log("----------search", search)
                searchCustomListClone.field = i
                if (search[""]) {
                  searchCustomListClone.selectValue = search[""].value
                }
                searchCustomListClone.data_time = [search[key].value["gte"], search[key].value["lt"]]
              }
            }
          }
        }
        i.text = `${i.field_name}（${i.field_name_ch || '-'}）`
        return i
      })
      if (isLocation == true) {
        if (selectMethod == "condition") {
          console.log("---------------searchListClone", searchListClone)
          setSearchList(searchListClone)
        } else {
          setSearchCustomList(searchCustomListClone)
        }
      }
      setAllFiledArr(allFilterArray)
    })
  }, [curID])

  useEffect(() => {
    if (!curID) return
    commonApi(getTablecfgList, {
      limit: 10,
      page: 1,
      search: JSON.stringify({
        id: {
          value: curID,
          operator: '='
        },
      })
    }).then(data => {
      const record = _.get(data, 'items.[0]')
      if (_.isEmpty(record)) return
      setCurRecord(record)
    }).catch(err => Message.error(err))
  }, [curID])

  useEffect(() => {
    if (!curID) return
    let params = {
      data_set_id: {
        value: curID,
        operator: '='
      }
    }
    let fieldsArray = []
    if (isLocation == true) {
      let propsData = props.location.state.data
      fieldsArray = propsData.fields.split(",")
      console.log("-----propsData", propsData)
      params["field_name"] = {"operator": "in", "value": fieldsArray}
    }
    commonApi(getTablecfgFieldsNew, {
      search: JSON.stringify(params)
    }).then(data => {
      const items = _.get(data, 'items')
      if (!_.isArray(items)) return

      let fieldNum = 0
      console.log("-----fieldsArray", fieldsArray)
      let fieldArrTmp = []
      const arr = items.map(i => {
        i.text = `${i.field_name}（${i.field_name_ch || '-'}）`
        if (isLocation == true) {
          if (fieldsArray.indexOf(i.field_name) >= 0) {
            i.isSelected = 1
            fieldArrTmp.push(i)
          }
        } else if (fieldNum < 5){
          i.isSelected = 1
          fieldArrTmp.push(i)
          fieldNum ++
        }
        return i
      })
      setFieldArr(fieldArrTmp)
      setAllCheckList(arr)
      //setCheckedArr(arr.slice(0, 5)) // 需求：进来默认选中前5个
    }).catch(err => Message.error(err))
  }, [curID])

  useEffect(() => {
    const dataSetType = curRecord.data_set_type
    if (!curID || !dataSetTypeMap[dataSetType]) return

    switch (dataSetTypeMap[dataSetType]) {
      case dataSetTypeMap.es:
        commonApi(getTables, {
          id: curID
        }).then(data => {
          if (_.isEmpty(data) || !_.isArray(data)) return
          const arr = data.filter(i => i.indexOf(curRecord.database_name) !== -1).sort().reverse()
          setDropMenu(arr) // es 取 database_name
          if (isLocation == true) {
            let propsData = props.location.state.data
            setCurDropDownItem(propsData.database_name)
          } else {
            if (arr.length > 1) {
              setCurDropDownItem(arr[1] || '')
            } else {
              setCurDropDownItem(arr[0] || '')
            }
          }
        }).catch(err => Message.error(err))
        break;

      case dataSetTypeMap.mysql:
        commonApi(getTables, {
          id: curID
        }).then(data => {
          if (_.isEmpty(data) || !_.isArray(data)) return
          const arr = data.filter(i => i.indexOf(curRecord.table_name) !== -1).sort().reverse()
          setDropMenu(arr) // mysql 取 table_name
          if (isLocation == true) {
            let propsData = props.location.state.data
            setCurDropDownItem(propsData.database_name)
          } else {
            setCurDropDownItem(arr[1] || '')
          }
        }).catch(err => Message.error(err))
        break;

      default:
        break;
    }
  }, [curID, curRecord.data_set_type])

  const getColumns = useCallback(() => {
    if (!fieldArr.length) return

    const arr = fieldArr.map(i => ({
      title: <>
        <div>{i.field_name}</div>
        <div>{i.field_name_ch}</div>
      </>,
      dataIndex: i.field_name
    }))

    return arr
  }, [fieldArr])


  function getTableCfgFieldsNew() {
    if (!curID) return
    commonApi(getTablecfgFieldsNew, {
      search: JSON.stringify({
        data_set_id: {
          value: curID,
          operator: '='
        },
        ...fieldName?{
          field_name: {
            value: fieldName,
            operator: "like",
            type: "both"
          }
        }:{}
      })
    }).then(data => {
      const items = _.get(data, 'items')
      if (!_.isArray(items)) return
      const arr = items.map(i => {
        i.text = `${i.field_name}（${i.field_name_ch || '-'}）`
        if (fieldArr.some(fieldItemI => fieldItemI.field_name == i.field_name)) {
          i.isSelected = 1
        }
        return i
      })
      setAllCheckList(arr)
      //setCheckedArr(arr.slice(0, 5)) // 需求：进来默认选中前5个
    }).catch(err => Message.error(err))
  }

  function onDropMenuChange(val) {
    isLocation = false
    setCheckedArr([])
    setCurDropDownItem(val)
    setCheckedArr(checkedArr) // 需求：切换menu默认选中已勾选的选项
  }

  // 给个最小高度：盛满屏幕
  const minHeight = useMemo(() => window.innerHeight - 200, [window.innerHeight])

  function onAddItem() {
    const maxIndexID = Math.max(...searchList.map(i => i.indexID))
    setSearchList(r => _.assign([...r, {
      indexID: maxIndexID + 1,
    }]))
  }

  function onDelItem(item) {
    let curArr = _.cloneDeep(searchList)
    curArr = curArr.filter(i => i.indexID !== item.indexID)
    setSearchList(curArr)
  }

  function updateSearchList(item ={}, val = '', indexID, key = 'value') {
    let cloneSearchList = _.cloneDeep(searchList)
    const index = cloneSearchList.findIndex(i => i.indexID === indexID)

    if (index === -1) return
    let keyValue = {[key]: val}
    if (item.field_type != "date" && key != "value" && !item["value"]) {
      keyValue = {
        [key]: val,
        value :[],
      }
    }
    cloneSearchList.splice(index, 1, _.assign({}, {
      ...key === 'key' ? { // 当key切换的时候，只保留indexID（专门用于前端key的）
        indexID,
        field_type: item.field_type,
        equal: item.equal || 'must'
      } : {
        ...item,
        indexID
      },
      // [key]: val,
      ...keyValue,
      field_name: item.field_name
    }))
    setSearchList(cloneSearchList)
  }

  const getJsonSearch = useMemo(() => {
    let searchObj = {}
    console.log("====searchList", searchList)
    searchList.forEach(i => {
      const fieldName = i.field_name
      const fieldType = i.field_type

      if (!fieldName) return

      // 时间格式
      if (TIME_ARR.includes(fieldType)) {
        //const curDate = getRangeDateTime(i.date)
        const curDate = i.date
        if (!_.isArray(curDate) || curDate.length !== 2) return
        searchObj[fieldName] = {
          operator: 'range',
          type: 'must',
          value: {
            gte: curDate[0],
            lt: curDate[1]
          }
        }
      } else if (i.equal == "range") {
        if (!_.isArray(i.value) || i.value.length !== 2) return
        searchObj[fieldName] = {
          operator: 'range',
          type: 'must',
          value: {
            gte: i.value[0],
            lt: i.value[1]
          }
        }
      } else {
        const curEqual = i.equal
        searchObj[fieldName] = {
          operator: 'term',
          type: curEqual,
          value: i.value
        }
      }
    })

    return JSON.stringify(searchObj)
  }, [searchList])

  const getCustomJsonSearch = useMemo(() => {
    let searchObj = {}
    if (_.isArray(searchCustomList.data_time) && searchCustomList["data_time"].length == 2 && !!searchCustomList.field.field_name) {
      searchObj[searchCustomList.field.field_name] = {
        operator: 'range',
        type: 'must',
        value: {
          gte: searchCustomList["data_time"][0],
          lt: searchCustomList["data_time"][1]
        }
      }
    }
    if (!!searchCustomList.selectValue) {
      searchObj[""] = {
        operator: 'query_string',
        type: "must",
        value: searchCustomList.selectValue
      }
    }

    return searchObj
  }, [searchCustomList])

  const fieldIDs = useMemo(() => {
    return fieldArr.map(i => i.field_name).join(',')
  }, [fieldArr])

  // 这里触发查询逻辑
  function handleOnSearch(headers = {}) {
    // console.log('handleOnSearch: ', searchList, checkedArr);
    setIsLoadding(true)
    let params = {
      id: curID,
      t: curDropDownItem,
      field_ids: fieldIDs
    }
    if ( selectMethod == "condition") {
      params["search"] = getJsonSearch
    } else {
      if (!(_.isArray(searchCustomList.data_time) && searchCustomList["data_time"].length == 2 && !!searchCustomList.field.text)) {
        setIsLoadding(false)
        SdwMessage.error("查询范围不能为空")
        return
      }
      params["search"] = getCustomJsonSearch
    }
    let header = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      ...headers
    }
    getItemsNew(params, header)
      .then(res => {
        setIsLoadding(false)
        if (res && res.data && res.data.code === 0) {
          setTableRecord(res.data.data || {})
        } else if (res.data.code == 4005){
          functionName = "handleOnSearch"
          createCaptcha()
        } else {
          Message.error(res.data.msg)
        }
      })
      .catch(() => {
        setIsLoadding(false)
        SdwMessage.error('请求失败')
      })
  }

  function onExportFile() {
    let config = {
      title: '导出明细',
      msg: (
        <div>
          <div>{ `共${tableRecordTotal}条数据，确认导出？` }</div>
          {/* <div style={{ marginTop: 16 }}>注：任务完成后将通过邮件通知</div> */}
        </div>
      )
    }

    if (isTotalHeigh) {
      config.msg = '单次导出不能超过10万条。'
    }

    setDialogConfig(config)
    setVisible(true)
  }

  function changeDialogVisible(bool) {
    setVisible(bool)
  }
  // 确认导出
  function onSureClick(headers = {}) {
    let params = {
      id: curID,
      field_ids: fieldIDs,
      type: selectMethod,
      t: curDropDownItem
    }
    if ( selectMethod == "condition") {
      params.search = getJsonSearch
    } else {
      params.search = getCustomJsonSearch
    }
    let header = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      ...headers
    }
    exportItemsData(params, header)
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          Message.success('任务创建成功')
          changeDialogVisible(false)
        } else if (res.data.code == 4005){
          functionName = "onSureClick"
          createCaptcha()
        } else {
          Message.error(res.data.msg)
        }
      })
      .catch(() => SdwMessage.error('请求失败'))
  }
  // 验证码js加载错误处理
  function loadErrorCallback() {
    var appid = ''
    // 生成票据或自行做其它处理
    var ticket = 'terror_1001_2025699866' + Math.floor(new Date().getTime() / 1000);
    callback({
      ret: 0,
      randstr: '@'+ Math.random().toString(36).substr(2),
      ticket,
      errorCode: 1001,
      errorMessage: 'jsload_error',
    });
  }
  function callback(res) {
    console.log("callback",res.ticket)
    console.log(res.randstr)
    let headers = {
      "x-au-code":res.ticket,
      "x-au-rand":res.randstr,
    }
    console.log("====functionName", functionName)
    if (functionName == "onSureClick") {
      onSureClick(headers)
    } else if (functionName == "handleOnSearch"){
      handleOnSearch(headers)
    }
  }
  function createCaptcha() {
    // 生成一个验证码对象
    try {
      var captcha = new window.TencentCaptcha('2025699866', // 验证码appid
        callback,  // 验证码回调函数
        {  // 验证码options配置参数
          ready: function(){},
          needFeedBack: true
        }
      );
      // 显示验证码
      captcha.show();
    } catch (error) {
      console.log("--====error", error)
      loadErrorCallback();
    }
  }

  function changeFieldList(id) {
    let fieldArrTmp = _.cloneDeep(fieldArr)
    let allCheckListTmp = _.cloneDeep(allCheckList)
    let item = {}
    for (let i in allCheckListTmp) {
      if (allCheckListTmp[i].id == id) {
        if (allCheckListTmp[i].isSelected == 1) {
          allCheckListTmp[i].isSelected = 0
        } else {
          allCheckListTmp[i].isSelected = 1
          item = allCheckListTmp[i]
        }
        break
      }
    }
    let num = fieldArrTmp.length
    for (let j in fieldArrTmp) {
      if (fieldArrTmp[j].id == id) {
        fieldArrTmp.splice(j, 1)
        break
      }
      num --
    }
    if (num == 0) {
      fieldArrTmp.push(item)
    }
    setFieldArr(fieldArrTmp)
    setAllCheckList(allCheckListTmp)
  }

  function clickButton(item) {
    //isLocation = false
    if (curRecord.data_set_type == "mysql") {
      SdwMessage.error("mysql数据源暂不支持自定义查询")
      return
    }
    setSelectMethod(item.type)
  }

  // 过滤出type为date的选项
  const dateTypeMap = useMemo(() => {
    return allFiledArr.filter(i => TIME_ARR.includes(i.field_type))
  }, [allFiledArr])

  // 禁用条件：左侧需要选至少一个展示字段、右上侧必须选时间范围
  const searchBtnDisabled = useMemo(() => {
    return !(fieldArr.length > 0)
  }, [fieldArr])
  function changeSearch(type, value) {
    let searchCustomListClone = _.cloneDeep(searchCustomList)
    searchCustomListClone[type] = value
    setSearchCustomList(searchCustomListClone)
  }
  console.log("=====searchList", searchList)
  console.log("=====allCheckList", allCheckList)

  const curMenuIndex = props.location.state.curMenuIndex

  return (
    <>
      <div>
        <BreadCrumbs
          history={props.history}
          data={[
            {
              title: '数据加油站',
              path: '/admin/dataGasStation/dataSearch'
            }, {
              title: '明细查询',
              path: curMenuIndex == 1 ? '/admin/dataGasStation/dataSearch?tab=Record' : '/admin/dataGasStation/dataSearch'
            }, {
              title: '查询导出'
            }
          ]}
        />
      </div>

      {
        code !== "" ? (
          isApplyAuth === false ?
            (
              code === 0 ?
                <Loadding
                  loadding={isLoadding}
                >
                  <div className='data-search__search-export__wrap'>
                    {/* 左侧 */}
                    <div
                      className='data-search__search-export__wrap-left'
                      style={{
                        minHeight
                      }}
                    >
                      <div className='data-search__search-export__wrap-left__drop-menu'>
                        <div>
                          选择查询字段
                          <span style={{color: "#999999"}}>（默认选择5个）</span>
                        </div>
                      </div>

                      <div style={{marginTop: 8, marginLeft: 14, marginRight: 14}}>
                        <Select
                          value={[]}
                          width="100%"
                          placeholder={"选择查询字段"}
                          onInputFilter={value => {
                            if (value.length > 0) {
                              setFieldName(value)
                              getTableCfgFieldsNew()
                            }
                          }}
                          isMultipleChoice={true}
                          readOnly={false}
                          data={allCheckList}
                          onChange={item =>  {
                            changeFieldList(item[0].id)
                          }}
                        />
                      </div>

                      <div style={{height: minHeight - 140}}
                           className="data-search__search-export__main">
                        {
                          _.isArray(fieldArr) &&
                          fieldArr.length > 0 ?
                          <>
                            {
                              fieldArr.map(i => (
                                <div key={i.id} className='data-search__search-export__wrap-left__select-item'>
                                  <div>
                                    {i.text}
                                  </div>
                                 <div className={"data-search__search-export__del_icon"}>
                                   <img
                                     src={delImg}
                                     style={{ cursor: 'pointer', height: 16 }}
                                     onClick={() => changeFieldList(i.id)}
                                   />
                                 </div>
                                </div>
                              ))
                            }
                          </> : <>
                                <div className="data-search__search-export__text">
                                  {allCheckList.length === 0 ? "暂无数据" : "请选择查询字段"}
                                </div>
                              </>
                        }
                      </div>
                    </div>

                    {/* 右侧 */}
                    <div
                      className='data-search__search-export__wrap-right'
                      style={{
                        minHeight
                      }}
                    >
                      <div className='opr-wrap-select'>
                        <div className="opr-left-wrap">
                            <div>
                              <DropDown
                                menu={dropMenu}
                                listMaxWidth={600}
                                value={curDropDownItem}
                                changeValue={val => onDropMenuChange(val)}
                              />
                            </div>
                        </div>
                        <div className='opr-right-wrap'>
                          {
                            clickButtonMap.map(i => {
                              return <div key={i.type} onClick={()=>clickButton(i)} className={selectMethod == i.type ? i.className + " selected-clicked" :i.className}>
                                {i.text}
                              </div>
                            })
                          }
                        </div>
                      </div>
                      <div className='opr-wrap'>
                        <div className='opr-left-wrap'>
                          {
                             selectMethod == "condition" ?
                            // selectMethod == "1111" ?
                            // 搜索查询条件，默认有一个时间过滤条件
                              (_.isArray(searchList) &&
                            searchList.length > 0 &&
                            searchList.map((item, index) => {
                              console.log("======item", item)
                              return <>
                                <div key={"condition"+item.indexID}>
                                  <div className='opr-list-wrap'>
                                    <Select
                                      value={item.key}
                                      width={240}
                                      readOnly={false}
                                      placeholder={"请选择过滤字段"}
                                      data={allFiledArr}
                                      onChange={(val, obj) => updateSearchList(obj, val, item.indexID, 'key')}
                                    />
                                    {
                                      item.key &&
                                      <>
                                        {
                                          TIME_ARR.includes(item.field_type) ?
                                            <>
                                              {/* date类型 */}
                                              <DateTimePicker
                                                showDateTimeRangePicker={true} // true: 表示选择日期范围   fasle或不配置: 表示选择日期点
                                                value={item.date}  // 绑定的value值：范围是数组：['2020-01-01', '2020-02-08']
                                                changeValue={val => updateSearchList(item, val, item.indexID, 'date')} // 改变value的函数
                                              />
                                            </> :
                                            <>
                                              {/* 非date类型 */}
                                              <Select
                                                value={item.equal}
                                                width={240}
                                                clearable={false}
                                                data={[{
                                                  id: 'must',
                                                  text: '值等于'
                                                }, {
                                                  id: 'must_not',
                                                  text: '值不等于'
                                                }, {
                                                  id: 'range',
                                                  text: '范围'
                                                }]}
                                                onChange={val => updateSearchList(item, val, item.indexID, 'equal')}
                                              />
                                              {
                                                item.equal == "range" ?
                                                  <>
                                                    <Input
                                                      value={(_.isArray(item.value) && item.value.length > 0) ? item.value[0] : ""}
                                                      width={240}
                                                      placeholder='最小值'
                                                      onChange={val => {
                                                        let value = ["", ""]
                                                        if (_.isArray(item.value)) {
                                                          value = item.value
                                                        }
                                                        value[0] = val
                                                        updateSearchList(item, value, item.indexID)
                                                      }}
                                                    />
                                                    <Input
                                                      value={(_.isArray(item.value) && item.value.length ==2 )  ? item.value[1] : ""}
                                                      width={240}
                                                      placeholder='最大值'
                                                      onChange={val => {
                                                        let value = ["", ""]
                                                        if (_.isArray(item.value)) {
                                                          value = item.value
                                                        }
                                                        value[1] = val
                                                        updateSearchList(item, value, item.indexID)
                                                      }}
                                                    />
                                                  </>
                                                  :
                                                  <>
                                                    <Input
                                                      value={item.value.join(";")}
                                                      width={240}
                                                      placeholder='多个请用分号(;)隔开'
                                                      onChange={val => {
                                                        updateSearchList(item, val.split(";"), item.indexID)
                                                      }}
                                                    />
                                                  </>
                                              }
                                            </>
                                        }
                                      </>
                                    }
                                  </div>
                                  <i
                                    className='el-icon-plus'
                                    onClick={onAddItem}
                                  />
                                  {
                                    item.indexID !== 0 &&
                                    <i
                                      className='el-icon-minus'
                                      onClick={() => onDelItem(item)}
                                    />
                                  }
                                </div>
                              </>
                            })
                              ):
                               <>
                                 <div className='opr-list-wrap' style={{display:"flex"}}>
                                   <div className="label-text">
                                     查询范围
                                   </div>
                                   <Select
                                     value={searchCustomList.field.id}
                                     width={246}
                                     readOnly={false}
                                     placeholder={"请选择时间字段"}
                                     data={dateTypeMap}
                                     onChange={(val, obj) => {
                                       changeSearch("field", obj)
                                     }}
                                   />
                                   <DateTimePicker
                                     showDateTimeRangePicker={true} // true: 表示选择日期范围   fasle或不配置: 表示选择日期点
                                     //placeholder='请选择时间范围'
                                     width='100%'
                                     value={searchCustomList.data_time}  // 绑定的value值：范围是数组：['2020-01-01', '2020-02-08']
                                     changeValue={val => changeSearch("data_time", val)} // 改变value的函数
                                   />
                                 </div>
                                 <div className='opr-list-wrap' style={{display:"flex"}}>
                                   <div className="label-text">
                                     查询语句
                                   </div>
                                   <Input
                                     type="textarea"
                                     width={600}
                                     placeholder="请输入Lucene查询语法"
                                     value={searchCustomList.selectValue}
                                     onChange={val => changeSearch("selectValue", val)}
                                   />
                                 </div>
                               </>
                          }
                        </div>
                        <div className='opr-right-wrap'>
                          <Button
                            text='查询明细'
                            type='submit'
                            tip={searchBtnDisabled ? '请先选择查询字段' : ''}
                            disabled={searchBtnDisabled}
                            onClick={handleOnSearch}
                            iconClass={'el-icon-search'}
                          />
                        </div>
                      </div>

                      <div className='export-opr-wrap'>
                        <span>{`共${tableRecordTotal}条，展示前200条`}</span>
                        <span style={{marginLeft: 16}}>
                          <Button
                            style={!tableRecordTotal ? {color: "#A8BEF9"} : {color: "#265CF0"}}
                            text='导出明细'
                            tip={!tableRecordTotal ? '暂无导出记录' : ''}
                            disabled={!tableRecordTotal}
                            iconClass='iconfont icon-download'
                            onClick={onExportFile}
                          />
                        </span>
                      </div>
                      <div className='export-opr-table'>
                        <Table
                            tableHeight={selectMethod == "condition" ? minHeight - 200 : minHeight - 280}
                          columns={getColumns()}
                          dataSource={_.isArray(_.get(tableRecord, 'page')) ? _.get(tableRecord, 'page') : []}
                        />
                      </div>
                    </div>
                  </div>
                </Loadding>
                :
                  <>
                    <Empty innerWidth="600px" icon="noAuth" text={<AuthText showAuthApply={bool => {
                      setCode("")
                      showAuthApply(bool)
                    }}  code={code}/>}/>
                  </>
            ) :
              <>
                <div className="table-detail-index__tabs_wrap">
                  <AuthApply loginName={props.userInfo.ChineseName} showAuthApply={bool => {
                    setCode("")
                    showAuthApply(bool)
                  }} id={curID} />
                </div>
              </>
        ) : ""
      }


      <Dialog
        title={dialogConfig.title}
        width={500}
        visible={visible}
        changeVisible={changeDialogVisible}
        onCancelClick={() => changeDialogVisible(false)}
        onSureClick={()=>onSureClick()}
        sureBtnDisabled={isTotalHeigh}
        hideCancelButton={true}
      >
        <div>
          {dialogConfig.msg}
        </div>
      </Dialog>
    </>
  )
}

const mapState = state => ({
  userInfo: state.user.userInfo,
})

export default connect(mapState, function() {

})(SearchEport)
