import produce from 'immer'

const state = {
  topicInfo: {}, // 存放主题域
  topic: [], // 字段与接口对应: 主题域
  business: [], // 字段与接口对应: 业务
  standardType: [], // 字段与接口对应: 类型
}

const updateTopicInfo = produce((state, payload) => {
  state.topicInfo = payload
})

const updateTopic = produce((state, payload) => {
  state.topic = payload
})

const updateBusiness = produce((state, payload) => {
  state.business = payload
})

const updateStandardType = produce((state, payload) => {
  state.standardType = payload
})

const reducers = {
  updateTopicInfo,
  updateTopic,
  updateBusiness,
  updateStandardType,
}

export default { state, reducers }
