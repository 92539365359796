import { useState, useEffect } from "react"
import { standardItemSelects } from './api'
import { commonApi } from "@utils/request"
import Message from '@common/Message'

export default function UseStandardItemSelects(props) {

  const [record, setRecord] = useState({})
  
  useEffect(() => {
    commonApi(standardItemSelects, {}).then(data => {
      setRecord(data)
    }).catch(err => Message.error(err))
  }, [])

  return record
}