import React from 'react'
import _ from 'lodash'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import WarnStrategy from './WarnStrategy'
import SelectAddInputGroup from './SelectAddInputGroup'
import SdwMessage from '@common/Message'
import { getAlarmCfgList, getTablecfgFields, getStaffs, updateAlarmConf, insertAlarmConf, getAlarmItemSelects, alarmBusinessItemSelects, channelItemSelects } from '@services/alertRules'
import { formatJsonParse } from '@utils/formatJson'
import { formatTime } from '@utils/formatTime'
import { DatePicker } from 'element-react'

import { isCheck } from "./util/validate";
import { MODULE_ID_OPTIONS,SEND_TYPE_OPTIONS, DEFAULT_ITEM_OBJ,
  STRATEGY_TYPE_MAP, TIME_MAP, Frequency_TIME_MAP, FIXED_VALUE,
  FIXED_VALUE_LESS, OPR_TYPE_MAP, MONITOR_TYPE_MAP
} from "./common";

import './style/edit-control-win.css'

const PERSON_OBJ = {
  decorate: '',
  related_field: '',
  user_type: ''
}

const DAY = 'day'

export default class EditControlWin extends React.Component {

  state = {
    formData:{
      alarm_rule_name: '', // 规则名称
      module: '', //所属模块
      label: '', //标签
      relatedIndicators: [], //关联指标
      jump_url:"",//跳转链接
      alarm_title: '', // 标题
      alarm_content: '', // 推送文案
      fk_id: '', // 数据源
      alarm_field: '', // 预警字段
      target_name_ch: '', // 字段转义
      date_field: '', // 时间字段
      filter_str: '', // 过滤条件
      exec_cycle: '', // 监控频率
      monitor_freq_unit: DAY,
      show_time:"",//时间跨度
      show_time_unit: DAY,
      recently_monitor: '', // 监控最近
      recently_monitor_unit: DAY,
      push_limit_time: '', // 告警频率
      alert_freq_unit: DAY,
      push_limit_plot: '', // 告警频率--每策略
      enable: 0, // 是否可用
      is_task: 0,  // 创建任务

      send_type: '', // 推送方式
      monitor_type: '', // 监控类型
      dimension_field: '', // 预警维度
      dimension_value: '', // 维度值
      dimension_value_name: '', // 维度转义
      comment: '', // 备注
      start_time: '%Y年%m月%d日 %H:%M:%S',
      end_time: '%Y年%m月%d日 %H:%M:%S',
      schedule_time: '', // 起始时间
      delay_time: '', // 延迟时间
      delay_time_unit: ''
    },
    currentAlarmData: {},
    warnStrategyList: [
      [DEFAULT_ITEM_OBJ]
    ],
    handlerList: [
      PERSON_OBJ
    ],
    copyMenList: [
      PERSON_OBJ
    ],

    tablecfgFields: [],
    allMembers: [],
    sholdCheckValidate: false,
    dataSourceOptions: [],
    alarmItemSelection: {},
    alarmBusinessItems: [],
    channelSelection: []
  }

  componentDidMount() {
    this.initData()
    this.setState({
      dataSourceOptions: this.props.dataSourceOptions
    })
  }

  componentDidUpdate (prevProps, prevState) {

    if (!_.isEqual(prevState.formData.fk_id, this.state.formData.fk_id)) {
      this.onGetTablecfgFields()
    }

    if (!_.isEqual(prevState.formData.owner_business, this.state.formData.owner_business)) {
      this.getAlarmBusinessItemSelects(this.state.formData.owner_business)
    }
  }

  initData = () => {

    if (this.props.isEdit && !_.isEqual(this.props.currentAlarmID, '')) {
      this.getAlarmCfgList(this.props.currentAlarmID)
    }

    getStaffs().then(res => {
      if (res && res.data && res.data.code === 0) {
        let list = _.isArray(res.data.data) ? res.data.data : []
        this.setState({
          allMembers: list
        })
      }
    })

    // 获取所属业务下拉选项
    getAlarmItemSelects().then(res => {
      let code = _.get(res, 'data.code')
      let data = _.get(res, 'data.data')
      if (code === 0) {
        this.setState({
          alarmItemSelection: data
        })
      }
    })

    // 获取所属聚到
    channelItemSelects().then(res => {
      let code = _.get(res, 'data.code')
      if (code === 0) {
        let data = _.get(res, 'data.data.channel')
        if (_.isArray(data)) {
          data = data.map(i =>({
            id: i.value,
            text: i.name
          }))
        }
        // console.log('channelSelection: ', data);
        this.setState({
          channelSelection: data
        })
      }
    })
  }

  // 获取分支业务
  getAlarmBusinessItemSelects = val => {
    alarmBusinessItemSelects({
      type: val == 'other' ? '' : val
    }).then(res => {
      let code = _.get(res, 'data.code')
      let data = _.get(res, 'data.data')
      if (code === 0) {
        let op = []
        if (_.isArray(data)) {
          op = data.map(i => ({
            ...i,
            id: i.service_name,
            text: i.service_name
          }))
        }
        this.setState({
          alarmBusinessItems: op
        })
      }
    })
  }

  onGetTablecfgFields = () => {
    getTablecfgFields({
      id: this.state.formData.fk_id
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let list = _.isArray(res.data.data) ? res.data.data : []
          this.setState({
            tablecfgFields: list
          })
        }
      })
  }

  initFormData = (record,execCycleArr,gettimer,show_time_range,pushLimitTimeArr,delay_timeArr) => {
    let formatJson = formatJsonParse(record.format_json, {})
    delete(record["modify_time"])
    delete(record["modify_user"])
    console.log("====init,formatJson", record)
    let isCopyOprType = this.props.oprType === OPR_TYPE_MAP.copy

    let obj = {
      ...record,
      exec_cycle: execCycleArr[0] || '', // 监控频率
      monitor_freq_unit: execCycleArr[1] || '',
      recently_monitor: gettimer[0], // 监控最近
      recently_monitor_unit: gettimer[1],
      show_time: show_time_range[0], // 时间跨度
      show_time_unit: show_time_range[1],
      push_limit_time: pushLimitTimeArr[0] || '', // 告警频率
      alert_freq_unit: pushLimitTimeArr[1] || '',
      delay_time: delay_timeArr[0] || '', // 延迟时间
      delay_time_unit: delay_timeArr[1] || '',
      start_time: formatJson.start_time || '',
      end_time: formatJson.end_time || '',
      enable: isCopyOprType ? 0 : record.enable,
      is_task: isCopyOprType ? 0 : record.is_task
    }

    return obj
  }
  getAlarmCfgList = id => {
    getAlarmCfgList({
      search: JSON.stringify({
        id: {
          value: id,
          operator: '='
        }
      })
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let record = _.isArray(res.data.data && res.data.data.items) ? res.data.data.items[0] : {}
          let handlerList = formatJsonParse(record.deal_users, [PERSON_OBJ])
          let copyMenList = formatJsonParse(record.duplicate_users, [PERSON_OBJ])
          let warnStrategyList = formatJsonParse(record.alarm_rules_json, [])
          let execCycleArr = !!record.exec_cycle ? record.exec_cycle.split('/') : []
          let pushLimitTimeArr = !!record.push_limit_time ? record.push_limit_time.split('/') : []
          let delay_timeArr = !!record.delay_time ? record.delay_time.split('/') : []
          let gettimer = !!record.gettimer ? record.gettimer.split('/') : []
          let show_time_range = !!record.show_time_range ? record.show_time_range.split('/') : []

          let newWarnStrategyList = warnStrategyList.reduce((prev, arr) => {
            if (_.isArray(arr)) {
              arr = arr.map(i => {
                if (i.rule_name === FIXED_VALUE && i.operator === 'lt') {
                  return _.assign({}, i, {
                    rule_name: FIXED_VALUE_LESS
                  })
                } else {
                  return i
                }
              })
              prev.push(arr)
            }

            return prev
          }, [])

          let formData =  this.initFormData(record,execCycleArr,gettimer,show_time_range,pushLimitTimeArr,delay_timeArr)

          this.setState({
            formData,
            currentAlarmData: record,
            warnStrategyList: newWarnStrategyList, // 预警策略
            handlerList: handlerList.map(i => {
              return {
                decorate: i.decorate,
                related_field: i.related_field,
                user_type: i.user_type
              }
            }), // 处理人
            copyMenList: copyMenList.map(i => {
              return {
                decorate: i.decorate,
                related_field: i.related_field,
                user_type: i.user_type
              }
            }), // 抄送人
          })
        }
      })
  }

  validateFun = val => {
    if (!!val) {
      return true
    }
    return '该输入项不能为空'
  }
  validateIntegerFun = val => {
    if(/-/.test(val)){
      return '请输入正数'
    }
    if (!!val) {
      return true
    }
    return '该输入项不能为空'
  }
  validateWarnFieldFun = val => {
    if (Array.isArray(val.ids) && val.ids.length === 2) {
      return true
    }
    return '该输入项不能为空'
  }

  addStrategy = () => {
    this.setState({
      sholdCheckValidate: false,
      warnStrategyList: [...this.state.warnStrategyList, [DEFAULT_ITEM_OBJ]]
    })
  }

  removeStrategy = index => {
    let arr = _.cloneDeep(this.state.warnStrategyList)
    arr.splice(index, 1)

    this.setState({
      warnStrategyList: arr
    })
  }

  updateWarnStrategyList = (firstIndex, type, secondIndex, obj) => {

    // 手动触发check检查，置为false
    this.resetCheckValidate()

    let { warnStrategyList } = this.state

    // check
    if (!warnStrategyList[firstIndex]) return

    let curWarnStrategy = _.cloneDeep(warnStrategyList)

    // add
    if (type === STRATEGY_TYPE_MAP.add) {
      curWarnStrategy[firstIndex].push(DEFAULT_ITEM_OBJ)
      this.setState({
        warnStrategyList: curWarnStrategy
      })
      return
    }

    // del
    if (type === STRATEGY_TYPE_MAP.del) {
      curWarnStrategy[firstIndex].splice(secondIndex, 1)
      this.setState({
        warnStrategyList: curWarnStrategy
      })
      return
    }

    // update
    if (type === STRATEGY_TYPE_MAP.update && !_.isEmpty(obj)) {
      curWarnStrategy[firstIndex].splice(secondIndex, 1, obj)
      this.setState({
        warnStrategyList: curWarnStrategy
      })
    }
  }

  updateHanderList = (index, type, obj) => {

    // 手动触发check检查，置为false
    this.resetCheckValidate()

    let { handlerList } = this.state
    let arr = _.cloneDeep(handlerList)

    // add
    if (type === STRATEGY_TYPE_MAP.add) {
      arr.push(PERSON_OBJ)
      this.setState({
        handlerList: arr
      })
      return
    }

    // del
    if (type === STRATEGY_TYPE_MAP.del) {
      arr.splice(index, 1)
      this.setState({
        handlerList: arr
      })
      return
    }

    // update
    if (type === STRATEGY_TYPE_MAP.update && !_.isEmpty(obj)) {
      arr.splice(index, 1, obj)
      this.setState({
        handlerList: arr
      })
    }
  }

  updateCopyMenList = (index, type, obj) => {
    let { copyMenList } = this.state
    let arr = _.cloneDeep(copyMenList)

    // add
    if (type === STRATEGY_TYPE_MAP.add) {
      arr.push(PERSON_OBJ)
      this.setState({
        copyMenList: arr
      })
      return
    }

    // del
    if (type === STRATEGY_TYPE_MAP.del) {
      arr.splice(index, 1)
      this.setState({
        copyMenList: arr
      })
      return
    }

    // update
    if (type === STRATEGY_TYPE_MAP.update && !_.isEmpty(obj)) {
      arr.splice(index, 1, obj)
      this.setState({
        copyMenList: arr
      })
    }
  }

  resetCheckValidate = (flag = false) => {
    this.setState({
      sholdCheckValidate: flag
    })
  }
  checkIsPassValidate = (formData ,warnStrategyList, handlerList) => {
    let notNull =  [ val => val !== '' ]
    let notEmptyRule =  [ val => _.isString(val) && !!val.trim()]
    let monitorTypeValidate =  [ val => !!val ] // 接口的0也标识空
    let isPostiveNumberRule =  [ val => _.isString(val) && !!val.trim() && !/-/.test(val) ]
    let isNumberRule =  [ val => _.isNumber(val) ]
    let rulers = {
      alarm_rule_name: notEmptyRule, // 规则名称
      module: notEmptyRule, //所属模块
      dimension_field: notEmptyRule, // 预警维度

      ...formData.module == '6' ? {
        tendency: notEmptyRule, // 时序指标
        recover_point: notNull, // 恢复点
        alarm_repeat_point: notNull, // 二次预警
      } : {},

      alarm_title: notEmptyRule, // 标题
      alarm_content: notEmptyRule, // 标题
      fk_id: isNumberRule, // 数据源
      alarm_field: notEmptyRule, // 预警字段
      date_field: notEmptyRule, // 时间字段
      exec_cycle: isPostiveNumberRule, // 监控频率
      monitor_freq_unit: notEmptyRule,
      show_time: notEmptyRule, // 时间跨度
      show_time_unit: notEmptyRule,
      recently_monitor: notEmptyRule, // 监控最近
      recently_monitor_unit: notEmptyRule,
      push_limit_time: isPostiveNumberRule, // 告警频率
      alert_freq_unit: notEmptyRule,
      push_limit_plot: isPostiveNumberRule, // 告警频率--每策略
      enable: isNumberRule, // 是否可用
      send_type: notEmptyRule, // 推送方式
      monitor_type: monitorTypeValidate, // 监控类型
    }
     // 处理人是一维数组，进行pass判断
    let handlerListPass = !handlerList.some(i => {
      return i.user_type === 'input' ? !i.related_field : (!i.related_field || !i.decorate)
    })
    // 预警策略是二维数组，进行pass判断
    let warnStrategyListPass = !warnStrategyList.some(list => list.some(i => {
      if (i.rule_name === FIXED_VALUE) {

        // 固定值只需要有最小值
        return !i.left_value || !i.data_cycle
      } else if (i.rule_name === FIXED_VALUE_LESS) {

        // 固定值只需要有最大值
        return !i.right_value || !i.data_cycle
      } else {
        if( /-/.test(i.data_cycle)) return true
        // 突增突降需要填写后面的两项
        return !i.data_cycle || !i.operator
      }
    }))
    return isCheck(rulers, formData) && handlerListPass && warnStrategyListPass
  }
  setErrorMsg = (errName, val) => {
    this.setState({[errName]:val})
  }
  dealBeforeRequetParam = (formData,warnStrategyList,handlerList,duplicateUsers) => {
    let newWarnStrategyList = warnStrategyList.reduce((prev, arr) => {
      if (_.isArray(arr)) {
        arr = arr.map(i => {
          let name = i.rule_name
          if (name === FIXED_VALUE) {
            return _.assign({}, i, {
              operator: 'gt'
            })
          } else if (name === FIXED_VALUE_LESS) {
            return _.assign({}, i, {
              rule_name: FIXED_VALUE,
              operator: 'lt'
            })
          } else {
            return i
          }
        })
        prev.push(arr)
      }

      return prev
    }, [])
    formData.alarm_rules_json = JSON.stringify(newWarnStrategyList)
    formData.exec_cycle = `${formData.exec_cycle}/${formData.monitor_freq_unit}`
    formData.gettimer = `${formData.recently_monitor}/${formData.recently_monitor_unit}`
    formData.show_time_range = `${formData.show_time}/${formData.show_time_unit}`
    formData.push_limit_time = `${formData.push_limit_time}/${formData.alert_freq_unit}`
    formData.delay_time = `${formData.delay_time}/${formData.delay_time_unit}`
    formData.deal_users = JSON.stringify(handlerList)
    formData.duplicate_users = duplicateUsers

    // // 固定参数：schedule_time、format_json
    // let nextMin = formatTime(+new Date() + 60 * 1000, 0) // 该字段为时间字段，内容为用户提交时间+1分钟
    // formData.schedule_time = nextMin
    formData.format_json = JSON.stringify({
      start_time: formData.start_time,
      end_time: formData.end_time
    })

    // 如果是复制按钮逻辑：不需要下发id
    if (this.props.oprType === OPR_TYPE_MAP.copy) delete formData.id
    return formData
  }
  handleSubmit = () => {

    let {formData, copyMenList ,warnStrategyList, handlerList, } = this.state
    let { isEdit, currentAlarmID, oprType } = this.props

    console.log('---formData: ', formData);

    // 复制按钮，逻辑走新增接口
    let isUpdate = (isEdit && oprType === OPR_TYPE_MAP.edit)

    let isValidatePass = this.checkIsPassValidate(formData ,warnStrategyList, handlerList)
    if (!isValidatePass) {
      // 手动触发check检查
      this.resetCheckValidate(true)
      return
    }

    let duplicateUsers = (copyMenList[0] && !!copyMenList[0].user_type) ? JSON.stringify(copyMenList) : null
    let params = this.dealBeforeRequetParam(formData,warnStrategyList,handlerList,duplicateUsers)
    console.log("====", params)
    console.log("====", params.target_name_ch)
    let api = isUpdate ? updateAlarmConf : insertAlarmConf
    api({
      ...isUpdate ? {
        id: currentAlarmID
      } : {},
      field: params
    }).then(res => {

      if (res && res.data && res.data.code === 0) {
        let msg = isEdit ? '编辑规则成功' : '新增规则成功'
        SdwMessage.success(msg)
        this.props.changeVisibleFun(false)
        this.props.getAlarmCfgList()
      } else {
        let msg = isEdit ? '编辑规则失败' : '新增规则失败'
        SdwMessage.error(msg)
      }
    })
      .catch(() => SdwMessage.error("接口异常"))
  }

  setFormDataProp = (name,value) => {

    if (name === 'schedule_time') {
      value = formatTime(+new Date(value), 0)
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    })
  }

  render() {
    let {
      warnStrategyList,
      handlerList,
      copyMenList,
      sholdCheckValidate,
      tablecfgFields,
      allMembers,
      dataSourceOptions,
      formData,
      alarmItemSelection,
      alarmBusinessItems,
      channelSelection
    } = this.state

    let hasnoFkId = !formData.fk_id

    let warnDementionOptions = tablecfgFields.map(i => {
      return {
        id: i.field_name,
        text: !!i.field_name_ch ? `${i.field_name}(${i.field_name_ch})` : i.field_name
      }
    })

    // 嘉濠定的规则：只展示 2160 的项
    let relatedOption = tablecfgFields.filter(j => j.field_ref_standard == 2160).map(i => {
      return {
        id: i.field_name,
        text: !!i.field_name_ch ? `${i.field_name}(${i.field_name_ch})` : i.field_name
      }
    })

    let businessOption = _.isArray(alarmItemSelection.bussiness) ? alarmItemSelection.bussiness.map(i => {
      return {
        id: i.value,
        text: i.name
      }
    }) : []

    return (
      <div style={{ minWidth: 860 }}>
        <div className="control-win__display-wrap">
          <span className="control-win__title-label required">规则名称</span>
          <SdwInput
            value={formData.alarm_rule_name}
            placeholder="请输入规则名称"
            validateFun={this.validateFun}
            sholdCheckValidate={sholdCheckValidate}
            onChange={val => this.setFormDataProp('alarm_rule_name' , val)} />
        </div>

        <div className="control-win__display-wrap">
          <span className="control-win__title-label required">推送文案</span>
          <SdwInput
            value={formData.alarm_content}
            placeholder="请输入推送文案"
            validateFun={this.validateFun}
            sholdCheckValidate={sholdCheckValidate}
            onChange={val => this.setFormDataProp('alarm_content' , val)} />
        </div>

        <div className="control-win__display-wrap">
          <span className="control-win__title-label required">展示标题</span>
          <SdwInput
            value={formData.alarm_title}
            placeholder="请输入展示标题"
            validateFun={this.validateFun}
            sholdCheckValidate={sholdCheckValidate}
            onChange={val => this.setFormDataProp('alarm_title' , val)} />
        </div>

        <div className="control-win__display-wrap">
          <div className="control-win__half-width">
            <span className="control-win__title-label required">所属模块</span>
            <SdwSelect
              value={formData.module}
              width={'60%'}
              listWidth={'100%'}
              clearable={false}
              placeholder="请选择所属模块"
              validateFun={this.validateFun}
              sholdCheckValidate={sholdCheckValidate}
              data={MODULE_ID_OPTIONS}
              onChange={val => this.setFormDataProp('module' , val)}
               />
          </div>
          <div className="control-win__half-width">
            <span className="control-win__title-label required">推送方式</span>
            <SdwSelect
              value={formData.send_type}
              width={'60%'}
              listWidth={'100%'}
              placeholder="请选择推送类型"
              clearable={false}
              validateFun={this.validateFun}
              sholdCheckValidate={sholdCheckValidate}
              data={SEND_TYPE_OPTIONS}
              onChange={val => this.setFormDataProp('send_type' , val)} />
          </div>
        </div>

        <div className="control-win__display-wrap">
          <div className="control-win__half-width">
            <span className="control-win__title-label">所属业务</span>
            <SdwSelect
              value={formData.owner_business}
              width={'60%'}
              listWidth={'100%'}
              placeholder="请选择所属业务"
              data={businessOption}
              onChange={val => {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    owner_business: val,
                    branch_business: ''
                  }
                })
                this.getAlarmBusinessItemSelects(val)
              }} />
          </div>
          <div className="control-win__half-width">
            <span className="control-win__title-label">分支业务</span>
              <SdwSelect
                value={formData.branch_business}
                readOnly={false}
                width={'60%'}
                listWidth={'100%'}
                disabled={!formData.owner_business}
                placeholder="请选择分支业务"
                data={alarmBusinessItems}
                onChange={val => this.setFormDataProp('branch_business' , val)} />
          </div>
        </div>

        <div className="control-win__display-wrap">
          <div className="control-win__half-width">
            <span className="control-win__title-label required">监控类型</span>
              <SdwSelect
                value={formData.monitor_type}
                width={'60%'}
                listWidth={'100%'}
                placeholder="请选择监控类型"
                data={MONITOR_TYPE_MAP}
                validateFun={this.validateFun}
                sholdCheckValidate={sholdCheckValidate}
                onChange={val => this.setFormDataProp('monitor_type' , val)} />
          </div>
          <div className="control-win__half-width">
            <span className="control-win__title-label">所属渠道</span>
            <SdwSelect
              value={formData.channel}
              width={'60%'}
              listWidth={'100%'}
              placeholder="请选择所属渠道"
              data={channelSelection}
              onChange={val => this.setFormDataProp('channel' , val)} />
          </div>
        </div>

        <div className="control-win__display-wrap">
          <span className="control-win__title-label">标签</span>
          <SdwInput
            value={formData.label}
            placeholder="多个标签用;隔开"
            onChange={val => this.setFormDataProp('label' , val)} />
        </div>

        <div className="control-win__display-wrap">
          <span className="control-win__title-label required">数据源</span>
          <SdwSelect
            value={formData.fk_id}
            placeholder="请选择数据源"
            clearable={false}
            readOnly={false}
            validateFun={this.validateFun}
            sholdCheckValidate={sholdCheckValidate}
            data={dataSourceOptions}
            onChange={val => this.setState({
              formData: {
                ...this.state.formData,
                fk_id: val,
                alarm_field: '',
                dimension_field: '',
                date_field: ''
              }
            })} />
        </div>
        <div className="control-win__display-wrap">
          <div className="control-win__half-width">
            <span className="control-win__title-label required">预警字段</span>
            <SdwSelect
              value={formData.alarm_field}
              disabled={hasnoFkId}
              placeholder={hasnoFkId ? '请先选择数据源' : '请选择预警字段'}
              readOnly={false}
              clearable={false}
              width={'60%'}
              listWidth={'100%'}
              validateFun={this.validateFun}
              sholdCheckValidate={sholdCheckValidate}
              data={warnDementionOptions}
              onChange={val => this.setState({
                formData: {
                  ...this.state.formData,
                  alarm_field: val
                }
              })} />
          </div>
          <div className="control-win__half-width">
            <span className="control-win__title-label">字段转义</span>
            <SdwInput
              value={formData.target_name_ch}
              width={'60%'}
              placeholder="请输入字段转义"
              onChange={val => this.setFormDataProp('target_name_ch' , val)} />
          </div>
        </div>
        <div className="control-win__display-wrap">
          <div className="control-win__half-width">
            <span className="control-win__title-label required">预警维度</span>
            <SdwSelect
              width={'60%'}
              listWidth={'100%'}
              value={formData.dimension_field}
              disabled={hasnoFkId}
              placeholder={hasnoFkId ? '请先选择数据源' : '请选择预警维度'}
              readOnly={false}
              clearable={false}
              validateFun={this.validateFun}
              sholdCheckValidate={sholdCheckValidate}
              data={ warnDementionOptions }
              onChange={val => this.setFormDataProp('dimension_field' , val)} />
          </div>
          <div className="control-win__half-width">
            <span className="control-win__title-label required">时间字段</span>
            <SdwSelect
              width={'60%'}
              listWidth={'100%'}
              value={formData.date_field}
              disabled={hasnoFkId}
              placeholder={hasnoFkId ? '请先选择数据源' : '请选择时间字段'}
              clearable={false}
              validateFun={this.validateFun}
              sholdCheckValidate={sholdCheckValidate}
              data={tablecfgFields.filter(k => k.field_type === 'date').map(i => {
                return {
                  id: i.field_name,
                  text: !!i.field_name_ch ? `${i.field_name}(${i.field_name_ch})` : i.field_name
                }
              })}
              onChange={val => this.setFormDataProp('date_field' , val)} />
          </div>
        </div>

        <div className="control-win__display-wrap">
          <div className="control-win__half-width">
            <span className="control-win__title-label">维度值</span>
            <SdwInput
              value={formData.dimension_value}
              width={'60%'}
              placeholder="请输入维度值"
              onChange={val => this.setFormDataProp('dimension_value' , val)} />
          </div>
          <div className="control-win__half-width">
            <span className="control-win__title-label">维度转义</span>
            <SdwInput
              value={formData.dimension_value_name}
              width={'60%'}
              placeholder="请输入维度转义"
              onChange={val => this.setFormDataProp('dimension_value_name' , val)} />
          </div>
        </div>

        <div className="control-win__display-wrap">
          <div className="control-win__half-width">
            <span className="control-win__title-label">维度单位</span>
            <SdwInput
              value={formData.dimension_value_unit}
              width={'60%'}
              placeholder="请输入维度单位"
              onChange={val => this.setFormDataProp('dimension_value_unit' , val)} />
          </div>
        </div>

        <div className="control-win__display-wrap" style={{ marginBottom: 16 }}>
          <span
            className="control-win__title-label"
          >过滤条件</span>
          <SdwInput
            type='textarea'
            value={formData.filter_str}
            placeholder="请输入过滤条件语句（lucene语法），如：count:1 OR type:2"
            onChange={val => this.setFormDataProp('filter_str' , val)} />
        </div>

        {/* <div className="control-win__display-wrap">
          <span className="control-win__title-label">关联指标</span>
          <SdwSelect
            isMultipleChoice={true}
            disabled={hasnoFkId}
            placeholder={hasnoFkId? '请先选择数据源' : '请选择关联指标'}
            clearable={false}
            value={formData.relatedIndicators}
            data={ warnDementionOptions }
            onChange={val => this.setFormDataProp('relatedIndicators' , val)}
             />
        </div> */}

        {
          warnStrategyList.map((strategy, index) => (
            <div key={index} className="control-win__display-wrap" style={{ marginTop: 20 }}>
              <span className="control-win__title-label warn-strategy">
                {
                  !!index > 0 &&
                  <i onClick={() => this.removeStrategy(index)} className="el-icon-minus"></i>
                }
                <span className="required">{`预警策略${index + 1}`}</span>
              </span>
              <WarnStrategy
                strategyData={strategy}
                firstIndex={index}
                sholdCheckValidate={sholdCheckValidate}
                updateWarnStrategyList={this.updateWarnStrategyList}
              />
            </div>
          ))
        }

        <div className="control-win__display-wrap" style={{ marginTop: 0 }}>
          <span
            className="control-win__title-label add-strategy"
            onClick={this.addStrategy}
          >添加策略</span>
        </div>

        {/* 模块选择时序算法 */}
        {
          formData.module == '6' &&
          <>
            <div className="control-win__display-wrap" style={{ marginTop: 0 }}>
              <span className="control-win__title-label required">时序指标</span>
              <SdwSelect
                value={formData.tendency}
                placeholder='请选择'
                clearable={false}
                validateFun={this.validateFun}
                sholdCheckValidate={sholdCheckValidate}
                data={[
                  {
                    id: 'up',
                    text: '上涨'
                  }, {
                    id: 'down',
                    text: '下跌'
                  }
                ]}
                onChange={val => this.setFormDataProp('tendency' , val)}
              />
            </div>
            <div className="control-win__display-wrap">
              <div className="control-win__half-width">
                <span className="control-win__title-label required">恢复点</span>
                <span>
                  <SdwInput
                    label='连续'
                    width={160}
                    value={formData.recover_point}
                    validateFun={val => val !== '' || '该输入项不能为空'}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setFormDataProp('recover_point' , val)}
                  />
                  <span className='control-win__title-label__unit'>个</span>
                </span>
              </div>

              <div className="control-win__half-width">
                <span className="control-win__title-label required">二次预警</span>
                <span>
                  <SdwInput
                    label='连续'
                    width={160}
                    value={formData.alarm_repeat_point}
                    validateFun={val => val !== '' || '该输入项不能为空'}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setFormDataProp('alarm_repeat_point' , val)}
                  />
                  <span className='control-win__title-label__unit'>个</span>
                </span>
              </div>
            </div>
          </>
        }

        <div className="control-win__display-wrap">
          <span className="control-win__title-label">起始时间</span>
          <DatePicker
            value={formData.schedule_time ? new Date(formData.schedule_time) : ''}
            isShowTime={true}
            isReadOnly={true}
            rangeSeparator=" 至 "
            placeholder="选择日期范围"
            format="yyyy-MM-dd HH:mm:ss"
            align="right"
            onChange={date => this.setFormDataProp('schedule_time' , date)}
          />
        </div>

        <div className="control-win__display-wrap cycle_time">

          <div className="control-win__half-width">
            <span className="control-win__title-label required">监控频率</span>
            <SdwInput
              width={100}
              value={formData.exec_cycle}
              validateFun={this.validateIntegerFun}
              sholdCheckValidate={sholdCheckValidate}
              onChange={val => this.setFormDataProp('exec_cycle' , val)}
            />
            <span style={{ marginLeft: 10, marginRight: 10 }}>
              <SdwSelect
                value={formData.monitor_freq_unit}
                width={100}
                clearable={false}
                validateFun={this.validateFun}
                sholdCheckValidate={sholdCheckValidate}
                data={Frequency_TIME_MAP}
                onChange={val => this.setFormDataProp('monitor_freq_unit' , val)}
              />
            </span>
          </div>

          <div className="control-win__half-width">
            <span className="control-win__title-label required">监控周期</span>
            <SdwInput
              label='最近'
              width={100}
              value={formData.recently_monitor}
              validateFun={this.validateFun}
              sholdCheckValidate={sholdCheckValidate}
              onChange={val => this.setFormDataProp('recently_monitor' , val)}
            />
            <span style={{ marginLeft: 10 }}>
              <SdwSelect
                value={formData.recently_monitor_unit}
                width={100}
                clearable={false}
                validateFun={this.validateFun}
                sholdCheckValidate={sholdCheckValidate}
                data={TIME_MAP}
                onChange={val => this.setFormDataProp('recently_monitor_unit' , val)}
              />
            </span>
          </div>
        </div>

        <div className="control-win__display-wrap">
          <div className="control-win__half-width">
            <span className="control-win__title-label">延迟时间</span>
            <SdwInput
              width={100}
              type='number'
              value={formData.delay_time}
              onChange={val => this.setFormDataProp('delay_time' , val)}
            />
            <span style={{ marginLeft: 10 }}>
            <SdwSelect
              value={formData.delay_time_unit}
              width={100}
              clearable={false}
              data={TIME_MAP}
              onChange={val => this.setFormDataProp('delay_time_unit' , val)}
            />
          </span>
          </div>

          <div className="control-win__half-width">
            <span className="control-win__title-label required">展示时间跨度</span>
            <SdwInput
              width={100}
              type='number'
              validateFun={this.validateIntegerFun}
              sholdCheckValidate={sholdCheckValidate}
              value={formData.show_time}
              onChange={val => this.setFormDataProp('show_time' , val)}
            />
            <span style={{ marginLeft: 10 }}>
              <SdwSelect
                value={formData.show_time_unit}
                width={100}
                clearable={false}
                validateFun={this.validateFun}
                sholdCheckValidate={sholdCheckValidate}
                data={TIME_MAP}
                onChange={val => this.setFormDataProp('show_time_unit' , val)}
              />
            </span>
          </div>

        </div>
        <div className="control-win__display-wrap cycle_time">
          <span className="control-win__title-label">展示时间格式</span>
          <SdwInput
            width={300}
            value={formData.start_time}
            placeholder='%Y年%m月%d日 %H:%M:%S'
            onChange={val => this.setFormDataProp('start_time' , val)}
          />
          <span style={{margin:"0px 10px"}}>-</span>
          <SdwInput
            width={300}
            value={formData.end_time}
            placeholder='%Y年%m月%d日 %H:%M:%S'
            onChange={val => this.setFormDataProp('end_time' , val)}
          />
        </div>
        <div className="control-win__display-wrap cycle_time">
          <span className="control-win__title-label required">告警频率</span>
          <SdwInput
            width={100}
            value={formData.push_limit_time}
            validateFun={this.validateIntegerFun}
            sholdCheckValidate={sholdCheckValidate}
            onChange={val => this.setFormDataProp('push_limit_time' , val)}
          />
          <span style={{ marginLeft: 10 }}>
            <SdwSelect
              value={formData.alert_freq_unit}
              width={100}
              clearable={false}
              validateFun={this.validateFun}
              sholdCheckValidate={sholdCheckValidate}
              data={Frequency_TIME_MAP}
              onChange={val => this.setFormDataProp('alert_freq_unit' , val)}
            />
          </span>

          <span style={{ marginLeft: 10 }}>
            <SdwInput
              label='每策略'
              width={160}
              value={formData.push_limit_plot}
              validateFun={this.validateIntegerFun}
              sholdCheckValidate={sholdCheckValidate}
              onChange={val => this.setFormDataProp('push_limit_plot' , val)}
            />
            <span className='control-win__title-label__unit'>次</span>
          </span>
        </div>
        <div className="control-win__display-wrap">
          <span className="control-win__title-label">跳转链接</span>
          <SdwInput
            value={formData.jump_url}
            placeholder="不填写默认打开预警详情页"
            onChange={val => this.setFormDataProp('jump_url' , val)} />
        </div>


        <div className="control-win__display-wrap">
          <span className="control-win__title-label required">处理人</span>
          <SelectAddInputGroup
            handlerList={handlerList}
            allMembers={allMembers}
            relatedOption={relatedOption}
            sholdCheckValidate={sholdCheckValidate}
            updateHanderList={this.updateHanderList}
          />
        </div>

        <div className="control-win__display-wrap">
          <span className="control-win__title-label">抄送人</span>
          <SelectAddInputGroup
            handlerList={copyMenList}
            allMembers={allMembers}
            relatedOption={relatedOption}
            isEmpty={true}
            updateHanderList={this.updateCopyMenList}
          />
        </div>

        <div className="control-win__display-wrap">
          <span className="control-win__title-label">备注</span>
          <SdwInput
            value={formData.comment}
            placeholder="请输入备注"
            onChange={val => this.setFormDataProp('comment' , val)} />
        </div>

        <div className="control-win__display-wrap required">
          <span className="control-win__title-label">是否可用</span>
          <SdwSwitch
              value={formData.enable}
              changeValue={val => this.setFormDataProp('enable' , val)}
          />
        </div>

        <div className="control-win__display-wrap required">
          <span className="control-win__title-label">创建任务</span>
          <SdwSwitch
              value={formData.is_task}
              changeValue={val => this.setFormDataProp('is_task' , val)}
          />
        </div>
      </div>
    )
  }
}
