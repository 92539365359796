import { request } from '../utils/request'
import { ajaxPost } from "./AjaxApi";
import { requestOptions } from '../config/config'

const baseURL = requestOptions.basename

// 获取主题列表
export const getTopicBusiness = (params = {}) => {
  return request({ url: '/dataset/common/getTopicBusiness', method: 'get', data: params })
}

// 获取所有的表
export const getTablecfgList = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgList', method: 'get', params: params })
}

// 获取该表的所有的字段
export const getTablecfgFields = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgFields', method: 'get', params: params })
}

// 获取该表的所有的字段
export const getTablecfgDetail = (params = {}) => {
  return request({ url: '/metadata/tableApi/getTablecfgDetail', method: 'get', params: params })
}

// 获取分页数据
export const getPage = (params = {}) => {
  return request({ url: '/dataset/modifier/getModifierList', method: 'get', params: params })
}

// 获取所有的计算方式
export const getPublicCfg = (params = {}) => {
  return request({ url: '/dataset/common/getPublicCfg', method: 'get', data: params })
}

/**
 *  修改数据
 * @param {object} params
 * @param {func} callback
 */
export function upData (params, callback) {
  return ajaxPost(baseURL + "/dataset/modifier/update", params, callback);
}

/**
 *  添加数据
 * @param {object} params
 * @param {func} callback
 */
export function addData (params, callback) {
  return ajaxPost(baseURL + "/dataset/modifier/store", params, callback);
}

// 获取预判数据
export const getTablecfgpreview = (params = {}) => {
  return request({ url: '/metadata/tableApi/getItems', method: 'get', params: params })
}

// 获取某个数据数据
export const getModifierList = (params = {}) => {
  return request({ url: '/dataset/modifier/getModifierList', method: 'get', params: params })
}