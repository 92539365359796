import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Steps from '@common/Steps'
import BasicConfiguration from './BasicConfiguration'
import GroupingConfiguration from './GroupingConfiguration'
import Staffing from './Staffing'
import { getTargetGroupDetail, saveTargetsGroup } from '@services/keyIndicatorAllocation'
import SdwMessage from '@common/Message'

function StepWin(props) {
    const [sholdCheckValidate, setSholdCheckValidate] = useState(false)
    useEffect(() => {
        if (props.isEdit) {
            getTargetGroupDetail({
                id:props.record.id
            })
              .then(res => {
                  if (res && res.data && res.data.code === 0) {
                      let record = res.data.data || {}
                      let subscribe_info_dict = record.subscribe_info_dict || {}
                      let item = {
                          "group_name": record.group_name,
                          "remark": record.remark,
                          "two_group": record.two_group,
                          "three_group":  record.three_group,
                          "relate_module": record.relate_module ,
                          "enabled":  record.enabled,
                          "visible_list": record.visible_list || [],
                          "focused_list": record.focused_list || [],
                          "subscribe_info_dict": {
                              "real_time_change": subscribe_info_dict.real_time_change || [],
                              "change_day": subscribe_info_dict.change_day || [],
                              "once_day": subscribe_info_dict.once_day || [],
                              "change_week": subscribe_info_dict.change_week || [],
                              "once_week": subscribe_info_dict.once_week || [],
                              "change_month": subscribe_info_dict.change_month || [],
                              "once_month": subscribe_info_dict.once_month || []
                          }
                      }
                      props.updateFormData(item)
                  }
              })
        } else {
            let item = {
                group_name: "",
                remark: "",
                two_group: "",
                three_group: "",
                relate_module: "",
                enabled: 1,
                visible_list: [],
                focused_list: [],
                subscribe_info_dict: {
                    real_time_change: [],
                    change_day: [],
                    once_day: [],
                    change_week: [],
                    once_week: [],
                    change_month: [],
                    once_month: []
                },
            }
            setSholdCheckValidate(false)
            props.updateFormData(item)
        }
    }, props.record)
    const {
        showSteps, isEdit, hideStepsFunc, record, updatePage,
        formData
    } = props

    function saveSubmit() {
        let {formData} = props
        let flag = ruleFormData(formData)
        console.log("saveSubmit============formData", formData)
        console.log("saveSubmit============flag", flag)
        if (!flag) {
            setSholdCheckValidate(true)
            return
        }
        let params = {
            data:{...formData}
        }
        let msg = "新增成功"
        if (props.isEdit) {
            params.id = props.record.id
            msg = "编辑成功"
        }
        saveTargetsGroup({
            ...params
        })
          .then(res => {
              if (res && res.data && res.data.code === 0) {
                  props.updatePage()
                  props.hideStepsFunc(false)
                  SdwMessage.success(msg)
              } else {
                  SdwMessage.error(res.data.msg)
              }
          })
    }

    function ruleFormData (formData) {
        if (!formData.group_name || formData.group_name.length > 12) {
            return false
        }
        if (formData.two_group) {
            let num = JSON.parse(formData.two_group).filter(i => (i.name == "" || i.name.length > 8)).length
            console.log("JSON.parse(formData.two_group)", JSON.parse(formData.two_group))
            if (num > 0) {
                return false
            }
        }
        if (formData.three_group) {
            let num = JSON.parse(formData.three_group).filter(i => i.name == "" || i.name.length > 4).length
            if (num > 0) {
                return false
            }
        }
        if (formData.three_group) {
            let num = JSON.parse(formData.three_group).filter(i => i.name == "" || i.name.length > 4).length
            if (num > 0) {
                return false
            }
        }
        return true
    }


    const stepMenu = [
        [
            {
                stepNumber: 'one',
                title: '基础配置',
                component: <BasicConfiguration sholdCheckValidate={sholdCheckValidate} {...props} />,
            },
            {
                stepNumber: 'two',
                title: '分组配置',
                component: <GroupingConfiguration sholdCheckValidate={sholdCheckValidate} {...props} />,
            },
            {
                stepNumber: 'three',
                title: '人员配置',
                component: <Staffing saveSubmit={saveSubmit} {...props} />,
            }
        ]
    ]

    return (
        <>
            {
                showSteps &&
                <Steps
                    {...props}
                    stepMenu={stepMenu}
                    breadMenu={[
                        {
                            title: '智慧运营台',
                            path: '/admin/monitor/monitorManagement'
                        }, {
                            title: '关键指标配置',
                            goBack: true // 返回上一级页面
                        }, {
                            title: isEdit ? '编辑指标组' : '新增指标组'
                        }
                    ]}
                />
            }
        </>
    )
}

const mapState = state => ({
    formData: state.keyIndicatorAllocation.formData
})

const mapDispatch = dispatch => ({
    updateFormData: dispatch.keyIndicatorAllocation.updateFormData,
})

export default connect(mapState, mapDispatch)(StepWin)
