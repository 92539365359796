import produce from 'immer'

const state = {
  pageMap: {
    main: 'main',
    logSecondPage: 'log-second-page'
  },
  formData: {}, // 数据接入--日志，表单
  tableData: [], // 数据接入--日志，table
  currentPage: 'main', // 数据接入--日志，当前主界面显示内容区
}

const updateFormData = produce((state, payload) => {
  state.formData = payload
})

const updateTableData = produce((state, payload) => {
  state.tableData = payload
})

const updateCurrentPage = produce((state, payload) => {
  state.currentPage = payload
})

const reducers = {
  updateFormData,
  updateTableData,
  updateCurrentPage
}

export default { state, reducers }
