import _ from 'lodash'
import switchRoutes from './switchRoutes'
import DataSearch from '@pages/DataGasStation/DataSearch'
import SearchEport from '@pages/DataGasStation/DataSearch/SearchEport'
import UserSearch from '@pages/Monitor/UserSearch'
import DisplayTraceDetail from '@pages/Monitor/UserSearch/DisplayTraceDetail'
import DataVisualization from '@pages/DataGasStation/DataVisualization'

export const routerPath = '/admin/dataGasStation'

export const bar = {
  rootUrl: routerPath,
  activeClassName: 'current',
  className: 'yzf-nav-item',
  name: '数据加油站',
  children: [
    {
      icon: '',
      title: '明细查询',
      path: `${routerPath}/dataSearch`
    },
    // {
    //   icon: '',
    //   title: '数据导出',
    //   path: `/html/metadata/exportList`,
    //   gotoPathState: 1,
    // },
    // {
    //   icon: '',
    //   title: '工单查询',
    //   path: `https://samquery.cm.com/yzf/task/home`,
    //   gotoPathState: 1,
    // }, {
    //   icon: '',
    //   title: '工单统计',
    //   path: `http://kfdata.oa.com/#/web/archiveStats`,
    //   gotoPathState: 1,
    // },
    {
      icon: '',
      title: '用户查询',
      path: `${routerPath}/userSearch`,
    }, {
      icon: '',
      title: '数据可视化',
      path: `${routerPath}/dataVisualization/${0}`,
    }
  ],
  order: 4
}

const pages = {
  datasearch: {
    icon: '',
    title: '明细查询',
    path: `${routerPath}/datasearch`,
    component: DataSearch,
  },
  searchEport: {
    icon: '',
    title: '明细查询界面',
    path: `${routerPath}/searchEport/:id`,
    component: SearchEport,
  },
  // Map: {
  //    icon: 'icon-278',
  //    title: '数据导出',
  //    path: `${routerPath}/portal`,
  //    component: Map,
  // },
  UserSearch: {
    icon: 'icon-278',
    title: '用户查询',
    path: `${routerPath}/userSearch`,
    component: UserSearch
  },
  UserSearchDetail: {
    icon: 'icon-278',
    title: '用户查询详情',
    path: `${routerPath}/userSearchDetail`,
    component: DisplayTraceDetail
  },
  DataVisual: {
    icon: 'icon-278',
    title: '数据可视化',
    path: `${routerPath}/dataVisualization/:id`,
    component: DataVisualization
  }
}

export const menus = [
  // {
  //   icon: 'icon-64',
  //   title: '知识菜单',
  //   path: `${routerPath}/monitor`,
  //   children: [pages.knowledgeLearning, pages.learningGroup],
  // },
  // {
  //   icon: 'icon-132',
  //   title: '考试菜单',
  //   path: `${routerPath}/dialogue`,
  //   children: [pages.orderShare, pages.testList],
  // },
]

export const Router = switchRoutes({ routes: _.values(pages) })
