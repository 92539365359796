import { useState, useEffect } from 'react'
import SdwDialog from '@common/Dialog'
import SdwLayout from '@common/Layout'
import SdwTable from '@common/Table'
import { DatePicker } from 'element-react'
import { formatTime } from '@utils/formatTime'

import './style/over-view.css'

export default function OverViewWin(props) {

  const [visibal, setVisibal] = useState(props.showOverViewWin)
  const [datePicker, setDatePicker] = useState(new Date())
  const [dataList, setDataList] = useState([
    {
      "date": "161.57",
      "value": "18.8"
    }
  ])
  const [dataList2, setDataList2] = useState([
    {
      "date": "2021-01-14 15:15:00",
      "value": "66"
    }, {
      "date": "2021-01-14 15:15:00",
      "value": "77"
    }
  ])

  useEffect(() => {
    props.changeVisible(visibal)
  }, [visibal])
  
  useEffect(() => {
    console.log('currentAlarmID: ', props.currentAlarmID);
  }, [props.currentAlarmID])
  
  useEffect(() => {
    let time = formatTime(+new Date(datePicker), 0)
    console.log('time: ', time);
  }, [datePicker])

  function getcolumns() {
    return [
      {
        title: "均值",
        dataIndex: "date",
      }, {
        title: "3倍标准差",
        dataIndex: "value",
      }
    ]
  }

  function getcolumns2() {
    return [
      {
        title: "日期",
        dataIndex: "date",
      }, {
        title: "数据值",
        dataIndex: "value",
      }
    ]
  }

  return (
    <SdwDialog
      title={`预警预览（${props.currentAlarmID}）`}
      width={800}
      visible={visibal}
      changeVisible={setVisibal}
      onCancelClick={() => setVisibal(false)}
      onSureClick={() => setVisibal(false)}
    >
      <div>
        <DatePicker
          isShowTime
          value={datePicker}
          isReadOnly={true}
          placeholder="选择日期"
          format="yyyy-MM-dd HH:mm:ss"
          align="right"
          // disabledDate={time=>time.getTime() > Date.now()}
          onChange={setDatePicker}
        />
      </div>

      <div className="warn-rules__overview-wrap">
        <SdwLayout.Row>
          <SdwLayout.Col span={12}>
            <span className="label">标题</span>
            <span>xxx</span>
          </SdwLayout.Col>
          <SdwLayout.Col span={12}>
            <span className="label">标签</span>
            <span>xxx</span>
          </SdwLayout.Col>
        </SdwLayout.Row>

        <SdwLayout.Row>
          <SdwLayout.Col span={12}>
            <span className="label">当前值</span>
            <span>xxx</span>
          </SdwLayout.Col>
          <SdwLayout.Col span={12}>
            <span className="label">异动情况</span>
            <span>xxx</span>
          </SdwLayout.Col>
        </SdwLayout.Row>

        <SdwLayout.Row>
          <SdwLayout.Col span={12}>
            <span className="label">预警时间</span>
            <span>xxx</span>
          </SdwLayout.Col>
          <SdwLayout.Col span={12}>
            <span className="label">目标值</span>
            <span>xxx</span>
          </SdwLayout.Col>
        </SdwLayout.Row>
      </div>

      <div>
        <SdwTable
          tbodyMinHeight={100}
          columns={getcolumns2()}
          dataSource={dataList2}
        />
      </div>

      <div>
        <SdwTable
          tbodyMinHeight={50}
          columns={getcolumns()}
          dataSource={dataList}
        />
      </div>
    </SdwDialog>
  )
}