import React from 'react'
import SdwEmpty from '@common/Empty'

function NoRight(props) {
  return (
    <SdwEmpty
      text='暂无权限，请联系@kurtgu申请权限'
      height={props.height || window.innerHeight}
      innerWidth={300}
      icon='noAuth'
    />
  )
}

export default NoRight
