import { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import SdwInput from '@common/Input2'
import SdwTextarea from '@common/Textarea'
import SdwRadiobox from '@common/Radiobox'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import SdwTable from '@common/Table'
import Dialog from '@common/Dialog'
import SdwMessage from '@common/Message/index'
import { getTableApiItems, getLogCollectConfigDetail, setLogCollectConfig } from '@services/dataAccess'

const Default_form_data = {
  log_topic: 'tdproxy',
  white_ip_list: '',
  regmatchKeywords: '',
  parse_type: 1,
  separator: '',
  example: '',
  encodeFlag: 0,
  store_table_id: ''
}

const LOG_TOPIC_MAP = [{
  id: 'teg_cs_b_teg_cs_user_profile_block_new',
  text: 'teg_cs_b_teg_cs_user_profile_block_new',
}, {
  id: 'teg_cs_teg_cs_separate_normal_new',
  text: 'teg_cs_teg_cs_separate_normal_new',
}, {
  id: 'tdproxy',
  text: 'tdproxy',
}]

let oriTableData = [] // 存储原始的字段定义数据

function DataLog(props) {

  const [formData, setFormData] = useState(Default_form_data)
  const [tableData, setTableData] = useState([])
  const [targetTableList, setTargetTableList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [showConfirmWin, setShowConfirmWin] = useState(false)

  useEffect(() => {
    const isEdit = _.get(props, 'location.state.isEdit')
    setIsEdit(isEdit)
    const id = _.get(props, 'location.state.data.id')
    if (isEdit && id) { // 编辑：数据回显
      getLogCollectConfigDetail({
        id
      }).then(res => {
        const code = _.get(res, 'data.code')
        const msg = _.get(res, 'data.msg')
        if (code === 0) {
          const configInfo = _.get(res, 'data.data.configInfo')
          const tableColumnList = _.get(res, 'data.data.tableColumnList')

          if (!_.isEmpty(configInfo)) setFormData(configInfo)
          if (!_.isEmpty(tableColumnList)) {
            const list = tableColumnList.map((i, index) => _.assign({}, i, {index: index+1}))
            oriTableData = list
            setTableData(list)
          }
        } else {
          SdwMessage.error(msg || '获取配置详情数据失败！')
        }
      }).catch(err => SdwMessage.error('接口异常' + err))
    }
  }, [])

  useEffect(() => {

    // 目标表参数
    let paramData = {
      limit: 10000,
      search: JSON.stringify({
        data_set_type: {
          value: 'es',
          operator: '='
        },
        warehouse_level: {
          value: 'ODS',
          operator: '='
        },
        enable: {
          value: '1',
          operator: '='
        }
      })
    }

    // 目标表
    getTableApiItems(paramData).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const items = _.get(res, 'data.data.items')
        let curArr = _.isArray(items) ? items : []
        curArr = curArr.map(i => {
          let curName = !!i.database_name ? `${i.database_name}.${i.table_name}` : `${i.table_name}`
          return {
            id: i.id,
            text: `${curName}（${i.data_set_chinese}）`,
            store_table_name_ch: i.data_set_chinese,
            store_table_name_en: `${i.database_name}.${i.table_name}`,
            topic: i.topic,
            business: i.business,
            data_set_type: i.data_set_type
          }
        })
        setTargetTableList(curArr)
      } else {
        SdwMessage.error('获取目标表数据失败')
      }
    })
      .catch(error => {
        SdwMessage.error('获取目标表接口异常：' + error)
      })
  }, [])

  useEffect(() => {
    const { formData, tableData } = props
    if (!_.isEmpty(formData)) setFormData(formData)
    if (!_.isEmpty(tableData)) setTableData(tableData)
  }, [])

  // 下一步按钮是否禁用
  const can_next = useMemo(() => {
    return isEdit ?
    !!formData.example && formData.encodeFlag !== '' && !!tableData.length :
     _.values(formData).every(val => val !== '') && !!tableData.length
  }, [formData, tableData])

  function updateTableData(key, val, index) {
    let cloneTableData = _.cloneDeep(tableData)
    cloneTableData[index][key] = val
    setTableData(cloneTableData)
  }

  function getDisabled(i, data) {
    return isEdit && i.index === data.index && i.column_name === data.column_name
  }

  function getcolumns() {
    return [
      {
        title: "序号",
        dataIndex: "index",
        width: 50
      },
      isEdit ? {
        title: "日志字段示例",
        width: 150,
        render: (data, index) => (
          <SdwInput
            placeholder='请输入日志字段示例'
            value={data.column_example}
            // disabled={oriTableData.some(i => getDisabled(i, data))}
            onChange={val => updateTableData('column_example', val, index)}
          />
        )
      } : {
        title: "日志字段示例",
        dataIndex: "column_example",
        width: 150
      }, {
        title: "字段ID",
        width: 150,
        render: (data, index) => (
          <SdwInput
            placeholder='请输入字段ID'
            // disabled={oriTableData.some(i => getDisabled(i, data))}
            value={data.column_name}
            onChange={val => updateTableData('column_name', val, index)}
          />
        )
      }, {
        title: "字段数据类型",
        width: 150,
        render: (data, index) => (
          <SdwSelect
            placeholder='请选择字段数据类型'
            // disabled={oriTableData.some(i => getDisabled(i, data))}
            value={data.column_type}
            onChange={val => updateTableData('column_type', val, index)}
            data={[
              {
                id: 'string',
                text: 'string'
              }, {
                id: 'date',
                text: 'date'
              }, {
                id: 'int',
                text: 'int'
              }, {
                id: 'double',
                text: 'double'
              }
            ]}
          />
        )
      }, {
        title: "数据长度",
        width: 150,
        render: (data, index) => (
          <SdwInput
            width={200}
            type='number'
            // disabled={oriTableData.some(i => getDisabled(i, data))}
            placeholder='请输入字段长度限制'
            value={data.column_length}
            onChange={val => updateTableData('column_length', val, index)}
          />
        )
      },
      ...isEdit ? [{
        title: "操作",
        width: 50,
        render: (data, index) => (
          <SdwButton
            disabled={oriTableData.some(i => getDisabled(i, data))}
            text={oriTableData.some(i => getDisabled(i, data)) ? '-' : '删除'}
            onClick={() => onDelClick(data)}
          />
        )
      }] : []
    ]
  }

  function onDelClick(data) {
    const delIndex = tableData.findIndex(i => i.index === data.index)
    let cloneTableData = _.cloneDeep(tableData)
    if (delIndex > -1) {
      cloneTableData.splice(delIndex, 1)
      setTableData(cloneTableData)
    }
  }

  function updateFormData(key, val) {
    let cloneFormData = _.cloneDeep(formData)
    cloneFormData[key] = val
    if (cloneFormData.parse_type === 2) delete cloneFormData.separator
    setFormData(cloneFormData)
  }

  function handGetConfig() {
    const { example, separator, parse_type } = formData
    let list = []
    if (!example) return

    switch (parse_type) {
      case 1:
        if (!separator || String(example).indexOf(separator) === -1) {
          list = [{ column_example: example, index: 1, column_name: '', column_type: '', column_length: 10 }]
        } else {
          let arr = String(example).split(separator)
          if (_.isArray(arr)) list = arr.map((i, j) => ({ column_example: i, index: j+1, column_name: '', column_type: '', column_length: 10 }))
        }
        break;

      case 2:
        let curExample = example
        if (String(example).substr(0, 4) == 'msg=') {
          curExample = example.substr(4)
        } else {
          SdwMessage.error('键值对解析必须以：msg= 开始')
          return
        }
        if (String(curExample).indexOf('=') === -1) {
          list = [{ column_example: '', index: 1, column_name: curExample, column_type: '', column_length: 10 }]
        } else {
          let arr = String(curExample).split('&')
          if (_.isArray(arr)) {
            arr.forEach((item, j) => {
              const curlist = item.split('=')
              list.push({ column_example: curlist[1] || '', index: j+1, column_name: curlist[0], column_type: '', column_length: 10 })
            })
          }
        }
        break;

      default:
        break;
    }

    // 只有分隔符的需要给前三项默认值
    if (parse_type === 1) {
      list = list.map((i, index) => {
        let res = _.cloneDeep(i)
        switch (index) {
          case 0:
            res.column_type = 'string'
            res.column_name = 'log_head'
            res.column_length = 50
            break;

          case 1:
            res.column_type = 'string'
            res.column_name = 'log_module'
            res.column_length = 50
            break;

          case 2:
            res.column_type = 'date'
            res.column_name = 'log_time'
            break;

          default:
            break;
        }
        return res
      })
    }

    setTableData(list)
  }

  function handAddConfig() {
    let cloneTableData = _.cloneDeep(tableData)
    cloneTableData.push({
      column_example: '',
      index: tableData.length+1,
      column_name: '',
      column_type: '',
      column_length: 10,
      store_table_id: formData.store_table_id,
      table_type: formData.data_set_type
    })
    setTableData(cloneTableData)
  }

  function getValiidate() {
    let uniqColumnName = []
    if (_.isArray(tableData)) {
      uniqColumnName = [...new Set(tableData.map(i => i.column_name))]
    }

    let bool = uniqColumnName.length === tableData.length

    return bool
  }

  function handleNext() {
    if (isEdit) {
      setShowConfirmWin(true)
    } else {
      onConfirmSubmit()
    }
  }

  // 保存数据，成功跳转下一步
  function onConfirmSubmit() {
    if (isEdit) {
      setShowConfirmWin(false)
    }

    // 校验：字段ID不能重复
    const isPass = getValiidate()
    if (!isPass) return SdwMessage.error('字段ID不能重复！')

    // 有id既编辑；无id既新增
    const id = _.get(props, 'location.state.data.id')

    let curFormData =  _.assign({}, formData)
    let curTableData = _.assign([], tableData)

    // 新增添加扩展属性：task_type\task_status\store_table_id\table_type
    if (!id) {
      curFormData =  _.assign({
        ...id ? {id} : {},
        task_type: 0,
        task_status: 0
      }, formData)
      curTableData = tableData.map(i => ({
        ...i,
        store_table_id: formData.store_table_id,
        table_type: formData.data_set_type
      }))
    }

    setLogCollectConfig({
      data: curFormData,
      columns: curTableData
    }).then(res => {
      const code = _.get(res, 'data.code')
      const id = _.get(res, 'data.data')
      if (code === 0 && id) {
        SdwMessage.success('保存成功！')

        // 将数据存储到redux中，其他页面下发使用
        props.updateFormData(_.assign({}, curFormData, {id}))
        props.updateTableData(curTableData)
        props.updateCurrentPage(props.pageMap.logSecondPage)
      } else {
        const msg = _.get(res, 'data.msg') || '保存失败！'
        SdwMessage.error(msg)
      }
    }).catch(err => SdwMessage.error('接口异常' + err))
  }

  return (
    <>
      <div className="data-access__body">
        <span className="number-title-wrap">
          <span className="number-title__two"></span>
          <span className="config-title">内容存储配置</span>
        </span>
        <div className="main-body">

          <div className="label-title-wrap">
            <i className="label-title-divider" />
            <span className="label-title">解析存储配置</span>
            {/* <span className="label-title-des">请保障信息准确，避免同步出错</span> */}
          </div>
          <div className="label-title-wrap">
            <span className="label required">日志topic：</span>
            {/* <SdwInput
              width={440}
              placeholder="请输入日志topic"
              // disabled={isEdit}
              value={formData.log_topic}
              onChange={val => updateFormData('log_topic', val)}
            /> */}

            <SdwSelect
              data={LOG_TOPIC_MAP}
              width={440}
              // disabled={isEdit}
              readOnly={false}
              placeholder="请选择日志topic"
              value={formData.log_topic}
              onChange={val => updateFormData('log_topic', val)}
            />
          </div>
          <div className="label-title-wrap">
            <span className="label required">访问IP白名单：</span>
            <SdwInput
              width={440}
              // disabled={isEdit}
              placeholder="可访问该节点的IP清单，多个用,隔开"
              value={formData.white_ip_list}
              onChange={val => updateFormData('white_ip_list', val)}
            />
          </div>
          <div className="label-title-wrap">
            <span className="label required">日志头部：</span>
            <SdwInput
              width={440}
              // disabled={isEdit}
              placeholder="请输入解析日志头部标识"
              value={formData.regmatchKeywords}
              onChange={val => updateFormData('regmatchKeywords', val)}
            />
          </div>
          <div className="label-title-wrap">
            <span className="label required">解析标识：</span>
            <SdwRadiobox
              // disabled={isEdit ? [1,2] : []}
              options={[{
                text: '分隔符解析',
                value: 1
              }, {
                text: '键值对(key_value)解析',
                value: 2
              }]}
              value={formData.parse_type}
              changeCheckedFun={val => updateFormData('parse_type', val) }
            />
          </div>
          {
            formData.parse_type === 1 &&
            <div className="label-title-wrap">
              <span className="label required">分隔符：</span>
              <SdwInput
                width={440}
                // disabled={isEdit}
                placeholder="请输入分隔符"
                value={formData.separator}
                onChange={val => updateFormData('separator', val)}
              />
            </div>
          }
          <div className="label-title-wrap">
            <span className="label required">日志内容示例：</span>
            <SdwTextarea
              width={440}
              rows={6}
              placeholder="请输入日志内容示例"
              value={formData.example}
              onChange={val => updateFormData('example', val)}
            />
          </div>
          <div className="label-title-wrap">
            <span className="label required">是否转码：</span>
            <SdwRadiobox
              options={[{
                text: '否',
                value: 0
              }, {
                text: '是',
                value: 1
              }]}
              value={formData.encodeFlag}
              changeCheckedFun={val => updateFormData('encodeFlag', val)}
            />
          </div>
          <div className="label-title-wrap">
            <span className="label required">落地数据库：</span>
            <SdwSelect
              data={targetTableList}
              width={440}
              // disabled={isEdit}
              readOnly={false}
              placeholder="请选择落地数据库"
              value={+formData.store_table_id}
              onChange={(val, item) => {
                setFormData(r => _.assign({}, r, {
                  store_table_id: val,
                  store_table_name_ch: item.store_table_name_ch,
                  store_table_name_en: item.store_table_name_en,
                  topic: item.topic,
                  business: item.business,
                  data_set_type: item.data_set_type
                }))
              }}
            />
          </div>

          <div className="label-title-wrap">
            <i className="label-title-divider" />
            <span className="label-title">字段内容定义</span>
            {/* <span className="label-title-des">请保障信息准确，避免同步出错</span> */}
          </div>

          <div className="label-title-wrap" style={{ display: 'block' }}>
            <div>
              <span className="label required" style={{ textAlign: 'left', marginLeft: 16 }}>字段定义</span>
              {
                isEdit ?
                <SdwButton
                  text='新增字段'
                  onClick={handAddConfig}
                /> :
                <SdwButton
                  text='获取配置字段'
                  disabled={!formData.example}
                  onClick={handGetConfig}
                />
              }
            </div>
            <div>
              <SdwTable
                setOverflow='visible'
                columns={getcolumns()}
                dataSource={_.isArray(tableData) ? tableData : []}
              />
            </div>
          </div>

        </div>
      </div>
      <div className="data-access__footer">
        <SdwButton
          type="submit"
          text={'保存&下一步'}
          disabled={!can_next}
          onClick={handleNext}
        />
      </div>

      {
        showConfirmWin &&
        <Dialog
          title='提示'
          width={500}
          visible={showConfirmWin}
          changeVisible={setShowConfirmWin}
          onCancelClick={() => setShowConfirmWin(false)}
          onSureClick={onConfirmSubmit}
        >
          <h5>将清空已关联的监听任务，确认保存？</h5>
        </Dialog>
      }
    </>
  )
}

const mapState = state => ({
  pageMap: state.dataAccess.pageMap,
  formData: state.dataAccess.formData,
  tableData: state.dataAccess.tableData,
})

const mapDispatch = dispatch => ({
  updateFormData: dispatch.dataAccess.updateFormData,
  updateTableData: dispatch.dataAccess.updateTableData,
  updateCurrentPage: dispatch.dataAccess.updateCurrentPage,
})

export default connect(mapState, mapDispatch)(DataLog)
