import Tabs from '@common/Tabs'
import Button from '@common/Button'

import BaseInfo from './BaseInfo'
import LinkAccount from './LinkAccount'

const menu = [
  {
    title: '基本信息',
    path: '/baseInfo',
    component: BaseInfo
  }, {
    title: '关联帐号',
    path: '/linkAccount',
    component: LinkAccount
  }
]

export default function Base(props) {


  return (
    <>
      <Tabs 
        menu={menu}
        {...props}
      />
    </>
  )
}