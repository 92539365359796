import axios from 'axios'
import Qs from 'qs'
import SdwMessage from '../common/Message'

// 支持跨域请求
axios.defaults.withCredentials = true

// 拦截request,设置全局请求为ajax请求
axios.interceptors.request.use((config) => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  return config
})

/**
 * 通过axios异步get数据
 * @param url           请求地址
 * @param params        请求参数
 * @param options       axios参数
 * @param callback      回调函数(定义最后一个参数如果是函数则为回调函数)
 * @returns void
 */
export function ajaxGet(url, params, options) {
  // 定义最后一个参数如果是函数则为回调函数
  const callback = arguments[arguments.length - 1]
  if (typeof options === 'function') {
    options = {}
  } else {
    options = options || {}
  }
  options.params = params || {}
  options.url = url
  options.method = 'get'
  return request(options, callback)
}

/**
 * 通过axios异步post数据 
 * @param url           请求地址
 * @param params        请求参数
 * @param options       axios参数
 * @param callback      回调函数(定义最后一个参数如果是函数则为回调函数)
 * @returns void
 */
export function ajaxPost(url, params, options) {
  // 定义最后一个参数如果是函数则为回调函数
  const callback = arguments[arguments.length - 1]
  if (typeof options === 'function') {
    options = {}
  } else {
    options = options || {}
  }
  options.method = 'post'
  options.url = url
  options.data = params
  if (!options.headers) {
    options.headers = {}
  }
  if (window._csrf_token && window._csrf_token.value) {
    options.headers['x-csrf-token'] = window._csrf_token.value
  }
  options.transformRequest = [
    (data, headers) => {
      // Do whatever you want to transform the data
      return Qs.stringify(params)
    }
  ]
  return request(options, callback)
}

/**
 * 通过异步下载文件
 * @param {string} url     请求下载地址
 * @param {object} params  请求参数
 * @param {string} fileName 自定义下载文件名称
 */
export function ajaxDownload(url, params, fileName) {
  axios({
    url: url,
    method: 'POST',
    responseType: 'blob', // important
    params: params || {}
  })
    .then((response) => {
      if (!fileName) {
        if (
          response.headers['content-disposition'] &&
          response.headers['content-disposition'].indexOf('filename=') !== -1
        ) {
          fileName = response.headers['content-disposition'].split(
            'filename='
          )[1]
        } else if (response.headers.filename) {
          fileName = response.headers.filename
        } else {
          const d = new Date()
          fileName =
            '' +
            d.getFullYear() +
            d.getMonth() +
            d.getHours() +
            d.getMinutes() +
            d.getSeconds()
        }
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
    .catch((error) => {
      // console.log('下载文件异常')
    })
}

/**
 * 调用axios插件发起ajax请求
 * @param {object} options     axios参数
 * @param {object} callback    回调方法，为空时，则按原axios链式方式回调处理数据
 */
export function request(options) {
  options.timeout = options.timeout || 30000
  options.async = options.async || true
  // 定义最后一个参数如果是函数则为回调函数
  const callback = arguments[arguments.length - 1]
  if (typeof callback !== 'function') {
    return axios(options)
  }
  return axios(options)
    .then((response) => {
      response.code = 0
      if (typeof response.data === 'string') {
        // 接口返回结果非json
      } else {
        response.code = response.data.retcode || response.data.code || 0 // 定义code=0时表示正常返回
      }
      response.msg = response.msg || ''
      response.body = response.data
      if (response.data.csrf_token) {
        window._csrf_token = response.data.csrf_token
      }
      requestHook(response)

      callback(response)
    })
    .catch((error) => {
      // 定义code=500时表示异常返回
      const response = { data: [], msg: error, code: 500 }
      requestHook(response)
      callback(response)
    })
}

/**
 * 并发请求
 * @param {array} ajax
 * @param {func} callback
 */
export function ajaxAll(ajax, callback) {
  axios.all(ajax).then(
    axios.spread((...res) => {
      if (typeof callback === 'function') callback(res)
    }),
    (error) => {
      const res = { data: error }
      // handle error
      res.msg = '查询超时或接口异常'
      res.status = -200
      if (typeof callback === 'function') callback(res)
      // console.log('error: ', res)
    }
  )
}

function requestHook(res) {
  if (res.code == 4004) {
    // 无权限做跳转
    // SdwMessage.warning({
    //   message: "无权限",
    //   duration: 0,
    //   showClose: true
    // })
  } else if (res.code == 1004 || res.code == 1005) {
    SdwMessage.warning({
      message: '未登录或登录失效，请按F5刷新页面',
      duration: 0,
      showClose: true
    })
    // 未登录
    setTimeout(() => {
      // login()
    }, 1000)
  } else if (res.code == 500) {
    // 网络异常
  }
  return true
}
