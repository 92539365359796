import React, { useEffect, useState } from 'react'
import { Card, Layout } from 'element-react'
import { getTopicInfoById } from '../../../services/subjectDomainDetail'

const KEY_MAP = {
  createTime: 'create_time',
  introduct: 'introduct',
  system: 'system',
  businesses: 'businesses',
  developmentHeads: 'development_heads',
  productHeads: 'product_heads',
}

const KEY_TEXT_MAP = {
  [KEY_MAP.createTime]: '创建时间',
  [KEY_MAP.introduct]: '业务简介',
  [KEY_MAP.system]: '相关系统',
  [KEY_MAP.businesses]: '相关业务',
  [KEY_MAP.developmentHeads]: '开发负责人',
  [KEY_MAP.productHeads]: '产品负责人',
}

export default props => {

  const [record, setRecord] = useState({})

  useEffect(() => {
    getTopicInfoById({
      id: props.id
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          setRecord(res.data.data || {})
        }
      })
  }, [props.id])

  return (
    <Card
      className="box-card"
      header={
        <div className="box-card__head">
          <span style={{ fontSize: '18px'}}>基本信息</span>
        </div>
      }
    >
      <div className="text item">
        {
          Object.values(KEY_MAP).map(key => (
            <div key={key} style={{ padding: '10px' }}>
              <Layout.Row>
                <Layout.Col span="8">
                  <div 
                    style={{color: '#999'}} 
                    className="grid-content bg-purple"
                  >{ KEY_TEXT_MAP[key] }</div>
                </Layout.Col>
                <Layout.Col span="16">
                  <div 
                    title={ record[key] }
                    style={{ width: 'auto', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    className="grid-content bg-purple-light"
                  >{ record[key] || '-' }</div>
                </Layout.Col>
              </Layout.Row>
            </div>
          ))
        }
      </div>
    </Card>
  )
}
