export const OPR_TYPE_MAP = {
  edit: 'edit',
  copy: 'copy'
}

export const STRATEGY_TYPE_MAP = {
  add: 'add',
  del: 'del',
  update: 'update'
}

export const TIME_MAP = [
  {
    id: 'minute',
    text: '分钟'
  },
  {
    id: 'hour',
    text: '小时'
  },
  {
    id: 'day',
    text: '天'
  },
  {
    id: 'week',
    text: '周'
  },
  {
    id: 'month',
    text: '月'
  }
]

export const Frequency_TIME_MAP = [
  {
    id: 'minute',
    text: '分钟/次'
  },
  {
    id: 'hour',
    text: '小时/次'
  },
  {
    id: 'day',
    text: '天/次'
  },
  {
    id: 'month',
    text: '月/次'
  }
]
// 所属模块选项
export const MODULE_ID_OPTIONS = [
  {
    id: '1',
    text: '经营分析'
  }, {
    id: '2',
    text: '质检学习'
  }, {
    id: '3',
    text: '员工绩效'
  }, {
    id: '4',
    text: '热词预警'
  }, {
    id: '6',
    text: '时序预警'
  }, {
    id: '0',
    text: '其他'
  }
]


export const SEND_TYPE_OPTIONS = [
  {
    id: 'wxappmsg',
    text: '微信消息模版推送告警'
  }, {
    id: 'wxmsg',
    text: '微信文本消息推送告警'
  }, {
    id: 'no_send',
    text: '不推送'
  }
]

export const FIXED_VALUE = 'fixed_value'

export const FIXED_VALUE_LESS = 'fixed_value_less'

// 这是固定值对应的map
export const DEFAULT_ITEM_OBJ = {
  rule_name: FIXED_VALUE,
  left_value: '',
  left_operator: '>', // 此处跟嘉濠确定了的：固定传下面2个符号：> 和 <
  right_operator: '<',
  right_value: '',
  data_cycle: '7'
}

// 下面是突增、突降字段对应的map
export const DEFAULT_VARIATION_OBJ = {
  rule_name: 'variation',
  operator: '',
  data_cycle: '',
  value: ''
}

// 监控类型
export const MONITOR_TYPE_MAP = [{
  id: 1,
  text: '基础服务'
}, {
  id: 2,
  text: '风险服务'
}, {
  id: 3,
  text: '项目观察'
}, {
  id: 4,
  text: '用户观察'
}, {
  id: 5,
  text: '智能生态'
}]
