import React, {PureComponent} from 'react'
import {Dialog, DateRangePicker} from 'element-react'
import 'element-theme-default'
import './style/DialogShowData.css'
import PropTypes from 'prop-types'
import SdwTable from '../../../../common/Table'
import SdwSelect from '../../../../common/Select'
import SdwDialog from '@common/Dialog'
import {getTablecfgDetail, getTablecfgpreview} from '@services/modifier'
import {formatTime} from '@utils/formatTime'
import SdwLoading from '@common/Loadding'

class DialogVisible extends PureComponent {
    constructor(props) {
        super(props);
    }

    state = {
        id: "",
        timeField: "",
        dateRange: [],
        tableFields: [],
        dialogVisible: false,
        pagination: {
            total: 0,
            per_page: 20,
            from: 1,
            to: 0,
            current_page: 1,
            items: []
        },
        record: {},
        tableShowData: false
    }

    // 可传递的参数
    static propTypes = {
        closeDialog: PropTypes.object,
    }

    // 属性默认值
    static defaultProps = {
        closeDialog: () => {
        },
    };

    componentDidMount() {
        this.initData()
        // 初始化预览数据的时间选择器的默认时间
        this.getShowDataDefauteTime()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dialogVisible == false) {
            return
        }

        if (prevProps.record.id !== this.props.record.id || prevProps.dialogVisible !== this.props.dialogVisible) {
            this.initData()
        }
        if (prevProps.dialogVisible !== this.props.dialogVisible) {
            this.setState({
                dialogVisible: this.props.dialogVisible,
            })
        }
    }

    initData = () => {
        this.setState({
            dialogVisible: this.props.dialogVisible,
            id: this.props.record.id || 0,
            pagination: {
                total: 0,
                per_page: 20,
                from: 1,
                to: 0,
                current_page: 1,
                items: []
            },
            record: {...this.props.record}
        })
        this.getTablecfgFields(this.props.record.source_id)
    }

    getTablecfgFields = (id) => {
        let that = this
        let parame = {
            "id": id
        }
        if (!id || id == "") {
            return
        }

        that.setState({tableShowData: false})
        getTablecfgDetail(parame).then(res => {
            that.setState({tableShowData: true})

            let tableFields = res.data.data.fields || []
            that.setState({tableFields: tableFields})
            that.gettest(tableFields)

            // 获取预览数据
            that.fetchItems(that.props.record)
        })
    }

    validateFun = (curValue, label = '') => {
        if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
            return true
        }
        return `${label}不能为空`
    }

    // 获取昨天时间和今天时间
    getShowDataDefauteTime = () => {
        var date = new Date();//当前时间
        var year = date.getFullYear()
        var month = this.zeroFill(date.getMonth() + 1);//月
        var day = this.zeroFill(date.getDate());//日
        let startTime = year + "/" + month + "/" + day + " 00:00:00"

        var s = startTime
        startTime = new Date(s);

        var date2 = new Date();//当前时间
        date2 = new Date(date2.getTime() + 24 * 60 * 60 * 1000);
        year = date2.getFullYear()
        month = this.zeroFill(date2.getMonth() + 1);//月
        day = this.zeroFill(date2.getDate());//日
        let endTime = year + "/" + month + "/" + day + " 00:00:00"

        s = endTime
        endTime = new Date(s);

        this.setState({dateRange: [startTime, endTime]})
    }

    /**
     * 时间补零
     */
    zeroFill = (i) => {
        if (i >= 0 && i <= 9) {
            return "0" + i;
        } else {
            return i;
        }
    }

    // 获取预览数据
    getShowData = (val, type) => {
        if (type == "field") {
            this.setState({timeField: val}, () => {
                // 获取预览数据
                this.fetchItems()
            })
        }
        if (type == "date") {
            this.setState({dateRange: val}, () => {
                // 获取预览数据
                this.fetchItems()
            })
        }
    }

    fetchItems() {
        let that = this
        const data = {
            bool: {
                must: []
            }
        };

        let curRecord = this.state.record

        if (curRecord.filter_value != "") {
            data.bool.must.push({
                query_string: {
                    query: curRecord.filter_value
                }
            });
        }

        if (this.state.timeField != "" && this.state.dateRange != null && this.state.dateRange.length > 0) {
            const range_time = {
                range: {}
            };
            range_time.range[this.state.timeField] = {
                gte: formatTime(+new Date(this.state.dateRange[0]), 0),
                lt: formatTime(+new Date(this.state.dateRange[1]), 0),
            };
            data.bool.must.push(range_time);
        }

        const post = {
            id: curRecord.source_id,
            limit: 50,
            search: {
                query: data
            }
        };

        getTablecfgpreview(post).then(res => {
            let pagination = {...that.state.pagination}
            pagination.items = [];
            pagination.current_page = 1;
            if (res.data.code == 0) {
                let targetDatas = res.data.data.items || [];
                pagination.items = res.data.data.items || [];
                pagination.total = res.data.data.total || 0;
                that.setState({
                    targetDatas: targetDatas,
                    pagination: pagination
                })
            }
        });
    }

    gettest = (fieldData) => {
        let dateFieldList = [];
        let timeField = "";
        if (fieldData.length > 0) {
            for (let i in fieldData) {
                const v = fieldData[i];
                if (v.field_type.includes("date")) {
                    dateFieldList.push(v);
                }
            }
            if (dateFieldList.length > 0) {
                timeField = dateFieldList[0].field_name;
            }

        }
        this.setState({
            tableFields: dateFieldList,
            timeField: timeField
        })
    }

    closeDialog = () => {
        this.setState({dialogVisible: false})
        this.props.closeDialog()
    }

    // 获取表标题
    getColumns = () => {
        let columnData = this.state.pagination.items[0]

        let columns = []

        for (let i in columnData) {
            let item = {
                title: i,
                width: "120",
                dataIndex: i,
            }
            columns.push(item)
        }

        return columns
    }

    render() {
        let {dateRange, timeField, pagination, tableShowData} = this.state;

        // 字段解析
        let tableFields = this.state.tableFields.map(i => {
            return {
                id: i.field_name,
                text: i.field_name_ch + '(' + i.field_name + ')'
            }
        })

        return (
            <div className="show-data DialogShowData DialogShowData-Modifier">
                <SdwDialog
                    title={"预览数据"}
                    width={1200}
                    height={680}
                    visible={this.state.dialogVisible}
                    onCancelClick={() => this.closeDialog()}
                    hideCancelButton={true}
                    hideSureButton={true}
                    cusWrapClass="DialogShowData-wrap"
                >
                    <div className="DialogShowData-label">
                        <span>
                            <SdwSelect
                                width={200}
                                listWidth={200}
                                readOnly={false}
                                value={timeField}
                                onChange={val => this.getShowData(val, "field")}
                                data={tableFields}
                            />
                        </span>
                        <span style={{marginLeft: 8}}>
                            <DateRangePicker
                                value={dateRange}
                                placeholder="选择日期范围"
                                isShowTime={true}
                                format="yyyy-MM-dd HH:mm:ss"
                                onChange={date=>{
                                    this.getShowData(date, "date")
                                }}
                            />
                        </span>
                        <span style={{float: "right"}}>共{pagination.total}条</span>
                    </div>
                    <div style={{marginTop: 20}}>
                        <SdwLoading className="page-main__wrap" loadding={!tableShowData}>
                            <SdwTable
                                columns={this.getColumns()}
                                dataSource={pagination.items}
                                tdWhiteSpace={true}
                            />
                        </SdwLoading>
                    </div>
                </SdwDialog>
            </div>
        )
    }
}

export default DialogVisible
