import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import Empty from '@common/Empty'
import Button from '@common/Button'
import SdwTabs from '@common/Tabs'
import Alert from './Alert'
import Base from './Base'
import Blood from './Blood'
import AuthText from "./common/AuthText"
import Review from './Review'
import { getPublicCfg } from '@services/Derived'
import { getMetaDataDetailInfo } from '../common'
import '../style/detail.css'
import AuthApply from './common/AuthApply'
import {getTablecfgDetailPermission, getTablecfgDetailAuth} from '@api/metaData'
import SdwMessage from '@common/Message'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

const MENU = [
  {
    title: '基本信息',
    path: '/baseInfo',
    component: Base
  },
  // {
  //   title: '数据血缘',
  //   path: '/blood',
  //   component: Blood
  // },
  {
    title: '数据预览',
    path: '/reviewData',
    component: Review
  },
  // {
  //   title: '数据告警',
  //   path: '/alert',
  //   component: Alert
  // }
]

class DetailIndex extends React.PureComponent {

  state = {
    curData: {},
    code:"",
    isApplyAuth:false
  }
  componentDidMount() {
    let ID = this.props.match.params.id
    if (!!ID) this.initData(ID)
  }

  // 更新一些公共的数据，写入redux备用
  initData = async (ID = '') => {
    await this.onGetTablecfgDetailPermission(ID)
    // 获取时间周期+更新频率
    getPublicCfg().then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        this.props.updatePublicCfgInfo(record)
      }
    })

    // 获取表头详情信息
    let record = await getMetaDataDetailInfo(ID)
    this.setState({ curData: record })
    this.props.updateDatabaseInfo(record)
  }
  onGetTablecfgDetailPermission = async (id) => {
      if (!id) return
      let auth = hasPriv('getTablecfgDetailPermission')
      if (auth == false) {
        let res = await getTablecfgDetailAuth ({id:id})
        if (res && res.data ) {
          this.setState({ code: res.data.code })
        } else {
          let msg = res.data.msg || '请求失败'
          SdwMessage.error(msg)
        }
      } else {
        this.setState({ code: 0 })
      }
  }
  showAuthApply = (isApplyAuth = true) => {
    this.setState({"isApplyAuth":isApplyAuth})
  }
  render () {
    let { curData ,code, isApplyAuth} = this.state
    const curEnabled = (curData || {}).enable || 0
    let id = this.props.match.params.id
    return (
      <>
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '数据集市',
              path: '/admin/dataMarket/dictionary'
            }, {
              title: '元数据管理',
              path: '/admin/dataMarket/metadatas'
            }, {
              title: '表信息'
            }
          ]}
        />

        <div className="table-detail-index__header-wrap">
          {
            !curEnabled ?
            <span className='style__label' style={{
              color: '#999',
              border: '1px solid #999',
            }}>无效</span> :
            <span className='style__label'>{curData.warehouse_level}</span>
          }
          {`${curData.database_name}.${curData.table_name}（${curData.data_set_chinese}）`}
        </div>

        <div className="table-detail-index__tabs_wrap">
          {
              code !== "" ?
                (
                  isApplyAuth === false ?
                    (
                      (code === 0) ?
                        <SdwTabs history={this.props.history} menu={MENU} curEnabled={curEnabled} />
                        : (<Empty innerWidth="600px" icon="noAuth" text={<AuthText showAuthApply={this.showAuthApply}  code={code}/>}/>)
                    )
                    : (<AuthApply loginName={this.props.userInfo.ChineseName} showAuthApply={bool=>{
                      this.onGetTablecfgDetailPermission(id)
                      this.showAuthApply(bool)
                    }} id={curData.id} />)
                )
                :""
          }
        </div>
      </>
    )
  }
}
const mapState = state => ({
  userInfo: state.user.userInfo,
})

const mapDispatch = dispath => ({
  updateDatabaseInfo: dispath.database.updateDatabaseInfo,
  updatePublicCfgInfo: dispath.database.updatePublicCfgInfo,
})

export default connect(mapState, mapDispatch)(DetailIndex)
