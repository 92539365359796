import { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Form from "@common/Form_D"
import Select from '@common/Select'
import Input from '@common/Input'
import Textarea from '@common/Textarea'
import Message from '@common/Message'
import { commonApi } from '@utils/request'
import { getCollectConfigList, getLogCollectConfigDetail } from '@services/dataAccess'
import { getMethods } from '@services/labelManagement'
import UseStandardItemSelects from '@customHooks/UseStandardItemSelects'
import UseLabelName from '../../common/UseLabelName'
import './index.css'

function notEmpty(val) {
  return _.trim(val) !== ''
}

const rules = {
  label_standard_id: [
    { func: notEmpty, message: '请选择标签名称' }
  ],
  rule_name: [
    { func: notEmpty, message: '请输入规则名称' }
  ],
  rule_type: [
    { func: notEmpty, message: '请选择规则类型' }
  ],
  process_way: [
    { func: notEmpty, message: '请选择加工方式' }
  ],
  log_id: [
    { func: notEmpty, message: '请选择关联日志' }
  ],
  task_id_field: [
    { func: notEmpty, message: '请选择匹配字段' }
  ],
  extract_field: [
    { func: notEmpty, message: '请选择提取字段' }
  ]
}

const validateFun = val => {
  if (!!val) {
    return true
  }
  return '该输入项不能为空'
}

const LogIdMap = {
  'localhost': 20049,  // 本地 20049
  'sdwtest.yzfchat.com/public': 20049,  // 测试环境用 20049
  'sdw.oa.com': 20058,  //  现网用 20058
  'sdw.yzfchat.com': 20058,  //  现网用 20058
}

function LabelRuleConfig(props) {
  const standardItemSelects = UseStandardItemSelects()

  const { labelRuleConfig, updateLabelRuleConfig, updateExample } = props
  const formRef = props.formRef
  const [logArr, setLogArr] = useState([])
  const [taskIDFieldArr, setTaskIDFieldArr] = useState([])
  const [ruleTypeArr, setRuleTypeArr] = useState([])
  const [processWayArr, setProcessWayArr] = useState([])
  const [labelName, setLabelName] = useState('')
  const standardManageItems = UseLabelName(labelName)
  const [functionNameArr, setFunctionNameArr] = useState([])

  useEffect(() => {
    commonApi(getMethods, {}).then(data => {
      if (!_.isArray(data)) return
      setFunctionNameArr(data.map(i => ({
        id: i.name,
        text: `${i.name}(${i.value.name})`
      })))
    }).catch(err => Message.error(err))
  }, [])

  useEffect(() => {
    const ruleType = _.get(standardItemSelects, 'rule_type') || []
    const processWay = _.get(standardItemSelects, 'process_way') || []
    setRuleTypeArr(ruleType.map(i => ({
      id: String(i.value),
      text: i.name
    })))
    setProcessWayArr(processWay.map(i => ({
      id: String(i.value),
      text: i.name
    })))
  }, [standardItemSelects])

  useEffect(() => {
    commonApi(getCollectConfigList, {
      limit: 200
    }).then(data => {
      const items = _.get(data, 'items') || []
      setLogArr(items.map(i => ({
        ...i,
        text: `${i.store_table_name_ch}(${i.store_table_name_en})(${i.id})`
      })))
    }).catch(err => Message.error(err))
  }, [])

  useEffect(() => {
    if (!labelRuleConfig.log_id) return
    commonApi(getLogCollectConfigDetail, {
      id: labelRuleConfig.log_id
    }).then(data => {
      const list = _.get(data, 'tableColumnList') || []
      const example = _.get(data, 'configInfo.example') || ''
      updateExample(example)
      setTaskIDFieldArr(list.map(i => ({
        id: i.column_name,
        text: i.column_name
      })))
    }).catch(err => Message.error(err))
  }, [labelRuleConfig.log_id])

  useEffect(() => {
    const isTypeOne = labelRuleConfig.rule_type == 1

    // 如果选中轨迹加工，关联日志、匹配字段 选中固定值
    if (!isTypeOne) return

    const logID = LogIdMap[_.get(window, 'location.hostname')] || LogIdMap['localhost']

    if (logArr.length && labelRuleConfig.log_id != logID) {
      setFormDataProps('log_id', logID)
    }

    if (taskIDFieldArr.length && labelRuleConfig.log_id == logID && labelRuleConfig.task_id_field != 'taskid') {
      setFormDataProps('task_id_field', 'taskid')
    }

  }, [labelRuleConfig.rule_type, logArr, taskIDFieldArr])

  function setFormDataProps(key, val) {
    let cloneFormData = _.cloneDeep(labelRuleConfig)
    cloneFormData[key] = val
    updateLabelRuleConfig(cloneFormData)
  }

  return (
    <div style={{ width: 600 }}>
      <Form
        labelWidth={'112px'}
        labelPosition={'left'}
        ref={formRef}
        model={labelRuleConfig}
        rules={rules}
      >
        <div className='label-rule-config__title-wrap'>规则类型配置</div>

        <Form.FormItem label="标签名称" prop="label_standard_id" required>
          <Select
            placeholder="请选择标签名称"
            width='100%'
            readOnly={false}
            onInputFilter={setLabelName}
            value={labelRuleConfig.label_standard_id}
            data={standardManageItems}
            onChange={val => setFormDataProps('label_standard_id', val)}
          />
        </Form.FormItem>
        <Form.FormItem label="规则名称" prop="rule_name" required>
          <Input
            placeholder="请输入规则名称"
            width='100%'
            value={labelRuleConfig.rule_name}
            onChange={val => setFormDataProps('rule_name', val)}
          />
        </Form.FormItem>
        <Form.FormItem label="规则类型" prop="rule_type" required>
          <Select
            placeholder="请选择规则类型"
            width='100%'
            readOnly={false}
            value={String(labelRuleConfig.rule_type)}
            data={ruleTypeArr}
            onChange={val => setFormDataProps('rule_type', val)}
          />
        </Form.FormItem>
        <Form.FormItem label="加工方式" prop="process_way" required>
          <Select
            placeholder="请选择加工方式"
            width='100%'
            value={String(labelRuleConfig.process_way)}
            data={processWayArr}
            onChange={val => setFormDataProps('process_way', val)}
          />
        </Form.FormItem>

        {/* labelRuleConfig.rule_type == '加工日志' ---> 0 ；'轨迹加工' ---> 1*/}
        {
          labelRuleConfig.rule_type !== '' &&
          <>
            <div className='label-rule-config__title-wrap'>规则详情配置</div>

            <Form.FormItem label="关联日志" prop="log_id" required>
              <Select
                placeholder="请选择关联日志"
                width='100%'
                readOnly={false}
                disabled={labelRuleConfig.rule_type == 1} // 轨迹加工，此项禁用
                value={labelRuleConfig.log_id}
                data={logArr}
                onChange={val => setFormDataProps('log_id', val)}
              />
            </Form.FormItem>
            <Form.FormItem label="匹配字段" prop="task_id_field" required>
              <Select
                placeholder={!labelRuleConfig.log_id ? '请先选择关联日志' : '请选择匹配字段'}
                width='100%'
                readOnly={false}
                disabled={!labelRuleConfig.log_id || labelRuleConfig.rule_type == 1} // 轨迹加工，此项禁用
                value={labelRuleConfig.task_id_field}
                data={taskIDFieldArr}
                onChange={val => setFormDataProps('task_id_field', val)}
              />
            </Form.FormItem>
            <Form.FormItem label="提取字段" prop="extract_field" required>
              <Select
                placeholder={!labelRuleConfig.log_id ? '请先选择关联日志' : '请选择提取字段'}
                width='100%'
                readOnly={false}
                disabled={!labelRuleConfig.log_id}
                value={labelRuleConfig.extract_field}
                data={taskIDFieldArr}
                onChange={val => setFormDataProps('extract_field', val)}
              />
            </Form.FormItem>
            {
              labelRuleConfig.rule_type == 0 &&
              <Form.FormItem label="过滤条件" prop="filter">
                <Textarea
                  placeholder="请输入过滤条件，使用Lucene语法"
                  width='100%'
                  value={labelRuleConfig.filter}
                  onChange={val => setFormDataProps('filter', val)}
                />
              </Form.FormItem>
            }
          </>
        }

        <div className='label-rule-config__title-wrap'>加工清洗规则</div>

        <div className='label-rule-config__group-wrap'>
          <Select
            placeholder="请选择加工规则"
            width='50%'
            value={labelRuleConfig.function_name}
            data={functionNameArr}
            onChange={val => setFormDataProps('function_name', val)}
          />
          <div className='label-rule-config__group-label-span'>
            <Input
              placeholder={!labelRuleConfig.function_name ? '请先选择加工规则' : '请输入规则名称'}
              width='100%'
              disabled={!labelRuleConfig.function_name}
              value={labelRuleConfig.function_handle}
              errTip={!!labelRuleConfig.function_name ? (
                !!labelRuleConfig.function_handle ? '' : '该输入项不能为空'
              ) : ''}
              onChange={val => setFormDataProps('function_handle', val)}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

const mapState = state => ({
  labelRuleConfig: state.labelManagement.labelRuleConfig
})

const mapDispatch = dispatch => ({
  updateExample: dispatch.labelManagement.updateExample,
  updateLabelRuleConfig: dispatch.labelManagement.updateLabelRuleConfig
})

export default connect(mapState, mapDispatch)(LabelRuleConfig)
