import React, { Fragment } from 'react'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import '../css/AddSource.css'
import _ from 'lodash'
import { LEVEL_TEXT_MAP } from '@pages/Market/Metadata/common'
import SdwMulRadiobox from '@common/MulRadiobox'
import { connect } from 'react-redux'
import { getNodeFields } from '@api/midTable'
import SdwMessage from '@common/Message'

class ViewSource extends React.Component {


  changeDataSource = (item, type) => {
    console.log("-------------changeDataSource====item", item)
    let params = {
      id:item.job_id,
      node_id:item.id,
    }
    if (item.hasOwnProperty("link") && item.link) {
      params.link_id = item.link.id
    }
    getNodeFields(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || []
        record = record.map(i => {
          return {id:i.field, text:i.field}
        })
        switch (type) {
          case "table_id":
            this.props.setViewNodeValue("table_id", item)
            this.props.setViewNodeValue("groupby_key", "")
            this.props.setViewNodeValue("tableFieldsList", record)
            break
          case "left_node_id":
            this.props.setViewNodeValue("left_node_id", item)
            this.props.setViewNodeValue("left_field", [])
            this.props.setViewNodeValue("leftNodeFieldsList", record)
            break
          case "right_node_id":
            this.props.setViewNodeValue("right_node_id", item)
            this.props.setViewNodeValue("right_field", [])
            this.props.setViewNodeValue("rightNodeFieldsList", record)
            break
        }


      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }

  render () {
    let {
      sholdCheckValidate,
      tableFieldsList,
      leftNodeFieldsList,
      rightNodeFieldsList,
      setViewNodeValue,
    } = this.props
    let {
      isEdit,
      stepList,
      linkParams,
      tableConf, physicsTableList, //reduce中的节点列表、物理表节点列表
    } = this.props
    let dataSourceList = Object.values(stepList)
    //let tableConfList = tableConf.filter(el => el.is_used === 0 ).map(el => {
    let tableConfList = dataSourceList.filter(el => el.text !== "根节点" ).map(item => {
      let el = item.item
      if (el.table_type == 0) {
        //物理表
        return {
          disabled: (linkParams.left_node_id == el.id || linkParams.right_node_id == el.id) ? true :false,
          id: el.id,
          //text: "【"+el.table_item.warehouse_level + "】"+el.table_item.data_set_chinese + '（' + (el.table_item.database_name ? el.table_item.database_name + '.' : '') + el.table_item.table_name + '）',
          text: el.table_name,
          data:el,
        }
      } else {
        // 【视图】
        return {
          disabled: (linkParams.left_node_id == el.id || linkParams.right_node_id == el.id) ? true :false,
          id: el.id,
          text: el.table_name,
          data:el,
        }
      }

    })
    let groupby_key = linkParams.groupby_key
    let left_field = linkParams.left_field
    let right_field = linkParams.right_field
    if (_.isString(linkParams.groupby_key)) {
      if (linkParams.groupby_key == "") {
        groupby_key = []
      } else {
        groupby_key = linkParams.groupby_key.split(',').map(i => {
          return {id:i, text:i}
        })
      }
    }
    if (_.isString(linkParams.left_field)) {
      left_field = linkParams.left_field.split(',').map(i => {
        return {id:i, text:i}
      })
    }
    if (_.isString(linkParams.right_field)) {
      right_field = linkParams.right_field.split(',').map(i => {
        return {id:i, text:i}
      })
    }
    return (
      <>
        <div className='add-AddSource-win__label' >
          <span className='label__title required'>视图类型</span>
          <SdwSelect
            width={450}
            listWidth={450}
            clearable={false}
            value={linkParams.view_type}
            data={[{id:1, text:"单表聚合"}, { id:0, text:"多表关联"}, { id:2, text:"分桶聚合"}]}
            onChange={val => setViewNodeValue("view_type", val)}
            sholdCheckValidate={sholdCheckValidate}
            validateFun={val=> { return !!val?true:"视图类型不能为空"}}
          />
        </div>
        <div className='add-AddSource-win__label'>
          <span className='label__title required'>视图名称</span>
          <SdwInput
            placeholder='请输入视图名称'
            width={450}
            value={linkParams.table_name}
            sholdCheckValidate={sholdCheckValidate}
            validateFun={val=> { return !!val?true:"视图名称不能为空"}}
            onChange={val => {
              setViewNodeValue("table_name", val)
            }}
          />
        </div>
        {
          linkParams.view_type == 1 && //单表聚合
            <Fragment key ={"view_source_1"}>
              <div className='add-AddSource-win__label'>
                <span className='label__title required'>数据源</span>
                <SdwSelect
                  isMultipleChoice={false}
                  placeholder={"请选择聚合数据源"}
                  width={450}
                  listWidth={450}
                  clearable={false}
                  readOnly={false}
                  value={linkParams.left_node_id}
                  data={tableConfList}
                  onChange={(val , item) => {
                    this.changeDataSource(item.data, "table_id")
                  }}
                  sholdCheckValidate={sholdCheckValidate}
                  validateFun={val=> { return !!val?true:"聚合数据源不能为空"}}
                />
              </div>
              <div className='add-AddSource-win__label'>
                <span className='label__title required'>聚合字段</span>
                <SdwSelect
                  isMultipleChoice={true}
                  placeholder={"请选择取聚合字段"}
                  width={450}
                  listWidth={450}
                  listMaxHeight={150}
                  clearable={false}
                  readOnly={false}
                  value={groupby_key}
                  data={tableFieldsList}
                  //data={[{id:"create_time", text:"create_time"}]}
                  onChange={(val) => {
                    setViewNodeValue("groupby_key", val)
                  }}
                  sholdCheckValidate={sholdCheckValidate}
                  validateFun={val=> { return val.length>0?true:"聚合字段不能为空"}}
                />
              </div>
            </Fragment>
        }
        {
          linkParams.view_type == 0 && //多表关联
          <Fragment key ={"view_source_2"}>
            <div className='add-AddSource-win__label'>
              <span className='label__title required'>主表</span>
              <SdwSelect
                placeholder={"请选择主表"}
                width={450}
                listWidth={450}
                clearable={false}
                readOnly={false}
                value={linkParams.left_node_id}
                data={tableConfList}
                onChange={(val , item) => {
                  this.changeDataSource(item.data, "left_node_id")
                }}
                sholdCheckValidate={sholdCheckValidate}
                validateFun={val=> { return !!val?true:"主表不能为空"}}
              />
            </div>
            <div className='add-AddSource-win__label'>
              <span className='label__title required'>副表</span>
              <SdwSelect
                placeholder={"请选择副表"}
                width={450}
                listWidth={450}
                clearable={false}
                readOnly={false}
                value={linkParams.right_node_id}
                data={tableConfList}
                onChange={(val , item) => {
                  this.changeDataSource(item.data, "right_node_id")
                }}
                sholdCheckValidate={sholdCheckValidate}
                validateFun={val=> { return !!val?true:"副表不能为空"}}
              />
            </div>
            <div className='add-AddSource-win__label'>
              <span className='label__title required'>连接方式</span>
              <SdwMulRadiobox
                style={{width:"228px"}}
                options={[
                  {
                    text: '并集',
                    value: 'union'
                  },{
                  text: '左关联',
                  value: 'join'
                }]}
                value={linkParams.concat_type}
                changeCheckedFun={val => setViewNodeValue("concat_type", val)}
              />
            </div>
            {
              linkParams.concat_type && linkParams.concat_type=="join" &&
              <div className='add-AddSource-win__label'>
                <span className='label__title required'>关联字段</span>
                <SdwSelect
                  isMultipleChoice={true} // 下拉多选
                  placeholder={"主表关联字段"}
                  width={220}
                  listWidth={220}
                  listMaxHeight={100}
                  clearable={false}
                  readOnly={false}
                  value={left_field}
                  data={leftNodeFieldsList}
                  onChange={val => {
                    console.log("SdwSelect====", val)
                    setViewNodeValue("left_field", val)
                  }}
                  sholdCheckValidate={sholdCheckValidate}
                  validateFun={val=> { return val.length>0?true:"主表关联字段不能为空"}}
                />
                <span style={{marginLeft:"8px"}}> </span>
                <SdwSelect
                  isMultipleChoice={true} // 下拉多选
                  placeholder={"副表关联字段"}
                  width={220}
                  listWidth={220}
                  listMaxHeight={100}
                  clearable={false}
                  readOnly={false}
                  value={right_field}
                  data={rightNodeFieldsList}
                  onChange={(val , item) => {
                    setViewNodeValue("right_field", val)
                  }}
                  sholdCheckValidate={sholdCheckValidate}
                  validateFun={val=> { return val.length>0?true:"副表关联字段不能为空"}}
                />
              </div>
            }
          </Fragment>
        }
        {
          linkParams.view_type == 2 && //分桶聚合
          <Fragment key ={"view_source_2"}>
            <div className='add-AddSource-win__label'>
              <span className='label__title required'>数据源</span>
              <SdwSelect
                isMultipleChoice={false}
                placeholder={"请选择聚合数据源"}
                width={450}
                listWidth={450}
                clearable={false}
                readOnly={false}
                value={linkParams.left_node_id}
                data={tableConfList}
                onChange={(val , item) => {
                  this.changeDataSource(item.data, "table_id")
                }}
                sholdCheckValidate={sholdCheckValidate}
                validateFun={val=> { return !!val?true:"聚合数据源不能为空"}}
              />
            </div>
            <div className='add-AddSource-win__label'>
              <span className='label__title required'>分桶字段</span>
              <SdwSelect
                isMultipleChoice={true}
                placeholder={"请选择取分桶字段"}
                width={450}
                listWidth={450}
                listMaxHeight={150}
                clearable={false}
                readOnly={false}
                value={groupby_key}
                data={tableFieldsList}
                //data={[{id:"create_time", text:"create_time"}]}
                onChange={(val) => {
                  console.log("----val", val)
                  setViewNodeValue("groupby_key", val)
                }}
                sholdCheckValidate={sholdCheckValidate}
                validateFun={val=> { return val.length>0?true:"分桶字段不能为空"}}
              />
            </div>
          </Fragment>
        }
        <div className='add-AddSource-win__label'>
          <span className='label__title'>过滤条件</span>
          <SdwInput
            placeholder='请输入过滤条件，该过滤将在视图最后一步执行'
            width={450}
            value={linkParams.after_filter}
            onChange={val => {
              setViewNodeValue("after_filter", val)
            }}
          />
        </div>
      </>
    )
  }
}
const mapState = state => ({
  tableConf: state.midTableView.tableConf,
  physicsTableList: state.midTableView.physicsTableList,
  stepList: state.midTableView.stepList,
})

const mapDispatch = dispatch => ({
  updateTableConf: dispatch.midTableView.updateTableConf,
  updatePhysicsTableList: dispatch.midTableView.updatePhysicsTableList,
  updateStepList: dispatch.midTableView.updateStepList,
})

export default connect(mapState, mapDispatch)(ViewSource)
