import { request } from '../utils/request'

export const login = (params = {}) =>
  request({ url: '/auth/login/check', method: 'post', data: params })

export const logout = (params = {}) =>
  request({ url: '/UserManage/logout', method: 'post', data: params })

export const getUserInfo = (params = {}) => {
  // return request({ url: '/UserManage/getUserInfo', method: 'post', data: params })
  // return Promise.resolve({ username: 'username', age: 18 })
}


