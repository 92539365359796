import produce from 'immer'

const state = {
    pageInfo: '', // 储存当前页码
    tabsInfo: '', // 储存当前tab的位置
}

const updatePageInfo = produce((state, payload) => {
    state.pageInfo = payload
})

const updateTabsInfo = produce((state, payload) => {
    state.tabsInfo = payload
})

const reducers = {
    updatePageInfo,
    updateTabsInfo
}

export default { state, reducers }