import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwTabs from '@common/Tabs'
import CollectionServiceManagement from './CollectionServiceManagement'
import ResolveDistributionConfiguration from './ResolveDistributionConfiguration'
import LogIdConfiguration from './LogIdConfiguration'
import IpAccessConfiguration from './IpAccessConfiguration'
import QualityMonitoring from './QualityMonitoring'
// import CodeHotUpdate from './CodeHotUpdate'
import {hasPriv} from '@components/FrontendAuth/AuthMap'


const menu = [
    {
        title: '日志标识配置',
        path: '/logIdConfiguration',
        component: LogIdConfiguration
    }, {
        title: '解析分发配置',
        path: '/resolveDistributionConfiguration',
        component: ResolveDistributionConfiguration
    }, {
        title: '采集服务管理',
        path: '/collectionServiceManagement',
        component: CollectionServiceManagement
    }, {
        title: '采集IP管理',
        path: '/ipAccessConfiguration',
        component: IpAccessConfiguration
    },
    {
        title: '质量监控-对账',
        path: '/qualityMonitoring',
        component: QualityMonitoring
    },
    // {
    //     title: '代码热更新',
    //     path: '/codeHotUpdate',
    //     component: CodeHotUpdate
    // }
]

class LogAccess extends React.PureComponent {

    render() {

        const hasPrivMenu = menu.filter(i => hasPriv(i.priv))

        return (
            <>
                <SdwBreadCrumbs
                    history={this.props.history}
                    data={[
                        {
                            title: '数据采集地',
                            path: '/admin/dataCollect/logManagement'
                        }, {
                            title: '日志接入'
                        }
                    ]}
                />

                <div style={{padding: '0 20px', background: '#fff'}}>
                    <SdwTabs
                        menu={hasPrivMenu}
                        curMenuIndex={this.props.tabsInfo || 0}
                        {...this.props}
                        titleWidth={120}
                    />
                </div>
            </>
        )
    }
}

const mapState = state => ({
    tabsInfo: state.page.tabsInfo
})

export default withRouter(connect(mapState)(LogAccess))
