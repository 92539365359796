import React, { PureComponent } from 'react'

import 'element-theme-default'
import '../style/showFilterData.css'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwRadiobox from '@common/Radiobox'
import SdwSwitch from '@common/Switch'
import SdwDialog from '@common/Dialog'

class DialogVisible extends PureComponent {
  constructor(props) {
    super(props);
  }
  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    return `${label}不能为空`
  }

  closeDialog = () => {
    this.props.closeDialog()
  }


  render () {
    const { item, index, rankDimensionData ,timeCycle, baseInfo, dialogVisible} = this.props

    const {store_dimension, time_schedule } = item

    // function
    const { handleSubmit,setRankDimension,  enableStateChange, setAddFilterValue } = this.props
    let time_schedule_obj = JSON.parse(baseInfo.time_schedule)
    let timeCycles = []
    for (let i in timeCycle) {
      for (let j in time_schedule_obj) {
        if (time_schedule_obj[j].time_cycle == timeCycle[i].id) {
          timeCycles.push(timeCycle[i])
        }
      }
    }
    let time_schedules = []
    for (let i in timeCycle) {
      for (let j in time_schedule) {
        if (time_schedule[j].time_cycle == timeCycle[i].id) {
          time_schedules.push(timeCycle[i])
        }
      }
    }
    const rankDimention = (store_dimension.length > 0 && store_dimension[0].id[0]) || ''
    return (
      <div className="show-data DialogShowData DialogShowData-Derived">
        <SdwDialog
            title={(index==-1?"新增":"编辑")+"衍生指标-过滤型"}
            size="tiny"
            visible={ dialogVisible }
            //changeVisible={changeDialogVisible}
            width={610}
            onSureClick={handleSubmit}
            onCancelClick={ () => this.closeDialog() }
            lockScroll={ false }
          >
          <div className='add-application-win__label'>
            <span className='label__title1 required'>过滤维度</span>
            <SdwSelect
              width={418}
              listWidth={418}
              clearable={false}
              isMultipleChoice={false}
              placeholder={'请选择维度'}
              value={rankDimention}
              data={rankDimensionData}
              validateFun={value => !!value ? true : '维度不能为空'}
              sholdCheckValidate={item.disabledCheck}
              onChange={val => setRankDimension(val)} />
          </div>
          <div className='add-application-win__label'>
            <span className='label__title1 required'>时间周期</span>
            <SdwSelect
              width={418}
              listWidth={418}
              clearable={false}
              isMultipleChoice={true}
              placeholder={'请选择时间周期'}
              value={time_schedules}
              data={timeCycles}
              onChange={val =>setAddFilterValue( "time_schedule",val)}
              sholdCheckValidate={item.disabledCheck}
              validateFun={value => !!value ? true : '时间周期不能为空'}
            />
          </div>
          <div className='add-application-win__label'>
            <span className='label__title1 required'>过滤语法</span>
            <SdwInput
              type="textarea"
              value={item.cal_expression}
              size="large"
              autosize={{ minRows: 4, maxRows: 4}}
              placeholder="请输入语法"
              onChange={val => setAddFilterValue( "cal_expression", val)}
              sholdCheckValidate={item.disabledCheck}
              validateFun={value => !!value ? true : '过滤语法不能为空'}
            />
          </div>
          <div className='add-application-win__label'>
            <span className='label__title1 required'>指标英文名</span>
            <SdwInput
              width={418}
              listWidth={418}
              placeholder={"请输入英文名"}
              value={item.target_name}
              onChange={val => setAddFilterValue( "target_name", val)}
              sholdCheckValidate={item.disabledCheck}
              validateFun={value => !!value ? true : '英文名不能为空'}
            />
          </div>
          <div className='add-application-win__label'>
            <span className='label__title1 required'>指标中文名</span>
            <SdwInput
              width={418}
              listWidth={418}
              placeholder={"请输入中文名"}
              value={item.target_name_cn}
              validateFun={value => !!value ? true : '中文名不能为空'}
              sholdCheckValidate={item.disabledCheck}
              onChange={val => setAddFilterValue( "target_name_cn", val)}
            />
          </div>
          <div className='add-application-win__label'>
            <span className='label__title1 required'>是否启用</span>
            <SdwSwitch
              value={item.enable}
              changeValue={val => enableStateChange(val)}
            />
          </div>
          </SdwDialog>
      </div>
    )
  }
}

export default DialogVisible
