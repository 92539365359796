export const TIME_CYCLE_OPTIONS = [
  {
    id: '1d',
    text: '最近1天'
  },
  {
    id: '7d',
    text: '最近7天'
  },
  {
    id: '1m',
    text: '最近1个月'
  },
  {
    id: '30d',
    text: '最近30天'
  },
  {
    id: '1h',
    text: '最近1小时'
  },
  {
    id: 'mtd',
    text: '月初截止到当天'
  },
  {
    id: 'b2d',
    text: '2天前'
  },
  {
    id: 'b5d',
    text: '5天前'
  },
  {
    id: 'b7d',
    text: '7天前'
  },
  {
    id: 'b29d',
    text: '29天前'
  },
  {
    id: 'dth',
    text: '零点截止到当前小时'
  },
  {
    id: 'ytm',
    text: '年初截止到当月'
  },
  {
    id: 'asm',
    text: '考核月截止至当天'
  }
]