// 类型
export const accountType = {
  openId: '0',
  qq: '1',
  phone: '2'
}

export const accountMap = [
  {
    id: accountType.phone,
    text: '手机号'
  }, {
    id: accountType.qq,
    text: 'QQ号'
  }, {
    id: accountType.openId,
    text: 'OpenId'
  }
]

// 用户标签显示的字段：有key值，需要显示对应的字段，有value值，需要显示对应的前缀
export const USER_LABELS = {
  user_gender: '性别',
  user_age: '年龄',
  user_hometown: '地区分布',
  generation: '', // 老年人
  // xxx: '', // 未成年人
}