import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwMessage from '@common/Message'
import './css/AddSource.css'
import { saveTable, saveNode, saveLink, getNodeFields } from '@services/midTable'
import TableSource from './TableSource/index'


import { DateRangePicker, DatePicker } from "element-react"
import { getAllTablecfg, getMetaDataInfo } from '@api/metaData'
import ViewSource from '@pages/Knowledge/MidTable/AddSource/ViewSource'
import { connect } from 'react-redux'
import { getTablecfgDetail } from '@api/modifier'

class AddSource extends React.Component {

  state = {
    table_type: 0,
    nodeParams:{
      id:"",
      table_type:0, //节点类型：-1为根表，0为物理表，1为视图
      data_set_type:"es",
      table_id: 0,       //物理表数据源id
      filter: "",   //物理表过滤条件
      time_field: [], //物理表取数时间字段
      get_time_cycle: "",       //物理表取数范围
      groupby_key: [],
      enabled: 1,
      table_name:"", //视图名称
      fields_conf:[]
    },
    linkParams: {
      id: "",  // 视图节点id，新增不传，编辑传
      view_type:1, //1 单表聚合  0多表关联，1单表聚合，2分桶聚合
      after_filter:"", //过滤条件
      link_id: "",  // 连接id，新增不传，编辑传
      table_name: "", //视图名称
      left_node_id: "",  //主表字段id
      right_node_id: "", //副表节点id
      left_field: [], //主表关联字段
      right_field: [], //副表关联字段
      concat_type: "union", //关联类型，有左关联join和合并union
      groupby_key: [], //聚合字段
      enabled: 1, //默认有效
    }, // 连线的列表
    sholdTableCheckValidate:false,
    sholdViewCheckValidate:false,
    tableList:[], // 物理表的数据源列表
    timeFieldList:[], // 物理表的数据源-关于时间的字段列表
    timeCycleList:[], //物理表的数据源-取数时间字段列表
    tableFieldsList:[], // 单表聚合的数据源-所有的聚合字段列表
    leftNodeFieldsList:[], // 多表连接的主表数据源-所有的聚合字段列表
    rightNodeFieldsList:[], // 多表连接的副表数据源-所有的聚合字段列表
    physicsTableList:[], //物理表列表
  }


  componentDidMount () {
    this.initData()
    this.initTimeCycle()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("AddSource++++componentDidUpdate")
    // console.log("AddSource++++componentDidUpdate===prevProps", prevProps)
    // console.log("AddSource++++componentDidUpdate====props", this.props)
    if (
      prevState.nodeParams != this.state.nodeParams ||
      prevState.linkParams != this.state.linkParams
    ) {
      //this.initData()
    }
    // if (prevProps.currentSource !== this.props.currentSource) {
    //   console.log("AddSource++++componentDidUpdate==currentSource", this.props.currentSource)
    //   this.initData()
    // }
  }

  initTimeCycle = () => {
    let cfgData = this.props.cfgData
    let midTableTimes = cfgData.midTableTimes.map(i => {
      let text = ""
      if (i.value) {
        text = i.name+"（"+i.code+"）"
      } else {
        text = i.name
      }
      return {id:i.value, text:text, item:i}
    })
    this.setState({
      timeCycleList:midTableTimes
    })
  }


  addNode = () => {
    let {nodeParams, linkParams, table_type} = this.state
    let {
      isEdit,editMidTable,
      tableConf, physicsTableList, linkConf //reduce中的节点列表、物理表节点列表、连接的列表
    } = this.props
    console.log("addNode============nodeParams", nodeParams)
    console.log("addNode============linkParams", linkParams)

    let params = {}
    if (table_type == 0) {
      let res = this.ruleNodeTableParams(nodeParams)
      if (!res) {
        this.setState({
          sholdTableCheckValidate:true
        })
        return
      }
      params = {
        "id": editMidTable.id,
        "table_conf": {
          "table_id": nodeParams.table_id,
          "table_name": nodeParams.table_name,
          "table_type": table_type,
          "filter": nodeParams.filter,
          "time_field": nodeParams.time_field.map(i=> {
            return i.id
          }).join(","),
          "get_time_cycle": nodeParams.get_time_cycle,
          "reader_key": "", //未知
          "groupby_key": "",
          "enabled": 1,
        }
      }
      if (isEdit) {
        params.table_conf.id = nodeParams.id
      }
      saveNode({
        ...params
      }).then(res => {
        if (res.data && res.data.code === 0) {
          SdwMessage.success(isEdit?"修改成功":"新增成功")
          this.props.iniTableViewList(editMidTable.id)
          this.props.changeShowAddSource(false)
        } else {
          let msg = res.data && res.data.data
          SdwMessage.error(msg)
        }
      }, () => SdwMessage.error("接口异常"))
    } else {
      let res = this.ruleNodeViewParams(linkParams)
      if (!res) {
        this.setState({
          sholdViewCheckValidate:true
        })
        return
      }
      params = {
        id: editMidTable.id,
        link_conf: {
          table_name: linkParams.table_name,
          view_type: linkParams.view_type,
          after_filter: linkParams.after_filter,
          left_node_id: linkParams.left_node_id,
          right_node_id: linkParams.right_node_id,
          left_field:"",
          right_field:"",
          concat_type: linkParams.concat_type,
          groupby_key: "",
          enabled: 1
        }
      }

      if (linkParams.view_type == 1 || linkParams.view_type == 2) { //单表聚合或者分桶聚合
        if (Array.isArray(linkParams.groupby_key)) {
          params.link_conf.groupby_key = linkParams.groupby_key.map(i=> {
            return i.id
          }).join(",")
        } else {
          params.link_conf.groupby_key = linkParams.groupby_key
        }
      } else  { //多表连接
        if (Array.isArray(linkParams.left_field)) {
          params.link_conf.left_field = linkParams.left_field.map(i=> {
            return i.id
          }).join(",")
        } else {
          params.link_conf.left_field = linkParams.left_field
        }
        if (Array.isArray(linkParams.right_field)) {
          params.link_conf.right_field = linkParams.right_field.map(i=> {
            return i.id
          }).join(",")
        } else {
          params.link_conf.right_field = linkParams.right_field
        }
      }
      if (isEdit) {
        params.link_conf.id = linkParams.id
        params.link_conf.link_id = linkParams.link_id
      }
      console.log("linkParams", linkParams)
      console.log("linkParams", linkParams)
      console.log("params", params)
      saveLink({
        ...params
      }).then(res => {
        if (res.data && res.data.code === 0) {
          SdwMessage.success(isEdit?"修改成功":"新增成功")
          this.props.iniTableViewList(editMidTable.id)
          this.props.changeShowAddSource(false)
        } else {
          let msg = res.data.data || "异常"
          SdwMessage.error(msg)
        }
      }, () => SdwMessage.error("接口异常"))
    }
  }

  ruleNodeTableParams = (nodeParams) => {
    if (
      !nodeParams.table_id ||
      !nodeParams.time_field || nodeParams.time_field.length == 0
    ) {
      return false
    }
    return true
  }

  ruleNodeViewParams = (linkParams) => {
    if (
      !linkParams.table_name ||
      !linkParams.left_node_id

    ) {
      return false
    }
    if (linkParams.view_type == 1 || linkParams.view_type == 2) { //分桶聚合
      if ((Array.isArray(linkParams.groupby_key) && linkParams.groupby_key.length == 0) || (_.isString(linkParams.groupby_key) && !linkParams.groupby_key)) {
        return false
      }
    } else {
      if (!linkParams.right_node_id) {
        return false
      }
      if (linkParams.concat_type == "join") {
        if ((Array.isArray(linkParams.left_field) && linkParams.left_field.length == 0) || (_.isString(linkParams.left_field) && !linkParams.left_field)) {
          return false
        }
        if ((Array.isArray(linkParams.right_field) && linkParams.right_field.length == 0) || (_.isString(linkParams.right_field) && !linkParams.right_field)) {
          return false
        }
      }
    }
    return true
  }

  initData = () => {
    let {isEdit, currentSource} = this.props
    console.log("currentSource=========-----------------------", currentSource)
    let currentSourceClone = _.cloneDeep(currentSource)
    let data_set_type = "es"
    if (isEdit) {
      if (currentSource.table_type == 0) {
        data_set_type = currentSourceClone.data_set_type
        this.setState({
          table_type:currentSource.table_type,
          nodeParams:currentSourceClone,
        })
        this.getTablecfgDetail(currentSource.table_id)
      } else {
        if (currentSource.view_type == 1 || currentSource.view_type == 2) { // 0多表关联，1单表聚合，2分桶聚合
          this.iniFieldsList({job_id:currentSource.job_id, id: currentSource.left_node_id, link_id:currentSource.link_id}, "table_id")
        } else { //多表关联
          this.iniFieldsList({job_id:currentSource.job_id, id: currentSource.left_node_id, link_id:currentSource.link_id}, "left_node_id")
          this.iniFieldsList({job_id:currentSource.job_id, id: currentSource.right_node_id, link_id:currentSource.link_id}, "right_node_id")
        }
        this.setState({
          table_type:currentSource.table_type,
          linkParams:currentSourceClone
        })
      }
    }
    this.changeDataSetType(data_set_type)
  }

  iniFieldsList = (item, type) => {
    console.log("changeDataSource====item", item)
    let params = {
      id:item.job_id,
      node_id:item.id,
      link_id:item.link_id
    }
    getNodeFields(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || []
        record = record.map(i => {
          return {id:i.field, text:i.field}
        })
        switch (type) {
          case "table_id":
            this.setState({
              tableFieldsList: record
            })
            break
          case "left_node_id":
            this.setState({
              leftNodeFieldsList: record
            })
            break
          case "right_node_id":
            this.setState({
              rightNodeFieldsList: record
            })
            break
        }
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }

  getTablecfgDetail = (id) => {
    getTablecfgDetail({id:id}).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        let timeFieldList = []
        record.fields = record.fields || []
        record.fields.forEach(i => {
          if (i.field_type == "date") {
            timeFieldList.push({id:i.field_name, text:i.field_name})
          }
        })
        this.setState({
          timeFieldList:timeFieldList
        })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    }).catch(error => SdwMessage.error('请求失败 ' + error))
  }

  handleSubmit =() => {
    let nodeParams = this.state.nodeParams
    let {isEdit, editMidTable} = this.props
    saveTable({
      id:editMidTable.id,
    }).then(res => {
      if (res.data && res.data.code === 0) {
        this.props.changeShowAddSource(false)
        if (isEdit) {
          this.props.updatePage(nodeParams.id)
        } else {
          this.props.updatePage()
        }

        SdwMessage.success(isEdit?"修改成功":"新增成功")
      } else {
        let msg = res.data && res.data.data
        SdwMessage.error(msg)
      }
    }, () => SdwMessage.error("接口异常"))
  }

  formatDate = date => {
    if (_.isArray(date)) {
      return date.map((item, index) => {
        let time = index === 0 ? '00:00:00' : '23:59:59'
        return `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate()} ${time}`
      })
    } else {
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()

      let dateStr =  year+ "-"
      dateStr += (month < 10 ? "0"+month:month) + "-"
      dateStr += (day < 10 ? "0"+day:day) + " "
      dateStr += (hours < 10 ? "0"+hours:hours) + ":"
      dateStr += (minutes < 10 ? "0"+minutes:minutes) + ":"
      dateStr += (seconds < 10 ? "0"+seconds:seconds)
      return dateStr
    }
  }

  changeDataSetType = (val) => {
    // 获取表名称
    let paramsData = {
      page: 1,
      limit: 10000,
      search:{
        data_set_type: {
          value: val,
          operator: '='
        },
        warehouse_level: {
          value: ["ODS", "DIM"],
          operator: 'in'
        },
      }

    }
    getMetaDataInfo(paramsData).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        this.setState({
          tableList: record.items,
        })
      } else {
        let msg = res.data.msg || '请求失败'
        SdwMessage.error(msg)
      }
    })
  }
  //设置物理表的数据
  setTableNodeValue = (type, val) => {
    let obj = {}
    switch (type) {
      case "table_id":
        obj = {
          table_id:val.id,
          table_name:val.data_set_chinese,
          table_item:{
            table_id:val.id,
            table_name:val.table_name,
            warehouse_level:val.warehouse_level,
            data_set_chinese:val.data_set_chinese,
            database_name:val.database_name,
          },
        }
        break
      case "timeFieldList":
        this.setState({
          timeFieldList:val
        })
        return
        break
      case "get_time_cycle":
        obj = {get_time_cycle:val}
        break
      case "time_field":
        obj = {time_field:val}
        break
      case "filter":
        obj = {filter:val}
        break
    }
    let nodeParams = this.state.nodeParams
    this.setState({
      nodeParams: Object.assign({}, nodeParams, {
        ...obj
      })
    })
  }
  //设置视图的数据
  setViewNodeValue = (type, val, fields = {}) => {
    let linkParams = this.state.linkParams
    let obj = {}
    switch (type) {
      case "view_type":
        let linkParamsClone = _.cloneDeep(linkParams)
        console.log("===view_type", type)
        obj = {
          view_type:val, // 0多表关联，1单表聚合，2分桶聚合
          link_id: "",  // 连接id，新增不传，编辑传
          table_name: "", //视图名称
          left_node_id: "",  //主表字段id
          right_node_id: "", //副表节点id
          left_field: [], //主表关联字段
          right_field: [], //副表关联字段
          concat_type: "union", //关联类型，有左关联join和合并union
          groupby_key: "", //聚合字段
          enabled: 1, //默认有效
        } // 连线的列表
        this.setState({
          linkParams:Object.assign({}, linkParamsClone, {
            ...obj
          }),
          tableFieldsList:[],
          leftNodeFieldsList:[],
          rightNodeFieldsList:[],
        })
        return
        break
      case "table_name":
        obj = {table_name:val}
        break
      case "groupby_key":
        obj = {groupby_key:val}
        break
      case "left_node_id":
        obj = {
          left_node_id:val.id,
        }
        break
      case "tableFieldsList":
        this.setState({
          tableFieldsList: val
        })
        return
        break
      case "table_id":
        obj = {
          left_node_id:val.id,
        }
        break
      case "right_node_id":
        obj = {
          right_node_id:val.id,
        }
        break
      case "leftNodeFieldsList":
        this.setState({
          leftNodeFieldsList: val
        })
        return
        break
      case "rightNodeFieldsList":
        this.setState({
          rightNodeFieldsList: val
        })
        return
        break
      case "concat_type":
        obj = {
          concat_type:val,
        }
        break
      case "left_field":
        obj = {
          left_field:val,
        }
        break
      case "right_field":
        obj = {
          right_field:val,
        }
        break
      case "after_filter":
        obj = {
          after_filter:val,
        }
        break
    }
    this.setState({
      linkParams: Object.assign({}, linkParams, {
        ...obj
      })
    })
  }
  render () {
    let {
      showAddSource,
      changeShowAddSource,
      isEdit,
    } = this.props

    let {
      nodeParams,
      linkParams,
      sholdTableCheckValidate,
      sholdViewCheckValidate,
      tableList,
      timeFieldList,
      timeCycleList,
      tableFieldsList,
      leftNodeFieldsList,
      rightNodeFieldsList,
      table_type,
    } = this.state
    console.log("linkParams", linkParams)
    console.log("nodeParams", nodeParams)
    return (
      <div>
        <SdwDialog
          title={isEdit?"编辑数据源":"新增数据源"}
          visible={showAddSource}
          width={640}
          height={800}
          zIndex={1010}
          changeVisible={changeShowAddSource}
          onCancelClick={() => changeShowAddSource(false)}
          onSureClick={this.addNode}
        >
          <div className='add-AddSource-win__label'>
            <span className='label__title required'>数据源类型</span>
            <SdwSelect
              width={450}
              disabled={isEdit}
              listWidth={450}
              clearable={false}
              readOnly={false}
              value={table_type}
              data={[{id:0, text:"物理表"}, { id:1, text:"视图"}]}
              onChange={val => {
                this.setState({
                  table_type:val
                })
              }}
            />
          </div>
          {
            table_type == 0&&
              <TableSource
                nodeParams={nodeParams}
                sholdCheckValidate={sholdTableCheckValidate}
                tableList={tableList}
                timeFieldList={timeFieldList}
                timeCycleList={timeCycleList}
                isEdit={isEdit}
                setTableNodeValue={this.setTableNodeValue}
                changeDataSetType={(val)=>{
                  this.setState({
                    nodeParams:_.assign({}, nodeParams, {
                      data_set_type:val
                    })
                  }, ()=>{
                    this.changeDataSetType(val)
                  })
                }}
              />
          }
          {
            table_type == 1&&
              <ViewSource
                sholdCheckValidate={sholdViewCheckValidate}
                linkParams={linkParams}
                tableFieldsList={tableFieldsList}
                leftNodeFieldsList={leftNodeFieldsList}
                rightNodeFieldsList={rightNodeFieldsList}
                isEdit={isEdit}
                setViewNodeValue={this.setViewNodeValue}
              />
          }
        </SdwDialog>
      </div>
    )
  }
}

const mapState = state => ({
  tableConf: state.midTableView.tableConf,
  linkConf: state.midTableView.linkConf,
  physicsTableList: state.midTableView.physicsTableList,
  currentSource: state.midTableView.currentSource,
  cfgData: state.midTableView.cfgData,
})

const mapDispatch = dispatch => ({
  updateTableConf: dispatch.midTableView.updateTableConf,
  updateLinkConf: dispatch.midTableView.updateLinkConf,
  updatePhysicsTableList: dispatch.midTableView.updatePhysicsTableList,
})

export default connect(mapState, mapDispatch)(AddSource)
