import React from 'react'
import _ from 'lodash'
import './index.css'

// id：指标ID
// target_name：指标名称
// target_name_cn：指标中文
// target_type：指标类型（1：普通指标；2：复合指标；3：个性指标；4：衍生指标）

const TYPE_MAP = {
  one: 1,
  two: 2,
  three: 3,
  four: 4
}

const TYPE_MAP_TEXT = {
  [TYPE_MAP.one]: '普通指标',
  [TYPE_MAP.two]: '复合指标',
  [TYPE_MAP.three]: '个性指标',
  [TYPE_MAP.four]: '衍生指标'
}

export default function ConfirmWin(props) {
  let cloneArr =  []
  let typeList = []
  let objByID = []
  if (_.isArray(props.confirmData)) {
    cloneArr = _.cloneDeep(props.confirmData)
    objByID = _.groupBy(cloneArr, 'target_type')
    typeList = _.keys(objByID)
  } else if (_.isString(props.confirmData)) {
    cloneArr = props.confirmData
  }



  return (
    <div className='add-application-win__label'>
      {
        !props.hideTitle &&
        <span className='title'>{ props.title }</span>
      }

      <div className='main'>
        {
          _.isString(cloneArr) ? <div>
            {cloneArr}
            </div> :
            (
              !!typeList.length &&
              typeList.map(type => (
                <>
                  <div key={type} className='type-name'>{ TYPE_MAP_TEXT[type] }</div>
                  {
                    _.isArray(objByID[type]) &&
                    objByID[type].map(i => (
                      <div key={i.id}>{`${i.id} ${i.target_name} （${i.target_name_cn}）`}</div>
                    ))
                  }
                </>
              ))
            )
        }
      </div>
    </div>
  )
}
