import {request} from '../utils/request'

//标签分页
export function getItems(params) {
    return request({url: '/tagmanage/userManager/items', method: 'post', data: params})
}

//保存标签
export function saveConfig(params) {
    return request({url: '/tagmanage/userManager/saveConfig', method: 'post', data: params})
}

//保存标签规则
export function saveRuleConfig(params) {
    return request({url: '/tagmanage/userManager/saveRuleConfig', method: 'post', data: params})
}

//查看标签详情
export const searchDetail = params => {
    return request({url: '/tagmanage/userManager/searchDetail', method: 'get', params: params})
}

//标签启用停用
export function switchStatus(params) {
    return request({url: '/tagmanage/userManager/switchStatus', method: 'post', data: params})
}

//查询变动日志
export function searchChangeLogs(params) {
    return request({url: '/tagmanage/userManager/searchChangeLogs', method: 'post', data: params})
}

//标签测试接口
export function labelTest(params) {
    return request({url: '/tagmanage/userManager/labelTest', method: 'post', data: params})
}

//获取数据源
export function getRuleDataSource(params) {
    return request({url: '/tagmanage/userManager/getRuleDataSource', method: 'get', params: params})
}

//获取数据源
export function getTablecfgList(params) {
    return request({ url: '/metadata/tableApi/getTablecfgList', method: 'get', params: params })
}

//获取运行日志详情
export function searchLogs(params) {
    return request({url: '/tagmanage/userManager/searchLogs', method: 'post', data: params})
}
