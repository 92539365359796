import React, { PureComponent } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import { Button } from 'element-react'
import { getItermSelects, searchFields, searchFieldsForAll, getFieldById, getBusinessStatisticByTopic } from '@services/dataStandars'
import { TABLE_EXTRA_MAP_TEXT } from '../common'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import EditDataStandardWin from '../components/EditDataStandardWin'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import 'element-theme-default'
import './index.css'

class DataStandard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      curTitle: '',
      curRecord: {},
      visible: false,
      curPage: this.props.pageInfo || 0,
      curLimit: 10,
      tableData: {},
      subjectMap: {}, // 主题topic对应的map
      standardOption: [],
      topicOption: [], // 全部主题域
      businessOption: [], // 全部业务
      businessList: [], // 已选主题域对应的业务
      standardValue: '',
      subjectValue: '',
      businessValue: '',
      searchValue: '',
      enableValue: 1,
      hideSearchField: true,
      searchFieldsArr: [], // 用于模糊搜索的
    }
  }

  componentDidMount () {
    this.onGetItermSelects()
    this.onSearchFieldsForAll()

    // 路由跳转携带参数进行过滤
    let query = this.props.location.state || {}
    if (query.typeID) {
      this.setState({
        standardValue: query.typeID
      })
    }
    if (query.topicID) {
      this.setState({
        subjectValue: query.topicID
      })
    }

    if (!query.typeID && !query.topicID) {
      this.onSearchFields()
    }
  }

  onSearchFieldsForAll = () => {
    searchFieldsForAll().then(res => {
      if (res && res.data && res.data.code === 0) {
        let list = _.isArray(res.data.data) ? res.data.data : []
        this.setState({
          searchFieldsArr: list
        })
      }
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      prevState.standardValue !== this.state.standardValue ||
      prevState.subjectValue !== this.state.subjectValue ||
      prevState.businessValue !== this.state.businessValue ||
      prevState.enableValue !== this.state.enableValue
    ) {
      if (prevState.subjectValue !== this.state.subjectValue) {
        this.onGetBusinessByTopic()
      }
      this.onSearchFields()
      }
  }

  getColumns = () => {
    let self = this
    return [
      {
        title: "ID",
        dataIndex: "id",
        width: 80,
      },
      {
        title: "中文名称",
        dataIndex: "field_name_ch",
        width: 100,
      },
      {
        title: "英文名称",
        dataIndex: "field_name",
        width: 150,
      },
      {
        title: "类型",
        dataIndex: "standard_type",
        width: 120,
        render: (data, item) => {
          return (
            <span>
              { TABLE_EXTRA_MAP_TEXT[item.standard_type] || '-' }
            </span>
          )
        }
      },
      {
        title: "主题",
        dataIndex: "topic",
        width: 120,
        render: (data, item) => {
          return (
            <span>
              { this.state.subjectMap[item.topic] || '-' }
            </span>
          )
        }
      },
      {
        title: "描述",
        dataIndex: "field_define",
        showTip: true
      },
      {
        title: "修改时间",
        dataIndex: "modify_time",
        width: 180,
      },
      {
        title: "操作",
        width: 120,
        render: function (data, record) {
          return (
            <span>
              {
                hasPriv('getFieldById') &&
                <Button
                  plain={true}
                  type="text"
                  size="small"
                  onClick={ () => self.jumpToDetail(data) }
                  style={{"fontSize": "14px"}}
                >详情</Button>
              }
              <Button
                type="text"
                size="small"
                onClick={ () => self.addOrEditStandard({
                  title: '编辑数据标准',
                  ...data,
                }) }
                style={{"fontSize": "14px"}}
              >编辑</Button>
            </span>
          )
        }
      }
    ]
  }

  jumpToDetail = record => {
    this.props.history.push({
      pathname: '/admin/dataMarket/standardinfo',
      state: {
        id: record.id
      }
    })
  }

  onGetItermSelects = () => {
    getItermSelects()
      .then(res => {
        if (res && res.data && _.isEqual(res.data.code, 0)) {
          let dataObj = res.data.data || {}
          if (Array.isArray(dataObj.standard_type) && dataObj.standard_type.length) {
            this.setState({
              standardOption: dataObj.standard_type
            })
          }
          if (Array.isArray(dataObj.topic) && dataObj.topic.length) {
            let subMap = {}
            dataObj.topic.forEach(item => {
              subMap[item.id] = item.topic_name
            });
            this.setState({
              topicOption: dataObj.topic,
              subjectMap: subMap
            })
          }
          if (Array.isArray(dataObj.business) && dataObj.business.length) {
            this.setState({
              businessOption: dataObj.business
            })
          }
        }
      })
  }

  // 根据主题域ID请求对应的业务
  onGetBusinessByTopic = () => {
    let { subjectValue } = this.state
    getBusinessStatisticByTopic({id: subjectValue}).then(res => {
        if (res && res.data && res.data.code === 0) {
          let topicArr = res.data.data && res.data.data.list.map(i => {
            return {
              id: i.business_id,
              text: i.business_name
            }
          }) || []
          this.setState({
            businessList: topicArr
          })
        }
      })
  }

  onSearchFields = (params = {}) => {
    let {
      curPage,
      curLimit,
      standardValue,
      subjectValue,
      businessValue,
      searchValue,
      enableValue,
    } = this.state

    let paramsData = {
      page: params.page || curPage,
      limit: params.limit || curLimit,
      search: JSON.stringify({
        ...(params.standard_type || standardValue) ? {
          standard_type: {value: (params.standard_type || standardValue), operator: '='}
        } : {},
        ...(params.topic || subjectValue) ? {
          topic: {value: (params.topic || subjectValue), operator: '='}
        } : {},
        ...(params.business_id || businessValue) ? {
          business_id: {value: (params.business_id || businessValue), operator: '='}
        } : {},
        ...(params.field_name || searchValue) ? {
          field_name: {value: (params.field_name || searchValue), operator: 'like', type: 'both'}
        } : {},
        ...(params.enable || typeof enableValue === 'number') ? {
          enable: {value: (params.enable || enableValue), operator: '='}
        } : {},
      })
    }

    searchFields(paramsData)
      .then(res => {
        if (res && res.data && _.isEqual(res.data.code, 0)) {
          this.setState({
            tableData: res.data.data || {}
          })
        }
      })
  }

  addOrEditStandard = (record = {}) => {
    if(record.title == "编辑数据标准"){
      getFieldById({id: record.id}).then(res => {
        if(res.data.code === 0) {
          this.setState({
            visible: true,
            curTitle: record.title,
            curRecord: res.data.data,
          })
        }
      })
    }else{
      this.setState({
        visible: true,
        curTitle: record.title,
        curRecord: record,
      })
    }
  }

  changeSearch = (type, value) => {
    this.setState({
      curPage: 1,
      [type]:value
    })
  }

  render() {

    let {
      tableData,
      standardValue,
      standardOption,
      subjectValue,
      topicOption,
      businessValue,
      businessOption,
      businessList, // 主题域对应的业务
      searchValue,
      visible,
      curTitle,
      curRecord,
      enableValue,
      hideSearchField,
    } = this.state

    let searchFieldsArr = this.state.searchFieldsArr.map(i => {
      return {
        id: i.field_name_ch+'',
        name: "【" + i.id + "】" + i.field_name_ch + "（" + i.field_name + "）"
      }
    })
    let filterSearchFieldsArr = searchFieldsArr.filter(i => i.name.indexOf(searchValue) !== -1)

    return (
      <>
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '数据集市',
              path: '/admin/dataMarket/dictionary'
            }, {
              title: '数据标准'
            }
          ]}
        />

        <div className="page-mainbody">
          <div className="page-search">
            <div className="input-wrap">
              <div className="ele-search-input">
                <SdwSelect
                  value={standardValue}
                  label="类型"
                  width={160}
                  data={standardOption.map(el => {
                    return {
                      id: el.id,
                      text: el.standard_type
                    }
                  })}
                  onChange={val => this.changeSearch("standardValue", val)}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  value={subjectValue}
                  width={160}
                  label="主题"
                  data={topicOption.map(el => {
                    return {
                      id: el.id,
                      text: el.topic_name
                    }
                  })}
                  onChange={val => this.changeSearch("subjectValue", val)}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  value={businessValue}
                  width={160}
                  label="业务"
                  data={subjectValue ? businessList : businessOption.map(el => {
                    return {
                      id: el.id,
                      text: el.business_name
                    }
                  })}
                  onChange={val => this.changeSearch("businessValue", val)}
                />
              </div>
              <div className="ele-search-input">
                <SdwSelect
                  value={enableValue}
                  width={160}
                  label="是否有效"
                  data={[
                    {
                      id: 1,
                      text: '有效'
                    }, {
                      id: 0,
                      text: '无效'
                    }
                  ]}
                  onChange={val => this.changeSearch("enableValue", val)}
                />
              </div>
              <div className="ele-search-input">
                <SdwInput
                  width={150}
                  label="标准名"
                  value={searchValue}
                  onEnterKeyDown={() => ({})} // enter或触发onblur事件，所以这里传空func
                  onFocus={() => this.setState({
                    curPage: 1,
                    hideSearchField: false
                  })}
                  onBlur={val => {
                    this.onSearchFields({
                      field_name: val
                    })
                    setTimeout(() => {
                      this.setState({
                        hideSearchField: true
                      })
                    }, 500);
                  }}
                  onChange={val => this.setState({searchValue: val})}
                />
                {
                  _.isArray(filterSearchFieldsArr) &&
                  !!filterSearchFieldsArr.length &&
                  !hideSearchField &&
                  <div className="sdw-data-standard__search-input-list-wrap">
                    {
                      filterSearchFieldsArr.map(item => (
                        <div
                          className="ellipsis"
                          title={item.name}
                          onClick={() => {
                            this.onSearchFields({
                              field_name: item.id
                            })
                            this.setState({
                              hideSearchField: true,
                              searchValue: item.id
                            })
                          }}>{item.name}</div>
                      ))
                    }
                  </div>
                }
              </div>
              <div className="btn-group">
                <Button
                  type="primary"
                  icon='plus'
                  onClick={ () => this.addOrEditStandard({ title: '新增数据标准' }) }
                >新增</Button>
              </div>
            </div>
          </div>
          <div className="page-table">
            <SdwTable
              columns={ this.getColumns() }
              tdWhiteSpace={ true }
              dataSource={ tableData.items || [] }
            />
            {
              _.isArray(tableData.items) && tableData.items.length &&
              <div className="pagination-wrap">
                <div className="pagination-text">{ tableData.statistic }</div>
                <SdwPagenation
                  pageStyle={{ marginTop: 0 }}
                  total={ tableData.total }
                  currentPage={ tableData.current_page }
                  pageCountList={[10, 15, 20, 40]}
                  pageCount={ tableData.per_page }
                  getClickPage={ page => {
                    this.setState({
                      curPage: page
                    })
                    this.onSearchFields({page})
                  } }
                  pageCountChange={ limit => {
                    this.setState({
                      curLimit: limit
                    })
                    this.onSearchFields({limit})
                  } }
                />
              </div>
            }
          </div>
        </div>

        <EditDataStandardWin
          title={curTitle}
          visible={visible}
          record={curRecord}
          standardOption={standardOption}
          topicOption={topicOption}
          onIconClick={() => this.setState({ visible: false })}
          onCancelClick={() => this.setState({ visible: false })}
          onSureClick={() => {
            this.setState({ visible: false })
            this.onSearchFields()
          }}
        />
      </>
    )
  }
}

const mapState = state => ({
  pageInfo: state.page.pageInfo
})

export default withRouter(connect(mapState)(DataStandard))
