import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './index.css'

class SdwInput extends Component {

  static defaultProps = {
    width: '100%',
    rows: 3,
    cols: 28,
    placeholder: '请输入',
    clearable: true,
    value: '',
    disabled: false,
    sholdCheckValidate: false,
    error: {
      isError: false,
      msg: ''
    },

    onChange: () => {},
    onBlur: () => {},
  }

  state = {
    inputValue: this.props.value || '',
    isOnFocus: false,
    showClearIcon: false,
    inputValueIsChange: false,
  }

  componentDidMount() {
    const value = this.props.value
    if (value !== '') this.setState({ inputValue: value })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value })
    }
  }

  handleChange = e => {
    if (this.props.disabled) return
    let value = e.target.value
    this.setState({
      inputValue: value,
      inputValueIsChange: true
    })
    this.props.onChange(value)
  }
  
  handleBlur = e => {    
    this.setState({
      isOnFocus: false,
      inputValueIsChange: false
    })
    this.props.onBlur(e.target.value)
  }

  handleClearInput = e => {
    e.preventDefault()
    this.setState({ inputValue: '' })
    this.props.onChange('')
  }

  render() {
    let { placeholder, disabled, width, rows, cols, clearable, error } = this.props
    let { inputValue, showClearIcon, isOnFocus, inputValueIsChange } = this.state

    let textareaClassName = 'sdw-textarea-input__wrap'
    if (error.isError) {
      textareaClassName += ' sdw-textarea-input-error'
    }

    let wrapDivClassName = 'sdw-textarea-input__div-wrap'
    if (isOnFocus) {
      wrapDivClassName += ' sdw-textarea-input__on-focus'
    }

    return (
      <div
        onMouseEnter={() => this.setState({ showClearIcon: true })}
        onMouseLeave={() => this.setState({ showClearIcon: false })}
        className={wrapDivClassName}
        style={{ width }}
      >
        <textarea
          rows={rows}
          cols={cols}
          style={{ width }}
          value={inputValue}
          disabled={disabled}
          placeholder={placeholder}
          className={textareaClassName}
          onChange={e => this.handleChange(e)}
          onFocus={() => this.setState({ isOnFocus: true })}
          onBlur={e => this.handleBlur(e)}
        />
        {
          !disabled && clearable && showClearIcon && !!inputValue && 
          <i className='sdw-textarea-input-clearable' onClick={this.handleClearInput}></i>
        }
        {
          !inputValueIsChange && error.isError && <div className='sdw-textarea-input-error__tip'>{error.msg}</div>
        }
      </div>
    )
  }
}

SdwInput.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  sholdCheckValidate: PropTypes.bool, // true: 手动触发必填项的检查
  error: PropTypes.object
}

export default SdwInput
