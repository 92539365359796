import React, {PureComponent} from 'react'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwDialog from '@common/Dialog'
import SdwSwitch from '@common/Switch'
import './index.css'

class ReportWin extends PureComponent {

    validateFun = val => {
        if (!val) {
            return '该输入项不能为空'
        }
        return true
    }

    closeDialog = () => {
        this.props.closeDialog()
    }

    render() {
        let {
            item,
            staffUserList,
            reportDialogVisible,
            title,
            handleSubmit,
            setPathTreeData,
            staffItems,
            treeData
        } = this.props

        let curPermissionsObj = item.data.permissions || []

        return (
            <div>
                <SdwDialog
                    title={title}
                    visible={reportDialogVisible}
                    width={1200}
                    onSureClick={handleSubmit}
                    onCancelClick={() => this.closeDialog()}
                    lockScroll={false}
                >
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title required'>报表名称</span>
                        <SdwInput
                            width={1000}
                            listWidth={1000}
                            placeholder={'请输入'}
                            value={item.data.path_name}
                            onChange={val => setPathTreeData('path_name', val)}
                            sholdCheckValidate={item.sholdCheckValidate}
                            validateFun={this.validateFun}
                        />
                    </div>
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title required'>报表地址</span>
                        <SdwInput
                            width={1000}
                            listWidth={1000}
                            placeholder={'请输入'}
                            value={item.data.uri}
                            onChange={val => setPathTreeData('uri', val)}
                            sholdCheckValidate={item.sholdCheckValidate}
                            validateFun={this.validateFun}
                        />
                    </div>
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title'>所有人可见</span>
                        <SdwSelect
                            width={1000}
                            listWidth={1000}
                            data={[
                                {
                                    id: 0,
                                    text: '否'
                                }, {
                                    id: 1,
                                    text: '是'
                                }
                            ]}
                            value={item.data.is_white_node}
                            onChange={val => setPathTreeData('is_white_node', val)}
                        />
                    </div>
                    {!item.data.is_white_node &&
                        <div className='visualWin-win__label'>
                            <span style={{width: "80px"}} className='label__title'>权限控制</span>
                            <SdwSelect
                                width={1000}
                                listWidth={1000}
                                placeholder={'请输入'}
                                disabled={!!item.data.is_white_node}
                                readOnly={false}
                                isMultipleChoice={true}
                                data={staffUserList.map(i => {
                                    return {
                                        id: i.LoginName,
                                        text: i.FullName
                                    }
                                })}
                                value={curPermissionsObj}
                                getCurrentVal={val => staffItems(val)}
                                onChange={val => setPathTreeData('permissions', val)}
                            />
                        </div>
                    }
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title'>移动目录</span>
                        <SdwSelect
                            width={1000}
                            listWidth={1000}
                            placeholder={'请选择'}
                            data={treeData.map(i => {
                                return {
                                    id: i.id,
                                    text: i.path_name
                                }
                            })}
                            value={item.data.pid}
                            onChange={val => setPathTreeData('pid', val)}
                        />
                    </div>
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title'>备注</span>
                        <SdwInput
                            type='textarea'
                            width={1000}
                            listWidth={1000}
                            placeholder={'请输入'}
                            value={item.data.remark}
                            onChange={val => setPathTreeData('remark', val)}
                        />
                    </div>
                    <div className='visualWin-win__label'>
                        <span style={{width: "80px"}} className='label__title required'>是否有效</span>
                        <SdwSwitch
                            value={item.data.enabled}
                            changeValue={val => setPathTreeData('enabled', val)}
                        />
                    </div>
                </SdwDialog>
            </div>
        )
    }
}

export default ReportWin
