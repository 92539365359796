import React, { PureComponent } from 'react'
import SdwTable from '@common/Table'
import { Button } from "element-react"
import _ from 'lodash'
import { formatJsonParse } from '@utils/formatJson'
import DialogShowFilterData from './DialogShowFilterData'

class AddFilter extends PureComponent {
  state = {
    index:-1,
    isShow: true,
    enable: true,
    dialogVisible: false,
    addData: [],
    filterDataList:[],
    currentRecord: {},
  }
  componentDidMount() {
    let filterDataList = [...this.props.filterDataList]
    let enable = filterDataList.length > 0 ? true : false
    this.setState({
      enable: enable,
      filterDataList: filterDataList
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.filterDataList != this.props.filterDataList) {
      let enable = this.props.filterDataList.length > 0 ? true : false
      let filterDataListNew = [...this.props.filterDataList]
      this.setState({
        enable: enable,
        filterDataList: filterDataListNew
      })
    }
  }

  showFilterContent = () => {
    let isShow = this.state.isShow
    this.setState({
      isShow: !isShow
    })
  }
  //过滤型-设置数据
  setAddFilterValue = (type, value) => {
    let currentRecord = {...this.state.currentRecord}
    let arrChangeOne = _.cloneDeep(currentRecord)
    switch (type) {
      case "cal_expression" :
        arrChangeOne.cal_expression = value
        break
      case "target_name" :
        arrChangeOne.target_name = value
        break
      case "target_name_cn" :
        arrChangeOne.target_name_cn = value
        break
      case "time_schedule" :
        let time_schedule = JSON.parse(this.props.baseInfo.time_schedule)
        let time_schedules = []
        for (let i in time_schedule) {
          for (let j in value) {
            if (time_schedule[i].time_cycle == value[j].id) {
              time_schedules.push(time_schedule[i])
            }
          }
        }
        arrChangeOne.time_schedule = time_schedules
        break
    }
    arrChangeOne.enable = 0
    this.setState({
      currentRecord: arrChangeOne
    })
  }
  // 设置排名维度值
  setRankDimension = (value) => {
    let currentRecord = {...this.state.currentRecord}
    currentRecord.store_dimension = !!value ? [{ id: [value] }] : [{ id: [] }]
    currentRecord.enable = 0
    this.setState({
      currentRecord: currentRecord
    })
  }
  handleSubmit = () => {
    let flag = this.validateIndicateItemValue()
    if (!flag) {
      return
    }
    let {currentRecord, filterDataList, index} = this.state
    let filterDataListNew = _.cloneDeep(filterDataList)
    if (index == -1) {
      filterDataListNew.push(currentRecord)
    } else {
      filterDataListNew[index] = currentRecord
    }
    console.log("======submit_currentRecord", currentRecord)
    console.log("======filterDataListNew", filterDataListNew)
    this.setState({
      dialogVisible:false,
      currentRecord:{},
      filterDataList:filterDataListNew
    })
    this.props.syncFilterDataList(filterDataListNew)
    // this.setState(function(prevState, props){
    //   props.syncFilterDataList(filterDataListNew)
    //   return {
    //     dialogVisible:false,
    //     currentRecord:{},
    //     filterDataList:filterDataListNew
    //   }
    // });
  }
  addIndicators = () => {
    let name = "filter"
    let array = this.state.filterDataList
    // 往formData里面push空的类型
    let obj = {
      key: `${name}${array.length}`,
      metric_type: "filter",
      metric_field: "",
      label: "过滤型",
      cal_expression: "",
      enable: 0,
      store_dimension: [{ id: [] }],
      time_schedule: [{ run_cycle: '', time_cycle: "" }],
      disabledCheck: false,
      isShow: true,
      target_name: "",
      target_name_cn: "",
    }
    this.setState({
      index:-1,
      currentRecord:obj,
      dialogVisible: true
    })
    // array.push(obj)
    // this.setState({
    //   showAddList: false,
    //   filterDataList: array
    // })
  }
  handleEdit = (data, index) => {
    this.setState({
      index:index,
      dialogVisible: true,
      currentRecord:data
    })
  }
  enableStateChange = (val) => {
    let currentRecord = {...this.state.currentRecord}
    if (val !== 0) {
      let flag = this.validateIndicateItemValue()
      if (!flag) return
    }
    currentRecord.disabledCheck = false //为 0 直接fasle 为1上边通过校验置为false
    currentRecord.enable = val
    this.setState({
      currentRecord:currentRecord
    })
  }
  validateIndicateItemValue = () => {
    // 区分是哪个指标 对应的指标check 不通过把对应指标的data里的disabledCheck置为true
    let currentRecord = {...this.state.currentRecord}
    let flag = currentRecord.store_dimension[0].id.length > 0 &&
      !!currentRecord.time_schedule.length > 0 &&
      !!currentRecord.time_schedule[0].time_cycle &&
      !!currentRecord.cal_expression &&
      !!currentRecord.metric_type &&
      !!currentRecord.target_name &&
      !!currentRecord.target_name_cn
    if (!flag) {
      currentRecord.disabledCheck = true
    } else {
      currentRecord.disabledCheck = false
    }
    this.setState({
      currentRecord:currentRecord
    })
    return flag
  }
  getColumns = () => {
    let self = this
    const columns = [
      {
        title: "指标名称",
        width: 160,
        render: (data, record) => {
          return (
            <span>{`${data.target_name_cn}(${data.target_name})`}</span>
          )
        }
      },
      {
        title: "状态",
        render: function(data, record) {
          return (
            <>
              {
                _.isEqual(typeof data.enable, 'number') && <span className={
                  data.enable ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                }></span>
              }
              <span>{_.isEqual(typeof data.enable, 'number') ?
                (data.enable ? '有效' : '无效') : '-'}</span>
            </>
          )
        }
      },
      {
        title: "过滤维度",
        width: 80,
        render: function(data, recode) {
          let sd = data.store_dimension
          if (_.isArray(sd)) {
            sd = sd.map(i => {
              const arr = _.isArray(i.id) ? i.id : []
              return arr.join('#')
            }).join('/')
          }
          return sd || "-"
        }
      },
      {
        title: "过滤语句",
        width: 96,
        dataIndex: "cal_expression",
      },
      {
        title: "操作",
        width: 80,
        render: (data, index) => {
          return <Button
            type="text"
            size="small"
            onClick={() => this.handleEdit(data, index)}
            style={{ "fontSize": "14px" }}
          >编辑</Button>
        },
      }
    ]
    return columns
  }
  setAdd = (dialogVisible) => {
    this.setState({
      dialogVisible: dialogVisible
    })
  }

  render() {
    const { index,isShow,enable, filterDataList, dialogVisible, currentRecord } = this.state
    const { baseInfo, rankDimensionData, timeCycle } = this.props
    return (
      <div>
        <div className="sdw-collapse-listitem">
          <div className="sdw-collapse-listitem-title" onClick={() => this.showFilterContent()}>
            <div className='sdw-collapse-listitem-title-text' style={{ 'color': enable ? '#265CF0' : '' }}>衍生指标-过滤型
            </div>
            <div><i
              className={`sdw-collapse__icon sdw-collapse__drop-down ${isShow ? 'sdw-collapse__drop-up' : ''}`}></i>
            </div>
          </div>
          <div className="sdw-collapse-listitem-content" style={{ 'display': isShow ? 'block' : 'none' }}>
            <div className="form data-editor-win-wrap">
              <SdwTable
                tdWhiteSpace={true}
                columns={this.getColumns()}
                dataSource={filterDataList}
              />
              <div className="sdw-collapse-listitem-title sdw-collapse-listitem-title-increatment">
                <div className='sdw-collapse-listitem-title-text2'>
                  <span className='sdw-collapse-listitem-plus' onClick={() => {
                    this.addIndicators()
                  }}>新增</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** 预览数据弹窗 */}
        {
          dialogVisible &&
          <DialogShowFilterData
            closeDialog={() => {
              this.setAdd(false)
            }}
            index={index}
            dialogVisible={dialogVisible}
            item={currentRecord}
            rankDimensionData={rankDimensionData}
            handleSubmit={this.handleSubmit}
            setRankDimension={this.setRankDimension}
            timeCycle={timeCycle}
            baseInfo={baseInfo}
            enableStateChange={this.enableStateChange}
            setAddFilterValue={this.setAddFilterValue}
            closeDialog={() => {
              this.setAdd(false)
            }}
          />
        }
      </div>
    )
  }
}
export default AddFilter
