import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwSelect from '@common/Select'
import SdwCheckbox from '@common/Checkbox'
import SdwMessage from '@common/Message'
import { DateRangePicker } from 'element-react'
import { runTargetResult, searchDistinctTarget } from '@services/application'
import {taskReRun } from '@services/midTable'
import { formatTime } from '@utils/formatTime'
import { commonApi } from '@utils/request'

//import './style/reRunWin.css'

export default class ReRunWin extends React.Component {
  state = {
    dataPicker: '',
    is_cover: false,
    multipleValue: [],
    allTargetArr: [],
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.record.id !== this.props.record.id) {
      this.initData()
    }
  }

  initData = () => {
    this.setState({
      dataPicker: '',
      isChecked: false,
      isChecked2: false,
      multipleValue: []
    })

    // search={"table_id":{"value":61,"operator":"="}}
    if (!this.props.record.id) return
    commonApi(searchDistinctTarget, {
      search: {
        table_id: {
          value: this.props.record.id,
          operator: "="
        }
      }
    }).then(arr => {
      if (!_.isArray(arr)) return
      const allTargetArr = arr.reduce((prev, i) => {
        if (prev.every(j => i.target_id != j.id)) {
          prev.push({
            id: i.target_id,
            text: `${i.target_name_en}(${i.target_name_cn || '-'})`
          })
        }
        return prev
      }, [])
      this.setState({
        allTargetArr
      })
    })
  }

  handleSubmit = () => {
    let { dataPicker, is_cover} = this.state
    let { record} = this.props
    console.log("========record", record)
    // 未选择时间，不下发重跑
    if (!dataPicker || dataPicker.length != 2) return
    let params = {}
    params["id"] = record.id
    params["is_cover"] = +is_cover
    if (dataPicker && dataPicker.length > 0) {
      params["start_time"] = formatTime(+new Date(dataPicker[0]), 0)
      params["end_time"] = formatTime(+new Date(dataPicker[1]), 0)
    }
    taskReRun(params).then(res => {
      let record = res && res.data || {}
      if (record.code === 0) {
        SdwMessage.success(`中间表重跑任务提交成功`)
        this.props.changeReRunVisible(false)
        return
      }
      const errMsg = record.msg || `中间表重跑任务提交失败`
      SdwMessage.error(errMsg)
    }, () => SdwMessage.error("接口异常"))
  }

  render () {
    let { reRunVisible, changeReRunVisible } = this.props

    let { dataPicker, is_cover } = this.state

    return (
      <SdwDialog
        title='重跑中间表'
        visible={reRunVisible}
        changeVisible={changeReRunVisible}
        onCancelClick={() => changeReRunVisible(false)}
        onSureClick={this.handleSubmit}
        width={550}
        height={300}
      >
        <div className="sdw-re-run-win__date-range-picker-wrap">
          <span className='required'  style={{ marginRight: '32px', wordBreak: 'keep-all' }}>时间范围</span>
          <DateRangePicker
            value={dataPicker}
            isShowTime={true}
            isReadOnly={true}
            width={450}
            rangeSeparator=" 至 "
            placeholder="请选择重跑的时间范围"
            format="yyyy-MM-dd HH:mm:ss"
            align="right"
            disabledDate={time=>time.getTime() > (Date.now() + 24*60*60*1000)}
            onChange={date => {
              this.setState({ dataPicker: date })
            }}
          />
        </div>
        <div className="sdw-re-run-win__date-range-picker-wrap" style={{marginTop: 16, display: 'flex'}}>
          <div className="sdw-re-run-win__checkBox">
            <SdwCheckbox
              text="测试环境覆盖式更新（按最新配置重新加工）"
              isChecked={is_cover}
              changeCheckedFun={() => {
                this.setState({
                  is_cover: !is_cover
                })
              }}
            />
          </div>
          {/*<span style={{ marginRight: '16px', transform: 'translateY(10px)', wordBreak: 'keep-all' }}>覆盖式更新（按最新配置重新加工）</span>*/}
        </div>
      </SdwDialog>
    )
  }
}
