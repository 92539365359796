// @file 导出记录
import { useState, useEffect, useMemo } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import Input from '@common/Input'
import Select from '@common/Select'
import Table from '@common/Table'
import Message from '@common/Message'
import Pagenation from '@common/Pagenation'
import Button from '@common/Button'
import DatePicker from '@common/DatePicker'
import { exportTaskItems } from '@services/dataSearch'
import { commonApi, fileDownload } from '@utils/request'
import { getRangeDateTime } from '@utils/formatTime'
import { TYPE_MAP, TYPE_TEXT_MAP, TYPE_CLASS_NAEM_MAP } from '../common'
import { debounce } from '@utils/common'

import useAllTablecfg from '@pages/common/useAllTablecfg'
import './index.css'

function ExportRecord(props) {

  const tableArr = useAllTablecfg()

  const [curRecord, setCurRecord] = useState({})
  const [apiParams, setApiParams] = useState({
    page: props.pageInfo || 1,
    limit: 10
  })
  const [searchParams, setSearchParams] = useState({})
  const [curTabID, setCurTabID] = useState('')
  const [createUser, setCreateUser] = useState('')

  useEffect(() => {
    setApiParams(r => _.assign({}, r, {
      page: 1
    }))
    onSetSearchParams('create_user', createUser)
  }, [createUser])

  useEffect(() => {
    const curSearchParams = _.keys(searchParams).reduce((prev, key) => {
      if (searchParams[key] === '') return prev

      // 时间用 range
      if (key === 'create_time') {
        prev[key] = {
          value: _.isArray(searchParams[key]) ? getRangeDateTime(searchParams[key]) : '',
          operator: 'range'
        }
      } else {
        const isLikeType = ['table_name', 'create_user'].includes(key)
        prev[key] = {
          value: isLikeType ? `%${searchParams[key]}%` : searchParams[key],
          operator: isLikeType ? 'like' : '='
        }
      }

      return prev
    }, {})

    commonApi(exportTaskItems, _.assign({}, apiParams, {
      search: JSON.stringify(curSearchParams)
    })).then(data => {
      if (!_.isEmpty(data)) setCurRecord(data)
    }).catch(msg => Message.error(msg || '获取数据失败！'))
  }, [apiParams, searchParams])

  function getcolumns() {
    return [
      {
        width: 20,
        title: 'ID',
        dataIndex: 'id',
      }, {
        title: '表英文名',
        width: 200,
        render: data => <span>{ !!(data.database_name) ? `${data.database_name}.${data.table_name}` : !!(data.table_name) ? data.table_name : '-'}</span>
      }, {
        title: '表中文名',
        width: 140,
        render: data => <span>{ tableArr.find(i => i.id === data.table_id) && tableArr.find(i => i.id === data.table_id).data_set_chinese || '-' }</span>
      }, {
        title: '查询条件',
        dataIndex: 'search',
        width: 300,
      }, {
        title: '数据量',
        width: 80,
        dataIndex: 'data_total',
      }, {
        title: '发起时间',
        dataIndex: 'create_time',
        width: 140
      }, {
        title: '任务状态',
        render: data => (
          <span className={TYPE_CLASS_NAEM_MAP[data.status]}>{TYPE_TEXT_MAP[data.status]}</span>
        )
      }, {
        title: '导出人',
        dataIndex: 'create_user',
      }, {
        title: '操作',
        width: 180,
        render: data => {
          return <>
            <Button
              text='下载文件'
              tip={data.status !== TYPE_MAP.done ? '任务未完成' : (!data.is_download ? '没有权限' : '')}
              disabled={!data.is_download}
              onClick={() => fileDownload(`/metadata/tableApi/download?id=${data.id}`)}
            />
            <Button
              text='再次按模板导出'
              onClick={() => {
                console.log("----data", data)
                props.history.push({
                  pathname: '/admin/dataGasStation/searchEport/'+data.table_id,
                  state: {
                    data: data,
                    curMenuIndex: 1
                  }
                })
              }}
            />
            </>
        }
      },
    ]
  }

  const onSetSearchParams = debounce((key, val) => {
    setSearchParams(r => _.assign({}, r, {
      [key]: val
    }))
  })

  function disabledDateFun(time) {
    return time.getTime() > Date.now()
  }

  const stateMap = useMemo(() => {
    return _.keys(TYPE_TEXT_MAP).map(key => ({
      id: key,
      text: TYPE_TEXT_MAP[key]
    }))
  }, [TYPE_TEXT_MAP])

  return (
    <div style={{
      margin: '20px 0'
    }}>
      <div className='data-search__export-record__search-wrap'>

        <div style={{ display: 'inline-block', marginRight: 8 }}>
          <DatePicker
            label='导出日期'
            showDateRangePickePage={true} // true: 表示选择日期范围   fasle或不配置: 表示选择日期点
            disabledDateFun={disabledDateFun}  // 禁用日期
            value={searchParams.create_time || ''}  // 绑定的value值：单个是字符串：'2020-01-01'; 范围是数组：['2020-01-01', '2020-02-08']
            changeValue={val => onSetSearchParams('create_time', val)}  // 改变value的函数
          />
        </div>

        <Select
          label='表名'
          value={curTabID}
          width={220}
          listWidth={400}
          onChange={(val, item) => {
            setApiParams(r => _.assign({}, r, {
              page: 1
            }))
            onSetSearchParams('table_name', item && item.table_name || '')
            setCurTabID(val)
          }}
          data={tableArr}
          readOnly={false}
        />
        <Select
          label='任务状态'
          value={searchParams.status || ''}
          width={220}
          onChange={val => {
            setApiParams(r => _.assign({}, r, {
              page: 1
            }))
            onSetSearchParams('status', val)
          }}
          data={stateMap}
        />
        <Input
          label='导出人'
          value={createUser || ''}
          width={220}
          onChange={setCreateUser}
        />
      </div>

      <div style={{
        marginTop: 16
      }}>
        <Table
          columns={getcolumns()}
          dataSource={ _.isArray(curRecord.items) ? curRecord.items : [] }
        />

        <Pagenation
          total={curRecord.total || 0}
          currentPage={curRecord.current_page || 1}
          getClickPage={val => setApiParams(r => _.assign({}, r, {
            page: val
          }))}
          pageCountChange={val => setApiParams(r => _.assign({}, r, {
            limit: val
          }))}
        />
      </div>
    </div>
  )
}

const mapState = state => ({
  pageInfo: state.page.pageInfo
})

export default withRouter(connect(mapState)(ExportRecord))