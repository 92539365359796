import Table from '@common/Table'
import Pagenation from '@common/Pagenation'
import { useEffect, useState } from 'react'

import {searchChangeLogs} from '@services/labelProcess'
import _ from 'lodash'

const OPERATE_MAP = {
    "add":"新增规则",
    "edit":"编辑规则"
}


function LogPage(prop) {
    let labelDetail = prop.labelDetail

    const [dataSource, setDataSource] = useState({})
    const [searchParams, setSearchParams] = useState({
        limit: 10,
        page: 1
    })

    useEffect(()=>{
        let params = {
            "id":labelDetail.id,   // 标签id
            ...searchParams,
            "search":{}
        }
        searchChangeLogs(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let dataSource = res.data.data || {}
                setDataSource(dataSource)
            }
        })

    }, [searchParams])

    function updateSearchParams(type, val) {
        setSearchParams(r => _.assign({}, r, {
            [type]: val
        }))
    }

    function getColumns() {
        return [
            {
                title: '操作',
                render:(data)=>{
                    return (
                      <span>{OPERATE_MAP[data.oper]}</span>
                    )
                },
                width:20,
            },
            {
                title: '变更时间',
                dataIndex: 'addtime',
                width:60,
            },
            {
                title: '处理人',
                dataIndex: 'username',
                width:20,
            },
            {
                title: '变更日志',
                dataIndex: 'newinfo',
                width:500,
            }
        ]
    }

    return (
        <div style={{padding: 16}}>
            <Table
                columns={getColumns()}
                dataSource={_.isArray(dataSource.items) ? dataSource.items : []}
            />
            <Pagenation
              total={dataSource.total || 0}
              currentPage={dataSource.current_page || 1}
              getClickPage={val => updateSearchParams('page', val)}
              pageCountChange={val => updateSearchParams('limit', val)}
            />
        </div>
    )
}

export default LogPage
