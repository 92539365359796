// @file 已选指标，前端分页
import { useState, useMemo } from 'react'
import _ from 'lodash'
import Table from '@common/Table'
import Pagenation from '@common/Pagenation'

export default function SelectedAttributes(props) {
  const {
    columns,
    dataSource,
    isDisplayPage
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(30)

  const curDataSource = useMemo(() => {
    if (!_.isArray(dataSource) || !dataSource.length) return []
    const start = (currentPage - 1) * pageCount
    const end = currentPage * pageCount
    return dataSource.slice(start, end)
  }, [currentPage, pageCount, dataSource])

  const Total = _.isArray(dataSource) ? dataSource.length : 0

  return (
    <div style={{ padding: '10px', height: document.body.offsetHeight - 398, overflow: 'overlay' }}>
      <Table
        tdWhiteSpace={true}
        columns={columns()}
        dataSource={curDataSource}
      />

      {isDisplayPage&&<Pagenation
        total={ Total }
        currentPage={ currentPage }
        pageCount={ pageCount }
        // pageCountList={ [15, 30, 40, 50] }
        getClickPage={ val => setCurrentPage(val) }
        pageCountChange={ val => setPageCount(val) }
      />}
    </div>
  )
}
