import { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import SdwSelect from '@common/Select'
import SdwTable from '@common/Table'
import SdwButton from '@common/Button'
import SdwEnable from '@common/Enable'
import SdwPagenation from '@common/Pagenation'
import EditWin from './EditWin'
import { getArchiveCodeConfItems, getArchiveCodeSelect } from '@services/operationConfiguration'
import '../style/BusinessIndicatorsOperation.css'
import SdwMessage from '@common/Message/index'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

// 1：归档项，2：产品，3：l4
const TYPE_MAP = {
  archive_name: 1,
  service_id: 2,
  ai_code: 3
}

export default function ArchiveL4(props) {

  const [timer, setTimer] = useState(null)
  const [showEditWin, setShowEditWin] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    limit: 10
  })
  const [curItem, setCurItem] = useState({})
  const [tableData, setTableData] = useState({})
  const [archiveMap, setArchiveMap] = useState([])
  const [productMap, setProductMap] = useState([])
  const [l4Map, setL4Map] = useState([])

  useEffect(onGetArchiveCodeConfItems, [])

  useEffect(() => {
    onGetArchiveCodeConfItems(params)
  }, [params])

  function onGetArchiveCodeConfItems(params = {}) {
    if (!_.isEmpty(params)) {
      params = {
        ...params.page ? {
          page: params.page
        } : {},
        ...params.limit ? {
          limit: params.limit
        } : {},
        search: JSON.stringify({
          ...params.service_id ? {
            service_id: {
              value: params.service_id,
              operator: '='
            }
          } : {},
          ...params.archive_name ? {
            archive_code: {
              value: params.archive_name,
              operator: '='
            }
          } : {},
          ...params.ai_code ? {
            ai_code: {
              value: params.ai_code,
              operator: '='
            }
          } : {}
        })
      }
    }
    getArchiveCodeConfItems(params).then(res => {
      const code = _.get(res, 'data.code')
      const msg = _.get(res, 'data.msg')
      if (code === 0) {
        const jasonData = _.get(res, 'data.data')
        setTableData(jasonData)
      } else {
        SdwMessage.error('接口请求异常: ' + msg)
      }
    })
  }

  function getColumns() {
    return [
      {
        title: "ID",
        width: 80,
        dataIndex: "id"
      }, {
        title: "归档项",
        width: 160,
        dataIndex: "archive_name"
      }, {
        title: "L4id",
        width: 80,
        dataIndex: "ai_code"
      }, {
        title: "L4名称",
        width: 100,
        dataIndex: "ai_name"
      }, {
        title: "产品id",
        width: 80,
        dataIndex: "service_id"
      }, {
        title: "产品名称",
        width: 100,
        dataIndex: "service_name"
      }, {
        title: "状态",
        width: 100,
        render: data => (
          <SdwEnable enable={data.enable} />
        )
      }, {
        title: "修改人",
        width: 100,
        dataIndex: "modify_user"
      }, {
        title: "修改时间",
        width: 100,
        dataIndex: "modify_time"
      },
      ...hasPriv('updateArchiveCodeConf') ? [{
        title: "操作",
        width: 80,
        render: data => (
          <>
            <SdwButton
              text="编辑"
              onClick={() => {
                setShowEditWin(true)
                setIsEdit(true)
                setCurItem(data)
              }}
            />
          </>
        )
      }] : []
    ]
  }

  function updateParams(type, val) {
    let cloneParams = _.cloneDeep(params)
    cloneParams[type] = val
    if (type != 'page') {
      cloneParams['page'] = 1
    }
    setParams(cloneParams)
  }

  function onUpdatePage() {
    onGetArchiveCodeConfItems(params)
  }

  function onSearch(type, val) {

    if (timer) clearTimeout(timer)
    let t = setTimeout(() => {
      getArchiveCodeSelect({
        type,
        query: val
      }).then(res => {
        let code = _.get(res, 'data.code')
        if (code === 0) {
          let data = _.get(res, 'data.data')
          data = _.isArray(data) ? data : []
          formatSelectOptions(type, data)
        }
      })

      clearTimeout(timer)
    }, 500)
    setTimer(t)
  }

  function formatSelectOptions(type, data) {
    const list = _.isArray(data) ? data.map(i => {
      return {
        id: i.code,
        text: i.name
      }
    }) : []
    switch (type) {
      case TYPE_MAP.archive_name:
        setArchiveMap(list)
        break;

      case TYPE_MAP.service_id:
        setProductMap(list)
        break;

      case TYPE_MAP.ai_code:
        setL4Map(list)
        break;

      default:
        break;
    }
  }

  function handleOnFocus(type, val) {
    if (!val) onSearch(type, '')
  }

  return (
    <div>
      <div className="page-mainbody">
        <div className="sdw-business-indicators__wrap">
          <span className="sdw-business-indicators-search__wrap">
            <SdwSelect
              placeholder="请输入关键字进行模糊搜索"
              width={250}
              label="归档项"
              readOnly={false}
              value={params.archive_name}
              onInputFilter={val => onSearch(TYPE_MAP.archive_name, val)}
              onFocus={val => handleOnFocus(TYPE_MAP.archive_name, val)} // 首次点击下拉框，触发搜索接口
              data={archiveMap}
              onChange={val => updateParams('archive_name', val)}
            />
          </span>
          <span className="sdw-business-indicators-search__wrap">
            <SdwSelect
                placeholder="请输入关键字进行模糊搜索"
                width={250}
                label="L4名称"
                readOnly={false}
                value={params.ai_code}
                data={l4Map}
                onInputFilter={val => onSearch(TYPE_MAP.ai_code, val)}
                onFocus={val => handleOnFocus(TYPE_MAP.ai_code, val)} // 首次点击下拉框，触发搜索接口
                onChange={val => updateParams('ai_code', val)}
            />
          </span>
          <span className="sdw-business-indicators-search__wrap">
            <SdwSelect
              placeholder="请输入关键字进行模糊搜索"
              width={250}
              label="产品名称"
              readOnly={false}
              value={params.service_id}
              data={productMap}
              onInputFilter={val => onSearch(TYPE_MAP.service_id, val)}
              onFocus={val => handleOnFocus(TYPE_MAP.service_id, val)} // 首次点击下拉框，触发搜索接口
              onChange={val => updateParams('service_id', val)}
            />
          </span>

          <span className="sdw-business-indicators-search__wrap btn-group">
            {
              hasPriv('insertArchiveCodeConf') &&
              <button
                className="sure-button el-icon-plus"
                icon="plus"
                onClick={() => {
                  setShowEditWin(true)
                  setIsEdit(false)
                  setCurItem({})
                }}
              >添加配置</button>
            }
          </span>
        </div>

        <SdwTable
          columns={ getColumns() }
          dataSource={ _.isArray(tableData.items) ? tableData.items : [] }
        />

        <div style={{ marginTop: '16px' }}>
          <SdwPagenation
            total={ tableData.total }
            currentPage={ tableData.current_page }
            pageCountList={[10, 15, 20, 40]}
            pageCount={ tableData.per_page }
            getClickPage={ page => updateParams('page', page) }
            pageCountChange={ limit => updateParams('limit', limit) }
          />
        </div>
      </div>

      {
        showEditWin &&
        <EditWin
          isEdit={isEdit}
          data={curItem}
          showEditWin={showEditWin}
          setShowEditWin={bool => setShowEditWin(bool)}
          updatePage={onUpdatePage}
        />
      }

    </div>
  )
}
