import React, { PureComponent } from 'react'
import SdwDrawer from '../../../../common/Drawer'
import SdwInput from '../../../../common/Input'
import SdwInput2 from '../../../../common/Input2'
import SdwSelect from '../../../../common/Select'
import SdwMessage from '../../../../common/Message'
import { getTableFields, getTablecfgFields, addOrUpdateDimenMap, getDimenById } from '@services/dimen'
import 'element-theme-default'
import './style/EditMapData.css'

class EditData extends PureComponent {

  state = {
    id: "",
    map_name_cn: "",
    map_name_en: "",
    source_dim: "",
    target_dim: "",
    propertyTableId: [['', '']],
    virtualPropertyList: [
      {"id":"","source_attr_id":"","target_attr_id":""}
    ],
    virtualPropertyTwoList: [{"id":"","source_attr_id":"","target_attr_id":""}],
    mapping_type: 1,

    tableFields: [],
    tableFieldsTwo: [],
    propertySourceSqlTables: [
      {
        "id": "",
        "source_attr_id": "",
        "target_attr_id": "",
      }
    ],
    virtualPropertyListInit:[
      {
        "id": "",
        "source_attr_id": "",
        "target_attr_id": "",
      }
    ],
    selectSourceDimAttrList: [],
    sholdCheckValidate: false,
  }

  componentDidMount () {
    this.initData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.record.id !== this.props.record.id) {
      this.initData()
    }
   
    this.setState({propertySourceSqlTables: this.props.propertySourceSqlTables})
  }

  initData = () => {
    let source_dim = this.props.record.source_dim || "";
    this.setState({
      id: this.props.record.id,
      map_name_cn: this.props.record.map_name_cn || "",
      map_name_en: this.props.record.map_name_en || "",
      source_dim: source_dim,
      target_dim: this.props.record.target_dim || "",
      propertySourceSqlTables: this.props.propertySourceSqlTables || [],
      propertyTableId: this.props.record.propertyTableId || [['', '']],
      virtualPropertyList: this.props.record.virtualPropertyList || [{
        "id": "",
        "source_attr_id": "",
        "target_attr_id": "",
      }],
      virtualPropertyTwoList: this.props.record.virtualPropertyTwoList || [{
        "id": "",
        "source_attr_id": "",
        "target_attr_id": "",
      }],
      mapping_type: this.props.record.mapping_type || 0
    })

    // 获取所有的应用表数据
    let propertyTableId = this.props.record.propertyTableId || [['', '']]
    for(let i=0; i<propertyTableId.length; i++) {
      if(propertyTableId[i].length > 0){
        let tid = propertyTableId[i][0]
      
        if(tid != "") {
          this.getTablecfgFields(tid, i)
        }
      }
    }

    // 如果来源维度是普通维度则获取改维度信息
    if(source_dim != "") {
      this.getDimenById(source_dim, 'source')
    }
     
  }
  
  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    
    return `${label}不能为空`
  }

  handleSureClick = () => {
    let {
      id,
      map_name_cn,
      map_name_en,
      source_dim,
      target_dim,
      propertyTableId,
      virtualPropertyList,
      virtualPropertyTwoList,
      mapping_type
    } = this.state

    let isValidatePass =
      !!map_name_cn &&
      !!map_name_en && 
      !!source_dim &&
      !!target_dim

    if (!isValidatePass) {
      // 手动触发必填项的检查
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    let dataid = this.props.record.id || 0

    let params = {
      id: dataid,
      map_name_cn,
      map_name_en,
      source_dim,
      target_dim,
      propertyTableId,
      virtualPropertyList,
      virtualPropertyTwoList,
      tableFieldsIdList: [],
      tableIdList: [],
      mapping_type
    }

    for (let i = 0; i < propertyTableId.length; i++) {
      params.tableFieldsIdList.push(propertyTableId[i][1])
      params.tableIdList.push(propertyTableId[i][0])
    }

    if (mapping_type == 1) {
      params.virtualPropertyTwoList = this.state.virtualPropertyListInit;
    }else {
      params.virtualPropertyList = this.state.virtualPropertyListInit;
    }

    params.propertyTableId = JSON.stringify(params.propertyTableId)

    // 编辑数据
    addOrUpdateDimenMap(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let msg = id === 0 ? '新建成功' : '编辑成功'
        SdwMessage.success(msg)
        // 告诉父级点击了确定按钮
        this.props.onSureClick()
      } else {
        SdwMessage.error(res.data.msg);
      }
    })
  }

  // 获取指定的表的字段
  getTablecfgFields = (related_table_id, index) => {
    let that = this
    let parame = {}
    parame.id = related_table_id
    getTablecfgFields(parame).then(res => {
      let tableFieldsOne = res.data.data || []

      // 表字段
      let tableFieldsOneNew = tableFieldsOne.map(i => {
        return {
          id: i.id,
          text: i.field_name
        }
      })

      let tableFields = [...this.state.tableFields]
      tableFields[index] = tableFieldsOneNew
      that.setState({tableFields: tableFields})
    })
  }

  // 更新数据
  propertyInfoUpData = (val, type, index) => {
    if(type == "propertyTableId-table") {
      let propertyTableId = [...this.state.propertyTableId]
      propertyTableId[index][0] = val 
      this.setState({
        propertyTableId: propertyTableId
      });
      this.getTablecfgFields(val, index)
    }
    if(type == "propertyTableId-field") {
      let propertyTableId = [...this.state.propertyTableId]
      propertyTableId[index][1] = val 
      this.setState({
        propertyTableId: propertyTableId
      });
    }

    if(type == "source_attr_id") {
      let virtualPropertyTwoList = [...this.state.virtualPropertyTwoList]
      virtualPropertyTwoList[index].source_attr_id = val 
      this.setState({
        virtualPropertyTwoList: virtualPropertyTwoList
      });
    }
    if(type == "target_attr_id") {
      let virtualPropertyTwoList = [...this.state.virtualPropertyTwoList]
      virtualPropertyTwoList[index].target_attr_id = val 
      this.setState({
        virtualPropertyTwoList: virtualPropertyTwoList
      });
    }

    if(type == "source_dim") {
      this.setState({
        source_dim: val
      });
      this.getDimenById(val, 'source')
    }

    if(type == "target_dim") {
      this.setState({
        target_dim: val
      });
      this.getDimenById(val, 'target')
    }

    if(type == "virtualPropertyList-one-source_attr_id") {
      let virtualPropertyList = [...this.state.virtualPropertyList]
      virtualPropertyList[index].source_attr_id = val
      this.setState({
        virtualPropertyList: virtualPropertyList
      });
    }

    if(type == "virtualPropertyList-one-target_attr_id") {
      let virtualPropertyList = [...this.state.virtualPropertyList]
      virtualPropertyList[index].target_attr_id = val 
      this.setState({
        virtualPropertyList: virtualPropertyList
      });
    }
  }
  
  addVirtualProperty = (index) => {
    let item = ['', '']
    let propertyTableId = [...this.state.propertyTableId]
    propertyTableId.push(item);
    this.setState({propertyTableId: propertyTableId})
  }

  removeVirtualProperty = (index) => {
    let propertyTableId = [...this.state.propertyTableId]
    propertyTableId.splice(index, 1);
    this.setState({propertyTableId: propertyTableId})
  }

  addVirtualProperty2 = (index) => {
    let item = {
      "id": "",
      "source_attr_id": "",
      "target_attr_id": "",
    }
    let virtualPropertyTwoList = [...this.state.virtualPropertyTwoList]
    virtualPropertyTwoList.push(item);
    this.setState({virtualPropertyTwoList: virtualPropertyTwoList})
  }

  removeVirtualProperty2 = (index) => {
    let virtualPropertyTwoList = [...this.state.virtualPropertyTwoList]
    virtualPropertyTwoList.splice(index, 1);
    this.setState({virtualPropertyTwoList: virtualPropertyTwoList})
  }

  getDimenById = (dimEnName, type) => {
    let dimId = dimEnName;
    let that = this
    getDimenById({ id: dimId }).then(res => {
      if (res.data.code == 0) {
        if (res.data.code === 0) {
          let virtualPropertyList = res.data.data.propertyInfo || []
          
          if(type == "source") {
            that.setState({selectSourceDimAttrList: [...virtualPropertyList]})
          }

          // 英文名生成
          if(that.state.source_dim != "" && that.state.target_dim != "") {
            let name = this.props.sourceDimListDic[this.state.source_dim].dim_name_en + "_" + this.props.targetDimListDic[this.state.target_dim].dim_name_en
            that.setState({map_name_en: name})

            //判断是否选了虚拟维度
            if(this.props.sourceDimListDic[this.state.source_dim].dim_type == 2 || this.props.targetDimListDic[this.state.target_dim].dim_type == 2) {
              this.setState({mapping_type: 2})
            }else{
              this.setState({mapping_type: 1})

              // 获取来源维度配置的来源表的所有字段选项
              let parame = {
                id: this.props.sourceDimListDic[this.state.source_dim].source_table_id
              }
              getTableFields(parame).then(res => {
                
                let tableFieldsTwo = res.data.data.fields || []
                that.setState({
                  tableFieldsTwo: tableFieldsTwo,
                })
                
              })
            }
          }
        }
      }
    })
  }

  render () {
    let {
      map_name_cn,
      map_name_en,
      source_dim,
      target_dim,
      propertyTableId,
      virtualPropertyList,
      virtualPropertyTwoList,
      mapping_type,

      tableFields,
      sholdCheckValidate
    } = this.state

    // 来源表
    let tableList = this.props.tableList.map(i => {
      return {
        id: i.id,
        text: i.data_set_chinese + "(" + i.database_name + "." + i.table_name + ")"
      }
    })

    // 来源维度列表
    let sourceDimList = []
    for(let i = 0; i < this.props.cfgData.sourceDimList.length; i++) {
      let item = this.props.cfgData.sourceDimList[i]
      let itemV = {
        id: item.id,
        text: item.dim_name_cn + '[' + item.dim_name_en + ']'
      }
      // if(item.dim_type == 1) {
      //   sourceDimList.push(itemV)
      // }
      sourceDimList.push(itemV)
    }

    // 目标维度列表
    let targetDimList = []
    for(let i = 0; i < this.props.cfgData.targetDimList.length; i++) {
      let item = this.props.cfgData.targetDimList[i]
      let itemV = {
        id: item.id,
        text: item.dim_name_cn + '[' + item.dim_name_en + ']'
      }
      targetDimList.push(itemV)
    }

    // 维度1表字段
    let tableFieldsTwo = this.state.tableFieldsTwo.map(i => {
      return {
        id: i.id,
        text: i.field_name
      }
    })

    // 维度1来源属性
    let selectSourceDimAttrList = this.state.selectSourceDimAttrList.map(i => {
      return {
        id: i.attr_id_field_id,
        text: i.attr_id
      }
    })

    return (
      <div className="editorMain editorMain-map">
        <SdwDrawer
          title={this.props.title}
          visible={this.props.visible}
          onIconClick={this.props.onIconClick}
          onCancelClick={this.props.onCancelClick}
          onSureClick={this.handleSureClick}
        >
          <SdwDrawer.Body>
            <div className="form data-editor-win-wrap data-editor-win-wrap-map">
              <div className="formInput">
                <span className="label required">中文名</span>
                <span className="content EditMapData-content">
                  <SdwInput
                    value={map_name_cn}
                    validateFun={value => this.validateFun(value, '中文名')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({map_name_cn: val})}
                    placeholder="请输入指标中文描述，最多15个字"
                  />
                </span>
              </div>

              <div className="formInput">
                <span className="label required">英文名</span>
                <span className="content EditMapData-content">
                  <SdwInput
                    disabled
                    value={map_name_en}
                    validateFun={value => this.validateFun(value, '英文名')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({map_name_en: val})}
                    placeholder="由英文、下划线组成，英文请勿大写"
                  />
                </span>
              </div>

              <div className="formInput">
                <span className="label required">来源维度</span>
                <span className="content EditMapData-content">
                  <SdwSelect
                    value={source_dim}
                    readOnly={false}
                    validateFun={value => this.validateFun(value, '来源维度')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.propertyInfoUpData(val, "source_dim")}
                    data={sourceDimList}
                  />
                </span>
              </div>

              <div className="formInput">
                <span className="label required">目标维度</span>
                <span className="content EditMapData-content">
                  <SdwSelect
                    value={target_dim}
                    readOnly={false}
                    validateFun={value => this.validateFun(value, '目标维度')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.propertyInfoUpData(val, "target_dim")}
                    data={targetDimList}
                  />
                </span>
              </div>
              
              <div className="formInput formInput-propertyTableId-add">
                <span className="label">来源维度关联字段</span>
                <span className="content EditMapData-content EditMapData-content-propertyTableId">
                  {propertyTableId.map((row, k) => {
                    return (
                      <div key={k} className="content contentOne">
                        <span className="editorTwo-input-map left">
                          <SdwSelect
                            readOnly={false}
                            value={propertyTableId[k][0]}
                            onChange={val => this.propertyInfoUpData(val, "propertyTableId-table", k) }
                            data={tableList}
                          />
                        </span>

                        <span className="editorTwo-input-map right">
                          <SdwSelect
                            readOnly={false}
                            value={propertyTableId[k][1]}
                            onChange={val => this.propertyInfoUpData(val, "propertyTableId-field", k) }
                            data={tableFields[k]}
                          />
                        </span>

                        <i onClick={() => this.addVirtualProperty(k)} className="el-icon-plus"></i>
                        {k > 0 && (<i onClick={() => this.removeVirtualProperty(k)} className="el-icon-minus"></i>)}
                      </div>
                    )
                  })}
                </span>
              </div>

              {mapping_type == 1 && ( 
              <div className="formInput">
                <span className="label">映射关系配置</span>
                <span className="content virtualPropertyList-content-one">
                  {virtualPropertyList.map((row, k) => {
                    return (
                      <div key={k} className="content-content">
                        <span className="editorTwo-input-map left">
                          <SdwSelect
                            value={virtualPropertyList[k].source_attr_id}
                            onChange={val => this.propertyInfoUpData(val, "virtualPropertyList-one-source_attr_id", k) }
                            data={selectSourceDimAttrList}
                          />
                        </span>

                        <span className="editorTwo-input-map right">
                          <SdwSelect
                            value={virtualPropertyList[k].target_attr_id}
                            onChange={val => this.propertyInfoUpData(val, "virtualPropertyList-one-target_attr_id", k) }
                            data={tableFieldsTwo}
                          />
                        </span>
                      </div>
                    )
                  })}
                </span>
              </div>
              )}
              
              {mapping_type == 2 && ( 
              <div className="formInput formInput-virtualPropertyTwoList">
                <span className="label required">映射关系配置</span>
                <span className="content virtualPropertyList-content-two">
                  {virtualPropertyTwoList.map((row, k) => {
                    return (
                      <div key={k} className="content contentOne">
                        <span className="editorTwo-input-map left">
                          <SdwInput2
                            value={virtualPropertyTwoList[k].source_attr_id} 
                            sholdCheckValidate={sholdCheckValidate}
                            onChange={val => this.propertyInfoUpData(val, "source_attr_id", k) }
                            placeholder="请输入"
                          />
                        </span>

                        <span className="editorTwo-input-map right">
                          <SdwInput2
                            value={virtualPropertyTwoList[k].target_attr_id} 
                            sholdCheckValidate={sholdCheckValidate}
                            onChange={val => this.propertyInfoUpData(val, "target_attr_id", k) }
                            placeholder="请输入"
                          />
                        </span>

                        <i onClick={() => this.addVirtualProperty2(k)} className="el-icon-plus"></i>
                        {k > 0 && (<i onClick={() => this.removeVirtualProperty2(k)} className="el-icon-minus"></i>)}
                      </div>
                    )
                  })}
                </span>
              </div>
              )}
            </div>
          </SdwDrawer.Body>
        </SdwDrawer>
      </div>
    )
  }
}

export default EditData
