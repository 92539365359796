import { request } from '../utils/request'

export const getAllModels = params => {
  return request({ url: '/collection/dataGovern/getAllModels', method: 'get', params: params })
}

export const downloadModel = params => {
  return request({ url: '/collection/dataGovern/downloadModel', method: 'get', params: params })
}

export const getServiceConfig = params => {
  return request({ url: '/collection/dataGovern/getServiceConfig', method: 'get', params: params })
}

export const getAllUploadRecord = params => {
  return request({ url: '/collection/dataGovern/getAllUploadRecord', method: 'get', params: params })
}

export const deleteUploadRecord = params => {
  return request({ url: '/collection/dataGovern/deleteUploadRecord', method: 'get', params: params })
}

export const uploadFile = params => {
  return request({ url: '/collection/dataGovern/uploadFile', method: 'post', data: params })
}