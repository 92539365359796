import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import SdwMessage from '@common/Message'
import { TOP_MAP, TOP_MAP_TEXT } from './common'
import { getGroupTree, saveApiTargets, getPublicCfg} from '@services/api'

import './style/setTopWin.css'

export default class EditCurrentWin extends React.Component {

  state = {
    params: {
      short_name: "", // 简称
      full_name: "", // 全称
      table_unit: "", // 单位
      sort_value: "", // 排序
      alarm_ids: "", // 预警值
      remark: "", // 备注
      agg_name: "", // 聚合算法
      agg_calculate: "", // 选”比率“时必填
    },
    group_value_one_id: "",
    group_value_one_name: "",
    group_value_one_select: [],
    group_value_two_id: "",
    group_value_two_name: "",
    group_value_two_select: [],
    group_value_three_id: "",
    group_value_three_name: "",
    group_value_three_select: [],
    groupTree: [],
    cfgData: "",
    selectInputValue: "",
    inputValue: "",
    sholdCheckValidate: false,
    selectList: [
      {
        id: TOP_MAP.top,
        text: TOP_MAP_TEXT[TOP_MAP.top]
      }, {
        id: TOP_MAP.normal,
        text: TOP_MAP_TEXT[TOP_MAP.normal]
      }
    ]
  }

  componentDidMount() {
    this.iniData()
    this.getGroupTree()
    this.getPublicCfg()
  }

  iniData = () => {
    let targets = this.props.curClickTargetData
    let params = {
      short_name: targets.short_name, // 简称
      full_name: targets.full_name, // 全称
      table_unit: targets.table_unit, // 单位
      sort_value: targets.sort_value, // 排序
      alarm_ids: targets.alarm_ids, // 预警值
      remark: targets.remark, // 备注
      agg_name: targets.agg_name, // 聚合算法
      agg_calculate: targets.agg_calculate,
    }
    this.setState({
      params: params,
      sholdCheckValidate: false
    })
  }

  getGroupTree = () => {
    let params = {
      name :""
    }
    let curClickTargetData = this.props.curClickTargetData

    let group_value_name = curClickTargetData.group_value.split(";")
    let two_group = []
    let three_group = []
    let group_value_one_id   = ""
    let group_value_two_id = ""
    let group_value_three_id = ""

    getGroupTree(params).then(res => {
      let groupTree = res.data.data || []
      let group_value_one_select = groupTree.map(item => {
        if (item.group_name == group_value_name[0]) {
          group_value_one_id = item.id
          for (let i in item.two_group) {
            if (item.two_group[i].name == group_value_name[1]) {
              group_value_two_id = i
              two_group.push({id:i, text: item.two_group[i].name})
            }
          }
          for (let i in item.three_group) {
            if (item.three_group[i].name == group_value_name[2]) {
              group_value_three_id = i
              three_group.push({id:i, text: item.three_group[i].name})
            }
          }
        }
        return {
          ...item,
          text: `${item.group_name}`
        }
      })
      let group = {
        group_value_one_id: parseInt(group_value_one_id),
        group_value_one_name: group_value_name[0],
        group_value_two_id: group_value_two_id,
        group_value_two_name: group_value_name[0]?group_value_name[1]:"",
        group_value_three_id: group_value_three_id,
        group_value_three_name: group_value_name[0]?group_value_name[2]:"",
        group_value_two_select: two_group,
        group_value_three_select: three_group,
        groupTree:groupTree,
        group_value_one_select: group_value_one_select
      }
      this.setState({
        ...group
      })
    }, () => SdwMessage.error("接口异常"))
  }

  getPublicCfg = () => {
    getPublicCfg().then(res => {
      if (res.data.code === 0 && res.data.data) {
        let cfgData = {}
        for (let i in res.data.data) {
          cfgData[i] = res.data.data[i]
        }
        this.setState({ cfgData: cfgData })
      }
    }, () => SdwMessage.error("接口异常"))
  }

  numberValidateFun = val => {
    if (!!val && Number(val) > 0 && String(val).indexOf('.') === -1) {
      return true
    }
    return '请输入一个有效整数'
  }

  topValidateFun = val => {
    if (!!val) {
      return true
    }
    return '指标类型不能为空'
  }

  handleSubmit = () => {
    let {
      params,
      group_value_one_id,
      group_value_one_name,
      group_value_two_id,
      group_value_two_name,
      group_value_three_id,
      group_value_three_name
    } = this.state

    let {curClickTargetData, currentData} = this.props
    let group_value = group_value_one_name ? group_value_one_name+";"+(group_value_two_name || "" )+";"+(group_value_three_name || "") :""
    let group_value_id = group_value_one_id ? group_value_one_id+";"+(group_value_two_id || "")+";"+(group_value_three_id || "") : ""

    let postParams = {
      api_id: currentData.api_id,
      template_id: currentData.id,
      targets: [{
        "id": curClickTargetData.id,
        "target_id": curClickTargetData.target_id, // 指标id
        "dimension_value": curClickTargetData.dimension_value, // 维度
        "time_cycle": curClickTargetData.time_cycle, // 时间周期
        "filter_str": curClickTargetData.filter_str, // 过滤条件
        "short_name": params.short_name, // 简称
        "full_name": params.full_name, // 全称
        "table_unit": params.table_unit, // 单位
        "sort_value": params.sort_value, // 排序
        "alarm_ids": params.alarm_ids, // 预警值
        "group_value": group_value_id, // 分组
        "remark": params.remark, // 备注
        "agg_name": params.agg_name, // 聚合算法
        "agg_calculate": params.agg_calculate,
      }]
    }

    let regex = /^\[[^\[\]]*\](\/\[[^\[\]]*\]){0,4}$/
    if (params.agg_name == "calculate" && !regex.test(params.agg_calculate)) {
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    saveApiTargets({
      ...postParams
    }).then(res => {
      if (res && res.data && res.data.code === 0) {
        this.props.iniAllSelectedIndicator()
        this.props.changeSetTopVisible(false)
      } else {
        SdwMessage.error("参数异常")
      }
    }, () => SdwMessage.error("接口异常"))
  }

  changeOneGroup = (item) => {
    let two_group = []
    for (let i in item.two_group) {
      two_group.push({id:i, text: item.two_group[i].name})
    }
    let three_group = []
    for (let i in item.three_group) {
      three_group.push({id:i, text: item.three_group[i].name})
    }

    this.setState({
      group_value_one_id: item.id,
      group_value_one_name: item.group_name,
      group_value_two_select: two_group,
      group_value_two_id:"",
      group_value_three_select: three_group,
      group_value_three_id:""
    })
  }

  setParamsData = (type, val) => {
    let {params} = this.state
    let obj = {}
    obj[type] = val
    let paramsClone = Object.assign({}, params, obj)
    this.setState({
      params: paramsClone
    })
  }

  render () {

    let {
      params,
      group_value_one_id,
      group_value_two_id,
      group_value_three_id,
      group_value_one_select,
      group_value_two_select,
      group_value_three_select,
      cfgData,
      sholdCheckValidate
    } = this.state

    let {
      setTopVisible,
      changeSetTopVisible
    } = this.props

    let regex = /^\[[^\[\]]*\](\/\[[^\[\]]*\]){0,4}$/

    return (
      <SdwDialog
        title='编辑扩展字段'
        visible={setTopVisible}
        changeVisible={changeSetTopVisible}
        onCancelClick={() => changeSetTopVisible(false)}
        onSureClick={this.handleSubmit}
        width={650}
        height={650}
      >
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">简称</span>
          <SdwInput
            width={440}
            placeholder="请输入"
            clearable={false}
            value={params.short_name}
            onChange={val => this.setParamsData('short_name', val)}
          />
        </div>
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">全称</span>
          <SdwInput
            width={440}
            placeholder="请输入"
            clearable={false}
            value={params.full_name}
            onChange={val => this.setParamsData('full_name', val)}
          />
        </div>
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">单位</span>
          <SdwInput
            width={440}
            placeholder="请输入"
            clearable={false}
            value={params.table_unit}
            onChange={val => this.setParamsData('table_unit', val)}
          />
        </div>
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">排序</span>
          <SdwInput
            width={440}
            placeholder="请输入"
            clearable={false}
            value={params.sort_value}
            onChange={val => this.setParamsData('sort_value', val)}
          />
        </div>
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">指标分组</span>
          <SdwSelect
            width={140}
            listWidth={140}
            placeholder="请选择一级分组"
            clearable={true}
            data={group_value_one_select}
            value={group_value_one_id}
            onChange={(val,item) => {
              this.changeOneGroup(item)
            }}
          />
          <span style={{marginRight:"10px"}}></span>
          <SdwSelect
            width={140}
            listWidth={140}
            placeholder="请选择二级分组"
            clearable={true}
            data={group_value_two_select}
            value={group_value_two_id}
            onChange={(val,item) => this.setState({
                group_value_two_id: item.id,
                group_value_two_name: item.text,
              })}
          />
          <span style={{marginRight:"10px"}}></span>
          <SdwSelect
            width={140}
            listWidth={140}
            placeholder="请选择三级分组"
            clearable={true}
            data={group_value_three_select}
            value={group_value_three_id}
            onChange={(val,item) => this.setState({
              group_value_three_id: item.id,
              group_value_three_name: item.text,
            })}
          />
        </div>
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">关联预警</span>
          <SdwInput
            width={440}
            placeholder="请输入，若有多个关联可使用;分隔"
            clearable={false}
            value={params.alarm_ids}
            onChange={val => this.setParamsData('alarm_ids', val)}
          />
        </div>
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">备注</span>
          <SdwInput
            width={440}
            placeholder="请输入"
            clearable={false}
            value={params.remark}
            onChange={val => this.setParamsData('remark', val)}
          />
        </div>
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">聚合算法</span>
          <SdwSelect
              width={150}
              listWidth={150}
              data={cfgData ? cfgData["target_api_agg"].map(el => {
                return {id: el.value, text: el.name}
              }) : []}
              value={params.agg_name}
              onChange={val => {
                this.setState({
                  params: Object.assign({}, params, {
                    agg_name: val,
                    agg_calculate: ""
                  })
                })
              }}
          />
          <span style={{marginRight:"10px"}}></span>
          <SdwInput
              width={280}
              placeholder={params.agg_name == "calculate" ? "请使用字段id输入，示例[2313]/[2314]" : "计算方式无需填写"}
              disabled={!(params.agg_name == "calculate")}
              value={params.agg_calculate}
              sholdCheckValidate={sholdCheckValidate}
              validateFun={value => regex.test(value) ? true : '格式错误，请重新填写'}
              onChange={val => this.setParamsData('agg_calculate', val)}
          />
        </div>
      </SdwDialog>
    )
  }
}
