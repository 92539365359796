import _ from 'lodash'
import switchRoutes from './switchRoutes'
import LabelManagement from '@pages/LabelProcess/LabelManagement'
import UserLabel from '@pages/LabelProcess/UserLabel'
import UserLabelDetail from '@pages/LabelProcess/UserLabel/UserLabelDetail'
import UserLabelWin from '@pages/LabelProcess/UserLabel/UserLabelWin'
import SetupTwo from '@pages/LabelProcess/UserLabel/UserLabelWin/setupTwo'

export const routerPath = '/admin/labelProcess'

export const bar = {
    rootUrl: routerPath,
    activeClassName: 'current',
    className: 'sdw-nav-item',
    name: '标签加工厂',
    children: [
        {
            icon: '',
            title: '工单标签',
            path: `${routerPath}/labelManagement`,
        },
        {
            icon: '',
            title: '用户标签',
            path: `${routerPath}/userLabel`,
        }
    ],
    order: 3
}

const pages = {
    LabelManagement: {
        icon: 'icon-278',
        title: '标签管理',
        path: `${routerPath}/labelManagement`,
        component: LabelManagement
    },
    UserLabel: {
        icon: '',
        title: '用户标签',
        path: `${routerPath}/userLabel`,
        component: UserLabel,
    },
    UserLabelDetail: {
        icon: '',
        title: '标签详情',
        path: `${routerPath}/userLabelDetail/:id`,
        component: UserLabelDetail,
    },
    UserLabelWin: {
        icon: '',
        title: '标签新增或编辑',
        path: `${routerPath}/userLabelWin/:id`,
        component: UserLabelWin,
    },
    SetupTwo: {
        icon: '',
        title: '标签规则配置',
        path: `${routerPath}/setupTwo`,
        component: SetupTwo,
    }
}

export const Router = switchRoutes({routes: _.values(pages)})
