import React, { PureComponent } from 'react'
import _ from 'lodash'
import { GetHotEventOne, GetHotEventUrl } from '@services/monitoringEye'
import { Layout, Button } from 'element-react'
import SdwTable from '../../../common/Table'
import SdwPagenation from '../../../common/Pagenation'
import SdwDrawer from '../../../common/Drawer'
import SdwBreadCrumbs from '../../../common/BreadCrumbs'
import SdwCopyText from '../../../common/CopyText'
import ChatArea from './ChatArea'
import { formatTime } from '@utils/formatTime'

import './style/monitoringEyeDetail.css'

const RECORD_TYPE_MAP = {
  0: '无效',
  1: '故障',
  2: '咨询',
  3: '策略',
}

class MonitoringEyeDetail extends PureComponent {

  state = {
    detailDate: {},
    curPage: 1,
    curLimit: 5,
    visible: false,
    curTableData: {},
    curID: '',
    isLoad: false,
    userProfileData: {}
  }

  componentDidMount () {
    let query = this.props.match.params || {}
    if (!query.id) return
    this.onGetHotEventOne(query.id)
    this.setState({
      curID: query.id
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      !_.isEqual(prevState.curPage, this.state.curPage) ||
      !_.isEqual(prevState.curLimit, this.state.curLimit)
    ) {
      this.onGetHotEventOne()
    }
  }

  onGetHotEventOne = id => {
    let { curPage, curLimit, curID } = this.state
    GetHotEventOne({
      ...id ? { id } : {id: curID},
      page: curPage,
      limit: curLimit
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          this.setState({
            detailDate: res.data.data || {}
          })
        }
      })
  }

  getColumns = () => {
    let self = this
    return [
      {
        title: "OPENID",
        dataIndex: "openid",
        width: 300
      },
      {
        title: "会话内容",
        render: function (data, record) {
          return (
            <span dangerouslySetInnerHTML={{__html: self.formatSession(data.session)}}></span>
          )
        }
      },
      {
        title: "操作",
        width: 80,
        render: function (data, record) {
          return (
            <span>
              <Button 
                plain={true} 
                type="text" 
                size="small"
                onClick={ () => self.openDetail(data) }
              >会话详情</Button>
            </span>
          )
        }
      }
    ]
  }

  formatSession = str => {

    // 热词格式：收,客服 公众号,客服 人工,不到  -----> 由 逗号 或者 空格 分隔
    let s = this.state.detailDate.hot_word.replace(/(,|\s)/g, '|')
    let reg = new RegExp("(" + s + ")", "g")
    return str.replace(reg, "<span class='red'>$1</span>")
  }

  openDetail = record => {

    if (this.state.detailDate.type === 'ASYNC') {
      // 异步跳转病历库界面
      window.open(`http://kfdata.oa.com/#/portrait/searchDetail?type=openid&value=${record.openid}`)
      return
    }

    this.setState({
      isLoad: false
    })

    // 会话取预警时间前后一个小时的
    let startTime = formatTime(new Date(this.state.detailDate.create_time).getTime(), -2)
    let endTime = formatTime(new Date(this.state.detailDate.create_time).getTime(), 2)
    GetHotEventUrl({
      openid: record.openid,
      relation_type: -1,
      account_type: 0,
      start_time: startTime,
      end_time: endTime
    })
      .then(res => {
        this.setState({
          isLoad: true
        })
        if (res && res.data && res.data.code === 0) {
          this.setState({
            userProfileData: res.data.data && JSON.parse(res.data.data) || {}
          })
        }
      })
      .catch(() => {
        this.setState({
          isLoad: true
        })
      })

    this.setState({
      visible: true,
      curTableData: record
    })
  }

  render () {

    let { detailDate, visible, curTableData, userProfileData, isLoad } = this.state

    return (
      <div>
        <SdwBreadCrumbs 
          history={this.props.history}
          data={[
            {
              title: '智慧运营台',
              path: '/admin/monitor/monitoringEye?showPrevDate' // 多传 showPrevDate：表示时间需要显示session里面的
            }, {
              title: '热词监控眼',
              path: '/admin/monitor/monitoringEye?showPrevDate'
            }, {
              title: '预警详情'
            }
          ]}
        />

        <Layout.Row>
          <Layout.Col span="24">
            <div style={{ backgroundColor: '#fff', minHeight: '178px' }}>
              <div style={{ padding: '16px 20px', fontSize: '18px', borderBottom: '1px solid #ddd' }}>基础信息</div>
              <div style={{ padding: '16px 16px 16px 30px' }}>
                <Layout.Row>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">热词</span>
                      <span className="label-text" title={detailDate.hot_word || '-'}>{detailDate.hot_word || '-'}</span>
                    </div>
                  </Layout.Col>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">所属业务</span>
                      <span className="label-text">{detailDate.product || '-'}</span>
                    </div>
                  </Layout.Col>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">反馈数</span>
                      <span className="label-text">{detailDate.today_word || '-'}</span>
                    </div>
                  </Layout.Col>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">热词占比</span>
                      <span className="label-text">{detailDate.such_heat && detailDate.such_heat.split(' ')[0] || '-'}</span>
                    </div>
                  </Layout.Col>
                </Layout.Row>
                <Layout.Row>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">预警时间</span>
                      <span className="label-text">{detailDate.create_time || '-'}</span>
                    </div>
                  </Layout.Col>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">预警渠道</span>
                      <span className="label-text">{detailDate.type || '-'}</span>
                    </div>
                  </Layout.Col>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">同比增长</span>
                      <span className="label-text">{detailDate.tongbi || '-'}</span>
                    </div>
                  </Layout.Col>
                </Layout.Row>
              </div>
            </div>
          </Layout.Col>
          <Layout.Col span="24">
            <div style={{ backgroundColor: '#fff', minHeight: '178px', marginTop: '16px' }}>
              <div style={{ padding: '16px 20px', fontSize: '18px', borderBottom: '1px solid #ddd' }}>补登信息</div>
              <div style={{ padding: '16px 16px 16px 30px' }}>
                <Layout.Row>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">补登时间</span>
                      <span className="label-text">{detailDate.record_time || '-'}</span>
                    </div>
                  </Layout.Col>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">补登人</span>
                      <span className="label-text">{detailDate.record_person || '-'}</span>
                    </div>
                  </Layout.Col>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">是否有效</span>
                      {
                        _.isEqual(typeof detailDate.isEffect, 'number') && <span className={
                          detailDate.isEffect ? 'monitor-eye-detail__title-label__is-effect' : 'monitor-eye-detail__title-label__no-effect'
                        }></span>
                      }
                      <span className="label-text">{_.isEqual(typeof detailDate.isEffect, 'number') ? (detailDate.isEffect ? '有效' : '无效') : '-'}</span>
                    </div>
                  </Layout.Col>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">预警类型</span>
                      <span className="label-text">{RECORD_TYPE_MAP[detailDate.record_type] || '-'}</span>
                    </div>
                  </Layout.Col>
                </Layout.Row>
                <Layout.Row>
                  <Layout.Col span="6">
                    <div>
                      <span className="monitor-eye-detail__title-label">补登备注</span>
                      <span className="label-text">{detailDate.record_detail || '-'}</span>
                    </div>
                  </Layout.Col>
                </Layout.Row>
              </div>
            </div>
          </Layout.Col>
        </Layout.Row>

        <Layout.Row>
          <Layout.Col span="24">
            <div style={{ marginTop: '16px' }}>
              <SdwTable
                tbodyMinHeight="280px"
                columns={ this.getColumns() }
                dataSource={ detailDate.all_session_values || [] }
              />

              <div style={{ marginTop: '16px' }}>
                <SdwPagenation
                  total={ detailDate.total }
                  currentPage={ detailDate.current_page }
                  pageCountList={[5, 10, 15, 20, 40]}
                  pageCount={ detailDate.per_page }
                  getClickPage={ page => {
                    this.setState({
                      curPage: page
                    })
                  } }
                  pageCountChange={ limit => {
                    this.setState({
                      curLimit: limit
                    })
                  } }
                />
              </div>
            </div>
          </Layout.Col>
        </Layout.Row>

        <SdwDrawer
          title="会话详情"
          hideSureButton={true}
          cancelText="关 闭"
          visible={visible}
          onIconClick={() => this.setState({ visible: false })}
          onCancelClick={() => this.setState({ visible: false })}
          onSureClick={() => this.setState({ visible: false })}
        >
          <SdwDrawer.Body>
            <div>
              <div style={{ marginBottom: '16px' }}>用户：
                <span 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => window.open(`http://kfdata.oa.com/#/portrait/searchDetail?type=openid&value=${curTableData.openid}`)}
                >{curTableData.openid}</span>
                <SdwCopyText 
                  copyText={curTableData.openid}
                  copyTip="点击复制用户名称"
                />
              </div>
              {/* <div style={{ marginBottom: '16px' }}>来源：来源来源来源来源来源来源来源来源</div> */}
              <ChatArea
                isLoad={isLoad}
                formatSession={this.formatSession}
                userInfoData={userProfileData && userProfileData.msg && userProfileData.msg.traces || []}
              />
            </div>
          </SdwDrawer.Body>
        </SdwDrawer>
      </div>
    )
  }
}

export default MonitoringEyeDetail
