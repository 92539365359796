import React from 'react'
import SdwSelect from '@common/Select'
import SdwDialog from '@common/Dialog'
import SdwMessage from '@common/Message'
import {getDetailInfo, getNodeFields} from '@services/midTable'
import {getTableApiItems} from '@services/dataAccess'
import {connect} from 'react-redux'
import './css/index.css'

class PublishDetail extends React.Component {

    state = {
        params: {
            id: '',
            table_id: '', // 落地表id
            table_primary_key: '', // 多选，选节点的字段
            result_id: '', // 节点id
            partition_field_id: '', // 分区字段
        },
        tableList: [],
        nodeList: [],
        fieldsListOption: [],
    }

    componentDidMount() {
        this.getDetailInfo()
        this.getTableApiItems()
        this.getNodeList()
    }

    getDetailInfo = () => {
        let {editMidTable} = this.props
        getDetailInfo({
            id: editMidTable.id,
        }).then(res => {
            if (res.data && res.data.code === 0) {
                let detailInfo = res.data.data || {}
                this.setState({
                    params: {
                        id: editMidTable.id,
                        table_id: detailInfo.table_id || '', // 落地表id
                        table_primary_key: detailInfo.table_primary_key || '', // 多选，选节点的字段
                        result_id: detailInfo.result_id || '', // 节点id
                        partition_field_id: detailInfo.partition_field_id || '', // 分区字段
                    },
                })
            }
        }, () => SdwMessage.error("接口异常"))
    }

    getTableApiItems = () => {
        // 目标表参数
        let paramData = {
            limit: 10000,
            search: JSON.stringify({
                'warehouse_level': {'value': 'DWD', 'operator': '='},
                'enable': {'value': 1, 'operator': '='}
            })
        }
        // 目标表
        getTableApiItems(paramData).then(res => {
            if (res && res.data && res.data.code === 0) {
                let curArr = Array.isArray(res.data.data.items) ? res.data.data.items : []
                curArr = curArr.map(i => {
                    let curName = !!i.database_name ? `${i.database_name}.${i.table_name}` : `${i.table_name}`
                    return Object.assign({}, i, {
                        text: `${curName}（${i.data_set_chinese}）`,
                    })
                })
                this.setState({tableList: curArr})
            } else {
                SdwMessage.error('获取落地表数据失败')
            }
        }).catch(error => {
            SdwMessage.error('获取落地表接口异常：' + error)
        })
    }

    getNodeList = () => {
        let {stepList, editMidTable} = this.props
        let nodeList = []
        for (let i in stepList) {
            nodeList.push(stepList[i])
        }
        this.setState({
            clickItem: stepList[editMidTable.result_id] ? stepList[editMidTable.result_id] : {},
            nodeList: nodeList
        }, this.getNodeFields)
    }

    getNodeFields = () => {
        let {clickItem} = this.state
        let item = clickItem.item
        if (!item) {
            return
        }
        let params = {
            id: item.job_id,
            node_id: item.id,
        }
        if (item.hasOwnProperty("link") && item.link) {
            params.link_id = item.link.id
        }
        getNodeFields(params).then(res => {
            if (res && res.data && res.data.code === 0) {
                let record = res.data.data || []
                this.setState({
                    fieldsListOption: record.map(i => {
                        return {id: i.id, text: i.field}
                    }),
                })
            } else {
                let msg = res.data.msg || '请求失败'
                SdwMessage.error(msg)
            }
        }).catch(error => SdwMessage.error('请求失败 ' + error))
    }

    render() {

        let {
            params,
            tableList,
            nodeList,
            fieldsListOption,
        } = this.state

        let {
            showDetailDialog,
            changeShowAddWin,
        } = this.props

        return (
            <div>
                <SdwDialog
                    title={'发布详情'}
                    visible={showDetailDialog}
                    width={600}
                    height={500}
                    changeVisible={changeShowAddWin}
                    onCancelClick={() => changeShowAddWin(false)}
                    hideSureButton={true}
                    hideCancelButton={true}
                >
                    <div className='add-AddWin-win__label'>
                        <span className='label__title required'>落地表</span>
                        <SdwSelect
                            disabled={true}
                            width={450}
                            listWidth={450}
                            placeholder={'请选择落地的数据表'}
                            data={tableList}
                            value={params.table_id}
                        />
                    </div>
                    <div className='add-AddWin-win__label'>
                        <span className='label__title required'>节点</span>
                        <SdwSelect
                            disabled={true}
                            width={450}
                            listWidth={450}
                            placeholder={'请选择节点'}
                            data={nodeList}
                            value={params.result_id}
                        />
                    </div>
                    <div className='add-AddWin-win__label'>
                        <span className='label__title required'>主键</span>
                        <SdwSelect
                            disabled={true}
                            isMultipleChoice={true}
                            placeholder={'请选择主键，支持多个字段组合'}
                            width={450}
                            listWidth={450}
                            value={params.table_primary_key ? params.table_primary_key.split(',').map(i => {
                                return {id: i, text: i}
                            }) : []}
                        />
                    </div>
                    <div className='add-AddWin-win__label'>
                        <span className='label__title'>分区字段</span>
                        <SdwSelect
                            disabled={true}
                            placeholder={'默认为【加工实例时间】'}
                            width={450}
                            listWidth={450}
                            listMaxHeight={150}
                            data={fieldsListOption}
                            value={params.partition_field_id}
                        />
                    </div>
                </SdwDialog>
            </div>
        )
    }
}

const mapState = state => ({
    stepList: state.midTableView.stepList,
})

const mapDispatch = dispatch => ({
    updateStepList: dispatch.midTableView.updateStepList
})
export default connect(mapState, mapDispatch)(PublishDetail)
