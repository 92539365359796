import { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import Input from '@common/Input'
import Table from '@common/Table'
import Button from '@common/Button'
import Enable from '@common/Enable'
import Pagenation from '@common/Pagenation'
import Drawer from '@common/Drawer'
import Switch from '@common/Switch'
import { getPushLogList, setPushLog } from '@services/monitorSetting'
import SdwMessage from '@common/Message/index'
import { MONITOR_TYPE } from '@utils/monitor'
import './index.css'

export default function SecdRecord(props) {

  const [tableData, setTableData] = useState({})
  const [searchParams, setSearchParams] = useState({
    page: 1,
    limit: 10
  })
  const [showWin, setShowWin] = useState(false)
  const [curRecord, setCurRecord] = useState({})
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState(0)

  useEffect(() => {
    initData()
  }, [searchParams])

  function initData() {
    getPushLogList({
      ...searchParams,
      search: JSON.stringify({
        ...title ? {
          push_title: {
            value: title,
            operator: 'like',
            type: 'both'
          }
        } : {}
      })
    }).then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        const data = _.get(res, 'data.data')
        if (!_.isEmpty(data)) setTableData(data)
      } else {
        const msg = _.get(res, 'data.msg') || '获取数据失败！'
        SdwMessage.error(msg)
      }
    }).catch(err => SdwMessage.error('接口异常' + err))
  }

  function getcolumns() {
    return [
      {
        title: "类型",
        render: data => <span>{!!data.job_monitor_type ? MONITOR_TYPE[data.job_monitor_type] : '-'}</span>
      },{
        title: "标题",
        dataIndex: "push_title",
      },{
        title: "期数",
        dataIndex: "period",
      },{
        title: "推送时间",
        dataIndex: "send_time",
      },{
        title: "推送情况",
        render: data => {
          const sendList = _.isArray(data.sendList) ? data.sendList: []
          const total = sendList.length
          const success = sendList.filter(i => i.status == 2).length // 状态为2表示成功，其他的都是失败的
          return <span>{`${success}人成功/${total - success}人失败`}</span>
        }
      },{
        title: "状态",
        render: data => <Enable enable={data.enable}/>
      },{
        title: "操作",
        render: data => (
          <Button
            text='查看'
            onClick={() => onOpenDetail(data)}
          />
        )
      },
    ]
  }

  function onOpenDetail(data) {
    setStatus(data.enable)
    setCurRecord(data)
    setShowWin(true)
  }

  function handleSureClick () {
    console.log(status);

    setPushLog({
      data: {
        id: curRecord.id,
        enable: status
      }
    }).then(res => {
      const code = _.get(res, 'data.code')
      const msg = _.get(res, 'data.msg')
      if (code === 0) {
        SdwMessage.success(msg || '编辑成功！')
        setShowWin(false)
        initData()
      } else {
        SdwMessage.error(msg || '编辑失败！')
      }
    }).catch(err => SdwMessage.error('接口异常' + err))
  }

  const successMember = useMemo(() => {
    // 状态为2表示成功，其他的都是失败的
    return _.isArray(curRecord && curRecord.sendList) ? curRecord.sendList.filter(i => i.status == 2).map(j => j.send_user).join(';') : ''
  }, [curRecord])

  const failMember = useMemo(() => {
    // 状态为2表示成功，其他的都是失败的
    return _.isArray(curRecord && curRecord.sendList) ? curRecord.sendList.filter(i => i.status != 2).map(j => j.send_user).join(';') : ''
  }, [curRecord])

  return (
    <div style={{ padding: '16px 0' }}>
      <div style={{ marginBottom: 16 }}>
        <Input
          label='标题'
          width={260}
          value={title}
          onChange={val => {
            setSearchParams(r => _.assign({}, r, {page: 1}))
            setTitle(val)
          }}
          onBlur={initData}
        />
      </div>
      <div>
        <Table
          columns={getcolumns()}
          dataSource={_.isArray(tableData.items) ? tableData.items : []}
        />

        <Pagenation
          total={ tableData.total }
          currentPage={ tableData.current_page }
          pageCountList={[10, 15, 20, 40]}
          pageCount={ tableData.per_page }
          getClickPage={ page => setSearchParams(r => _.assign({}, r, {page: page})) }
          pageCountChange={ limit => setSearchParams(r => _.assign({}, r, {limit: limit})) }
        />
      </div>

      {
        showWin &&
        <Drawer
          title='推送详情'
          visible={showWin}
          onIconClick={() => setShowWin(false)}
          onCancelClick={() => setShowWin(false)}
          onSureClick={handleSureClick}
        >
          <Drawer.Body>
            <div className='drawer__wrap-div'>
              <div className='drawer__label'>推送成功用户</div>
              <div className='drawer__main'>
                <div className='monitor-management__send-record__member-wrap'>
                  {successMember}
                </div>
              </div>
            </div>
            <div className='drawer__wrap-div'>
              <div className='drawer__label'>推送失败用户</div>
              <div className='drawer__main'>
                <div className='monitor-management__send-record__member-wrap'>
                  {failMember}
                </div>
              </div>
            </div>
            <div className='drawer__wrap-div'>
              <div className='drawer__label'>是有有效</div>
              <div className='drawer__main'>
                <Switch
                  value={status}
                  changeValue={val => setStatus(val)}
                />
              </div>
            </div>
          </Drawer.Body>
        </Drawer>
      }
    </div>
  )
}
