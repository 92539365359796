// @file 数据列表
import {useState, useEffect, useMemo} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import Select from '@common/Select'
import Table from '@common/Table'
import Pagenation from '@common/Pagenation'
import Button from '@common/Button'
import Message from '@common/Message'
import {getTablecfgList} from '@services/dataSearch'
import {commonApi} from '@utils/request'
import {LEVEL_TYPE_MAP, LEVEL_TEXT_MAP} from '@pages/common'
import useAllTablecfg from '@pages/common/useAllTablecfg'
import UseGetTopic from '@customHooks/UseGetTopic'
import './index.css'

const BASE_LEVEL_MAP = _.keys(LEVEL_TYPE_MAP).map(key => ({
    id: key,
    text: LEVEL_TEXT_MAP[key]
}))

function DataList(props) {

    const tableArr = useAllTablecfg()
    const topicAndBusinessRecord = UseGetTopic()

    const [topicID, setTopicID] = useState('')

    const curTopicList = useMemo(() => {
        return _.isArray(_.get(topicAndBusinessRecord, 'topic')) ? _.get(topicAndBusinessRecord, 'topic').map(i => ({
            ...i,
            id: i.topic_name,
            text: i.topic_name,
            topic_id: i.id
        })) : []
    }, [topicAndBusinessRecord])

    const curBusinessList = useMemo(() => {
        return _.isArray(_.get(topicAndBusinessRecord, 'business')) ? _.get(topicAndBusinessRecord, 'business').filter(j => j.topic_id == topicID).map(i => ({
            ...i,
            id: i.business_name,
            text: i.business_name
        })) : []
    }, [topicAndBusinessRecord, topicID])

    const allBusinessList = useMemo(() => {
        return _.isArray(_.get(topicAndBusinessRecord, 'business')) ? _.get(topicAndBusinessRecord, 'business').map(i => ({
            ...i,
            id: i.business_name,
            text: i.business_name
        })) : []
    }, [topicAndBusinessRecord])

    const [curRecord, setCurRecord] = useState({})
    const [searchParams, setSearchParams] = useState({
        limit: 10,
        page: props.pageInfo || 1
    })
    const [search, setSearch] = useState({})
    const [curTabID, setCurTabID] = useState('')

    useEffect(() => {
        // 处理一下search中的空值
        const curSearch = _.keys(search).reduce((prev, key) => {
            if (search[key] !== '') {
                prev[key] = {
                    value: search[key],
                    operator: '='
                }
            }
            return prev
        }, {})

        commonApi(getTablecfgList, _.assign({}, searchParams, {
            search: JSON.stringify(curSearch)
        })).then(data => {
            if (!_.isEmpty(data)) setCurRecord(data)
        }).catch(msg => {
            Message.error(msg)
        })
    }, [searchParams, search])

    function getColumns() {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 50
            }, {
                title: '主题域',
                dataIndex: 'topic',
                width: 100
            }, {
                title: '业务',
                dataIndex: 'business',
                width: 100
            }, {
                title: '数据源类型',
                dataIndex: 'data_set_type',
                width: 100
            }, {
                title: '表英文名',
                width: 300,
                render: data =>
                    <span>{!!(data.database_name) ? `${data.database_name}.${data.table_name}` : !!(data.table_name) ? data.table_name : '-'}</span>
            }, {
                title: '表中文名',
                dataIndex: 'data_set_chinese',
                width: 250
            }, {
                title: '备注',
                dataIndex: 'comments',
                width: 150
            }, {
                title: '操作',
                width: 100,
                render: data => (
                    <Button
                        text='查询导出'
                        onClick={() => jumpToSearchEport(data)}
                    />
                )
            },
        ]
    }

    function jumpToSearchEport(data) {
        props.history.push({
            pathname: `/admin/dataGasStation/searchEport/${data.id}`,
            state: {
                curMenuIndex: 0
            }
        })
    }

    function updateSearchParams(type, val) {
        setSearchParams(r => _.assign({}, r, {
            [type]: val
        }))
    }

    function onSearch(type, val) {
        setSearch(r => _.assign({}, r, {
            [type]: val
        }))
    }

    return (
        <div style={{margin: '20px 0'}}>
            <div className='data-search__data-list__search-wrap'>
                <div className='data-search__data-list__label'>
                    <Select
                        label='主题域'
                        value={search.topic || ''}
                        width={220}
                        onChange={(val, item) => {
                            updateSearchParams('page', 1)
                            onSearch('topic', val)
                            setTopicID(item.topic_id)
                        }}
                        data={curTopicList}
                        readOnly={false}
                    />
                </div>
                <div className='data-search__data-list__label'>
                    <Select
                        label='业务'
                        value={search.business || ''}
                        width={220}
                        onChange={val => {
                            updateSearchParams('page', 1)
                            onSearch('business', val)
                        }}
                        data={search.topic ? curBusinessList : allBusinessList}
                        readOnly={false}
                    />
                </div>
                <div className='data-search__data-list__label'>
                    <Select
                        label='表名'
                        value={curTabID || ''}
                        width={220}
                        onChange={(id, item = {}) => {
                            updateSearchParams('page', 1)
                            setSearch(r => _.assign({}, r, {
                                'database_name': item.database_name || '',
                                'table_name': item.table_name || ''
                            }))
                            setCurTabID(id)
                        }}
                        data={tableArr}
                        listWidth={400}
                        readOnly={false}
                    />
                </div>
                <div className='data-search__data-list__label'>
                    <Select
                        label='仓库分层'
                        value={search.warehouse_level || ''}
                        width={220}
                        onChange={val => {
                            updateSearchParams('page', 1)
                            onSearch('warehouse_level', val)
                        }}
                        data={BASE_LEVEL_MAP}
                    />
                </div>
                <div className='data-search__data-list__label'>
                    <Select
                        label='仓库类型'
                        value={search.data_set_type || ''}
                        width={220}
                        onChange={val => {
                            updateSearchParams('page', 1)
                            onSearch('data_set_type', val)
                        }}
                        data={[{
                            id: 'mysql',
                            text: 'mysql'
                        }, {
                            id: 'es',
                            text: 'es'
                        }]}
                    />
                </div>
            </div>

            <div style={{
                marginTop: 8
            }}>
                <Table
                    columns={getColumns()}
                    dataSource={_.isArray(curRecord.items) ? curRecord.items : []}
                />

                <Pagenation
                    total={curRecord.total || 0}
                    currentPage={curRecord.current_page || 1}
                    getClickPage={val => updateSearchParams('page', val)}
                    pageCountChange={val => updateSearchParams('limit', val)}
                />
            </div>
        </div>
    )
}

const mapState = state => ({
    pageInfo: state.page.pageInfo
})

export default withRouter(connect(mapState)(DataList))