import React, { Component } from 'react'
import _ from "lodash";

import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import SdwMessage from '@common/Message'
import Form from "@common/Form_D";

import { notEmpty,isInteger } from "@utils/validateFun";

import { insertIndicatorConf,updateIndicatorConf,deleteTarget } from "@services/operationConfiguration";

import "../style/EditBusinessIndicaWin.css";
import { TIME_CYCLE_OPTIONS } from "../utils/const";


const Target_VALUE_ITEM = {
  target_id: '',     //指标
  target_name: '',   //指标英文名
  target_rule: {     //目标值范围
    left_value: '',
    operator: 'AND',
    right_value: ''
  },
  alarm_rule: {      //预警值范围
    left_value: '',
    operator: 'AND',
    right_value: ''
  }
}
const OPRATOR_OPTION = [
  {
    id: 'AND',
    text: '且'
  },
  {
    id: 'OR',
    text: '或'
  }
]

export default class EditBusinessIndicaWin extends Component {
  form = React.createRef()
  state = {
    formData: {
      dimension_value: '',
      dimension_id: '',
      dimension_name: '',
      time_cycle: '',

      target_value_configuration: [
        Target_VALUE_ITEM
      ]
    },
    rules: {
      dimension_id: [{ func: isInteger, message: '请选择指标维度' }],
      time_cycle: [{ func: notEmpty, message: '请选择统计周期' }],
      dimension_value: [{ func: notEmpty, message: '请输入纬度值' }],
      target_value_configuration: [{ func: (val) => {
        if (val[0].target_id === '') {
          return false
        }
        return true 
      }, message: '请选择指标' }],
    },
    deleteTargetList:[],
  }
  componentDidMount(){
    let {currentConf,isEdit} = this.props
    if(isEdit) {
      currentConf = _.cloneDeep(currentConf)
      this.initTargetValueConfig(currentConf)
      this.setState({
        formData: currentConf
      })
    }
  }
  initTargetValueConfig = (currentConf) => {
    let {target_value_configuration} = currentConf
    if ( Array.isArray(target_value_configuration) ) {
      currentConf.target_value_configuration = target_value_configuration.map(i => {
        let {alarm_rule,target_rule} = i
        alarm_rule = this.processTargetRule(alarm_rule)
        target_rule = this.processTargetRule(target_rule)
        return {
          ...i,
          alarm_rule,
          target_rule
        }
      })
    }
  }
  processTargetRule = (rule) => {
    if(rule) {
      let tempArr = rule.replace(/[><]/g,'').split(' ')
      return {
        left_value: Number(tempArr[0]),
        operator: tempArr[1],
        right_value: Number(tempArr[2])
      }
    }else{
      return {
        left_value: '',
        operator: 'AND',
        right_value: ''
      }
    }
  }
  setFormDataProps = (name, value,item) => {
    if(name === 'dimension_id') {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value,
          dimension_name: item.field_name
        }
      })
    }else{
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
      })
    }
  }
  processTargetValueConf = (params) => {
    let {dimension_value,dimension_id,dimension_name,time_cycle, target_value_configuration} = params
    params.target_value_configuration = target_value_configuration.map(item => {
      let target_rule = `>${item.target_rule.left_value} ${item.target_rule.operator} <${item.target_rule.right_value}`
      let alarm_rule = `>${item.alarm_rule.left_value} ${item.alarm_rule.operator} <${item.alarm_rule.right_value}`
      return {
        ...item,
        dimension_value,
        dimension_id,
        dimension_name,
        time_cycle,
        target_rule: target_rule,
        alarm_rule: alarm_rule
      }
    })
  }
  handleSubmit = () => {
    let {isEdit,currentConf} = this.props
    let api = isEdit ? updateIndicatorConf : insertIndicatorConf
    this.form.current.validate(valid => {
      if (valid) {
        let {formData} = this.state
        let params = _.cloneDeep(formData)
        this.processTargetValueConf(params)
        let target_value_configuration= params.target_value_configuration
        api({ 
          ...isEdit ? {
            id: currentConf.id
          } : {},
          field: params,
          target_value_configuration
        }).then(res => {
          if (res && res.data && res.data.code === 0) {
            this.onDeleteTarget()
            let msg = isEdit ? '编辑配置成功' : '添加配置成功'
            SdwMessage.success(msg)
            this.props.changeVisibleFun(false)
            this.props.getBusinessIndicatorsCfgListBySearch()
          } else {
            let msg = isEdit ? '编辑配置失败' : '添加配置失败'
            SdwMessage.error(msg)
          }
        }).catch(() => SdwMessage.error("接口异常"))
      }
    })
  }
  addTargetConfig = () => {
    let { target_value_configuration } = this.state.formData
    target_value_configuration = _.cloneDeep(target_value_configuration)
    target_value_configuration.push(Target_VALUE_ITEM)
    this.setState({
      formData: {
        ...this.state.formData,
        target_value_configuration
      }
    })
  }
  removeTargetConfig = (index) => {
    let { target_value_configuration } = this.state.formData
    let delObj = target_value_configuration.splice(index, 1)[0]
    if(delObj && delObj.id) {
      this.state.deleteTargetList.push(delObj.id)
    }
    this.setState({
      formData: {
        ...this.state.formData
      }
    })
  }
  setTargetValueConfItemValue = (name, value, index, prop) => {
    let { target_value_configuration } = this.state.formData
    target_value_configuration = _.cloneDeep(target_value_configuration)
    if( name === 'target_rule' || name === 'alarm_rule') {
      target_value_configuration[index][name][prop] = value
    }else if(name === 'target_id') {
      target_value_configuration[index][name] = value.id
      target_value_configuration[index].target_name = value.target_name
    }
    else{
      target_value_configuration[index][name] = value
    }
    this.setState({
      formData: {
        ...this.state.formData,
        target_value_configuration
      }
    })
  }
  onDeleteTarget = () => {
    if(this.state.deleteTargetList.length > 0){
      deleteTarget({
        ids:this.state.deleteTargetList
      })
    }
  }
  render() {
    let { formData, rules } = this.state
    let {indicatorsDimentionList,targetList} = this.props
    let target_value_configuration = formData.target_value_configuration
    return (
      <Form labelWidth={'100px'} labelPosition={'left'} ref={this.form} model={formData} rules={rules}>
        <div>
          <div className="business-indicatorwin__half-width">
            <Form.FormItem label="指标维度" prop="dimension_id" required>
              <SdwSelect
                placeholder="请选择指标维度"
                value={formData.dimension_id}
                width={'95%'}
                listWidth={'100%'}
                clearable={false}
                readOnly={false}
                data={indicatorsDimentionList}
                onChange={(val,item) => this.setFormDataProps('dimension_id', val,item)}
              />
            </Form.FormItem>
          </div>
          <div className="business-indicatorwin__half-width">
            <Form.FormItem label="统计周期" prop="time_cycle" required>
              <SdwSelect
                placeholder="请选择统计周期"
                value={formData.time_cycle}
                width={'100%'}
                clearable={false}
                readOnly={false}
                data={TIME_CYCLE_OPTIONS.map(item => ({id: item.id, text: `${item.id}(${item.text})`}))}
                onChange={val => this.setFormDataProps('time_cycle', val)}
              />
            </Form.FormItem>
          </div>
        </div>
        <Form.FormItem label="维度值" prop="dimension_value" required>
          <SdwInput
            placeholder="请输入维度值"
            width='100%'
            value={formData.dimension_value}
            onChange={val => this.setFormDataProps('dimension_value', val)}
          />
        </Form.FormItem>
        <Form.FormItem className={'target_value_configuration-style'} label="目标值配置" required>
          <span className='target_value_configuration-addtext' onClick={this.addTargetConfig}>添加</span>
        </Form.FormItem>
        {
          Array.isArray(target_value_configuration) &&
          target_value_configuration.map((item, index) => {
            let {target_rule, alarm_rule,target_id} = item
            return (
              <div className="target_value_configuration-wrap" key={index}>
                <Form.FormItem labelWidth={78} label="指标" prop="target_value_configuration">
                  <SdwSelect
                    placeholder="请选择指标"
                    value={target_id}
                    width='99%'
                    listWidth={'100%'}
                    readOnly={false}
                    clearable={false}
                    disabledObj={{
                      arr: target_value_configuration.map(i => i.target_id),
                      key: 'id',
                      tip: '同一配置下，每个指标只能配置1次，请勿重复配置'
                    }}
                    data={targetList}
                    onChange={(val,item) => this.setTargetValueConfItemValue('target_id', item, index)}
                  />
                </Form.FormItem>
                <div>
                  <div className="business-indicatorwin__half-width target_value_configuration-input-minwidth">
                    <span style={{marginRight: '8px'}}>目标值范围</span>
                    <div className="target_value_configuration-width">
                      <SdwInput
                        value={target_rule.left_value}
                        width={80}
                        label="大于"
                        placeholder=''
                        onChange={val => this.setTargetValueConfItemValue('target_rule', val, index, 'left_value')}
                      />
                    </div>
                    <div className="target_value_configuration-width">
                      <SdwSelect
                        value={target_rule.operator}
                        width={58}
                        placeholder=''
                        clearable={false}
                        readOnly={false}
                        data={OPRATOR_OPTION}
                        onChange={val => this.setTargetValueConfItemValue('target_rule', val, index, 'operator')}
                      />
                    </div>
                    <div className="target_value_configuration-width-last">
                      <SdwInput
                        value={target_rule.right_value}
                        width={80}
                        label="小于"
                        placeholder=''
                        onChange={val => this.setTargetValueConfItemValue('target_rule', val, index, 'right_value')}
                      />
                    </div>
                  </div>
                  <div className="business-indicatorwin__half-width target_value_configuration-input-minwidth">
                    <span style={{marginRight: '8px'}}>预警值范围</span>
                    <div className="target_value_configuration-width">
                      <SdwInput
                        value={alarm_rule.left_value}
                        width={80}
                        label="大于"
                        placeholder=''
                        onChange={val => this.setTargetValueConfItemValue('alarm_rule', val, index, 'left_value')}
                      />
                    </div>
                    <div className="target_value_configuration-width">
                      <SdwSelect
                        value={alarm_rule.operator}
                        width={58}
                        placeholder=''
                        clearable={false}
                        readOnly={false}
                        data={OPRATOR_OPTION}
                        onChange={val => this.setTargetValueConfItemValue('alarm_rule', val, index, 'operator')}
                      />
                    </div>
                    <div className="target_value_configuration-width-last">
                      <SdwInput
                        value={alarm_rule.right_value}
                        width={80}
                        label="小于"
                        placeholder=''
                        onChange={val => this.setTargetValueConfItemValue('alarm_rule', val, index, 'right_value')}
                      />
                    </div>
                  </div>
                </div>
                { index > 0 && <div onClick={() => this.removeTargetConfig(index)} className="target_value_configuration-close iconfont icon-close"></div>}
              </div>
          )})
        }
      </Form>
    )
  }
}