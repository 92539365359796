import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import Input from '@common/Input'
import './index.css'

class BasicConfiguration extends PureComponent {


    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    setFormValue = (type, val) => {
        let formData = this.props.formData
        let formDataClone = _.cloneDeep(formData)
        switch (type) {
            case "group_name":
                formDataClone.group_name = val
                break
            case "remark":
                formDataClone.remark = val
                break
        }
        this.props.updateFormData(formDataClone)
    }

    render() {

        let {
            sholdCheckValidate,
            formData
        } = this.props


        console.log("BasicC=====formData", formData)

        return (
            <div className='access-body'>
                <div className='main-body'>
                    <div className='label-wrap'>
                        <span className='label required'>指标组名：</span>
                        <Input
                            placeholder='请输入指标组名'
                            value={formData.group_name}
                            onChange={val => this.setFormValue('group_name', val)}
                            sholdCheckValidate={sholdCheckValidate}
                            validateFun={val => {
                                if (!val) {
                                    return '请输入分组名称（12个字内）'
                                }
                                if (val.length > 12) {
                                    return '输入最大长度为12'
                                }
                                return true
                            }}
                            width={610}
                        />
                    </div>
                    <div className='label-wrap'>
                        <span className='label'>备注：</span>
                        <Input
                            placeholder='请输入'
                            type="textarea"
                            value={formData.remark}
                            onChange={val => this.setFormValue('remark', val)}
                            width={610}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = state => ({
    formData: state.keyIndicatorAllocation.formData
})

const mapDispatch = dispatch => ({
    updateFormData: dispatch.keyIndicatorAllocation.updateFormData,
})

export default connect(mapState, mapDispatch)(BasicConfiguration)
