// API

import React, { Component } from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import './index.css';
import { ceil } from 'lodash';
import SdwSelect from '../Select'

class Pagenation extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    seqPageStartList: [], // 序列页码， 前面部位
    seqPageCenterList: [], // 序列页码， 中间部位
    seqPageEndNum: 0, // 序列页码，最后的页码
    currentPage: 0, // 当前页码
    currPageCount: 0, // 当前每页显示总条数
    gotoPageVal: 0,
  }

  // 可传递的参数
  static propTypes = {
    className: PropTypes.string, // 自定义样式class名
    total: PropTypes.number.isRequired, //记录总数
    showTotal: PropTypes.bool, // 是否显示总记录数样本
    pageCountList: PropTypes.array, // 每页显示的总条数的选项列表
    showPageCountSelect: PropTypes.bool, // 是否显示每页总记录数选项
    pageCount: PropTypes.number.isRequired, // 每页显示条数
    seqPageStartListCount: PropTypes.number, // 序列页码， 前面部位最多显示多少个
    pageStyle: PropTypes.object, // 可定义组件顶层dom样式
    currentPage: PropTypes.number.isRequired, // 当前页码
    getClickPage: PropTypes.func, // 获取当前点击的页码
    showGotoPage: PropTypes.bool, // 实现显示前往页码
  }

  // 属性默认值
  static defaultProps = {
    className: "",
    total: 0,
    currentPage: 1, 
    showTotal: true,
    pageCountList: [10, 15, 20, 40, 50],
    showPageCountSelect: true,
    pageCount: 10,
    seqPageStartListCount: 6,
    pageStyle: {"textAlign": "right"},
    getClickPage: () => {},
    pageCountChange: () => {},
    showGotoPage: true,
  };
  
  // 初始化
  componentDidMount () {
    this.setState({
      currentPage: this.props.currentPage,
      gotoPageVal: this.props.gotoPageVal,
    })
    this.retPageGroup(this.props.pageCount, this.props.currentPage);
  }

  // 变更
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.total !== this.props.total) {
      this.setState({
        currentPage: this.props.currentPage,
        gotoPageVal: this.props.gotoPageVal,
      })
      this.retPageGroup(this.props.pageCount, this.props.currentPage);
    }
  }

  // 页码分组
  retPageGroup = (currPageCount, currentPage) => {
    currentPage = parseInt(currentPage)
    currPageCount = parseInt(currPageCount)
    const {
      total,
      seqPageStartListCount,
      pageCount
    } = this.props;

    let seqPageStartList = []
    let seqPageCenterList = []
    let seqPageEndNum = currPageCount
    if (currPageCount == 0) {
      seqPageEndNum = pageCount
    }
    seqPageEndNum = Math.ceil(total/seqPageEndNum)
    if (currentPage > seqPageEndNum) {
      currentPage = seqPageEndNum 
    }
    
    // 分组开始部位的序号
    if (seqPageEndNum < seqPageStartListCount) {
      for(let i=0; i<seqPageEndNum; i++) {
        seqPageStartList.push(i+1)
      } 
    }else {
      if(currentPage < seqPageStartListCount) {
        for(let i=0; i<seqPageStartListCount; i++) {
          seqPageStartList.push(i+1)
        } 
      }else {
        seqPageStartList.push(1)
        // 分组中间序号
        let n1 = currentPage - 2
        let n2 = currentPage - 1
        if (n1 > 1) seqPageCenterList.push(n1)
        if (n2 > 1) seqPageCenterList.push(n2)
        seqPageCenterList.push(currentPage)
        let n3 = currentPage + 1
        let n4 = currentPage + 2
        if (n3 < seqPageEndNum) seqPageCenterList.push(n3)
        if (n4 < seqPageEndNum) seqPageCenterList.push(n4) 
      }
    }

    // 分组尾部部位序号
    if (total > seqPageStartListCount) {
      this.setState({seqPageEndNum: seqPageEndNum})
    }else {
      this.setState({seqPageEndNum: 0})
    }

    this.setState({
      seqPageStartList: seqPageStartList,
      seqPageCenterList: seqPageCenterList,
      currPageCount: currPageCount,
      currentPage: currentPage,
      gotoPageVal: currentPage
    })
  }

  // 每页总数选择数据改变事件
  pageCountChange = (val) => {
    const { currentPage } = this.state
    let curPage = currentPage
    let maxPageCount = Math.ceil(this.props.total / val)
    const showChangeCurrentPage = maxPageCount < currentPage

    // 如果每页展示的条数大于当前展示的条数，需要更新下展示的页码
    if (showChangeCurrentPage) {
      curPage = maxPageCount
    }
    this.retPageGroup(val, curPage)
    this.props.pageCountChange(val)
    
    if (showChangeCurrentPage) {
      const t = setTimeout(() => {
        this.props.getClickPage(curPage)
        clearTimeout(t)
      }, 0);
    }
  }

  // 跳到指定页事件
  gotoPageChange = (event) => {
    const {
      seqPageEndNum,
      currentPage
    } = this.state
    let val = Number(event.target.value) || currentPage
    if (parseInt(val) > parseInt(seqPageEndNum)) {
      val = seqPageEndNum || 1
    }
    this.retPageGroup(this.state.currPageCount, val)
    this.props.updatePageInfo(val)
    this.props.getClickPage(val)
  }

  // 跳转指定页输入改变事件
  gotoPageChangeVal = (event) => {
    const val = event.target.value
    this.setState({gotoPageVal: val})
  }

  // 点击了指定页码
  clickPageNum = (num) => {
    this.retPageGroup(this.state.currPageCount, num)
    this.props.updatePageInfo(num)
    this.props.getClickPage(num)
  }

  render () {
    const {
      className,
      total,
      showTotal,
      showPageCountSelect,
      pageStyle,
      seqPageStartListCount
    } = this.props;
    
    const {
      seqPageStartList,
      currentPage,
      currPageCount,
      seqPageEndNum,
      seqPageCenterList,
      gotoPageVal
    } = this.state  

    let pageCountList = this.props.pageCountList.map(i => {
      return {
        id: i,
        text: i + '条/页'
      }
    })

    return (
      <div style={pageStyle} className={`table-box-paging ${className}`}>
        {/* 总记录数 */}
        { showTotal && <span className="pageCom total">共{total}条</span> }

        {/* 下拉选项每页条数 */}
        { showPageCountSelect &&
          <span className="pageCom pageCount">
            <SdwSelect
              clearable={false}
              value={currPageCount}
              onChange={(val) => this.pageCountChange(val)}
              data={pageCountList}
            />
          </span>
        }
        
        {/* 序列页码 */}
        <span className="pageCom seqPage">
          { currentPage > 0 && seqPageStartList.length !== 1 && <span className="lastNext last" onClick={() => {
            if (currentPage > 1) {
              this.clickPageNum(currentPage - 1)
            }
          }} ></span> }
          <ul className="seqPageLi">
            {/* 序列前面部位 */}
            {seqPageStartList.map((num, k) => {
              if(num == currentPage) {
                return <li onClick={() => this.clickPageNum(num)} style={{"color": "#265cf0"}} key={k}>{num}</li>
              }else {
                return <li onClick={() => this.clickPageNum(num)} key={k}>{num}</li>
              }
            })}
        
            {/* 序列中间部位 */}
            {seqPageEndNum > seqPageStartListCount && <li className="more">...</li>}
            {seqPageCenterList.map((num, k) => {
              if(num == currentPage) {
                return <li onClick={() => this.clickPageNum(num)} style={{"color": "#265cf0"}} key={k}>{num}</li>
              }else {
                return <li onClick={() => this.clickPageNum(num)} key={k}>{num}</li>
              }
            })}
            {seqPageCenterList.length > 0 && currentPage < seqPageEndNum && <li className="more">...</li>}

            {/* 序列尾部 */}
            {seqPageEndNum > seqPageStartListCount && currentPage < seqPageEndNum &&  <li onClick={() => this.clickPageNum(seqPageEndNum)}>{seqPageEndNum}</li>}
          </ul>
          { currentPage <= seqPageEndNum && <span className="lastNext next" onClick={() => {
            if (currentPage < seqPageEndNum) {
              this.clickPageNum(currentPage + 1)
            }
          }}></span> }
        </span>

        {/* 跳转页码 */}
        {
          this.props.showGotoPage && 
          <span className="pageCom gotoPage">
            前往<input value={gotoPageVal} onChange={(event) => this.gotoPageChangeVal(event)} onBlur={(event) => this.gotoPageChange(event)} />页
          </span>
        }
      </div>
    );
  }
}

const mapState = state => ({
  pageInfo: state.page.pageInfo
})

const mapDispatch = dispatch => ({
  updatePageInfo: dispatch.page.updatePageInfo
})

export default withRouter(connect(mapState, mapDispatch)(Pagenation))