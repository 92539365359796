import { useRef } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import SdwDialog from '@common/Dialog'
import DataBaseWin from './win/DataBaseWin'
import DataLogWin from './win/DataLogWin'

import './style/dataReview.css'

function DataReview (props) {
  const { type } = props
  
  const currentRef = useRef(_.noop)

  return (
    <>
      <SdwDialog
        width={type === 'DataLogWin' ? '60%' : '600'}
        title='提交任务'
        visible={props.submitVisible}
        changeVisible={props.changeVisible}
        onCancelClick={() => props.changeVisible(false)}
        onSureClick={() => currentRef.current()}
      >
        {
          type === 'DataBaseWin' &&
          <DataBaseWin {...props} curRef={currentRef} />
        }

        {
          type === 'DataLogWin' &&
          <DataLogWin {...props} curRef={currentRef} />
        }
      </SdwDialog>
    </>
  )
}

DataReview.propTypes = {
  changeVisible: PropTypes.func
}

DataReview.defaultProps = {
  changeVisible: _.noop
}

export default DataReview