// 元数据 常量文件
import _ from 'lodash'
import { getMetaDataInfo } from '@services/metaData'
import SdwMessage from '@common/Message/index'
import { LEVEL_TYPE_MAP as LTyM, LEVEL_TEXT_MAP as LTxM } from '@pages/common/index'

// 对应下发参数
export const LEVEL_TYPE_MAP = LTyM

export const LEVEL_TEXT_MAP = LTxM

export async function getMetaDataDetailInfo(id = '') {
  if (!id) return null

  let item = {}
  
  // 获取表头详情信息
  await getMetaDataInfo({
    search: JSON.stringify({
      id: {
        value: id,
        operator: '='
      }
    })
  }).then(res => {
    if (res && res.data && res.data.code === 0) {
      let record = res.data.data || {}
      item = _.isArray(record.items) ? record.items[0] : {}
    } else {
      let msg = res && res.data && res.data.msg || '获取数据失败'
      SdwMessage.error(msg)
    }
  }).catch(err => SdwMessage.error('请求异常 ' + err))

  return item
}