import Tabs from '@common/Tabs'
import Trajectory from './Trajectory'
import WorkOrder from './WorkOrder'

const menu = [
  {
    title: '服务轨迹',
    path: '/trajectory',
    component: Trajectory
  }, {
    title: '历史工单',
    path: '/workOrdert',
    component: WorkOrder
  }
]

export default function Service(props) {


  return (
    <>
      <Tabs 
        menu={menu}
        {...props}
      />
    </>
  )
}