import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwTabs from '@common/Tabs'
import LabelStandardManagement from './LabelStandardManagement'
import TicketLabelRules from './TicketLabelRules'
import UserLabelRules from './UserLabelRules'
import OfflineTaskManagement from './OfflineTaskManagement'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

const menu = [
    {
        title: '标签标准管理',
        path: '/labelStandardManagement',
        component: LabelStandardManagement,
        priv: 'standardManage'
    }, {
        title: '工单标签规则',
        path: '/ticketLabelRules',
        component: TicketLabelRules,
        priv: 'orderRule'
    }
    // 一期暂时隐藏
    // , {
    //   title: '用户标签规则',
    //   path: '/userLabelRules',
    //   component: UserLabelRules,
    // }
    , {
        title: '离线任务管理',
        path: '/offlineTaskManagement',
        component: OfflineTaskManagement,
        priv: 'taskFlow'
    }
]

export default function LabelManagement(props) {

    const hasPrivMenu = menu.filter(i => hasPriv(i.priv))

    return (
        <>
            <SdwBreadCrumbs
                history={props.history}
                data={[
                    {
                        title: '标签加工厂',
                        path: '/admin/labelProcess/ticketLabel'
                    }, {
                        title: '工单标签'
                    }
                ]}
            />

            <div style={{ padding: '0 20px', background: '#fff' }}>
                <SdwTabs
                    menu={hasPrivMenu}
                    titleWidth={120}
                />
            </div>
        </>
    )
}
