import { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Steps from '@common/Steps'
import LabelTest from './LabelTest'
import LabelRuleConfig from './LabelRuleConfig'
import Message from '@common/Message'
import Dialog from '@common/Dialog'
import { commonApi } from '@utils/request'
import { insertOrderRule, updateOrderRule } from '@services/labelManagement'
import _ from 'lodash'

let curNext = _.noop

function StepWin(props) {
  const {
    showSteps, isEdit, hideStepsFunc, labelRuleConfig, record, updateLabelRuleConfig, updatePage, updateLabelRuleConfigID, labelRuleConfigID
  } = props

  const formRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [curIsEdit, setCurIsEdit] = useState(false)

  useEffect(() => {
    if (!isEdit || _.isEmpty(record)) {
      updateLabelRuleConfig({})
    } else {
      updateLabelRuleConfig(record)
    }
  }, [record, isEdit])

  const stepMenu = [
    [
      {
        stepNumber: 'one',
        title: '标签规则配置',
        component: <LabelRuleConfig formRef={formRef} />,
        nextBtn: '保存&下一步'
      }
    ],
    [
      {
        stepNumber: 'two',
        title: '标签测试',
        component: <LabelTest updatePage={updatePage} />,
        prevBtn: '上一步',
        nextBtn: '完成'
      }
    ]
  ]

  // 上一步
  function handlePrevBtn(curIndex, next) {
    // console.log('handlePrevBtn: ', curIndex);
    // 如果回到上一步，保存走编辑逻辑
    setCurIsEdit(true)
    next()
  }

  function handleSure() {
    setVisible(false)
    onSumit()
  }

  function onSumit() {
    const api = (isEdit || curIsEdit) ? updateOrderRule : insertOrderRule
    commonApi(api, {
      field: labelRuleConfig,
      ...isEdit ? {
        id: record.id
      } : {},
      ...curIsEdit ? {
        id: labelRuleConfigID
      } : {}
    }).then(data => {
      updateLabelRuleConfigID(data.id || '')
      updatePage()
      curNext()
    }).catch(err => Message.error(err))
  }

  // 下一步
  function handleNextBtn(curIndex, next) {
    curNext = next

    switch (curIndex) {

      // 标签规则配置，点击保存&下一步按钮触发逻辑
      case 0:
        formRef.current.validate(valid => {
          const { function_name, function_handle } = labelRuleConfig
          const isPass = valid && (!!function_name ? !!function_handle : true)
          if (isPass) { // 校验成功进入下一步

            if (isEdit || curIsEdit) {
              setVisible(true)
            } else {
              onSumit()
            }
          }
        })
        break;

      // 标签测试后，点击完成按钮触发逻辑
      case 1:
        hideStepsFunc(false) // 关闭步骤弹窗
        break;

      default:
        break;
    }
  }

  return (
    <>
      {
        showSteps &&
        <Steps
          {...props}
          stepMenu={stepMenu}
          handlePrevBtn={handlePrevBtn}
          handleNextBtn={handleNextBtn}
          breadMenu={[
            {
              title: '标签加工厂',
              path: '/admin/labelProcess/ticketLabel'
            }, {
              title: '工单标签',
              goBack: true // 返回上一级页面
            }, {
              title: isEdit ? '编辑工单标签' : '新增工单标签'
            }
          ]}
        />
      }
      {
        visible &&
        <Dialog
          title='提示'
          width={500}
          visible={visible}
          changeVisible={setVisible}
          onCancelClick={() => setVisible(false)}
          onSureClick={handleSure}
        >
          <div>将暂停该标签规则，确认保存？</div>
        </Dialog>
      }
    </>
  )
}

const mapState = state => ({
  labelRuleConfig: state.labelManagement.labelRuleConfig,
  labelRuleConfigID: state.labelManagement.labelRuleConfigID
})

const mapDispatch = dispatch => ({
  updateLabelRuleConfig: dispatch.labelManagement.updateLabelRuleConfig,
  updateLabelRuleConfigID: dispatch.labelManagement.updateLabelRuleConfigID
})

export default connect(mapState, mapDispatch)(StepWin)
