import React, { PureComponent } from 'react'
import { Dialog, Form, Input } from 'element-react'
import SdwSwitch from '@common/Switch'
import SdwMessage from '@common/Message'
import { getManageTopics, storeTopic, getTopicInfoById } from '@services/management'
import editImg from '../../../img/icon_16_bj@2x.png'
import addNewImg from '../../../img/icon_56_xz@2x.png'
import addNewHoverImg from '../../../img/icon_56_xz_hover@2x.png'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwLoading from '@common/Loadding'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

import 'element-theme-default'
import './index.css'

const DEFAULT_FORM = {
  topic_name: '',
  introduct: '',
  system: '',
  development_heads: '',
  product_heads: '',
  enable: true,
  // icon: '',
}

class DomainManagement extends PureComponent {
  formRef = React.createRef()

  state = {
    showData: false,
    listData: [],
    form: DEFAULT_FORM,
    dialogTitle: '',
    dialogVisible: false,
    addImgSrc: addNewImg,
    rules: {
      topic_name: [
        { required: true, message: '主题域名称不能为空', trigger: 'blur' }
      ],
      introduct: [
        { required: true, message: '主题域简介不能为空', trigger: 'blur' }
      ],
      system: [
        { required: true, message: '相关系统不能为空', trigger: 'blur' }
      ],
      development_heads: [
        { required: true, message: '开发负责人不能为空', trigger: 'blur' }
      ],
      product_heads: [
        { required: true, message: '产品负责人不能为空', trigger: 'blur' }
      ],
      icon: [
        { required: true, message: '主题图标不能为空', trigger: 'blur' }
      ],
    }
  }

  componentDidMount () {
    this.initData()
  }

  initData = () => {
    getManageTopics().then(res => {
      this.setState({
        showData: true
      })
      let jsonData = res && res.data || {}
      if (jsonData && jsonData.code === 0 && Array.isArray(jsonData.data)) {
        this.setState({
          listData: jsonData.data
        })
      }
    })
      .catch(() => {
        this.setState({
          showData: true
        })
      })
  }

  editManagement = async record => {
    this.formRef.current.resetFields()
    let res = await getTopicInfoById({ id: record.topic_id })
    let formData = {}
    if (res && res.data && res.data.code === 0) {
      formData = res.data.data || {}
    }
    this.setState({
      form: Object.assign({}, formData, {
        enable: Boolean(formData.enable)
      }),
      dialogTitle: '编辑主题域',
      dialogVisible: true,
    })
  }

  goTopicInfo = item => {

    // 注：使用 this.props.location.state 接受query参数
    this.props.history.push({
      pathname: '/admin/dataMarket/domaindetail',
      state: {
        topicid: item.topic_id,
        seclevel: 'management'
      }
    })
  }

  handleLink = (item, id) => {
    let pathName = ''

    switch (id) {
      case 1:
        pathName = '/admin/dataMarket/standard'
        break;

      case 2:
        pathName = '/admin/factory/atom'
        break;

      case 3:
        pathName = '/admin/factory/derived'
        break;

      default:
        break;
    }

    if (!pathName) return
    this.props.history.push({
      pathname: pathName,
      state: {
        typeID: id,
        topicID: item.topic_id
      }
    })
  }

  onMouseenter = () => {
    this.setState({
      addImgSrc: addNewHoverImg
    })
  }

  onMouseleave = () => {
    this.setState({
      addImgSrc: addNewImg
    })
  }

  handleCancel = () => {
    this.setState({
      dialogVisible: false,
      form: Object.assign({}, this.state.form, { enable: true }),
    })
  }

  addSubjectDomain = () => {
    this.formRef.current.resetFields()
    this.setState({
      form: DEFAULT_FORM,
      dialogTitle: '新增主题域',
      dialogVisible: true,
    })
  }

  handleSubmit = () => {
    this.formRef.current.validate((valid) => {
      if (valid) {
        let { form } = this.state
        storeTopic({
          ...form.id ? {
            id: form.id
          } : {},
          topic_name: form.topic_name,
          introduct: form.introduct,
          system: form.system,
          development_heads: form.development_heads,
          product_heads: form.product_heads,
          enable: +form.enable
        })
          .then( res => {
            if(res && res.data.code === 0) {
              let msg = form.id ? '编辑成功' : '创建成功'
              SdwMessage.success(msg)
              this.setState({
                dialogVisible: false
              })
              this.initData()
            } else {
              SdwMessage.error(res && res.data && res.data.msg)
            }
          })
      } else {
        SdwMessage.error('表单未校验通过!')
        return false
      }
    })
  }

  render () {
    let {
      listData,
      showData,
      addImgSrc,
      dialogTitle,
      dialogVisible,
      rules,
      form,
    } = this.state

    return (
      <SdwLoading className="page-main__wrap" loadding={!showData}>
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '数据集市',
              path: '/admin/dataMarket/dictionary'
            }, {
              title: '主题域管理'
            }
          ]}
        />

        <div className="views-management__content-wrap">
          {
            <DomainItem
              data={listData.filter(i => i.enable)}
              handleLink={this.handleLink}
              goTopicInfo={this.goTopicInfo}
              editManagement={this.editManagement} />
          }
          {
            showData &&
            <div
              className="m-panel-theme views-management__wrap"
              onMouseEnter={this.onMouseenter}
              onMouseLeave={this.onMouseleave}
              onClick={this.addSubjectDomain}
            >
              <div className="views-management__add-new">
                <div className="views-management__add-img">
                  <img src={addImgSrc} alt="" />
                </div>
                <div className="views-management__add-button">
                  添加主题域
              </div>
              </div>
            </div>
          }
        </div>
        {
          !!listData.filter(i => !i.enable).length &&
          <div className="views-management__no-domain-wrap">无效主题域</div>
        }
        <div className="views-management__content-wrap">
          {
            <DomainItem
              data={listData.filter(i => !i.enable)}
              handleLink={this.handleLink}
              goTopicInfo={this.goTopicInfo}
              editManagement={this.editManagement} />
          }
        </div>

        <Dialog
          size='tiny'
          className="domain-management__dialog-wrap"
          title={ dialogTitle }
          visible={ dialogVisible }
          onCancel={ this.handleCancel }
          lockScroll={ false }
        >
          <Dialog.Body>
            <Form
              ref={this.formRef}
              rules={ rules }
              model={ form }
              labelPosition='left'
              labelWidth="100"
            >
              <Form.Item label="主题域名称" prop="topic_name">
                <Input value={form.topic_name} onChange={ (value) => this.setState({
                  form: { ...form, topic_name: value }
                }) }></Input>
              </Form.Item>

              <Form.Item label="主题域简介" prop="introduct">
                <Input value={form.introduct} onChange={ (value) => this.setState({
                  form: { ...form, introduct: value }
                }) }></Input>
              </Form.Item>

              <Form.Item label="相关系统" prop="system">
                <Input value={form.system} onChange={ (value) => this.setState({
                  form: { ...form, system: value }
                }) }></Input>
              </Form.Item>

              <Form.Item label="开发负责人" prop="development_heads">
                <Input value={form.development_heads} onChange={ (value) => this.setState({
                  form: { ...form, development_heads: value }
                }) }></Input>
              </Form.Item>

              <Form.Item label="产品负责人" prop="product_heads">
                <Input value={form.product_heads} onChange={ (value) => this.setState({
                  form: { ...form, product_heads: value }
                }) }></Input>
              </Form.Item>

              <div style={{ paddingLeft: '10px' }}>
                <Form.Item label="是否有效">
                  <SdwSwitch
                      value={form.enable}
                      changeValue={(value) => this.setState({
                        form: { ...form, enable: value }
                      })}
                  />
                  <div>启用后，配置标准时可选择该主题域</div>
                </Form.Item>
              </div>
            </Form>
          </Dialog.Body>
          <Dialog.Footer className="dialog-footer">
            <div className="sdw-drawer__content-footer" style={{ borderTop: 'none' }}>
              <button
                className='sdw-drawer__content-footer-button--cancel'
                onClick={ this.handleCancel }
              >取 消</button>
              <button
                className='sdw-drawer__content-footer-button--primary'
                onClick={ this.handleSubmit }
              >确 定</button>
            </div>
          </Dialog.Footer>
        </Dialog>
      </SdwLoading>
    )
  }
}

export default DomainManagement

function DomainItem (props) {
  return (
    props.data.map(item => (
      <div key={item.topic_id} className="m-panel-theme">
        <div className="panel-theme__hd">
          <div className="panel-title">
            <div style={{ paddingLeft: 4, cursor: 'pointer' }}>
              <span
                className="panel-title__txt"
                onClick={ () => {
                  if (!hasPriv('getTopicInfoById')) return
                  props.goTopicInfo(item)
                } }
              >
                {item.topic_name}
              </span>
              <img
                src={editImg}
                style={{ paddingLeft: 4, cursor: 'pointer', height: 16 }}
                onClick={ () => props.editManagement(item) }
              />
            </div>

            {/* icon 暂时隐藏
            <div className="panel-title__rightIcon">
              <img className="pc-image" src={item.icon} />
            </div> */}
          </div>
          <div className="panel-data-status">
            {
              item.data.map((item1, index1) => (
                <div
                  key={index1}
                  className="data-status__item"
                  onClick={ () => {
                    if (!hasPriv('searchFields')) return
                    props.handleLink(item, item1.standard_type)
                  } }
                >
                  <div className="data-status__value">
                    {item1.num}
                  </div>
                  <div className="data-status__desc">{item1.name}</div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="panel-theme__bd">
          <div className="panel-theme-target">
            <div className="target__hd">
              <div className="target__row">
                {item.introduct}
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  )
}
