import React from 'react'
import _ from 'lodash'
import SdwDialog from '@common/Dialog'
import SdwSelect from '@common/Select'
import SdwInput from '@common/Input'
import { TOP_MAP, TOP_MAP_TEXT } from './common'

import './style/setTopWin.css'

export default class SetTopWim extends React.Component {
  state = {
    selectInputValue: '',
    inputValue: '',
    sholdCheckValidate: false,
    selectList: [
      {
        id: TOP_MAP.top,
        text: TOP_MAP_TEXT[TOP_MAP.top]
      }, {
        id: TOP_MAP.normal,
        text: TOP_MAP_TEXT[TOP_MAP.normal]
      }
    ]
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    let { record } = this.props

    if (!_.isEqual(prevProps.record, record)) {
      this.setState({
        selectInputValue: record.target_type
      })

      if (record.top_count) {
        this.setState({
          inputValue: record.top_count
        })
      }
    }
  }

  numberValidateFun = val => {
    if (!!val && Number(val) > 0 && String(val).indexOf('.') === -1) {
      return true
    }
    return '请输入一个有效整数'
  }

  topValidateFun = val => {
    if (!!val) {
      return true
    }
    return '指标类型不能为空'
  }

  handleSubmit = () => {
    let {
      selectInputValue,
      inputValue
    } = this.state

    let isTopType = this.state.selectInputValue === TOP_MAP.top
    let isSelectPass = this.topValidateFun(selectInputValue) === true

    let isValidatePass = isTopType ? this.numberValidateFun(inputValue) === true && isSelectPass : isSelectPass

    if (!isValidatePass) {
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    let params = isTopType ? {selectInputValue, inputValue} : {selectInputValue}

    this.props.updateTargetValue(this.props.record, params)
    this.props.changeSetTopVisible(false)
  }

  render () {
    let { setTopVisible, changeSetTopVisible } = this.props

    return (
      <SdwDialog
        title='指标设置'
        visible={setTopVisible}
        changeVisible={changeSetTopVisible}
        onCancelClick={() => changeSetTopVisible(false)}
        onSureClick={this.handleSubmit}
        width={480}
        height={300}
      >
        <div className="sdw-set-top-win__lable-wrap">
          <span className="label">指标类型</span>
          <SdwSelect
            width={260}
            listWidth={260}
            placeholder="请选择"
            clearable={false}
            data={this.state.selectList}
            value={this.state.selectInputValue}
            validateFun={this.topValidateFun}
            sholdCheckValidate={this.state.sholdCheckValidate}
            onChange={val => this.setState({selectInputValue: val})}
          />
        </div>
        {
          this.state.selectInputValue === TOP_MAP.top &&
          <div className="sdw-set-top-win__lable-wrap">
            <span className="label">数值</span>
            <SdwInput
              width={260}
              placeholder="请输入一个有效整数"
              clearable={false}
              value={this.state.inputValue}
              validateFun={this.numberValidateFun}
              sholdCheckValidate={this.state.sholdCheckValidate}
              onChange={val => this.setState({inputValue: +val})}
            />
          </div>
        }
      </SdwDialog>
    )
  }
}
