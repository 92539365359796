import BreadCrumbs from '@common/BreadCrumbs'
import Tabs from '@common/Tabs'
import IndexGroupConfiguration from './IndexGroupConfiguration'
import IndexConcernManagement from './IndexConcernManagement'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

const menu = [
    {
        title: '指标组配置',
        path: '/IndexGroupConfiguration',
        component: IndexGroupConfiguration,
    }, {
        title: '关注人管理',
        path: '/IndexConcernManagement',
        component: IndexConcernManagement,
    }
]

export default function KeyIndicatorAllocation(props) {

    const hasPrivMenu = menu.filter(i => hasPriv(i.priv))

    return (
        <>
            <BreadCrumbs
                history={props.history}
                data={[
                    {
                        title: '智慧运营台',
                        path: '/admin/monitor/monitorManagement'
                    }, {
                        title: '关键指标配置'
                    }
                ]}
            />

            <div style={{ padding: '0 20px', background: '#fff' }}>
                <Tabs
                    menu={hasPrivMenu}
                    titleWidth={120}
                />
            </div>
        </>
    )
}
