import React from 'react'
import _ from 'lodash'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import SdwMessage from '@common/Message'
import SdwTable from '@common/Table'
import SdwEnable from '@common/Enable'
import SdwPagination from '@common/Pagenation'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import LogDetails from './StepWin/LogDetails'
import CollectionTask from './StepWin/CollectionTask'
import StatusWin from './StepWin/StatusWin'
import {getStandardSelects} from '@services/Derived'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../index.css'
import {requestOptionsLOG} from '@config/config'
const baseURL = requestOptionsLOG.basename

class CollectionServiceManagement extends React.PureComponent {
    state = {
        logVisible: false, // 新增或编辑日志文件弹窗
        taskVisible: false, // 新增或编辑采集任务弹窗
        statusVisible: false, // 链路检查弹窗
        topicData: [], // 主题域
        ipListData: [], // 日志服务器
        search: {
            page: 1,
            size: 10,
            path: "",
            inputTag: "",
            businessTopic: "",
            enable: "",
            accessStatus: "",
            ip: ""
        },
        tableData: {
            configList: [],
            total: 0,
        },
        current_page: 1,
        currentItem: {},
        isEdit: false,
        tagIsEdit: false,
        socketList: {
            checkerList: [],
            sessionId: 0
        }
    }

    componentDidMount() {
        this.queryInputFileConfigList()
        this.getStandardSelects()
        this.queryValidIpList()
    }

    // 查询采集服务配置列表
    queryInputFileConfigList = () => {
        let {search} = this.state
        let params = {}
        let rtx = this.props.userInfo.name
        for (let key in search) {
            if (search[key]) {
                params[key] = search[key]
            }
        }
        fetch(baseURL + '/sdw/filebeat/input/queryInputFileConfigList',
            {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                let record = data.data || {}
                this.setState({
                    tableData: record
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 主动链路检测
    chainCheck = (data) => {
        let rtx = this.props.userInfo.name
        let str = '?id=' + parseInt(data.id)
        fetch(baseURL + '/sdw/filebeat/input/chainCheck/' + str,
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then((data) => {
                let record = data.data || {}
                if (data && data.code === 100 || data.code === 101) {
                    this.collectionTaskSubmit(data)
                } else {
                    SdwMessage.error(data.message)
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 获取主题域列表
    getStandardSelects = () => {
        getStandardSelects().then((res) => {
            let topicRecord = res.data.data
            this.setState({
                topicData: topicRecord.topic,
            })
        })
    }

    // 查询有效的机器ip
    queryValidIpList = () => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/filebeat/queryValidIpList',
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then(data => {
                if (data && data.code === 100) {
                    let items = data.data || []
                    this.setState({
                        ipListData: items.map(i => {
                            return {
                                id: i,
                                text: i
                            }
                        })
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    getColumns = () => {
        let self = this
        return [
            {
                title: 'checkTd',
                dataIndex: 'id',
                checkTd: 1,
                width: 20,
            },
            {
                title: 'ID',
                dataIndex: 'id',
                checkChildren: true,
                width: 68
            },
            {
                title: '日志文件',
                dataIndex: 'path',
                width: 160
            },
            {
                title: '采集服务名称',
                dataIndex: 'inputName',
                width: 124
            },
            {
                title: 'CMD',
                dataIndex: 'cmd',
                width: 130
            },
            {
                title: '采集标识',
                dataIndex: 'inputTag',
                width: 130
            },
            {
                title: '主题域',
                dataIndex: 'businessTopic',
                width: 126
            },
            {
                title: '业务',
                dataIndex: 'business',
                width: 110
            },
            {
                title: '提交状态',
                render: data => <SdwEnable enable={data.enable}/>,
                width: 70
            },
            {
                title: '链路状态',
                render: data => {
                    let arr = {
                        '-803': '采集配置下发失败',
                        '-804': '解析下发失败',
                        '-805': '采集失败',
                        '-806': '收包失败',
                        '-807': '消费失败',
                        '800': '正常'
                    }
                    let msg = arr[data.status]
                    return <span className={
                        (msg ? (Boolean(data.status === 800) ? 'status-comp_is-effect' : 'status-comp_no-effect') : '-')
                    }>
                        {msg ? msg : '-'}
                    </span>
                },
                width: 148
            },
            {
                title: '修改人',
                dataIndex: 'updateRtx',
                width: 96
            },
            {
                title: '修改时间',
                dataIndex: 'updateTime',
                width: 148
            },
            {
                title: '操作',
                width: 140,
                render: data => {
                    if (data.hasOwnProperty("inputConfigViewDTOS")) {
                        return <>
                            <SdwButton
                                text='编辑'
                                onClick={() => {
                                    self.logAddOrEdit(true, data)
                                }}
                            />
                            <SdwButton
                                text='新增采集'
                                onClick={() => {
                                    self.taskAddOrEdit(false, data)
                                }}
                            />
                        </>
                    } else {
                        return <>
                            <SdwButton
                                text='编辑'
                                onClick={() => {
                                    let dataClone = data
                                    dataClone.id = dataClone.id.replace(/(^\s*)/g, "");
                                    self.taskAddOrEdit(true, dataClone)
                                }}
                            />
                            <SdwButton
                                text='链路检测'
                                onClick={() => {self.chainCheck(data)}}
                            />
                        </>
                    }

                }
            }
        ]
    }

    // 新增或编辑日志文件弹窗
    logAddOrEdit = (isEdit, record = {}) => {
        this.setState({
            isEdit: isEdit,
            logVisible: true,
            currentItem: record,
        })
    }

    // 新增或编辑采集任务弹窗
    taskAddOrEdit = (tagIsEdit, record) => {
        this.setState({
            tagIsEdit: tagIsEdit,
            taskVisible: true,
            currentItem: record,
        })
    }

    setSearch = (type, val) => {
        let {search} = this.state
        let obj = {}
        obj[type] = val
        if (type != 'page') {
            obj['page'] = 1
        }
        let searchClone = Object.assign({}, search, obj)
        this.setState({
            search: searchClone
        }, () => {
            this.queryInputFileConfigList()
        })
    }

    collectionTaskSubmit = (data) => {
        this.queryInputFileConfigList()
        let code = data.code
        if (code === 101) {
            this.setState({
                statusVisible: true,
                socketList: data.data
            })
        }
    }

    render() {
        let {
            search,
            logVisible,
            taskVisible,
            statusVisible,
            tableData,
            topicData,
            ipListData,
            isEdit,
            tagIsEdit,
            currentItem,
            socketList,
        } = this.state

        let tableDataClone = _.cloneDeep(tableData)
        tableDataClone.configList = tableDataClone.configList || []
        let list = tableDataClone.configList.map(i => {
            if (i.hasOwnProperty("inputConfigViewDTOS") && !!i.inputConfigViewDTOS) {
                i.inputConfigViewDTOS = i.inputConfigViewDTOS.map(j => {
                    j.id = " " + j.id
                    return j
                })
            }
            return i
        })

        return (
            <>
                <BreadCrumbsAndTablde>
                    <BreadCrumbsAndTablde.LeftCrumbs>
                        <SdwInput
                            label='日志文件'
                            width={260}
                            value={search.path}
                            onChange={val => this.setSearch('path', val)}
                        />
                        <SdwInput
                            label='采集标识'
                            width={260}
                            value={search.inputTag}
                            onChange={val => this.setSearch('inputTag', val)}
                        />
                        <SdwSelect
                            label='主题域'
                            width={200}
                            readOnly={false}
                            data={topicData.map(i => {
                                return {
                                    id: i.topic_name,
                                    text: i.topic_name
                                }
                            })}
                            value={search.businessTopic}
                            onChange={(val, item) => {
                                this.setSearch('businessTopic', item.text)
                            }}
                        />
                        {/*<SdwSelect
                            label='提交状态'
                            width={200}
                            value={search.enable}
                            data={[{
                                id: 'true',
                                text: '有效'
                            }, {
                                id: 'false',
                                text: '无效'
                            }]}
                            onChange={val => this.setSearch('enable', val)}
                        />*/}
                        <SdwSelect
                            label='链路状态'
                            width={200}
                            value={search.accessStatus}
                            data={[{
                                id: -803,
                                text: '采集配置下发失败'
                            }, {
                                id: -804,
                                text: '解析下发失败'
                            }, {
                                id: -805,
                                text: '采集失败'
                            }, {
                                id: -806,
                                text: '收包失败'
                            }, {
                                id: -807,
                                text: '消费失败'
                            }, {
                                id: 800,
                                text: '正常'
                            }]}
                            onChange={val => this.setSearch('accessStatus', val)}
                        />
                        <SdwSelect
                            label='日志服务器'
                            width={200}
                            readOnly={false}
                            data={ipListData}
                            value={search.ip}
                            onChange={val => this.setSearch('ip', val)}
                        />
                    </BreadCrumbsAndTablde.LeftCrumbs>
                    <BreadCrumbsAndTablde.RightCrumbs>
                        <SdwButton
                            text='新增日志文件'
                            type='submit'
                            iconClass='iconfont icon-add'
                            onClick={() => this.logAddOrEdit(false, {})}
                        />
                    </BreadCrumbsAndTablde.RightCrumbs>
                    <BreadCrumbsAndTablde.CenterWrap>
                        <SdwTable
                            tdWhiteSpace={true}
                            childrenString={"inputConfigViewDTOS"}
                            columns={this.getColumns()}
                            dataSource={list || []}
                        />
                        <SdwPagination
                            total={tableData.total || 0}
                            currentPage={tableData.current_page || 1}
                            pageCount={tableData.per_page}
                            getClickPage={val => this.setSearch("page", val)}
                            pageCountChange={val => this.setSearch("size", val)}
                        />
                    </BreadCrumbsAndTablde.CenterWrap>
                </BreadCrumbsAndTablde>

                {/* 新增或编辑日志文件弹窗 */}
                {logVisible &&
                    <LogDetails
                        isEdit={isEdit}
                        currentItem={currentItem}
                        logVisible={logVisible}
                        changeVisible={(bool) => {
                            this.setState({logVisible: bool})
                        }}
                        iniList={this.queryInputFileConfigList}
                        collectionTaskSubmit={this.collectionTaskSubmit}
                    />
                }

                {/* 新增或编辑采集任务弹窗 */}
                {taskVisible &&
                    <CollectionTask
                        isEdit={tagIsEdit}
                        currentItem={currentItem}
                        taskVisible={taskVisible}
                        changeVisible={(bool) => {
                            this.setState({taskVisible: bool})
                        }}
                        collectionTaskSubmit={this.collectionTaskSubmit}
                    />
                }

                {statusVisible &&
                    <StatusWin
                        statusVisible={statusVisible}
                        socketList={socketList}
                        closeDialog={() => this.setState({statusVisible: false})}
                    />
                }

            </>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(CollectionServiceManagement))
