// @file 获取表名称自定义kook
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { getAllTablecfg } from '@services/dataSearch'
import { commonApi } from '@utils/request'

export default function useAllTablecfg() {

  const [record, setRecord] = useState([])

  useEffect(() => {
    commonApi(getAllTablecfg).then(data => {
      if (_.isArray(data) && data.length) {
        setRecord(data.map(i => ({
          ...i,
          text: `${i.database_name}.${i.table_name}(${i.data_set_chinese})`
        })))
      }
    })
  }, [])

  return record
}