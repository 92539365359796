import React, { PureComponent } from 'react'
import SdwDrawer from '../../../../common/Drawer'
import SdwInput from '../../../../common/Input'
import SdwInput2 from '../../../../common/Input2'
import SdwSelect from '../../../../common/Select'
import SdwCascader from '../../../../common/Cascader'
import { Radio, Cascader } from 'element-react'
import SdwMessage from '../../../../common/Message'
import { getTablecfgFields, storeOrUpdateAtomTarget } from '@services/dimen'
import 'element-theme-default'
import './style/EditData.css'

class EditData extends PureComponent {

  state = {
    id: "",
    dim_type: 1,
    dim_name_cn: "",
    dim_name_en: "",
    data_standard: "",
    propertyTableId: "",
    propertyInfo: {
      "id": "",
      "field": "",
      "name": "",
      "selectVal": "",
      "fieldId": "",
    },
    virtualPropertyList: [
      {
        "id": "",
        "name": "",
        "desc": "",
      }
    ],
    filter: '',
    
    tableFields: [],
    sholdCheckValidate: false,
    upState: 0
  }

  componentDidMount () {
    this.initData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.record.id !== this.props.record.id) {
      this.initData()
      this.getTablecfgFields(this.props.record.propertyTableId)
    }
  }

  initData = () => {
    this.setState({
      id: this.props.record.id,
      dim_type: this.props.record.dim_type || 1,
      dim_name_cn: this.props.record.dim_name_cn,
      dim_name_en: this.props.record.dim_name_en,
      data_standard: this.props.record.data_standard,
      propertyTableId: this.props.record.propertyTableId,
      propertyInfo: this.props.record.propertyInfo || {
        "id": "",
        "field": "",
        "name": "",
        "selectVal": "",
        "fieldId": 0,
      },
      virtualPropertyList: this.props.record.virtualPropertyList || [{
        "id": "",
        "name": "",
        "desc": "",
      }],
      filter: this.props.record.filter || ''
    })
  }
  
  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    
    return `${label}不能为空`
  }

  handleSureClick = () => {
    let {
      id,
      dim_type,
      dim_name_cn,
      dim_name_en,
      data_standard,
      propertyTableId,
      propertyInfo,
      virtualPropertyList,
      filter
    } = this.state

    let isValidatePass = 
      !!dim_type && 
      !!dim_name_cn &&
      !!dim_name_en &&
      !!data_standard 

    if (!isValidatePass) {
      // 手动触发必填项的检查
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    let dataid = this.props.record.id || 0

    let params = {
      id: dataid,
      dim_type,
      dim_name_cn,
      dim_name_en,
      data_standard,
      propertyTableId,
      propertyInfo,
      virtualPropertyList,
      filter
    }
    
    if(params.propertyInfo.selectVal && params.propertyInfo.selectVal != "") {
      let selectValList = params.propertyInfo.selectVal.split("@@@")
      if(selectValList.length > 1) {
        params.propertyInfo.field = selectValList[0] 
        params.propertyInfo.fieldId = selectValList[1] && selectValList[1] != '' ? selectValList[1] : 0
      }
    }

    if(params.propertyInfo.fieldId == "") {
      params.propertyInfo.fieldId = 0
    }

    // 编辑数据
    storeOrUpdateAtomTarget(params).then(res => {
      if (res && res.data && res.data.code === 0) {
        let msg = id === 0 ? '新建成功' : '编辑成功'
        SdwMessage.success(msg)
        // 告诉父级点击了确定按钮
        this.props.onSureClick()
      } else {
        SdwMessage.error(res.data.msg);
      }
    })
  }

  // 获取指定的表的字段
  getTablecfgFields = (related_table_id) => {
    this.setState({propertyTableId: related_table_id})
    let that = this
    let parame = {}
    parame.id = related_table_id
    getTablecfgFields(parame).then(res => {
      let tableFields = res.data.data || []
      that.setState({tableFields: tableFields})
    })
  }

  // 更新数据
  propertyInfoUpData = (val, type, index) => {
    if(type == "selectVal") {
      let propertyInfo = {...this.state.propertyInfo}
      propertyInfo.selectVal = val 
      this.setState({
        propertyInfo: propertyInfo
      });
    }
    if(type == "name") {
      let propertyInfo = {...this.state.propertyInfo}
      propertyInfo.name = val 
      this.setState({
        propertyInfo: propertyInfo
      });
    }
    if(type == "virtualPropertyList-name") {
      let virtualPropertyList = [...this.state.virtualPropertyList]
      virtualPropertyList[index].name = val
      this.setState({virtualPropertyList: virtualPropertyList})
    }
    if(type == "virtualPropertyList-desc") {
      let virtualPropertyList = [...this.state.virtualPropertyList]
      virtualPropertyList[index].desc = val
      this.setState({virtualPropertyList: virtualPropertyList})
    }
  }
  
  addVirtualProperty = (index) => {
    let item = {
      "id": "",
      "name": "",
      "desc": "",
    }
    
    let virtualPropertyList = [...this.state.virtualPropertyList]
    virtualPropertyList.push(item);
    this.setState({virtualPropertyList: virtualPropertyList})
  }

  removeVirtualProperty = (index) => {
    let virtualPropertyList = [...this.state.virtualPropertyList]
    virtualPropertyList.splice(index, 1);
    this.setState({virtualPropertyList: virtualPropertyList})
  }

  render () {
    let {
      dim_type,
      dim_name_cn,
      dim_name_en,
      data_standard,
      propertyTableId,
      propertyInfo,
      virtualPropertyList,
      filter,

      sholdCheckValidate,
    } = this.state

    // 来源表
    let tableList = this.props.tableList.map(i => {
      return {
        id: i.id,
        text: i.data_set_chinese + "(" + i.database_name + "." + i.table_name + ")"
      }
    })

    // 表字段
    let tableFields = this.state.tableFields.map(i => {
      return {
        id: i.field_name+'@@@'+i.id,
        id2: i.field_name,
        text: i.field_name
      }
    })

    let tableFields2 = this.state.tableFields.map(i => {
      return {
        id: i.field_name,
        text: i.field_name
      }
    })

    return (
      <div className="editorMain editorMain-dimen">
        <SdwDrawer
          title={this.props.title}
          visible={this.props.visible}
          onIconClick={this.props.onIconClick}
          onCancelClick={this.props.onCancelClick}
          onSureClick={this.handleSureClick}
        >
          <SdwDrawer.Body>
            <div className="form data-editor-win-wrap">
              <div className="formInput">
                <span className="content">
                  <div>
                    <Radio value="1" checked={dim_type == 1} onChange={val => this.setState({dim_type: val})}>普通维度</Radio>
                    <Radio value="2" checked={dim_type == 2} onChange={val => this.setState({dim_type: val})}>虚拟维度</Radio>
                  </div>
                </span>
              </div>

              <div className="formInput">
                <span className="label required">中文名</span>
                <div className="content">
                  <SdwInput
                    value={dim_name_cn}
                    validateFun={value => this.validateFun(value, '中文名')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({dim_name_cn: val})}
                    placeholder="请输入指标中文描述，最多15个字"
                  />
                </div>
              </div>

              <div className="formInput">
                <span className="label required">英文名</span>
                <div className="content">
                  <SdwInput
                    value={dim_name_en}
                    validateFun={value => this.validateFun(value, '英文名')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({dim_name_en: val})}
                    placeholder="由英文、下划线组成，英文请勿大写"
                  />
                </div>
              </div>

              <div className="formInput formInput-data_standard">
                <span className="label required">关联数据标准</span>
                <div className="content">
                  <Cascader
                    options={this.props.standardSelectsOptions}
                    value={data_standard}
                    onChange={val => this.setState({data_standard: val})} 
                    clearable={true}
                    filterable={true}
                  />
                </div>
              </div>

              {/** 普通维度的配置 */}
              {dim_type == 1 && 
                <div className="formInput">
                  <span className="label">来源表</span>
                  <div className="content">
                    <SdwSelect
                      readOnly={false}
                      value={propertyTableId}
                      sholdCheckValidate={sholdCheckValidate}
                      onChange={val => {this.getTablecfgFields(val)}}
                      data={tableList}
                    />
                  </div>
                </div>
              }

              {dim_type == 1 && 
                <div className="formInput formInput-propertyInfo">
                  <span className="label">属性配置</span>
                  <div className="content">
                    <span className="editorTwo-input left">
                      <SdwSelect
                        width={'100%'}
                        value={propertyInfo.selectVal}
                        sholdCheckValidate={sholdCheckValidate}
                        onChange={val => this.propertyInfoUpData(val, "selectVal") }
                        data={tableFields}
                      />
                    </span>

                    <span className="editorTwo-input right right-dimen-propertyInfo">
                      <SdwSelect
                        width={'100%'}
                        value={propertyInfo.name}
                        sholdCheckValidate={sholdCheckValidate}
                        onChange={val => this.propertyInfoUpData(val, "name") }
                        data={tableFields2}
                      />
                    </span>
                  </div>
                </div>
              }

              {dim_type == 2 && 
                <div className="formInput editorTwo editorDimen">
                  <span className="label">属性配置</span>
                  <div className="content">
                    {virtualPropertyList.map((row, k) => {
                      return (
                        <div className="contentOne">
                          <span className="editorTwo-input left">
                            <SdwInput2
                              value={virtualPropertyList[k].name} 
                              sholdCheckValidate={sholdCheckValidate}
                              onChange={val => this.propertyInfoUpData(val, "virtualPropertyList-name", k) }
                              placeholder="请输入维度ID, department"
                            />
                          </span>
    
                          <span className="editorTwo-input left">
                            <SdwInput2
                              value={virtualPropertyList[k].desc}
                              sholdCheckValidate={sholdCheckValidate}
                              onChange={val => this.propertyInfoUpData(val, "virtualPropertyList-desc", k) }
                              placeholder="请输入维度描述, 如：部门"
                            />
                          </span>
    
                          <i onClick={() => this.addVirtualProperty(k)} className="el-icon-plus"></i>
                          {k > 0 && (<i onClick={() => this.removeVirtualProperty(k)} className="el-icon-minus"></i>)}
                        </div>
                      )
                    })}
                  </div>
                </div>
              }

              <div className="formInput">
                <span className="label">条件配置</span>
                <div className="content">
                  <SdwInput
                    type='textarea'
                    value={filter}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({filter: val})}
                    placeholder="请输入语法"
                  />
                </div>
              </div>

            </div>
          </SdwDrawer.Body>
        </SdwDrawer>
      </div>
    )
  }
}

export default EditData
