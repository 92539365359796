import { Message } from 'element-react'

import './index.css'

function SdwMessage(record) {
  if (typeof record === 'object') {
    return Message({
      ...record,
      customClass: `custom-${record.type}`
    })
  }
}

SdwMessage.success =  message => {
  let obj = typeof message === 'string' ? { message: message } : message
  Message({
    ...obj,
    type: 'success',
    customClass: 'custom-success'
  })
}

SdwMessage.error =  message => {
  let obj = typeof message === 'string' ? { message: message } : message
  Message({
    ...obj,
    type: 'error',
    customClass: 'custom-error'
  })
}

SdwMessage.warning =  message => {
  let obj = typeof message === 'string' ? { message: message } : message
  Message({
    ...obj,
    type: 'warning',
    customClass: 'custom-warning'
  })
}

SdwMessage.info =  message => {
  let obj = typeof message === 'string' ? { message: message } : message
  Message({
    ...obj,
    type: 'info',
    customClass: 'custom-info'
  })
}

export default SdwMessage
