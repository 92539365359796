import { init } from '@rematch/core'

const models = {}

// 获取models下所有的xxxxxModel.js
const modelFiles = require.context('./redux', false, /Model\.js$/)
modelFiles.keys().forEach(key => {
  const modelName = key.slice('./'.length, -'Model.js'.length)
  models[modelName] = modelFiles(key).default
})

const store = init({
  models,
})

export default store
