import _ from 'lodash'
import switchRoutes from './switchRoutes'
import Atom from '@pages/MachFactory/Atom'
import Derived from '@pages/MachFactory/Derived'
import Dimension from '@pages/MachFactory/Dimension'
import Modifier from '@pages/MachFactory/Modifier'
import Application from '@pages/MachFactory/Application'
import ApplicationDetail from '@pages/MachFactory/Application/ApplicationDetail'
import Api from '@pages/MachFactory/Api'
import ApiDetail from '@pages/MachFactory/Api/ApiDetail'
import indexDetail from '@pages/MachFactory/Api/indexDetail'
import DerivedDetail from '@pages/MachFactory/Derived/Detail'
import AuditInfoPage from '@pages/common/auditInfoPage'

export const routerPath = '/admin/factory'

export const bar =   {
  rootUrl: routerPath,
  activeClassName: 'current',
  className: 'sdw-nav-item',
  name: '指标加工厂',
  children: [
    {
      icon: '',
      title: '原子指标',
      path: `${routerPath}/atom`,
    },
    {
      icon: '',
      title: '派生指标',
      path: `${routerPath}/derived`,
    },
    {
      icon: '',
      title: '维度管理',
      path: `${routerPath}/dimension`,
    },
    {
      icon: '',
      title: '修饰词管理',
      path: `${routerPath}/modifier`,
    },
    // {
    //   icon: '',
    //   title: '应用管理',
    //   path: `${routerPath}/application`,
    // },
    // {
    //   icon: '',
    //   title: '配置管理',
    //   path: `/html/dms/departmentList`,
    //   gotoPathState: 1,
    // },
    {
      icon: '',
      title: '应用表管理',
      path: `${routerPath}/application`
    },
    {
      icon: '',
      title: '指标API',
      path: `${routerPath}/api`
    }
  ],
  order: 2
}

const pages = {
  atomData: {
    icon: '',
    title: '原子指标',
    path: `${routerPath}/atom`,
    component: Atom,
  },
  atomDataDetail: {
    icon: '',
    title: '',
    path: `${routerPath}/atomData/detail/:id`,
    component: Atom,
  },
  derived: {
    icon: '',
    title: '派生指标',
    path: `${routerPath}/derived`,
    component: Derived,
  },
  derivedDetail: {
    icon: '',
    title: '',
    path: `${routerPath}/derived-detail/:id`,
    component: DerivedDetail,
  },
  dimensionManage: {
    icon: '',
    title: '维度管理',
    path: `${routerPath}/dimension`,
    component: Dimension,
  },
  dimensionDetail: {
    icon: '',
    title: '',
    path: `${routerPath}/dimension/detail/:id`,
    component: Dimension,
  },
  modifierManage: {
    icon: '',
    title: '修饰词管理',
    path: `${routerPath}/modifier`,
    component: Modifier,
  },
  modifierDetail: {
    icon: '',
    title: '',
    path: `${routerPath}/modifier/detail/:id`,
    component: Modifier,
  },
  application: {
    icon: '',
    title: '应用表管理',
    path: `${routerPath}/application`,
    component: Application,
  },
  applicationDetail: {
    icon: '',
    title: '新增应用表',
    path: `${routerPath}/applicationDetail`,
    component: ApplicationDetail,
  },
  api: {
    icon: '',
    title: '指标API',
    path: `${routerPath}/api`,
    component: Api,
  },
  apiDetail: {
    icon: '',
    title: '指标API编辑',
    //path: `${routerPath}/apiDetail`,
    path: `${routerPath}/apiDetail/:id`,
    component: ApiDetail,
  },
  indexDetail: {
    icon: '',
    title: '指标配置',
    path: `${routerPath}/indexDetail`,
    component: indexDetail,
  },
  AuditInfoPage: {
    icon: '',
    title: '审计详情界面',
    path: `${routerPath}/auditInfoPage`,
    component: AuditInfoPage,
  },
}

export const menus = [

]

export const Router = switchRoutes({ routes: _.values(pages) })
