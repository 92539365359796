import { request } from '../utils/request'

export function getUserTraceItem (params) {
  return request({ url: '/usertrace/userTrace/item', method: 'get', params: params })
}

export function relationAccount (params) {
  return request({ url: '/usertrace/userTrace/relationAccount', method: 'get', params: params })
}

export function historyOrder (params) {
  return request({ url: '/usertrace/userTrace/historyOrder', method: 'get', params: params })
}

export function getUserLabelHbase (params) {
  return request({ url: '/usertrace/userTrace/getUserLabelHbase', method: 'get', params: params })
}
