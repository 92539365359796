// @file 审计组件
import React, { useState, useEffect, useMemo } from "react"
import Empty from "@common/Empty"
import Tabs from "@common/Tabs"
import Button from "@common/Button"
import Message from "@common/Message"
import '../style/auditInfo.css'
import { getMessage, applyApproval, getProcessApproval } from '@services/auditInfoPage'
import { commonApi } from "@utils/request"
import _ from "lodash"
import { formatJsonParse } from "@utils/formatJson"
import { hasPriv } from "@components/FrontendAuth/AuthMap"
import Popover from "@common/Popover"
import {
  ALL, PENDING, STATE_MAP, MSG_TYPE_MAP
} from '@pages/common/index'
import Loadding from "@common/Loadding"

export default function AuditInfo (props) {

  const [showDialog, setShowDialog] = useState(false)
  const [messageArr, setMessageArr] = useState([]);
  const [processApproval, setProcessApproval] = useState([]);
  const [approvalTotal, setApprovalTotal] = useState(0);
  const [curStatus, setCurStatus] = useState(ALL);
  const [isLoading, setIsLoading] = useState(false);
  const [isImgEnter, setIsImgEnter] = useState(false);
  const [isImgEnterHelp, setIsImgEnterHelp] = useState(false);

  useEffect(() => {
    document.addEventListener('click', docClick)
    return () => {
      document.removeEventListener('click', docClick)
    }
  }, [])

  // 初始化
  useEffect(() => {
    onInit({})
  }, [])

  // 定时刷新: 1分钟刷新一次
  useEffect(() => {
    let timer = setInterval(() => {
      onInit({})
    }, 60 * 1000)

    return () => {
      clearInterval(timer)
    };
  }, []);

  function onInit({
    search = {}
  }) {
    setIsLoading(true)
    Promise.all([commonApi(getMessage, {
      limit: 10,
      page: 1,
      search: JSON.stringify({})
    }), commonApi(getProcessApproval, {
      limit: 10,
      page: 1,
      search: JSON.stringify(search)
    })]).then(([r1 = {}, r2 = {}]) => {
      let arr = _.get(r1, 'items')
      arr = _.isArray(arr) ? arr : []
      setMessageArr(arr)

      let arr2 = _.get(r2, 'items')
      arr2 = _.isArray(arr2) ? arr2 : []
      setProcessApproval(arr2)
      let total = _.get(r2, 'total')
      setApprovalTotal(total)
    }).catch(err => Message.error(err))
    .finally(() => setIsLoading(false))
  }

  function docClick() {
    setShowDialog(false)
  }

  function handleChange(item) {
    const curStatus = _.get(item, 'path') || ALL
    setCurStatus(curStatus)
    // console.log('curStatus: ', curStatus);

    if (curStatus === ALL) {
      setIsLoading(true)
      commonApi(getMessage, {
        limit: 10,
        page: 1,
        search: JSON.stringify({})
      }).then(r1 => {
        let arr = _.get(r1, 'items')
        arr = _.isArray(arr) ? arr : []
        setMessageArr(arr)
      }).catch(err => Message.error(err))
      .finally(() => setIsLoading(false))
    } else {

      setIsLoading(true)
      commonApi(getProcessApproval, {
        limit: 10,
        page: 1,
        search: JSON.stringify({})
      }).then(r2 => {
        let arr2 = _.get(r2, 'items')
        arr2 = _.isArray(arr2) ? arr2 : []
        setProcessApproval(arr2)
        let total = _.get(r2, 'total')
        setApprovalTotal(total)
      }).catch(err => Message.error(err))
      .finally(() => setIsLoading(false))
    }
  }

  function jumpToDetail() {
    props.history.push('/admin/factory/auditInfoPage')
    docClick()
  }

  function onapplyApproval(params) {
    commonApi(applyApproval, params).then(data => {
      Message.success('审批成功')
      onInit({})
    }).catch(err => Message.error('审批失败 ' + err))
  }

  function onShowDialog() {
    setShowDialog(r => !r)
    onInit({})
  }

  const curMessageList = useMemo(() => {
    return curStatus === ALL ? messageArr : processApproval
  }, [curStatus, messageArr, processApproval]);


  return (
    <div className="audit_info__wrap" onClick={e => e.stopPropagation()}>

      <div className="help" onClick={()=>{
        window.open("https://doc.weixin.qq.com/doc/w3_AFwAxgbdAFwzNzdDMPaSxu2QJeJVA?scode=AJEAIQdfAAoBbEdbnqAFwAxgbdAFw", "_blank")
      }} onMouseEnter={() => {
        setIsImgEnterHelp(true)
      }} onMouseLeave={() => {
        setIsImgEnterHelp(false)
      }}>

        <div
          style={{
            left: `28px`,
            top: `99px`
          }}
          className={`${isImgEnterHelp ? 'sdw-popover__wrap_top' : 'sdw-popover__wrap_top_hidden'}`}
        >
          <span className={`${isImgEnterHelp ? '' : 'sdw-popover__wrap_top_hidden'}`}>帮助中心</span>
        </div>
        {/*<Popover tip="帮助中心" position={{x:(window.innerWidth - 340), y:115}} direction="top">*/}
          <div className="audit_info__wrap_tip_div">
            <div className={`info_img_help ${isImgEnterHelp ? 'is_img_enter_help' : ''}`}>
            </div>
          </div>
      </div>

      <div className="user-email" onClick={onShowDialog} onMouseEnter={() => {
        setIsImgEnter(true)
      }} onMouseLeave={() => {
        setIsImgEnter(false)
      }}>
        <div
          style={{
            left: `28px`,
            top: `99px`
          }}
          className={`${isImgEnter && !showDialog ? 'sdw-popover__wrap_top' : 'sdw-popover__wrap_top_hidden'}`}
        >
          <span className={`${isImgEnter && !showDialog ? '' : 'sdw-popover__wrap_top_hidden'}`}>消息中心</span>
        </div>
        <div className="audit_info__wrap_tip_div">
          <div className={`info_img ${isImgEnter ? 'is_img_enter' : ''}`}>
            {
              hasPriv('applyApproval') &&
              processApproval.length > 0 &&
              <span className="show_red_hot" />
            }
          </div>
        </div>
      </div>
      {
        showDialog &&
          <div className="info__dialog">
            <Loadding loadding={isLoading}>
            {
              (curStatus === ALL && (!_.isArray(messageArr) || messageArr.length === 0)) ?
              <Empty text='暂无消息'/> :
              <div>
                <div className="header">
                  <Tabs
                    menu={
                      [
                        {
                          title: '全部消息',
                          path: ALL,
                          component: null
                        },
                        ...hasPriv('applyApproval') ? [{
                          title: `待处理(${approvalTotal})`,
                          path: PENDING,
                          component: null
                        }] : []
                      ]
                    }
                    onChange={handleChange}
                  />
                </div>

                <div className="body" style={{
                  maxHeight: window.innerHeight - 200
                }}>
                  {
                    curMessageList.length > 0 ?
                    curMessageList.map((item, index) => {
                      const messageContext = formatJsonParse(item.message_context) || {}
                      // console.log('messageContext: ', messageContext);
                      const comment = _.get(messageContext, 'comment') || '-'
                      const isReRun = item.msg_type == '3'

                      return <div key={index} className="inner-box">
                        <div className="box_wrap">
                          {/* 1:指标审批   2:指标重跑  */}
                          <div className="tag">{MSG_TYPE_MAP[item.msg_type] || '-'}</div>
                          <div className="title" title={item.message_title || ''}>{item.message_title || '-'}</div>
                        </div>
                        {
                          isReRun &&
                          <div className="box_wrap">
                            <div className="label">{'指标ID：'}</div>
                            <div className="label_text">{messageContext.id || '-'}</div>
                          </div>
                        }
                        <div className="box_wrap">
                          <div className="label">{isReRun ? '提交人：' : '修改人：'}</div>
                          <div className="label_text">{item.send_user || '-'}</div>
                        </div>
                        {
                          isReRun &&
                          <>
                            <div className="box_wrap">
                              <div className="label">{'重跑时间：'}</div>
                              <div className="label_text">{`${messageContext.start_time} - ${messageContext.end_time}` || '-'}</div>
                            </div>
                            <div className="box_wrap">
                              <div className="label">{'提交时间：'}</div>
                              <div className="label_text">{item.update_time || '-'}</div>
                            </div>
                          </>
                        }
                        {
                          !isReRun &&
                          <div className="box_wrap">
                            <div className="label">{'修改原因：'}</div>
                            <div className="label_text">{comment}</div>
                          </div>
                        }
                        {
                          item.task_status != '0' &&
                          <>
                            <div className="box_wrap">
                              <div className="label">{'审批人:'}</div>
                              <div className="label_text">{item.approval_user || '-'}</div>
                            </div>
                            <div className="box_wrap">
                              <div className="label">{'审批时间:'}</div>
                              <div className="label_text">{item.update_time || '-'}</div>
                            </div>
                          </>
                        }
                        <div className="box_wrap">
                          <div className="label">{'审批情况:'}</div>
                          {/* 0: 审批中   1：通过   2：驳回 */}
                          <div className={`label_text pass_${item.task_status}`}>{STATE_MAP[item.task_status] || '-'}</div>
                        </div>
                        <div className="box_wrap">
                          <div className="label time">{item.send_time || '-'}</div>
                        </div>
                        {
                          (
                            item.task_status == '0' &&
                            hasPriv('applyApproval')
                          ) &&
                          <>
                            <span>
                              <Button text='驳回' type='cancel' style={{
                                width: 124,
                                textAlign: 'center'
                              }} onClick={() => onapplyApproval({
                                approvalId: messageContext.approvalId,
                                task_status: 2,
                                comment: ''
                              })} />
                            </span>
                            <span style={{ marginLeft: 8 }}>
                              <Button text='通过' type='submit' style={{
                                width: 124,
                                textAlign: 'center'
                              }} onClick={() => onapplyApproval({
                                approvalId: messageContext.approvalId,
                                task_status: 1,
                                comment: ''
                              })} />
                            </span>
                          </>
                        }
                      </div>
                    }) :
                    <Empty />
                  }
                </div>

                <div className="footer" onClick={jumpToDetail}>
                  <span>查看全部消息</span>
                  <span className="iconfont icon-arrow-right" />
                </div>
              </div>
            }
          </Loadding>
        </div>
      }
    </div>
  )
}
