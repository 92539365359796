import { useState, useEffect } from "react"
import { getStandardSelects } from './api'
import { commonApi } from "@utils/request"
import Message from '@common/Message'

export default function UseGetTopic(props) {

  const [topicAndBusiness, setTopicAndBusiness] = useState({})
  
  useEffect(() => {
    commonApi(getStandardSelects, {}).then(data => {
      setTopicAndBusiness(data)
    }).catch(err => Message.error(err))
  }, [])

  return topicAndBusiness
}