import axios from 'axios'
import _ from 'lodash'
import { requestOptions, requestOptionsYZF } from '../config/config'

export const baseReqInst = axios.create({
  /**
   * 设置所有请求的前缀
   */
  baseURL: requestOptions.basename,
  withCredentials: true,
})

export const baseReqInstYZF = axios.create({
  /**
   * 设置云智服相关请求的前缀
   */
  baseURL: requestOptionsYZF.basename,
  withCredentials: true,
})

/**
 * 请求接口，项目内的接口都使用request，作统一处理。
 * 请避免直接使用axios或者使用其他请求方式
 * @param options
 */
export function request(options) {
  options.config = options.config || {}
  return baseReqInst.request(options)
}

/**
 * 云智服接口
 */
export function requestYZF(options) {
  options.config = options.config || {}
  return baseReqInstYZF.request(options)
}

/**
 * 写一个统一处理接口问题的api
 * @param {接口请求} api Function
 * @param {请求参数} params Object
 * @returns
 *  1、如果请求成功：直接取出接口对应的data值
 *  2、如果请求失败：返回msg值
 *  3、如果接口异常：返回err值
 */
export function commonApi(api, params) {
  if (typeof api !== 'function') return

  return new Promise((resolve, reject) => {
    Promise.resolve(api(params)).then(res => {
      const code = _.get(res, 'data.code')
      const msg = _.get(res, 'data.msg')
      if (code === 0) {
        let data = _.get(res, 'data.data') || {}
        resolve(data)
      } else {
        reject(msg)
      }
    }).catch(err => reject('请求异常' + err))
  })
}

// 用于文件下载功能
export function fileDownload(url = '') {
  window.location.href = `${window.location.hostname === 'localhost' ? 'http://dev.dsp.cm.com' : window.location.origin}${url}`
}

// 打开新窗口
export function openNewWin(url = '') {
  let winObj = window.open(url)
  winObj.opener = null
}
