// 表基本信息
import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

class Card extends React.PureComponent {

  render () {
    let { columns, data, headerTitle, lableWidth } = this.props

    return (
      <div className="sdw-card__wrap">
        <div className='card-header'>{ headerTitle }</div>

        {
          Array.isArray(columns) &&
          columns.map(item => (
            <div key={item.dataIndex || item.title} className="sdw-card__lable-wrap">
              <span className="sdw-card__lable" style={{ width: lableWidth }}>{item.title}:</span>
              {
                item.render ?
                <>
                  {item.render(data)}
                </> :
                <span className="sdw-card__lable-text" style={{
                  width: `calc(100% - ${lableWidth+30}px)`
                }}>{data[item.dataIndex] || '-'}</span>
              }
            </div>
          ))
        }
    
      </div>
    )
  }
}

Card.propTypes = {
  headerTitle: PropTypes.string, // 卡片头部title
  columns: PropTypes.array, // 配置展示的行信息
  data: PropTypes.object, // 需要展示的对象
  lableWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 默认值是 78px
}

export default Card