import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwTabs from '@common/Tabs'
import SecdRecord from './SendRecord'
import MonitorConfig from './MonitorConfig'
import { hasPriv } from '@components/FrontendAuth/AuthMap'

const menu = [
  {
    title: '推送记录',
    path: '/secdrecord',
    component: SecdRecord,
    priv: 'getPushLogList'
  }, {
    title: '监控配置',
    path: '/monitorconfig',
    component: MonitorConfig,
    priv: 'getMonitorSettingList'
  }
]

export default function MonitorManagement(props) {

  const hasPrivMenu = menu.filter(i => hasPriv(i.priv))

  return (
    <>
      <SdwBreadCrumbs
        history={props.history}
        data={[
          {
            title: '智慧运营台',
            path: '/admin/monitor/monitorManagement'
          }, {
            title: '分析监控眼'
          }
        ]}
      />

      <div style={{ padding: '0 20px', background: '#fff' }}>
        <SdwTabs 
          menu={hasPrivMenu}
        />
      </div>
    </>
  )
}