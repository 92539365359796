import React from 'react'
import './index.css'

const FormInstance = React.createContext('');

class Form extends React.Component {
  state = {
    fields: [] //存放每个formItem的实例
  }
  addFormItemInstance = (instance) => {
    if (instance.props.prop) {
      this.state.fields.push(instance)
    }
  }
  removeField(field) {
    if (field.props.prop) {
      this.state.fields.splice(this.state.fields.indexOf(field), 1);
    }
  }
  validate = (cb) => {
    // 对每一个formItem的项的校验
    let flag = true
    this.state.fields.forEach(field => {
       let res = field.validate()
       if(res) flag = false
    })
    typeof cb === 'function' && cb( flag )
  }
  render() {
    let {labelPosition} = this.props
    return (
      <FormInstance.Provider value={this}>
        <div className={`sdw-form ${labelPosition && `sdw-form-label-${labelPosition}`}`} >
          {this.props.children}
        </div>
      </FormInstance.Provider>
    )
  }
}

//FormItem的校验控制自己那一项的校验状态
class FormItem extends React.Component {
  static contextType = FormInstance;
  state = {
    isError: '',
    errMsg: false
  }
  componentDidMount() {
    this.timer = null
    let form = this.context
    form.addFormItemInstance(this)
  }
  componentWillUnmount() {
    let form = this.context
    form.removeField(this);
  }
  validate = () => {
    let form = this.context
    let {model,rules} = form.props
    let prop = this.props.prop

    let rule = rules[prop]
    let value = model[prop]

    let isError = false
    let errMsg = ''

    if (!rule) return false

    // 在这拿到值和规则 进行校验
    for (let index = 0; index < rule.length; index++) {
      const element = rule[index];
      if(element.func && !element.func(value)) {
        errMsg = element.message
        isError = true
        break
      }
    }

    this.setState({
      isError,
      errMsg
    })
    return isError
  }
  onFieldBlur = () => {
    // this.validate()
    setTimeout(this.validate, 250)
  }
  onFieldChange = () => {
    if(this.timer) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.validate()
      }, 250)
    }else{
      this.timer = setTimeout(this.validate, 250)
    }
  }
  render() {
    let { isError, errMsg } = this.state
    let { label, required,className } = this.props
    let labelWidth = this.context.props && this.context.props.labelWidth
    if (this.props.labelWidth) {
      labelWidth = this.props.labelWidth
    }
    return (
      <div 
        className={`sdw-form-item ${isError? 'is-error' : ''} ${required? 'is-required' : ''} ${className? className : ''}`} 
        onBlur={this.onFieldBlur}
        onChange={this.onFieldChange} >
          

        <label className="sdw-form-item__label" style={{ width: labelWidth }}>{label}</label>

        <div className="sdw-form-item__content" style={{ marginLeft: labelWidth }}>

          {this.props.children}

          {isError && <div className="sdw-form-item__error">{errMsg}</div>}
        </div>

      </div>
    )
  }
}
Form.FormItem = FormItem

export default Form