import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwTable from '@common/Table'
import SdwEmpty from '@common/Empty'
import SdwButton from '@common/Button'
import { Tabs, DateRangePicker } from 'element-react'
import { formatTime } from '@utils/formatTime'
import { getTargetResults } from '@services/application'
import SdwMessage from '@common/Message'
import { TOP_MAP, TOP_MAP_TEXT } from '../common'
import SetTopWin from '../SetTopWin'
import SelectedAttributes from './SelectedAttributes'

const CUR_TARGET_INDEX = '1'

class TabPage extends React.PureComponent {

  state = {
    datePicker: '',
    previewData: [], // 预览数据
    previewTableHeader: [], // 预览数据表头
    curActiveName: CUR_TARGET_INDEX,
    setTopVisible: false,
    curClickTargetData: {}, // 当前设置点击的对象，设置指标 or 删除的项
  }

  componentDidMount () {
    this.initDatePicker()
  }

  // 初始化时间选择当前一天
  initDatePicker = () => {
    let end = new Date(`${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`)
    let start = new Date()
    start.setTime(end.getTime() - 3600 * 1000 * 24 * 1)

    this.setState({datePicker: [start, end]})
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    // 预览数据，时间搜索
    if (
      prevState.datePicker.length !== this.state.datePicker.length ||
      _.differenceWith(prevState.datePicker, this.state.datePicker, _.isEqual).length
    ) {
      this.onSearchPreviewData()
    }
  }

  onSearchPreviewData = () => {
    let { datePicker } = this.state
    let { currentData } = this.props

    let timeRange = datePicker.map(t => formatTime(+t, 0))
    // console.log('--------timeRange: ', timeRange) //  ["2021-02-10 00:00:00", "2021-02-24 00:00:00"]

    getTargetResults({
      app_table: currentData.table_name,
      run_cycle: currentData.run_cycle,
      start_time: timeRange[0],
      end_time: timeRange[1]
    }).then(res => {
      if (res && res.data && res.data.code === 0) {
        let record = res.data.data || {}
        let list = record.data || []
        let header = record.header || []

        this.setState({
          previewData: list,
          previewTableHeader: header
        })
      } else {
         this.setState({
          previewDataMsg: res && res.data && res.data.msg || '暂无数据'
         })
      }
    }, () => SdwMessage.error("接口异常"))
  }

  getcolumns = () => {
    let isCurTarget = this.state.curActiveName === CUR_TARGET_INDEX
    return [
      ...isCurTarget ? [] : [
        {
          title: "字段ID",
          dataIndex: "db_id",
          width: 120
        }
      ],
      {
        title: "指标ID",
        dataIndex: "target_id",
        width: 120
      },
      {
        title: "中文名",
        dataIndex: "name_cn",
        width: 120
      },
      {
        title: "英文名",
        showTip: true,
        dataIndex: "name",
        width: 120
      },
      {
        title: "时间周期",
        dataIndex: "time",
        width: 120
      },
      {
        title: "维度",
        dataIndex: "dimension",
        width: 120
      },
      {
        title: "字段类型",
        width: 120,
        // dataIndex: "target_type",
        render: (data, record) => {
          return (
            <span>{TOP_MAP_TEXT[data.target_type]}</span>
          )
        }
      },
      ...!isCurTarget ? [] : [
        {
          title: "操作",
          width: 80,
          fiexd: true,
          render: (data, record) => {
            return (
              <>
                <span
                  style={{'color': '#517df3', 'cursor': 'pointer'}}
                  onClick={ () => this.handleDeleteButtom(data) }
                >删除</span>
                <span
                  style={{'color': '#517df3', 'cursor': 'pointer', marginLeft: 8}}
                  onClick={() => this.handleSetButtom(data)}
                >设置</span>
              </>
            )
          },
        }
      ]
    ]
  }

  handleSetButtom = data => {
    this.setState({
      curClickTargetData: data
    }, () => {
      this.changeSetTopVisible(true)
    })
  }

  handleDeleteButtom = data => {
    let { curShowSelectedTarget } = this.props
    let arr = _.cloneDeep(curShowSelectedTarget)

    let index = arr.findIndex(i => i.time === data.time && i.dimension === data.dimension)
    arr.splice(index, 1)

    this.props.updateCurShowSelectedTarget(arr)

    // 还原勾选
    this.props.onReWriteData(arr)
  }

  updateTargetValue = (curTarget, params) => {
    let { curShowSelectedTarget } = this.props
    let arr = _.cloneDeep(curShowSelectedTarget)

    arr.forEach(item => {
      if (curTarget.id === item.id && item.time === curTarget.time && item.dimension === curTarget.dimension) {
        item.target_type = params.selectInputValue
        item.target_top = params.selectInputValue === TOP_MAP.top ? params.inputValue : null
      }
    })

    this.props.updateCurShowSelectedTarget(arr)
  }

  changeSetTopVisible = flag => {
    this.setState({
      setTopVisible: flag
    })
  }

  onCancel = () => {
    let { oriAllSelectedTarget } = this.props

    this.props.updateCurShowSelectedTarget(oriAllSelectedTarget)
    this.props.onReWriteData(oriAllSelectedTarget)
  }

  render () {

    let {
      datePicker,
      curActiveName,
      previewData,
      previewTableHeader,
      setTopVisible,
      curClickTargetData,
    } = this.state

    let {
      curShowSelectedTarget,
      allSelectedIndicator,
      oriAllSelectedTarget
    } = this.props

    let previewColumns = previewTableHeader.map(item => {
      return {
        title: item,
        dataIndex: item,
        showTip: true,
        width: 150
      }
    })

    let isButtomDisabled = curShowSelectedTarget.length !== oriAllSelectedTarget.length ||
                           _.differenceWith(curShowSelectedTarget, oriAllSelectedTarget, _.isEqual).length

    return (
      <>
        <Tabs activeName="1" onTabClick={(tab) => this.setState({ curActiveName: tab.props.name })}>
          <Tabs.Pane label={`当前指标（${curShowSelectedTarget.length}）`} name={CUR_TARGET_INDEX}>
            <div style={{ padding: '10px' }}>
              {
                !!curShowSelectedTarget.length ?
                <SdwTable
                  tbodyMinHeight={0}
                  tdWhiteSpace={true}
                  columns={this.getcolumns()}
                  dataSource={curShowSelectedTarget}
                /> :
                <SdwEmpty
                  text="请从左侧选择指标"
                  icon="emptyLeft"
                />
              }
            </div>
          </Tabs.Pane>
          <Tabs.Pane label={`已选指标（${allSelectedIndicator.length}）`} name="2">
            <SelectedAttributes
              columns={this.getcolumns}
              dataSource={allSelectedIndicator}
            />
          </Tabs.Pane>
          <Tabs.Pane label="预览数据" name="3">
            <div style={{ padding: '10px' }}>

              <div className="sdw-application-detail__opration-wrap">
                <span className="sdw-monitor-eye__date-range-picker-wrap">
                  <DateRangePicker
                    value={datePicker}
                    isReadOnly={true}
                    rangeSeparator=" 至 "
                    placeholder="选择日期范围"
                    format="yyyy-MM-dd"
                    align="right"
                    disabledDate={time=>time.getTime() > Date.now()}
                    onChange={date => {
                      this.setState({ datePicker: date })
                    }}
                  />
                </span>
                <span className="sdw-monitor-eye__date-range-picker-wrap">
                  <SdwButton
                    text="刷新"
                    iconClass="iconfont icon-refresh"
                    onClick={this.onSearchPreviewData}
                  />
                </span>
                <span className="right-wrap">{
                  previewData.length <= 200 ? `共${previewData.length}条` : `共${previewData.length}条，仅显示200条`
                }</span>
              </div>
              {
                !!previewData.length ?
                <SdwTable
                  tbodyHeight={document.body.offsetHeight - 540}
                  tdWhiteSpace={true}
                  columns={previewColumns}
                  dataSource={previewData}
                /> :
                <SdwEmpty
                  text={this.state.previewDataMsg}
                />
              }
            </div>
          </Tabs.Pane>
        </Tabs>

        {
          curActiveName === CUR_TARGET_INDEX &&
          <div className="sdw-application-detail__opration-buttom-wrap">
            <div className="sdw-application-detail__opration">
              <button
                disabled={!isButtomDisabled}
                onClick={this.onCancel}
              >取消</button>
              <button
                className="sure-button"
                disabled={!isButtomDisabled}
                onClick={this.props.onSubmit}
              >保存</button>
            </div>
          </div>
        }

        <SetTopWin
          setTopVisible={setTopVisible}
          record={curClickTargetData}
          updateTargetValue={this.updateTargetValue}
          changeSetTopVisible={this.changeSetTopVisible}
        />
      </>
    )
  }
}

const mapState = state => ({
  allSelectedIndicator: state.application.allSelectedIndicator,
  oriAllSelectedTarget: state.application.oriAllSelectedTarget,
  curShowSelectedTarget: state.application.curShowSelectedTarget,
})

const mapDispatch = dispatch => ({
  updateCurShowSelectedTarget: dispatch.application.updateCurShowSelectedTarget,
})

export default connect(mapState, mapDispatch)(TabPage)
