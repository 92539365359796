import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Button, DateRangePicker } from 'element-react'
import SdwTable from '@common/Table'
import SdwPagenation from '@common/Pagenation'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwBreadCrumbs from '@common/BreadCrumbs'
import SdwButton from '@common/Button'
import SdwDialog from '@common/Dialog'
import Switch from '@common/Switch'
import { getAlarmCfgList, getProblemTypeSelects, storeProblemType } from '@services/alertRules'
import { formatTime } from '@utils/formatTime'
import { MODULE_ID_OPTIONS,SEND_TYPE_OPTIONS } from "./EarlyWarningRules/common";

import './style/index.css'
import SdwMessage from '@common/Message/index'

// 自定义
const SELF = 'self'

class MonitorControl extends PureComponent {

  state = {
    module: '',
    send_type: '',
    datePicker: '',
    alarmRuleName: '',
    notifyPerson: '',
    ruleName: '',
    tableData: {},
    curPage: 1,
    curLimit: 10,
    showEditWin: false,
    problemTypeSelects: [],
    editData: {
      problemType: '',
      selfType: ''
    },
    currentData: {}
  }

  componentDidMount () {
    this.initDatePicker()
    this.initData()
  }

  componentDidUpdate (prevProps, prevState) {

    if (
      !_.isEqual(prevState.curPage, this.state.curPage) ||
      !_.isEqual(prevState.curLimit, this.state.curLimit) ||
      !_.isEqual(prevState.datePicker, this.state.datePicker)
    ) {
      this.getAlarmCfgList()
    }
  }

  initData = () => {
    getProblemTypeSelects().then(res => {
      const code = _.get(res, 'data.code')
      if (code === 0) {
        let data = _.get(res, 'data.data.problem') || []
        if (_.isArray(data)) {
          data = data.map(i => ({
            id: i.problem_name,
            text: i.problem_name
          }))
        }
        data.push({
          id: SELF,
          text: '自定义'
        })
        // console.log('xxx ', data);
        this.setState({
          problemTypeSelects: data
        })
      }
    })
  }

  // 初始化时间选择当前一天
  initDatePicker = () => {
    let end = new Date();
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7); // 默认近7天的数据

    this.setState({datePicker: [start, end]})
  }

  getAlarmCfgList = () => {
    let { curPage, curLimit, datePicker, alarmRuleName, notifyPerson, ruleName, module, send_type } = this.state
    let timeRange = datePicker.map(t => formatTime(+t, 0))

    let searchParams = JSON.stringify({
      ...datePicker ? {
        create_time: {
          value: timeRange,
          operator: 'range'
        }
      } : {},
      ...module ? {
        module: {
          value: module,
          operator: '='
        }
      } : {},
      ...send_type ? {
        send_type: {
          value: send_type,
          operator: '='
        }
      } : {},
      ...alarmRuleName ? {
        alarm_rule_name: {
          value: `%${alarmRuleName}%`,
          operator: 'like'
        }
      } : {},
      ...notifyPerson ? {
        deal_users: {
          value: `%${notifyPerson}%`,
          operator: 'like'
        }
      } : {},
      ...ruleName ? {
        title: {
          value: `%${ruleName}%`,
          operator: 'like'
        }
      } : {}
    })

    getAlarmCfgList({
      tablename: 't_alarm_article_new',
      page: curPage,
      limit: curLimit,
      search: searchParams
    })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          this.setState({
            tableData: res.data.data || {}
          })
        }
      })
  }

  getColumns = () => {
    return [
      {
        title: "预警时间",
        width: 200,
        dataIndex: "create_time"
      },
      {
        title: "规则名称",
        dataIndex: "alarm_rule_name"
      },
      {
        title: "所属模块",
        render: data => {
          let text = _.get(MODULE_ID_OPTIONS.filter(i => i.id === data.module), '[0].text')
          return (
            <span>{ text || '-' }</span>
          )
        }
      },
      {
        title: "推送方式",
        render: data => {
          let text = _.get(SEND_TYPE_OPTIONS.filter(i => i.id === data.send_type), '[0].text')
          return (
            <span>{ text || '-' }</span>
          )
        }
      },
      {
        title: "标签",
        dataIndex: "label"
      },
      {
        title: "关联任务ID",
        dataIndex: "task_id"
      },
      {
        title: "问题类型",
        dataIndex: "problem_type"
      },
      // {
      //   title: "通知人",
      //   // dataIndex: "deal_users",
      //   render: (data, record) => {
      //     return (
      //       // console.log(data.deal_users, typeof JSON.parse(data.deal_users))
      //       (_.isString(data.deal_users) && _.isArray(JSON.parse(data.deal_users))) ? JSON.parse(data.deal_users).map(i => <span>{i.decorate}</span>) : '-'
      //     )
      //   }
      // },
      // {
      //   title: "备注",
      //   dataIndex: "remark"
      // }
      {
        title: '操作',
        render: data => (
          <>
            <SdwButton
              text='编辑'
              onClick={() => this.onEditClick(data)}
            />
          </>
        )
      }
    ]
  }

  setShowEditWin = bool => {
    this.setState({
      showEditWin: bool
    })
  }

  onEditClick = data => {
    const { editData } = this.state
    // console.log('onEditClick: ', data);
    this.setState({
      currentData: data,
      editData: {
        ...editData,
        problemType: data.problem_type,
        is_recommend_case: data.is_recommend_case
      }
    })
    this.setShowEditWin(true)
  }

  onEditSureClick = () => {
    const { editData, currentData } = this.state
    let params = {
      problemType: editData.problemType
    }
    if (editData.problemType === SELF) params.problemType = editData.selfType
    // console.log('currentData: ', currentData);

    storeProblemType({
      id: currentData.id,
      field: {
        problem_type: params.problemType,
        is_recommend_case: editData.is_recommend_case || 0
      }
    }).then(res => {
      const code = _.get(res, 'data.code')
      const msg = _.get(res, 'data.msg')
      if (code === 0) {
        this.initData()
        this.getAlarmCfgList()
        this.setShowEditWin(false)
        SdwMessage.success(msg || '编辑成功')
      } else {
        SdwMessage.error(msg || '编辑失败')
      }
    }).catch(err => {
      SdwMessage.error('编辑失败 ' + err)
    })
  }

  updateEditData = (key, val = '') => {
    let cloneEditData = _.cloneDeep(this.state.editData)
    cloneEditData[key] = val
    this.setState({
      editData: cloneEditData
    })
  }

  render () {

    let {
      datePicker,
      alarmRuleName,
      notifyPerson,
      ruleName,
      tableData,
      module,
      send_type,
      showEditWin,
      problemTypeSelects,
      editData
    } = this.state

    return (
      <div className="sdw-monitor-quality-control-wrap">
        <SdwBreadCrumbs
          history={this.props.history}
          data={[
            {
              title: '智慧运营台',
              path: '/admin/monitor/monitorManagement'
            }, {
              title: '预警监控眼'
            }
          ]}
        >
          <Button
            style={{ position: 'absolute', right: 0, top: 11 }}
            type="text"
            onClick={() => {
              this.props.history.push('/admin/monitor/earlyWarningRules')
            }}
          >预警规则管理</Button>
        </SdwBreadCrumbs>

        <div className="page-mainbody">
          <div className="sdw-monitor-eye__wrap" style={{ marginBottom: '16px', lineHeight: '44px' }}>
            <span className="sdw-monitor-eye__date-range-picker-wrap">
              <DateRangePicker
                value={datePicker}
                isReadOnly={true}
                rangeSeparator=" 至 "
                placeholder="选择日期范围"
                format="yyyy-MM-dd"
                align="right"
                disabledDate={time=>time.getTime() > Date.now()}
                onChange={date => {
                  this.setState({ datePicker: date })
                }}
              />
              {/* <span className="sdw-monitor-eye__quality-conctrol__date-label">预警时间</span> */}
            </span>

            <span style={{ marginLeft: '8px', lineHeight: '48px' }}>
              <SdwInput
                placeholder="请输入预警规则"
                value={alarmRuleName}
                width={160}
                label="预警规则"
                onChange={val => this.setState({curPage: 1, alarmRuleName: val})}
                onBlur={this.getAlarmCfgList}
              />
            </span>

            {/* <span style={{ marginLeft: '8px', lineHeight: '48px' }}>
              <SdwInput
                value={notifyPerson}
                width={160}
                label="通知人"
                onChange={val => this.setState({notifyPerson: val})}
                onBlur={this.getAlarmCfgList}
                onEnterKeyDown={this.getAlarmCfgList}
              />
            </span> */}

            <span style={{ marginLeft: '8px', lineHeight: '48px' }}>
              <SdwSelect
                placeholder="请选择"
                value={module}
                width={150}
                label="所属模块"
                readOnly={false}
                data={MODULE_ID_OPTIONS}
                onChange={val => this.setState({curPage: 1, module: val}, this.getAlarmCfgList)}
              />
            </span>
            <span style={{ marginLeft: '8px', lineHeight: '48px' }}>
              <SdwSelect
                placeholder="请选择"
                value={send_type}
                width={150}
                label="推送方式"
                readOnly={false}
                data={SEND_TYPE_OPTIONS}
                onChange={val => this.setState({curPage: 1, send_type: val}, this.getAlarmCfgList)}
              />
            </span>
            <span style={{ marginLeft: '8px', lineHeight: '48px' }}>
              <SdwSelect
                placeholder="请选择"
                value={[]}
                width={150}
                label="创建任务"
                readOnly={false}
                data={[{
                  id:1,
                  text:"是"
                },{
                  id:0,
                  text:"否"
                }]}
                onChange={val => console.log(val)}
              />
            </span>
            {/* <span style={{ marginLeft: '8px', lineHeight: '48px' }}>
              <SdwInput
                value={ruleName}
                width={160}
                label="规则名称"
                onChange={val => this.setState({ruleName: val})}
                onBlur={this.getAlarmCfgList}
                onEnterKeyDown={this.getAlarmCfgList}
              />
            </span> */}
          </div>

          <SdwTable
            columns={ this.getColumns() }
            dataSource={ _.isArray(tableData.items) ? tableData.items : [] }
          />

          <div style={{ marginTop: '16px' }}>
            <SdwPagenation
              total={ tableData.total }
              currentPage={ tableData.current_page }
              pageCountList={[10, 15, 20, 40]}
              pageCount={ tableData.per_page }
              getClickPage={ page => {
                this.setState({
                  curPage: page
                })
              } }
              pageCountChange={ limit => {
                this.setState({
                  curLimit: limit
                })
              } }
            />
          </div>
        </div>

        {
          showEditWin &&
          <SdwDialog
            title='修改字段值'
            width={666}
            visible={showEditWin}
            changeVisible={this.setShowEditWin}
            onCancelClick={() => this.setShowEditWin(false)}
            onSureClick={this.onEditSureClick}
            sureBtnDisabled={
              editData.problemType === SELF ? !editData.selfType : false
            }
          >
            <div className='con-add-application-win__label'>
              <span className='label__title'>问题类型</span>
              <SdwSelect
                listWidth={465}
                placeholder='请选择问题类型'
                data={problemTypeSelects}
                value={editData.problemType}
                onChange={val => this.updateEditData('problemType', val)}
              />
            </div>
            {
              editData.problemType === SELF &&
              <div className='con-add-application-win__label'>
                <span className='label__title required'>自定义值</span>
                <SdwInput
                  placeholder='请输入描述'
                  value={editData.selfType}
                  onChange={val => this.updateEditData('selfType', val) }
                />
              </div>
            }
            <div className='con-add-application-win__label'>
              <span className='label__title'>是否推荐案例</span>
              <Switch
                value={editData.is_recommend_case || 0}
                changeValue={val => this.updateEditData('is_recommend_case', val)}
              />
            </div>
          </SdwDialog>
        }
      </div>
    )
  }
}

export default MonitorControl
