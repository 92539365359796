import React, { PureComponent } from 'react'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import { Mapping } from '../util'
import { getTablecfgFieldsAndStandardList } from '@services/Derived'

class AddIndicators extends PureComponent {
  state = {
    sourceFieldData: []
  }
  componentDidUpdate (prevProps, prevState) {
    let id = this.props.item.group_by_field.id
    if (prevProps.item.group_by_field.id !== id) {
      this.onGetTablecfgFieldsAndStandardList(id)
    }
  }
  componentDidMount () {
    let id = this.props.item.group_by_field.id
    this.onGetTablecfgFieldsAndStandardList(id)
  }
  // 来源字段和范围字段
  onGetTablecfgFieldsAndStandardList = id => {
    getTablecfgFieldsAndStandardList({ id })
      .then(res => {
        if (res && res.data && res.data.code === 0) {
          let record = res.data.data || {}
          let field = Array.isArray(record.field) ? record.field : []
          let standardMap = record.standardMap || {}
          let curArr = field.map(i => {
            let ID = i.id
            let text = !!standardMap[ID] ? `${i.field_name}（${standardMap[ID].field_name_ch}）` : i.field_name
            return {
              originID: ID,
              id: text,
              text: text
            }
          })
          this.setState({
            sourceFieldData: curArr
          })
        }
      })
  }
  render() {
    const { item, index, rankDimensionData, rangeRankData, runCycle, timeCycle } = this.props
    const { complex_script, store_dimension, group_by_field, time_schedule } = item
    const rankDimention = (store_dimension.length > 0 && store_dimension[0].id[0]) || ''
    const { id: group_by_field_Id, key: group_by_field_key, value: group_by_field_value } = group_by_field || {}
    // function
    const { showOrhideContent, setComplexScript, setRankDimension, setRankRange, setSourceOrRangeField, configDataChange, addVirtualProperty2, removeVirtualProperty2, enableStateChange } = this.props
    return (
      <div>
        <div className="sdw-collapse-listitem">
          <div className="sdw-collapse-listitem-title" onClick={() => showOrhideContent(item, index, 'addData')}>
            <div className='sdw-collapse-listitem-title-text' style={{'color': item.enable ? '#265CF0' : '' }}>衍生指标-{Mapping[item.metric_type]}{item.complex_script === 'asc' ? '(升序)' : item.complex_script === 'desc' ? '(降序)' : ''}{!!item.id ? `（${item.id}）`: ''}</div>
            <div><i className={`sdw-collapse__icon sdw-collapse__drop-down ${item.isShow ? 'sdw-collapse__drop-up' : ''}`}></i></div>
          </div>
          <div className="sdw-collapse-listitem-content" style={{ 'display': item.isShow ? 'block' : 'none' }}>
            <div className="form data-editor-win-wrap">
              <div className="formInput">
                <span className="label required">排名类型</span>
                <div className="content">
                  <SdwSelect
                    clearable={false}
                    isMultipleChoice={false}
                    placeholder={'请选择'}
                    value={complex_script}
                    data={[{
                      id: 'asc',
                      text: '升序'
                    }, {
                      id: 'desc',
                      text: '降序'
                    }]}
                    validateFun={value => !!value.length ? true : '排名类型不能为空'}
                    sholdCheckValidate={item.disabledCheck}
                    onChange={val => setComplexScript(index, val)}
                  />
                </div>
              </div>
              <div className="formInput">
                <span className="label required">排名维度</span>
                <div className="content">
                  <SdwSelect
                    clearable={false}
                    isMultipleChoice={false}
                    placeholder={'请选择'}
                    value={rankDimention}
                    data={rankDimensionData}
                    validateFun={value => !!value ? true : '排名维度不能为空'}
                    sholdCheckValidate={item.disabledCheck}
                    onChange={val => setRankDimension(index, val)}
                  />
                </div>
              </div>
              <div className="formInput">
                <span className="label">排名范围</span>
                <div className="content">
                  <SdwSelect
                    isMultipleChoice={false}
                    placeholder={'来源表'}
                    value={group_by_field_Id}
                    data={rangeRankData}
                    onChange={val => setRankRange(index, val)}
                  />
                </div>
              </div>
              <div className="formInput">
                <span style={{ marginLeft: '100px' }} />
                <span className="content">
                  <SdwSelect
                    width="35%"
                    readOnly={false}
                    placeholder={!group_by_field_Id ? '请先选择来源表' : "来源字段"}
                    value={group_by_field_key}
                    disabled={!group_by_field_Id}
                    data={this.state.sourceFieldData}
                    onChange={val => setSourceOrRangeField(index, val, 'key')}
                  />
                  <span style={{ marginLeft: '8px' }} />
                  <SdwSelect
                    width="35%"
                    readOnly={false}
                    placeholder={!group_by_field_Id ? '请先选择来源表' : "范围字段"}
                    value={group_by_field_value}
                    disabled={!group_by_field_Id}
                    data={this.state.sourceFieldData}
                    onChange={val => setSourceOrRangeField(index, val, 'value')}
                  />
                </span>
              </div>
              <div className="formInput formInput-time_schedule">
                <span className="label required">时间周期</span>
                <div className="content">
                  {
                    Array.isArray(time_schedule) && time_schedule.map((row, k) => {
                      return (
                        <div className="formInput-time_schedule-content" key={k}>
                          <SdwSelect
                            clearable={false}
                            placeholder={'请选择时间周期'}
                            value={row.time_cycle}
                            data={timeCycle}
                            sholdCheckValidate={item.disabledCheck}
                            validateFun={value => !!value ? true : '时间周期不能为空'}
                            onChange={val => configDataChange(val, 'time_schedule_time_cycle', k, index)} />
                          <SdwSelect
                            clearable={false}
                            placeholder={'请选择运行频率'}
                            value={row.run_cycle}
                            data={runCycle}
                            sholdCheckValidate={item.disabledCheck}
                            validateFun={value => !!value ? true : '运行频率不能为空'}
                            onChange={val => configDataChange(val, 'store_dimension_run_cycle', k, index)} />
                          <i onClick={() => addVirtualProperty2(index)} className="el-icon-plus"></i>
                          { k > 0 && (<i onClick={() => removeVirtualProperty2(k, index)} className="el-icon-minus"></i>)}
                        </div>
                      )
                    })
                  }

                </div>
              </div>
              <div className="formInput">
                <span className="label">是否启用</span>
                <div className="content el-switch-enable">
                  <SdwSwitch
                    value={item.enable}
                    changeValue={val => enableStateChange(val, item, index,'addData')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddIndicators
