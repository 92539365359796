// 对应接口字段
export const TYPE_MAP = {
  new: '0',
  start: '1',
  done: '2',
  notEffect: '3',
  error: '4',
  run:"200",
  stop:"201"
}

export const TYPE_TEXT_MAP = {
  [TYPE_MAP.new]: '未开始',
  [TYPE_MAP.start]: '进行中',
  [TYPE_MAP.done]: '已完成',
  [TYPE_MAP.notEffect]: '文件已过期',
  [TYPE_MAP.error]: '异常'
}

export const TYPE_CLASS_NAEM_MAP = {
  [TYPE_MAP.new]: 'is_new',
  [TYPE_MAP.start]: 'is_doing',
  [TYPE_MAP.done]: 'is_done',
  [TYPE_MAP.notEffect]: 'is_doing',
  [TYPE_MAP.error]: 'is_error'
}

export const STATUS_MAP = {
  [TYPE_MAP.run]: '服务正在运行',
  [TYPE_MAP.stop]: '服务已停止',
}
