import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loaddingImg from './img/loadding.png'

import './index.css'

const IMG_MAP = {
  loaddingImg: loaddingImg,
}

class Loadding extends Component {

  static defaultProps = {
    tipContent: "加载中...",
    tipIconWidth: 44,
    tipIconHeight: 44,
  }

  render() {
    let {
      top,
      width,
      loadding,
      tipContent,
      tipIconWidth,
      tipIconHeight
    } = this.props
    
    return (
      <div className="loadding-main" style={{ width }}>
        {
          loadding &&
          <div className="loadding-main-wrap" style={{ width }}>
            <div className="loadding-main-wrap__body" style={{
              ...!!top ? {
                top: top,
                transform: 'translateX(-50%)'
              } : {}
            }}>
              <span className="loadding-main-img" style={{ width: tipIconWidth, height: tipIconHeight }}><img src={IMG_MAP.loaddingImg}></img></span>
              <span className="loadding-main-tip">{tipContent}</span>
            </div>
          </div>
        }
        {this.props.children}
      </div>
    )
  }
}

Loadding.propTypes = {
  loadding: PropTypes.bool,
  tipContent: PropTypes.string, // 加载中的提示文本
  tipIconWidth: PropTypes.number, // 加载icon的宽度
  tipIconHeight: PropTypes.number, // 加载icon的高度
  width: PropTypes.number, // 宽度
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 提示文字固定top
}

export default Loadding
