import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SdwTree from '@common/Tree'
import { getAllTopicTarget } from '@services/application'
import SdwMessage from '@common/Message'

import './style/index.css'

class TagTree extends React.PureComponent {

  state = {
    allTargetData: [],
    checkedTargetIDs: [],
    treeOptions: {
      children: 'children',
      label: 'name'
    },
  }

  componentDidMount () {
    getAllTopicTarget().then(res => {
      let targetData = res.data.data || []
      this.setState({
        allTargetData: targetData
      })
    }, () => SdwMessage.error("接口异常"))
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    // 总的指标有所更改,界面需要标记出已编辑的指标
    if (
      prevProps.allSelectedIndicator.length !== this.props.allSelectedIndicator.length ||
      _.differenceWith(prevProps.allSelectedIndicator, this.props.allSelectedIndicator, _.isEqual).length ||
      prevState.allTargetData.length !== this.state.allTargetData.length ||
      _.differenceWith(prevState.allTargetData, this.state.allTargetData, _.isEqual).length
    ) {
      this.handleRenderTree()
    }
  }

  handleRenderTree = () => {
    let { allSelectedIndicator } = this.props
    let { allTargetData } = this.state
    let checkedTargetIDs = allSelectedIndicator.reduce((prev, i) => {
      prev = prev.concat(Number(i.target_id))
      return prev
    }, [])

    checkedTargetIDs = Array.from(new Set(checkedTargetIDs))

    this.setState({
      checkedTargetIDs: checkedTargetIDs
    })

    if (!checkedTargetIDs.length) return

    let curAllTargetData = this.addTreeCustomClass(allTargetData, checkedTargetIDs)

    this.setState({
      allTargetData: curAllTargetData
    })
  }

  addTreeCustomClass = (list, ids) => {
    return list.map(item => {
      let curItem = _.cloneDeep(item)
      if (item.id && ids.includes(item.id)) {
        curItem = {
          ...curItem,
          customClass: 'sdw-application-detail__tree-is-edit'
        }
      } else {
        curItem = {
          ...curItem,
          customClass: ''
        }
      }

      if (item.children && item.children.length) {
        curItem.children = this.addTreeCustomClass(item.children, ids)
      }

      return curItem
    })
  }

  changeCurClickTreeValue = val => {
    console.log("========val", val)
    let { curShowSelectedTarget, oriAllSelectedTarget } = this.props
    console.log("=======curShowSelectedTarget", curShowSelectedTarget)
    console.log("=======oriAllSelectedTarget", oriAllSelectedTarget)
    this.initClickTreeNode(val)
    //逻辑改变 ===== 不需要判断检查变更了
    // 点击其他节点,检查是否有变更,有变更弹出弹窗: 是否保存当前修改
    // let hasUpdateData =
    //   curShowSelectedTarget.length !== oriAllSelectedTarget.length ||
    //   !!_.differenceWith(curShowSelectedTarget, oriAllSelectedTarget, _.isEqual).length
    //
    // if (hasUpdateData) {
    //   //this.props.changeComfirmVisible(true)
    //   this.props.updateCurrentClickTreeNode(val)
    // } else {
    //   this.initClickTreeNode(val)
    // }
  }

  initClickTreeNode = val => {
    this.props.updateCurClickTreeValue(val)

    // 点击叶子节点，查询对应的信息
    if (!val.children) {
      this.props.onGetTarget(val)
    } else {
      this.props.updateTargetTime([])
      this.props.updateTargetDimension([])
    }
  }

  render () {
    let {
      checkedTargetIDs,
      allTargetData,
      treeOptions
    } = this.state
    // 总的指标
    let targetTotal = allTargetData.reduce((prev, i) => {
      prev = prev.concat(i.children)
      return prev
    }, []).length

    return (
      <>
        <div className="title" style={{ position: 'relative' }}>
          <span>指标选择</span>
          <span style={{ position: 'absolute', right: 0, fontSize: 14, color: '#999' }}>{`${checkedTargetIDs.length}/${targetTotal}`}</span>
        </div>

        <div style={{ marginTop: '16px', height: '100%' }}>
          <SdwTree
            showFilterInput={true}
            filterInputWidth={298}
            data={allTargetData}
            options={treeOptions}
            inputPlaceholder="请输入主题域或指标名"
            changeValue={this.changeCurClickTreeValue}
          />
        </div>
      </>
    )
  }
}

const mapState = state => ({
  allSelectedIndicator: state.apiIndex.allSelectedIndicator,
  curShowSelectedTarget: state.apiIndex.curShowSelectedTarget,
  oriAllSelectedTarget: state.apiIndex.oriAllSelectedTarget,
})

const mapDispatch = dispatch => ({
  changeComfirmVisible: dispatch.apiIndex.changeComfirmVisible,
  updateCurrentClickTreeNode: dispatch.apiIndex.updateCurrentClickTreeNode,
  updateCurClickTreeValue: dispatch.apiIndex.updateCurClickTreeValue,
  updateCurShowSelectedTarget : dispatch.apiIndex.updateCurShowSelectedTarget,
  updateTargetTime: dispatch.apiIndex.updateTargetTime,
  updateTargetDimension: dispatch.apiIndex.updateTargetDimension,
})

export default connect(mapState, mapDispatch)(TagTree)
