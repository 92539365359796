// { url, title, icon, component, children }
// url
// children
const layout = {
  // initial state
  state: {
    menus: {},
  },
  reducers: {
    increment(state, payload) {
      // console.log(state + payload)
      return state + payload
    },
    decrease(state, payload) {
      return state - payload
    },
  },
  effects: dispatch => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async incrementAsync(payload, rootState) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      dispatch.count.increment(payload)
    },
    async decreaseAsync(payload, rootState) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      dispatch.count.increment(payload)
    },
  }),
}

export default layout
