import React from 'react'
import _ from 'lodash'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwButton from '@common/Button'
import SdwMessage from '@common/Message'
import SdwTable from '@common/Table'
import SdwDialog from '@common/Dialog'
import SdwPagination from '@common/Pagenation'
import SdwDateTimePicker from '@common/DateTimePicker'
import BreadCrumbsAndTablde from '@components/BreadCrumbsAndTablde'
import DetailedWin from './StepWin/DetailedWin'
import SupplementWin from './StepWin/SupplementWin'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {requestOptionsLOG} from '@config/config'

const baseURL = requestOptionsLOG.basename

class QualityMonitoring extends React.PureComponent {

    state = {
        tableData: {},
        datePicker: [],
        search: {
            page: 1,
            size: 10,
            cmd: '', // 解析标识
            timeGranularity: '', // 时间粒度
            timeCycle: '', // 时间周期
            reconciliationStatus: '', // 对账状态
            additionalRecordingStatus: '', // 补录状态
            start: '', // 查询开始时间
            end: '' // 查询结束时间
        },
        permissions: false, // 异常补录权限
        currentItem: {},
        detailedVisible: false, //对账明细弹窗
        supplementVisible: false, // 异常补录弹窗
        infoVisible: false, // 补录信息弹窗
        reasonVisible: false, // 异常原因弹窗
    }

    componentDidMount() {
        this.initDatePicker()
        this.queryReconciliationAuthority()
    }

    // 初始化时间为当天零点到第二天零点
    initDatePicker = () => {
        let startDate = new Date(new Date().setHours(0, 0, 0))
        let endDate = new Date(new Date().setHours(24, 0, 0))
        let arr = []
        arr.push(startDate, endDate)
        let date = this.formatDate(arr)
        this.setState({
            datePicker: date,
            search: {
                start: date[0],
                end: date[1],
                timeGranularity: 'HOUR'
            }
        }, () => this.queryReconciliations())
    }

    // 格式化时间为yyyy-MM-dd HH:mm:ss
    formatDate = (date) => {
        if (_.isArray(date)) {
            return date.map(item => {
                let year = item.getFullYear()
                let month = item.getMonth() + 1
                month = month < 10 ? ('0' + month) : month
                let date = item.getDate()
                date = date < 10 ? ('0' + date) : date
                let time = '00:00:00'
                return `${year}-${month}-${date} ${time}`
            })
        }
    }

    // 查询对账详情
    queryReconciliations = () => {
        let rtx = this.props.userInfo.name
        let {search, datePicker} = this.state
        let params = {}
        for (let key in search) {
            if (search[key]) {
                params[key] = search[key]
            }
        }
        params.start = datePicker[0]
        params.end = datePicker[1]
        fetch(baseURL + '/sdw/filebeat/reconciliation/queryReconciliations',
            {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
                body: JSON.stringify(params)
            }).then(res => {
            res.json().then((data) => {
                let record = data.data || []
                this.setState({
                    tableData: record
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    // 查询是否有补录权限
    queryReconciliationAuthority = () => {
        let rtx = this.props.userInfo.name
        fetch(baseURL + '/sdw/filebeat/reconciliation/queryReconciliationAuthority',
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then(data => {
                if (data && data.code === 100) {
                    let record = data.data || false
                    this.setState({
                        permissions: record
                    })
                }
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    getColumns = () => {
        let self = this
        let {permissions} = this.state
        return [
            {
                title: 'CMD',
                width: 42,
                dataIndex: 'cmd'
            },
            {
                title: '分发方式',
                width: 66,
                render: data => {
                    let arr = {
                        'STORE': '入库',
                        'INDIVIDUAL': '自定义'
                    }
                    let msg = arr[data.deliveryType]
                    return <span>{msg ? msg : '-'}</span>
                }
            },
            {
                title: '时间周期',
                width: 158,
                dataIndex: 'timeCycle'
            },
            {
                title: '对账结果',
                width: 82,
                render: data => (
                    <span className={
                        Boolean(data.reconcileStatus) ? 'status-comp_is-effect' : 'status-comp_no-effect'
                    }>
                        {Boolean(data.reconcileStatus) ? '正确' : '异常'}
                    </span>
                )
            },
            {
                title: '采集对账',
                width: 82,
                render: data => (
                    <span className={
                        Boolean(data.accessReconcileStatus) ? 'status-comp_is-effect' : 'status-comp_no-effect'
                    }>
                        {Boolean(data.accessReconcileStatus) ? '正确' : '异常'}
                    </span>
                )
            },
            {
                title: '处理对账',
                width: 82,
                render: data => (
                    <span className={
                        Boolean(data.processReconcileStatus) ? 'status-comp_is-effect' : 'status-comp_no-effect'
                    }>
                        {Boolean(data.processReconcileStatus) ? '正确' : '异常'}
                    </span>
                )
            },
            {
                title: '消费对账',
                width: 82,
                render: data => (
                    <span className={
                        Boolean(data.consumeReconcileStatus) ? 'status-comp_is-effect' : 'status-comp_no-effect'
                    }>
                        {Boolean(data.consumeReconcileStatus) ? '正确' : '异常'}
                    </span>
                )
            },
            {
                title: '负责人',
                width: 96,
                dataIndex: 'principal'
            },
            {
                title: '补录状态',
                width: 70,
                render: data => (
                    <span className={
                        Boolean(data.additionalRecordingStatus) ? 'status-comp_is-effect' : 'status-comp_no-effect'
                    }>
                        {Boolean(data.additionalRecordingStatus) ? '已补录' : '未补录'}
                    </span>
                )
            },
            {
                title: '操作',
                width: 180,
                render: data => (
                    <>
                        <SdwButton
                            text='对账明细'
                            onClick={() => self.reconciliationDetailed(data)}
                        />
                        {!data.additionalRecordingStatus ?
                            <SdwButton
                                text='异常补录'
                                disabled={!(permissions && !data.reconcileStatus)}
                                onClick={() => self.abnormalSupplement(data)}
                            /> :
                            <SdwButton
                                text='补录信息'
                                onClick={() => self.supplementInfo(data)}
                            />
                        }
                        <SdwButton
                            text='查看异常'
                            disabled={data.reconcileStatus}
                            onClick={() => {self.abnormalReason(data)}}
                        />
                    </>
                )
            },
        ]
    }

    // 对账明细
    reconciliationDetailed = (record = {}) => {
        this.setState({
            detailedVisible: true,
            currentItem: record
        })
    }

    // 异常补录
    abnormalSupplement = (record = {}) => {
        this.setState({
            supplementVisible: true,
            currentItem: record
        })
    }

    // 补录信息
    supplementInfo = (record = {}) => {
        this.setState({
            infoVisible: true,
            currentItem: record
        })
    }

    // 异常原因
    abnormalReason = (record = {}) => {
        this.setState({
            reasonVisible: true,
            currentItem: record
        })
    }

    setSearch = (type, val) => {
        let {search} = this.state
        let obj = {}
        obj[type] = val
        if (type != 'page') {
            obj['page'] = 1
        }
        let searchClone = Object.assign({}, search, obj)
        this.setState({
            search: searchClone
        }, () => {
            this.queryReconciliations()
        })
    }

    render() {

        let {
            tableData,
            datePicker,
            currentItem,
            search,
            infoVisible,
            reasonVisible,
            detailedVisible,
            supplementVisible
        } = this.state

        return (
            <>
                <BreadCrumbsAndTablde>
                    <BreadCrumbsAndTablde.LeftCrumbs>
                        <SdwDateTimePicker
                            value={datePicker}
                            showDateTimeRangePicker={true}
                            changeValue={val => {
                                this.setState({
                                    datePicker: val
                                }, () => {
                                    this.queryReconciliations()
                                })
                            }}
                        />
                        <SdwInput
                            label='CMD'
                            width={260}
                            value={search.cmd}
                            onChange={val => this.setSearch('cmd', val)}
                        />
                        <SdwSelect
                            label='时间粒度'
                            width={200}
                            data={[{
                                id: 'HOUR',
                                text: '小时',
                            }, {
                                id: 'DAY',
                                text: '天',
                            }, {
                                id: 'MONTH',
                                text: '月'
                            }]}
                            value={search.timeGranularity}
                            onChange={val => this.setSearch('timeGranularity', val)}
                        />
                        <SdwInput
                            label='时间周期'
                            width={260}
                            value={search.timeCycle}
                            onChange={val => this.setSearch('timeCycle', val)}
                        />
                        <SdwSelect
                            label='对账结果'
                            width={200}
                            data={[{
                                id: 'true',
                                text: '正确'
                            }, {
                                id: 'false',
                                text: '异常'
                            }]}
                            value={search.reconciliationStatus}
                            onChange={val => this.setSearch('reconciliationStatus', val)}
                        />
                        <SdwSelect
                            label='补录状态'
                            width={200}
                            data={[{
                                id: 'true',
                                text: '已补录'
                            }, {
                                id: 'false',
                                text: '未补录'
                            }]}
                            value={search.additionalRecordingStatus}
                            onChange={val => this.setSearch('additionalRecordingStatus', val)}
                        />
                    </BreadCrumbsAndTablde.LeftCrumbs>

                    <BreadCrumbsAndTablde.CenterWrap>
                        <SdwTable
                            columns={this.getColumns()}
                            dataSource={tableData.dataList || []}
                        />
                        <SdwPagination
                            total={tableData.total || 0}
                            currentPage={tableData.current_page || 1}
                            pageCount={tableData.per_page}
                            getClickPage={val => this.setSearch('page', val)}
                            pageCountChange={val => this.setSearch('size', val)}
                        />
                    </BreadCrumbsAndTablde.CenterWrap>
                </BreadCrumbsAndTablde>

                {/* 对账明细弹窗 */}
                {detailedVisible &&
                    <DetailedWin
                        currentItem={currentItem}
                        detailedVisible={detailedVisible}
                        closeDialog={() => this.setState({detailedVisible: false})}
                    />
                }

                {/* 异常补录弹窗 */}
                {supplementVisible &&
                    <SupplementWin
                        currentItem={currentItem}
                        supplementVisible={supplementVisible}
                        queryReconciliations={this.queryReconciliations}
                        closeDialog={() => this.setState({supplementVisible: false})}
                    />
                }

                {/* 补录信息弹窗 */}
                {infoVisible &&
                    <SdwDialog
                        title={'补录信息'}
                        width={800}
                        visible={infoVisible}
                        hideCancelButton={true}
                        hideSureButton={true}
                        onCancelClick={() => this.setState({infoVisible: false})}
                    >
                        <div className='info-reason_wrap'>
                            {currentItem.exceptionRecording}
                        </div>
                    </SdwDialog>
                }

                {/* 异常原因弹窗 */}
                {reasonVisible &&
                    <SdwDialog
                        title={'异常原因'}
                        width={800}
                        visible={reasonVisible}
                        hideCancelButton={true}
                        hideSureButton={true}
                        onCancelClick={() => this.setState({reasonVisible: false})}
                    >
                        <div className='info-reason_wrap'>
                            {currentItem.exceptionResult || '暂无异常'}
                        </div>
                    </SdwDialog>
                }
            </>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(QualityMonitoring))
