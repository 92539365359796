import { useState, useEffect, useLayoutEffect, useMemo } from 'react'
import { connect } from 'react-redux'

import "./style/index.css"
import FlowChart from '@common/FlowChart'
import _ from 'lodash'
import img3 from '@pages/MachFactory/Derived/Detail/Blood/img/level3.png'
import { getDataKinship } from '@api/Derived'
import Message from '@common/Message'

function TableView(props) {
  const [initRecord, setInitRecord] = useState([])
  const [initPathArr, setInitPathArr] = useState([])
  useEffect(() => {
    let treeInfo = props.treeInfo
    let detailInfo = props.detailInfo
    console.log("treeInfo", treeInfo)
    console.log("detailInfo", detailInfo)
    let dataClone = getDetailInfoList(detailInfo)
    //获取连接关系
    let linkConf = detailInfo.link_conf
    //通过后序遍历的方式计算 列的left
    // let treeObj = getTree(treeInfo.tree)
    // let treeTrace = treeObj.treeTrace
    // let linkConf = treeObj.linkConf
    // //通过中序遍历的方式计算 行的top
    // let treeMidTrace = getMidTree(treeInfo.tree)
    // let levelLength = treeTrace.length
    // let deepNum = -1
    // if (treeInfo.list != null) {
    //   treeInfo.list.forEach(i => {
    //     deepNum = deepNum + 2
    //     dataClone[i.id] = {id:i.id, is_used:0, text:(i.table_name?i.table_name:"根节点"), type:(i.table_type==-1?0:i.table_type), deep:deepNum, level:1, item:i}
    //   })
    // }
    console.log("======dataClone", dataClone)
    props.updateStepList(dataClone)
    let data = Object.values(dataClone)
    //组装连线关系
    let linkConfList = []
    if (!linkConf) {
      linkConf = []
    }
    linkConf.forEach(i => {
      linkConfList.push({ beginID: i.left_node_id, beginPosition: "right", endID: i.target_node_id, endPosition: "left", type:"left_node_id"})
      if (i.right_node_id !== null) {
        linkConfList.push({ beginID: i.right_node_id, beginPosition: "right", endID: i.target_node_id, endPosition: "left", type:"right_node_id"})
      }
    })
    let initRecord = []
    data.forEach((item, key) => {
      let style = {
        width: 113,
        height: 32
      }
      let marginLeft = item.is_used == 1 ? 450 : 280
      let deep = item.deep //行的层级
      let level = item.level //列的层级
      initRecord.push({
        id: item.id,
        textContent: getDiv(item),
        style: getStyle(style, deep, level, marginLeft)
      })
    })
    setInitPathArr(linkConfList)
    setInitRecord(initRecord)
  }, [props.detailInfo])

  function getDetailInfoList(detailInfo) {

    let link_conf = detailInfo.link_conf
    let table_conf = detailInfo.table_conf
    let positionMap = {}
    let readerMap = {}
    for (let i in table_conf) {
      let table_conf_clone = _.cloneDeep(table_conf[i])
      if (link_conf) {
        link_conf.forEach(link_conf_item => {
          if (link_conf_item.target_node_id == table_conf[i].id) {
            table_conf_clone.link = link_conf_item
          }
        })
      }
      readerMap[table_conf[i].id] = table_conf_clone
    }
    console.log("readerMap===", readerMap)
    console.log("link_conf===", link_conf)
    let targetNodeIdTem = 0
    for (let i in link_conf) {
      let leftNodeId = link_conf[i].left_node_id
      let rightNodeId = link_conf[i].right_node_id
      let targetNodeId = link_conf[i].target_node_id
      let x = parseInt(i) + 2
      let y = parseInt(i) + 2
      //左节点
      if (leftNodeId && !positionMap.hasOwnProperty(leftNodeId)) {
        let readerInfo = readerMap[leftNodeId]
        let id = leftNodeId
        let deep = y - 1
        let level = x - 1
        if (readerMap[targetNodeId] && (readerMap[targetNodeId].view_type == 1 || readerMap[targetNodeId].view_type == 2)) { //0多表关联，1单表聚合，2分桶聚合
          deep = deep + 2
        }
        if (i != 0 && leftNodeId != targetNodeIdTem) {
          level = level - 1
          deep  = deep + 4
        }
        let text = readerInfo.table_name?readerInfo.table_name:"根节点"
        let type = readerInfo.table_type==-1?0:readerInfo.table_type
        positionMap[id] = {id:id, is_used:1, text:text, type:type, deep:deep, level:level, item:readerInfo}
      }
      //目标节点
      if (targetNodeId && !positionMap.hasOwnProperty(targetNodeId)) {
        let readerInfo = readerMap[targetNodeId]
        let id = targetNodeId
        let level = 0
        let deep = 0
        if (readerInfo && (readerInfo.view_type == 1 || readerInfo.view_type == 2)) { //0多表关联，1单表聚合，2分桶聚合
          level = positionMap[leftNodeId].level + 1
          deep = positionMap[leftNodeId].deep
        } else { //多表关联
          level = x
          deep = y
        }
        if (i != 0 && leftNodeId != targetNodeIdTem) {
          level = level - 1
          deep  = deep + 4
        }
        let text = readerInfo.table_name?readerInfo.table_name:"根节点"
        let type = readerInfo.table_type==-1?0:readerInfo.table_type
        positionMap[id] = {id:id, is_used:1, text:text, type:type, deep:deep, level:level, item:readerInfo}
      }
      //右节点
      if (rightNodeId && !positionMap.hasOwnProperty(rightNodeId)) {
        let readerInfo = readerMap[rightNodeId]
        let id = rightNodeId
        let level = x - 1
        let deep = y + 1
        if (i != 0 && leftNodeId != targetNodeIdTem) {
          level = level - 1
          deep  = deep + 4
        }
        let text = readerInfo.table_name?readerInfo.table_name:"根节点"
        let type = readerInfo.table_type==-1?0:readerInfo.table_type
        positionMap[id] = {id:id, is_used:1, text:text, type:type, deep:deep, level:level, item:readerInfo}
      }
      targetNodeIdTem = targetNodeId
    }
    let n = -1
    for (let id in readerMap) {
      if (!positionMap.hasOwnProperty(readerMap[id].id)) {
        n = n + 2
        let readerInfo = readerMap[id]
        let text = readerInfo.table_name?readerInfo.table_name:"根节点"
        let type = readerInfo.table_type==-1?0:readerInfo.table_type
        positionMap[readerMap[id].id] =  {id:readerMap[id].id, is_used:0, text:text, type:type, deep:n, level:1, item:readerInfo}
      }
    }
    console.log("===positionMap", positionMap)
    return positionMap
  }

  /*
  * @param {TreeNode} root
  * 后序遍历
  * @return {number[]}
  * */
  function getTree(root) {
    // 按前序的次序来存储节点的值
    const ans = [];
    const linkConf = [];
    /**
     * 递归遍历树节点
     */
    const traversal = (node, n) => {
      // 如果该子节点不存在，则终止递归
      if (node === "" || node === null || JSON.stringify(node) == "{}") return;
      // 处理当前节点的左子树
      if (node.hasOwnProperty("left")) {
        traversal(node.left, n + 1)
      }

      // 处理当前节点的右子树
      if (node.hasOwnProperty("right")) {
        traversal(node.right, n + 1)
      }
      // 处理当前节点
      let reader = {...node.reader}
      reader["link"] = node.link
      if (ans[n]) {
        ans[n].push(reader);
      } else {
        ans[n] = [reader]
      }
      if (node.link !== null) {
        linkConf.push(node.link)
      }
    };
    traversal(root, 0);

    return {treeTrace:ans, linkConf:linkConf};
  }

  /*
  * @param {TreeNode} root
  * 中序遍历
  * @return {number[]}
  * */
  function getMidTree(root) {
    if (root === null) return [];
    // 按前序的次序来存储节点的值
    const ans = [];
    /**
     * 递归遍历树节点
     */
    const traversal = (node, n) => {
      // 如果该子节点不存在，则终止递归
      if (node === "" || node === null || JSON.stringify(node) == "{}") return;
      // 处理当前节点的左子树
      if (node.hasOwnProperty("left")) {
        traversal(node.left, n + 1)
      }
      ans.push(node.reader);
      // 处理当前节点的右子树
      if (node.hasOwnProperty("right")) {
        traversal(node.right, n + 1)
      }
    };
    traversal(root, 0);

    return ans;
  }

  function onDivClick(item) {
    props.tableViewClickNode(item)
  }

  function getDiv(item) {
    return (
      <div
        className={`detail-midTableView__flow-chart-wrap`}
        key={`${item.type}_${item.id}`}
        onClick={(e) => onDivClick(item)}
      >
        <div  className={`detail-midTableView__flow-chart-wrap_left type_left_${item.type}`}></div>
        <div
          className={`detail-midTableView__flow-chart-wrap_right type_right_${item.type}`}
          title={item.text}
        >{item.text}</div>
      </div>
    )
  }
  // 根据deep, level获取top值和left
  function getStyle(style, deep, level, marginLeft = 0) {
    let top = 0
    let left = 0
    if (deep % 2 === 0) { //偶数行
      top = (deep / 2 -1) * (32 + 20) + 26
    } else { //奇数行
      top = ((deep + 1) / 2 -1) * (32 + 20)
    }

    left = marginLeft +  ( level -1) * (113+ 58)

    return {
      ...style,
      left,
      top
    }
  }
  let flowHeight = "1000"
  console.log("====initRecord", initRecord)
  console.log("====initPathArr", initPathArr)
  console.log("====flowHeight", flowHeight)
  return (
    <div className="detail-EditMidTable__wrap-box">
      {
        initRecord.length > 0 &&
        <FlowChart
          width='100%'
          height={flowHeight}
          initRecord={initRecord}
          initPathArr={initPathArr}
        />
      }
    </div>
  )
}



const mapState = state => ({
  treeInfo: state.midTableView.treeInfo,
  stepList: state.midTableView.stepList,
  detailInfo: state.midTableView.detailInfo,
})
const mapDispatch = dispatch => ({
  updateTreeInfo:dispatch.midTableView.updateTreeInfo,
  updateStepList:dispatch.midTableView.updateStepList,
  updateDetailInfo:dispatch.midTableView.updateDetailInfo,
})
export default connect(mapState, mapDispatch)(TableView)
