import React, { PureComponent } from 'react'
import SdwDrawer from '@common/Drawer'
import SdwInput from '@common/Input'
import SdwSelect from '@common/Select'
import SdwSwitch from '@common/Switch'
import SdwCascader from '@common/Cascader'
import SdwMessage from '@common/Message'
import { upData, addData } from '@services/modifier'
import 'element-theme-default'
import './style/EditData.css'

class EditData extends PureComponent {

  state = {
    id: "",
    modifier_name: "",
    source_id: "",
    filter_value: "",
    decoration: "",
    enable: 0
  }

  componentDidMount () {
    this.initData()
  }

  componentDidUpdate (prevProps, prevState) {

    if (prevProps.record.id !== this.props.record.id) {
      this.initData()
    }

    if (prevProps.record.related_table_id !== this.props.record.related_table_id) {
      this.getTablecfgFields(this.props.record.related_table_id)
    }
  }

  initData = () => {
    this.setState({
      id: this.props.record.id,
      modifier_name: this.props.record.modifier_name,
      source_id: this.props.record.source_id,
      filter_value: this.props.record.filter_value,
      decoration: this.props.record.decoration,
      enable: this.props.record.enable
    })
  }

  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    return `${label}不能为空`
  }

  handleSureClick = () => {
    let {
      id,
      modifier_name,
      source_id,
      filter_value,
      decoration,
      enable,
    } = this.state

    let isValidatePass =
      !!modifier_name &&
      !!source_id &&
      !!filter_value

    if (!isValidatePass) {
      // 手动触发必填项的检查
      this.setState({
        sholdCheckValidate: true
      })
      return
    }

    let dataid = this.props.record.id || 0

    let params = { id: dataid, field: {}}

    let source_name = ""
    for (const i in this.props.tableList) {
      let item = this.props.tableList[i];
      if (item["id"] == source_id) {
        source_name =
          (item.database_name ? item.database_name + "." : "") +
          item.table_name +
          "（" +
          item.data_set_chinese +
          "）";
        break;
      }
    }

    let comitFieldData = {
      modifier_name,
      source_id,
      filter_value,
      decoration,
      enable,
      source_name: source_name
    }

    let formData = new FormData()
    for (let i in comitFieldData) {
      let val = comitFieldData[i]
      if (i === 'id') {
        continue
      }
      params.field[i] = val
    }

    for (let i in params) {
      formData.append(i, params[i])
    }

    if(dataid == 0) {
      // 添加数据
      addData(params, (res) => {

        if (res && res.data && res.data.code === 0) {
          let msg = id === 0 ? '新建成功' : '编辑成功'
          SdwMessage.success(msg)
          // 告诉父级点击了确定按钮
          this.props.onSureClick()
        } else {
          SdwMessage.error(res.data.msg);
        }
      })
    }else {
      // 更新数据
      upData(params, (res) => {

        if (res && res.data && res.data.code === 0) {
          let msg = id === 0 ? '新建成功' : '编辑成功'
          SdwMessage.success(msg)
          // 告诉父级点击了确定按钮
          this.props.onSureClick()
        } else {
          SdwMessage.error(res.data.msg);
        }
      })
    }
  }

  enableStateChange = (val) => {
    if(val) {
      this.setState({enable: 1})
    }else {
      this.setState({enable: 0})
    }
  }

  render () {
    let {
      modifier_name,
      source_id,
      filter_value,
      decoration,
      enable,

      sholdCheckValidate,
    } = this.state

    // 来源表
    let tableList = this.props.tableList.map(i => {
      return {
        id: i.id+'',
        text: i.data_set_chinese + "(" + i.database_name + "." + i.table_name + ")"
      }
    })

    // 有效无效状态
    let enableState = enable == 1 ? true : false

    return (
      <div className="editorMain">
        <SdwDrawer
          title={this.props.title}
          visible={this.props.visible}
          onIconClick={this.props.onIconClick}
          onCancelClick={this.props.onCancelClick}
          onSureClick={this.handleSureClick}
        >
          <SdwDrawer.Body>
            <div className="form data-editor-win-wrap">
              <div className="formInput">
                <span className="label required">修饰词名称</span>
                <SdwInput
                  value={modifier_name}
                  validateFun={value => this.validateFun(value, '修饰词名称')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({modifier_name: val})}
                />
              </div>

              <div className="formInput">
                <span className="label required">来源表</span>
                <SdwSelect
                  readOnly={false}
                  value={source_id}
                  validateFun={value => this.validateFun(value, '来源表')}
                  sholdCheckValidate={sholdCheckValidate}
                  onChange={val => this.setState({source_id: val})}
                  data={tableList}
                />
              </div>

              <div className="formInput">
                <span className="label required">过滤条件</span>
                <span className="content">
                  <SdwInput
                    value={filter_value}
                    validateFun={value => this.validateFun(value, '过滤条件')}
                    sholdCheckValidate={sholdCheckValidate}
                    onChange={val => this.setState({filter_value: val})}
                  />
                </span>
              </div>

              <div className="formInput">
                <span className="label">描述</span>
                <SdwInput
                  type="textarea"
                  value={decoration}
                  placeholder="请输入修饰词含义描述"
                  onChange={val => this.setState({decoration: val})}
                />
              </div>

              <div className="formInput formInput-enableState-xsc">
                <span className="label">是否启用</span>
                <span className="content">
                  <SdwSwitch
                      value={enableState}
                      changeValue={val => {this.enableStateChange(val)}}
                  />
                </span>
              </div>
            </div>
          </SdwDrawer.Body>
        </SdwDrawer>
      </div>
    )
  }
}

export default EditData
