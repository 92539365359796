import React, { PureComponent } from 'react'
import SdwInput from '@common/Input'
import SdwDialog from '@common/Dialog'
import 'element-theme-default'
import './style/showTemplateConf.css'

class DialogVisible extends PureComponent {
  constructor(props) {
    super(props);
  }
  validateFun = (curValue, label = '') => {
    if (typeof curValue != 'object' && (typeof curValue === 'number' || !!curValue.trim())) {
      return true
    }
    return `${label}不能为空`
  }

  closeDialog = () => {
    this.props.closeDialog()
  }


  render () {
    let { item, dialogVisible} = this.props
    const { handleSubmit , setTemplateConfValue} = this.props
    return (
      <div className="show-data DialogShowData DialogShowData-Derived">
        <SdwDialog
            //title={(!item?"新增":"编辑")+"账号专区API"}
            size="tiny"
            visible={ dialogVisible }
            width={500}
            onSureClick={handleSubmit}
            onCancelClick={ () => this.closeDialog() }
            lockScroll={ false }
          >
          <div className='add-application-win__label'>
            <SdwInput
              width={418}
              placeholder={"请输入"}
              value={item.data.template_name}
              onChange={val => setTemplateConfValue( "template_name", val)}
              sholdCheckValidate={item.hasOwnProperty("sholdCheckValidate")?item.sholdCheckValidate:false}
              validateFun={value => !!value ? true : '名称不能为空'}
            />
          </div>
          </SdwDialog>
      </div>
    )
  }
}

export default DialogVisible
