import { useState, useEffect } from "react"
import { commonApi } from "@utils/request"
import Message from '@common/Message'
import { getUserTraceItem } from '@customHooks/api'

export default function UseGetUserTraceItem(search, doSearch, setIsLoadding) {

  const [userTraceItem, setUserTraceItem] = useState({})
  
  useEffect(() => {
    if (!doSearch || !search.account || !search.accountType) return

    setIsLoadding(true)
    commonApi(getUserTraceItem, search).then(data => {
      setUserTraceItem(data)
    }).catch(err => Message.error(err))
    .finally(() => setIsLoadding(false))
  }, [doSearch])

  return userTraceItem
}