import React, {PureComponent} from 'react'
import SdwDialog from '@common/Dialog'
import SdwMessage from '@common/Message'
import {Table} from 'antd'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../../index.css'
import {requestOptionsLOG} from '@config/config'

const baseURL = requestOptionsLOG.basename

class DetailedWin extends PureComponent {

    state = {
        tableData: {}
    }

    componentDidMount() {
        this.queryReconciliationDetail()
    }

    // 查询对账详情
    queryReconciliationDetail = () => {
        let rtx = this.props.userInfo.name
        let {currentItem} = this.props
        let str = ''
        if (!!currentItem.id) {
            str = '?id=' + currentItem.id
        }
        fetch(baseURL + '/sdw/filebeat/reconciliation/queryReconciliationDetail' + str,
            {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'STAFFNAMES': rtx},
            }).then(res => {
            res.json().then((data) => {
                let record = data || {}
                this.setState({
                    tableData: record
                })
            })
        }).catch(error => {
            SdwMessage.error('接口异常：' + error)
        })
    }

    getColumns = () => {
        return [
            {
                title: 'CMD',
                dataIndex: 'cmd',
                checkChildren: true,
                width: 130,
            },
            {
                title: '对账名',
                dataIndex: 'reconcileName',
                width: 100
            },
            {
                title: '分发标识',
                dataIndex: 'deliveryCmd',
                width: 100
            },
            {
                title: '分发方式',
                render: data => {
                    let arr = {
                        'STORE': '入库',
                        'INDIVIDUAL': '自定义'
                    }
                    let msg = arr[data.deliveryType]
                    return <span>{msg ? msg : '-'}</span>
                },
                width: 100
            },
            {
                title: '采集ip',
                dataIndex: 'logIp',
                width: 158
            },
            {
                title: '处理ip',
                dataIndex: 'processIp',
                width: 158
            },
            {
                title: '消费ip',
                dataIndex: 'consumeIp',
                width: 158
            },
            {
                title: '时间周期',
                dataIndex: 'timeCycle',
                width: 158
            },
            {
                title: '采集接收成功数量',
                dataIndex: 'accessReceiveSuccessCount',
                width: 100
            },
            {
                title: '采集接收失败数量',
                dataIndex: 'accessReceiveFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '采集处理成功数量',
                dataIndex: 'accessProcessSuccessCount',
                width: 100
            },
            {
                title: '采集处理失败数量',
                dataIndex: 'accessProcessFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '采集发送成功数量',
                dataIndex: 'accessSendSuccessCount',
                width: 100
            },
            {
                title: '采集发送失败数量',
                dataIndex: 'accessSendFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '处理接收成功数量',
                dataIndex: 'processReceiveSuccessCount',
                width: 100
            },
            {
                title: '处理接收失败数量',
                dataIndex: 'processReceiveFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '处理处理成功数量',
                dataIndex: 'processProcessSuccessCount',
                width: 100
            },
            {
                title: '处理处理失败数量',
                dataIndex: 'processProcessFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '处理发送成功数量',
                dataIndex: 'processSendSuccessCount',
                width: 100
            },
            {
                title: '处理发送失败数量',
                dataIndex: 'processSendFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '消费接收成功数量',
                dataIndex: 'consumeReceiveSuccessCount',
                width: 100
            },
            {
                title: '消费接收失败数量',
                dataIndex: 'consumeReceiveFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '消费处理成功数量',
                dataIndex: 'consumeProcessSuccessCount',
                width: 100
            },
            {
                title: '消费处理失败数量',
                dataIndex: 'consumeProcessFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '消费发送成功数量',
                dataIndex: 'consumeSendSuccessCount',
                width: 100
            },
            {
                title: '消费发送失败数量',
                dataIndex: 'consumeSendFailedCount',
                render: text => text > 0 ? <span style={{color: 'red', fontWeight: 'bold'}}>{text}</span> : <span>{text}</span>,
                width: 100
            },
            {
                title: '入库量',
                dataIndex: 'storeCount',
                width: 80
            }
        ]
    }

    closeDialog = () => {
        this.props.closeDialog()
    }

    render() {

        let {
            tableData
        } = this.state

        let {
            detailedVisible
        } = this.props

        return (
            <div>
                <SdwDialog
                    title={'对账明细'}
                    width={'85%'}
                    height={600}
                    visible={detailedVisible}
                    hideCancelButton={true}
                    hideSureButton={true}
                    onCancelClick={() => this.closeDialog()}
                    cusWrapClass={'detailed-win_dialog'}
                >
                    <div className='detailed-win_table'>
                        <Table
                            columns={this.getColumns()}
                            dataSource={tableData.data || []}
                            childrenColumnName={'reconciliationDetailDTOS'}
                            scroll={{x: 'max-content', y: 400}}
                            pagination={false}
                        />
                    </div>
                </SdwDialog>
            </div>
        )
    }
}

const mapState = state => ({
    userInfo: state.user.userInfo
})
export default withRouter(connect(mapState)(DetailedWin))