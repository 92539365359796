import { request } from '../utils/request'

export function insertStandardManage (params) {
  return request({ url: '/tagmanage/standardManage/insertStandardManage', method: 'post', data: params })
}

export function updateStandardManage (params) {
  return request({ url: '/tagmanage/standardManage/updateStandardManage ', method: 'post', data: params })
}

export function getStandardManageItems (params) {
  return request({ url: '/tagmanage/standardManage/items', method: 'get', params: params })
}

export function insertOrderRule (params) {
  return request({ url: '/tagmanage/orderRule/insertOrderRule', method: 'post', data: params })
}

export function updateOrderRule (params) {
  return request({ url: '/tagmanage/orderRule/updateOrderRule', method: 'post', data: params })
}

export function getOrderRuleItems (params) {
  return request({ url: '/tagmanage/orderRule/items', method: 'get', params: params })
}

export function testOrderRule (params) {
  return request({ url: '/tagmanage/orderRule/testOrderRule', method: 'post', data: params })
}

export function submitTask (params) {
  return request({ url: '/tagmanage/orderRule/submitTask', method: 'post', data: params })
}

export function getMethods (params) {
  return request({ url: '/tagmanage/orderRule/getMethods', method: 'get', params: params })
}

export function getTaskFlowItems (params) {
  return request({ url: '/tagmanage/taskFlow/items', method: 'get', params: params })
}

export function elementLabels (params) {
  return request({ url: '/tagmanage/standardManage/elementLabels', method: 'get', params: params })
}